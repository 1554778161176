import React, {
  type ComponentProps,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  Table,
  type TableColumn,
  type TableRow,
  type TableRowAddon,
  type TableSelectAddon,
  Text,
  TextField,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useDialog, useEvent } from "@adaptive/design-system/hooks";
import { fuzzySearch } from "@adaptive/design-system/utils";
import { useGetBudgetLinesQuery } from "@api/budgets";
import { useAddBudgetLinesToJobMutation } from "@api/budgets/budget-lines";
import { handleErrors } from "@api/handle-errors";
import type { BudgetLineItems } from "@api/jobs";
import { TableFilterControls } from "@components/table-filter";
import { setFormatter } from "@components/table-filter/formatters";
import { useTableFilters } from "@components/table-filter/table-filter-hooks";
import { useJobsCostCodeAccountSimplified } from "@hooks/use-jobs-cost-codes-accounts-simplified";
import type { UseTableFilterOptionsProps } from "@hooks/use-table-filter-options";
import { useJobPermissions } from "@src/jobs";
import { useJobInfo } from "@store/jobs";
import { useClientSettings } from "@store/user";
import { getItemUrls } from "@utils/get-cost-code-account-values";
import { isAccount } from "@utils/is-account";
import { isCostCode } from "@utils/is-cost-code";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";

type AddBudgetLineDialogProps = {
  dialog: ReturnType<typeof useDialog>;
};

type Hierarchy = TableRow<{
  disabled: string | false | undefined;
  label: string;
  value: string;
  parent?: string | undefined;
  groupLabel?: string | undefined;
  isCategory?: string | undefined;
  description?: string | undefined;
  data?: Hierarchy[] | undefined;
  id?: string;
}>;

const TABLE_HEIGHT = 347;

const CostCodeColumn = ({
  row,
  shouldShowGroupLabel,
}: {
  row: Hierarchy;
  shouldShowGroupLabel: boolean;
}) => {
  const { itemDescriptionEnabled } = useClientSettings();

  return (
    <Flex gap="md" width="full" align="center" justify="space-between">
      <Flex direction="column">
        <Flex align="center">
          <Text>{row.label}</Text>
          {row.isCategory && (
            <Tooltip
              name="info-circle"
              as={Icon}
              size="sm"
              message="Cost code is a category in QuickBooks"
            />
          )}
        </Flex>
        {shouldShowGroupLabel && <Text size="xs">Group: {row.groupLabel}</Text>}
        {itemDescriptionEnabled && row.description && (
          <Text size="xs">Description: {row.description}</Text>
        )}
      </Flex>
    </Flex>
  );
};

const EMPTY_BUDGET_LINES: BudgetLineItems[] = [];

export const AddBudgetLineDialog = ({ dialog }: AddBudgetLineDialogProps) => {
  const { job } = useJobInfo();

  const { filters, setFilters, rawFilters } = useTableFilters({
    formatter: setFormatter,
  });

  const includeFilters = useMemo<
    UseTableFilterOptionsProps["includeFilters"]
  >(() => {
    const includeFilters: Exclude<
      UseTableFilterOptionsProps["includeFilters"],
      undefined
    > = [
      {
        name: "costCodes",
        showDisableAsEnabled: true,
        canItemsLinkToLinesDesktop: true,
      },
    ];

    if (window.BUDGETS_DRAW_ACCOUNTS_COST_CODE_ENABLED) {
      includeFilters.push({
        name: "accounts",
        filters: { can_accounts_link_to_lines_desktop: true },
      });
    }

    return includeFilters;
  }, []);

  const costCodeAccountsSimplified = useJobsCostCodeAccountSimplified();

  const { data = EMPTY_BUDGET_LINES, isFetching } = useGetBudgetLinesQuery(
    { customerId: job.id },
    { skip: !dialog.isRendered, refetchOnFocus: true }
  );

  const [addBudgetLinesToJob] = useAddBudgetLinesToJobMutation();

  const [selected, setSelected] = useState<Hierarchy[]>([]);

  const [searchQuery, setSearchQuery] = useState("");

  const costCodesAccountsData = useMemo(() => {
    return costCodeAccountsSimplified.data.map((item) => ({
      ...item,
      id: item.value,
    })) as Hierarchy[];
  }, [costCodeAccountsSimplified.data]);

  const fuzzyInstance = useMemo(
    () =>
      fuzzySearch(costCodeAccountsSimplified.data, {
        keys: ["label"],
        threshold: 0,
      }),
    [costCodeAccountsSimplified.data]
  );

  const searchResults = useMemo(
    () =>
      (searchQuery
        ? fuzzyInstance.search(searchQuery)
        : fuzzyInstance.all
      ).filter((item) => {
        const costCodes = (
          filters?.cost_code instanceof Set ? [...filters.cost_code] : []
        ).map((costCode) => costCode);
        const accounts = (
          filters?.account instanceof Set ? [...filters.account] : []
        ).map((account) => account);
        const lineItems = [...costCodes, ...accounts];

        if (lineItems.length) {
          const id = parseRefinementIdFromUrl(item.value);
          if ("groupLabel" in item && item.groupLabel) {
            const existCostCode =
              isCostCode(item.groupLabel) && costCodes.includes(id);
            const existAccount =
              isAccount(item.groupLabel) && accounts.includes(id);
            return existCostCode || existAccount;
          }
          return lineItems.includes(id);
        }
        return true;
      }),
    [searchQuery, fuzzyInstance, filters]
  );

  const shouldShowGroupLabel = useMemo(
    () =>
      !costCodesAccountsData.every(
        (item) => costCodesAccountsData[0].groupLabel === item.groupLabel
      ),
    [costCodesAccountsData]
  );

  const hierarchicalColumns: TableColumn<Hierarchy>[] = useMemo(() => {
    return [
      {
        id: "costCode",
        width: "fill",
        name: "Line items",
        render: (row) => (
          <CostCodeColumn
            row={row}
            shouldShowGroupLabel={shouldShowGroupLabel}
          />
        ),
      },
    ];
  }, [shouldShowGroupLabel]);

  useEffect(() => {
    if (!dialog.isVisible) {
      setSearchQuery("");
      return;
    }
    const costCodeInBudgetsTable = [];

    for (const lineItem of data) {
      const costCodeIndex = costCodeAccountsSimplified.data.findIndex(
        (costCode) => {
          return costCode.value === lineItem.jobCostMethod.url;
        }
      );

      const costCode = costCodeAccountsSimplified.data[
        costCodeIndex
      ] as Hierarchy;

      if (costCode) {
        costCodeInBudgetsTable.push({
          ...costCode,
          path: costCodeIndex,
          id: costCode.value,
        });
      }
    }

    setSelected(costCodeInBudgetsTable);
  }, [data, costCodeAccountsSimplified.data, dialog.isVisible]);

  const onAddItems = useEvent(async () => {
    /**
     * @todo convert backend api layer to accept item urls instead of ids to be consistent
     * with rest of the api
     */
    const ids = [];

    for (const row of selected) {
      const lineItemId = getItemUrls(row.value);
      ids.push({
        itemId: lineItemId.item
          ? parseRefinementIdFromUrl(lineItemId.item)
          : undefined,
        accountId: lineItemId.account
          ? parseRefinementIdFromUrl(lineItemId.account)
          : undefined,
      });
    }

    if (ids.length) {
      try {
        await addBudgetLinesToJob({
          budgetLines: ids,
          customerId: job.id,
        }).unwrap();

        toast.success(`Budget updated`);
      } catch (e) {
        handleErrors(e);
      }
    }

    dialog.hide();
  });

  const row = useMemo<TableRowAddon<Hierarchy>>(
    () => ({
      isVisible: (row) =>
        searchResults.some((item) => item.value === row.value),
    }),
    [searchResults]
  );

  const select = useMemo<TableSelectAddon<Hierarchy>>(
    () => ({
      onChange: setSelected,
      value: selected,
      isDisabled: (row) => {
        const budgetLine = data.find(
          (lineItem) => lineItem?.jobCostMethod.url === row.value
        );

        if (!budgetLine) return false;

        return !!budgetLine.spent;
      },
    }),
    [selected, data]
  );

  const onFiltersChange = useEvent((filters) => {
    setFilters(filters);
  });

  const hasFilters = Object.values(rawFilters).some((filter) => !!filter);

  const onClearFilters = useEvent(() => {
    setFilters({});
    setSearchQuery("");
  });

  return (
    <Dialog show={dialog.isVisible} variant="dialog" onClose={dialog.hide}>
      <DialogHeader>Add lines to budget</DialogHeader>
      <DialogContent>
        <Flex direction="column" gap="lg" minHeight="411px">
          <div>
            <TableFilterControls
              onFiltersChange={onFiltersChange}
              includeFilters={includeFilters}
              filters={rawFilters}
              withFilterTags
              filterProps={{
                grow: true,
                suffix: undefined,
                maxWidth: "auto",
                addonAfter: undefined,
              }}
              renderAfter={() => (
                <Flex width="114px" shrink={false}>
                  {hasFilters || searchQuery ? (
                    <Button block onClick={onClearFilters}>
                      Clear filters
                    </Button>
                  ) : null}
                </Flex>
              )}
              renderBefore={() => (
                <Flex grow width="full">
                  <TextField
                    value={searchQuery}
                    onChange={setSearchQuery}
                    addonAfter={<Icon name="search" />}
                    placeholder="Search"
                    messageVariant="hidden"
                    data-testid="budget-add-lines-search"
                  />
                </Flex>
              )}
            />
          </div>
          <Table
            id="budget-add-lines-table"
            size="sm"
            row={row}
            data={costCodesAccountsData}
            select={select}
            loading={isFetching}
            columns={hierarchicalColumns}
            maxHeight={`${hasFilters ? TABLE_HEIGHT - 40 : TABLE_HEIGHT}px`}
            data-testid="budget-add-lines-table"
          />
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Button block color="neutral" variant="text" onClick={dialog.hide}>
          Cancel
        </Button>
        <Button
          onClick={onAddItems}
          block
          disabled={!selected.length}
          data-testid="budget-add-lines-submit"
        >
          Add to budget
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

type AddBudgetLineDialogButtonProps = Pick<
  ComponentProps<typeof Button>,
  "size" | "variant" | "block"
> & {
  tooltipPlacement?: "left" | "top";
};

export const AddBudgetLineDialogButton = ({
  size = "sm",
  variant = "ghost",
  tooltipPlacement = "left",
}: AddBudgetLineDialogButtonProps) => {
  const { canManage, budgetReadOnly } = useJobPermissions();

  const addBudgetLineDialog = useDialog({ lazy: true });

  return (
    <>
      {addBudgetLineDialog.isRendered && (
        <AddBudgetLineDialog dialog={addBudgetLineDialog} />
      )}
      <Tooltip
        message={
          !canManage
            ? "You don't have permission to do this"
            : budgetReadOnly
              ? "This job is linked to a BuilderTrend job. Manage changes in BuilderTrend"
              : ""
        }
        placement={tooltipPlacement}
      >
        <Button
          size={size}
          variant={variant}
          onClick={addBudgetLineDialog.show}
          disabled={!canManage || budgetReadOnly}
          data-testid="budget-add-lines-button"
        >
          {variant === "ghost" && <Icon name="plus" />}
          Add line
        </Button>
      </Tooltip>
    </>
  );
};
