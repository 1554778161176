import React, { useCallback, useEffect, useId, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router";
import {
  Button,
  Card,
  dialog,
  Flex,
  Icon,
  Link,
  Loader,
  Tag,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import {
  useDialog,
  useEvent,
  useVisibilityChange,
} from "@adaptive/design-system/hooks";
import { handleErrors } from "@api/handle-errors";
import { PDF_BASE64_TYPE } from "@components/pdf-viewer";
import { useCustomersSimplified } from "@hooks/use-customers-simplified";
import { isLoadingBillSelector } from "@src/bills/utils";
import { useClientSettings, useUserInfo } from "@store/user";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";

import {
  useDeleteLienWaiverRequestMutation,
  useLazyRenderBillLienWaiverPdfQuery,
} from "../api/api";
import type { LienWaiver, LienWaiverVendor } from "../api/types";
import {
  LIEN_WAIVER_LINKED_STATUS,
  LIEN_WAIVER_NOT_REQUESTED_STATUS,
  LIEN_WAIVER_REQUESTED_STATUS,
  LIEN_WAIVER_SIGNED_STATUS,
  LIEN_WAIVER_STATUS,
  STRINGS,
} from "../constants/constants";
import type {
  AmountByCustomer,
  OnChangeHandler,
  OnLoadTemplateDataHandler,
  RequestOnPayment,
} from "../types";
import { parseLienWaiverMissingFields } from "../utils/format-missing-fields";

import { AlertMissingFields } from "./alert-missing-fields";
import { BillLienWaiverFieldCombobox } from "./bill-lien-waiver-field-combobox";
import { BillLienWaiverFieldRequested } from "./bill-lien-waiver-field-requested";
import { BillLienWaiverFieldRequestedExternal } from "./bill-lien-waiver-field-requested-external";
import { BillLienWaiverFieldSigned } from "./bill-lien-waiver-field-signed";
import { BillLienWaiverFieldSignedExternal } from "./bill-lien-waiver-field-signed-external";
import { BillLienWaiverMarkSignedDialog } from "./bill-lien-waiver-mark-signed-dialog";
import { BillLienWaiverPreviewDrawer } from "./bill-lien-waiver-preview-drawer";
import { BillLienWaiverRequestDialog } from "./bill-lien-waiver-request-dialog";

export type BillLienWaiverFieldProps = {
  customer?: string;
  value?: string;
  onChange?: OnChangeHandler;
  onLoadTemplateData?: OnLoadTemplateDataHandler;
  requestOnPayment?: RequestOnPayment & { checked: boolean };
  recipientEmail?: string;
  vendor: LienWaiverVendor;
  paymentAmount?: number;
  netPaymentAmount?: number;
  billId?: string | number;
  billDocNumber?: string;
  billPaymentId?: string | number;
  billLienWaiver?: LienWaiver | null;
  onRequestUpdate?: () => void;
  billInternalVersion?: string;
  /**
   * Indicates if this lien waiver being signed is a prerequisite for a payment to be made
   **/
  isProcessPrerequisite?: boolean;
  /**
   *
   */
  isReadOnly?: boolean;
  externalFlow?: boolean;
};

export const BillLienWaiverField = ({
  value,
  onChange,
  recipientEmail,
  vendor,
  paymentAmount,
  netPaymentAmount,
  billId,
  billDocNumber,
  billPaymentId,
  billLienWaiver,
  onRequestUpdate,
  requestOnPayment,
  onLoadTemplateData,
  customer,
  isProcessPrerequisite,
  isReadOnly,
  externalFlow = false,
}: BillLienWaiverFieldProps) => {
  const previewDrawer = useDialog({ lazy: true });
  const requestDialog = useDialog({ lazy: true });
  const uploadSignatureDialog = useDialog({ lazy: true });
  const [deleteLienWaiverRequest, { isLoading }] =
    useDeleteLienWaiverRequestMutation();
  const formId = useId();
  const [params] = useSearchParams();

  const lienWaiverId = params.get("lien-waiver-id");
  const lienWaiverFieldRef = useRef<HTMLDivElement>(null);
  const [
    renderLienWaiverPDF,
    { data: templateData, isLoading: isLoadingLienWaiverPDF },
  ] = useLazyRenderBillLienWaiverPdfQuery();

  /**
   * We are depending on the isLoadingBill selector because we need to refetch
   * the lien waiver template data when the bill data change. Since we don't
   * know which fields are changed, we need to refetch the data every time the
   * bill data changes.
   */
  const isLoadingBill = useSelector(isLoadingBillSelector);

  const { canManageLienWaiverRequest } = useUserInfo();

  const { paymentsRevampEnabled } = useClientSettings();

  const customersSimplified = useCustomersSimplified({
    enabled: !billLienWaiver?.customer,
    active: null,
  });

  const customerName = useMemo(
    () =>
      billLienWaiver?.customer?.displayName ||
      customersSimplified?.data?.find((item) => item.value === customer)
        ?.label ||
      "",
    [billLienWaiver?.customer?.displayName, customer, customersSimplified?.data]
  );

  const billLienWaiverStatus =
    !billLienWaiver ||
    !LIEN_WAIVER_LINKED_STATUS.includes(billLienWaiver.status)
      ? undefined
      : billLienWaiver.status;

  const isUnRequested = !!(billId || billPaymentId) && !billLienWaiverStatus;
  const isLinkedStatus = !!billLienWaiverStatus && !!billLienWaiver;
  const isRequested =
    isLinkedStatus &&
    LIEN_WAIVER_REQUESTED_STATUS.includes(billLienWaiverStatus);
  const isSigned =
    isLinkedStatus && LIEN_WAIVER_SIGNED_STATUS.includes(billLienWaiverStatus);

  const isValidValue =
    !!value &&
    !LIEN_WAIVER_NOT_REQUESTED_STATUS.some((status) => status === value);

  const canPreview = isValidValue || isSigned || isRequested;

  const haveMissingFields = useMemo(
    () => isLoadingLienWaiverPDF || !!templateData?.missingFields,
    [templateData?.missingFields, isLoadingLienWaiverPDF]
  );

  const previewPDF = isRequested
    ? billLienWaiver?.fileExport?.document
    : billLienWaiver?.pdf;

  const onDelete = useEvent(() => {
    if (!billLienWaiver?.id) return;

    dialog.confirmation({
      title: STRINGS.DELETE_LIEN_WAIVER_REQUEST_DIALOG_TITLE,
      message: STRINGS.DELETE_LIEN_WAIVER_REQUEST_DIALOG_TEXT,
      action: {
        primary: {
          children: STRINGS.DELETE,
          color: "error",
          onClick: async () => {
            try {
              await deleteLienWaiverRequest({
                id: billLienWaiver?.id.toString(),
              });
              toast.success(STRINGS.LIEN_WAIVER_REQUEST_DELETED);
              onRequestUpdate?.();
            } catch (error) {
              handleErrors(error);
            }
          },
        },
      },
    });
  });

  const loadLienWaiverTemplateData = useCallback(
    async (lienWaiverTemplate: string) => {
      if (!billId && !billPaymentId) return;
      const data = await renderLienWaiverPDF({
        billIds: billId ? [billId] : [],
        lienWaiverTemplateId: parseRefinementIdFromUrl(lienWaiverTemplate)!,
        paymentAmount: paymentAmount,
        netPaymentAmount: netPaymentAmount,
        billPaymentId: billPaymentId,
        customerId: parseRefinementIdFromUrl(customer)!,
      }).unwrap();

      if (data) {
        onLoadTemplateData?.({
          templateData: data,
          customer,
        });
      }
    },
    [
      billId,
      billPaymentId,
      renderLienWaiverPDF,
      paymentAmount,
      netPaymentAmount,
      customer,
      onLoadTemplateData,
    ]
  );

  const onDownloadRenderedPdf = useEvent(async () => {
    if ((!billId && !billPaymentId) || !value || !templateData) return;

    const a = document.createElement("a");
    a.href = `${PDF_BASE64_TYPE}${templateData.pdf}`;
    a.download = `lien-waiver-${
      billId ? `bill-${billId}` : `payment${billPaymentId}`
    }__${new Date().toISOString()}.pdf`;
    a.click();
    a.remove();
  });

  const billIds = useMemo(() => (billId ? [`${billId}`] : []), [billId]);

  const customers = useMemo<AmountByCustomer[]>(
    () => [
      {
        customer: customer!,
        paymentAmount: paymentAmount || 0,
        netPaymentAmount: netPaymentAmount || 0,
      },
    ],
    [customer, netPaymentAmount, paymentAmount]
  );

  const lienWaiverMissingFields = useMemo(() => {
    if (!customer || !vendor || !billId || !billDocNumber) return;

    const customerId = parseRefinementIdFromUrl(customer);

    return parseLienWaiverMissingFields({
      rawMissingFields: templateData?.missingFields || [],
      customer: {
        id: customerId!,
        url: customer,
        displayName: customerName,
      },
      vendor: {
        id: vendor.id.toString(),
        url: vendor.url,
        displayName: vendor.displayName,
      },
      bill: {
        id: billId.toString(),
        docNumber: billDocNumber,
      },
    });
  }, [
    billDocNumber,
    billId,
    customer,
    customerName,
    templateData?.missingFields,
    vendor,
  ]);

  useEffect(() => {
    if (
      !isLoadingBill &&
      isValidValue &&
      isUnRequested &&
      canManageLienWaiverRequest
    ) {
      loadLienWaiverTemplateData(value);
    }
  }, [
    isValidValue,
    isUnRequested,
    loadLienWaiverTemplateData,
    value,
    isLoadingBill,
    canManageLienWaiverRequest,
  ]);

  const shouldScroll = !!lienWaiverId && lienWaiverId === billLienWaiver?.id;

  useEffect(() => {
    if (shouldScroll) {
      lienWaiverFieldRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [shouldScroll]);

  useVisibilityChange((isVisible) => {
    if (isVisible && !isReadOnly && isValidValue) {
      loadLienWaiverTemplateData(value);
    }
  });

  const title = customerName ? (
    customerName
  ) : billDocNumber ? (
    <>
      Bill:{" "}
      <Link size="sm" href={`/bills/${billId}`} target="_blank">
        #{billDocNumber}
      </Link>
    </>
  ) : (
    ""
  );

  return (
    <Flex
      width="full"
      direction="column"
      id={billLienWaiver?.id?.toString() || formId}
      ref={lienWaiverFieldRef}
      gap="sm"
    >
      <Card
        as={Flex}
        width="full"
        size="sm"
        direction="column"
        gap="md"
        shrink={false}
      >
        {!externalFlow && (
          <Flex gap="lg" align="flex-start" direction="row">
            <Text size="sm">{title}</Text>
            {billLienWaiver && isProcessPrerequisite && (
              <Tooltip message={STRINGS.LIEN_WAIVER_SIGNATURE_REQUIRED}>
                <Tag size="sm" truncate={{ tooltip: false }}>
                  {STRINGS.SIGNATURE_REQUIRED}
                </Tag>
              </Tooltip>
            )}
          </Flex>
        )}
        {!(billId || billPaymentId) ? (
          <Flex width="full" height="48px" justify="center" align="center">
            <Loader size="xl" />
          </Flex>
        ) : (
          <Flex direction="column" gap="md" width="full">
            <Flex width="full" gap="md" justify="space-between">
              {isUnRequested && (
                <BillLienWaiverFieldCombobox
                  value={value}
                  customer={customer}
                  onChange={onChange}
                  paymentAmount={paymentAmount}
                  netPaymentAmount={netPaymentAmount}
                  openRequestDialog={requestDialog.show}
                  onRequestUpdate={onRequestUpdate}
                  requestOnPayment={requestOnPayment}
                  billLienWaiver={billLienWaiver}
                  billPaymentId={billPaymentId}
                  billId={billId}
                  haveMissingFields={haveMissingFields}
                  vendor={vendor}
                  isReadOnly={isReadOnly}
                />
              )}
              {isRequested &&
                onRequestUpdate &&
                !externalFlow &&
                !isReadOnly && (
                  <BillLienWaiverFieldRequested
                    billLienWaiver={billLienWaiver}
                    onRequestUpdate={onRequestUpdate}
                    isProcessPrerequisite={isProcessPrerequisite}
                  />
                )}
              {isRequested && externalFlow && (
                <BillLienWaiverFieldRequestedExternal
                  billLienWaiver={billLienWaiver}
                  onRequestUpdate={onRequestUpdate}
                />
              )}

              {isSigned && !externalFlow && !isReadOnly && (
                <BillLienWaiverFieldSigned
                  markedAsSigned={
                    billLienWaiver.status ===
                    LIEN_WAIVER_STATUS.MARKED_AS_SIGNED
                  }
                  vendor={
                    billLienWaiver.printedName ??
                    billLienWaiver.vendor.displayName
                  }
                  user={billLienWaiver.revisionEvent?.author.fullName}
                  time={billLienWaiver.signedAt}
                />
              )}
              {isSigned && externalFlow && (
                <BillLienWaiverFieldSignedExternal
                  markedAsSigned={
                    billLienWaiver.status ===
                    LIEN_WAIVER_STATUS.MARKED_AS_SIGNED
                  }
                  vendor={
                    billLienWaiver.printedName ??
                    billLienWaiver.vendor.displayName
                  }
                  user={billLienWaiver.revisionEvent?.author.fullName}
                  time={billLienWaiver.signedAt}
                  customer={billLienWaiver?.customer?.displayName || undefined}
                  paymentAmount={paymentAmount}
                />
              )}
              <Flex gap="sm" shrink={false}>
                {!externalFlow && isSigned && (
                  <Tooltip message={STRINGS.DELETE_SIGNED_LIEN_WAIVER}>
                    <Button
                      color="neutral"
                      variant="ghost"
                      onClick={onDelete}
                      disabled={isLoading || !canManageLienWaiverRequest}
                      data-testid="delete-lien-waiver-button"
                    >
                      {isLoading ? <Loader /> : <Icon name="trash" />}
                    </Button>
                  </Tooltip>
                )}

                {!isSigned &&
                  !externalFlow &&
                  ((paymentsRevampEnabled && billPaymentId) ||
                    !paymentsRevampEnabled) &&
                  onRequestUpdate &&
                  !isReadOnly && (
                    <Tooltip
                      message={
                        isUnRequested
                          ? STRINGS.MARK_AS_REQUESTED_FIRST
                          : STRINGS.UPLOAD_SIGNED_LIEN_WAIVER
                      }
                    >
                      <Button
                        color="neutral"
                        variant="ghost"
                        disabled={isUnRequested || !canManageLienWaiverRequest}
                        onClick={uploadSignatureDialog.show}
                        data-testid="upload-signature-button"
                      >
                        <Icon name="signature" />
                      </Button>
                    </Tooltip>
                  )}

                {!isReadOnly && (
                  <Tooltip
                    message={
                      isSigned
                        ? STRINGS.VIEW_SIGNED_LIEN_WAIVER
                        : STRINGS.VIEW_LIEN_WAIVER
                    }
                  >
                    <Button
                      color="neutral"
                      variant="ghost"
                      onClick={
                        externalFlow ? onRequestUpdate : previewDrawer.show
                      }
                      disabled={
                        (!billLienWaiver?.fileExport?.document &&
                          !isValidValue) ||
                        isLoading ||
                        !canPreview ||
                        (!canManageLienWaiverRequest && !externalFlow)
                      }
                      data-testid="view-lien-waiver-button"
                    >
                      <Icon name="eye" />
                    </Button>
                  </Tooltip>
                )}
                {onRequestUpdate && !isReadOnly && (
                  <Tooltip
                    message={
                      isSigned
                        ? STRINGS.DOWNLOAD_SIGNED_LIEN_WAIVER
                        : STRINGS.DOWNLOAD_LIEN_WAIVER
                    }
                  >
                    <Button
                      color="neutral"
                      variant="ghost"
                      data-testid="download-lien-waiver-button"
                      {...(isUnRequested
                        ? {
                            as: "button",
                            disabled:
                              isLoadingLienWaiverPDF ||
                              !isValidValue ||
                              !canManageLienWaiverRequest,
                            onClick: onDownloadRenderedPdf,
                          }
                        : {
                            as: "a",
                            target: "_blank",
                            download: true,
                            href: previewPDF!,
                          })}
                    >
                      {isLoadingLienWaiverPDF ? (
                        <Loader />
                      ) : (
                        <Icon name="download" />
                      )}
                    </Button>
                  </Tooltip>
                )}
              </Flex>
            </Flex>
            {isValidValue &&
              isUnRequested &&
              !isLoading &&
              haveMissingFields &&
              !isReadOnly &&
              lienWaiverMissingFields &&
              templateData?.missingFields?.length && (
                <AlertMissingFields
                  lienWaiverMissingFields={lienWaiverMissingFields}
                />
              )}
          </Flex>
        )}
      </Card>

      {previewDrawer.isRendered &&
        !!(value || previewPDF) &&
        canManageLienWaiverRequest && (
          <BillLienWaiverPreviewDrawer
            show={previewDrawer.isVisible}
            onClose={previewDrawer.hide}
            value={value}
            paymentAmount={paymentAmount}
            netPaymentAmount={netPaymentAmount}
            billIds={billIds}
            billPaymentId={billPaymentId}
            customers={customers}
            pdf={isLinkedStatus ? previewPDF! : undefined}
          />
        )}
      {requestDialog.isRendered &&
        canManageLienWaiverRequest &&
        onRequestUpdate && (
          <BillLienWaiverRequestDialog
            show={requestDialog.isVisible}
            onClose={requestDialog.hide}
            recipientEmail={recipientEmail}
            customer={customer}
            vendor={vendor}
            lienWaiverTemplateId={value}
            paymentAmount={paymentAmount}
            billId={billId}
            billPaymentId={billPaymentId}
            onRequestUpdate={onRequestUpdate}
          />
        )}
      {uploadSignatureDialog.isRendered &&
        billLienWaiver &&
        canManageLienWaiverRequest &&
        onRequestUpdate && (
          <BillLienWaiverMarkSignedDialog
            onClose={uploadSignatureDialog.hide}
            show={uploadSignatureDialog.isVisible}
            billLienWaiver={billLienWaiver}
            onRequestUpdate={onRequestUpdate}
          />
        )}
    </Flex>
  );
};
