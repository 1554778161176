import React, {
  type ComponentRef,
  type ElementType,
  type FocusEventHandler,
  type ForwardedRef,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router";
import {
  Checkbox,
  ComboBox,
  type ComboBoxActionAddon,
  type ComboBoxProps,
  type ComboBoxRef,
  type ComboBoxRenderOptionComponent,
  type ComboBoxRenderOptionHandler,
  DropdownComboBoxItem,
  type DropdownListRef,
  type DropdownTrigger,
  Flex,
  Icon,
  Tag,
  TagGroup,
  Text,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import {
  is,
  isFocusable,
  isOption,
  mergeRefs,
} from "@adaptive/design-system/utils";
import {
  type BankAccountOption,
  type DataBankAccount,
} from "@bill-payment/types";

import type { Option } from "../../types";

type Ref = ComponentRef<typeof ComboBox>;

type EnhancedOption = Option & {
  parentLabel?: string;
};

type ValueFilter = "bank-account" | "credit";

type Filter = {
  label: string;
  value: ValueFilter;
};

const BANK_ACCOUNT_FILTER: Filter = {
  label: "Bank account",
  value: "bank-account",
};

const CREDIT_CARD_FILTER: Filter = {
  label: "Credit card",
  value: "credit",
};

const AVAILABLE_FILTERS = [BANK_ACCOUNT_FILTER, CREDIT_CARD_FILTER];

const MultipleOption: ComboBoxRenderOptionComponent<
  EnhancedOption
  // eslint-disable-next-line react/prop-types
> = ({ highlightedLabel, ...option }) => {
  return (
    <Flex align="center" gap="lg">
      {option.multiple && (
        <Checkbox aria-hidden="true" checked={option.selected} />
      )}
      <Flex direction="column">
        <Text>{highlightedLabel}</Text>
      </Flex>
    </Flex>
  );
};

const TaggableOption: ComboBoxRenderOptionComponent<
  EnhancedOption
  // eslint-disable-next-line react/prop-types
> = ({ highlightedLabel, ...option }) => {
  if (!option.value) {
    return null;
  }

  return (
    <Flex align="center" gap="lg">
      {option.multiple && (
        <Checkbox aria-hidden="true" checked={option.selected} />
      )}
      <Flex direction="column">
        <Flex gap="sm" align="center">
          {highlightedLabel}
        </Flex>
        <Tag size="sm" color="neutral">
          {option.parentLabel}
        </Tag>
      </Flex>
    </Flex>
  );
};

export type BankAccountComboBoxProps<IsMultiple extends boolean> = Omit<
  ComboBoxProps<IsMultiple>,
  "label" | "placeholder"
> & {
  as?: ElementType;
  label?: string;
  paymentOptions?: DataBankAccount;
};

const BankAccountComboBox = <IsMultiple extends boolean = false>(
  {
    as: Component = ComboBox,
    label,
    value,
    paymentOptions,
    loading,
    onChange,
    ...props
  }: BankAccountComboBoxProps<IsMultiple>,
  ref: ForwardedRef<Ref>
) => {
  const mainComboboxRef = useRef<ComboBoxRef>(null);
  const dropdownListRef = useRef<DropdownListRef>(null);
  const dropdownTriggerRef = useRef<ComponentRef<typeof DropdownTrigger>>(null);
  const navigate = useNavigate();

  const filterComboboxRef = useRef<ComboBoxRef>(null);

  const icon = useMemo(() => {
    if (!paymentOptions?.accounts) return;

    const castData = paymentOptions?.accounts as EnhancedOption[];
    const option = castData.find(
      (item) => item.value === value
    ) as BankAccountOption;

    return option?.isCreditCard ? "credit-card" : "bank";
  }, [paymentOptions?.accounts, value]);

  const [appliedFilters, setAppliedFilters] = useState<EnhancedOption[]>([]);

  const enhancedData = useMemo(() => {
    const castData = paymentOptions?.accounts;

    if (!castData) return [];

    const labeledData = castData.map((option) => {
      const bankAccountOption = option as BankAccountOption;
      const parentLabel = bankAccountOption.isCreditCard
        ? "Credit card"
        : bankAccountOption.isPaymentAccount
          ? "Account"
          : "Bank account";

      return { ...option, parentLabel };
    });

    for (const option of is.array(value) ? value : [value]) {
      if (!isOption(option)) continue;

      const shouldAppendValue = !castData.some(
        (innerOption) => innerOption.value === option.value
      );

      if (shouldAppendValue) castData.unshift(option as BankAccountOption);
    }

    const filteredData = labeledData.filter((item) => {
      const bankAccount = item as BankAccountOption;
      if (!appliedFilters?.length) return true;

      return appliedFilters.some((filter) => {
        switch (filter.value) {
          case "credit":
            return bankAccount.isCreditCard;
          case "bank-account":
            return !bankAccount.isCreditCard;
          default:
            return false;
        }
      });
    });

    return filteredData as EnhancedOption[];
  }, [paymentOptions?.accounts, value, appliedFilters]);

  const enhancedOnChange = useEvent<
    Exclude<ComboBoxProps<IsMultiple>["onChange"], undefined>
  >((...props) => {
    onChange?.(...props);
  });

  const emptyActionAddon = useMemo<ComboBoxActionAddon>(
    () => ({
      mode: appliedFilters?.length > 0 ? "empty" : "hide",
      onClick: () => {
        navigate("/settings/company/bank-accounts");
      },
      children: "Add bank accounts",
      hideOnClick: false,
    }),
    [appliedFilters, navigate]
  );

  const onRemoveFilter = useEvent((option: EnhancedOption) => {
    setAppliedFilters((filters) =>
      filters.filter((filter) => filter.value !== option.value)
    );
  });

  const onFilterBlur = useEvent<FocusEventHandler<HTMLInputElement>>((e) => {
    if (!isFocusable(e.relatedTarget)) {
      mainComboboxRef.current?.focus();
    }
  });

  const hideOnInteractOutside = useEvent(
    () =>
      dropdownListRef.current?.dataset.open !== "true" &&
      document.activeElement !== dropdownTriggerRef.current &&
      filterComboboxRef.current?.getAttribute("aria-expanded") !== "true"
  );

  const onFilterChange = useEvent((_, selectedFilter?: EnhancedOption) => {
    filterComboboxRef.current?.blur();

    if (!selectedFilter) return;

    setAppliedFilters((filters) =>
      filters.some((filter) => filter.value === selectedFilter.value)
        ? filters.filter((filter) => filter.value !== selectedFilter.value)
        : [...filters, selectedFilter]
    );
  });

  const renderComboboxOption = useCallback<
    ComboBoxRenderOptionHandler<EnhancedOption>
  >((option, extra) => {
    return <TaggableOption {...option} {...extra} />;
  }, []);

  const renderFilterOption = useCallback<
    ComboBoxRenderOptionHandler<EnhancedOption>
  >(
    (option, extra) => (
      <MultipleOption
        {...option}
        multiple
        selected={appliedFilters.some(
          (filter) => filter.value === option.value
        )}
        {...extra}
      />
    ),
    [appliedFilters]
  );

  return (
    <Component
      ref={mergeRefs(mainComboboxRef, ref)} // eslint-disable-line react-compiler/react-compiler
      hideOnInteractOutside={hideOnInteractOutside}
      data={enhancedData}
      label={label}
      value={value}
      action={emptyActionAddon}
      prefix={icon && <Icon name={icon} />}
      loading={loading}
      onChange={enhancedOnChange}
      placeholder="Select account"
      renderOption={renderComboboxOption}
      emptyMessage="No results matching the applied filters"
      header={
        AVAILABLE_FILTERS.length > 1 && (
          <Flex gap="md" direction="column" width="full">
            <Flex gap="md">
              <ComboBox
                ref={filterComboboxRef}
                size="sm"
                data={AVAILABLE_FILTERS}
                value=""
                portal
                onBlur={onFilterBlur}
                onChange={onFilterChange}
                placeholder="Filters"
                renderOption={renderFilterOption}
                messageVariant="hidden"
              />
            </Flex>
            {appliedFilters.length > 0 && (
              <TagGroup
                size="sm"
                data={appliedFilters}
                render="label"
                onRemove={onRemoveFilter}
              />
            )}
          </Flex>
        )
      }
      gap={Component === DropdownComboBoxItem ? false : undefined}
      includeValueWhenMissing={false}
      {...props}
    />
  );
};

const ForwardedBankAccountComboBox = forwardRef(BankAccountComboBox) as <
  IsMultiple extends boolean = false,
>(
  props: BankAccountComboBoxProps<IsMultiple> & { ref?: ForwardedRef<Ref> }
) => ReturnType<typeof BankAccountComboBox>;

export { ForwardedBankAccountComboBox as BankAccountComboBox };
