import { referenceNodeSchema } from "@api/expenses/response";
import {
  CUSTOMER_ACCOUNT_TYPE,
  PAYMENT_METHOD,
  PAYMENT_STATUS,
  PROCESS_PREREQUISITE_TYPE,
} from "@bill-payment/constants";
import { lienWaiverSchema } from "@lien-waiver/api";
import { OBJECT_ORIGIN } from "@src/shared/constants";
import {
  arraySchema,
  commentSchema,
  dateSchema,
  errorSchema,
  idSchema,
  userSchema,
} from "@utils/schema";
import { z } from "zod";

export const getPaymentOptionsRequestSchema = z.object({
  billIds: z.array(idSchema),
  // We want vendorId to be part of the query cache key so it refetches if the vendor on a bill changes
  vendorIds: z.array(idSchema),
});

export const LIEN_WAIVER_STATUS = [
  "not_selected",
  "not_required",
  "not_requested",
  "requested",
  "marked_as_requested",
  "signed",
  "marked_as_signed",
] as const;

export const LIEN_WAIVER_STATUS_UPPER = [
  "NOT_SELECTED",
  "NOT_REQUIRED",
  "REQUESTED",
  "NOT_REQUESTED",
  "MARKED_AS_REQUESTED",
  "SIGNED",
  "MARKED_AS_SIGNED",
] as const;

export const LIEN_WAIVER_REQUESTED_STATUS = [
  "requested",
  "marked_as_requested",
] as const;

export const lienWaiverStatusSchema = z.enum(LIEN_WAIVER_STATUS);
export const lienWaiverStatusUpperSchema = z.enum(LIEN_WAIVER_STATUS_UPPER);
export const paymentMethodEnumSchema = z.enum([
  PAYMENT_METHOD.MARK_AS_PAID,
  PAYMENT_METHOD.ACH,
  PAYMENT_METHOD.PRINT_CHECK,
  PAYMENT_METHOD.MAIL_CHECK,
  PAYMENT_METHOD.VIRTUAL_EMAIL_CHECK,
  PAYMENT_METHOD.VIRTUAL_SMS_CHECK,
]);

export const paymentStatusEnumSchema = z.enum([
  PAYMENT_STATUS.ACH_REQUESTED,
  PAYMENT_STATUS.CANCELLED,
  PAYMENT_STATUS.FAILED,
  PAYMENT_STATUS.PAID,
  PAYMENT_STATUS.PENDING,
  PAYMENT_STATUS.SCHEDULED,
  PAYMENT_STATUS.PENDING_PREREQUISITES,
]);

export const customerAccountTypeEnumSchema = z.enum([
  CUSTOMER_ACCOUNT_TYPE.CREDIT_CARD,
  CUSTOMER_ACCOUNT_TYPE.BANK_ACCOUNT,
]);

export const processPrerequisiteTypeEnumSchema = z.enum([
  PROCESS_PREREQUISITE_TYPE.VENDOR_BANKING_ACH,
  PROCESS_PREREQUISITE_TYPE.LIEN_WAIVER_SIGNATURE,
  PROCESS_PREREQUISITE_TYPE.SCHEDULED_DATE,
]);

export const lienWaiverBasePayloadSchema = z.object({
  customer: z.string().url(),
  status: lienWaiverStatusSchema.nullish(),
  sendLienWaiverOnPayment: z.boolean(),
});

export const createdInEnumSchema = z.enum([
  OBJECT_ORIGIN.ADAPTIVE,
  OBJECT_ORIGIN.QUICKBOOKS,
]);

export const lienWaiverPayloadSchema = lienWaiverBasePayloadSchema.merge(
  z.object({
    lienWaiverTemplateId: idSchema.nullish(),
  })
);

export const accountTypeEnumSchema = z.enum([
  "depository",
  "loan",
  "credit",
  "investment",
  "other",
]);

export const accountSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  parent: z.string().url().nullish(),
  displayName: z.string().nullish(),
  isPaymentAccount: z.boolean(),
  isCreditCard: z.boolean(),
  isBankAccount: z.boolean(),
  fullyQualifiedCategory: z.string().nullish(),
  isBudgetCode: z.boolean(),
  isVendorCode: z.boolean(),
  isRemovedVendorCode: z.boolean(),
  parentName: z.string().nullish(),
});

export const customerSimpleSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  displayName: z.string(),
});

export const itemSchema = z
  .object({
    id: idSchema,
    url: z.string().url(),
    parent: z.string().url().nullish(),
    displayName: z.string(),
    fullyQualifiedName: z.string().nullish(),
  })
  .transform((item) => ({
    ...item,
    fullyQualifiedName: item.fullyQualifiedName ?? item.displayName,
  }));

export const customerCardSchema = z.object({
  id: idSchema,
  name: z.string(),
  mask: z.string().nullish(),
  url: z.string().url(),
  user: userSchema.nullish(),
  account: accountSchema.nullish(),
  item: itemSchema.nullish(),
  fullName: z.string(),
  customer: customerSimpleSchema.nullish(),
});

export const plaidAccountOwnerBasicSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  cardId: idSchema,
  accountOwner: z.string().nullish(),
});

export const customerBankAccountSchema = z.object({
  id: z.string(),
  url: z.string().url(),
  name: z.string(),
  currency: z.string().nullish(),
  type: accountTypeEnumSchema,
  mask: z.string().nullish(),
  plaidAccountId: z.string(),
  paymentAccount: accountSchema.nullish(),
  plaidAccountOwners: arraySchema(plaidAccountOwnerBasicSchema),
  bankName: z.string().nullish(),
  verificationStatus: z.string().nullish(),
  isTransactionPullEnabled: z.boolean(),
  checkbookAccountIsActive: z.boolean().nullish(),
  lastSyncDate: z.string().nullish(),
  isDefault: z.boolean().nullish(),
});

export const paymentScheduleSchema = z.object({
  processOn: dateSchema,
  expectedDeliveryAfter: dateSchema,
  expectedDeliveryBefore: dateSchema,
});

export const vendorBankAccountSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  routingNumber: z.string(),
  accountNumber: z.string(),
});

export const processPrerequisiteSchema = z.object({
  type: processPrerequisiteTypeEnumSchema,
  isSatisfied: z.boolean().nullish(),
  satisfiedOn: z.string().nullish(),
});

export const optionSchema = z.object({
  default: z.boolean().nullish(),
  customerAccountType: customerAccountTypeEnumSchema,
  customerBankAccount: customerBankAccountSchema.nullish(),
  customerCard: customerCardSchema.nullish(),
  customerPaymentAccount: accountSchema.nullish(),
  vendorBankAccount: vendorBankAccountSchema.nullish(),
  paymentMethod: paymentMethodEnumSchema,
  hasUnmatchedCardTransactions: z
    .boolean()
    .nullish()
    .transform((value) => !!value),
  processPrerequisites: arraySchema(processPrerequisiteSchema),
  paymentSchedules: arraySchema(paymentScheduleSchema),
});

export const billPaymentV2AppliedVendorCreditPayloadSchema = z.object({
  appliedAmount: z.string(),
  vendorCredit: z.string().url(),
});

export const billPaymentV2PayloadSchema = z.object({
  bills: arraySchema(idSchema),
  options: z.object({
    appliedAmount: z.number(),
    vendorBankAccount: z.string().url().nullish(),
    customerPaymentAccount: z.string().url().nullish(),
    appliedProcessOn: z.string().nullish(),
    appliedCommentText: z.string().nullish(),
    appliedVendorEmail: z.string().nullish(),
    customerBankAccount: z.string().url().nullish(),
    customerCard: z.string().url().nullish(),
    paymentMethod: paymentMethodEnumSchema.nullish(),
    appliedSignature: z.string().nullish(),
    appliedCardTransaction: z.string().url().nullish(),
    appliedVendorCredits: arraySchema(
      billPaymentV2AppliedVendorCreditPayloadSchema
    ).nullish(),
    processPrerequisites: arraySchema(
      z.object({
        type: z.string(),
      })
    ).nullish(),
  }),
  lienWaivers: arraySchema(lienWaiverPayloadSchema).nullish(),
});

export const billPaymentIdPayloadSchema = z.object({
  billPaymentId: idSchema,
});

export const reviewStatusSchema = z.enum([
  "ALL",
  "PAID",
  "DRAFT",
  "APPROVAL",
  "FOR_PAYMENT",
  "PAYMENT_FAILED",
  "PARTIALLY_PAID",
  "ACH_PROCESSING",
  "ACH_INFO_REQUESTED",
  "PAYMENT_SCHEDULED",
]);

export const latestAchRequestSchema = z.object({
  id: idSchema,
  createdAt: dateSchema,
  status: z.string().nullish(),
  vendorEmail: z.string().email().nullish(),
  isCompleted: z.boolean(),
  isEditable: z.boolean(),
  isExpired: z.boolean(),
  token: z.string().nullish(),
});

export const achSchema = z.object({
  id: idSchema,
  archived: z.boolean().nullish(),
  routingNumber: z.string(),
  accountNumber: z.string(),
  isForPendingPrerequisitesPayments: z.boolean().nullish(),
  url: z.string().url(),
});

export const billPaymentVendorSchema = z.object({
  id: idSchema,
  url: z.string(),
  displayName: z.string(),
  email: z.string().email().nullish(),
  latestAchRequest: latestAchRequestSchema.nullish(),
  uniqueId: z.string().uuid().nullish(),
});

export const fileExportSchema = z.object({
  document: z.string(),
  id: z.string(),
});

export const revisionEventSchema = z.object({
  author: z.object({
    id: z.number(),
    url: z.string(),
    fullName: z.string(),
  }),
  createdAt: z.string(),
  timelineEventType: z.string(),
});

export const LIEN_WAIVER_SIGNED_STATUS = <const>["signed", "marked_as_signed"];
export const LIEN_WAIVER_LINKED_STATUS = <const>[
  ...LIEN_WAIVER_REQUESTED_STATUS,
  ...LIEN_WAIVER_SIGNED_STATUS,
];

export const billPaymentLienWaiverSchema = lienWaiverSchema.extend({
  isProcessPrerequisite: z.boolean().nullish(),
});

export const lienWaiverPaymentOptionSchema = z.object({
  id: z.string(),
  paymentAmount: z.string().nullish(),
  signedAt: z.string().nullish(),
  status: lienWaiverStatusUpperSchema,
  statusLabel: z.string(),
  updatedAt: dateSchema,
});

export const billSchema = z.object({
  id: idSchema,
  date: z.string(),
  docNumber: z.string(),
  humanReadableType: z.string(),
  isInQuickbooks: z.boolean(),
  reviewStatus: z.string(),
  totalAmount: z.string().nullish(),
  url: z.string(),
});

export const vendorCreditPaymentOptionSchema = z.object({
  id: idSchema,
  date: dateSchema.nullish(),
  docNumber: z.string(),
  humanReadableType: z.string(),
  isInQuickbooks: z.boolean(),
  reviewStatus: z.string(),
  totalAmount: z.string().nullish(),
  openBalance: z.string().nullish(),
  url: z.string(),
  customers: arraySchema(customerSimpleSchema),
  billableStatus: z.optional(
    arraySchema(z.enum(["Billable", "HasBeenBilled", "NotBillable"]))
  ),
  createdAt: dateSchema,
});

export const appliedVendorCreditSchema = vendorCreditPaymentOptionSchema.extend(
  {
    appliedAmount: z.number().nullish(),
  }
);

export const paymentScheduledSchema = z.object({
  processedOn: dateSchema,
  deliveredOn: dateSchema,
  expectedDeliveryAfter: dateSchema,
  expectedDeliveryBefore: dateSchema,
});

export const paymentOptionSchema = z.object({
  bills: arraySchema(billSchema),
  options: arraySchema(optionSchema),
  lienWaivers: arraySchema(lienWaiverPaymentOptionSchema),
  vendor: billPaymentVendorSchema,
  vendorCredits: arraySchema(vendorCreditPaymentOptionSchema),
});
export const paymentOptionResponseSchema = arraySchema(paymentOptionSchema);
export const createBillPaymentV2ResponseItemSchema = z.object({
  id: idSchema,
  url: z.string().url(),
});

export const createBillPaymentV2ResponseSchema = arraySchema(
  createBillPaymentV2ResponseItemSchema
);

export const paymentErrorsSchema = z.object({
  customerBankAccount: customerBankAccountSchema.nullish(),
  customerPaymentAccount: accountSchema.nullish(),
  error: z.string().nullish(),
  errorDetail: z.string().nullish(),
  link: z.string().nullish(),
});

export const billSimpleSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  humanReadableType: z.string().nullish(),
  docNumber: z.string(),
  reviewStatus: reviewStatusSchema,
  date: dateSchema.nullish(),
  isInQuickbooks: z.boolean(),
});

export const cardTransactionSimpleSchema = z
  .object({
    id: z.string().nullish(),
    amount: z
      .string()
      .nullish()
      .transform((amt) => (amt ? parseFloat(amt) : null)),
    date: dateSchema,
    name: z.string().nullish(),
    merchantName: z.string().nullish(),
    displayName: z.string().nullish(),
    url: z.string().url().nullish(),
    card: z.object({ user: userSchema.nullish() }).nullish(),
    paymentAccount: z.optional(referenceNodeSchema).or(z.null()),
    pending: z.boolean(),
    description: z.string().nullish(),
    mask: z.string().nullish(),
    plaidAccountOwner: z
      .object({
        id: idSchema,
        url: z.string().url(),
        accountOwner: z.string().nullish(),
      })
      .nullish(),
  })
  .nullish();

export const billFromPaymentSchema = z.object({
  bill: billSimpleSchema,
  appliedAmount: z.string(),
  openBalance: z.string(),
  customers: arraySchema(customerSimpleSchema),
});

export const vendorCreditFromPaymentSchema = z.object({
  vendorCredit: billSimpleSchema,
  appliedAmount: z.string(),
  openBalance: z.string(),
  customers: arraySchema(customerSimpleSchema),
});

/**
 * Contains just enough data for the bill detail page and the payments' page table
 */
export const billPaymentListingV2Schema = z.object({
  id: idSchema,
  url: z.string().url(),
  docNumber: z.string().nullish(),
  status: z.string(),
  customerAccountType: customerAccountTypeEnumSchema,
  customerBankAccount: customerBankAccountSchema.nullish(),
  customerCard: customerCardSchema.nullish(),
  customerPaymentAccount: accountSchema.nullish(),
  vendorBankAccount: vendorBankAccountSchema.nullish(),
  vendorEmail: z.string().nullish(),
  vendorPhoneNumber: z.string().nullish(),
  vendorAddress: z.string().nullish(),
  paymentMethod: paymentMethodEnumSchema,
  appliedCardTransaction: cardTransactionSimpleSchema.nullish(),
  processPrerequisites: arraySchema(processPrerequisiteSchema),
  vendor: billPaymentVendorSchema,
  lienWaivers: arraySchema(billPaymentLienWaiverSchema),
  appliedAmount: z.string(),
  totalAmount: z.string(),
  paymentSchedule: paymentScheduledSchema.nullish(),
  updatedAt: z.string().nullish(),
  updatedBy: userSchema.nullish(),
  isVoided: z.boolean(),
  createdIn: createdInEnumSchema.nullish(),
  bills: arraySchema(billFromPaymentSchema),
});

export const billPaymentDetailV2Schema = billPaymentListingV2Schema.extend({
  appliedVendorCredits: arraySchema(vendorCreditFromPaymentSchema),
  syncErrors: arraySchema(errorSchema).nullish(),
  paymentErrors: arraySchema(paymentErrorsSchema).nullish(),
  timelineEvents: arraySchema(commentSchema),
  hasUnmatchedCardTransactions: z.boolean().nullish(),
  realm: z.string().url(),
});

export const cancelBillPaymentResponseSchema = z.object({
  id: z.string(),
});

export const payNowScheduledPaymentResponseSchema = z.object({
  id: z.string(),
});

export const externalBillPaymentResponse = z.object({
  id: idSchema,
  url: z.string().url(),
  appliedAmount: z.string(),
  bills: arraySchema(billFromPaymentSchema),
  comments: arraySchema(commentSchema),
  customerBankAccount: customerBankAccountSchema.nullish(),
  customerCard: customerCardSchema.nullish(),
  customerPaymentAccount: accountSchema.nullish(),
  lienWaivers: arraySchema(lienWaiverSchema),
  processPrerequisites: arraySchema(processPrerequisiteSchema),
  paymentSchedule: paymentScheduledSchema.nullish(),
  status: paymentStatusEnumSchema,
  totalAmount: z.string(),
  vendor: billPaymentVendorSchema,
  vendorBankAccount: vendorBankAccountSchema.nullish(),
  appliedVendorCredits: arraySchema(vendorCreditFromPaymentSchema),
  realm: z.object({
    id: idSchema,
    companyName: z.string(),
    url: z.string().url(),
    uniqueId: z.string().uuid(),
  }),
});

export const listBillPaymentsV2PayloadSchema = z
  .object({
    dataIndex: z.string(),
    value: z.boolean().or(z.string()).or(z.number()).nullish(),
  })
  .array();

export const listBillPaymentsV2ResponseSchema = z.object({
  count: z.number(),
  hasFailedPayments: z.boolean().nullish(),
  results: arraySchema(billPaymentListingV2Schema),
});

export const bankAccountOptionSchema = z.object({
  label: z.string(),
  value: z.string(),
  mask: z.string(),
  type: z.string().nullish(),
  customerBankAccount: customerBankAccountSchema.nullish(),
  customerCard: customerCardSchema.nullish(),
  hasUnmatchedCardTransactions: z.boolean(),
  markAsPaidOption: optionSchema.nullish(),
  achOption: optionSchema.nullish(),
  vendorBankAccount: vendorBankAccountSchema.nullish(),
  isCreditCard: z.boolean(),
  isPaymentAccount: z.boolean().nullish(),
  accountOwner: plaidAccountOwnerBasicSchema.nullish(),
  paymentMethods: arraySchema(
    z.object({
      default: z.boolean().nullish(),
      customerBankAccount: customerBankAccountSchema.nullish(),
      customerCard: customerCardSchema.nullish(),
      paymentMethod: paymentMethodEnumSchema,
      customerPaymentAccount: z
        .object({ url: z.string().url().nullish() })
        .nullish(),
      vendorBankAccount: z
        .object({ url: z.string().url().nullish() })
        .nullish(),
      processPrerequisites: arraySchema(
        z.object({
          type: z.string(),
        })
      ),
      paymentSchedules: arraySchema(paymentScheduleSchema),
    })
  ),
});

export const dataBankAccountSchema = z.object({
  accounts: arraySchema(bankAccountOptionSchema),
  vendor: billPaymentVendorSchema,
  vendorCredits: arraySchema(vendorCreditPaymentOptionSchema),
});
