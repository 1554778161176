import React from "react";
import { Flex, type TableColumn, Tag } from "@adaptive/design-system";

import type { CardTransaction } from "../api/types";

import {
  Actions,
  Amount,
  Description,
  LinkedCard,
  Match,
  Status,
  TransactionDate,
  Vendor,
} from "./misc";
import type { GetColumnsHandler } from "./types";

export const getColumns: GetColumnsHandler = ({
  match,
  status,
  capitalOsEnabled,
}) => {
  const columns: TableColumn<CardTransaction>[] = [
    {
      id: "transaction_date",
      sortable: true,
      width: 175,
      name: "Transaction date",
      visibility: "always-visible",
      render: (row) => <TransactionDate {...row} />,
    },
    {
      id: "vendor",
      sortable: "asc",
      minWidth: 170,
      name: "Vendor name",
      render: (row) => <Vendor {...row} />,
    },
    {
      id: "description",
      minWidth: 200,
      width: "fill",
      sortable: "asc",
      name: "Description",
      render: (row) => <Description {...row} />,
    },
    {
      id: "linked_card",
      width: 200,
      name: "Linked card",
      visibility: "always-visible",
      render: (row) => <LinkedCard {...row} />,
    },
    {
      id: "user",
      sortable: "asc",
      name: "Card holder",
      minWidth: 170,
      render: (row) => (row.user ? <Tag>{row.user.fullName}</Tag> : null),
    },
    {
      id: "amount",
      sortable: true,
      name: "Amount",
      width: 150,
      textAlign: "right",
      render: (row) => <Amount {...row} />,
    },
  ];

  if (status) {
    columns.push({
      id: "status",
      name: "Status",
      width: 150,
      render: (row) => <Status {...row} />,
    });
  }

  columns.push({
    id: "payment_account",
    name: "Payment account",
    width: 250,
    sortable: "asc",
    visibility: "hidden",
    render: (row) =>
      row.paymentAccount?.displayName ? (
        <Flex width="full">
          <Tag>{row.paymentAccount?.displayName}</Tag>
        </Flex>
      ) : null,
  });

  if (match) {
    columns.push({
      id: "match",
      name: "Matched cost",
      width: 220,
      render: (row) => <Match {...row} />,
    });
  }

  columns.push({
    id: "actions",
    name: "Actions",
    width: capitalOsEnabled ? 265 : 225,
    render: (row) => <Actions {...row} />,
    visibility: "always-visible",
  });

  return columns;
};
