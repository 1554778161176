import React, { useEffect, useReducer, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogStep,
  Flex,
  Icon,
  Loader,
  Tabs,
  TabsList,
  TabsPanel,
  TabsTab,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useEvent, useMultiStepDialog } from "@adaptive/design-system/hooks";
import { suffixify } from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import { captureMessage } from "@sentry/react";
import {
  useAddBudgetLineMarkupsMutation,
  useGetBudgetLinesQuery,
} from "@shared/api/budgets";
import {
  useLazyGetChangesQuery,
  useUpdateChangeMutation,
} from "@shared/api/jobs/changes";
import {
  MARKUP_PERCENTAGE_FORMAT,
  useJobPermissions,
  useJobSettings,
} from "@src/jobs";
import { useJobInfo } from "@store/jobs";
import { getItemUrls } from "@utils/get-cost-code-account-values";

import {
  MarkupPercentageForm,
  type MarkupPercentageFormProps,
} from "../../markup";

import { AmountForm } from "./amount-form";
import type { ManageMarkupDialogProps, MarkupDialogSteps } from "./types";

const FORM_ID = "budgets-add-markup";

export const AddMarkupDialog = ({ dialog }: ManageMarkupDialogProps) => {
  const [tab, setTab] = useState("percentage");
  const [isLoading, setIsLoading] = useState(false);
  const [addBudgetLineMarkups] = useAddBudgetLineMarkupsMutation();
  const { job } = useJobInfo();
  const { ownersAmountEnabled } = useJobSettings();
  const { budgetLines } = useGetBudgetLinesQuery(
    { customerId: job.id },
    {
      skip: !dialog.isRendered,
      selectFromResult: ({ data }) => ({
        budgetLines: (data ?? []).map((line) => ({
          amount: ownersAmountEnabled ? line.ownersAmount : line.builderAmount,
          ...line,
        })),
      }),
    }
  );
  const [isValid, setIsValid] = useState(false);
  const [updateChange] = useUpdateChangeMutation();
  const [trigger] = useLazyGetChangesQuery();

  const [formKey, updateFormKey] = useReducer((key) => key + 1, 0);

  useEffect(() => {
    setTab("percentage");
    updateFormKey();
  }, [dialog.isVisible]);

  const onSubmitPercentage = useEvent<MarkupPercentageFormProps["onSubmit"]>(
    async (values, e) => {
      const submitter = (e.nativeEvent as SubmitEvent)
        .submitter as HTMLButtonElement;

      const linesWithChanges = values.lines.filter(
        (line) => "hasChanges" in line && line.hasChanges
      );

      if (linesWithChanges.length && !submitter.value) {
        return dialog.setStep("change-markup-confirmation");
      }

      try {
        setIsLoading(true);

        const valueKey = ownersAmountEnabled
          ? "ownersValue"
          : ("value" as const);
        await addBudgetLineMarkups({
          [valueKey]: values.value,
          ...getItemUrls(values.costCode),
          budgetLineIds: values.lines.map((line) => line.id),
          markupType: "percentage",
          isSeparateLine: true,
          customer: job.url,
        }).unwrap();

        if (submitter.value === "with-changes") {
          await Promise.all(
            linesWithChanges.map(async (line) => {
              const limit = 100;
              const { data } = await trigger({
                customerId: job.id,
                budgetLineId: line.id,
                limit,
              });

              if (data?.next) {
                captureMessage(
                  `There's more than ${limit} results on job ${job.id} for budget line ${line.id}`,
                  { level: "info" }
                );
              }

              await Promise.all(
                (data?.results ?? []).map((change) => {
                  const existingMarkupIndex = change.markups.findIndex(
                    (markup) =>
                      markup.markupType === "percentage" &&
                      markup.jobCostMethod.url === values.costCode
                  );
                  const markups: Parameters<typeof updateChange>[0]["markups"] =
                    change.markups.map((markup) => ({
                      id: markup.id,
                      markupType: markup.markupType,
                      jobCostMethod: markup.jobCostMethod.url,
                      changeLineJobCostMethods: markup.changeLineJobCostMethods,
                      value: markup[valueKey],
                    }));

                  if (existingMarkupIndex > -1) {
                    // Existing markup -> update
                    markups[existingMarkupIndex] = {
                      ...markups[existingMarkupIndex],
                      value: values.value,
                    };
                  } else {
                    // New markup -> add
                    markups.push({
                      markupType: "percentage",
                      value: values.value,
                      jobCostMethod: values.costCode,
                      changeLineJobCostMethods: change.lines
                        .filter((line) =>
                          values.lines
                            .map(({ jobCostMethod }) => jobCostMethod?.id)
                            .includes(line.jobCostMethod.id)
                        )
                        .map((line) => line.jobCostMethod.url),
                    });
                  }

                  return updateChange({
                    ...change,
                    customerId: job.id,
                    changeId: change.id,
                    markups,
                    lines: change.lines.map((line) => ({
                      ...line,
                      amount: line.builderAmount,
                      jobCostMethod: line.jobCostMethod.url,
                    })),
                  });
                })
              );
            })
          );
        }

        const budgetLine = budgetLines.find(
          (line) => line.jobCostMethod.url === values.costCode
        );
        if (budgetLine) {
          toast.success(
            `"${budgetLine.jobCostMethod.displayName}" converted to an auto-calculated percent markup`
          );
        } else {
          toast.success("Markup added to the budget");
        }
        setIsLoading(false);
        dialog.hide();
      } catch (e) {
        handleErrors(e);
        throw e;
      }
    }
  );

  return (
    <>
      {dialog.isRendered && (
        <Dialog
          show={dialog.isVisible}
          variant="multi-step-dialog"
          step={dialog.step}
          onClose={dialog.hide}
          size="auto"
        >
          <DialogStep name="markup-form">
            <DialogHeader>Add budget markup</DialogHeader>
            <DialogContent>
              <Tabs value={tab} onChange={setTab}>
                <TabsList>
                  <TabsTab value="percentage">Percent</TabsTab>
                  <TabsTab value="cost">Amount</TabsTab>
                </TabsList>
                <TabsPanel
                  as={Flex}
                  direction="column"
                  height="444px"
                  padding={["2xl", "none", "none", "none"]}
                >
                  {tab === "percentage" && (
                    <MarkupPercentageForm
                      key={suffixify(formKey, "percentage")}
                      allowedType="separate-line"
                      lines={budgetLines}
                      formId={FORM_ID}
                      onSubmit={onSubmitPercentage}
                      onValidityChange={setIsValid}
                      percentageFormat={MARKUP_PERCENTAGE_FORMAT}
                    />
                  )}
                  {tab === "cost" && (
                    <AmountForm
                      key={suffixify(formKey, "amount")}
                      formId={FORM_ID}
                      onFormChange={setIsValid}
                      onClose={dialog.hide}
                    />
                  )}
                </TabsPanel>
              </Tabs>
            </DialogContent>
            <DialogFooter>
              <Flex width="full">
                <Button
                  block
                  color="neutral"
                  onClick={dialog.hide}
                  variant="text"
                >
                  Cancel
                </Button>
              </Flex>
              <Tooltip
                as={Flex}
                width="full"
                message={
                  !isValid
                    ? "Select items from the table before saving markup"
                    : undefined
                }
                placement="left"
              >
                <Button block disabled={!isValid} type="submit" form={FORM_ID}>
                  Save
                </Button>
              </Tooltip>
            </DialogFooter>
          </DialogStep>
          <DialogStep name="change-markup-confirmation" onBack={dialog.back}>
            <DialogHeader>
              <Text size="xl" weight="bold" align="center">
                You&apos;ve added markup to items
                <br />
                with existing change orders
              </Text>
            </DialogHeader>
            <DialogContent>
              <Text align="center">
                Do you want to update those change orders with the new markup?
                <br />
                (Any existing change order markup will be replaced.)
              </Text>
            </DialogContent>
            <DialogFooter>
              <Flex width="full">
                <Button
                  block
                  color="neutral"
                  variant="text"
                  type="submit"
                  form={FORM_ID}
                  value="no-changes"
                  disabled={isLoading}
                >
                  No, don&apos;t apply markup
                </Button>
              </Flex>
              <Flex width="full">
                <Button
                  block
                  type="submit"
                  form={FORM_ID}
                  value="with-changes"
                  disabled={isLoading}
                >
                  {isLoading ? <Loader /> : "Yes, update changes"}
                </Button>
              </Flex>
            </DialogFooter>
          </DialogStep>
        </Dialog>
      )}
    </>
  );
};

export const AddMarkupLineButton = () => {
  const { canManage, budgetReadOnly } = useJobPermissions();

  const addMarkupDialog = useMultiStepDialog<MarkupDialogSteps>({
    initialStep: "markup-form",
    lazy: true,
  });

  return (
    <>
      {addMarkupDialog.isRendered && (
        <AddMarkupDialog dialog={addMarkupDialog} />
      )}
      <Tooltip
        message={
          !canManage
            ? "You don't have permission to do this"
            : budgetReadOnly
              ? "This job is linked to a BuilderTrend job. Manage changes in BuilderTrend"
              : ""
        }
        placement="right"
      >
        <Button
          disabled={!canManage || budgetReadOnly}
          size="sm"
          variant="ghost"
          onClick={addMarkupDialog.show}
          data-testid="budget-add-markup-button"
        >
          <Icon name="plus" />
          Add markup
        </Button>
      </Tooltip>
    </>
  );
};
