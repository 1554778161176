import React from "react";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Flex,
  Text,
} from "@adaptive/design-system";

import { STRINGS } from "../constants";
import { type MissingLienWaiverFieldsInfo as MissingLienWaiverFieldsInfoType } from "../types";

import { MissingLienWaiverFieldsInfo } from "./missing-lien-waiver-fields-info";

type LienWaiversMissingFields = Record<string, MissingLienWaiverFieldsInfoType>;

type AlertMissingFieldsProps = {
  lienWaiverMissingFields: LienWaiversMissingFields;
};

export const AlertMissingFields = ({
  lienWaiverMissingFields,
}: AlertMissingFieldsProps) => {
  return (
    <Alert
      variant={{
        icon: "exclamation-circle",
        iconColor: "warning-200",
        background: "warning-100",
      }}
    >
      <AlertTitle>{STRINGS.REQUIRED_LIEN_WAIVER_DATA_TITLE}</AlertTitle>
      <AlertContent as={Flex} direction="column" gap="lg">
        <Text>{STRINGS.FILL_OUT_LW_INFO_TITLE}</Text>
        <Flex direction="column" gap="md">
          {Object.entries(lienWaiverMissingFields).map(
            ([key, missingField]) => (
              <MissingLienWaiverFieldsInfo
                key={key}
                frontendUrl={missingField.frontendUrl || undefined}
                objectId={missingField.id!}
                displayName={missingField.displayName!}
                missingFields={missingField.missingFields}
              />
            )
          )}
        </Flex>
      </AlertContent>
    </Alert>
  );
};
