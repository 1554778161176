import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Link as ReactRouterLink,
  useNavigate,
  useSearchParams,
} from "react-router";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  Copy,
  Flex,
  Link,
  Loader,
  type TablePaginationAddon,
  Tabs,
  TabsList,
  TabsPanel,
  TabsTab,
  Text,
  toast,
  VisuallyHidden,
} from "@adaptive/design-system";
import {
  useDeepMemo,
  useEvent,
  useLocalStorageState,
} from "@adaptive/design-system/hooks";
import { isEqual } from "@adaptive/design-system/utils";
import { type BillListItemResponse, getBills } from "@api/bills";
import { CounterLabel } from "@components/counter-label";
import {
  Main,
  MainContent,
  MainHeader,
  MainSubtitle,
  MainTitle,
} from "@components/main";
import {
  defaultArrayFormatter,
  type QueryItem,
} from "@components/table-filter/formatters";
import { type StrictValuesFilters } from "@components/table-filter/types";
import { useTableFilters } from "@shared/components/table-filter/table-filter-hooks";
import { DEFAULT_PAGINATION_LIMIT } from "@shared/constants";
import {
  fetchBillsByStatus,
  updateContextualFilter,
  updateFilter,
  updateLimit,
  updateOffset,
  updateOrder,
  updateRequiresMyApproval,
} from "@shared/store/billListSlice";
import { useAppDispatch, useAppSelector } from "@shared/store/hooks";
import { resetBill } from "@store/billSlice";
import {
  BasePermissions,
  useClientInfo,
  useClientSettings,
  useUserInfo,
} from "@store/user";
import * as analytics from "@utils/analytics";
import { scrollMainTop } from "@utils/scroll-main-top";

import { PaymentFloatingBar } from "./components/payment-floating-bar";
import { Table, type TableProps } from "./components/table";
import { BILL_STATUS, STATUS_FILTER, TAB_STATUS } from "./constants";
import type { QueryFilters, Status } from "./types";
import {
  billListFilterV2Selector,
  getBillConfigByStatus,
  getBillsByStatus,
  getOrderingByStatus,
  isValidStatus,
  sortQuery,
} from "./utils";

const CONTEXTUAL_FILTERS = [
  "review_status",
  "is_rejected",
  "archived",
  "requires_user_approval",
];

const formatter: typeof defaultArrayFormatter = (realmId, selections) => {
  const filters = defaultArrayFormatter(realmId, selections);
  const containsIsRejected = filters.some(
    (item) => item.dataIndex === "status" && item.value === "rejected"
  );
  const containsIsNotRejected = filters.some(
    (item) => item.dataIndex === "status" && item.value === "not_rejected"
  );

  return defaultArrayFormatter(realmId, selections).reduce((acc, filter) => {
    if (filter.dataIndex === "status") {
      if (["rejected", "not_rejected"].includes(filter.value as string)) {
        return containsIsRejected && containsIsNotRejected
          ? acc
          : [
              ...acc,
              { dataIndex: "is_rejected", value: filter.value === "rejected" },
            ];
      } else if (filter.value === "archived") {
        return [...acc, { dataIndex: "archived", value: true }];
      } else if (filter.value === "PAYMENT_SCHEDULED") {
        return [
          ...acc,
          { dataIndex: "review_status", value: "PAYMENT_SCHEDULED" },
          { dataIndex: "review_status", value: "ACH_INFO_REQUESTED" },
        ];
      } else {
        return [...acc, { dataIndex: "review_status", value: filter.value }];
      }
    }
    if (filter.dataIndex === "approval_required_from") {
      return [
        ...acc,
        { dataIndex: "requires_user_approval", value: filter.value },
      ];
    }

    return [...acc, filter];
  }, [] as QueryItem[]);
};

/**
 * @todo The way we current fetch was hard to understand and also enhance
 * with new features. At some point we need to refactoring it and make
 * it better.
 */
export const Bills = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [stateLimit, setStateLimit] = useLocalStorageState(
    "bills-table-limit",
    DEFAULT_PAGINATION_LIMIT
  );

  const [requiresYourApprovalStoredState, setRequiresYourApprovalStoredState] =
    useLocalStorageState("bills-table-requires-my-approval", false);

  const isSyncedStoredFiltersRef = useRef(false);

  /**
   * With these flags we know if we already fetch something before
   * to prevent some unnecessary fetches
   */
  const queryRef = useRef<
    Record<Status, { fetched: boolean; filter: QueryFilters }>
  >({
    all: {
      filter: [],
      fetched: false,
    },
    draft: {
      filter: [],
      fetched: false,
    },
    approval: {
      filter: [],
      fetched: false,
    },
    "for-payment": {
      filter: [],
      fetched: false,
    },
  });

  const tableFilters = useTableFilters({
    formatter,
    storageKey: "bills-filters",
  });

  const { hasPermission } = useUserInfo();

  const [hasErrors, setHasErrors] = useState(false);

  const [selectedRows, setSelectedRows] = useState<BillListItemResponse[]>([]);

  const [searchParams, setSearchParams] = useSearchParams({ status: "draft" });

  const {
    canManageLienWaivers,
    paymentsRevampEnabled,
    combinedPaymentsEnabled,
  } = useClientSettings();

  const { client: currentClient, realmId } = useClientInfo();

  const currentStatus = useMemo<Status>(() => {
    const status = searchParams.get("status");
    return isValidStatus(status) ? status : "draft";
  }, [searchParams]);

  const [previousClientId, setPreviousClientId] = useState(currentClient?.id);

  const { filter, billList } = useAppSelector(billListFilterV2Selector);

  const currentBillList = getBillsByStatus(billList, currentStatus);

  const currentFilter = useDeepMemo(
    () => ({
      query: [...filter.query, ...currentBillList.filter.query],
      values: { ...filter.values, ...currentBillList.filter.values },
    }),
    [filter, currentBillList.filter]
  );

  const pagination = useMemo(() => {
    const offset = Number(
      currentFilter.query.find((item) => item.dataIndex === "offset")?.value ??
        0
    );

    let limit = Number(
      currentFilter.query.find((item) => item.dataIndex === "limit")?.value ??
        DEFAULT_PAGINATION_LIMIT
    );

    if (stateLimit !== limit) {
      dispatch(updateLimit(stateLimit));
      limit = stateLimit;
    }

    const page = offset / limit;

    return {
      page,
      limit,
      offset,
      total: currentBillList.total,
      perPage: limit,
      perPageVariant: "lg" as TablePaginationAddon["perPageVariant"],
      maxCount: window.MAX_COUNT_PAGINATION_LIMIT,
      onChange: (page: number, status = currentStatus) => {
        scrollMainTop(0);
        dispatch(updateOffset({ status, value: page * limit }));
      },
      onPerPageChange: (perPage: number) => {
        dispatch(updateLimit(perPage));
        setStateLimit(perPage);
        analytics.track("perPageLimitChange", {
          location: "bills-table",
          limit: perPage,
        });
      },
    };
  }, [
    currentFilter.query,
    stateLimit,
    currentBillList.total,
    currentStatus,
    dispatch,
    setStateLimit,
  ]);

  const onOrderChange = useEvent((value: string) => {
    dispatch(updateOrder(value));
    pagination.onChange(0);
  });

  const order = useMemo(() => {
    const value = currentFilter.query.find(
      (item) => item.dataIndex === "ordering"
    )?.value as string;

    return {
      value: value ? value : getOrderingByStatus(currentStatus),
      onChange: onOrderChange,
    };
  }, [onOrderChange, currentStatus, currentFilter.query]);

  const canAddBill = useMemo(
    () => hasPermission(BasePermissions.ADD_BILL),
    [hasPermission]
  );

  const canPayBills = hasPermission(BasePermissions.PAY_BILLS);

  const rowRender = useCallback<TableProps["rowRender"]>(
    ({ row, props }) => {
      if (
        row.fileSyncStatus &&
        [
          "done",
          "failed",
          "timeout",
          "discarded",
          "none",
          "not-applied",
        ].includes(row.fileSyncStatus)
      ) {
        return (
          <ReactRouterLink
            {...props}
            to={`/bills/${row.id}?status=${currentStatus}`}
            state={{ prev: window.location.pathname + window.location.search }}
          >
            <VisuallyHidden>Open #{row.docNumber}</VisuallyHidden>
          </ReactRouterLink>
        );
      }

      return (
        <button
          {...props}
          onClick={() =>
            toast.warning(
              "Our AI is still working! Please wait a few seconds for it to finish"
            )
          }
        >
          <VisuallyHidden>Open #{row.docNumber}</VisuallyHidden>
        </button>
      );
    },
    [currentStatus]
  );

  const onTabChange = useEvent((status) => {
    if (status === searchParams.get("status")) return;

    setSearchParams({ status });
    pagination.onChange(0, status);
  });

  const requiresYourApproval =
    getBillsByStatus(billList, "approval").filter.query.find(
      (item) => item.dataIndex === "requires_my_approval"
    )?.value === true;

  const onRequiresYourApprovalChange = setRequiresYourApprovalStoredState;

  useEffect(() => {
    if (requiresYourApprovalStoredState !== requiresYourApproval) {
      dispatch(updateRequiresMyApproval(requiresYourApprovalStoredState));
    }
  }, [dispatch, requiresYourApproval, requiresYourApprovalStoredState]);

  const onFiltersChange = useEvent((filters, status = currentStatus) => {
    const nextTableFilters = tableFilters.setFilters(filters);
    const contextualQuery = nextTableFilters.filters.filter((item) =>
      CONTEXTUAL_FILTERS.includes(item.dataIndex)
    );

    const query = nextTableFilters.filters.filter(
      (item) => !CONTEXTUAL_FILTERS.includes(item.dataIndex)
    );

    const contextualValues = Object.entries(nextTableFilters.rawFilters).reduce(
      (acc, [key, value]) => {
        const isContextualFilter = [
          "Status",
          "Approval required from",
          "Lien waiver status",
          "Lien waiver type",
        ].includes(value.groupLabel);
        return !isContextualFilter ? acc : { ...acc, [key]: value };
      },
      {} as StrictValuesFilters
    );

    const values = Object.entries(nextTableFilters.rawFilters).reduce(
      (acc, [key, value]) => {
        const isContextualFilter = [
          "Status",
          "Approval required from",
          "Lien waiver status",
          "Lien waiver type",
        ].includes(value.groupLabel);
        return isContextualFilter ? acc : { ...acc, [key]: value };
      },
      {} as StrictValuesFilters
    );

    dispatch(updateFilter({ values, query }));
    dispatch(
      updateContextualFilter({
        query: contextualQuery,
        values: contextualValues,
        status,
      })
    );
    pagination.onChange(0);
  });

  const showSyncErrors = useEvent(() => {
    navigate("/bills?status=all");
    onFiltersChange(
      { errors: STATUS_FILTER.find((filter) => filter.value === "errors") },
      "all"
    );
  });

  const onDrop = useEvent(() => {
    setSearchParams({ status: "draft" });
    pagination.onChange(0);
  });

  const emptyStateAction = useMemo(
    () =>
      canAddBill ? (
        <Button
          as={ReactRouterLink}
          to="/bills/create"
          state={{ prev: window.location.pathname + window.location.search }}
          data-testid="table-new-bill-button"
        >
          Create new bill
        </Button>
      ) : undefined,
    [canAddBill]
  );

  const billColumns = useMemo(() => {
    const { columns, status } = getBillConfigByStatus(currentStatus);

    const enhancedColumns = !canManageLienWaivers
      ? columns.filter((column) => column.id !== "lien_waivers")
      : columns;

    return { columns: enhancedColumns, status };
  }, [canManageLienWaivers, currentStatus]);

  const getTableProps = () => {
    const { columns, status } = billColumns;

    let tableProps: TableProps = {
      data: currentBillList.bills as BillListItemResponse[],
      order,
      filter: currentFilter,
      status,
      onDrop,
      loading: currentBillList.loading === "pending",
      isError: currentBillList.isError,
      columns,
      onSelect: setSelectedRows,
      droppable: canAddBill,
      rowRender,
      pagination,
      selectedRows,
      onFiltersChange,
      emptyStateAction,
      onGetBillsWithErrors: getBillsWithErrors,
    };

    if (currentStatus === "approval") {
      tableProps = {
        ...tableProps,
        requiresYourApproval,
        onRequiresYourApprovalChange,
      };
    }

    return tableProps;
  };

  const selectedRowsForPayment = useMemo(
    () =>
      selectedRows.filter((row) =>
        [BILL_STATUS.FOR_PAYMENT, BILL_STATUS.PARTIALLY_PAID].some(
          (status) => status === row.reviewStatus
        )
      ),
    [selectedRows]
  );

  useEffect(() => {
    const hasNewFilters = !isEqual(
      sortQuery(queryRef.current[currentStatus].filter),
      sortQuery(currentFilter.query)
    );

    if (!hasNewFilters) return;

    dispatch(
      fetchBillsByStatus({
        status: currentStatus,
        filters: currentFilter.query,
      })
    );
    queryRef.current[currentStatus] = {
      filter: currentFilter.query,
      fetched: true,
    };
  }, [dispatch, currentFilter.query, currentStatus]);

  useEffect(() => {
    Object.values(TAB_STATUS).forEach((status) => {
      if (status === currentStatus) return;

      const enhancedQuery = [
        ...filter.query,
        ...getBillsByStatus(billList, status).filter.query,
      ];
      const previousQuery = queryRef.current[status].filter;

      const hasNewFilters = !isEqual(
        sortQuery(previousQuery),
        sortQuery(enhancedQuery)
      );

      if (!hasNewFilters && queryRef.current[status].fetched) return;

      dispatch(fetchBillsByStatus({ status, filters: enhancedQuery }));
      queryRef.current[status] = { filter: enhancedQuery, fetched: true };
    });
  }, [dispatch, filter, currentStatus, requiresYourApproval, billList]);

  /**
   * We do it to guarantee that we always reset bill state
   */
  useEffect(() => {
    dispatch(resetBill());
  }, [dispatch]);

  useEffect(() => {
    setSelectedRows([]);
    setSearchParams(
      (previousSearchParams) => {
        if (!isValidStatus(previousSearchParams.get("status"))) {
          previousSearchParams.set("status", "draft");
        }

        return previousSearchParams;
      },
      { replace: true }
    );
  }, [currentFilter.query, setSearchParams, currentStatus]);

  useEffect(() => {
    if (!currentClient) return;
    const hasChangedClient = currentClient.id != previousClientId;

    if (hasChangedClient) {
      isSyncedStoredFiltersRef.current = false;
      onFiltersChange({});
      onRequiresYourApprovalChange(false);
      setPreviousClientId(currentClient.id);
    }
  }, [
    currentClient,
    onFiltersChange,
    previousClientId,
    onRequiresYourApprovalChange,
  ]);

  const getBillsWithErrors = useCallback(() => {
    getBills({
      signal: undefined,
      filters: [
        { dataIndex: "realm", value: realmId },
        { dataIndex: "archived", value: false },
        { dataIndex: "review_status", value: "errors" },
      ],
    }).then((response) => setHasErrors(response?.count > 0));
  }, [realmId]);

  const onRemoveBillForPayment = useEvent((billId: string) => {
    setSelectedRows((rows) => rows.filter((row) => row.id !== billId));
  });

  /**
   * We unfortunately need to do this because we need to sync table filter component
   * filters with bills redux store filters. @todo We need to find a better way to do
   * this in the future, probably refactoring the whole bills component to follow the
   * same approach as we use in the rest of our app.
   */
  useEffect(() => {
    if (isSyncedStoredFiltersRef.current) return;

    isSyncedStoredFiltersRef.current = true;
    requestAnimationFrame(() => onFiltersChange(tableFilters.rawFilters));
  }, [tableFilters.rawFilters, onFiltersChange]);

  useEffect(() => {
    getBillsWithErrors();
  }, [getBillsWithErrors]);

  if (!realmId) return <Loader />;

  return (
    <Main>
      <MainHeader>
        <Flex align="center" height="full" gap="xl">
          <Flex direction="column" grow>
            <MainTitle>Bills</MainTitle>
            {currentClient?.email && canAddBill ? (
              <MainSubtitle>
                <Flex gap="sm" wrap align="baseline">
                  New bills? Drag and drop them in this window to upload or
                  email
                  <Flex gap="sm" align="center" height="xl">
                    <Text
                      as={Link}
                      truncate
                      href={`mailto:${currentClient.email}`}
                      variant="success"
                      data-testid="email-link"
                      data-skip-focusable=""
                    >
                      {currentClient.email}
                    </Text>
                    <Copy
                      size="sm"
                      color="brand-2"
                      value={currentClient.email}
                      data-testid="copy-email-button"
                      data-skip-focusable=""
                    />
                  </Flex>
                </Flex>
              </MainSubtitle>
            ) : null}
          </Flex>
          {canAddBill ? (
            <Button
              as={ReactRouterLink}
              to="/bills/create"
              state={{
                prev: window.location.pathname + window.location.search,
              }}
              data-testid="new-bill-button"
            >
              New bill
            </Button>
          ) : null}
        </Flex>
      </MainHeader>

      <MainContent>
        <Flex
          gap="xl"
          shrink={false}
          style={{
            /* We need to this calc to compensate margin from MainContent */
            minHeight: "calc(var(--spacing-full) - var(--spacing-4xl))",
          }}
          direction="column"
        >
          {hasErrors && (
            <Alert variant="warning">
              <AlertTitle>
                Some of your bills did not sync with QuickBooks
              </AlertTitle>
              <AlertContent>
                <Link as="button" type="button" onClick={showSyncErrors}>
                  View bills with sync errors
                </Link>
              </AlertContent>
            </Alert>
          )}
          <Flex minHeight="full" direction="column">
            <Tabs value={currentStatus} onChange={onTabChange}>
              <TabsList>
                <TabsTab value="draft">
                  <CounterLabel
                    label="Inbox"
                    counter={billList.draftBills.total}
                    loading={
                      !billList.draftBills?.loading ||
                      billList.draftBills.loading !== "loaded"
                    }
                    active={currentStatus === "draft"}
                  />
                </TabsTab>
                <TabsTab value="approval">
                  <CounterLabel
                    label="Waiting for approval"
                    counter={billList.approvalBills.total}
                    loading={
                      !billList.approvalBills?.loading ||
                      billList.approvalBills.loading !== "loaded"
                    }
                    active={currentStatus === "approval"}
                  />
                </TabsTab>
                <TabsTab value="for-payment">
                  <CounterLabel
                    label="Waiting for payment "
                    counter={billList.forPaymentBills.total}
                    loading={
                      !billList.forPaymentBills?.loading ||
                      billList.forPaymentBills.loading !== "loaded"
                    }
                    active={currentStatus === "for-payment"}
                  />
                </TabsTab>
                <TabsTab value="all">All</TabsTab>
              </TabsList>
              <TabsPanel>
                <Table {...getTableProps()} />
                {paymentsRevampEnabled &&
                  canPayBills &&
                  combinedPaymentsEnabled && (
                    <PaymentFloatingBar
                      selectedRows={selectedRowsForPayment}
                      currentStatus={currentStatus}
                      onRemoveBill={onRemoveBillForPayment}
                      setSelectedRows={setSelectedRows}
                    />
                  )}
              </TabsPanel>
            </Tabs>
          </Flex>
        </Flex>
      </MainContent>
    </Main>
  );
};
