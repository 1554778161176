import React, { useCallback, useMemo } from "react";
import {
  Table as DSTable,
  type TableEmptyState,
  type TableHeaderAddon,
  type TablePaginationAddon,
  type TableRowAddon,
  type TableSortAddon,
  VisuallyHidden,
} from "@adaptive/design-system";
import { omit } from "@adaptive/design-system/utils";
import { BILL_PAYMENTS_TABS } from "@bill-payment/constants";
import { useViewBillPayment } from "@bill-payment/hooks";
import { type BillPaymentListingV2 } from "@bill-payment/types";
import * as analytics from "@utils/analytics";

import {
  useActions,
  useCurrentTab,
  useHasFilters,
  useOrder,
  usePaymentsTablePagination,
  useTabAwareBillPayments,
  useTableOffset,
} from "./context";
import { getColumns } from "./table-columns";

export const BillPaymentsTable = () => {
  const order = useOrder();
  const currentTab = useCurrentTab();
  const tableOffset = useTableOffset();
  const hasFilters = useHasFilters();

  const { data, count, isLoading, isError } = useTabAwareBillPayments();

  const { setOrder, clearFilters } = useActions();

  const hasData = data.length > 0;

  const header = useMemo<TableHeaderAddon>(
    () => ({ hide: !hasData, sticky: { offset: tableOffset } }),
    [tableOffset, hasData]
  );

  const columns = useMemo(() => getColumns({ currentTab }), [currentTab]);

  const paginationContext = usePaymentsTablePagination();

  const emptyState = useMemo<TableEmptyState>(() => {
    if (isError) return "error";

    if (hasFilters) {
      return {
        title: "No payments match your filters",
        subtitle:
          "Try adjusting your search or filters to find what you're looking for",
        action: {
          children: "Clear filters",
          onClick: clearFilters,
        },
      };
    }

    if (currentTab === BILL_PAYMENTS_TABS.SCHEDULED) {
      return {
        title: "No scheduled payments found",
      };
    }
    if (currentTab === BILL_PAYMENTS_TABS.IN_PROGRESS) {
      return {
        title: "No in-progress payments found",
      };
    }

    return {
      title: "You haven't made any payments yet",
    };
  }, [isError, hasFilters, currentTab, clearFilters]);

  const sort = useMemo<TableSortAddon>(
    () => ({ value: order, onChange: setOrder }),
    [order, setOrder]
  );

  const pagination = useMemo<TablePaginationAddon>(
    () => ({
      ...omit(paginationContext, ["setPage", "setPerPage"]),
      total: count,
      onChange: paginationContext.setPage,
      onPerPageChange: (perPage) => {
        paginationContext.setPage(0);
        paginationContext.setPerPage(perPage);
        analytics.track("perPageLimitChange", {
          location: "bill-payments-table",
          limit: perPage,
        });
      },
    }),
    [count, paginationContext]
  );

  const { onViewBillPayment } = useViewBillPayment();

  const rowRender = useCallback<
    Exclude<TableRowAddon<BillPaymentListingV2>["render"], undefined>
  >(
    ({ row, props }) => {
      return (
        <button
          {...props}
          type="button"
          onClick={() => onViewBillPayment(row.id)}
        >
          <VisuallyHidden>Open payment #{row.docNumber}</VisuallyHidden>
        </button>
      );
    },
    [onViewBillPayment]
  );

  const row = useMemo(() => ({ render: rowRender }), [rowRender]);

  return (
    <DSTable
      size="sm"
      sort={sort}
      data={data}
      header={header}
      loading={isLoading}
      columns={columns}
      row={row}
      pagination={pagination}
      emptyState={emptyState}
    />
  );
};
