import { handleErrors } from "@api/handle-errors";
import { type z } from "zod";

export const handleResponse = <Response>(
  response: Response,
  schema: z.ZodSchema
) => {
  try {
    return schema.parse(response);
  } catch (error: unknown) {
    handleErrors(error);
    return response;
  }
};

export const handleRequest = <Request, Schema extends z.ZodSchema>(
  request: Request,
  schema: Schema
) => {
  return schema.parse(request) as z.infer<Schema>;
};
