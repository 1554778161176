import { createSlice, type Selector } from "@reduxjs/toolkit";
import type { WorkflowState } from "@shared/api/workflows";

import type { RootState } from "../types";

const initialState: WorkflowState = {
  workflows: [],
  defaultWorkflow: null,
  currentWorkflowApprovals: {
    id: "",
    objectId: null,
    objectType: null,
    steps: [{ requirementType: "ALL_OF", approvers: [] }],
  },
  currentWorkflow: {
    id: "",
    title: "",
    isDefault: false,
    steps: [{ requirementType: "ALL_OF", approvers: [] }],
    amountFrom: null,
    amountTo: null,
    budgetExceededBy: null,
    customerRequirementType: "ONE_OF",
    customersGroupUnderParent: false,
    customers: [],
    accounts: [],
    items: [],
    vendors: [],
    types: ["bill"],
  },
  duplicateWorkflow: false,
};
export const workflowsSelector: Selector<RootState, WorkflowState> = (state) =>
  state.workflow;

const workflowSlice = createSlice({
  name: "workflow",
  initialState,
  reducers: {
    updateWorkflows: (state, action) => {
      state.workflows = action.payload;
    },
    updateDefaultWorkflow: (state, action) => {
      state.defaultWorkflow = action.payload;
    },
    updateWorkflow: (state, action) => {
      const workflowIndex = state.workflows.findIndex(
        (workflow) => workflow.id === action.payload.id
      );

      if (workflowIndex > -1) {
        state.workflows[workflowIndex] = action.payload;
      } else {
        state.workflows = [action.payload, ...state.workflows];
      }
    },
    resetCurrentWorkflow: (state) => {
      state.currentWorkflow = initialState.currentWorkflow;
      state.duplicateWorkflow = false;
    },
    newDefaultWorkflow: (state) => {
      state.duplicateWorkflow = false;
      state.currentWorkflow = initialState.currentWorkflow;
      state.currentWorkflow.isDefault = true;
    },
    editDefaultWorkflow: (state) => {
      state.duplicateWorkflow = false;
      state.currentWorkflow = state.defaultWorkflow
        ? state.defaultWorkflow
        : initialState.currentWorkflow;
    },
    resetDefaultWorkflow: (state) => {
      state.duplicateWorkflow = false;
      state.defaultWorkflow = null;
    },
    setDefaultWorkflow: (state, action) => {
      state.duplicateWorkflow = false;
      state.defaultWorkflow = action.payload;
    },
    resetCurrentWorkflowApprovals: (state) => {
      state.currentWorkflowApprovals = initialState.currentWorkflowApprovals;
    },
    duplicateWorkflow: (state, action) => {
      state.currentWorkflow = {
        ...action.payload,
        title: action.payload.title ? `${action.payload.title} Copy` : null,
      };
      state.duplicateWorkflow = true;
    },
    setCurrentWorkflow: (state, action) => {
      state.currentWorkflow = action.payload;
      state.duplicateWorkflow = false;
    },
    setPartialCurrentWorkflowApprovals: (state, action) => {
      state.currentWorkflowApprovals = {
        ...initialState.currentWorkflowApprovals,
        ...action.payload,
      };
    },
    setCurrentWorkflowApprovals: (state, action) => {
      state.currentWorkflowApprovals = action.payload;
    },
    removeWorkflow: (state, action) => {
      state.workflows = state.workflows.filter(
        (workflow) => workflow.id !== action.payload.id
      );
    },
  },
});

export const {
  duplicateWorkflow,
  updateWorkflows,
  updateWorkflow,
  removeWorkflow,
  resetCurrentWorkflow,
  resetCurrentWorkflowApprovals,
  setCurrentWorkflow,
  setPartialCurrentWorkflowApprovals,
  setCurrentWorkflowApprovals,
  newDefaultWorkflow,
  editDefaultWorkflow,
  setDefaultWorkflow,
  resetDefaultWorkflow,
  updateDefaultWorkflow,
} = workflowSlice.actions;
export const { reducer } = workflowSlice;
