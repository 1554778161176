import { type TableEmptyState } from "@adaptive/design-system";

import type { GetCardTransactionsPayload } from "../api/types";
import type { Info, Permissions } from "../components/types";
import type { UseCostsParams } from "../hooks/use-costs";

export const STATUS = {
  ALL: "all",
  UNMATCHED: "unmatched",
} as const;

export const DEFAULT_INFO: Info = {
  data: [],
  count: 0,
  isLoading: true,
  isError: false,
};

export const DEFAULT_STATUS = STATUS.UNMATCHED;

export const DEFAULT_PERMISSIONS: Permissions = {
  canEdit: false,
  canEditCompany: false,
  canEditTransaction: () => false,
};

export const EDIT_STEP_TABLE_ID =
  "matching-transaction-dialog-edit-step-table" as const;

export const MATCH_STEP_TABLE_ID =
  "matching-transaction-dialog-match-step-table" as const;

export const MATCH_STEP_FORM_ID =
  "matching-transaction-dialog-match-step-form" as const;

export const SELECT_STEP_TABLE_ID =
  "matching-transaction-dialog-select-step-table" as const;

export const CREATE_RECEIPTS_DIALOG_TABLE_ID =
  "create-receipts-dialog-table" as const;

export const SELECT_COST_STEP_STATUS_FILTER = [
  { label: "Draft", value: "DRAFT" },
  { label: "For review", value: "FOR_REVIEW" },
  { label: "For approval", value: "APPROVAL" },
  { label: "For payment", value: "FOR_PAYMENT" },
].map((filter) => ({ ...filter, groupLabel: "Status" }) as const);

export const SELECT_COST_STEP_TYPE_FILTER = [
  { label: "Receipt", value: "expense" },
  { label: "Bill", value: "bill" },
  { label: "Bill payment", value: "billpayment" },
].map((filter) => ({ ...filter, groupLabel: "Type" }) as const);

export const STATUS_FILTER = [
  {
    label: "Has matched cost",
    value: "matched_cost_true",
  },
  {
    label: "No matched cost",
    value: "matched_cost_false",
  },
  {
    label: "Archived",
    value: "is_archived",
  },
].map((status) => ({ ...status, groupLabel: "Status" }));

export const STRINGS = {
  TITLE: "Card feed",
  SUBTITLE:
    "Review card transactions and match them to receipts & bill payments.",
  MANAGE_CARDS_ACTION: "Manage your company's linked cards",
  ALL_TAB: "All",
  MATCHED_STATUS: "Matched",
  ARCHIVED_STATUS: "Archived",
  UNMATCHED_STATUS: "Unmatched",
  UNMATCHED_TAB: "Unmatched",
  UNARCHIVE_ACTION: "Unarchive",
  NO_PERMISSION_TOOLTIP: "You don't have permission to do this",
  MY_TRANSACTIONS_SWITCH: "My transactions",
  MY_TRANSACTIONS_SWITCH_HINT: "Card transactions assigned to you",
  DATE_COLUMN: "Date",
  VENDOR_COLUMN: "Vendor",
  DESCRIPTION_COLUMN: "Description",
  LINKED_CARD_COLUMN: "Linked card",
  PENDING_TRANSACTION_AMOUNT_TOOLTIP: "Transaction pending. Amount may change",
  DISPUTE_TRANSACTION_ACTION_TOOLTIP: "You can't dispute this transaction",
  USER_COLUMN: "User",
  JOB_COLUMN: "Job",
  AMOUNT_COLUMN: "Amount",
  ACTIVITY_COLUMN: "Activity",
  MATCH_COLUMN: "Match",
  ACTIONS_COLUMN: "Actions",
  VIEW_TUTORIAL_ACTION: "View tutorial",
  EDIT_ACTION: "Edit match",
  MATCH_ACTION: "Match",
  BATCH_ACTION_TRIGGER: "Actions",
  BATCH_ACTION_EDIT: "Edit properties",
  BATCH_ACTION_ARCHIVE: "Archive",
  BATCH_ACTION_CREATE: "Create receipts",
  BATCH_ACTION_EDIT_VENDOR_PLACEHOLDER: "Set vendor",
  NOT_IMPLEMENTED_ACTION: "Not implemented",
  MATCH_ACTION_TOOLTIP:
    "Match this transaction to an existing receipt or bill payment",
  CREATE_ACTION: "Create",
  CREATE_ACTION_TOOLTIP: "Create a new receipt for this transaction",
  FILTERS_APPLIED_EMPTY_STATE_TITLE: "No transactions match your filters",
  FILTERS_APPLIED_EMPTY_STATE_SUBTITLE:
    "Try adjusting your search or filters to find what you're looking for",
  FILTER_CONFLICT_EMPTY_STATE_TITLE: "No results found",
  FILTER_CONFLICT_EMPTY_STATE_SUBTITLE:
    "You can't filter by User when the My transactions toggle is enabled.",
  FILTER_CONFLICT_TOOLTIP_MESSAGE: `You can't filter by user and my transactions at the same time`,
  FILTER_CONFLICT_TOOLTIP_USER_ACTION: "Remove user filter",
  FILTER_CONFLICT_TOOLTIP_MY_TRANSACTIONS_ACTION:
    "Disable My transactions toggle",
  UNMATCHED_EMPTY_STATE_TITLE:
    "You currently have no unmatched card transactions",
  UNMATCHED_EMPTY_STATE_SUBTITLE:
    "If something doesn't look right, go to Settings to confirm the time of last sync from your bank",
  ALL_EMPTY_STATE_TITLE: "You have no card transactions",
  ALL_EMPTY_STATE_SUBTITLE:
    "If something doesn't look right, go to Settings to confirm the time of last sync from your bank",
  NO_CARD_EMPTY_STATE_TITLE: "You have no card transactions",
  NO_CARD_EMPTY_STATE_SUBTITLE:
    "Go to Settings to connect your cards and start automatically\n matching your transactions to receipts and bill payments",
  MATCHING_TRANSACTION_DIALOG_EDIT_STEP_TITLE: "Edit match",
  MATCHING_TRANSACTION_DIALOG_EDIT_STEP_CHANGE_ACTION: "Change match",
  MATCHING_TRANSACTION_DIALOG_EDIT_STEP_UNMATCH_ACTION: "Unmatch",
  MATCHING_TRANSACTION_DIALOG_SELECT_CARD_TRANSACTION_STEP_TITLE:
    "Select a transaction from your card history that matches with this receipt",
  MATCHING_TRANSACTION_DIALOG_SELECT_CARD_TRANSACTION_STEP_VALIDATION_ERROR:
    "Please select a transaction",
  MATCHING_TRANSACTION_DIALOG_SELECT_CARD_TRANSACTION_STEP_EMPTY_STATE_TITLE:
    "No results found",
  MATCHING_TRANSACTION_DIALOG_SELECT_CARD_TRANSACTION_STEP_EMPTY_STATE_SUBTITLE:
    "Try adjusting your search or filters to find\n what you're looking for",
  MATCHING_TRANSACTION_DIALOG_SELECT_CARD_TRANSACTION_STEP_EMPTY_STATE_ACTION:
    "Clear filters",
  MATCHING_TRANSACTION_DIALOG_SELECT_CARD_TRANSACTION_STEP_CANCEL_ACTION:
    "Cancel",
  MATCHING_TRANSACTION_DIALOG_SELECT_CARD_TRANSACTION_STEP_MATCH_ACTION:
    "Match",
  MATCHING_TRANSACTION_DIALOG_SELECT_COST_STEP_TITLE:
    "Select costs to match with this card transaction",
  MATCHING_TRANSACTION_DIALOG_SELECT_COST_STEP_VALIDATION_ERROR:
    "Please select a cost",
  MATCHING_TRANSACTION_DIALOG_SELECT_COST_STEP_EMPTY_STATE_TITLE:
    "No results found",
  MATCHING_TRANSACTION_DIALOG_SELECT_COST_STEP_EMPTY_STATE_SUBTITLE:
    "Try adjusting your search or filters to find\n what you're looking for",
  MATCHING_TRANSACTION_DIALOG_SELECT_COST_STEP_EMPTY_STATE_ACTION:
    "Clear filters",
  MATCHING_TRANSACTION_DIALOG_MATCH_STEP_TITLE:
    "Confirm which information to keep on the matched transaction",
  MATCHING_TRANSACTION_DIALOG_MATCH_STEP_SUBTITLE:
    "Only fields with mismatched data are shown below",
  MATCHING_TRANSACTION_DIALOG_MATCH_STEP_BACK_ACTION: "Back",
  MATCHING_TRANSACTION_DIALOG_MATCH_STEP_SAVE_ACTION: "Save",
  CONNECT_CARD_ACTION: "Connect card",
  EXPIRED_CREDENTIALS_ALERT_TITLE: "Your credentials have expired",
  CLEAR_FILTERS_ACTION: "Clear filters",
  CREATE_RECEIPTS_DIALOG_TITLE:
    "Create receipts for the selected card transactions",
  CREATE_RECEIPTS_DIALOG_SUBTITLE:
    "Confirm the following data before submitting for review or syncing directly to QuickBooks",
  CREATE_RECEIPTS_DIALOG_SYNC_ACTION: "Sync to QuickBooks",
  CREATE_RECEIPTS_DIALOG_SYNC_ALL_ACTION: "Sync all to QuickBooks",
  CREATE_RECEIPTS_DIALOG_REMOVE_ACTION: "Remove",
  CREATE_RECEIPTS_DIALOG_FOR_REVIEW_ACTION: "Submit for review",
  CREATE_RECEIPTS_DIALOG_FOR_REVIEW_ALL_ACTION: "Submit all for review",
  CREATE_RECEIPTS_DIALOG_SYNC_JOB_ERROR: "Job is required to sync receipts",
  CREATE_RECEIPTS_DIALOG_SYNC_COST_CODE_ACCOUNT_ERROR:
    "Cost code / Account is required to sync receipts",
} as const;

export const FILTERS_APPLIED_EMPTY_STATE: Extract<TableEmptyState, object> = {
  title: STRINGS.FILTERS_APPLIED_EMPTY_STATE_TITLE,
  subtitle: STRINGS.FILTERS_APPLIED_EMPTY_STATE_SUBTITLE,
  maxWidth: 410,
};

export const FILTER_CONFLICT_EMPTY_STATE: Extract<TableEmptyState, object> = {
  title: STRINGS.FILTER_CONFLICT_EMPTY_STATE_TITLE,
  subtitle: STRINGS.FILTER_CONFLICT_EMPTY_STATE_SUBTITLE,
  maxWidth: 410,
};

export const UNMATCHED_EMPTY_STATE: Extract<TableEmptyState, object> = {
  title: STRINGS.UNMATCHED_EMPTY_STATE_TITLE,
  subtitle: STRINGS.UNMATCHED_EMPTY_STATE_SUBTITLE,
  maxWidth: 430,
};

export const NO_CARD_EMPTY_STATE: Extract<TableEmptyState, object> = {
  title: STRINGS.NO_CARD_EMPTY_STATE_TITLE,
  subtitle: STRINGS.NO_CARD_EMPTY_STATE_SUBTITLE,
  maxWidth: 460,
};

export const ALL_EMPTY_STATE: Extract<TableEmptyState, object> = {
  title: STRINGS.ALL_EMPTY_STATE_TITLE,
  subtitle: STRINGS.ALL_EMPTY_STATE_SUBTITLE,
  maxWidth: 430,
};

export const TABLE_ID = "card-feed-page-table" as const;

export const FILTERS_STORAGE_KEY = "card-feed-filters" as const;

export const MY_TRANSACTIONS_KEY = "my-transactions" as const;

export const DEFAULT_FILTERS: GetCardTransactionsPayload = [
  { dataIndex: MY_TRANSACTIONS_KEY, value: undefined },
];

export const ALL_CARD_TRANSACTION_FILTERS: GetCardTransactionsPayload = [];

export const UNMATCHED_CARD_TRANSACTION_FILTERS: GetCardTransactionsPayload = [
  { dataIndex: "is_archived", value: false },
  { dataIndex: "is_linked_to_transaction", value: false },
];

export const UNMATCHED_COSTS_FILTERS: UseCostsParams["query"] = [
  { dataIndex: "card_link", value: false },
];

export const TABLE_LOCAL_STORAGE_LIMIT_KEY = "card-feed-table-limit";
