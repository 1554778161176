import React, { memo, useMemo } from "react";
import {
  Link,
  Table,
  type TableColumn,
  TagGroup,
  Text,
} from "@adaptive/design-system";
import { formatCurrency } from "@adaptive/design-system/utils";
import { invertSign } from "@utils/invert-sign";
import { isNegative } from "@utils/is-negative";
import { sumBy } from "@utils/sumBy";
import { createContext, useContextSelector } from "use-context-selector";

import {
  type BillPaymentBill,
  type BillPaymentVendorCredit,
} from "../api/types";

export type BillPaymentLinkedTransaction = {
  id: string;
  type: string;
  amount: string;
  docNumber: string;
  customers: string[];
  balance: string;
};

type Context = {
  externalFlow: boolean;
};

const LinkedTransactionsTableContext = createContext<Context>({
  externalFlow: false,
});

const DocNumberCell = memo(
  ({ id, docNumber }: BillPaymentLinkedTransaction) => {
    const { externalFlow } = useContextSelector(
      LinkedTransactionsTableContext,
      ({ externalFlow }) => ({
        externalFlow,
      })
    );

    return externalFlow ? (
      <Text>#{docNumber}</Text>
    ) : (
      <Link href={`/bills/${id}`} target="_blank" size="sm">
        {`#${docNumber}`}
      </Link>
    );
  }
);
DocNumberCell.displayName = "DocNumberCell";

const AmountCell = ({
  amount,
  allowNegative = true,
  bold = false,
}: {
  amount: string;
  allowNegative?: boolean;
  bold?: boolean;
}) => {
  return (
    <Text
      weight={bold ? "bold" : "regular"}
      color={isNegative(amount) ? "success-200" : undefined}
    >
      {formatCurrency(amount, { currencySign: true, allowNegative })}
    </Text>
  );
};

const COLUMNS: TableColumn<BillPaymentLinkedTransaction>[] = [
  {
    id: "type",
    name: "Type",
    render: (row) => row.type,
  },
  {
    id: "docNumber",
    name: "Ref #",
    render: (row) => <DocNumberCell {...row} />,
  },
  {
    id: "amount",
    name: "Payment amount",
    render: (row) => {
      return <AmountCell amount={row.amount} />;
    },
    footer: (rows) => {
      const total = sumBy(rows, "amount").toString();

      return <AmountCell amount={total} bold />;
    },
  },
  {
    id: "balance",
    name: "Open balance",
    render: (row) => <AmountCell amount={row.balance} allowNegative={false} />,
  },
  {
    id: "jobs",
    name: "Jobs",
    render: (row) => {
      return <TagGroup data={row.customers || []} limit="auto" />;
    },
  },
];

export const LinkedTransactionsTable = ({
  bills,
  appliedVendorCredits,
  loading,
  externalFlow = false,
}: {
  bills?: BillPaymentBill[];
  appliedVendorCredits?: BillPaymentVendorCredit[];
  loading: boolean;
  externalFlow?: boolean;
}) => {
  const tableData = useMemo(() => {
    const linkedBills =
      bills?.map((linkedBill) => ({
        id: linkedBill.bill.id,
        type: "Bill",
        amount: invertSign(linkedBill.appliedAmount),
        docNumber: linkedBill.bill.docNumber,
        customers: linkedBill.customers.map((customer) => customer.displayName),
        balance: linkedBill.openBalance,
      })) || [];

    const linkedVendorCredits =
      appliedVendorCredits?.map((linkedVendorCredit) => ({
        id: linkedVendorCredit.vendorCredit.id,
        type: "Vendor credit",
        amount: invertSign(linkedVendorCredit.appliedAmount),
        docNumber: linkedVendorCredit.vendorCredit.docNumber,
        customers: linkedVendorCredit.customers.map(
          (customer) => customer.displayName
        ),
        balance: linkedVendorCredit.openBalance,
      })) || [];

    return [...linkedBills, ...linkedVendorCredits];
  }, [bills, appliedVendorCredits]);

  const context = useMemo<Context>(
    () => ({
      externalFlow,
    }),
    [externalFlow]
  );

  return (
    <LinkedTransactionsTableContext.Provider value={context}>
      <Table columns={COLUMNS} size="sm" data={tableData} loading={loading} />
    </LinkedTransactionsTableContext.Provider>
  );
};
