import React from "react";
import { type TableColumn } from "@adaptive/design-system";

import { BILL_PAYMENTS_TABS } from "../../constants";
import type {
  BillPaymentListingV2,
  BillPaymentsPageTab,
  GetBillPaymentColumnsHandler,
} from "../../types";

import {
  ACHStatusCell,
  DebitDateCell,
  DeliveryDateCell,
  JobsCell,
  LienWaiverStatusCell,
  PaymentAccountCell,
  PaymentAmountCell,
  PaymentMethodCell,
  RefNumberCell,
  StatusCell,
  VendorCell,
} from "./table-cells";

export const getColumns: GetBillPaymentColumnsHandler = ({
  currentTab,
}: { currentTab: BillPaymentsPageTab }) => {
  const columns: TableColumn<BillPaymentListingV2>[] = [
    {
      id: "vendor",
      minWidth: 200,
      name: "Vendor name",
      width: "fill",
      render: (row) => <VendorCell {...row} />,
    },
    {
      id: "debit_date",
      sortable: true,
      name: "Debit date",
      width: 220,
      render: (row) => <DebitDateCell {...row} />,
    },
    {
      id: "delivery_date",
      name: "Delivery date",
      width: 230,
      render: (row) => <DeliveryDateCell {...row} />,
    },
    {
      id: "payment_amount",
      name: "Payment amount",
      textAlign: "right",
      width: 150,
      render: (row) => <PaymentAmountCell {...row} />,
    },
    {
      id: "doc_number",
      name: "Ref #",
      visibility: "always-visible",
      width: 180,
      render: (row) => <RefNumberCell {...row} />,
    },
    {
      id: "payment_method",
      name: "Payment method",
      width: 180,
      render: (row) => <PaymentMethodCell {...row} />,
    },
    {
      id: "payment_account",
      name: "Payment account",
      visibility: "hidden",
      minWidth: 350,
      maxWidth: 600,
      render: (row) => <PaymentAccountCell {...row} />,
    },
    {
      id: "ach_status",
      name: "ACH status",
      width: 180,
      visibility: "hidden",
      render: (row) => <ACHStatusCell {...row} />,
    },
    {
      id: "lien_waiver_status",
      name: "Lien waiver",
      width: 250,
      visibility: "hidden",
      render: (row) => <LienWaiverStatusCell {...row} />,
    },
    {
      id: "jobs",
      name: "Jobs",
      width: 250,
      visibility: "hidden",
      render: (row) => <JobsCell {...row} />,
    },
  ];

  if (currentTab === BILL_PAYMENTS_TABS.ALL) {
    columns.push({
      id: "status",
      name: "Status",
      maxWidth: 140,
      visibility: "always-visible",
      render: (row) => <StatusCell {...row} />,
    });
  }

  return columns;
};
