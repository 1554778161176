import React, {
  createContext,
  memo,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  Flex,
  Icon,
  Link,
  Table,
  type TableColumn,
  TableConfigurationButton,
  type TableFooterAddon,
  type TableHeaderAddon,
  type TablePaginationAddon,
  TableProvider,
  type TableRowAddon,
  type TableSortAddon,
  Tag,
  Text,
} from "@adaptive/design-system";
import {
  useDeepMemo,
  type UseDialogReturn,
  useEvent,
  usePagination,
} from "@adaptive/design-system/hooks";
import { formatCurrency, formatDate } from "@adaptive/design-system/utils";
import {
  type PurchaseOrderLinesResponse,
  useGetPurchaseOrderLinesQuery,
} from "@api/jobs";
import { SignatureColumn } from "@src/purchase-orders/purchase-orders-table-cells";
import { useJobInfo } from "@store/jobs";
import { usePurchaseOrderDrawer } from "@store/ui";
import * as analytics from "@utils/analytics";
import { isAccount } from "@utils/is-account";
import { isCostCode } from "@utils/is-cost-code";

import { type LineWithGroupedBy } from "../budgets-table/types";

type Row = PurchaseOrderLinesResponse["results"][number];

const EMPTY_DATA = {
  count: 0,
  results: [] as Row[],
  totalAmount: 0,
  totalBalance: 0,
};

const PurchaseOrdersDialogContext = createContext<{
  totalAmount: number;
  totalBalance: number;
  budgetLineId?: string;
}>({
  totalAmount: 0,
  totalBalance: 0,
  budgetLineId: undefined,
});

const DocNumberColumn = memo(({ docNumber, purchaseOrderId }: Row) => {
  const { job } = useJobInfo();

  const { show } = usePurchaseOrderDrawer();

  const { budgetLineId } = useContext(PurchaseOrdersDialogContext);

  const onClick = useEvent(() => {
    analytics.track(
      budgetLineId
        ? "budgetViewPurchaseOrderOnViewLinePurchaseOrders"
        : "budgetViewPurchaseOrderOnViewPurchaseOrders",
      budgetLineId ? { jobId: job.id, budgetLineId } : { jobId: job.id }
    );
    show({ id: purchaseOrderId });
  });

  return (
    <Flex height="full" align="center">
      <Link as="button" type="button" variant="success" onClick={onClick}>
        <Text as="span" truncate size="sm">
          {docNumber}
        </Text>
      </Link>
    </Flex>
  );
});

DocNumberColumn.displayName = "DocNumberColumn";

const COLUMNS: TableColumn<Row>[] = [
  {
    id: "parent_doc_number",
    name: "PO #",
    width: 130,
    render: (row) => <DocNumberColumn {...row} />,
    align: "center",
    sortable: true,
    visibility: "always-visible",
  },
  {
    id: "vendor",
    name: "Vendor",
    width: 200,
    render: (row) => <Text truncate>{row.vendor}</Text>,
    sortable: "asc",
  },
  {
    id: "amount",
    name: "Amount",
    width: 150,
    render: (row) => formatCurrency(row.totalAmount, { currencySign: true }),
    textAlign: "right",
    sortable: true,
    footer: () => (
      <PurchaseOrdersDialogContext.Consumer>
        {({ totalAmount }) => (
          <Flex justify="flex-end">
            <Text
              size="sm"
              weight="bold"
              color={totalAmount >= 0 ? "neutral-800" : "warning-200"}
            >
              {formatCurrency(totalAmount, { currencySign: true })}
            </Text>
          </Flex>
        )}
      </PurchaseOrdersDialogContext.Consumer>
    ),
  },
  {
    id: "balance",
    name: "Open balance",
    width: 150,
    render: (row) => (
      <Text
        color={row.balance >= 0 ? "neutral-800" : "warning-200"}
        align="right"
      >
        {formatCurrency(row.balance, {
          currencySign: true,
          allowNegative: true,
        })}
      </Text>
    ),
    sortable: true,
    textAlign: "right",
    footer: () => (
      <PurchaseOrdersDialogContext.Consumer>
        {({ totalBalance }) => (
          <Flex justify="flex-end">
            <Text
              size="sm"
              weight="bold"
              color={totalBalance >= 0 ? "neutral-800" : "warning-200"}
            >
              {formatCurrency(totalBalance, {
                currencySign: true,
                allowNegative: true,
              })}
            </Text>
          </Flex>
        )}
      </PurchaseOrdersDialogContext.Consumer>
    ),
  },
  {
    id: "date",
    name: "Date",
    width: 120,
    render: (row) => formatDate(row.date),
    sortable: true,
  },
  {
    id: "job_cost_method",
    name: "Cost codes / Accounts",
    width: "fill",
    render: (row) => <Tag>{row.jobCostMethod}</Tag>,
    // sortable: "asc",
    minWidth: 200,
  },
  {
    id: "description",
    name: "Description",
    width: 200,
    render: (row) => <Text truncate>{row.description}</Text>,
    // sortable: "asc",
  },
];

if (window.VENDOR_PO_SIGNATURE_REQUEST_ENABLED) {
  COLUMNS.push({
    id: "signature",
    name: "Signature",
    width: 120,
    render: (row) => <SignatureColumn {...row} />,
    // sortable: true,
  });
}

export type PurchaseOrdersDialogProps = {
  dialog: UseDialogReturn;
  budgetLine?: LineWithGroupedBy;
};

export const PurchaseOrdersDialog = memo(
  ({ dialog, budgetLine }: PurchaseOrdersDialogProps) => {
    const { job } = useJobInfo();

    const { show } = usePurchaseOrderDrawer();

    const [ordering, setOrdering] =
      useState<TableColumn<Row>["id"]>("parent_doc_number");

    const pagination = usePagination({
      localStorageKey: `budget-purchase-orders-dialog-pagination${budgetLine ? `-${budgetLine.jobCostMethod.id}` : ""}`,
    });

    const params = useDeepMemo(() => {
      let itemId = undefined;
      let accountId = undefined;
      let categoryId = undefined;
      let withChildren = undefined;

      if (budgetLine?.groupedBy === "categories") {
        categoryId = budgetLine.category?.id || null;
      }

      if (
        budgetLine?.groupedBy === "quickbooks" &&
        (budgetLine.data || []).length > 0
      ) {
        withChildren = true;
      } else if (budgetLine) {
        withChildren = false;
      }

      if (!categoryId) {
        if (isCostCode(budgetLine?.jobCostMethod?.url)) {
          itemId = budgetLine.jobCostMethod.id;
        } else if (isAccount(budgetLine?.jobCostMethod?.url)) {
          accountId = budgetLine.jobCostMethod.id;
        }
      }

      return { itemId, accountId, categoryId, withChildren };
    }, [budgetLine]);

    const { data = EMPTY_DATA, isFetching } = useGetPurchaseOrderLinesQuery(
      {
        limit: pagination.perPage,
        offset: pagination.offset,
        ordering,
        customerId: job.id,
        ...params,
      },
      { refetchOnMountOrArgChange: true }
    );

    const tableSort = useMemo<TableSortAddon>(
      () => ({ value: ordering, onChange: setOrdering }),
      [ordering, setOrdering]
    );

    const tablePagination = useMemo<TablePaginationAddon>(
      () => ({
        page: pagination.page,
        total: data.count,
        perPage: pagination.perPage,
        onPerPageChange: (perPage) => {
          pagination.setPerPage(perPage);
          pagination.setPage(0);
        },
        onChange: pagination.setPage,
      }),
      [pagination, data.count]
    );

    const tableHeader = useMemo<TableHeaderAddon>(
      () => ({
        hide: data.count === 0,
      }),
      [data.count]
    );

    const tableFooter = useMemo<TableFooterAddon>(
      () => ({
        hide: data.count === 0,
      }),
      [data.count]
    );

    const columns = useMemo(() => {
      if (isCostCode(budgetLine?.jobCostMethod?.url)) {
        return COLUMNS.filter((column) => column.id !== "job_cost_method");
      }

      return COLUMNS;
    }, [budgetLine]);

    const budgetLineId = budgetLine?.id;

    const onAddPurchaseOrder = useEvent(() => {
      analytics.track(
        budgetLineId
          ? "budgetAddPurchaseOrderOnViewLinePurchaseOrders"
          : "budgetAddPurchaseOrderOnViewPurchaseOrders",
        budgetLineId ? { jobId: job.id, budgetLineId } : { jobId: job.id }
      );

      show({
        lines: [
          {
            item: isCostCode(budgetLine?.jobCostMethod?.url)
              ? {
                  url: budgetLine.jobCostMethod.url,
                  displayName: budgetLine.jobCostMethod.displayName,
                }
              : undefined,
            account: isAccount(budgetLine?.jobCostMethod?.url)
              ? {
                  url: budgetLine.jobCostMethod.url,
                  displayName: budgetLine.jobCostMethod.displayName,
                }
              : undefined,
            amount:
              budgetLine?.purchaseOrderBudgetAvailable &&
              budgetLine?.purchaseOrderBudgetAvailable > 0
                ? budgetLine?.purchaseOrderBudgetAvailable
                : undefined,
            customer: {
              url: job.url,
              displayName: job.display_name,
            },
          },
        ],
      });
    });

    const row = useMemo<TableRowAddon<Row>>(
      () => ({
        onClick: (row) => {
          analytics.track(
            budgetLineId
              ? "budgetViewPurchaseOrderOnViewLinePurchaseOrders"
              : "budgetViewPurchaseOrderOnViewPurchaseOrders",
            budgetLineId ? { jobId: job.id, budgetLineId } : { jobId: job.id }
          );
          show({ id: row.purchaseOrderId });
        },
      }),
      [show, job.id, budgetLineId]
    );

    return (
      <Dialog
        size="auto"
        variant="dialog"
        show={dialog.isVisible}
        onClose={dialog.hide}
      >
        <DialogHeader>
          <Flex direction="column" gap="md">
            {budgetLine?.jobCostMethod?.displayName ?? job.display_name}
            <Text size="md">Purchase order lines</Text>
          </Flex>
        </DialogHeader>
        <DialogContent>
          <TableProvider id="budget-purchase-orders-dialog-table">
            <Flex direction="column" gap="lg" minWidth="900px">
              <Flex gap="md" justify="flex-end">
                <TableConfigurationButton size="sm" />
                <Button variant="ghost" size="sm" onClick={onAddPurchaseOrder}>
                  <Icon name="plus" />
                  Add a purchase order
                </Button>
              </Flex>
              <PurchaseOrdersDialogContext.Provider
                value={{
                  totalAmount: data.totalAmount,
                  totalBalance: data.totalBalance,
                  budgetLineId,
                }}
              >
                <Table
                  size="sm"
                  row={row}
                  data={data.results}
                  sort={tableSort}
                  header={tableHeader}
                  footer={tableFooter}
                  loading={isFetching}
                  columns={columns}
                  maxHeight="500px"
                  pagination={tablePagination}
                  data-testid="budget-purchase-orders-dialog-table"
                />
              </PurchaseOrdersDialogContext.Provider>
            </Flex>
          </TableProvider>
        </DialogContent>
      </Dialog>
    );
  }
);

PurchaseOrdersDialog.displayName = "PurchaseOrdersDialog";
