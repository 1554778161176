import type {
  BillBalanceByCustomerResponse,
  BillListItemResponse,
} from "@api/bills";
import type { MultiplePaymentFormPayment } from "@bill-payment/types";
export const parseListRowToFormValue = (
  row: BillListItemResponse,
  index: number,
  openBalanceByCustomer: BillBalanceByCustomerResponse["openBalance"],
  defaultLienWaiverTemplateUrl?: string
) => {
  return {
    billId: row.id,
    docNumber: row.docNumber,
    dueDate: row.dueDate,
    billRefNumber: row.docNumber,
    amountToPay: row.amountToPay,
    customers: row.customers,
    openBalanceByCustomer,
    vendor: row.vendor,
    balance: row.balance,
    vendorEmail: row.vendor?.email,
    lienWaivers: row.customers?.map((customer) => ({
      customer: customer.url,
      lienWaiverTemplate: defaultLienWaiverTemplateUrl,
      missingFields: {
        isValid: true,
      },
    })),
    index,
  } as MultiplePaymentFormPayment;
};
