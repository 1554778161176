import { parseCurrency } from "@adaptive/design-system/utils";
import { notRequiredStatuses } from "@lien-waiver/api";
import { LIEN_WAIVER_STATUS } from "@lien-waiver/constants";
import { sum } from "@src/shared/utils/sum";
import { sumBy } from "@src/shared/utils/sumBy";
import endOfDay from "date-fns/endOfDay";
import isAfter from "date-fns/isAfter";
import isSameDay from "date-fns/isSameDay";
import { z } from "zod";

import { COMBINED_PAYMENTS_STRINGS, PAYMENT_METHOD } from "./constants";

export const missingFieldsInfoSchema = z.object({
  displayName: z.string().nullish(),
  url: z.string().nullish(),
  missingFields: z.array(z.string()),
  objectType: z.enum(["customer", "vendor", "bill"]).nullish(),
  id: z.string().nullish(),
  frontendUrl: z.string().nullish(),
});

const customerSchema = z.object({
  url: z.string().nullish(),
  id: z.string().nullish(),
  displayName: z.string().nullish(),
});

const paymentSchema = z.object({
  billId: z.string(),
  bills: z
    .array(
      z.object({
        id: z.string(),
        url: z.string(),
      })
    )
    .optional(),
  billRefNumber: z.string(),
  docNumber: z.string().nullish(),
  dueDate: z.string().nullish(),
  amountToPay: z.string().nullish(),
  paymentMethod: z.string().nullish(),
  balance: z.string().nullish(),
  vendorEmail: z.string().email().nullish(),
  index: z.number(),
  customer: customerSchema.optional(),
  customers: z.array(customerSchema).optional(),
  vendor: z.object({
    url: z.string(),
    id: z.string(),
    displayName: z.string(),
  }),
  customerBankAccount: z
    .string()
    .url({
      message: COMBINED_PAYMENTS_STRINGS.PAYMENT_ACCOUNT_REQUIRED,
    })
    .nullish(),
  customerPaymentAccount: z.string().url().nullish(),
  vendorBankAccount: z.string().url().nullish(),
  debitDate: z.date().nullish(),
  nextAvailableDebitDate: z.date().nullish(),
  appliedVendorCredits: z
    .array(
      z.object({
        id: z.string(),
        url: z.string(),
        appliedAmount: z.number(),
      })
    )
    .nullish(),
  lienWaivers: z
    .array(
      z.object({
        status: notRequiredStatuses.nullish(),
        lienWaiverTemplate: z.string().nullish(),
        customer: z.string(),
        missingFields: z
          .object({
            customers: z.array(missingFieldsInfoSchema).nullish(),
            vendor: missingFieldsInfoSchema.nullish(),
            bill: missingFieldsInfoSchema.nullish(),
            isValid: z.boolean(),
          })
          .nullish(),
      })
    )
    .min(1),
  openBalanceByCustomer: z
    .array(
      z.object({
        customerId: z.string(),
        balance: z.string(),
      })
    )
    .nullish(),
});

export type RecursivePaymentSchema = z.infer<typeof paymentSchema> & {
  data?: RecursivePaymentSchema[];
};

export const recursivePaymentSchema: z.ZodType<RecursivePaymentSchema> =
  paymentSchema
    .extend({
      data: z.lazy(() => recursivePaymentSchema.array().optional()),
    })
    .superRefine(
      (
        {
          appliedVendorCredits,
          debitDate,
          nextAvailableDebitDate,
          balance,
          paymentMethod,
        },
        context
      ) => {
        const today = endOfDay(new Date());

        const isMarkAsPaid =
          sum(
            sumBy(appliedVendorCredits || [], "appliedAmount"),
            parseCurrency(balance || "0") || 0
          ) <= 0 || paymentMethod === PAYMENT_METHOD.MARK_AS_PAID;

        const dateIsInTheFuture = debitDate && isAfter(debitDate, today);

        if (isMarkAsPaid && !debitDate) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["debitDate"],
            message: COMBINED_PAYMENTS_STRINGS.DEBIT_DATE,
          });
        }

        if (isMarkAsPaid && dateIsInTheFuture) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["debitDate"],
            message:
              COMBINED_PAYMENTS_STRINGS.ERROR_CANT_HAVE_DATES_IN_FUTURE_FOR_MARK_AS_PAID,
          });
        }

        if (
          !isMarkAsPaid &&
          debitDate &&
          nextAvailableDebitDate &&
          !isSameDay(debitDate, nextAvailableDebitDate)
        ) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["debitDate"],
            message:
              COMBINED_PAYMENTS_STRINGS.DEBIT_DATE_MUST_MATCH_AVAILABLE_DATE,
          });
        }
      }
    );

export const multiplePaymentFormSchema = z
  .object({
    combinedPayments: z.boolean(),
    payWhenLienWaiverIsSigned: z.boolean(),
    payments: z.array(recursivePaymentSchema).min(1),
  })
  .superRefine((values, context) => {
    values.payments.forEach((payment, index) => {
      if (
        values.payWhenLienWaiverIsSigned &&
        !payment.lienWaivers?.every(
          (lienWaiver) =>
            lienWaiver?.lienWaiverTemplate ||
            lienWaiver?.status === LIEN_WAIVER_STATUS.NOT_REQUIRED
        )
      ) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["payWhenLienWaiverIsSigned"],
          message: COMBINED_PAYMENTS_STRINGS.LIEN_WAIVERS_TEMPLATE_NOT_SELECTED,
        });
      }

      if (!payment.customerPaymentAccount) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["payments", index, "customerPaymentAccount"],
          message: COMBINED_PAYMENTS_STRINGS.PAYMENT_ACCOUNT_NOT_LINKED,
        });
      }

      if (!payment.customerBankAccount) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["payments", index, "customerBankAccount"],
          message: COMBINED_PAYMENTS_STRINGS.PAYMENT_ACCOUNT_REQUIRED,
        });
      }

      if (
        !payment.lienWaivers?.every(
          (lienWaiver) => lienWaiver?.missingFields?.isValid
        )
      ) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["payments", index, "lienWaivers"],
          message: COMBINED_PAYMENTS_STRINGS.LIEN_WAIVER_MISSING_INFO,
        });
      }
    });
  });
