import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  Loader,
  Text,
} from "@adaptive/design-system";
import { useDialog, useEvent } from "@adaptive/design-system/hooks";
import { AccountActions, CardsApp } from "@capitalos/react";
import { isStaff } from "@utils/is-staff";

import { useCardsAdaptiveContext } from "./cards-adaptive-context";

type CustomLoaderProps = {
  onMount: () => void;
  onUnmount: () => void;
};

const CustomLoader = memo(({ onMount, onUnmount }: CustomLoaderProps) => {
  useEffect(() => {
    onMount();

    return () => {
      onUnmount();
    };
  }, [onUnmount, onMount]);

  return (
    <Flex width="full">
      <Loader />
    </Flex>
  );
});

CustomLoader.displayName = "CustomLoader";

export const CardsAdaptiveSettingsButton = memo(() => {
  const [isCardsLoading, setIsCardsLoading] = useState(false);

  const [isAccountLoading, setIsAccountLoading] = useState(false);

  const { setHasError } = useCardsAdaptiveContext();

  const dialog = useDialog({ lazy: true });

  const onError = useEvent(() => {
    setHasError(true);
    dialog.hide();
  });

  return (
    <>
      <Button variant="ghost" color="neutral" onClick={dialog.show}>
        <Icon name="gear" />
        Settings
      </Button>
      {dialog.isRendered && (
        <Dialog variant="drawer" show={dialog.isVisible} onClose={dialog.hide}>
          <DialogHeader>Adaptive card settings</DialogHeader>
          <DialogContent>
            <AccountActions
              loadingComponent={
                <CustomLoader
                  onMount={() => setIsAccountLoading(true)}
                  onUnmount={() => setIsAccountLoading(false)}
                />
              }
              onError={onError}
            />
            {(window.CAPITAL_OS_IS_SANDBOX || isStaff()) && (
              <Flex
                gap="sm"
                align="center"
                hidden={isAccountLoading}
                padding={["2xl", "none", "none"]}
                direction="column"
                borderWidth={["sm", "none", "none"]}
                borderColor="neutral-300"
              >
                {!isCardsLoading && (
                  <Text size="xl" weight="bold">
                    Debug
                  </Text>
                )}
                <CardsApp
                  loadingComponent={
                    <CustomLoader
                      onMount={() => setIsCardsLoading(true)}
                      onUnmount={() => setIsCardsLoading(false)}
                    />
                  }
                  onError={onError}
                />
              </Flex>
            )}
          </DialogContent>
          <DialogFooter>
            <Button
              size="lg"
              variant="text"
              color="neutral"
              onClick={dialog.hide}
            >
              Close
            </Button>
          </DialogFooter>
        </Dialog>
      )}
    </>
  );
});

CardsAdaptiveSettingsButton.displayName = "CardsAdaptiveSettingsButton";
