type NoopHandler = (...args: any[]) => any;

export const noop: NoopHandler = () => {};

export const curriedNoop = () => noop;

export const asyncNoop = (): Promise<void> => {
  return new Promise((resolve) => {
    resolve();
  });
};
