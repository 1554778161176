export const LINE_COLUMN_ID_TO_PROP = {
  nameItems: "jobCostMethod.displayName",
  nameQuickbooks: "jobCostMethod.displayName",
  nameCategories: "jobCostMethod.displayName",
  categoryItems: "category.displayName",
  categoryQuickbooks: "category.displayName",
  categoryCategories: "category.displayName",
  budget: "builderAmount",
  changes: "changeAmount",
  revisedBudget: "builderRevisedAmount",
  actual: "spent",
  unpaid: "unpaidBills",
  undrawnCosts: "undrawnCosts",
  costsRemaining: "budgetRemaining",
  ownersBudget: "ownersAmount",
  revenuesChanges: "externalChangeAmount",
  revenuesRevisedBudget: "ownersRevisedAmount",
  drawn: "invoicedAmount",
  remaining: "remaining",
  purchaseOrderAmount: "purchaseOrderAmount",
  purchaseOrderOpenBalance: "purchaseOrderOpenBalance",
  purchaseOrderBudgetAvailable: "purchaseOrderBudgetAvailable",
};

export const MARKUP_COLUMN_ID_TO_PROP = {
  nameItems: "jobCostMethod.displayName",
  nameQuickbooks: "jobCostMethod.displayName",
  nameCategories: "jobCostMethod.displayName",
  categoryItems: "budgetLine.category.displayName",
  categoryQuickbooks: "budgetLine.category.displayName",
  categoryCategories: "budgetLine.category.displayName",
  budget: "builderAmount",
  changes: "changeAmount",
  revisedBudget: "builderRevisedAmount",
  actual: "budgetLine.spent",
  undrawnCosts: "budgetLine.undrawnCosts",
  unpaid: "budgetLine.unpaidBills",
  costsRemaining: "budgetLine.budgetRemaining",
  ownersBudget: "ownersAmount",
  revenuesChanges: "externalChangeAmount",
  revenuesRevisedBudget: "ownersRevisedAmount",
  drawn: "budgetLine.invoicedAmount",
  remaining: "budgetLine.remaining",
  purchaseOrderAmount: "budgetLine.purchaseOrderAmount",
  purchaseOrderOpenBalance: "budgetLine.purchaseOrderOpenBalance",
  purchaseOrderBudgetAvailable: "budgetLine.purchaseOrderBudgetAvailable",
};
