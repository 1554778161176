import React, { useMemo } from "react";
import { Tag, TagGroup, Text } from "@adaptive/design-system";
import { formatCurrency, formatDate } from "@adaptive/design-system/utils";
import { getPaymentAccountDisplayName } from "@bill-payment/utils";
import { getAchProcessingOption } from "@src/bills/components/pay-bill-step/utils";
import { useClientSettings } from "@store/user";

import {
  EM_DASH,
  HUMAN_READABLE_PAYMENT_METHOD,
  PAYMENT_METHOD,
  PAYMENT_STATUS,
  PROCESS_PREREQUISITE_TYPE,
} from "../../constants";
import { type BillPaymentListingV2 } from "../../types";

export const VendorCell = ({ vendor }: BillPaymentListingV2) => {
  return vendor?.displayName || EM_DASH;
};

export const DebitDateCell = ({
  paymentSchedule,
  processPrerequisites,
  status,
}: BillPaymentListingV2) => {
  const { isAwaitingLienWaiver, isAwaitingVendorBankingAch } = useMemo(() => {
    const isAwaitingLienWaiver =
      status === PAYMENT_STATUS.PENDING_PREREQUISITES &&
      processPrerequisites?.some(
        (prereq) =>
          prereq.type === PROCESS_PREREQUISITE_TYPE.LIEN_WAIVER_SIGNATURE &&
          !prereq.isSatisfied
      );
    const isAwaitingVendorBankingAch =
      status === PAYMENT_STATUS.PENDING_PREREQUISITES &&
      processPrerequisites?.some(
        (prereq) =>
          prereq.type === PROCESS_PREREQUISITE_TYPE.VENDOR_BANKING_ACH &&
          !prereq.isSatisfied
      );
    return { isAwaitingLienWaiver, isAwaitingVendorBankingAch };
  }, [processPrerequisites, status]);

  if (isAwaitingLienWaiver && isAwaitingVendorBankingAch) {
    return (
      <Text color="neutral-600" truncate>
        On ACH collection & lien waiver signature
      </Text>
    );
  }

  if (isAwaitingLienWaiver) {
    return (
      <Text color="neutral-600" truncate>
        On lien waiver signature
      </Text>
    );
  }
  if (isAwaitingVendorBankingAch) {
    return (
      <Text color="neutral-600" truncate>
        On ACH collection
      </Text>
    );
  }

  return formatDate(paymentSchedule?.processedOn || null, "P") || EM_DASH;
};

export const DeliveryDateCell = ({
  paymentSchedule,
  status,
}: BillPaymentListingV2) => {
  const { achProcessingTime } = useClientSettings();

  if (status === PAYMENT_STATUS.CANCELLED || status === PAYMENT_STATUS.FAILED) {
    return EM_DASH;
  }

  if (
    status === PAYMENT_STATUS.PENDING_PREREQUISITES &&
    !paymentSchedule?.processedOn
  ) {
    return (
      <Text color="neutral-600" truncate>
        {`${
          getAchProcessingOption(achProcessingTime)?.description ||
          "3-5 business days"
        } after debit`}
      </Text>
    );
  }

  if (paymentSchedule?.deliveredOn) {
    return <Text truncate>{formatDate(paymentSchedule.deliveredOn, "P")}</Text>;
  }

  if (
    paymentSchedule?.expectedDeliveryAfter &&
    paymentSchedule?.expectedDeliveryBefore
  ) {
    return (
      <Text truncate>
        {formatDate(
          {
            from: paymentSchedule.expectedDeliveryAfter,
            to: paymentSchedule.expectedDeliveryBefore,
          },
          "P"
        )}
      </Text>
    );
  }

  return EM_DASH;
};

export const PaymentAmountCell = ({ appliedAmount }: BillPaymentListingV2) => {
  return formatCurrency(appliedAmount || 0, { currencySign: true });
};

export const RefNumberCell = ({ docNumber }: BillPaymentListingV2) => {
  return docNumber ? `#${docNumber}` : EM_DASH;
};

export const PaymentMethodCell = ({ paymentMethod }: BillPaymentListingV2) => {
  return HUMAN_READABLE_PAYMENT_METHOD[paymentMethod] || EM_DASH;
};

export const PaymentAccountCell = (row: BillPaymentListingV2) => {
  return <Text truncate>{getPaymentAccountDisplayName(row)}</Text>;
};

export const ACHStatusCell = ({
  vendor,
  vendorBankAccount,
  paymentMethod,
}: BillPaymentListingV2) => {
  if (paymentMethod !== PAYMENT_METHOD.ACH) {
    return EM_DASH;
  }
  if (vendorBankAccount) {
    return <Tag color="success">Active</Tag>;
  }

  const latestAchRequest = vendor?.latestAchRequest;
  if (latestAchRequest?.isExpired) {
    return <Tag color="warning">Request expired</Tag>;
  }
  if (!latestAchRequest?.isCompleted) {
    return <Tag color="info">Request sent</Tag>;
  }
  return <Tag color="neutral">Not requested</Tag>;
};

export const LienWaiverStatusCell = ({ lienWaivers }: BillPaymentListingV2) => {
  const lienWaiverProperties = useMemo(() => {
    const getLwProperties = (
      lw: BillPaymentListingV2["lienWaivers"][number]
    ) => {
      const jobName = lw.customer?.displayNameWithoutCompany
        ? ` (${lw.customer?.displayName})`
        : "";

      switch (lw.status) {
        case "not_selected":
        case "not_requested":
          return {
            color: "neutral",
            hidden: `Not requested${jobName}`,
            visible: "Not requested",
          };
        case "marked_as_requested":
        case "requested":
          return {
            color: "info",
            hidden: `Request sent${jobName}`,
            visible: "Request sent",
          };
        case "marked_as_signed":
        case "signed":
          return {
            color: "success",
            hidden: `Signed${jobName}`,
            visible: "Signed",
          };
        case "not_required":
          return {
            color: "neutral",
            hidden: `Not required${jobName}`,
            visible: "Not required",
          };
        default:
          return null;
      }
    };

    return lienWaivers.reduce<{
      [key: string]: {
        color: string;
        visible: string;
        hidden: string;
      };
    }>((acc, lw) => {
      acc[lw.id] = getLwProperties(lw) || {
        color: "neutral",
        visible: EM_DASH,
        hidden: EM_DASH,
      };
      return acc;
    }, {});
  }, [lienWaivers]);

  if (!lienWaivers?.length) {
    return EM_DASH;
  }

  return (
    <TagGroup
      limit="auto"
      data={lienWaivers}
      render={(lw) => {
        return lienWaiverProperties[lw.id] || EM_DASH;
      }}
    />
  );
};

export const JobsCell = ({ bills }: BillPaymentListingV2) => {
  const dedupedJobNames = useMemo(() => {
    if (!bills?.length) {
      return [];
    }
    const jobs = bills.flatMap((bill) => bill.customers);
    return Array.from(new Set(jobs.map((job) => job.displayName))).sort();
  }, [bills]);

  if (!bills?.length) {
    return EM_DASH;
  }
  return <TagGroup limit="auto" data={dedupedJobNames} />;
};

export const StatusCell = ({ status, isVoided }: BillPaymentListingV2) => {
  switch (status) {
    case PAYMENT_STATUS.PENDING_PREREQUISITES:
    case PAYMENT_STATUS.ACH_REQUESTED:
      return <Tag color="neutral">Scheduled</Tag>;
    case PAYMENT_STATUS.PENDING:
      return <Tag color="info">In progress</Tag>;
    case PAYMENT_STATUS.PAID:
      return <Tag color="success">Paid</Tag>;
    case PAYMENT_STATUS.FAILED:
      return <Tag color="error">Failed</Tag>;
    case PAYMENT_STATUS.CANCELLED: {
      if (isVoided) {
        return <Tag color="error">Voided</Tag>;
      }
      return <Tag color="error">Cancelled</Tag>;
    }
    default:
      return EM_DASH;
  }
};
