import React, { memo, type ReactNode, useMemo, useState } from "react";
import { Wrapper } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { useLazyGetCapitalOSInitiateLoginQuery } from "@api/capital-os";
import { CapitalOsAuthenticationProvider } from "@capitalos/react";
import {
  BasePermissions,
  useClientInfo,
  useClientSettings,
  useUserInfo,
} from "@store/user";

import { CardsAdaptiveContext } from "./cards-adaptive-context";

export const CardsAdaptiveProvider = memo(
  ({ children }: { children: ReactNode }) => {
    const { realmId } = useClientInfo();

    const { hasPermission } = useUserInfo();

    const isCompanyAdmin = hasPermission(BasePermissions.COMPANY_ADMIN);

    const { capitalOsEnabled } = useClientSettings();

    const [initiateLogin, initiateLoginInfo] =
      useLazyGetCapitalOSInitiateLoginQuery();

    const [capitalOsHasError, setCapitalOsHasError] = useState(false);

    const getToken = useEvent(async () => {
      try {
        const data = await initiateLogin().unwrap();
        return data.token;
      } catch {
        setCapitalOsHasError(true);
        return "";
      }
    });

    const userRole = useMemo(() => {
      if (initiateLoginInfo?.data?.account?.status === "approved") {
        return isCompanyAdmin ? "manage" : "view";
      }

      return isCompanyAdmin ? "register" : "view";
    }, [initiateLoginInfo?.data?.account?.status, isCompanyAdmin]);

    return (
      <Wrapper
        when={capitalOsEnabled}
        render={(children) => (
          <CapitalOsAuthenticationProvider key={realmId} getToken={getToken}>
            {children}
          </CapitalOsAuthenticationProvider>
        )}
      >
        <CardsAdaptiveContext.Provider
          value={{
            userId: initiateLoginInfo?.data?.account?.id ?? "",
            hasError: capitalOsHasError,
            userRole,
            isLoading: initiateLoginInfo.isFetching,
            setHasError: setCapitalOsHasError,
          }}
        >
          {children}
        </CardsAdaptiveContext.Provider>
      </Wrapper>
    );
  }
);

CardsAdaptiveProvider.displayName = "CardsAdaptiveProvider";
