import React, { memo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  Dropdown,
  DropdownComboBoxItem,
  type DropdownComboboxItemProps,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  Shortcut,
  Text,
  toast,
  Tooltip,
  VisuallyHidden,
  Wrapper,
} from "@adaptive/design-system";
import {
  useDeepMemo,
  useEvent,
  useKeydown,
} from "@adaptive/design-system/hooks";
import {
  hasDialogOpen,
  hasModifierKey,
  isField,
} from "@adaptive/design-system/utils";

import { useClientAction, useClientInfo } from "../../store/user";

type Props = { compact?: boolean };

export const SidebarClient = memo(({ compact }: Props) => {
  const location = useLocation();

  const navigate = useNavigate();

  const { changeClient } = useClientAction();

  const { client, clients } = useClientInfo();

  const [isVisible, setIsVisible] = useState<boolean | undefined>();

  const data = useDeepMemo(
    () => clients.map((item) => ({ label: item.name, value: item.id })),
    [clients]
  );

  const onChange = useEvent<
    Exclude<DropdownComboboxItemProps["onChange"], undefined>
  >((_, option) => {
    if (!option || client?.id === option?.value) return;

    let redirectTo = location.pathname;

    if (location.pathname.startsWith("/bills/")) {
      redirectTo = "/bills";
    } else if (location.pathname.startsWith("/expenses/")) {
      redirectTo = "/expenses";
    } else if (location.pathname.startsWith("/jobs/")) {
      redirectTo = "/jobs";
    }

    if (location.pathname !== redirectTo) {
      navigate(redirectTo);
    }

    toast.info(`You switched to a different workspace: ${option.label}`);
    changeClient(option.value);
  });

  useKeydown((e) => {
    if (isField(e.target) || hasModifierKey(e) || hasDialogOpen()) return;

    if (e.code === "KeyC") {
      setIsVisible(true);
      requestAnimationFrame(() => setIsVisible(undefined));
    }
  });

  return client ? (
    <Wrapper
      when={data.length > 1}
      render={(children) => (
        <Tooltip
          message={
            compact ? (
              ""
            ) : (
              <Flex gap="md">
                Switch client
                <Shortcut letter="C" />
              </Flex>
            )
          }
          placement="right"
        >
          <Dropdown
            show={isVisible}
            listSize={9}
            flip={false}
            placement="top-end"
          >
            <DropdownTrigger data-testid="client-name-trigger">
              <Flex
                gap="lg"
                width="full"
                align="center"
                padding={["xl", "2xl", "md"]}
                justify="space-between"
                minHeight="56px"
              >
                {children}
                {!compact && <Icon name="angles-up-down" color="neutral-800" />}
              </Flex>
            </DropdownTrigger>
            <DropdownList>
              <DropdownComboBoxItem
                name="client"
                data={data}
                value={client.id}
                onChange={onChange}
              />
            </DropdownList>
          </Dropdown>
        </Tooltip>
      )}
    >
      <Flex
        gap="lg"
        align="center"
        padding={data.length === 1 ? ["none", "2xl", "none"] : undefined}
        minHeight={data.length === 1 ? "56px" : undefined}
      >
        <Icon name="buildings" />
        {compact ? (
          <VisuallyHidden data-testid="client-name">
            {client.name}
          </VisuallyHidden>
        ) : (
          <Text
            size="sm"
            truncate={{ tooltip: data.length === 1 }}
            data-testid="client-name"
          >
            {client.name}
          </Text>
        )}
      </Flex>
    </Wrapper>
  ) : null;
});

SidebarClient.displayName = "SidebarClient";
