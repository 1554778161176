import React from "react";
import { Flex, Link, Text } from "@adaptive/design-system";
import { formatCurrency, parseStringCopy } from "@adaptive/design-system/utils";
import { CONFIRMATION_PAYMENT_STRINGS, PAYMENT_METHOD } from "@bills/constants";

type Props = {
  paymentsLength: number;
  totalAmountToPay: number;
  paymentMethod?: string;
  onViewPayment: () => void;
};

export const PaymentSuccessMessage = ({
  paymentsLength,
  totalAmountToPay,
  paymentMethod = PAYMENT_METHOD.ACH,
  onViewPayment,
}: Props) => {
  return (
    <Flex direction="column" gap="xs">
      <Text weight="bold" as="span" data-testid="payment-success-message">
        {parseStringCopy(
          CONFIRMATION_PAYMENT_STRINGS.SUCCESSFUL_PAYMENT_TITLE,
          {
            number: paymentsLength,
            suffix: paymentsLength > 1 ? "s" : "",
            total: formatCurrency(totalAmountToPay, {
              currencySign: true,
            }),
          }
        )}
      </Text>
      <Text as="span">
        {parseStringCopy(
          CONFIRMATION_PAYMENT_STRINGS.VIEW_PAYMENT_PREFIX_TITLE,
          {
            suffix: paymentsLength > 1 ? "s" : "",
            action: paymentMethod === PAYMENT_METHOD.ACH ? "scheduled" : "paid",
          }
        )}{" "}
        <Link as="button" type="button" onClick={onViewPayment}>
          <Text weight="bold">
            {CONFIRMATION_PAYMENT_STRINGS.VIEW_PAYMENT_PAGE_LINK_TITLE}
          </Text>
        </Link>{" "}
        {CONFIRMATION_PAYMENT_STRINGS.VIEW_PAYMENT_SUFFIX_TITLE}
      </Text>
    </Flex>
  );
};
