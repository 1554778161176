export const STRINGS = {
  MARK_AS_PAID: "Mark as Paid",
  PAY_WITH_ACH: "Pay with ACH",
  TOTAL_AMOUNT: "Total selected amount to pay ",
  MARK_AS_PAID_TOOLTIP_LINE1:
    "Paid your bills off platform? Use “Mark as paid” to log",
  MARK_AS_PAID_TOOLTIP_LINE2: "the payment and request lien waivers",
};

export const getTransactionTypesSelectedTitle = (
  hasVendorCreditsSelected: boolean,
  hasBillsSelected: boolean
) => {
  return `Selected ${hasBillsSelected ? "bills with open balance" : ""}${
    hasBillsSelected && hasVendorCreditsSelected ? "& " : ""
  }${hasVendorCreditsSelected ? "credits " : ""}`;
};
