import React, {
  type FocusEventHandler,
  memo,
  type MouseEventHandler,
  type ReactNode,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Tooltip as AriaKitTooltip,
  TooltipAnchor,
  type TooltipAnchorProps,
  TooltipArrow,
  TooltipProvider,
  type TooltipStoreState,
  useTooltipContext,
} from "@ariakit/react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

import { useEvent } from "../../hooks/use-event";
import { useVisibilityChange } from "../../hooks/use-visibility-change";
import { mergeRefs } from "../../utils/merge-refs";
import { suffixify } from "../../utils/suffixify";

import styles from "./tooltip.module.css";

export type Props = {
  show?: boolean;
  align?: "center" | "left";
  offset?: { x?: number; y?: number };
  message?: ReactNode;
  placement?: TooltipStoreState["placement"];
  "data-testid"?: string;
};

const DEFAULT_COMPONENT = "span";

/**
 * Depending on the TooltipArrow size we need to adjust the offset
 * to make sure the arrow is right positioned
 */
const OFFSET = 2;

const schedule = (callback: () => any, timeout = 84) => {
  if (typeof requestIdleCallback === "function") {
    const id = requestIdleCallback(callback, { timeout });
    return () => cancelIdleCallback(id);
  }
  // Safari doesn't support rIC
  const id = setTimeout(callback, timeout);
  return () => clearTimeout(id);
};

const TooltipAnchorDelayed = ({
  onBlur,
  onFocusVisible,
  ...props
}: TooltipAnchorProps) => {
  const store = useTooltipContext();

  const cancelScheduleRef = useRef(() => {});

  const enhancedOnBlur = useEvent((e) => {
    onBlur?.(e);
    cancelScheduleRef.current();
  });

  const enhancedOnFocusVisible = useEvent((e) => {
    onFocusVisible?.(e);

    e.preventDefault();

    const currentTarget = e.currentTarget;
    cancelScheduleRef.current();
    cancelScheduleRef.current = schedule(() => {
      store?.setAnchorElement(currentTarget);
      store?.show();
    });
  });

  return (
    <TooltipAnchor
      onBlur={enhancedOnBlur}
      onFocusVisible={enhancedOnFocusVisible}
      {...props}
    />
  );
};

const MemoizedTooltipAnchorDelayed = memo(TooltipAnchorDelayed);

MemoizedTooltipAnchorDelayed.displayName = "TooltipAnchorDelayed";

const Tooltip = forwardRefAs<typeof DEFAULT_COMPONENT, Props>(
  (
    {
      as: Component = DEFAULT_COMPONENT,
      show,
      align = "center",
      offset = { x: 0, y: 0 },
      onBlur,
      message,
      children,
      tabIndex = -1,
      placement = "top",
      className,
      onMouseEnter,
      "data-testid": testId,
      ...props
    },
    ref
  ) => {
    const id = useId();

    const internalRef = useRef<HTMLDivElement>(null);

    const [isRunning, setIsRunning] = useState(true);

    const isDisabled = !!("disabled" in props && props.disabled);

    const enhancedOnBlur = useEvent<FocusEventHandler<HTMLElement>>((e) => {
      if (message) setIsRunning(true);
      onBlur?.(e);
    });

    const enhancedOnMouseEnter = useEvent<MouseEventHandler<HTMLElement>>(
      (e) => {
        if (message) setIsRunning(true);
        onMouseEnter?.(e);
      }
    );

    const render = useMemo(
      () => <Component ref={mergeRefs(ref, internalRef)} tabIndex={tabIndex} />,
      [Component, ref, tabIndex]
    );

    useVisibilityChange((isVisible) => {
      if (!isVisible && message) setIsRunning(false);
    });

    return (
      <TooltipProvider
        open={isRunning ? show : false}
        placement={placement}
        showTimeout={0}
        hideTimeout={300}
      >
        {message && (
          <AriaKitTooltip
            id={id}
            shift={offset.y}
            gutter={(offset.x ?? 0) - OFFSET}
            className={cn(styles["tooltip"], { [styles[`-${align}`]]: align })}
            data-testid={suffixify(testId, "message")}
            unmountOnHide={import.meta.env.MODE !== "test"}
          >
            <TooltipArrow size={24} />
            {message}
          </AriaKitTooltip>
        )}
        <TooltipAnchor
          render={render}
          onBlur={enhancedOnBlur}
          tabIndex={tabIndex !== -1 ? tabIndex : undefined}
          className={cn(className, {
            [styles["tooltip-anchor"]]: message,
          })}
          focusable={isDisabled || tabIndex !== -1}
          data-testid={testId}
          onMouseEnter={enhancedOnMouseEnter}
          aria-describedby={id}
          {...props}
        >
          {children}
        </TooltipAnchor>
      </TooltipProvider>
    );
  }
);

const MemoizedTooltip = memo(Tooltip) as typeof Tooltip;

export { MemoizedTooltip as Tooltip };
