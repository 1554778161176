import { useMemo } from "react";
import { type usePagination } from "@adaptive/design-system/hooks";
import {
  type BillPaymentListingV2,
  type ListBillPaymentsV2Payload,
} from "@bill-payment/types";
import type { QueryItem } from "@components/table-filter/formatters";
import { type useTableFilters } from "@components/table-filter/table-filter-hooks";
import { useClientInfo } from "@store/user";

import { useListBillPaymentsV2Query } from "../api";

const EMPTY_DATA: BillPaymentListingV2[] = [];

type UseListBillPaymentsV2Props = {
  order?: string;
  query: ListBillPaymentsV2Payload;
  filters?: ReturnType<typeof useTableFilters<QueryItem[]>>["filters"];
  enabled?: boolean;
  pagination: ReturnType<typeof usePagination>;
};

export const useListBillPaymentsV2 = ({
  query,
  order,
  pagination,
  filters = [],
  enabled = true,
}: UseListBillPaymentsV2Props) => {
  const { realmId } = useClientInfo();

  const enhancedFilters = useMemo(() => {
    const params = [
      ...filters.filter(
        (item) =>
          !query.some((queryItem) => queryItem.dataIndex === item.dataIndex)
      ),
      ...query,
    ];

    params.push({ dataIndex: "limit", value: pagination.perPage });
    params.push({ dataIndex: "offset", value: pagination.offset });
    params.push({ dataIndex: "ordering", value: order });

    return params;
  }, [filters, query, pagination.perPage, pagination.offset, order]);

  return useListBillPaymentsV2Query(enhancedFilters, {
    selectFromResult: ({ isFetching, data, isError }) => {
      return {
        data: data?.results || EMPTY_DATA,
        count: data?.count || 0,
        isLoading: isFetching,
        isError,
      };
    },
    skip: !realmId || !enabled,
    refetchOnMountOrArgChange: true,
  });
};
