import React, { useMemo } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  Flex,
  Text,
} from "@adaptive/design-system";
import { formatCurrency, formatDate } from "@adaptive/design-system/utils";
import { type BillPaymentListingV2 } from "@bill-payment/types";
import { LIEN_WAIVER_REQUESTED_STATUS } from "@lien-waiver/constants";
import { OBJECT_ORIGIN } from "@shared/constants";
import {
  GET_HUMAN_READABLE_PAYMENT_STATUS,
  HUMAN_READABLE_PAYMENT_METHOD,
  PAYMENT_STATUS,
  PAYMENT_STATUS_VARIANT_V2,
  PAYMENT_STEP_INFO_STRINGS,
} from "@src/bills/constants";
import { useClientSettings } from "@store/user";
import { formatCard } from "@utils/format-card";
import { sumBy } from "@utils/sumBy";

import type { PaymentStatusKey } from "../../types";

import { getVendorACHLabel } from "./utils";

type BillPaymentInfoProps = {
  billPayment: BillPaymentListingV2;
  onViewPayment: (value: string) => void;
};
type PaymentInfoProps = {
  title: string;
  value: string;
  subtitle?: string;
};

const PaymentInfo = ({ title, value, subtitle }: PaymentInfoProps) => (
  <Flex>
    <Flex width="120px" shrink={false}>
      <Text size="sm" color="neutral-500">
        {title}
      </Text>
    </Flex>
    <Flex direction="column">
      <Text weight="bold" size="lg">
        {value}
      </Text>
      {subtitle && <Text size="sm">{subtitle}</Text>}
    </Flex>
  </Flex>
);

export const BillPaymentInfoV2 = ({
  billPayment,
  onViewPayment,
}: BillPaymentInfoProps) => {
  const { canManageLienWaivers } = useClientSettings();

  const totalRequestedAmount = formatCurrency(
    sumBy(
      (billPayment.lienWaivers ?? [])?.filter(
        (item) =>
          !item?.signedAt &&
          LIEN_WAIVER_REQUESTED_STATUS.some((status) => status === item?.status)
      ) as Record<string, unknown>[],
      "paymentAmount"
    ),
    {
      currencySign: true,
    }
  );

  const totalSignedAmount = formatCurrency(
    sumBy(
      (billPayment.lienWaivers ?? [])?.filter(
        (item) => item?.signedAt
      ) as Record<string, unknown>[],
      "paymentAmount"
    ),
    {
      currencySign: true,
    }
  );

  const {
    totalAmount,
    paymentMethod,
    status,
    vendorBankAccount,
    appliedCardTransaction,
    id: billPaymentId,
    appliedAmount,
    customerBankAccount,
    customerCard,
    customerPaymentAccount,
    isVoided,
    docNumber,
    paymentSchedule,
  } = billPayment;

  const isQBPayment = billPayment?.createdIn === OBJECT_ORIGIN.QUICKBOOKS;

  const account = useMemo(() => {
    const paymentAccountName =
      customerBankAccount?.paymentAccount?.displayName ||
      customerPaymentAccount?.displayName ||
      "";

    const name = customerCard
      ? customerCard?.fullName
      : customerBankAccount?.name;

    const mask = `${customerCard?.mask || customerBankAccount?.mask}`;

    return name
      ? `${formatCard({
          name,
          mask: name?.includes(mask) ? undefined : mask,
        })} ${paymentAccountName ? "-" : ""} ${paymentAccountName}`
      : paymentAccountName;
  }, [
    customerBankAccount?.paymentAccount?.displayName,
    customerBankAccount?.name,
    customerBankAccount?.mask,
    customerPaymentAccount?.displayName,
    customerCard,
  ]);

  const paymentAmount = useMemo(() => {
    if (
      (
        [PAYMENT_STATUS.CANCELLED, PAYMENT_STATUS.FAILED] as PaymentStatusKey[]
      ).includes(status as PaymentStatusKey)
    ) {
      const formattedOriginalTotalAmount = formatCurrency(appliedAmount, {
        currencySign: true,
        allowNegative: true,
      });
      return formattedOriginalTotalAmount;
    }
    const formattedTotalAmount = formatCurrency(totalAmount, {
      currencySign: true,
      allowNegative: true,
    });

    const formattedAmountForBill = formatCurrency(appliedAmount, {
      currencySign: true,
      allowNegative: true,
    });

    // if values differ, it means the payment includes multiple bills
    // and we only want to show the amount paid for this particular bill
    return formattedAmountForBill !== formattedTotalAmount
      ? `${formattedAmountForBill} (of ${formattedTotalAmount} payment)`
      : formattedTotalAmount;
  }, [totalAmount, appliedAmount, status]);

  const payFrom = useMemo(() => {
    if (account) {
      return account;
    } else {
      return PAYMENT_STEP_INFO_STRINGS.EM_DASH;
    }
  }, [account]);

  const payFromSubtitle = useMemo(() => {
    if (paymentSchedule?.processedOn) {
      return formatDate(paymentSchedule?.processedOn, "P");
    } else {
      return PAYMENT_STEP_INFO_STRINGS.EM_DASH;
    }
  }, [paymentSchedule?.processedOn]);

  const payToTitle = useMemo(() => {
    if (appliedCardTransaction) {
      return PAYMENT_STEP_INFO_STRINGS.CARD_TRANSACTION_TITLE;
    } else if (status === PAYMENT_STATUS.SCHEDULED) {
      return PAYMENT_STEP_INFO_STRINGS.PAYMENT_METHOD_TITLE;
    } else if (status === PAYMENT_STATUS.PAID) {
      return PAYMENT_STEP_INFO_STRINGS.PAID_TO_TITLE;
    } else {
      return PAYMENT_STEP_INFO_STRINGS.PAY_TO_TITLE;
    }
  }, [appliedCardTransaction, status]);

  const payTo = useMemo(() => {
    if (vendorBankAccount) {
      return getVendorACHLabel(vendorBankAccount);
    } else if (appliedCardTransaction?.displayName) {
      return appliedCardTransaction.displayName;
    } else {
      return PAYMENT_STEP_INFO_STRINGS.EM_DASH;
    }
  }, [vendorBankAccount, appliedCardTransaction]);

  const payToSubtitle = useMemo(() => {
    if (appliedCardTransaction) {
      return "";
    } else if (paymentSchedule?.deliveredOn) {
      return formatDate(paymentSchedule?.deliveredOn, "P");
    } else if (
      paymentSchedule?.expectedDeliveryAfter &&
      paymentSchedule?.expectedDeliveryBefore
    ) {
      return `${formatDate(
        paymentSchedule?.expectedDeliveryAfter,
        "P"
      )} - ${formatDate(paymentSchedule?.expectedDeliveryBefore, "P")}`;
    } else {
      return PAYMENT_STEP_INFO_STRINGS.EM_DASH;
    }
  }, [
    appliedCardTransaction,
    paymentSchedule?.deliveredOn,
    paymentSchedule?.expectedDeliveryAfter,
    paymentSchedule?.expectedDeliveryBefore,
  ]);

  return (
    <Card as={Flex} direction="column" width="full" size="sm" gap="2xl">
      <Alert
        variant={
          status in PAYMENT_STATUS_VARIANT_V2
            ? PAYMENT_STATUS_VARIANT_V2[status as PaymentStatusKey]
            : "info"
        }
      >
        <AlertTitle>
          <Flex gap="xl" justify="space-between" width="full">
            {GET_HUMAN_READABLE_PAYMENT_STATUS(
              status,
              paymentMethod,
              isVoided,
              isQBPayment
            )}
            <Text as="strong" weight="bold">
              {paymentAmount}
            </Text>
          </Flex>
        </AlertTitle>
      </Alert>
      <Flex
        gap="2xl"
        direction={{ mobile: "column", tablet: "row" }}
        width="full"
        separator={{ mobile: false, tablet: true }}
      >
        <Flex gap="2xl" direction="column" width="full">
          <PaymentInfo
            title={PAYMENT_STEP_INFO_STRINGS.PAYMENT_FROM_TITLE}
            subtitle={payFromSubtitle}
            value={payFrom}
          />
          {payTo !== PAYMENT_STEP_INFO_STRINGS.EM_DASH ? (
            <PaymentInfo
              title={payToTitle}
              value={payTo}
              subtitle={payToSubtitle}
            />
          ) : (
            <PaymentInfo
              title={payToTitle}
              value={
                HUMAN_READABLE_PAYMENT_METHOD[
                  paymentMethod as keyof typeof HUMAN_READABLE_PAYMENT_METHOD
                ]
              }
            />
          )}
        </Flex>
        <Flex gap="2xl" direction="column" width="full">
          {canManageLienWaivers && (
            <PaymentInfo
              title={PAYMENT_STEP_INFO_STRINGS.LIEN_WAIVER_TITLE}
              subtitle={`${totalRequestedAmount} ${PAYMENT_STEP_INFO_STRINGS.LIEN_WAIVER_REQUESTED_TITLE}`}
              value={`${totalSignedAmount} ${PAYMENT_STEP_INFO_STRINGS.LIEN_WAIVER_SIGNED_TITLE}`}
            />
          )}
          <PaymentInfo
            title={PAYMENT_STEP_INFO_STRINGS.PAYMENT_REF_TITLE}
            value={docNumber ? `#${docNumber}` : "—"}
          />
        </Flex>
      </Flex>
      <Button
        block
        variant="ghost"
        onClick={() => onViewPayment(billPaymentId.toString())}
      >
        {canManageLienWaivers
          ? PAYMENT_STEP_INFO_STRINGS.VIEW_PAYMENT_DETAILS_WITH_LW
          : PAYMENT_STEP_INFO_STRINGS.VIEW_PAYMENT_DETAILS}
      </Button>
    </Card>
  );
};
