import formatISO from "date-fns/formatISO";
import dateFnsFormat from "date-fns-tz/format";

import {
  DATE_FORMAT,
  FORMATTED_DATE_LENGTH,
  FORMATTED_RANGE_DATE_LENGTH,
  RANGE_DATE_SEPARATOR,
} from "../../constants";
import { formatNumber } from "../format-number";

export const TEST_NOW_STRING = "2025-01-31T17:11:11-03:00";

export function formatDate(
  value: string | number | null | Date | { from?: Date; to?: Date },
  format: "iso-8601-now" | (string & {}) = DATE_FORMAT
): string {
  if (value instanceof Date) {
    if (format === "iso-8601-now") {
      const now = new Date(
        import.meta.env.MODE === "test" ? TEST_NOW_STRING : Date.now()
      );
      const nowISO = formatISO(now);
      const dateString = dateFnsFormat(value, "yyyy-MM-dd");

      return `${dateString}T${nowISO.split("T")[1]}`;
    }

    return dateFnsFormat(value, format);
  } else if (value instanceof Object) {
    const from = value.from ? formatDate(value.from, format) : undefined;
    const to = value.to ? formatDate(value.to, format) : undefined;

    return from && to ? `${from}${RANGE_DATE_SEPARATOR}${to}` : "";
  }

  if (value === null) return "";

  const formattedDate = formatNumber(value)
    .split("")
    .reduce((acc, char, index) => {
      const isSlash = [2, 4, 10, 12].includes(index);
      const isSeparator = index === 8;

      if (isSlash) return `${acc}/${char}`;

      if (isSeparator) return `${acc}${RANGE_DATE_SEPARATOR}${char}`;

      return `${acc}${char}`;
    }, "");

  return formattedDate.substring(
    0,
    formattedDate.includes(RANGE_DATE_SEPARATOR)
      ? FORMATTED_RANGE_DATE_LENGTH
      : FORMATTED_DATE_LENGTH
  );
}
