import { createContext, useContext } from "react";

type CarouselContextProps = {
  value?: string;
  testId?: string;
};

export const CarouselContext = createContext<CarouselContextProps>({});

export const useCarousel = () => useContext(CarouselContext);
