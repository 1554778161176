import React, { useEffect, useState } from "react";
import { useClientInfo } from "@store/user";

import styles from "./staff-warning.module.css";

type StaffWarningProps = { show: boolean };

export const StaffWarning = ({ show }: StaffWarningProps) => {
  const { client } = useClientInfo();

  const [force, setForce] = useState<"show" | "hide" | undefined>();

  const commitSha = window.COMMIT_SHA ?? "";

  useEffect(() => {
    window.StaffWarning = {
      show: () => setForce("show"),
      hide: () => setForce("hide"),
    };
  }, [setForce]);

  return (
    (show || force === "show") &&
    force !== "hide" && (
      <div className={styles["staff-warning"]}>
        <span className={styles["content"]}>
          Production - {client?.name}
          {client?.integration_type ? ` (${client?.integration_type})` : ""} -{" "}
          <a
            rel="noreferrer"
            href={`https://github.com/AdaptiveZoning/bookkeeping/commit/${commitSha}`}
            target="_blank"
          >
            {commitSha.substring(0, 7)}
          </a>
        </span>
      </div>
    )
  );
};
