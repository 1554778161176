import React, { type ReactNode } from "react";
import {
  Flex,
  Icon,
  type IconProps,
  Tag,
  type TagProps,
} from "@adaptive/design-system";

import { PAYMENT_METHOD, STRINGS } from "../constants";
import { type PaymentMethod, type PaymentStatusKey } from "../types";

type BillPaymentStatusTagProps = {
  status: PaymentStatusKey;
  paymentMethod: PaymentMethod;
  isVoided: boolean;
  isQBPayment: boolean;
};

const PAYMENT_STATUS_VARIANTS: Record<
  PaymentStatusKey,
  {
    color: TagProps["color"];
    icon: IconProps["name"];
    render: (props: Omit<BillPaymentStatusTagProps, "status">) => ReactNode;
  }
> = {
  PAID: {
    color: "success",
    icon: "check-circle",
    render: ({ paymentMethod, isQBPayment }) => {
      if (isQBPayment) {
        return STRINGS.PAID_IN_QUICKBOOKS;
      }
      return paymentMethod === PAYMENT_METHOD.MARK_AS_PAID
        ? STRINGS.MARKED_AS_PAID
        : STRINGS.PAID;
    },
  },
  PENDING: {
    color: "info",
    icon: "refresh",
    render: () => STRINGS.IN_PROGRESS,
  },
  FAILED: {
    color: "error",
    icon: "exclamation-triangle",
    render: () => STRINGS.FAILED,
  },
  CANCELLED: {
    color: "error",
    icon: "circle-x",
    render: ({ isVoided }) => (isVoided ? STRINGS.VOIDED : STRINGS.CANCELLED),
  },
  ACH_REQUESTED: {
    color: "neutral",
    icon: "clock",
    render: () => STRINGS.SCHEDULED,
  },
  PENDING_PREREQUISITES: {
    color: "neutral",
    icon: "clock",
    render: () => STRINGS.SCHEDULED,
  },
  SCHEDULED: {
    color: "neutral",
    icon: "clock",
    render: () => STRINGS.SCHEDULED,
  },
};

export const BillPaymentStatusTag = ({
  status,
  paymentMethod,
  isVoided,
  isQBPayment,
}: BillPaymentStatusTagProps) => {
  const variant = PAYMENT_STATUS_VARIANTS[status as PaymentStatusKey];
  return (
    <Tag color={variant.color}>
      <Flex gap="md">
        <Icon size="sm" name={variant.icon} />
        {variant.render({ paymentMethod, isVoided, isQBPayment })}
      </Flex>
    </Tag>
  );
};
