import React, { useCallback, useMemo, useState } from "react";
import {
  Button,
  Flex,
  Icon,
  Link,
  Loader,
  Table,
  type TableColumn,
  TableConfigurationButton,
  type TablePaginationAddon,
  TableProvider,
  Text,
  TextField,
  toast,
} from "@adaptive/design-system";
import { useDebouncedValue, useEvent } from "@adaptive/design-system/hooks";
import { formatCurrency, formatDate } from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import {
  Sticky,
  StickyMeasurer,
  StickyProvider,
  type StickyProviderOnResizeHandler,
} from "@components/sticky";
import { usePurchaseOrderDrawer } from "@store/ui/hooks";

import { type BuilderTrendPurchaseOrder } from "../api/types";
import { useBuildertrend } from "../hooks/use-buildertrend";

const PurchaseOrderLink = ({
  purchaseOrder,
}: { purchaseOrder: BuilderTrendPurchaseOrder }) => {
  const { show } = usePurchaseOrderDrawer();

  if (!purchaseOrder.purchaseOrder?.id) return null;

  return (
    <Link
      onClick={() => show({ id: purchaseOrder.purchaseOrder?.id })}
      variant="success"
      data-testid="buildertrend-purchase-order-link"
    >
      <Text truncate>{purchaseOrder.purchaseOrder?.docNumber}</Text>
    </Link>
  );
};

const SyncPurchaseOrderButton = ({
  purchaseOrder,
}: { purchaseOrder: BuilderTrendPurchaseOrder }) => {
  const { loadBuilderTrendPurchaseOrder } = useBuildertrend({ skip: true });
  const [isLoading, setIsLoading] = useState(false);

  const curriedLoadBuilderTrendPurchaseOrder = useCallback(
    (row: BuilderTrendPurchaseOrder) => async () => {
      try {
        setIsLoading(true);
        await loadBuilderTrendPurchaseOrder(row.id).unwrap();
        toast.success("BuilderTrend purchase order loaded");
      } catch (error) {
        handleErrors(error);
      } finally {
        setIsLoading(false);
      }
    },
    [loadBuilderTrendPurchaseOrder]
  );

  return (
    <Flex gap="sm">
      <Button
        size="sm"
        color="neutral"
        variant="ghost"
        disabled={isLoading}
        onClick={curriedLoadBuilderTrendPurchaseOrder(purchaseOrder)}
      >
        {isLoading ? (
          <Loader size="sm" />
        ) : purchaseOrder.purchaseOrder?.docNumber ? (
          <>
            <Icon name="arrows-rotate" />
            Sync
          </>
        ) : (
          <>
            <Icon name="plus" />
            Create
          </>
        )}
      </Button>
    </Flex>
  );
};

const COLUMNS: TableColumn<BuilderTrendPurchaseOrder>[] = [
  {
    id: "number",
    name: "BuilderTrend purchase order",
    sortable: true,
    render: (row) => <Text truncate>{row.number}</Text>,
    width: 250,
  },
  {
    id: "title",
    name: "Title",
    sortable: true,
    render: (row) => <Text truncate>{row.title}</Text>,
    width: "fill",
  },
  {
    id: "total_builder_cost",
    name: "Builder cost",
    sortable: true,
    render: (row) => (
      <Text truncate>
        {formatCurrency(row.totalBuilderCost, {
          allowNegative: true,
          currencySign: true,
        })}
      </Text>
    ),
    width: 150,
  },
  {
    id: "amount_paid",
    name: "Paid",
    sortable: true,
    render: (row) => (
      <Text truncate>
        {formatCurrency(row.amountPaid, {
          allowNegative: true,
          currencySign: true,
        })}
      </Text>
    ),
    width: 150,
  },
  {
    id: "amount_remaining",
    name: "Remaining",
    sortable: true,
    render: (row) => (
      <Text truncate>
        {formatCurrency(row.amountRemaining, {
          allowNegative: true,
          currencySign: true,
        })}
      </Text>
    ),
    width: 150,
  },
  {
    id: "total_calculated_amount",
    name: "Calculated amount",
    sortable: true,
    render: (row) => (
      <Text truncate>
        {formatCurrency(row.totalCalculatedAmount, {
          allowNegative: true,
          currencySign: true,
        })}
      </Text>
    ),
    width: 175,
  },
  {
    id: "added",
    name: "Date created",
    sortable: true,
    render: (row) => (row.added ? formatDate(new Date(row.added)) : null),
    width: 150,
  },
  {
    id: "vendor__title",
    name: "BuilderTrend vendor",
    sortable: true,
    render: (row) => <Text truncate>{row.vendor?.title}</Text>,
    width: 250,
  },
  {
    id: "job__job_name",
    name: "BuilderTrend job",
    sortable: true,
    render: (row) => <Text truncate>{row.job?.jobName}</Text>,
    width: 250,
  },
  {
    id: "purchase_order__doc_number",
    name: "Adaptive purchase order",
    sortable: true,
    render: (row) => <PurchaseOrderLink purchaseOrder={row} />,
    width: 250,
  },
  {
    id: "buildertrend-purchase-order-actions",
    name: "Actions",
    width: 130,
    render: (row) => <SyncPurchaseOrderButton purchaseOrder={row} />,
  },
];

export const PurchaseOrdersTable = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("-amount_remaining");

  const [debouncedSearchQuery] = useDebouncedValue(
    searchQuery,
    import.meta.env.MODE === "test" ? 0 : 300
  );

  const sort = useMemo(
    () => ({ value: sortBy, onChange: setSortBy }),
    [sortBy]
  );

  const {
    purchaseOrders,
    purchaseOrdersIsLoading: isLoading,
    pagination,
  } = useBuildertrend({
    purchaseOrdersQuery: debouncedSearchQuery,
    purchaseOrdersSortBy: sortBy,
  });

  const [offset, setOffset] = useState(0);
  const onResize = useEvent<StickyProviderOnResizeHandler>(({ sticky }) => {
    setOffset(sticky.height);
  });

  const tablePagination = useMemo<TablePaginationAddon>(
    () => ({
      page: pagination.page,
      total: purchaseOrders?.count ?? 0,
      perPage: pagination.perPage,
      onChange: pagination.setPage,
      onPerPageChange: (perPage) => {
        pagination.setPerPage(perPage);
        pagination.setPage(0);
      },
    }),
    [pagination, purchaseOrders?.count]
  );

  return (
    <TableProvider id="buildertrend-purchase-orders-table">
      <StickyProvider onResize={onResize}>
        <Sticky
          style={{
            paddingTop: "var(--spacing-2xl)",
            paddingBottom: "var(--spacing-lg)",
          }}
        >
          <Flex width="full" gap="xl">
            <Flex width="full" maxWidth="350px">
              <TextField
                addonAfter={<Icon name="search" />}
                placeholder="Filter purchase orders"
                value={searchQuery}
                onChange={setSearchQuery}
                messageVariant="hidden"
              />
            </Flex>
            <Flex justify="flex-end" grow>
              <TableConfigurationButton />
            </Flex>
          </Flex>
        </Sticky>
        <StickyMeasurer>
          <Table
            sort={sort}
            data={purchaseOrders?.results}
            size="sm"
            header={{ hide: purchaseOrders?.count === 0, sticky: { offset } }}
            loading={isLoading}
            columns={COLUMNS}
            pagination={tablePagination}
          />
        </StickyMeasurer>
      </StickyProvider>
    </TableProvider>
  );
};
