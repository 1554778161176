import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  Loader,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useDialog, useEvent } from "@adaptive/design-system/hooks";
import { suffixify } from "@adaptive/design-system/utils";
import {
  approveBill,
  archiveBill,
  autoSplit,
  convert,
  convertToPurchaseOrder,
  deleteBill,
  exportBills,
  ignoreSyncErrors,
  rejectBill,
  restoreBill,
  updateBill,
} from "@api/bills";
import { useLazyGetBillLinkedInvoicesQuery } from "@api/bills";
import {
  handleErrors,
  parseCustomErrors,
  transformErrorToCustomError,
} from "@api/handle-errors";
import { BatchApplyObject } from "@components/batch-apply-object";
import { ConfirmationDialog } from "@components/confirmation-dialog";
import { DownloadButton } from "@components/download-button";
import { useApplyObject } from "@hooks/use-apply-object";
import { isErrorBill, isErrorBillV2, isLoadingBillOcr } from "@src/bills/utils";
import { api } from "@store/api-simplified";
import {
  fetchAllBills,
  fetchApprovalBills,
  fetchDraftBills,
  fetchForPaymentBills,
} from "@store/billListSlice";
import { BasePermissions, useClientSettings, useUserInfo } from "@store/user";
import { summarizeResults } from "@utils/all-settled";
import * as analytics from "@utils/analytics";
import { noop } from "@utils/noop";
import {
  confirmArchiveWithLinkedInvoices,
  confirmBackToEdit,
  UNLINK_INVOICE_LINES_OPTION,
} from "@utils/transaction-confirm-messages";

import {
  ARCHIVED_VALID_STATUS,
  BILL_STATUS,
  UNSYNCED_QUICKBOOKS_STATUS,
} from "../../constants";

export const BatchActions = ({ status, filters, bills, onAction }) => {
  const dispatch = useDispatch();

  const applyObject = useApplyObject({ items: bills, resource: "bills" });

  const confirmationDialog = useDialog();
  const { canManageLienWaivers, aiAutoSplitEnabled } = useClientSettings();

  const [isLoading, setIsLoading] = useState(false);
  const [triggerGetLinkedInvoices] = useLazyGetBillLinkedInvoicesQuery();
  const [confirmationState, setConfirmationState] = useState({
    title: "",
    message: "",
    onConfirm: noop,
    confirmationButtonText: "",
    confirmationButtonColor: "",
  });

  const onBatchApplyObjectChange = useEvent(async ({ value, fieldName }) => {
    if (!value) return;

    const syncInvoiceLines = false;

    try {
      const { success, errorResponses } = await applyObject.execute({
        value,
        method:
          fieldName === "billable_status"
            ? "apply_billable_status"
            : "apply_object",
        fieldName,
        syncInvoiceLines,
      });

      if (errorResponses.length) {
        errorResponses.forEach((error) => {
          analytics.track("billBatchActionsError", {
            value,
            method:
              fieldName === "billable_status"
                ? "apply_billable_status"
                : "apply_object",
            fieldName,
            syncInvoiceLines,
          });
          handleErrors(error);
        });
      }

      if (success) {
        toast.success(`${success} Bill${success > 1 ? "s" : ""} updated!`);

        if (status === BILL_STATUS.ALL) {
          dispatch(fetchAllBills());
        }

        if ([BILL_STATUS.ALL, BILL_STATUS.APPROVAL].includes(status)) {
          dispatch(fetchApprovalBills());
        }

        if ([BILL_STATUS.ALL, BILL_STATUS.FOR_PAYMENT].includes(status)) {
          dispatch(fetchForPaymentBills());
        }

        if ([BILL_STATUS.ALL, BILL_STATUS.DRAFT].includes(status)) {
          dispatch(fetchDraftBills());
        }
      }

      analytics.track("billBatchActions", {
        value,
        action: fieldName,
        billIds: bills.map(({ id }) => id),
        location: `${status}_tab`,
      });
    } catch {
      toast.error(`Error updating bills`);
    }
  });

  const performAction = useCallback(
    async ({ eventName, action, successMessage, syncOption }) => {
      setIsLoading(true);

      const requests = bills.map(async ({ id, doc_number }) => {
        try {
          return await action(id, syncOption);
        } catch (error) {
          throw transformErrorToCustomError({
            error,
            extra: { docNumber: doc_number },
            render: (message) => `${message} on the following bills:`,
          });
        }
      });

      const { success, successResponses, errorResponses } = summarizeResults(
        await Promise.allSettled(requests)
      );

      const shouldInvalidateCostCodesAccounts = successResponses.some(
        (item) => item?.review_status === BILL_STATUS.FOR_PAYMENT
      );

      if (shouldInvalidateCostCodesAccounts) {
        dispatch(api.util.invalidateTags(["CostCodesAccountsSimplified"]));
      }

      const enhancedErrors = parseCustomErrors({
        errors: errorResponses,
        render: ({ isFirst, message, docNumber }) =>
          `${message}${isFirst ? "" : ","} #${docNumber ?? "UNKNOWN"}`,
      });

      if (enhancedErrors.length) {
        enhancedErrors.forEach((error) =>
          handleErrors(error, { maxWidth: 800, truncate: 2 })
        );
      }

      if (success) {
        toast.success(
          `${success} Bill${success > 1 ? "s" : ""} ${successMessage}!`
        );
      }

      analytics.track("billBatchActions", {
        action: eventName,
        billIds: bills.map(({ id }) => id),
        location: `${status}_tab`,
      });

      setIsLoading(false);
      dispatch(fetchAllBills());
      dispatch(fetchDraftBills());
      dispatch(fetchApprovalBills());
      dispatch(fetchForPaymentBills());
      onAction?.();
    },
    [bills, status, dispatch, onAction]
  );

  const performActionWithConfirmation = useCallback(
    ({
      action,
      syncConfirmation,
      eventName,
      successMessage,
      confirmationTitle,
      confirmationMessage,
      confirmationButtonText,
      confirmationButtonColor,
    }) => {
      triggerGetLinkedInvoices({
        billIds: bills.map(({ id }) => id),
      }).then(({ data }) => {
        const linkedInvoices = data?.invoices ?? [];
        if (linkedInvoices.length && syncConfirmation) {
          syncConfirmation(linkedInvoices, (syncOption) =>
            performAction({
              eventName,
              action,
              successMessage,
              syncOption,
            })
          );
        } else if (confirmationMessage) {
          setConfirmationState({
            title: confirmationTitle ?? "Are you sure?",
            message: confirmationMessage,
            onConfirm: () =>
              performAction({
                eventName,
                action,
                successMessage,
                syncOption: UNLINK_INVOICE_LINES_OPTION.SKIP,
              }).then(() => {
                confirmationDialog.hide();
              }),
            confirmationButtonText,
            confirmationButtonColor,
          });
          confirmationDialog.show();
        } else {
          performAction({
            eventName,
            action,
            successMessage,
            syncOption: UNLINK_INVOICE_LINES_OPTION.SKIP,
          });
        }
      });
    },
    [bills, confirmationDialog, performAction, triggerGetLinkedInvoices]
  );

  const downloadData = useMemo(
    () => [
      {
        label: "Transactions (XLSX)",
        value: "export_xlsx",
      },
      {
        label: "Transaction backup (PDF)",
        value: "export_pdf",
      },
      {
        label: "Transaction backup (ZIP)",
        value: "export_zip",
      },
      ...(canManageLienWaivers &&
      [BILL_STATUS.ALL, BILL_STATUS.FOR_PAYMENT].some(
        (billStatus) => billStatus === status
      )
        ? [
            {
              label: "Signed lien waivers (PDF)",
              value: "export_lien_waivers_pdf",
            },
            {
              label: "Signed lien waivers (ZIP)",
              value: "export_lien_waivers_zip",
            },
          ]
        : []),
    ],
    [canManageLienWaivers, status]
  );

  const onDownload = useEvent(async ({ mode, params }) => {
    params.append("ordering", "-updated_at");

    if (status !== BILL_STATUS.ALL) {
      params.append("archived", false);
      params.append("review_status", status);
    }

    filters.forEach((filter) => {
      params.append(filter.dataIndex, filter.value);
    });

    if (mode === "selection") {
      (bills || []).forEach(({ id }) => params.append("id", id));
    }

    const fileId = await exportBills(params);
    onAction?.(false);
    return fileId;
  });

  const ignoreSyncErrorsAction = useMemo(() => {
    const isInvalid = bills.some(
      (item) => !isErrorBill(item) && !isErrorBillV2(item)
    );

    return {
      label: "Ignore sync errors",
      testId: "ignore-sync-errors",
      action: (id) => ignoreSyncErrors(id),
      tooltip:
        isInvalid &&
        "You can only ignore sync errors on bills that have sync errors",
      disabled: isInvalid,
      eventName: "ignore-sync-errors",
      successMessage: "with sync errors ignored",
    };
  }, [bills]);

  const archiveAction = useMemo(() => {
    const isInvalid = bills.some(
      (item) =>
        item.archived ||
        isLoadingBillOcr(item) ||
        ![
          BILL_STATUS.DRAFT,
          BILL_STATUS.APPROVAL,
          BILL_STATUS.FOR_PAYMENT,
        ].includes(item.reviewStatus)
    );

    return {
      label: "Archive",
      eventName: "archive",
      testId: "archive-bill",
      action: (id, syncOption) => archiveBill(id, syncOption),
      successMessage: "archived",
      tooltip: isInvalid
        ? "You can't archive bills that have already been Archived\n or are in payment process"
        : "",
      disabled: isInvalid,
      syncConfirmation: (linkedInvoices, callback) =>
        confirmArchiveWithLinkedInvoices({
          isPlural: bills.length > 1,
          linkedInvoices,
          note: "Note: Archiving will also delete the transaction from QuickBooks and reopen any linked purchase orders.",
          action: {
            primary: {
              color: "primary",
              onClick: () => callback(UNLINK_INVOICE_LINES_OPTION.DELETE),
              children: "Update line on draw",
            },
            secondary: {
              onClick: () => callback(UNLINK_INVOICE_LINES_OPTION.UNLINK),
              children: "Don't update line on draw",
            },
          },
        }),
      ...(!UNSYNCED_QUICKBOOKS_STATUS.includes(status)
        ? {
            confirmationTitle: (
              <>
                Are you sure you want to <br />
                archive this transaction?
              </>
            ),
            confirmationMessage: (
              <>
                Archiving will also delete the bill from QuickBooks
                <br />
                and reopen any linked purchase orders.
              </>
            ),
            confirmationButtonText: "Archive",
          }
        : {}),
    };
  }, [bills, status]);

  const deleteAction = useMemo(() => {
    const isInvalid = bills.some(
      (item) =>
        (item.reviewStatus !== BILL_STATUS.DRAFT && !item.archived) ||
        (item.archived && !ARCHIVED_VALID_STATUS.includes(item.reviewStatus)) ||
        isLoadingBillOcr(item)
    );

    return {
      label: "Delete",
      color: "error",
      eventName: "delete",
      testId: "delete",
      action: (id) => deleteBill(id),
      successMessage: "deleted",
      tooltip: isInvalid
        ? "For bills that were archived from the Approval or Pay pages, you can only delete Archived bills"
        : "",
      disabled: isInvalid,
      confirmationTitle: (
        <>
          Are you sure you want to <br />
          delete this transaction?
        </>
      ),
      confirmationMessage: "You can't undo this action.",
      confirmationButtonText: "Delete",
    };
  }, [bills]);

  const readyForApprovalAction = useMemo(() => {
    const isInvalid = bills.some(
      (item) =>
        item.reviewStatus !== BILL_STATUS.DRAFT ||
        item.archived ||
        isLoadingBillOcr(item)
    );

    return {
      label: "Ready for approval",
      eventName: "for_approval",
      testId: "ready-for-approval-bill",
      action: (id) => updateBill(id, { review_status: BILL_STATUS.APPROVAL }),
      successMessage: "ready for approval",
      tooltip: isInvalid
        ? "You can only update Drafts or bills Ready for payment"
        : "",
      disabled: isInvalid,
    };
  }, [bills]);

  const approveAction = useMemo(() => {
    const isInvalid = bills.some(
      (item) =>
        item.reviewStatus !== BILL_STATUS.APPROVAL ||
        item.archived ||
        isLoadingBillOcr(item)
    );

    return {
      label: "Approve",
      eventName: "approve",
      testId: "approve-bill",
      action: (id) => approveBill(id),
      successMessage: "approved",
      tooltip: isInvalid ? "You can only approve bills Ready for approval" : "",
      disabled: isInvalid,
    };
  }, [bills]);

  const rejectAction = useMemo(() => {
    const isInvalid = bills.some(
      (item) =>
        item.reviewStatus !== BILL_STATUS.APPROVAL ||
        item.archived ||
        isLoadingBillOcr(item)
    );

    return {
      label: "Reject",
      eventName: "reject",
      testId: "reject-bill",
      action: (id) => rejectBill(id),
      successMessage: "rejected",
      tooltip: isInvalid ? "You can only reject bills Ready for approval" : "",
      disabled: isInvalid,
    };
  }, [bills]);

  const revertToDraftAction = useMemo(() => {
    const isInvalid = bills.some(
      (item) =>
        item.archived ||
        isLoadingBillOcr(item) ||
        ![BILL_STATUS.APPROVAL, BILL_STATUS.FOR_PAYMENT].includes(
          item.reviewStatus
        )
    );

    return {
      label: "Revert to draft",
      eventName: "draft",
      testId: "revert-to-draft-bill",
      action: (id, syncOption) =>
        updateBill(id, {
          review_status: BILL_STATUS.DRAFT,
          unlink_invoice_lines_option: syncOption,
        }),
      successMessage: "reverted to draft",
      tooltip: isInvalid
        ? "You can't update bills that are already Drafts\n or are in payment process"
        : "",
      disabled: isInvalid,
      syncConfirmation: (linkedInvoices, callback) =>
        confirmBackToEdit({
          isPlural: bills.length > 1,
          linkedInvoices,
          action: {
            primary: {
              color: "primary",
              onClick: () => callback(UNLINK_INVOICE_LINES_OPTION.DELETE),
              children: "Delete line on draw",
            },
            secondary: {
              onClick: () => callback(UNLINK_INVOICE_LINES_OPTION.UNLINK),
              children: "Keep line on draw",
            },
          },
        }),
    };
  }, [bills]);

  const restoreAction = useMemo(() => {
    const isInvalid = bills.some(
      (item) =>
        !item.archived ||
        (item.archived && !ARCHIVED_VALID_STATUS.includes(item.reviewStatus)) ||
        isLoadingBillOcr(item)
    );

    return {
      label: "Restore",
      eventName: "restore",
      testId: "restore-bill",
      action: (id) => restoreBill(id),
      successMessage: "restored",
      tooltip: isInvalid && "You can only restore Archived bills",
      disabled: isInvalid,
    };
  }, [bills]);

  const convertAction = useMemo(() => {
    const isInvalid = bills.some((item) => isLoadingBillOcr(item));

    return {
      label: "Re-categorize as a receipt",
      eventName: "re-categorize-as-receipt",
      testId: "convert-bill",
      action: (id) => convert(id),
      successMessage: "re-categorized as a receipt",
      tooltip: isInvalid && "You cannot re-categorize pending bills",
      disabled: isInvalid,
    };
  }, [bills]);

  const { hasPermission } = useUserInfo();
  const canConvertToPo = useMemo(
    () =>
      hasPermission(BasePermissions.ADD_PO) ||
      hasPermission(BasePermissions.MANAGE_POS),
    [hasPermission]
  );

  const convertToPurchaseOrderAction = useMemo(() => {
    const isInvalid = bills.some((item) => isLoadingBillOcr(item));

    return {
      label: "Re-categorize as a purchase order",
      eventName: "re-categorize-as-purchase-order",
      testId: "convert-bill-to-purchase-order",
      action: (id) => convertToPurchaseOrder(id),
      successMessage: "re-categorized as a purchase order",
      tooltip: isInvalid && "You cannot re-categorize pending bills",
      disabled: isInvalid,
    };
  }, [bills]);

  const autoSplitAction = useMemo(() => {
    const isInvalid = bills.some((item) => isLoadingBillOcr(item));

    return {
      label: "Auto-split document",
      eventName: "auto-split",
      testId: "auto-split-bill",
      action: (id) => autoSplit(id),
      successMessage: "auto-split in progress",
      tooltip: isInvalid && "You cannot auto-split pending bills",
      disabled: isInvalid,
      confirmationTitle: <>Split the documents attached to these bills?</>,
      confirmationMessage: (
        <>The original bills will be archived, and new bills will be created.</>
      ),
      confirmationButtonColor: "primary",
    };
  }, [bills]);

  const statusToActions = useMemo(
    () => ({
      [BILL_STATUS.DRAFT]: [
        readyForApprovalAction,
        archiveAction,
        ignoreSyncErrorsAction,
        deleteAction,
        convertAction,
      ]
        .concat(aiAutoSplitEnabled ? [autoSplitAction] : [])
        .concat(canConvertToPo ? [convertToPurchaseOrderAction] : []),
      [BILL_STATUS.APPROVAL]: [
        approveAction,
        rejectAction,
        revertToDraftAction,
        archiveAction,
        ignoreSyncErrorsAction,
        convertAction,
      ].concat(canConvertToPo ? [convertToPurchaseOrderAction] : []),
      [BILL_STATUS.FOR_PAYMENT]: [revertToDraftAction, archiveAction],
      [BILL_STATUS.ALL]: [
        readyForApprovalAction,
        approveAction,
        rejectAction,
        revertToDraftAction,
        archiveAction,
        ignoreSyncErrorsAction,
        restoreAction,
        deleteAction,
      ],
    }),
    [
      rejectAction,
      deleteAction,
      approveAction,
      archiveAction,
      restoreAction,
      convertAction,
      convertToPurchaseOrderAction,
      revertToDraftAction,
      readyForApprovalAction,
      ignoreSyncErrorsAction,
      canConvertToPo,
      autoSplitAction,
      aiAutoSplitEnabled,
    ]
  );

  const statusActions = useMemo(() => {
    return statusToActions[status] || [];
  }, [status, statusToActions]);

  return (
    <Flex margin={["none", "none", "none", "auto"]} gap="md">
      {(applyObject.isLoading || isLoading) && <Loader position="fixed" />}
      <DownloadButton
        mode={{
          all: { enabled: true },
          selection: { enabled: bills?.length > 0 },
        }}
        size="md"
        onDownload={onDownload}
        type={downloadData}
        withDate={[BILL_STATUS.ALL, BILL_STATUS.FOR_PAYMENT].includes(status)}
        data-testid={status}
        location="bills"
      />
      {bills?.length > 0 && statusActions.length > 0 && (
        <Dropdown placement="bottom-end" flip={false} listSize={5}>
          <DropdownTrigger
            as={Button}
            color="primary"
            data-testid={`${status}-actions-trigger`}
          >
            Actions
            <Icon name="ellipsis-vertical" variant="solid" />
          </DropdownTrigger>

          <DropdownList>
            {statusActions.map((action) => (
              <Tooltip
                message={action.tooltip}
                key={suffixify(status, action.testId)}
                placement="left"
              >
                <DropdownItem
                  color={action.color}
                  onClick={() =>
                    performActionWithConfirmation({
                      eventName: action.eventName,
                      action: action.action,
                      syncConfirmation: action.syncConfirmation,
                      successMessage: action.successMessage,
                      confirmationTitle: action.confirmationTitle,
                      confirmationMessage: action.confirmationMessage,
                      confirmationButtonText: action.confirmationButtonText,
                      confirmationButtonColor: action.confirmationButtonColor,
                    })
                  }
                  disabled={action.disabled}
                  data-testid={`${status}-${action.testId}`}
                >
                  {action.label}
                </DropdownItem>
              </Tooltip>
            ))}
            <DropdownList label="Edit properties">
              <DropdownItem>
                <BatchApplyObject
                  onChange={onBatchApplyObjectChange}
                  includeBillableStatus
                  accountFilters={{ only_line_item_accounts: true }}
                />
              </DropdownItem>
            </DropdownList>
          </DropdownList>
        </Dropdown>
      )}
      <ConfirmationDialog
        title={confirmationState.title}
        message={confirmationState.message}
        dialog={confirmationDialog}
        onConfirm={confirmationState.onConfirm}
        confirmButtonColor={
          confirmationState.confirmationButtonColor || "error"
        }
        confirmButtonText={confirmationState.confirmationButtonText}
      />
    </Flex>
  );
};
