import React, {
  type ComponentProps,
  type ComponentRef,
  forwardRef,
  isValidElement,
  memo,
  type PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";
import flattenChildren from "react-keyed-flatten-children";
import cn from "clsx";

import { useEvent } from "../../hooks/use-event";
import { suffixify } from "../../utils/suffixify";
import { Button } from "../button";
import { Icon } from "../icon";
import { Tooltip } from "../tooltip";

import { CarouselContext } from "./carousel-context";
import { type CarouselItemProps } from "./carousel-item";
import styles from "./carousel.module.css";

type DefaultComponent = "div";

type CarouselRef = ComponentRef<DefaultComponent>;

export type CarouselProps = PropsWithChildren<
  ComponentProps<DefaultComponent> & {
    value?: string;
    onChange?: (value: string) => void;
    "data-testid"?: string;
  }
>;

const Carousel = forwardRef<CarouselRef, CarouselProps>(
  (
    { value, onChange, children, className, "data-testid": testId, ...props },
    ref
  ) => {
    const items = useMemo(
      () => flattenChildren(children).filter(isValidElement),
      [children]
    );

    const [internalValue, setInternalValue] = useState(
      value ?? (items[0]?.props as CarouselItemProps).value
    );

    const enhancedValue = value ?? internalValue;

    const enhancedOnChange = useEvent((value: string) => {
      onChange?.(value);
      setInternalValue(value);
    });

    const curriedOnChange = useCallback(
      (value: string) => () => enhancedOnChange(value),
      [enhancedOnChange]
    );

    const onNext = useEvent(() => {
      const currentIndex = items.findIndex(
        (item) => (item.props as CarouselItemProps).value === enhancedValue
      );

      const nextIndex = (currentIndex + 1) % items.length;

      enhancedOnChange((items[nextIndex].props as CarouselItemProps).value);
    });

    const onPrevious = useEvent(() => {
      const currentIndex = items.findIndex(
        (item) => (item.props as CarouselItemProps).value === enhancedValue
      );

      const previousIndex = (currentIndex - 1 + items.length) % items.length;

      enhancedOnChange((items[previousIndex].props as CarouselItemProps).value);
    });

    const context = useMemo(
      () => ({ value: enhancedValue, testId }),
      [testId, enhancedValue]
    );

    return (
      <div
        ref={ref}
        className={cn(styles["carousel"], className)}
        data-testid={testId}
        {...props}
      >
        <ul className={styles["list"]} data-testid={suffixify(testId, "list")}>
          <CarouselContext.Provider value={context}>
            {items}
          </CarouselContext.Provider>
        </ul>
        <nav className={styles["nav"]} data-testid={suffixify(testId, "nav")}>
          <Button
            size="sm"
            color="neutral"
            variant="ghost"
            onClick={onPrevious}
            aria-label="Previous"
            data-testid={suffixify(testId, "previous")}
          >
            <Icon name="chevron-left" />
          </Button>
          <div className={styles["indicators"]}>
            {items.map((item, index) => {
              const { label, value, errorMessage } =
                item.props as CarouselItemProps;

              const isActive = enhancedValue === value;

              return (
                <Tooltip
                  as="button"
                  key={index}
                  type="button"
                  message={errorMessage}
                  onClick={curriedOnChange(value)}
                  className={styles["indicator"]}
                  data-testid={suffixify(testId, `indicator`)}
                  aria-invalid={Boolean(errorMessage)}
                  aria-current={isActive}
                >
                  <Icon
                    size="sm"
                    name={errorMessage ? "exclamation-circle" : "circle"}
                    variant={errorMessage && !isActive ? "regular" : "solid"}
                  />
                  <span className={styles["indicator-name"]}>{label}</span>
                </Tooltip>
              );
            })}
          </div>
          <Button
            size="sm"
            color="neutral"
            variant="ghost"
            onClick={onNext}
            aria-label="Next"
            data-testid={suffixify(testId, "next")}
          >
            <Icon name="chevron-right" />
          </Button>
        </nav>
      </div>
    );
  }
);

Carousel.displayName = "Carousel";

const MemoizedCarousel = memo(Carousel);

export { MemoizedCarousel as Carousel };
