import type { ComponentProps } from "react";
import { type Icon } from "@adaptive/design-system";

import {
  type TimelineEventDiffType,
  type TimelineEventType,
} from "../../api/expenses/types";

export const STRINGS = {
  HEADING: "Activity",
  PLACEHOLDER:
    'Comments are visible to other users in your workspace. Type "@" to directly notify a colleague.',
  PLACEHOLDER_WITH_VENDOR: `Comments are visible to other users in your workspace. Type "@" to directly notify a colleague or the vendor on this transaction.`,
  PLACEHOLDER_FOR_VENDOR_REQUEST: `Comments are visible to other users in your workspace. The vendor of this purchase order will be notified directly.`,
  BUTTON_COMMENT: "Add comment",
  BUTTON_SHOW_MORE: "Show more",
  BUTTON_SHOW_LESS: "Show less",
  DIFFERENT_VENDOR_TOOLTIP:
    "The vendor mentioned in this thread is\n no longer assigned to this transaction",
  TOOLTIP_BUTTON_UPLOAD_FILE:
    "Click to attach a file or \n drag & drop files to quickly upload.",
  TITLE_LIEN_WAIVER: "Lien waiver",
  TITLE_FILE_ATTACHED: "File attached",
};

export const COMMENT_COLOR: Record<
  TimelineEventType,
  "neutral" | "success" | "error" | "info" | "warning"
> = {
  CREATED: "success",
  UPDATED: "success",
  PREDICTION: "warning",
  PREDICTION_MANUAL: "success",
  APPROVED: "success",
  REJECTED: "error",
  RESET_TO_DRAFT: "success",
  RESET_TO_APPROVAL: "success",
  CHECK_PRINTED: "success",
  CHECK_MAILED: "success",
  PAID: "success",
  PAID_BY_ACH: "success",
  PAID_BY_SMS: "success",
  PAID_BY_EMAIL: "success",
  PAYMENT_FAILED: "error",
  PAYMENT_INITIATED: "success",
  TRANSACTION: "success",
  CONVERTED_FROM_BILL: "success",
  CONVERTED_FROM_EXPENSE: "success",
  EMAIL: "success",
  COMMENT: "neutral",
  OPENED: "success",
  CLOSED: "success",
  ACH_PAYMENT_REQUESTED: "success",
  ACH_PAYMENT_CANCELLED: "success",
  PLAID_SYNC: "success",
  EDITED: "success",
  TRANSACTION_MATCH: "success",
  TRANSACTION_VOIDED: "neutral",
  TRANSACTION_LINKED: "neutral",
  TRANSACTION_UNLINKED: "neutral",
  BYPASSED: "success",
  AUTO_APPROVED: "success",
  SYNC_PULL: "success",
  QBDT_SYNC_PULL: "success",
  SYNC_PUSH: "success",
  QBDT_SYNC_PUSH: "success",
  BT_SYNC_PULL: "success",
  ARCHIVED: "neutral",
  VOIDED_IN_QBO: "error",
  DELETED_IN_QBO: "error",
  PAYMENT_VOIDED_IN_QBO: "error",
  PAYMENT_DELETED_IN_QBO: "error",
  PAYMENT_CANCELLED: "error",
  PAYMENT_VOIDED: "error",
  PAYMENT_SCHEDULED: "info",
  ATTACHABLE_ADD: "success",
  ATTACHABLE_DELETE: "error",
  PO_LINKED: "success",
  PO_AUTO_LINKED: "warning",
  PO_UNLINKED: "success",
  EXPORTED: "success",
  REQUEST_FOR_SIGNATURE_SENT: "success",
  PO_MARKED_AS_SIGNED: "success",
  PO_UNMARKED_AS_SIGNED: "neutral",
  PO_SIGNED_BY_VENDOR: "success",
  PO_CONVERTED: "success",
  LIEN_WAIVER_REQUESTED: "info",
  LIEN_WAIVER_NOT_REQUESTED: "success",
  LIEN_WAIVER_MARKED_AS_REQUESTED: "info",
  LIEN_WAIVER_SIGNED: "success",
  LIEN_WAIVER_MARKED_AS_SIGNED: "success",
  LIEN_WAIVER_VIEWED: "info",
  LIEN_WAIVER_REMINDER_SENT: "info",
  LIEN_WAIVER_DELETED: "success",
  AUTO_SPLIT: "success",
  COLLAPSE_LINES: "success",
  RUN_OCR_WITH_LINES: "success",
  SPREADSHEET_IMPORTED: "success",
  PROPAGATION_UPDATED: "success",
  PROPAGATION_UNLINKED_FROM_BILL: "success",
  PROPAGATION_UNLINKED_FROM_PO: "success",
  PROPAGATION_UNLINKED_FROM_INVOICE: "success",
  TOO_MANY_VERSIONS: "neutral",
  SENT_FOR_APPROVAL: "success",
};

export const COMMENT_ICON: Record<
  TimelineEventType,
  ComponentProps<typeof Icon>["name"] | undefined
> = {
  CREATED: "file-plus",
  UPDATED: undefined,
  PREDICTION: "bolt",
  PREDICTION_MANUAL: undefined,
  APPROVED: "thumbs-up",
  REJECTED: "thumbs-down",
  RESET_TO_DRAFT: undefined,
  RESET_TO_APPROVAL: undefined,
  CHECK_PRINTED: undefined,
  CHECK_MAILED: undefined,
  PAID: undefined,
  PAID_BY_ACH: undefined,
  PAID_BY_SMS: undefined,
  PAID_BY_EMAIL: undefined,
  PAYMENT_FAILED: undefined,
  PAYMENT_INITIATED: undefined,
  TRANSACTION: undefined,
  CONVERTED_FROM_BILL: undefined,
  CONVERTED_FROM_EXPENSE: undefined,
  EMAIL: undefined,
  COMMENT: "message",
  OPENED: undefined,
  CLOSED: undefined,
  ACH_PAYMENT_REQUESTED: undefined,
  EDITED: "pen",
  ACH_PAYMENT_CANCELLED: undefined,
  PLAID_SYNC: undefined,
  TRANSACTION_MATCH: undefined,
  TRANSACTION_VOIDED: "xmark",
  TRANSACTION_LINKED: undefined,
  TRANSACTION_UNLINKED: undefined,
  BYPASSED: "arrow-turn-right",
  AUTO_APPROVED: "thumbs-up",
  SYNC_PULL: "download",
  QBDT_SYNC_PULL: "download",
  SYNC_PUSH: "upload",
  QBDT_SYNC_PUSH: "upload",
  BT_SYNC_PULL: "download",
  ARCHIVED: "archive",
  VOIDED_IN_QBO: "x",
  DELETED_IN_QBO: "trash",
  PAYMENT_VOIDED_IN_QBO: "x",
  PAYMENT_DELETED_IN_QBO: "trash",
  PAYMENT_CANCELLED: "x",
  PAYMENT_VOIDED: "x",
  PAYMENT_SCHEDULED: "calendar",
  ATTACHABLE_ADD: "file-plus",
  ATTACHABLE_DELETE: "file-minus",
  PO_LINKED: "link",
  PO_AUTO_LINKED: "link",
  PO_UNLINKED: "link-slash",
  EXPORTED: "print",
  REQUEST_FOR_SIGNATURE_SENT: "paper-plane-top",
  PO_MARKED_AS_SIGNED: "signature",
  PO_UNMARKED_AS_SIGNED: "x",
  PO_SIGNED_BY_VENDOR: "signature",
  PO_CONVERTED: undefined,
  LIEN_WAIVER_REQUESTED: "paper-plane-top",
  LIEN_WAIVER_NOT_REQUESTED: "trash",
  LIEN_WAIVER_MARKED_AS_REQUESTED: "paper-plane-top",
  LIEN_WAIVER_SIGNED: "signature",
  LIEN_WAIVER_MARKED_AS_SIGNED: "signature",
  LIEN_WAIVER_VIEWED: "eye",
  LIEN_WAIVER_REMINDER_SENT: "paper-plane-top",
  LIEN_WAIVER_DELETED: "trash",
  AUTO_SPLIT: "split",
  COLLAPSE_LINES: "arrows-to-line",
  RUN_OCR_WITH_LINES: "arrows-from-line",
  SPREADSHEET_IMPORTED: "arrow-up-from-bracket",
  PROPAGATION_UPDATED: "pen",
  PROPAGATION_UNLINKED_FROM_BILL: "link-slash",
  PROPAGATION_UNLINKED_FROM_PO: "link-slash",
  PROPAGATION_UNLINKED_FROM_INVOICE: "link-slash",
  TOO_MANY_VERSIONS: "ellipsis",
  SENT_FOR_APPROVAL: "share",
};

export const COMMENT_ICON_VARIANT: Record<
  TimelineEventType,
  ComponentProps<typeof Icon>["variant"] | undefined
> = {
  CREATED: undefined,
  UPDATED: undefined,
  PREDICTION: "solid",
  PREDICTION_MANUAL: undefined,
  APPROVED: undefined,
  REJECTED: undefined,
  RESET_TO_DRAFT: undefined,
  RESET_TO_APPROVAL: undefined,
  CHECK_PRINTED: undefined,
  CHECK_MAILED: undefined,
  PAID: undefined,
  PAID_BY_ACH: undefined,
  PAID_BY_SMS: undefined,
  PAID_BY_EMAIL: undefined,
  PAYMENT_FAILED: undefined,
  PAYMENT_INITIATED: undefined,
  TRANSACTION: undefined,
  CONVERTED_FROM_BILL: undefined,
  CONVERTED_FROM_EXPENSE: undefined,
  EMAIL: undefined,
  COMMENT: undefined,
  OPENED: undefined,
  CLOSED: undefined,
  ACH_PAYMENT_REQUESTED: undefined,
  EDITED: undefined,
  ACH_PAYMENT_CANCELLED: undefined,
  PLAID_SYNC: undefined,
  TRANSACTION_MATCH: undefined,
  TRANSACTION_VOIDED: undefined,
  TRANSACTION_LINKED: undefined,
  TRANSACTION_UNLINKED: undefined,
  BYPASSED: undefined,
  AUTO_APPROVED: undefined,
  SYNC_PULL: undefined,
  QBDT_SYNC_PULL: undefined,
  SYNC_PUSH: undefined,
  QBDT_SYNC_PUSH: undefined,
  BT_SYNC_PULL: undefined,
  ARCHIVED: undefined,
  VOIDED_IN_QBO: undefined,
  DELETED_IN_QBO: undefined,
  PAYMENT_VOIDED_IN_QBO: undefined,
  PAYMENT_DELETED_IN_QBO: undefined,
  PAYMENT_CANCELLED: undefined,
  PAYMENT_VOIDED: undefined,
  PAYMENT_SCHEDULED: undefined,
  ATTACHABLE_ADD: undefined,
  ATTACHABLE_DELETE: undefined,
  PO_LINKED: undefined,
  PO_AUTO_LINKED: "solid",
  PO_UNLINKED: undefined,
  EXPORTED: undefined,
  REQUEST_FOR_SIGNATURE_SENT: undefined,
  PO_MARKED_AS_SIGNED: undefined,
  PO_UNMARKED_AS_SIGNED: undefined,
  PO_SIGNED_BY_VENDOR: undefined,
  PO_CONVERTED: undefined,
  LIEN_WAIVER_REQUESTED: undefined,
  LIEN_WAIVER_NOT_REQUESTED: undefined,
  LIEN_WAIVER_MARKED_AS_REQUESTED: undefined,
  LIEN_WAIVER_SIGNED: undefined,
  LIEN_WAIVER_MARKED_AS_SIGNED: undefined,
  LIEN_WAIVER_VIEWED: undefined,
  LIEN_WAIVER_REMINDER_SENT: undefined,
  LIEN_WAIVER_DELETED: undefined,
  AUTO_SPLIT: undefined,
  COLLAPSE_LINES: undefined,
  RUN_OCR_WITH_LINES: undefined,
  SPREADSHEET_IMPORTED: undefined,
  PROPAGATION_UPDATED: undefined,
  PROPAGATION_UNLINKED_FROM_BILL: undefined,
  PROPAGATION_UNLINKED_FROM_PO: undefined,
  PROPAGATION_UNLINKED_FROM_INVOICE: undefined,
  TOO_MANY_VERSIONS: undefined,
  SENT_FOR_APPROVAL: undefined,
};

export const COMMENT_TYPES: Record<TimelineEventType, string> = {
  CREATED: "Created",
  UPDATED: "Updated",
  PREDICTION: "Auto-filled",
  PREDICTION_MANUAL: "Updated",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  BYPASSED: "Approval bypassed",
  RESET_TO_DRAFT: "Sent back to Missing info",
  RESET_TO_APPROVAL: "Changed to approval",
  CHECK_PRINTED: "Check printed",
  CHECK_MAILED: "Check mailed",
  PAID: "Paid",
  PAID_BY_ACH: "Paid by ACH",
  PAID_BY_SMS: "Paid by virtual sms check",
  PAID_BY_EMAIL: "Paid by virtual email check",
  PAYMENT_FAILED: "Payment marked as failed",
  PAYMENT_INITIATED: "Payment initiated",
  TRANSACTION: "Pulled from transaction",
  CONVERTED_FROM_BILL: "Converted from bill",
  CONVERTED_FROM_EXPENSE: "Converted from receipt",
  EMAIL: "Email forwarded",
  COMMENT: "Comment",
  OPENED: "Opened",
  EDITED: "Edited",
  CLOSED: "Closed",
  ACH_PAYMENT_REQUESTED: "ACH payment requested",
  ACH_PAYMENT_CANCELLED: "ACH payment cancelled",
  PLAID_SYNC: "Pulled from your card history",
  TRANSACTION_MATCH: "Matched with a transaction from your card history",
  TRANSACTION_VOIDED: "Matched card transaction voided",
  TRANSACTION_LINKED: "Matched manually with a transaction",
  TRANSACTION_UNLINKED: "Unmatched manually from a transaction",
  AUTO_APPROVED: "Auto approved",
  SYNC_PULL: "Imported from QuickBooks",
  QBDT_SYNC_PULL: "Imported from QuickBooks",
  SYNC_PUSH: "Synced to QuickBooks",
  QBDT_SYNC_PUSH: "Synced to QuickBooks",
  BT_SYNC_PULL: "Imported from BuilderTrend",
  ARCHIVED: "Archived",
  VOIDED_IN_QBO: "Voided in Quickbooks",
  DELETED_IN_QBO: "Deleted in Quickbooks",
  PAYMENT_SCHEDULED: "Payment scheduled",
  PAYMENT_VOIDED_IN_QBO: "Payment voided in Quickbooks",
  PAYMENT_DELETED_IN_QBO: "Payment deleted in Quickbooks",
  PAYMENT_CANCELLED: "Payment cancelled",
  PAYMENT_VOIDED: "Payment voided",
  ATTACHABLE_ADD: "Attachment added",
  ATTACHABLE_DELETE: "Attachment deleted",
  PO_LINKED: "Purchase order linked",
  PO_AUTO_LINKED: "Purchase order auto linked",
  PO_UNLINKED: "Purchase order unlinked",
  EXPORTED: "Exported",
  REQUEST_FOR_SIGNATURE_SENT: "Request for signature sent",
  PO_MARKED_AS_SIGNED: "Marked as signed",
  PO_UNMARKED_AS_SIGNED: "Unmarked as signed",
  PO_SIGNED_BY_VENDOR: "Signed",
  PO_CONVERTED: "Converted to an Adaptive purchase order",
  LIEN_WAIVER_REQUESTED: "Lien waiver request sent",
  LIEN_WAIVER_NOT_REQUESTED: "Lien waiver request cancelled",
  LIEN_WAIVER_MARKED_AS_REQUESTED: "Lien waiver marked as requested",
  LIEN_WAIVER_SIGNED: "Lien waiver signed",
  LIEN_WAIVER_MARKED_AS_SIGNED: "Signed lien waiver uploaded",
  LIEN_WAIVER_VIEWED: "Lien waiver viewed",
  LIEN_WAIVER_DELETED: "Lien waiver deleted",
  LIEN_WAIVER_REMINDER_SENT: "Lien waiver request reminder sent",
  AUTO_SPLIT: "Document split triggered",
  COLLAPSE_LINES: "Lines collapsed",
  RUN_OCR_WITH_LINES: "Lines expanded",
  SPREADSHEET_IMPORTED: "Spreadsheet imported",
  PROPAGATION_UPDATED: "Edited",
  PROPAGATION_UNLINKED_FROM_BILL: "Unlinked from bill",
  PROPAGATION_UNLINKED_FROM_PO: "Unlinked from PO",
  PROPAGATION_UNLINKED_FROM_INVOICE: "Unlinked from draw",
  TOO_MANY_VERSIONS: "Earlier activity hidden for brevity",
  SENT_FOR_APPROVAL: "Sent for approval",
};

export const COMMENT_DIFF_TYPES: Record<TimelineEventDiffType, string> = {
  total_amount: "Total amount",
  vendor: "Vendor",
  doc_number: "Ref #",
  customer: "Jobs",
  item: "Cost codes",
  account: "Accounts",
  description: "Descriptions",
  billable_status: "Billable status",
  amount_to_pay: "Amount to pay",
  assignee: "Assignee",
  attachable: "Attachment",
  date: "Date",
  due_date: "Due date",
  scope_of_work: "Scope of work",
  amount: "Line amount",
};
