import { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import { toast } from "@adaptive/design-system";
import {
  handleErrors,
  isNonFieldErrors,
  parseCustomErrors,
  transformErrorToCustomError,
} from "@api/handle-errors";
import { invalidateBillPaymentDetail } from "@bill-payment/store";
import type { Option } from "@shared/types";
import { api } from "@store/api-simplified";
import { refetchCurrentBill } from "@store/billSlice";
import { refetchCurrentExpense } from "@store/expenses/thunks";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { refetchCurrentPurchaseOrder } from "@store/purchaseOrderSlice";
import { useDrawerVisibility } from "@store/ui";
import { summarizeResults } from "@utils/all-settled";
import { isAccount } from "@utils/is-account";
import { isCostCode } from "@utils/is-cost-code";
import { dispatchAndCollate } from "@utils/thunk";

import { vendorsApi } from "../api/api";
import type { PaymentTerm, Vendor } from "../api/types";
import {
  clearCreationId as clearCreationIdAction,
  createVendor,
  setAchField,
  setAddressField,
  setEmailsField,
  setField,
  setIsSubmitting,
} from "../store/actions";
import {
  selectUnsavedVendorInfoOrBankingChanges,
  selectVendorNeverSaved,
  vendorSelector,
} from "../store/selectors";
import {
  commitAchInfo,
  commitVendor,
  fetchById,
  syncDocuments,
  updateVendor,
} from "../store/thunks";
import type { Stage, VirtualDocument } from "../store/types";

type UseVendorActionParams = {
  onDrawerClose?: () => void;
};

export const useVendorAction = ({
  onDrawerClose,
}: UseVendorActionParams = {}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const isVisibleRef = useRef(false);

  const { setVisible, visible, state } = useDrawerVisibility("vendor");

  const neverSaved = useAppSelector(selectVendorNeverSaved);
  const { info: vendor, documents: vendorDocuments } =
    useAppSelector(vendorSelector);
  const hasUnsavedInfoOrBankingChanges = useAppSelector(
    selectUnsavedVendorInfoOrBankingChanges
  );

  useEffect(() => {
    if (!visible && isVisibleRef.current) {
      onDrawerClose?.();
      isVisibleRef.current = false;
    }
  }, [visible, onDrawerClose]);

  const create = useCallback(
    (displayName = "") => {
      dispatch(
        createVendor({ displayName }, { creationId: new Date().toString() })
      );
      isVisibleRef.current = true;
      setVisible(true);
    },
    [dispatch, setVisible]
  );

  const fetchVendorById = useCallback(
    async (id: string | number, initialStage: Stage = "info") => {
      if (!id) return;
      await dispatch(fetchById({ id, initialStage }));
    },
    [dispatch]
  );

  const saveVendor = useCallback(async (): Promise<boolean> => {
    if (!hasUnsavedInfoOrBankingChanges || state.hasUnsavedDocuments) {
      return true;
    }

    const dependentActions: any[] = [];

    // vendors have information that is stored in different places
    // so, we need to collate the changes and dispatch them in the correct order
    // without issuing unnecessary requests
    const { info, banking } = hasUnsavedInfoOrBankingChanges;

    let createdVendorId = null;

    if (info || neverSaved) {
      try {
        const result = await dispatch(commitVendor()).unwrap();
        createdVendorId = result?.vendor?.id;
      } catch (e) {
        if (isNonFieldErrors(e)) {
          handleErrors(e);
        }
        return false;
      }
    }

    if (neverSaved && vendorDocuments?.length) {
      dependentActions.push(
        syncDocuments({ added: vendorDocuments as VirtualDocument[] })
      );
    }

    if (banking) {
      dependentActions.push(commitAchInfo());
    }

    dispatch(setIsSubmitting(true));

    const { rejected: issues } = await dispatchAndCollate(
      dispatch,
      dependentActions
    );

    if (location.pathname.endsWith("/vendors") && createdVendorId) {
      navigate(`/vendors/${createdVendorId}`);
    }

    if (issues.length) {
      issues.forEach((issue) => {
        if (isNonFieldErrors(issue.payload)) handleErrors(issue.payload);
      });
    } else {
      toast.success(
        `Vendor ${vendor.displayName} ${vendor.url ? "updated" : "created"}`
      );

      /**
       * @todo move async logic to live on redux RTK to
       * avoid this kind of workaround to invalidate cache
       */
      dispatch(
        api.util.invalidateTags([
          "Vendors",
          "VendorsSimplified",
          "CostCodesAccountsSimplified",
        ])
      );
      dispatch(invalidateBillPaymentDetail());

      /**
       * We need this logic below to make sure that we update current transaction
       * to have the latest vendor info, it only runs if this action is called inside a transaction
       */
      await Promise.all([
        dispatch(refetchCurrentBill(["vendor", "bill_payments_v2"])),
        dispatch(refetchCurrentExpense(["vendor"])),
        dispatch(refetchCurrentPurchaseOrder(["vendor"], true)),
        dispatch(vendorsApi.util.invalidateTags(["Vendor"])),
      ]);

      fetchVendorById(vendor.id);
    }

    dispatch(setIsSubmitting(false));

    return issues.length === 0;
  }, [
    location.pathname,
    navigate,
    vendorDocuments,
    hasUnsavedInfoOrBankingChanges,
    state.hasUnsavedDocuments,
    neverSaved,
    dispatch,
    fetchVendorById,
    vendor.id,
    vendor.displayName,
    vendor.url,
  ]);

  const updateVendors = useCallback(
    async (payload: Vendor[]): Promise<boolean> => {
      const requests = payload.map(async (vendor) => {
        try {
          await dispatch(updateVendor(vendor)).unwrap();
        } catch (error) {
          throw transformErrorToCustomError({
            error: { data: error },
            extra: { displayName: vendor.displayName },
            render: (message) => `${message} on the following vendors:`,
          });
        }
      });

      const { success, errorResponses } = summarizeResults(
        await Promise.allSettled(requests)
      );

      dispatch(api.util.invalidateTags(["CostCodesAccountsSimplified"]));

      const enhancedErrors = parseCustomErrors({
        errors: errorResponses,
        render: ({ isFirst, message, displayName }) =>
          `${message}${isFirst ? "" : ","} ${displayName ?? "UNKNOWN"}`,
      });

      if (enhancedErrors.length) {
        enhancedErrors.forEach((error) =>
          handleErrors(error, { maxWidth: 800, truncate: 2 })
        );
      }

      if (success) {
        toast.success(`${payload.length} vendors updated successfully`);
        dispatch(api.util.invalidateTags(["Vendors"]));
        return true;
      }

      return false;
    },
    [dispatch]
  );

  const showVendorById = useCallback(
    async (id: string, initialStage: Stage = "info") => {
      fetchVendorById(id, initialStage);
      isVisibleRef.current = true;
      setVisible(true);
    },
    [fetchVendorById, setVisible]
  );

  const clearCreationId = useCallback(() => {
    dispatch(clearCreationIdAction());
  }, [dispatch]);

  const setAccountNumber = useCallback(
    (accountNumber: string) => {
      dispatch(setAchField({ accountNumber }));
    },
    [dispatch]
  );

  const setDisplayName = useCallback(
    (displayName: string) => {
      dispatch(setField({ displayName }));
    },
    [dispatch]
  );

  const setPhoneNumber = useCallback(
    (phoneNumber: string) => {
      dispatch(setField({ phoneNumber }));
    },
    [dispatch]
  );

  const setEmail = useCallback(
    (email: string) => {
      dispatch(setField({ email }));
    },
    [dispatch]
  );

  const setEmails = useCallback(
    (emails: string[]) => {
      dispatch(setEmailsField(emails));
    },
    [dispatch]
  );

  const setDefaultPaymentDays = useCallback(
    (defaultPaymentDays: number | null) => {
      dispatch(setField({ defaultPaymentDays }));
    },
    [dispatch]
  );

  const setCity = useCallback(
    (city: string) => {
      dispatch(setAddressField({ city }));
    },
    [dispatch]
  );

  const setState = useCallback(
    (state: string) => {
      dispatch(setAddressField({ state }));
    },
    [dispatch]
  );

  const setDefaultCostCodeAccount = useCallback(
    (_: string, option?: Option) => {
      dispatch(
        setField({
          defaultItem: isCostCode(option)
            ? { displayName: option.label, url: option.value }
            : null,
        })
      );
      dispatch(
        setField({
          defaultAccount: isAccount(option)
            ? { displayName: option.label, url: option.value }
            : null,
        })
      );
    },
    [dispatch]
  );

  const setDefaultCostCodeAccounts = useCallback(
    (_: string[], options: Option[]) => {
      dispatch(
        setField({
          defaultItems: (options || []).filter(isCostCode).map((option) => ({
            displayName: option.label,
            url: option.value,
          })),
        })
      );
      dispatch(
        setField({
          defaultAccounts: (options || []).filter(isAccount).map((option) => ({
            displayName: option.label,
            url: option.value,
          })),
        })
      );
    },
    [dispatch]
  );

  const setCommonVendor = useCallback(
    (_: string, option?: Option) => {
      dispatch(setField({ commonVendor: option?.value || null }));
    },
    [dispatch]
  );

  const setRestrictedToContentTypes = useCallback(
    (val: string[]) => {
      dispatch(setField({ types: val }));
    },
    [dispatch]
  );

  const setAddressLine1 = useCallback(
    (line1: string) => {
      dispatch(setAddressField({ line1 }));
    },
    [dispatch]
  );

  const setAddressLine2 = useCallback(
    (line2: string) => {
      dispatch(setAddressField({ line2 }));
    },
    [dispatch]
  );

  const setPaymentTerm = useCallback(
    (val: string, option?: Option<PaymentTerm>) => {
      if (!option) return;
      dispatch(setField({ paymentTerm: option.value }));
    },
    [dispatch]
  );

  const setPostalCode = useCallback(
    (postalCode: string) => {
      dispatch(setAddressField({ postalCode }));
    },
    [dispatch]
  );

  const setRoutingNumber = useCallback(
    (routingNumber: string) => {
      dispatch(setAchField({ routingNumber }));
    },
    [dispatch]
  );

  const setTaxId = useCallback(
    (taxId: string) => {
      dispatch(setField({ taxId }));
    },
    [dispatch]
  );

  return {
    create,
    fetchById: fetchVendorById,
    showVendorById,
    setAccountNumber,
    setAddressLine1,
    setAddressLine2,
    setRestrictedToContentTypes,
    setDisplayName,
    setPhoneNumber,
    setEmail,
    setEmails,
    clearCreationId,
    setDefaultPaymentDays,
    setCity,
    setPaymentTerm,
    setPostalCode,
    setRoutingNumber,
    setState,
    setDefaultCostCodeAccount,
    setDefaultCostCodeAccounts,
    setCommonVendor,
    setTaxId,
    saveVendor,
    updateVendors,
  };
};
