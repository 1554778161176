import { useMemo } from "react";
import { noop } from "@utils/noop";
import { createContext, useContextSelector } from "use-context-selector";

import { BILL_PAYMENTS_TABS, DEFAULT_DATA, DEFAULT_TAB } from "../../constants";
import type { BillPaymentsPageContextType } from "../../types";

export const BillPaymentsPageContext =
  createContext<BillPaymentsPageContextType>({
    scheduled: DEFAULT_DATA,
    inProgress: DEFAULT_DATA,
    all: DEFAULT_DATA,
    tab: DEFAULT_TAB,
    filters: [],
    order: "",
    pagination: {
      perPageVariant: "sm",
      setPerPage: noop,
      setPage: noop,
      perPage: 0,
      page: 1,
      offset: 0,
    },
    setOrder: noop,
    setTab: noop,
    rawFilters: {},
    setFilters: noop,
    tableOffset: 0,
    clearFilters: noop,
    setTableOffset: noop,
  });

export const useHasFilters = () => {
  const filters = useRawFilters();

  const hasFilters = useMemo(() => {
    return Object.values(filters ?? {}).some((filter) => !!filter);
  }, [filters]);

  return hasFilters;
};

export const useHasFilterConflict = () =>
  useFilters().some(
    (query) => query.dataIndex === "user" && query.value === -1
  );

export const usePaymentsTablePagination = () =>
  useContextSelector(BillPaymentsPageContext, (context) => context.pagination);

export const useOrder = () =>
  useContextSelector(BillPaymentsPageContext, (context) => context.order);

export const useCurrentTab = () =>
  useContextSelector(BillPaymentsPageContext, (context) => context.tab);

export const useTableOffset = () =>
  useContextSelector(BillPaymentsPageContext, (context) => context.tableOffset);

export const useFilters = () =>
  useContextSelector(BillPaymentsPageContext, (context) => context.filters);

export const useRawFilters = () =>
  useContextSelector(BillPaymentsPageContext, (context) => context.rawFilters);

export const useTabAwareBillPayments = () =>
  useContextSelector(BillPaymentsPageContext, (context) => {
    switch (context.tab) {
      case BILL_PAYMENTS_TABS.SCHEDULED:
        return context.scheduled;
      case BILL_PAYMENTS_TABS.IN_PROGRESS:
        return context.inProgress;
      default:
        return context.all;
    }
  });

export const useScheduledPayments = () =>
  useContextSelector(BillPaymentsPageContext, (context) => context.scheduled);

export const useInProgressPayments = () =>
  useContextSelector(BillPaymentsPageContext, (context) => context.inProgress);

export const useActions = () =>
  useContextSelector(BillPaymentsPageContext, (context) => ({
    setPage: context.pagination.setPage,
    setOrder: context.setOrder,
    setCurrentTab: context.setTab,
    setPerPage: context.pagination.setPerPage,
    setFilters: context.setFilters,
    clearFilters: context.clearFilters,
    setTableOffset: context.setTableOffset,
  }));
