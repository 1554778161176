import React from "react";
import {
  Flex,
  Icon,
  Link,
  type TableColumn,
  TagGroup,
  Text,
} from "@adaptive/design-system";
import { type MultiplePaymentFormPayment } from "@bill-payment/types";

import {
  AppliedVendorCreditsCell,
  AppliedVendorCreditsFooter,
  DebitDateCell,
  DueDateCell,
  LienWaiverCell,
  LienWaiverHeader,
  PayFromCell,
  RemoveLineCell,
  ThisPaymentCell,
  TotalPaymentAmountFooter,
  VendorCell,
} from "./table-cells";

/**
 * Columns definition
 */
type GetColumnsProps = {
  combined: boolean;
  hasAvailableVendorCredits: boolean;
  canManageLienWaivers: boolean;
};

export const getColumns = ({
  combined,
  hasAvailableVendorCredits,
  canManageLienWaivers,
}: GetColumnsProps) => {
  const baseColumns: TableColumn<MultiplePaymentFormPayment>[] = [
    {
      id: "vendor",
      name: "Vendor",
      width: 250,
      render: (row) => <VendorCell {...row} />,
      footer: <Text />,
    },
  ];

  if (!combined) {
    baseColumns.push({
      id: "ref",
      name: "Ref #",
      width: 150,
      render: (row) => (
        <Link href={`/bills/${row.billId}`} target="_blank" size="sm">
          {`#${row.docNumber}`}
        </Link>
      ),
      footer: <Text />,
    });
  }

  if (hasAvailableVendorCredits) {
    baseColumns.push({
      id: "applied_vendor_credits",
      name: "Vendor credits",
      width: 180,
      render: (row, index) => (
        <AppliedVendorCreditsCell {...row} index={index} />
      ),
      footer: <AppliedVendorCreditsFooter />,
    });
  }

  baseColumns.push(
    {
      id: "this_payment",
      name: "This payment",
      width: 150,
      align: "center",
      highlight: "success",
      render: (row) => <ThisPaymentCell {...row} />,
      footer: (rows) => <TotalPaymentAmountFooter rows={rows} />,
    },
    {
      id: "pay_from",
      name: (
        <Flex align="center" gap="sm">
          <Text weight="bold">Pay from</Text>
          <Icon size="2xs" name="circle" color="error-200" variant="solid" />
        </Flex>
      ),
      minWidth: 280,
      render: (row) => <PayFromCell {...row} />,
      footer: <Text />,
    },
    {
      id: "due_date",
      name: "Due date",
      width: 130,
      render: (row) => <DueDateCell {...row} />,
      footer: <Text />,
    },
    {
      id: "debit_date",
      name: (
        <Flex align="center" gap="sm">
          <Text weight="bold">Debit date</Text>
          <Icon size="2xs" name="circle" color="error-200" variant="solid" />
        </Flex>
      ),
      width: 160,
      render: (row) => <DebitDateCell {...row} />,
      footer: <Text />,
    }
  );

  if (canManageLienWaivers) {
    baseColumns.push({
      id: "lien_waiver",
      minWidth: 250,
      name: <LienWaiverHeader />,
      render: (row) => <LienWaiverCell {...row} />,
      footer: <Text />,
    });
  }

  if (!combined) {
    baseColumns.push({
      id: "jobs",
      name: "Jobs",
      width: 150,
      render: (row) => (
        <TagGroup
          data={row.customers || undefined}
          limit="auto"
          render="displayName"
        />
      ),
      footer: <Text />,
    });
  }

  baseColumns.push({
    id: "remove",
    name: "",
    width: 65,
    render: (row) => <RemoveLineCell {...row} />,
    footer: <Text />,
  });

  return baseColumns;
};
