import React, { memo } from "react";
import { Icon } from "@adaptive/design-system";
import { useEvent, useKeydown } from "@adaptive/design-system/hooks";
import {
  getPlatform,
  hasDialogOpen,
  isField,
} from "@adaptive/design-system/utils";
import * as analytics from "@utils/analytics";

import { useGlobalSearch } from "../global-search/global-search-hooks";

type Props = {
  compact?: boolean;
  visibility?: boolean;
};

const SHORTCUT: Record<
  Exclude<ReturnType<typeof getPlatform>, undefined>,
  string
> = {
  mac: "CMD + K",
  linux: "CTRL + K",
  windows: "CTRL + K",
};

export const SidebarSearch = memo(({ compact }: Props) => {
  const { show } = useGlobalSearch();

  const platform = getPlatform();

  const label = "Search" + (platform ? ` (${SHORTCUT[platform]})` : "");

  const onClick = useEvent(() => {
    analytics.track("searchShow", { trigger: "click" });
    show();
  });

  useKeydown((e) => {
    if (isField(e.target) || hasDialogOpen()) return;

    if (e.code === "KeyK" && (e.metaKey || e.ctrlKey)) {
      analytics.track("searchShow", { trigger: "shortcut" });
      show();
    }
  });

  return (
    <button id="global-search-button" type="button" onClick={onClick}>
      <Icon name="search" />
      {!compact && label}
    </button>
  );
});

SidebarSearch.displayName = "SidebarSearch";
