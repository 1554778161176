import React from "react";
import { TableConfigurationButton } from "@adaptive/design-system";

import { useActions, useRawFilters } from "./context";
import { FilterControls } from "./table-filters";

export const TableActions = () => {
  const filters = useRawFilters();

  const { clearFilters, setFilters } = useActions();

  return (
    <>
      <FilterControls
        filters={filters}
        onClear={clearFilters}
        onChange={setFilters}
      >
        <TableConfigurationButton />
      </FilterControls>
    </>
  );
};
