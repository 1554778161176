import { useCallback, useLayoutEffect, useRef } from "react";

/**
 * A Hook to define an event handler with an always-stable function identity.
 *
 * @todo Replace it as soon as React add it to userland https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
 */
const noop: (...args: any[]) => any = () => {};

export const useEvent = <T extends typeof noop>(handler: T) => {
  const handlerRef = useRef(noop);

  useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  return useCallback<typeof noop>((...args) => {
    const fn = handlerRef.current;

    return fn?.(...args);
  }, []) as T;
};
