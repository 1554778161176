import React, { useCallback, useEffect, useMemo } from "react";
import type { ColumnOrderState, VisibilityState } from "@tanstack/react-table";
import forwardRefAs from "forward-ref-as";

import { useDeepMemo } from "../../hooks/use-deep-memo";
import { useEvent } from "../../hooks/use-event";
import { is } from "../../utils/is";
import { isEqual } from "../../utils/is-equal";
import { suffixify } from "../../utils/suffixify";
import { Button } from "../button";
import {
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownSelectableItem,
  type DropdownSelectableItemOnOrderChangeHandler,
  type DropdownSelectableMultipleData,
  DropdownSeparator,
  DropdownTrigger,
} from "../dropdown";
import { Flex } from "../flex";
import { Icon } from "../icon";
import { Text } from "../text";
import { Tooltip } from "../tooltip";

import { useTableContext } from "./table-context";
import type { Visibility } from "./types";
import { getLeafColumns } from "./utils";

const DEFAULT_COMPONENT = Button;

const VISIBILITY_TO_DISABLED: Record<
  Visibility,
  Exclude<DropdownSelectableMultipleData["disabled"], undefined>
> = {
  hidden: false,
  visible: false,
  "always-visible": true,
  "always-visible-not-orderable": { change: true, order: true },
};

type TableConfigurationButtonProps = { "data-testid"?: string };

export const TableConfigurationButton = forwardRefAs<
  typeof DEFAULT_COMPONENT,
  TableConfigurationButtonProps
>(
  (
    {
      as: Component = DEFAULT_COMPONENT,
      color = "neutral",
      variant = "ghost",
      children,
      "data-testid": testId,
      ...props
    },
    ref
  ) => {
    const {
      id,
      order,
      sticky,
      columns,
      setOrder,
      setSticky,
      visibility,
      stickyEnabled,
      setVisibility,
    } = useTableContext();

    const isCacheUpdatedRef = React.useRef(false);

    const value = useDeepMemo(
      () =>
        Object.entries(visibility ?? {}).reduce(
          (acc, [key, value]) => (value ? [...acc, key] : acc),
          [] as string[]
        ),
      [visibility]
    );

    const leafColumns = useMemo(() => getLeafColumns(columns ?? []), [columns]);

    const enhancedSetSticky = useCallback<Exclude<typeof setSticky, undefined>>(
      (value) =>
        setSticky?.((previousSticky) => {
          const nextSticky = is.function(value) ? value(previousSticky) : value;

          if (isEqual(previousSticky, nextSticky)) return previousSticky;

          localStorage.setItem(
            `${id}-table-left-sticky`,
            JSON.stringify(nextSticky.left)
          );

          return nextSticky;
        }),
      [id, setSticky]
    );

    const curriedOnToggleSticky = useEvent(
      (column: string) => () =>
        enhancedSetSticky?.((previousSticky) => {
          const left = [...(previousSticky?.left ?? [])];

          const index = left.indexOf(column);

          if (index !== -1) {
            left.splice(index, 1);
          } else {
            left.push(column);
          }

          return { ...previousSticky, left };
        })
    );

    const [stickyData, staticData] = useDeepMemo(() => {
      const stickyData: DropdownSelectableMultipleData[] = [];

      const staticData: DropdownSelectableMultipleData[] = [];

      const data = leafColumns
        .map((column) => {
          const label = is.object(column.visibility)
            ? column.visibility.name
            : column.name;
          const parentLabel =
            column.parent?.visibilityName || column.parent?.name;

          const visibility = is.object(column.visibility)
            ? column.visibility.mode
            : column.visibility;

          const isSticky =
            stickyEnabled &&
            (sticky?.left?.includes(column.id) ||
              sticky?.right?.includes(column.id));

          return {
            label: parentLabel ? (
              <Flex direction="column">
                <Text>{label}</Text>
                <Text as="small" size="xs" color="neutral-600">
                  {parentLabel}
                </Text>
              </Flex>
            ) : (
              label
            ),
            value: column.id,
            extra: stickyEnabled
              ? {
                  icon: `thumbtack${isSticky ? "-slash" : ""}`,
                  onClick: curriedOnToggleSticky(column.id),
                }
              : undefined,
            isSticky,
            disabled: visibility ? VISIBILITY_TO_DISABLED[visibility] : false,
          };
        })
        .sort((a, b) => {
          const orderA = order?.indexOf(a.value) ?? 0;
          const orderB = order?.indexOf(b.value) ?? 0;
          return orderA - orderB;
        });

      for (const item of data) {
        if (item.isSticky) {
          stickyData.push({
            ...item,
            disabled: is.boolean(item.disabled)
              ? !item.disabled
                ? { change: true }
                : true
              : { ...item.disabled, change: true },
          } as DropdownSelectableMultipleData);
        } else {
          staticData.push(item as DropdownSelectableMultipleData);
        }
      }

      stickyData.sort((a, b) => {
        const orderA = sticky?.left?.indexOf(a.value) ?? 0;
        const orderB = sticky?.left?.indexOf(b.value) ?? 0;
        return orderA - orderB;
      });

      return [stickyData, staticData];
    }, [order, sticky, stickyEnabled, leafColumns, curriedOnToggleSticky]);

    const [stickyValue, staticValue] = useDeepMemo(() => {
      const stickyValue = [];
      const staticValue = [];

      for (const item of value) {
        const option = staticData.find((column) => column.value === item);

        if (option) staticValue.push(option.value);
      }

      stickyValue.push(...stickyData.map((column) => column.value));

      return [stickyValue, staticValue];
    }, [stickyData, staticData, value]);

    const enhancedSetOrder = useCallback<Exclude<typeof setOrder, undefined>>(
      (value) =>
        setOrder?.((previousOrder) => {
          const nextOrder = is.function(value) ? value(previousOrder) : value;

          if (isEqual(previousOrder, nextOrder)) return previousOrder;

          localStorage.setItem(`${id}-table-order`, JSON.stringify(nextOrder));
          return nextOrder;
        }),
      [id, setOrder]
    );

    const enhancedSetVisibility = useCallback<
      Exclude<typeof setVisibility, undefined>
    >(
      (value) =>
        setVisibility?.((previousVisibility) => {
          const nextVisibility = is.function(value)
            ? value(previousVisibility)
            : value;

          if (isEqual(previousVisibility, nextVisibility)) {
            return previousVisibility;
          }

          localStorage.setItem(
            `${id}-table-visibility`,
            JSON.stringify(nextVisibility)
          );

          return nextVisibility;
        }),
      [id, setVisibility]
    );

    const onChange = useEvent((value: string[]) =>
      enhancedSetVisibility?.((previousVisibility) => {
        const nextVisibility = Object.keys({
          ...(previousVisibility ?? {}),
        }).reduce(
          (acc, key) => ({ ...acc, [key]: stickyValue.includes(key) }),
          {} as VisibilityState
        );

        for (const key of value) {
          nextVisibility[key] = true;
        }

        return nextVisibility;
      })
    );

    const onReset = useEvent(() => {
      const visibility = leafColumns.reduce(
        (acc, column) => ({
          ...acc,
          [column.id]:
            (typeof column.visibility === "object"
              ? column.visibility.mode
              : column.visibility) !== "hidden",
        }),
        {} as VisibilityState
      );

      const sticky = leafColumns.reduce(
        (acc, column) => (column.sticky ? [...acc, column.id] : acc),
        [] as string[]
      );

      localStorage.removeItem(`${id}-table-order`);
      localStorage.removeItem(`${id}-table-visibility`);
      localStorage.removeItem(`${id}-table-left-sticky`);
      setOrder?.(Object.keys(visibility));
      setSticky?.((previousSticky) => ({ ...previousSticky, left: sticky }));
      setVisibility?.(visibility);
    });

    const onStaticOrderChange =
      useEvent<DropdownSelectableItemOnOrderChangeHandler>((value) =>
        enhancedSetOrder(value.map((item) => item.value))
      );

    const onStickyOrderChange =
      useEvent<DropdownSelectableItemOnOrderChangeHandler>((value) =>
        enhancedSetSticky?.((previousSticky) => ({
          ...previousSticky,
          left: value.map((item) => item.value),
        }))
      );

    const EnhancedComponent = useMemo(
      () =>
        forwardRefAs<typeof DEFAULT_COMPONENT>((props, ref) => (
          <Tooltip
            as={Component}
            ref={ref}
            message={!children ? "Configure table" : ""}
            tabIndex={0}
            placement="top"
            aria-label="Configure table"
            {...props}
          >
            {children ?? <Icon name="table" />}
          </Tooltip>
        )),
      [children, Component]
    );

    useEffect(() => {
      if (leafColumns.length === 0) return;

      isCacheUpdatedRef.current = false;

      const orderCache = localStorage.getItem(`${id}-table-order`);
      const stickyCache = localStorage.getItem(`${id}-table-left-sticky`);
      const visibilityCache = localStorage.getItem(`${id}-table-visibility`);

      let order = leafColumns.map((column) => column.id);
      let sticky = leafColumns.reduce(
        (acc, column) => (column.sticky ? [...acc, column.id] : acc),
        [] as string[]
      );

      let visibility = leafColumns.reduce(
        (acc, column) => ({
          ...acc,
          [column.id]:
            (typeof column.visibility === "object"
              ? column.visibility.mode
              : column.visibility) !== "hidden",
        }),
        {} as VisibilityState
      );

      if (stickyCache) {
        const parsedStickyCache = JSON.parse(stickyCache) as string[];
        sticky = [
          ...sticky,
          ...parsedStickyCache.filter((column) => !sticky.includes(column)),
        ];
      }

      if (orderCache) {
        const parsedOrderCache = JSON.parse(orderCache) as ColumnOrderState;
        let mergedCache: ColumnOrderState = [];

        order.forEach((column) => {
          if (!parsedOrderCache.includes(column)) {
            mergedCache.push(column);
          } else {
            const correctPosition = mergedCache.findIndex(
              (item) =>
                parsedOrderCache.indexOf(item) >
                parsedOrderCache.indexOf(column)
            );
            if (correctPosition !== -1) {
              mergedCache = [
                ...mergedCache.slice(0, correctPosition),
                column,
                ...mergedCache.slice(correctPosition),
              ];
            } else {
              mergedCache.push(column);
            }
          }
        });

        order = mergedCache;
      }

      if (visibilityCache) {
        const parsedVisibilityCache = JSON.parse(
          visibilityCache
        ) as VisibilityState;
        const mergedVisibility: VisibilityState = {};

        Object.keys(visibility).forEach((column) => {
          if (column in parsedVisibilityCache) {
            mergedVisibility[column] = parsedVisibilityCache[column];
          } else {
            mergedVisibility[column] = visibility[column];
          }
        });

        visibility = mergedVisibility;
      }

      setSticky?.((previousSticky) => ({ ...previousSticky, left: sticky }));
      setVisibility?.(visibility);
      enhancedSetOrder(order);

      isCacheUpdatedRef.current = true;

      return () => {
        isCacheUpdatedRef.current = false;
      };
    }, [id, leafColumns, enhancedSetOrder, setVisibility, setSticky]);

    useEffect(() => {
      if (!isCacheUpdatedRef.current || stickyValue.length === 0) return;

      enhancedSetVisibility((previousVisibility) => {
        const nextVisibility = { ...previousVisibility };

        for (const key of stickyValue) {
          nextVisibility[key] = true;
        }

        return nextVisibility;
      });
    }, [enhancedSetVisibility, stickyValue]);

    return (
      <Dropdown
        flip={false}
        listSize={5}
        placement="bottom-end"
        hideOnClick={false}
      >
        <DropdownTrigger
          as={EnhancedComponent}
          ref={ref}
          color={color}
          variant={variant}
          data-testid={suffixify(testId, "trigger")}
          {...props}
        />
        <DropdownList>
          <DropdownItem
            onClick={onReset}
            data-testid={suffixify(testId, "reset")}
          >
            Reset columns
          </DropdownItem>
          {stickyData.length > 0 && (
            <>
              <DropdownSelectableItem
                name="table-configuration-sticky"
                data={stickyData}
                value={stickyValue}
                multiple
                data-testid={suffixify(testId, "sticky")}
                onOrderChange={onStickyOrderChange}
              />
              <DropdownSeparator />
            </>
          )}
          <DropdownSelectableItem
            name="table-configuration-static"
            data={staticData}
            value={staticValue}
            multiple
            onChange={onChange}
            data-testid={suffixify(testId, "static")}
            onOrderChange={onStaticOrderChange}
          />
        </DropdownList>
      </Dropdown>
    );
  }
);
