import { useCallback } from "react";
import { type QueryItem } from "@components/table-filter/formatters";
import type {
  Workflow,
  WorkflowApprovalDelete,
  WorkflowApprovalMutation,
  WorkflowApprovals,
  WorkflowsRequest,
} from "@shared/api/workflows";
import { useAppDispatch, useAppSelector } from "@store/hooks";

import {
  duplicateWorkflow as duplicateWorkflowAction,
  editDefaultWorkflow as editDefaultWorkflowAction,
  newDefaultWorkflow as newDefaultWorkflowAction,
  resetCurrentWorkflow as resetCurrentWorkflowAction,
  resetCurrentWorkflowApprovals as resetCurrentWorkflowApprovalsAction,
  resetDefaultWorkflow as resetDefaultWorkflowAction,
  setCurrentWorkflow as setCurrentWorkflowAction,
  setCurrentWorkflowApprovals as setCurrentWorkflowApprovalsAction,
  setPartialCurrentWorkflowApprovals as setPartialCurrentWorkflowApprovalsAction,
  workflowsSelector,
} from "./slice";
import {
  deleteApprovalsWorkflow as deleteApprovalsWorkflowAction,
  deleteWorkflow as deleteWorkflowAction,
  loadWorkflows as loadWorkflowsAction,
  persistWorkflow as persistWorkflowAction,
  persistWorkflowApprovals as persistWorkflowApprovalsAction,
} from "./thunks";
export const useWorkflowAction = () => {
  const dispatch = useAppDispatch();
  const loadWorkflows = useCallback(
    (filters: QueryItem[]) => {
      dispatch(loadWorkflowsAction(filters));
    },
    [dispatch]
  );

  const persistWorkflow = useCallback(
    (payload: WorkflowsRequest) => {
      dispatch(persistWorkflowAction(payload));
    },
    [dispatch]
  );

  const resetCurrentWorkflow = useCallback(() => {
    dispatch(resetCurrentWorkflowAction());
  }, [dispatch]);

  const newDefaultWorkflow = useCallback(() => {
    dispatch(newDefaultWorkflowAction());
  }, [dispatch]);

  const editDefaultWorkflow = useCallback(() => {
    dispatch(editDefaultWorkflowAction());
  }, [dispatch]);

  const deleteWorkflow = useCallback(
    (payload: Workflow) => {
      dispatch(deleteWorkflowAction(payload));
    },
    [dispatch]
  );

  const resetDefaultWorkflow = useCallback(() => {
    dispatch(resetDefaultWorkflowAction());
  }, [dispatch]);

  const setCurrentWorkflow = useCallback(
    (payload: Workflow) => {
      dispatch(setCurrentWorkflowAction(payload));
    },
    [dispatch]
  );

  const setCurrentWorkflowApprovals = useCallback(
    (payload: Workflow | WorkflowApprovals) => {
      dispatch(setCurrentWorkflowApprovalsAction(payload));
    },
    [dispatch]
  );

  const setPartialCurrentWorkflowApprovals = useCallback(
    (payload: Omit<WorkflowApprovals, "id" | "steps">) => {
      dispatch(setPartialCurrentWorkflowApprovalsAction(payload));
    },
    [dispatch]
  );

  const resetCurrentWorkflowApprovals = useCallback(() => {
    dispatch(resetCurrentWorkflowApprovalsAction());
  }, [dispatch]);

  const duplicateWorkflow = useCallback(
    (payload: Workflow) => {
      dispatch(duplicateWorkflowAction(payload));
    },
    [dispatch]
  );

  const persistWorkflowApprovals = useCallback(
    (payload: WorkflowApprovalMutation) =>
      dispatch(persistWorkflowApprovalsAction(payload)),
    [dispatch]
  );

  const deleteApprovalsWorkflow = useCallback(
    (payload: WorkflowApprovalDelete) =>
      dispatch(deleteApprovalsWorkflowAction(payload)).unwrap(),
    [dispatch]
  );

  return {
    deleteWorkflow,
    loadWorkflows,
    persistWorkflow,
    persistWorkflowApprovals,
    duplicateWorkflow,
    resetCurrentWorkflow,
    newDefaultWorkflow,
    editDefaultWorkflow,
    resetDefaultWorkflow,
    setCurrentWorkflow,
    resetCurrentWorkflowApprovals,
    setCurrentWorkflowApprovals,
    setPartialCurrentWorkflowApprovals,
    deleteApprovalsWorkflow,
  } as const;
};

export const useWorkflowInfo = () => {
  const {
    workflows,
    defaultWorkflow,
    currentWorkflow,
    currentWorkflowApprovals,
    duplicateWorkflow,
  } = useAppSelector(workflowsSelector);

  const hasAmountCondition = useCallback(
    (workflow: Workflow): boolean =>
      !!workflow.amountFrom || !!workflow.amountTo,
    []
  );

  const hasBudgetCondition = useCallback(
    (workflow: Workflow): boolean => !!workflow.budgetExceededBy,
    []
  );

  const hasJobCondition = useCallback(
    (workflow: Workflow): boolean => !!workflow.customers.length,
    []
  );

  const hasCostCodeCondition = useCallback(
    (workflow: Workflow): boolean => !!workflow.items.length,
    []
  );

  const hasAccountCondition = useCallback(
    (workflow: Workflow): boolean => !!workflow.accounts.length,
    []
  );

  const hasVendorCondition = useCallback(
    (workflow: Workflow): boolean => !!workflow.vendors.length,
    []
  );

  return {
    workflows,
    defaultWorkflow,
    duplicateWorkflow,
    currentWorkflow,
    currentWorkflowApprovals,
    hasAmountCondition,
    hasBudgetCondition,
    hasJobCondition,
    hasCostCodeCondition,
    hasAccountCondition,
    hasVendorCondition,
  } as const;
};
