import {
  type Formatter,
  getArrayFormatter,
  type QueryItem,
} from "@components/table-filter/formatters";

export const billPaymentsFilterFormatter: Formatter<QueryItem[]> = (
  realmId,
  filters
) => {
  return getArrayFormatter(realmId, filters).map((query) => {
    if (query.dataIndex === "payment_status") {
      return { ...query, dataIndex: "status" };
    }
    return query;
  });
};
