import React from "react";
import { Flex, Tag, Tooltip } from "@adaptive/design-system";
import { formatDate, parseDate } from "@adaptive/design-system/utils";
import { VENDOR_REQUEST_STATUS } from "@components/request-vendor-po-signature/constants";
import { isListEmpty, poSignatureTagColor } from "@utils/usefulFunctions";

import {
  HUMAN_READABLE_PO_SIGNATURE_STATUS,
  PO_SIGNATURE_STATUSES,
} from "./constants";

const customFormatDate = (date: string | Date) =>
  typeof date === "string"
    ? `${formatDate(parseDate(date ?? "", ["yyyy-MM-dd hh:mm", "iso"]), "P")}`
    : formatDate(date, "P");

type SignatureColumnProps =
  | {
      latest_po_signature_request?: {
        status?: string | null;
        created_at?: string | null;
      } | null;
      po_signatures?: {
        signed_by?: string | null;
        created_at?: string | null;
      }[];
    }
  | {
      latestPoSignatureRequest?: {
        status?: string | null;
        createdAt?: Date | null;
      } | null;
      poSignatures?: {
        signedBy?: string | null;
        createdAt?: Date | null;
      }[];
    };

export const SignatureColumn = (props: SignatureColumnProps) => {
  const poSignatures =
    "po_signatures" in props
      ? props.po_signatures
      : "poSignatures" in props
        ? props.poSignatures
        : [];

  const firstPoSignature = poSignatures?.[0];

  const firstPoSignatureSignedBy = firstPoSignature
    ? "signed_by" in firstPoSignature
      ? firstPoSignature?.signed_by
      : "signedBy" in firstPoSignature
        ? firstPoSignature?.signedBy
        : ""
    : "";

  const firstPoSignatureCreatedAt = firstPoSignature
    ? "created_at" in firstPoSignature
      ? firstPoSignature?.created_at
      : "createdAt" in firstPoSignature
        ? firstPoSignature?.createdAt
        : undefined
    : undefined;

  const latestPoSignatureStatus =
    "latest_po_signature_request" in props
      ? props?.latest_po_signature_request?.status
      : "latestPoSignatureRequest" in props
        ? props?.latestPoSignatureRequest?.status
        : undefined;

  const latestPoSignatureCreatedAt =
    "latest_po_signature_request" in props
      ? props?.latest_po_signature_request?.created_at
      : "latestPoSignatureRequest" in props
        ? props?.latestPoSignatureRequest?.createdAt
        : undefined;

  const status = !isListEmpty(poSignatures)
    ? PO_SIGNATURE_STATUSES.SIGNED
    : latestPoSignatureStatus === VENDOR_REQUEST_STATUS.PENDING
      ? PO_SIGNATURE_STATUSES.REQUEST_SENT
      : latestPoSignatureStatus === "EXPIRED"
        ? PO_SIGNATURE_STATUSES.REQUEST_EXPIRED
        : undefined;

  const message =
    status === PO_SIGNATURE_STATUSES.SIGNED
      ? // in case there are multiple signatures, we only show the first one
        firstPoSignatureSignedBy && firstPoSignatureCreatedAt
        ? `${firstPoSignatureSignedBy} (${customFormatDate(firstPoSignatureCreatedAt)})`
        : undefined
      : status === PO_SIGNATURE_STATUSES.REQUEST_SENT ||
          status === PO_SIGNATURE_STATUSES.REQUEST_EXPIRED
        ? latestPoSignatureCreatedAt
          ? customFormatDate(latestPoSignatureCreatedAt)
          : undefined
        : undefined;

  return (
    status && (
      <Flex>
        <Tooltip placement="top" message={message}>
          <Tag
            color={poSignatureTagColor(status)}
            truncate={{ tooltip: false }}
          >
            {HUMAN_READABLE_PO_SIGNATURE_STATUS[status]}
          </Tag>
        </Tooltip>
      </Flex>
    )
  );
};
