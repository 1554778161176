import {
  billPaymentDetailV2Schema,
  cancelBillPaymentResponseSchema,
  createBillPaymentV2ResponseSchema,
  externalBillPaymentResponse,
  listBillPaymentsV2ResponseSchema,
  paymentOptionResponseSchema,
  payNowScheduledPaymentResponseSchema,
} from "@src/bill-payment/api/schemas";
import { api } from "@store/api-simplified";
import { getSearchParamsFromFilters, handleResponse } from "@utils/api";
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";

import type {
  BillPaymentDetailV2,
  BillPaymentV2Payload,
  CancelBillPaymentPayload,
  CancelBillPaymentResponse,
  CreateBillPaymentV2Response,
  ExternalBillPaymentResponse,
  GetBillPaymentDetailPayload,
  GetExternalBillPaymentPayload,
  GetPaymentOptionsPayload,
  ListBillPaymentsV2Payload,
  ListBillPaymentsV2Response,
  PaymentOptionsResponse,
  PayNowScheduledPaymentPayload,
  PayNowScheduledPaymentResponse,
  VoidBillPaymentPayload,
  VoidBillPaymentResponse,
} from "./types";

export const BILL_PAYMENT_DETAIL_TAG = "GetBillPaymentDetail";
export const BILL_PAYMENT_LIST_TAG = "ListBillPaymentsV2";
export const GET_BILL_PAYMENT_OPTIONS_TAG = "GetBillPaymentOptions";

const enhancedApi = api.enhanceEndpoints({
  addTagTypes: [BILL_PAYMENT_DETAIL_TAG, BILL_PAYMENT_LIST_TAG],
});

export const billPaymentApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    listBillPaymentsV2: builder.query<
      ListBillPaymentsV2Response,
      ListBillPaymentsV2Payload
    >({
      query: (queryItems) => {
        const searchParams = getSearchParamsFromFilters(queryItems);

        return {
          url: `v2/billpayments/?${searchParams.toString()}`,
          responseHandler: async (response) => {
            const data = await response.json();
            return handleResponse(
              transformKeysToCamelCase(data),
              listBillPaymentsV2ResponseSchema
            );
          },
        };
      },
      providesTags: [BILL_PAYMENT_LIST_TAG],
    }),
    getBillPaymentOptions: builder.query<
      PaymentOptionsResponse,
      GetPaymentOptionsPayload
    >({
      query: ({ billIds }) => {
        const params = billIds.map((id) => `bill_id=${id}`).join("&");

        return {
          url: `v2/billpayments/payment_options/?${params}`,
          responseHandler: async (response) => {
            const data = await response.json();
            return handleResponse(
              data.map(transformKeysToCamelCase),
              paymentOptionResponseSchema
            );
          },
        };
      },
      providesTags: [GET_BILL_PAYMENT_OPTIONS_TAG],
    }),
    createPaymentV2: builder.mutation<
      CreateBillPaymentV2Response,
      BillPaymentV2Payload[]
    >({
      query: (payload) => {
        const body = payload.map(transformKeysToSnakeCase);
        return {
          url: `v2/billpayments/`,
          method: "POST",
          body,
          responseHandler: async (response) => {
            const data = await response.json();

            return response.status === 200
              ? handleResponse(
                  data.map(transformKeysToCamelCase),
                  createBillPaymentV2ResponseSchema
                )
              : data;
          },
        };
      },
    }),
    getBillPaymentV2: builder.query<
      BillPaymentDetailV2,
      GetBillPaymentDetailPayload
    >({
      query: ({ billPaymentId }) => {
        return {
          url: `v2/billpayments/${billPaymentId}`,
          responseHandler: async (response) => {
            const data = await response.json();
            return handleResponse(
              transformKeysToCamelCase(data),
              billPaymentDetailV2Schema
            );
          },
        };
      },
      providesTags: (response) =>
        response && "id" in response && response?.id
          ? [{ type: BILL_PAYMENT_DETAIL_TAG, id: response.id }]
          : [{ type: BILL_PAYMENT_DETAIL_TAG, id: "DETAIL" }],
    }),
    getExternalBillPayment: builder.query<
      ExternalBillPaymentResponse,
      GetExternalBillPaymentPayload
    >({
      query: ({ billPaymentId }) => {
        return {
          url: `v2/billpayments/${billPaymentId}/external/`,
          responseHandler: async (response) => {
            const data = await response.json();
            return handleResponse(
              transformKeysToCamelCase(data),
              externalBillPaymentResponse
            );
          },
        };
      },
      providesTags: (response) =>
        response && "id" in response && response?.id
          ? [{ type: "GetBillPaymentExternal", id: response.id }]
          : [{ type: "GetBillPaymentExternal", id: "EXTERNAL" }],
    }),
    cancelBillPaymentV2: builder.mutation<
      CancelBillPaymentResponse,
      CancelBillPaymentPayload
    >({
      query: ({ billPaymentId }) => {
        return {
          url: `v2/billpayments/${billPaymentId}/cancel/`,
          method: "POST",
          responseHandler: async (response) => {
            const data = await response.json();
            return handleResponse(
              transformKeysToCamelCase(data),
              cancelBillPaymentResponseSchema
            );
          },
        };
      },
      invalidatesTags: [GET_BILL_PAYMENT_OPTIONS_TAG],
    }),
    voidBillPaymentV2: builder.mutation<
      VoidBillPaymentResponse,
      VoidBillPaymentPayload
    >({
      query: ({ billPaymentId }) => {
        return {
          url: `v2/billpayments/${billPaymentId}/void/`,
          method: "POST",
          responseHandler: async (response) => {
            const data = await response.json();
            return handleResponse(
              transformKeysToCamelCase(data),
              cancelBillPaymentResponseSchema
            );
          },
        };
      },
      invalidatesTags: [GET_BILL_PAYMENT_OPTIONS_TAG],
    }),
    payNowScheduledPaymentV2: builder.mutation<
      PayNowScheduledPaymentResponse,
      PayNowScheduledPaymentPayload
    >({
      query: ({ billPaymentId }) => {
        return {
          url: `v2/billpayments/${billPaymentId}/process_scheduled_payment/`,
          method: "POST",
          responseHandler: async (response) => {
            const data = await response.json();
            return handleResponse(
              transformKeysToCamelCase(data),
              payNowScheduledPaymentResponseSchema
            );
          },
        };
      },
      invalidatesTags: [GET_BILL_PAYMENT_OPTIONS_TAG],
    }),
  }),
});

export const {
  useListBillPaymentsV2Query,
  useGetBillPaymentOptionsQuery,
  useCreatePaymentV2Mutation,
  useGetBillPaymentV2Query,
  useCancelBillPaymentV2Mutation,
  useVoidBillPaymentV2Mutation,
  useGetExternalBillPaymentQuery,
  useLazyGetExternalBillPaymentQuery,
  usePayNowScheduledPaymentV2Mutation,
} = billPaymentApi;
