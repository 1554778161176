import { useEffect, useMemo } from "react";
import {
  type PlaidAccount,
  type PlaidLinkOnSuccessMetadata,
  type PlaidLinkOptions,
  usePlaidLink,
} from "react-plaid-link";
import { toast } from "@adaptive/design-system";
import { captureMessage } from "@sentry/react";
import { useUserInfo } from "@store/user";

import {
  postBankAccount,
  putBankAccount,
  putBankAccountVerificationStatus,
} from "../api/bank-accounts";

const isManuallyVerifiedAccount = (account: PlaidAccount) =>
  ["manually_verified"].includes(account.verification_status);

type PlaidLinkProps = {
  linkToken: string;
  currentClient: {
    id: string;
    name: string;
    email: string;
  };
  onCreationSuccess: () => void;
  onCreationError: (error: Error) => void;
  onLinkExit: () => void;
  accountType: string;
  selectedBankId?: string;
};

type EnhancedPlaidLinkOnSuccessMetadata = PlaidLinkOnSuccessMetadata & {
  account?: PlaidLinkOnSuccessMetadata["accounts"][number];
};

export const PlaidLink = ({
  linkToken,
  onLinkExit,
  accountType,
  currentClient,
  selectedBankId,
  onCreationError,
  onCreationSuccess,
}: PlaidLinkProps) => {
  const { user } = useUserInfo();

  const config = useMemo<PlaidLinkOptions>(
    () => ({
      token: linkToken,
      onExit: async (error) => {
        if (
          error?.error_type === "INVALID_INPUT" &&
          error?.error_code === "MICRODEPOSITS_ALREADY_VERIFIED" &&
          selectedBankId
        ) {
          try {
            await putBankAccountVerificationStatus(selectedBankId, {
              client_id: currentClient.id,
              verification_status: "manually_verified",
            });

            onCreationSuccess();

            toast.success("Bank account verified");
          } catch (e) {
            onCreationError(e as Error);
          }
        }
        onLinkExit();
      },
      onSuccess: async (
        public_token,
        metadata: EnhancedPlaidLinkOnSuccessMetadata
      ) => {
        const enhancedAccount = metadata.account || metadata.accounts?.[0];

        if (isManuallyVerifiedAccount(enhancedAccount) && selectedBankId) {
          try {
            const response = await putBankAccountVerificationStatus(
              selectedBankId,
              {
                client_id: currentClient.id,
                verification_status: enhancedAccount.verification_status,
              }
            );

            onCreationSuccess();

            return response;
          } catch (e) {
            onCreationError(e as Error);
          }
        }

        const params = {
          type: accountType,
          client_id: currentClient.id,
          institution: metadata.institution,
          public_token,
          account_balances: metadata.accounts,
        };

        try {
          const response = await (selectedBankId
            ? putBankAccount(selectedBankId, params)
            : postBankAccount(params));

          onCreationSuccess();

          return response;
        } catch (e) {
          onCreationError(e as Error);
        }
      },
      onEvent: (eventName, metadata) => {
        if (eventName === "ERROR") {
          captureMessage("Plaid link error", {
            user: {
              id: user.id,
              email: user.email,
            },
            tags: {
              link_token: linkToken,
              link_session_id: metadata.link_session_id,
              plaid_error_code: metadata.error_code,
              plaid_error_message: metadata.error_message,
              plaid_error_type: metadata.error_type,
              plaid_institution_id: metadata.institution_id,
              plaid_institution_name: metadata.institution_name,
              client_id: currentClient.id,
              client_name: currentClient.name,
              client_email: currentClient.email,
            },
          });
        }
      },
    }),
    [
      accountType,
      currentClient.email,
      currentClient.id,
      currentClient.name,
      linkToken,
      onCreationError,
      onCreationSuccess,
      onLinkExit,
      selectedBankId,
      user.email,
      user.id,
    ]
  );

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (ready) open();
  }, [open, ready]);

  return null;
};
