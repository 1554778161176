/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useCallback, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { toast } from "@adaptive/design-system";
import { dotObject } from "@adaptive/design-system/utils";
import type {
  AddCommentPayload,
  CheckExpenseDuplicationResponse,
  Expense,
  ExpenseMutateResponse,
} from "@api/expenses";
import { type Option } from "@shared/types/objects";
import { useCycle } from "@src/expenses/cycle-provider";
import { getTableRouterUrlForStatus } from "@src/expenses/utils/table";
import {
  selectExpenseQueryFilters,
  selectExpenseQueryResults,
} from "@store/expenses/selectors-memoized";
import type {
  CollectionQueries,
  CommonFilters,
  QueryFilters,
} from "@store/expenses/types";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { asReferenceNode } from "@utils/converters/utils";
import {
  confirmArchiveExpense,
  confirmArchiveWithLinkedInvoices,
  confirmBackToEdit,
  confirmSyncInvoiceLines,
  UNLINK_INVOICE_LINES_OPTION,
} from "@utils/transaction-confirm-messages";

import type { RootState } from "../types";
import { BasePermissions, useClientSettings, useUserInfo } from "../user";

import { type LineCollection } from "./line-types";
import {
  createIsOwnExpenseSelector,
  expenseSelectors,
  isDirtyLinkedInvoiceLinesSelector,
  staticIsArchivedSelector,
  staticIsTransactionGeneratedDraftSelector,
  staticLinesSelector,
  staticReviewStatusSelector,
} from "./selectors";
import {
  addAttachment,
  addComment,
  addLine,
  adjustTax,
  createNewExpense,
  removeLine,
  setActiveExpense,
  setCommonFilters,
  setField,
  setFilterByStatus,
  setLineField,
} from "./slice";
import {
  commitExpense,
  convertToBill,
  deleteExpense,
  fetchExpenseById,
  queryExpenses,
  queryExpensesWithErrors,
  syncInvoiceChanges,
  uploadAttachable as uploadAttachableAction,
} from "./thunks";

type MutateHandler<Value> = (id: number, value: Value) => void;

export const useTax = () => {
  const dispatch = useAppDispatch();

  const tax = useSelector<RootState>(expenseSelectors.tax);
  const setTax = (amount: number) => dispatch(adjustTax(amount));

  return {
    data: tax,
    taxSelector: expenseSelectors.tax,
    setTax,
  } as const;
};

export const useExpenseLines = () => {
  const lines = useSelector<RootState, LineCollection>(
    (s) => expenseSelectors.lines(s) || {}
  );

  const staticLines = useSelector<RootState, LineCollection>(
    (s) => staticLinesSelector(s) || {}
  );

  const getStaticLine = useCallback(
    (lineId: string) => staticLines[lineId],
    [staticLines]
  );

  const dispatch = useAppDispatch();

  const addNewLine = useCallback(() => dispatch(addLine()), [dispatch]);

  const removeLineById = useCallback(
    (id: (typeof ids)[number]) => {
      dispatch(removeLine(id));
    },
    [dispatch]
  );

  const setJob = useCallback<MutateHandler<Option | undefined>>(
    (id, value) => {
      dispatch(setLineField({ id, customer: asReferenceNode(value) }));
    },
    [dispatch]
  );

  const setAmount = useCallback<MutateHandler<number>>(
    (id, value) => {
      dispatch(setLineField({ id, amount: value }));
    },
    [dispatch]
  );

  const setBillable = useCallback<MutateHandler<boolean>>(
    (id, value) => {
      const staticLine = getStaticLine(id);
      dispatch(
        setLineField({
          id,
          billableStatus: !value
            ? "NotBillable"
            : staticLine?.billableStatus &&
                staticLine?.billableStatus !== "NotBillable"
              ? staticLine?.billableStatus
              : "Billable",
        })
      );
    },
    [dispatch, getStaticLine]
  );

  const setDescription = useCallback<MutateHandler<string>>(
    (id, value) => {
      dispatch(setLineField({ id, description: value }));
    },
    [dispatch]
  );

  const setCostAttribution = useCallback<MutateHandler<Option | undefined>>(
    (id, value) => {
      const attribution = asReferenceNode(value);
      dispatch(setLineField({ id, attribution }));
    },
    [dispatch]
  );

  return {
    lines,
    setJob,
    setAmount,
    addNewLine,
    setBillable,
    removeLineById,
    setDescription,
    setCostAttribution,
  };
};

export const useExpensePermissions = () => {
  const { user } = useUserInfo();

  const isOwnExpenseSelector = useMemo(
    () => createIsOwnExpenseSelector(user),
    [user]
  );

  const isOwnExpense = useSelector(isOwnExpenseSelector);

  const { hasPermission } = useUserInfo();

  const canAddExpense = useMemo(
    () => hasPermission(BasePermissions.ADD_EXPENSE),
    [hasPermission]
  );

  const canEditExpense = useMemo(
    () =>
      (canAddExpense && isOwnExpense) ||
      hasPermission(BasePermissions.EDIT_ALL_EXPENSES),
    [canAddExpense, isOwnExpense, hasPermission]
  );

  const canViewExpense = useMemo(
    () =>
      (canAddExpense && isOwnExpense) ||
      hasPermission(BasePermissions.VIEW_ALL_EXPENSES),
    [canAddExpense, isOwnExpense, hasPermission]
  );

  const canFlagExpense = useMemo(
    () =>
      (canAddExpense && isOwnExpense) ||
      hasPermission(BasePermissions.FLAG_EXPENSES),
    [canAddExpense, isOwnExpense, hasPermission]
  );

  const canCommentExpense = useMemo(
    () => hasPermission(BasePermissions.COMMENT_EXPENSE),
    [hasPermission]
  );

  const canPublishExpense = useMemo(
    () => hasPermission(BasePermissions.PUBLISH_EXPENSES),
    [hasPermission]
  );

  return {
    canAddExpense,
    canEditExpense,
    canViewExpense,
    canFlagExpense,
    canCommentExpense,
    canPublishExpense,
  };
};

/**
 * @todo figure out how to get rid of this ref, we did it
 * to prevent rerendering of expense form component, we
 * may need to refactor all the logic to be able to optimize it
 */
const useSaveInfoRef = () => {
  const infoRef = useRef({
    reviewStatus: "DRAFT",
    linkedInvoices: [],
    isDirtyInvoicedLines: false,
    staticReviewStatus: "DRAFT",
    staticIsArchived: false,
    isTransactionGeneratedDraft: false,
  });

  useSelector<RootState>((state) => {
    infoRef.current.reviewStatus = expenseSelectors.reviewStatus(state);
    infoRef.current.linkedInvoices = expenseSelectors.linkedInvoices(state);
    infoRef.current.isDirtyInvoicedLines =
      isDirtyLinkedInvoiceLinesSelector(state);
    infoRef.current.staticReviewStatus = staticReviewStatusSelector(state);
    infoRef.current.staticIsArchived = staticIsArchivedSelector(state);
    infoRef.current.isTransactionGeneratedDraft =
      staticIsTransactionGeneratedDraftSelector(state);
  });

  return infoRef;
};

export const useExpenseAction = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const cycle = useCycle();

  const { cardFeedEnabled } = useClientSettings();

  const { canEditExpense } = useExpensePermissions();

  const { state: locationState } = useLocation();

  const saveInfoRef = useSaveInfoRef();

  const uploadAttachable = useCallback(
    (file: File) => dispatch(uploadAttachableAction(file)),
    [dispatch]
  );

  const saveComment = useCallback(
    (comment: AddCommentPayload) => dispatch(addComment(comment)),
    [dispatch]
  );

  const createNew = useCallback(() => {
    return dispatch(createNewExpense()).unwrap();
  }, [dispatch]);

  const fetchById = useCallback(
    async (expenseId?: string | number) => {
      if (!expenseId || expenseId === "") return;

      await dispatch(fetchExpenseById(expenseId)).unwrap();
    },
    [dispatch]
  );

  const syncDrawChanges = useCallback(
    async (expenseId: string | undefined) => {
      if (!expenseId || expenseId === "") return;

      await dispatch(syncInvoiceChanges(expenseId)).unwrap();
    },
    [dispatch]
  );

  const loadTableData = useCallback(() => {
    dispatch(queryExpenses("DRAFT"));
    dispatch(queryExpenses("FOR_REVIEW"));
    dispatch(queryExpenses("ALL"));
  }, [dispatch]);

  const navigateTo = useCallback(
    (id?: string | number, replace?: boolean) => {
      if (id) navigate(`../${id}`, { replace });
    },
    [navigate]
  );

  const query = useCallback(
    (filters?: { [s: string]: number | string | boolean }) => {
      dispatch(queryExpenses(filters || {}));
    },
    [dispatch]
  );

  const queryErrors = useCallback(() => {
    dispatch(queryExpensesWithErrors());
  }, [dispatch]);

  const deleteExpenseAction = useCallback(async () => {
    if (canEditExpense) {
      await dispatch(deleteExpense({}));
    }
  }, [dispatch, canEditExpense]);

  const convertToBillAction = useCallback(
    async (expenseId) => {
      if (canEditExpense) {
        const { id: billId } = await dispatch(
          convertToBill({ id: expenseId })
        ).unwrap();
        navigate(`/bills/${billId}`, { replace: true });
      }
    },
    [navigate, dispatch, canEditExpense]
  );

  const saveExpense = useCallback(
    async (options?: {
      setStatus: Expense["reviewStatus"];
      isArchived?: boolean;
    }): Promise<ExpenseMutateResponse> => {
      return new Promise((resolve) => {
        const handler = async ({
          syncInvoiceLines = false,
          unlinkInvoiceLinesOption = UNLINK_INVOICE_LINES_OPTION.SKIP,
        } = {}) => {
          const data = await dispatch(
            commitExpense({
              ...options,
              syncInvoiceLines,
              unlinkInvoiceLinesOption,
            })
          ).unwrap();
          await fetchById(data.id);
          loadTableData();
          resolve(data);
        };

        const editingReviewedReceipt =
          saveInfoRef.current.reviewStatus === "REVIEWED" &&
          saveInfoRef.current.isDirtyInvoicedLines;

        const linkedReceiptToDraft =
          saveInfoRef.current.staticReviewStatus === "REVIEWED" &&
          (saveInfoRef.current.reviewStatus === "DRAFT" ||
            options?.setStatus === "DRAFT") &&
          saveInfoRef.current.linkedInvoices.length > 0;

        const archivingReceipt =
          !!options?.isArchived && !saveInfoRef.current.staticIsArchived;

        if (editingReviewedReceipt) {
          return confirmSyncInvoiceLines({
            linkedInvoices: saveInfoRef.current.linkedInvoices,
            action: {
              primary: {
                color: "primary",
                onClick: () => handler({ syncInvoiceLines: true }),
                children: "Update line on draw",
              },
              secondary: {
                onClick: () => handler(),
                children: "Don't update line on draw",
              },
            },
          });
        }

        if (linkedReceiptToDraft) {
          return confirmBackToEdit({
            linkedInvoices: saveInfoRef.current.linkedInvoices,
            action: {
              primary: {
                color: "primary",
                onClick: () =>
                  handler({
                    unlinkInvoiceLinesOption:
                      UNLINK_INVOICE_LINES_OPTION.DELETE,
                  }),
                children: "Delete line on draw",
              },
              secondary: {
                onClick: () =>
                  handler({
                    unlinkInvoiceLinesOption:
                      UNLINK_INVOICE_LINES_OPTION.UNLINK,
                  }),
                children: "Keep line on draw",
              },
            },
          });
        }

        if (archivingReceipt) {
          const receiptLinkedToInvoices =
            saveInfoRef.current.reviewStatus === "REVIEWED" &&
            saveInfoRef.current.linkedInvoices.length > 0;

          if (receiptLinkedToInvoices) {
            return confirmArchiveWithLinkedInvoices({
              linkedInvoices: saveInfoRef.current.linkedInvoices,
              note: "Note: Archiving will also delete the transaction from QuickBooks.",
              action: {
                primary: {
                  color: "primary",
                  onClick: () =>
                    handler({
                      unlinkInvoiceLinesOption:
                        UNLINK_INVOICE_LINES_OPTION.DELETE,
                    }),
                  children: "Delete line on draw",
                },
                secondary: {
                  onClick: () =>
                    handler({
                      unlinkInvoiceLinesOption:
                        UNLINK_INVOICE_LINES_OPTION.UNLINK,
                    }),
                  children: "Keep line on draw",
                },
              },
            });
          }

          return confirmArchiveExpense({
            action: {
              primary: {
                color: "error",
                onClick: () => {
                  handler({});
                },
                children: "Archive",
              },
            },
          });
        }

        handler({});
      });
    },
    [dispatch, fetchById, loadTableData, saveInfoRef]
  );

  const publish = useCallback(async () => {
    const data = await saveExpense({ setStatus: "REVIEWED" });

    toast.success(`Receipt #${data.docNumber} was synced`);

    if (!cycle.status || cycle.status === "ALL") return cycle.disable();

    const hasNext = cycle.hasNavigation && (await cycle.next());

    if (hasNext) {
      toast.info("Moved to the next receipt waiting for review");
      return;
    }

    if (!cardFeedEnabled || !saveInfoRef.current.isTransactionGeneratedDraft) {
      toast.success("You reviewed all receipts that require your review");
      navigate("/expenses?status=review");
    }
  }, [saveExpense, cycle, navigate, saveInfoRef, cardFeedEnabled]);

  const unPublish = useCallback(async () => {
    const { id } = await saveExpense({ setStatus: "DRAFT" });
    navigateTo(id);
  }, [navigateTo, saveExpense]);

  const setArchived = useCallback(
    (isArchived: boolean) => {
      dispatch(setField({ isArchived }));
    },
    [dispatch]
  );

  const unArchive = useCallback(async () => {
    cycle.disable();
    setArchived(false);
    const { id } = await saveExpense({ setStatus: "DRAFT" });
    navigateTo(id);
  }, [cycle, navigateTo, saveExpense, setArchived]);

  const archive = useCallback(async () => {
    const { reviewStatus } = await saveExpense({ isArchived: true });
    setArchived(true);
    navigate(
      dotObject.get(
        locationState as object,
        "prev",
        getTableRouterUrlForStatus(reviewStatus)
      )
    );
  }, [locationState, navigate, saveExpense, setArchived]);

  const saveAttachment = useCallback(
    (attachable: Expense["attachables"][number]) => {
      dispatch(addAttachment(attachable));
    },
    [dispatch]
  );

  const setDate = useCallback(
    (date: Date | null) => {
      dispatch(setField({ date }));
    },
    [dispatch]
  );

  const setDuplicate = useCallback(
    (duplicate: CheckExpenseDuplicationResponse["duplicate"]) => {
      dispatch(setField({ duplicate }));
    },
    [dispatch]
  );

  const setDocumentNumber = useCallback(
    (docNumber: string | undefined) => {
      dispatch(setField({ docNumber }));
    },
    [dispatch]
  );

  const setAssignee = useCallback(
    (val: string, option?: Option) => {
      dispatch(setField({ assignee: asReferenceNode(option) }));
    },
    [dispatch]
  );

  const setPaymentAccount = useCallback(
    (_: string, option?: Option) => {
      dispatch(setField({ paymentAccount: asReferenceNode(option) }));
    },
    [dispatch]
  );

  const setCardTransaction = useCallback(
    (_: string, option?: Option) => {
      const value = asReferenceNode(option);

      dispatch(
        setField({ cardTransaction: value ? { ...option, ...value } : null })
      );
    },
    [dispatch]
  );

  const setVendor = useCallback(
    (val: string, option?: Option) => {
      dispatch(setField({ vendor: asReferenceNode(option) }));
    },
    [dispatch]
  );

  const setStatus = useCallback(
    async (status: Expense["reviewStatus"]) => {
      await saveExpense({ setStatus: status });
    },
    [saveExpense]
  );

  const setExpense = useCallback(
    (raw: unknown) => {
      if (!raw) return;
      dispatch(setActiveExpense(raw));
    },
    [dispatch]
  );

  return {
    createNew,
    fetchById,
    setStatus,
    deleteExpense: deleteExpenseAction,
    convertToBill: convertToBillAction,
    loadTableData,
    navigateTo,
    publish,
    query,
    queryErrors,
    saveAttachment,
    setDuplicate,
    saveComment,
    saveExpense,
    setArchived,
    setDate,
    setDocumentNumber,
    setExpense,
    setPaymentAccount,
    setCardTransaction,
    setAssignee,
    setVendor,
    archive,
    uploadAttachable,
    unArchive,
    unPublish,
    syncDrawChanges,
  };
};

const isUnchangedSubset = (
  a: Partial<CommonFilters>,
  b: Partial<CommonFilters>
) => {
  return Object.keys(a).every((key) => {
    if (a[key] instanceof Set) {
      if (a[key].size !== b[key]?.size) return false;
      return [...a[key]].every((val) => b[key].has(val));
    }
    return a[key] === b[key];
  });
};

export const useExpenseQuery = <T extends CollectionQueries>(status: T) => {
  const dispatch = useAppDispatch();

  const { loadTableData } = useExpenseAction();

  const select = useMemo(() => {
    const query = (state: RootState) =>
      selectExpenseQueryResults(state, status);

    const filters = (state: RootState) =>
      selectExpenseQueryFilters(state, status);

    return { query, filters };
  }, [status]);

  const queryState = useAppSelector(select.query);
  const currentFilters = useAppSelector(select.filters);

  const setFilters = useCallback(
    (filters: Partial<CommonFilters>, avoidFetchStatus: CollectionQueries) => {
      if (isUnchangedSubset(filters, currentFilters)) {
        return;
      }

      dispatch(setCommonFilters(filters));
      ["ALL", "DRAFT", "FOR_REVIEW"].forEach((status) => {
        if (avoidFetchStatus !== status) {
          dispatch(queryExpenses(status));
        }
      });
    },
    [currentFilters, dispatch]
  );

  const setQueryFilters = useCallback(
    (
      filters: Partial<QueryFilters<T>>,
      options: { status?: CollectionQueries; replace?: boolean } = {}
    ) => {
      const { status: rewriteStatus, replace } = {
        status,
        replace: false,
        ...options,
      };
      dispatch(setFilterByStatus({ status: rewriteStatus, filters, replace }));
      return dispatch(queryExpenses(rewriteStatus)).unwrap();
    },
    [dispatch, status]
  );

  return {
    setFilters,
    setQueryFilters,
    query: loadTableData,
    filters: currentFilters,
    state: queryState,
  };
};
