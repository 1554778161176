import parse from "date-fns/parse";
import parseISO from "date-fns/parseISO";

import { DATE_FORMAT, RANGE_DATE_SEPARATOR } from "../../constants";

const isValidDate = (date: Date | Date[]) =>
  (Array.isArray(date) ? date : [date]).every(
    (item) => item.toString() !== "Invalid Date"
  );

export const parseDate = (
  value: string,
  format: string | "iso" | string[] = DATE_FORMAT
) => {
  let result: Date | null = null;

  for (const attempt of Array.isArray(format) ? format : [format]) {
    const parseFn = (value: string) =>
      attempt === "iso" ? parseISO(value) : parse(value, attempt, new Date());

    try {
      if (value.includes(RANGE_DATE_SEPARATOR)) {
        const [from, to] = value.split(RANGE_DATE_SEPARATOR);
        const parsedFrom = parseFn(from);
        const parsedTo = parseFn(to);

        if (!isValidDate([parsedFrom, parsedTo])) {
          throw new Error("Invalid date");
        }

        return { from: parsedFrom, to: parsedTo };
      }

      const parsedDate = parseFn(value);

      if (!isValidDate(parsedDate)) throw new Error("Invalid date");

      result = parsedDate;

      break;
    } catch {
      result = null;
    }
  }

  return result;
};
