import React, { memo, useMemo, useState } from "react";
import { Link as ReactRouterLink } from "react-router";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  Flex,
  Link,
  Table as DSTable,
  TableConfigurationButton,
  type TablePaginationAddon,
  TableProvider,
  type TableRowAddon,
  VisuallyHidden,
} from "@adaptive/design-system";
import { useEvent, usePagination } from "@adaptive/design-system/hooks";
import {
  Sticky,
  StickyMeasurer,
  StickyProvider,
  type StickyProviderOnResizeHandler,
} from "@components/sticky";
import { TableFilterControls } from "@components/table-filter";
import { setFormatter } from "@components/table-filter/formatters";
import { useTableFilters } from "@components/table-filter/table-filter-hooks";
import { useClientSettings } from "@store/user";
import * as analytics from "@utils/analytics";
import { getSearchParams } from "@utils/api";
import { scrollMainTop } from "@utils/scroll-main-top";

import { useGetVendorsQuery } from "../api/api";
import type { VendorsVendor } from "../api/types";
import {
  ACH_STATUS_OPTIONS,
  DOCUMENT_STATUS_OPTIONS,
  DOCUMENT_TYPE_OPTIONS,
  SYNC_STATUS_OPTIONS,
} from "../constants/constants";
import { useVendorAction } from "../hooks/use-vendor-action";

import { PageBatchActions } from "./page-batch-actions";
import { getColumns } from "./page-table-columns";

const DOC_TYPE_FILTER = DOCUMENT_TYPE_OPTIONS.map((option) => ({
  ...option,
  groupLabel: "Document type",
}));

const DOC_STATUS_FILTER = DOCUMENT_STATUS_OPTIONS.map((option) => ({
  ...option,
  groupLabel: "Document status",
}));

const ACH_STATUS_FILTER = ACH_STATUS_OPTIONS.map((option) => ({
  ...option,
  groupLabel: "ACH status",
}));

const SYNC_STATUS_FILTER = SYNC_STATUS_OPTIONS.map((option) => ({
  ...option,
  groupLabel: "Sync status",
}));

const EXTRA_DATA = [
  ...DOC_TYPE_FILTER,
  ...DOC_STATUS_FILTER,
  ...ACH_STATUS_FILTER,
  ...SYNC_STATUS_FILTER,
];

export const PageTable = memo(() => {
  const { create } = useVendorAction();

  const { failSafeCostCodesEnabled } = useClientSettings();

  const [order, setOrder] = useState("display_name");

  const [stickOffset, setStickyOffset] = useState(0);

  const [selectedRows, setSelectedRows] = useState<VendorsVendor[]>([]);

  const { filters, setFilters, rawFilters } = useTableFilters({
    storageKey: "vendors",
    formatter: setFormatter,
  });

  const pagination = usePagination({ localStorageKey: "vendors-table-limit" });

  const showSyncErrors = useEvent(() => {
    const hasSyncErrorFilter = SYNC_STATUS_FILTER.find(
      (filter) => filter.value === "HAS_SYNC_ERRORS"
    );

    if (hasSyncErrorFilter) {
      onFiltersChange({ [hasSyncErrorFilter?.value]: hasSyncErrorFilter });
    }
  });

  const searchParams = useMemo(() => {
    const enhancedFilters = { ...filters };

    if (enhancedFilters?.vendor) {
      enhancedFilters.id = enhancedFilters.vendor;
    }
    // since we're in vendors, we should replace vendor key with 'id'
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return getSearchParams({
      ...enhancedFilters,
      limit: pagination.perPage,
      offset: pagination.offset,
      ordering: order,
    });
  }, [order, filters, pagination.offset, pagination.perPage]);

  const vendors = useGetVendorsQuery(searchParams.toString(), {
    // force refetch here to mitigate an issue
    // with the Table component where it will only re-calculate
    // column widths on changes to "loading" prop, and not data prop
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const hasSyncErrors = vendors.data?.hasSyncErrors;

  const data = useMemo(() => vendors.data?.results || [], [vendors.data]);

  const onFiltersChange = useEvent((filters) => {
    setSelectedRows([]);
    setFilters(filters);
    pagination.setPage(0);
  });

  const row = useMemo<TableRowAddon<VendorsVendor>>(
    () => ({
      render: ({ row, props }) => (
        <ReactRouterLink {...props} to={`/vendors/${row.id}`}>
          <VisuallyHidden>Open {row.displayName}</VisuallyHidden>
        </ReactRouterLink>
      ),
      isError: (row) =>
        (row.errors ?? []).length > 0 &&
        row.errors?.some((error) => !error.isIgnored),
    }),
    []
  );

  const vendorsRefetch = vendors.refetch;

  const hasData = !!data.length;

  const header = useMemo(
    () => ({ hide: !hasData, sticky: { offset: stickOffset } }),
    [hasData, stickOffset]
  );

  const onResize = useEvent<StickyProviderOnResizeHandler>(({ sticky }) => {
    setStickyOffset(sticky.height);
  });

  const select = useMemo(
    () => ({ value: selectedRows, onChange: setSelectedRows }),
    [setSelectedRows, selectedRows]
  );

  const enhancedPagination = useMemo<Required<TablePaginationAddon>>(
    () => ({
      page: pagination.page,
      total: vendors.data?.count || 0,
      perPage: pagination.perPage,
      perPageVariant: "lg",
      onChange: (page: number) => {
        scrollMainTop(0);
        pagination.setPage(page);
      },
      onPerPageChange: (perPage: number) => {
        pagination.setPage(0);
        pagination.setPerPage(perPage);
        analytics.track("perPageLimitChange", {
          location: "vendors-table",
          limit: perPage,
        });
      },
    }),
    [pagination, vendors.data?.count]
  );

  const hasFilters = Object.values(rawFilters).some((filter) => !!filter);

  const onClearFiltersClick = useEvent(() => {
    onFiltersChange({});
  });

  const sort = useMemo(
    () => ({ value: order, onChange: setOrder }),
    [order, setOrder]
  );

  const emptyState = useMemo(() => {
    if (vendors.isError) return "error";

    if (hasFilters) {
      return {
        title: "No vendors match your filters",
        subtitle:
          "Try adjusting your search or filters to find what you're looking for",
        action: {
          children: "Clear filters",
          onClick: onClearFiltersClick,
        },
      };
    }

    return {
      title: "You currently have no vendors",
      subtitle: "Create your first vendor",
      action: {
        children: "Create new vendor",
        onClick: () => create(),
      },
      hasError: vendors.isError,
    };
  }, [create, hasFilters, onClearFiltersClick, vendors.isError]);

  const columns = useMemo(() => {
    return getColumns({ failSafeCostCodesEnabled });
  }, [failSafeCostCodesEnabled]);

  return (
    <Flex gap="xl" direction="column" shrink={false}>
      {hasSyncErrors && (
        <Alert variant="warning">
          <AlertTitle>
            Some of your vendors did not sync with QuickBooks
          </AlertTitle>
          <AlertContent>
            <Link as="button" type="button" onClick={showSyncErrors}>
              View vendors with sync errors
            </Link>
          </AlertContent>
        </Alert>
      )}
      <Flex direction="column">
        <TableProvider id="vendors-table">
          <StickyProvider onResize={onResize}>
            <Sticky
              style={{
                marginTop: "calc(var(--spacing-2xl) * -1)",
                paddingTop: "var(--spacing-2xl)",
                paddingBottom: "var(--spacing-lg)",
              }}
            >
              <Flex gap="md" direction="column">
                <TableFilterControls
                  onFiltersChange={onFiltersChange}
                  filters={rawFilters}
                  extraData={EXTRA_DATA}
                  includeFilters={["customers", "vendors"]}
                  withFilterTags
                >
                  {hasFilters && (
                    <Button onClick={onClearFiltersClick}>Clear filters</Button>
                  )}

                  <Flex grow gap="md" justify="flex-end">
                    <TableConfigurationButton />
                    <PageBatchActions
                      vendors={selectedRows}
                      filters={filters}
                      onAction={(clearSelections = true) => {
                        vendorsRefetch();
                        if (clearSelections) setSelectedRows([]);
                      }}
                    />
                  </Flex>
                </TableFilterControls>
              </Flex>
            </Sticky>
            <StickyMeasurer>
              <DSTable
                row={row}
                size="sm"
                sort={sort}
                data={data}
                header={header}
                select={select}
                columns={columns}
                loading={vendors.status === "pending"}
                pagination={enhancedPagination}
                emptyState={emptyState}
                data-testid="vendors-table"
              />
            </StickyMeasurer>
          </StickyProvider>
        </TableProvider>
      </Flex>
    </Flex>
  );
});

PageTable.displayName = "PageTable";
