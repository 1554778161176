import React, { useCallback, useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Table,
  type TableSelectAddon,
  type TableSortAddon,
  Text,
} from "@adaptive/design-system";
import { useDeepMemo, type useDialog } from "@adaptive/design-system/hooks";
import { sortBy } from "@adaptive/design-system/utils";
import { type DynamicBalanceVendorCredit } from "@bill-payment/types";

import { applyVendorCreditsForBalanceAmount } from "../../utils";

import { getColumns } from "./table-columns";

export type VendorCreditSelectionDialogProps = {
  dialog: ReturnType<typeof useDialog>;
  vendorCredits: DynamicBalanceVendorCredit[];
  openBalanceAmount: string | number;
  vendorName: string;
  initialSelectedVendorCredits?: DynamicBalanceVendorCredit[];
  onSave: (selectedVendorCredits: DynamicBalanceVendorCredit[]) => void;
};

export const VendorCreditSelectionDialog = ({
  dialog,
  vendorCredits,
  vendorName,
  initialSelectedVendorCredits,
  onSave,
  openBalanceAmount = "0",
}: VendorCreditSelectionDialogProps) => {
  const [selectedVendorCredits, setSelectedVendorCredits] = useState<
    DynamicBalanceVendorCredit[]
  >(initialSelectedVendorCredits || []);
  const [ordering, setOrdering] = useState("createdAt");

  const enhancedSetSelectedVendorCredits = useCallback(
    (newVendorCredits: DynamicBalanceVendorCredit[]) => {
      if (!selectedVendorCredits.length && !newVendorCredits.length) {
        return;
      }

      const { appliedVendorCredits } = applyVendorCreditsForBalanceAmount({
        vendorCredits: newVendorCredits,
        balanceAmount: openBalanceAmount,
      });

      setSelectedVendorCredits(
        appliedVendorCredits.filter((vc) => !!vc.appliedAmount)
      );
    },
    [openBalanceAmount, selectedVendorCredits.length]
  );

  const enhancedVendorCredits = useDeepMemo(() => {
    const withAppliedCredits = vendorCredits.map((vendorCredit) => {
      const selectedVendorCredit = selectedVendorCredits.find(
        (vc) => vc.id === vendorCredit.id
      );

      if (selectedVendorCredit) {
        return selectedVendorCredit;
      }

      return { ...vendorCredit, appliedAmount: 0 };
    });

    const withSorting = sortBy(withAppliedCredits, ordering.replace(/^-/, ""), {
      direction: ordering.startsWith("-") ? "desc" : "asc",
    });
    return withSorting;
  }, [ordering, selectedVendorCredits, vendorCredits]);

  const billBalanceAfterCredits = useMemo(() => {
    return selectedVendorCredits.reduce(
      (acc, vendorCredit) => acc + (vendorCredit.appliedAmount || 0),
      Number.parseFloat(openBalanceAmount.toString())
    );
  }, [selectedVendorCredits, openBalanceAmount]);

  const columns = useMemo(() => getColumns(), []);

  const selectAddon = useMemo<TableSelectAddon<DynamicBalanceVendorCredit>>(
    () => ({
      value: selectedVendorCredits,
      onChange: enhancedSetSelectedVendorCredits,
      isDisabled: (row) => {
        if (!row.appliedAmount && !row.currentOpenBalance) {
          return "Vendor credit has no open balance";
        }
        if (!row.appliedAmount && !billBalanceAfterCredits) {
          return "Bill is already fully paid with credits";
        }
        return false;
      },
    }),
    [
      selectedVendorCredits,
      enhancedSetSelectedVendorCredits,
      billBalanceAfterCredits,
    ]
  );

  const sortAddon = useMemo<TableSortAddon>(
    () => ({ value: ordering, onChange: setOrdering }),
    [ordering]
  );

  const emptyState = useMemo(() => {
    return {
      title: "You currently have no available credits for this vendor",
    };
  }, []);

  return (
    <Dialog
      size="auto"
      show={dialog.isVisible}
      variant="dialog"
      onClose={() => dialog.hide()}
    >
      <DialogHeader>
        <Flex direction="column" gap="md">
          Available vendor credits
          {vendorName && <Text size="md">{vendorName}</Text>}
        </Flex>
      </DialogHeader>
      <DialogContent>
        <Table
          data={enhancedVendorCredits}
          columns={columns}
          select={selectAddon}
          sort={sortAddon}
          emptyState={emptyState}
        />
      </DialogContent>
      <DialogFooter>
        <Button
          block
          variant="text"
          color="neutral"
          onClick={() => dialog.hide()}
        >
          Cancel
        </Button>
        <Button
          block
          onClick={() => {
            dialog.hide();
            onSave(selectedVendorCredits);
          }}
        >
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
