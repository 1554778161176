import { workflowStepSchema } from "@api/workflows/response";
import { lienWaiverStatusUpperSchema } from "@bill-payment/api";
import { arraySchema, dateSchema, idSchema, userSchema } from "@utils/schema";
import { z } from "zod";

export const reviewStatusSchema = z.enum([
  "ALL",
  "PAID",
  "DRAFT",
  "APPROVAL",
  "FOR_PAYMENT",
  "PAYMENT_FAILED",
  "PARTIALLY_PAID",
  "ACH_PROCESSING",
  "ACH_INFO_REQUESTED",
  "PAYMENT_SCHEDULED",
]);

export const fileSyncStatus = z.enum([
  "requested",
  "in-progress",
  "done",
  "failed",
  "discarded",
  "timeout",
  "deferred",
  "none",
]);

export const duplicateSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  docNumber: z.string(),
  reviewStatus: reviewStatusSchema,
  humanReadableType: z.enum(["Bill", "Vendor Credit"]),
});

export const checkBillDuplicationResponseSchema = z.object({
  duplicate: arraySchema(duplicateSchema),
});

export const linkedInvoicesResponseSchema = z.object({
  invoices: arraySchema(
    z.object({
      id: idSchema,
      docNumber: z.string(),
      customerId: idSchema,
      humanReadableType: z.enum(["Draw"]),
      url: z.string().url(),
    })
  ),
});

export const accountSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  displayName: z.string(),
});

export const itemSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  description: z.string().nullish(),
  disabled: z.boolean(),
  displayName: z.string(),
});

export const customerSimpleSchema = z.object({
  id: idSchema.nullish(),
  url: z.string().url().nullish(),
  displayName: z.string().nullish(),
  displayNameWithoutCompany: z.string().nullish(),
  disabled: z.boolean().nullish(),
});

export const vendorSchema = z.object({
  id: idSchema.nullish(),
  url: z.string().nullish(),
  displayName: z.string().nullish(),
  email: z.string().email().nullish(),
});

export const attachableSchema = z.object({
  id: idSchema,
  url: z.string(),
  thumbnail: z.string().nullish(),
  documentType: z.string().nullish(),
  email: z.string().email().nullish(),
});

const approvalWorkflowSchema = z.object({
  id: idSchema,
  steps: arraySchema(workflowStepSchema),
});

const approvalSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  status: z.enum(["APPROVED", "REJECTED"]),
  approvedBy: userSchema,
  roles: arraySchema(
    z.object({ id: idSchema, name: z.string(), url: z.string().url() })
  ),
});

const lienWaiverSchema = z.object({
  id: idSchema,
  customer: customerSimpleSchema.nullish(),
  paymentAmount: z.string().nullish(),
  signedAt: dateSchema.nullish(),
  status: lienWaiverStatusUpperSchema,
  statusLabel: z.string(),
  updatedAt: dateSchema.nullish(),
});

const openBalanceByCustomerSchema = z.object({
  customerId: idSchema,
  balance: z.string(),
});

export const billListItemSchema = z.object({
  accounts: arraySchema(accountSchema),
  amountToPay: z.string().nullish(),
  approvalWorkflows: arraySchema(approvalWorkflowSchema),
  approvals: arraySchema(approvalSchema),
  archived: z.boolean().nullish(),
  attachables: arraySchema(attachableSchema),
  balance: z.string().nullish(),
  createdAt: dateSchema,
  createdBy: userSchema.nullish(),
  customers: arraySchema(customerSimpleSchema),
  date: z.string().nullish(),
  docNumber: z.string().nullish(),
  dueDate: z.string().nullish(),
  errorsIgnoredExist: z.boolean().nullish(),
  errorsNotIgnoredExist: z.boolean().nullish(),
  fileSyncStatus: fileSyncStatus.nullish(),
  humanReadableType: z.string().nullish(),
  id: idSchema,
  isRejected: z.boolean().nullish(),
  isVendorCredit: z.boolean().nullish(),
  items: arraySchema(itemSchema),
  lienWaivers: arraySchema(lienWaiverSchema),
  linesCount: z.number().nullish(),
  privateNote: z.string().nullish(),
  publishToQuickbooks: z.boolean().nullish(),
  reviewStatus: reviewStatusSchema,
  totalAmount: z.string().nullish(),
  url: z.string().url().nullish(),
  vendor: vendorSchema.nullish(),
  customer: customerSimpleSchema.optional(),
  index: z.number().nullish(),
});

export const billListSchema = arraySchema(billListItemSchema);

export const billBalanceByCustomerSchema = z.object({
  id: idSchema,
  openBalance: arraySchema(openBalanceByCustomerSchema).nullish(),
});

export const billListBalanceByCustomerSchema = arraySchema(
  billBalanceByCustomerSchema
);
