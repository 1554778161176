import { is } from "@adaptive/design-system/utils";
import { createSelector, type Selector } from "@reduxjs/toolkit";
import type { RootState } from "@store/types";
import { camelToSpaceCase } from "@utils/schema/converters";

import { ROUTE_LABEL } from "../constants/constants";
import {
  type ChangeSet,
  type EditDocument,
  type SavedDocument,
  type VirtualDocument,
} from "../store/types";
import { getInitialState } from "../utils/get-initial-state";

import { type Form, type VendorState } from "./slice";

export const USER_FRIENDLY_ERRORS = {
  "The fields realm, display_name must make a unique set":
    "A vendor with this name already exists",
} as const;

export type UserFriendlyErrorKey = keyof typeof USER_FRIENDLY_ERRORS;

export const getUserFriendlyError = (error: string) =>
  USER_FRIENDLY_ERRORS[error as UserFriendlyErrorKey] || error;

const initialState = getInitialState();

type VendorKey = keyof VendorState["form"];

type VendorSelector<T extends VendorKey> = (
  s: RootState
) => VendorState["form"][T];

type SelectorMap<T extends { [key: string]: unknown }> = {
  [Field in keyof T]: Selector<RootState, T[Field]>;
};

export const vendorSelector: Selector<RootState, VendorState["form"]> = (
  state: RootState
) => state.vendors.form;

const getVendorFieldSelector: <T extends VendorKey>(s: T) => VendorSelector<T> =
  (field) => (s) =>
    vendorSelector(s)[field];

export const vendorSelectors = Object.keys(initialState.form).reduce(
  (selectors, key) => ({
    ...selectors,
    [key]: getVendorFieldSelector(key as VendorKey),
  }),
  {}
) as SelectorMap<VendorState["form"]>;

export const selectVendorFetchStatus = createSelector(
  (state: RootState) => state.vendors.fetchStatus,
  (fetchStatus) => fetchStatus
);

export const creationId = (state: RootState) => state.vendors.creationId;

export const creationInfoSelector = createSelector(
  (state: RootState) => state.vendors.creationId,
  (state: RootState) => state.vendors.transactions,
  (creationId, transactions) => (creationId ? transactions[creationId] : null)
);

const recursiveGetChangedFields = <T extends Record<string, unknown>>(
  a: T,
  b: T
) => {
  const keys = Object.keys(a);
  const changedKeys: string[] = keys.filter((key) => {
    const itemA = a?.[key];
    const itemB = b?.[key];

    if (is.object(itemA) && is.object(itemB)) {
      return recursiveGetChangedFields(itemA, itemB).length > 0;
    } else if (Array.isArray(itemA) && Array.isArray(itemB)) {
      return (
        itemA.length !== itemB.length ||
        itemA.some(
          (item: unknown, i: number) =>
            recursiveGetChangedFields(item, itemB[i]).length > 0
        )
      );
    }

    return itemA !== itemB;
  });
  return changedKeys;
};

const selectInitialSnapshot =
  <T extends keyof Form["initialSnapshot"]>(key: T) =>
  (state: RootState): Form["initialSnapshot"][T] =>
    state.vendors.form.initialSnapshot[key];

const selectUnsavedInfoChanges = createSelector(
  [vendorSelectors.info, selectInitialSnapshot("info")],
  (current, initial) => {
    const changedFields = recursiveGetChangedFields(current, initial);
    return changedFields.length ? changedFields.map(camelToSpaceCase) : null;
  }
);

const selectUnsavedBankingChanges = createSelector(
  [vendorSelectors.banking, selectInitialSnapshot("banking")],
  (current, initial) => {
    const changedFields = recursiveGetChangedFields(current, initial);
    return changedFields.length ? changedFields.map(camelToSpaceCase) : null;
  }
);

export const selectUnsavedDocumentsChanges = createSelector(
  [vendorSelectors.documents, selectInitialSnapshot("documents")],
  (current, initial) => {
    const changeSet: ChangeSet<VirtualDocument, SavedDocument, EditDocument> =
      {};

    // if any of the current documents are virtual, we know there are unsaved changes
    const virtualDocs = current.filter((doc) => !(doc as SavedDocument).url);
    if (virtualDocs.length) {
      changeSet.added = virtualDocs as VirtualDocument[];
    }

    if (current.length - virtualDocs.length < initial.length) {
      changeSet.removed = (initial as SavedDocument[]).filter(
        (initialDoc) =>
          !(current as SavedDocument[]).find(
            (currentDoc) => currentDoc.id === initialDoc.id
          )
      );
    }

    return Object.keys(changeSet).length ? changeSet : null;
  }
);

export const selectVendorDocumentItems = createSelector(
  [vendorSelectors.documents],
  (documents) =>
    documents.map((doc) => ({
      ...doc,
      isVirtual: doc.document instanceof File,
    }))
);

export const selectUnsavedVendorInfoOrBankingChanges = createSelector(
  [selectUnsavedInfoChanges, selectUnsavedBankingChanges],
  (info, banking) => (info || banking ? { info, banking } : null)
);

export const selectHasChangedEmailWithPendingRequest = createSelector(
  [vendorSelectors.info],
  (info) => {
    const { pendingRequestsEmails, emails } = info;
    return (pendingRequestsEmails || []).some(
      (email) => !(emails || []).includes(email)
    );
  }
);

export const selectIsSubmitting = createSelector(
  [vendorSelectors.isSubmitting],
  (isSubmitting) => isSubmitting
);

export const selectVendorNeverSaved = createSelector(
  [vendorSelectors.info],
  (info) => !info.url
);

const formatSimplifiedErrors = <
  T extends Omit<Form["errors"], "nonFieldErrors">,
>(
  error: T
) => {
  const format = (str: string) =>
    [
      camelToSpaceCase,
      (s: string) =>
        s
          .split(" ")
          .map((s) => s[0].toUpperCase() + s.slice(1))
          .join(" "),
    ].reduce((s, fn) => fn(s), str);

  return Object.entries(error).map(([key, value]) => ({
    id: key,
    message: `${format(key)}:\t${value}`,
    details: value,
  }));
};

export const selectErrors = (state: RootState) => state.vendors.form.errors;

export const selectSubmissionErrors = createSelector(selectErrors, (errors) => {
  if (!errors || !errors?.nonFieldErrors?.length) {
    return [];
  }

  const { nonFieldErrors } = errors;

  return nonFieldErrors.map((message, id) => ({
    id: `${id}`,
    message: getUserFriendlyError(message as UserFriendlyErrorKey),
    detail: null,
  }));
});

export const selectErrorsGroups = createSelector(
  [selectErrors, selectSubmissionErrors],
  (errors, submissionErrors) => {
    if (!errors) {
      return errors;
    }

    // these are "form"/"submission" level errors
    const duplicateNameError = submissionErrors.find(
      ({ message }) => message === "A vendor with this name already exists"
    );

    const { info, banking, documents } = errors;

    //  taxId is visible on the accounts tab,
    //  but the field is stored on the info object
    //  because it's part of the '/vendors' payload
    //  so, we'll break it out here
    const { taxId, ...infoErrors } = info || {};
    if (duplicateNameError) {
      infoErrors.displayName = duplicateNameError.message;
    }

    const vendorInfo = Object.keys(infoErrors).length > 0 && infoErrors;
    const accounts =
      (banking || taxId) && Object.assign({}, banking, taxId && { taxId });

    // TODO type/derive these keys from the registered tabs
    return {
      ...(vendorInfo ? { [ROUTE_LABEL.INFO]: vendorInfo } : {}),
      ...(accounts ? { [ROUTE_LABEL.ACCOUNTING]: accounts } : {}),
      ...(documents ? { [ROUTE_LABEL.DOCUMENTS]: documents } : {}),
    };
  }
);

export const makeErrorSelectorForTab = (...tabs: string[]) =>
  createSelector([selectErrorsGroups], (errors) => {
    if (!errors) {
      return {};
    }

    return tabs.reduce((acc, tab) => {
      const errKey = tab as unknown as keyof typeof errors;
      const formatted = formatSimplifiedErrors(errors[errKey] || {});
      return Object.assign(acc, formatted?.length && { [errKey]: formatted });
    }, {});
  });
