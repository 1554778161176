import React, {
  type ComponentProps,
  type ComponentRef,
  forwardRef,
  memo,
  type PropsWithChildren,
} from "react";
import cn from "clsx";

import { suffixify } from "../../utils/suffixify";

import { useCarousel } from "./carousel-context";
import styles from "./carousel.module.css";

type DefaultComponent = "li";

type CarouselItemRef = ComponentRef<DefaultComponent>;

export type CarouselItemProps = PropsWithChildren<
  ComponentProps<DefaultComponent> & {
    value: string;
    label: string;
    errorMessage?: string;
    "data-testid"?: string;
  }
>;

const CarouselItem = forwardRef<CarouselItemRef, CarouselItemProps>(
  ({ value, children, className, "data-testid": testId, ...props }, ref) => {
    const carousel = useCarousel();

    const enhancedTestId = testId
      ? testId
      : carousel.testId
        ? suffixify(carousel.testId, "item")
        : undefined;

    const isActive = carousel.value === value;

    return (
      <li
        ref={ref}
        className={cn(styles["item"], className)}
        aria-hidden={!isActive}
        data-testid={enhancedTestId}
        {...props}
      >
        {children}
      </li>
    );
  }
);

CarouselItem.displayName = "CarouselItem";

const MemoizedCarouselItem = memo(CarouselItem);

export { MemoizedCarouselItem as CarouselItem };
