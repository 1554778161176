import { toast } from "@adaptive/design-system";
import { omit, suffixify } from "@adaptive/design-system/utils";
import type {
  Expense,
  ExpenseMutateResponse,
  ExpensesResponse,
} from "@api/expenses";
import { api as expenseApi, type ExpenseFilters } from "@api/expenses";
import { handleErrors } from "@api/handle-errors";
import { SUPPORTED_UPLOAD_FORMATS as GENERIC_SUPPORTED_UPLOAD_FORMATS } from "@components/draggable";
import { createAsyncThunk } from "@reduxjs/toolkit";
import type { Identifiable } from "@shared/types";
import { isPending } from "@src/expenses/table/columns";
import { REVIEW_STATUS } from "@src/expenses/utils/constants";
import { api } from "@store/api-simplified";
import {
  selectExpenseQueryController,
  selectExpenseQueryFilters,
} from "@store/expenses/selectors-memoized";
import { poll, PollError } from "@utils/poll";
import {
  UNLINK_INVOICE_LINES_OPTION,
  type UnlinkInvoiceLinesOption,
} from "@utils/transaction-confirm-messages";
import type { AxiosResponse } from "axios";
import axios from "axios";

import { putComments } from "../../api/comments";
import type { RootState } from "../types";
import {
  selectClientSettings,
  selectRealmId,
  selectRealmUrl,
} from "../user/selectors-raw";

import { getLineItem } from "./line";
import { expenseSelector, staticEntrySelector } from "./selectors";
import {
  addExpenseInList,
  createNewExpense,
  removeExpenseFromList,
  setController,
  setErrorLength,
  setFilterByStatus,
  updateExpenseInList,
} from "./slice";
import type { CollectionQueries } from "./types";
import { getInitialExpense } from "./utils";

type ActionPayload = {
  setStatus?: Expense["reviewStatus"];
  isArchived?: boolean;
  syncInvoiceLines?: boolean;
  onComplete?: (resp: AxiosResponse) => void;
  unlinkInvoiceLinesOption?: UnlinkInvoiceLinesOption;
};

const SUPPORTED_UPLOAD_FORMATS = [
  ...GENERIC_SUPPORTED_UPLOAD_FORMATS,
  "image/heic",
  "image/heif",
  "image/png",
  "image/jpg",
  "image/jpeg",
] as const;

const SubmitExpenseErrorCodes = {
  NONE: 0,
  MISSING_REALM: 1,
  INVALID_PAYLOAD: 2,
  UNKNOWN: 3,
} as const;

type ThunkState = {
  state: RootState;
};

type AttachableThunkConfig = ThunkState & {
  rejectValue: {
    status: (typeof SubmitExpenseErrorCodes)[keyof typeof SubmitExpenseErrorCodes];
  };
};

export const syncExpense = createAsyncThunk<void, string | number, ThunkState>(
  "expense/sync",
  async (id, { dispatch }) => {
    const { run } = poll({
      fn: () => expenseApi.get({ id }),
      validate: (data) =>
        ["done", "timeout", "failed", "discarded"].includes(
          data.fileSyncStatus ?? ""
        ),
    });

    try {
      const expense = await run();
      dispatch(updateExpenseInList({ data: expense, status: "ALL" }));
      dispatch(updateExpenseInList({ data: expense, status: "DRAFT" }));
    } catch (e) {
      if (axios.isCancel(e)) return;

      if (e instanceof PollError) {
        toast.error("Failed to sync due timeout");
        return;
      }

      handleErrors(e);
    }
  }
);

export const uploadAttachable = createAsyncThunk<void, File, ThunkState>(
  "expense/uploadAttachable",
  async (file, { dispatch, getState }) => {
    if (
      !SUPPORTED_UPLOAD_FORMATS.some((format) => file.type.includes(format))
    ) {
      const error = new Error(
        "Only images, pdfs, spreadsheets and word documents are supported"
      );
      handleErrors(error);
      return Promise.reject(error);
    }

    const realm = selectRealmUrl(getState());

    const formData = new FormData();

    const processingId = suffixify(
      "processing",
      file.name,
      Date.now(),
      Math.random() * 100
    )!;

    const expense = getInitialExpense(processingId);
    expense.fileSyncStatus = "requested";

    dispatch(addExpenseInList({ data: expense, status: "ALL" }));
    dispatch(addExpenseInList({ data: expense, status: "DRAFT" }));

    formData.append("document", file);

    if (realm) formData.append("realm", realm);

    try {
      const { expense } = await expenseApi.uploadAttachable(formData);

      toast.success(`${file.name} uploaded`);
      dispatch(removeExpenseFromList({ id: processingId, status: "ALL" }));
      dispatch(removeExpenseFromList({ id: processingId, status: "DRAFT" }));
      dispatch(addExpenseInList({ data: expense, status: "ALL" }));
      dispatch(addExpenseInList({ data: expense, status: "DRAFT" }));
      dispatch(syncExpense(expense.id));
    } catch (e) {
      handleErrors(e);
      throw e;
    }
  }
);

export const commitExpense = createAsyncThunk<
  ExpenseMutateResponse,
  ActionPayload,
  AttachableThunkConfig
>(
  "expense/persistToDB",
  async (
    {
      setStatus,
      isArchived = false,
      syncInvoiceLines = false,
      unlinkInvoiceLinesOption = UNLINK_INVOICE_LINES_OPTION.SKIP,
    },
    { getState, dispatch }
  ) => {
    const state = getState();

    const settings = selectClientSettings(state);

    const realm = selectRealmUrl(getState());
    if (!realm) {
      throw { status: SubmitExpenseErrorCodes.MISSING_REALM };
    }

    const expense = expenseSelector(state);

    const persistToDatabase = !expense.id ? expenseApi.create : expenseApi.put;

    try {
      const response = await persistToDatabase({
        ...expense,
        ...(setStatus ? { reviewStatus: setStatus } : {}),
        isTransactionGeneratedDraft: settings.card_feed_enabled
          ? false
          : expense.isTransactionGeneratedDraft,
        syncInvoiceLines,
        unlinkInvoiceLinesOption,
        isArchived,
        realm,
        lines: expense.lines
          ? Object.values(expense.lines).reduce((acc, line) => {
              return { ...acc, [line.id]: { ...line, vendor: expense.vendor } };
            }, {})
          : undefined,
      });

      if (!expense.id && response.url && expense.comments?.length) {
        await putComments({
          // TODO - due to "generated" comments, author can be nullish now
          // so we need to update the type in comments api
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          comments: expense.comments,
          url: response.url,
        });
      }

      if (response.reviewStatus === REVIEW_STATUS.REVIEWED) {
        dispatch(api.util.invalidateTags(["CostCodesAccountsSimplified"]));
      }

      return response;
    } catch (e: any) {
      handleErrors(e);

      if (e?.name === "ZodError") {
        throw { status: SubmitExpenseErrorCodes.INVALID_PAYLOAD };
      } else {
        throw { status: SubmitExpenseErrorCodes.UNKNOWN };
      }
    }
  }
);

const getExpenseById = createAsyncThunk<
  ReturnType<typeof expenseApi.get>,
  string | number,
  ThunkState
>(
  "expenses/getExpenseById",
  async (expenseId: string | number, { getState }) => {
    const { is_billable_default_expense } = selectClientSettings(getState());
    const data = await expenseApi.get({ id: expenseId });

    if (Object.keys(data.lines).length === 0) {
      const line = {
        ...getLineItem(),
        billableStatus: is_billable_default_expense
          ? "Billable"
          : "NotBillable",
      };
      data.lines[line.id] = line;
    }

    return data;
  }
);

export const fetchExpenseById = createAsyncThunk<
  ReturnType<typeof expenseApi.get>,
  string | number,
  ThunkState
>("expenses/fetchById", async (expenseId: string | number, { dispatch }) => {
  return dispatch(getExpenseById(expenseId)).unwrap();
});

export const refetchCurrentExpense = createAsyncThunk<
  ReturnType<typeof expenseApi.get>,
  string[] | ((entry: Expense) => Partial<Expense>),
  ThunkState
>("expenses/refetchCurrentExpense", async (_, { dispatch, getState }) => {
  const entry = expenseSelector(getState());

  if (entry.id) return dispatch(getExpenseById(String(entry.id))).unwrap();
});

export const syncInvoiceChanges = createAsyncThunk<
  {
    entry: Expense;
    staticEntry: Expense;
    data: ReturnType<typeof expenseApi.get>;
  },
  string,
  ThunkState
>(
  "expenses/syncInvoiceChanges",
  async (expenseId: string, { dispatch, getState }) => {
    const entry = expenseSelector(getState());
    const staticEntry = staticEntrySelector(getState());
    const data = await dispatch(getExpenseById(expenseId)).unwrap();
    return { entry, staticEntry, data };
  }
);

export const convertToBill = createAsyncThunk<
  Identifiable,
  Identifiable,
  ThunkState
>(
  "expenses/convertToBill",
  async ({ id }: Identifiable, { dispatch, getState }) => {
    const { reviewStatus } = expenseSelector(getState());

    if (id) {
      const bill = await expenseApi.convert({ id });
      dispatch(
        queryExpenses(reviewStatus !== "REVIEWED" ? reviewStatus : "ALL")
      );
      return bill;
    } else {
      throw new Error("Receipt not found");
    }
  }
);

export const deleteExpense = createAsyncThunk<void, void, ThunkState>(
  "expenses/deleteExpense",
  async (_, { dispatch, getState }) => {
    const { id, reviewStatus } = expenseSelector(getState());

    if (id) {
      await expenseApi.delete({ id });
      dispatch(createNewExpense());
      dispatch(
        queryExpenses(reviewStatus !== "REVIEWED" ? reviewStatus : "ALL")
      );
    } else {
      throw new Error("Receipt not found");
    }
  }
);

export const queryExpenses = createAsyncThunk<
  { result: ExpensesResponse; queryStatus: CollectionQueries },
  CollectionQueries,
  ThunkState
>(
  "expenses/query",
  async (
    status: CollectionQueries,
    { getState, dispatch, rejectWithValue }
  ) => {
    const state = getState();
    const realm = selectRealmId(state);
    const clientSettings = selectClientSettings(state);

    if (!realm) return rejectWithValue(SubmitExpenseErrorCodes.MISSING_REALM);

    const managedFilters = selectExpenseQueryFilters(state, status);
    const enhancedFilters = {
      ...managedFilters,
      include_transaction_generated_drafts: !clientSettings.card_feed_enabled,
      realm: realm.toString(),
    } as ExpenseFilters;
    const previousController = selectExpenseQueryController(state, status);
    previousController?.abort();

    const controller = new AbortController();
    dispatch(setController({ status, controller }));

    try {
      let result = await expenseApi.query({
        filters: enhancedFilters,
        signal: controller?.signal,
      });

      const limit = Number(enhancedFilters?.limit ?? 10);
      const offset = Number(enhancedFilters?.offset ?? 0);

      /**
       * Sometimes after doing some batch actions, the results is empty because we
       * moved all the receipts to another status, so we need to adjust the offset
       * to fetch the previous page.
       */
      if (result.results.length === 0 && offset > 0) {
        dispatch(
          setFilterByStatus({
            status,
            filters: {
              ...omit(enhancedFilters, ["review_status", "offset"]),
              offset: offset - limit,
            },
          })
        );

        const latestResult = await dispatch(queryExpenses(status)).unwrap();
        result = latestResult.result;
      }

      result.results
        .filter(isPending)
        .forEach((item: Expense) => dispatch(syncExpense(item.id)));

      return {
        result,
        queryStatus: status,
      };
    } catch (e: any) {
      return rejectWithValue(e.toString());
    }
  }
);

export const queryExpensesWithErrors = createAsyncThunk<void, void, ThunkState>(
  "expenses/queryWithErrors",
  async (_, { getState, dispatch }) => {
    const state = getState();
    const realm = selectRealmId(state);
    const clientSettings = selectClientSettings(state);

    try {
      const result = await expenseApi.query({
        filters: {
          realm: String(realm),
          review_status: "errors",
          include_transaction_generated_drafts:
            !clientSettings.card_feed_enabled,
        },
      });
      dispatch(setErrorLength(result.count));
    } catch {
      throw new Error("Receipt with errors not found");
    }
  }
);

export interface Vendor {
  id: number;
  url: string;
  display_name: string;
}
