import { api } from "@store/api-simplified";
import { handleResponse } from "@utils/api";
import { transformKeysToCamelCase } from "@utils/schema/converters";

import { vendorPartialSchema, vendorsGetResponseSchema } from "./schemas";
import type {
  VendorGetPayload,
  VendorGetResponse,
  VendorsGetPayload,
  VendorsGetResponse,
} from "./types";

const enhancedApi = api.enhanceEndpoints({
  addTagTypes: ["Vendors", "Vendor"],
});

/*
These endpoints correspond to the vendors resource.
*/
export const vendorsApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getVendors: builder.query<VendorsGetResponse, VendorsGetPayload>({
      query: (queryString: string) => {
        return {
          url: `vendors/?${queryString}`,
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              vendorsGetResponseSchema
            );
          },
        };
      },
      providesTags: () => ["Vendors"],
    }),
    getVendor: builder.query<VendorGetResponse, VendorGetPayload>({
      query: (id: string) => {
        return {
          url: `vendors/${id}`,
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              vendorPartialSchema
            );
          },
        };
      },
      providesTags: () => ["Vendor"],
    }),
    exportVendorDocuments: builder.query<string, URLSearchParams>({
      query: (params) => ({
        url: `vendors/export/`,
        params,
        responseHandler: async (response) => {
          const data = await response.json();
          return data?.id ? (data.id as string) : data;
        },
      }),
    }),
  }),
});

export const {
  useGetVendorsQuery,
  useGetVendorQuery,
  useLazyExportVendorDocumentsQuery,
} = vendorsApi;
