import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  isValidElement,
  memo,
  type PropsWithChildren,
  type ReactNode,
} from "react";

import { useDeepMemo } from "../../hooks/use-deep-memo";
import { is } from "../../utils/is";
import { suffixify } from "../../utils/suffixify";
import { Flex } from "../flex";
import { Icon } from "../icon";
import { Text, type TextProps } from "../text";
import { Tooltip } from "../tooltip";

import styles from "./label-value.module.css";

type LabelProps = {
  size?: TextProps["size"];
  color?: TextProps["color"];
  weight?: TextProps["weight"];
  children: ReactNode;
};

type HintMessageProps = {
  label?: ReactNode;
  value?: ReactNode;
};

type ValueProps = PropsWithChildren<{
  size?: TextProps["size"];
  color?: TextProps["color"];
  weight?: TextProps["weight"];
}>;

type Props = Omit<ComponentPropsWithoutRef<"p">, "color"> & {
  label: ReactNode | LabelProps;
  value?: ReactNode | ValueProps;
  compact?: boolean;
  hintMessage?: ReactNode | HintMessageProps;
  "data-testid"?: string;
};

const LabelValue = forwardRef<ComponentRef<typeof Flex>, Props>(
  (
    { label, value, compact, hintMessage, "data-testid": testId, ...props },
    ref
  ) => {
    const labelProps = useDeepMemo(() => {
      let props: LabelProps = {
        size: "sm",
        color: "neutral-800",
        weight: "regular",
        children: null,
      };

      if (label) {
        props = isValidElement(label)
          ? { ...props, children: label }
          : is.object(label)
            ? { ...props, ...label }
            : { ...props, children: label };
      }

      return props;
    }, [label]);

    const valueProps = useDeepMemo(() => {
      let props: ValueProps = {
        size: compact ? "sm" : "md",
        color: "neutral-800",
        weight: compact ? "bold" : "bolder",
        children: "-",
      };

      if (value) {
        props = isValidElement(value)
          ? { ...props, children: value }
          : is.object(value)
            ? { ...props, ...value }
            : { ...props, children: value };
      }

      return props;
    }, [compact, value]);

    const labelHintMessage = useDeepMemo(
      () => (is.object(hintMessage) ? hintMessage.label : hintMessage),
      [hintMessage]
    );

    const valueHintMessage = useDeepMemo(
      () => (is.object(hintMessage) ? hintMessage.value : null),
      [hintMessage]
    );

    return (
      <Flex
        ref={ref}
        gap={compact ? "sm" : "none"}
        direction={compact ? "row" : "column"}
        data-testid={testId}
        {...props}
      >
        <Flex as="span" role="term" data-testid={suffixify(testId, "label")}>
          <Text as="span" truncate {...labelProps} />
          {labelHintMessage && (
            <Tooltip
              as={Icon}
              size="sm"
              name="info-circle"
              message={labelHintMessage}
              className={styles["hint-message"]}
              data-testid={suffixify(testId, "label-hint")}
            />
          )}
        </Flex>
        <Flex
          as="span"
          role="definition"
          data-testid={suffixify(testId, "value")}
        >
          <Text as="span" truncate {...valueProps} />
          {valueHintMessage && (
            <Tooltip
              as={Icon}
              size="sm"
              name="info-circle"
              message={valueHintMessage}
              className={styles["hint-message"]}
              data-testid={suffixify(testId, "value-hint")}
            />
          )}
        </Flex>
      </Flex>
    );
  }
);

LabelValue.displayName = "LabelValue";

const MemoizedLabelValue = memo(LabelValue);

export { MemoizedLabelValue as LabelValue };
