import React, {
  type ComponentRef,
  type ElementType,
  type ForwardedRef,
  forwardRef,
  useCallback,
  useMemo,
} from "react";
import {
  Checkbox,
  ComboBox,
  type ComboBoxActionAddon,
  type ComboBoxProps,
  type ComboBoxRenderOptionComponent,
  type ComboBoxRenderOptionHandler,
  Flex,
  Icon,
  Tooltip,
} from "@adaptive/design-system";
import { useDeepMemo } from "@adaptive/design-system/hooks";
import { isValidEmail } from "@shared/utils/is-valid-email";

import { useGetVendorQuery } from "../api/api";
import { useVendorAction } from "../hooks";

type ComboBoxRef = ComponentRef<typeof ComboBox>;

export type VendorEmailsProps<IsMultiple extends boolean> =
  ComboBoxProps<IsMultiple> & {
    as?: ElementType;
    vendorId?: string | number;
  };

type EnhancedOption = {
  value: string;
  label: string;
  disabled?: string;
  isDefaultEmail: boolean;
};
type ExtraData = { isDefaultEmail: boolean };

const CustomOption: ComboBoxRenderOptionComponent<
  ExtraData
  // eslint-disable-next-line react/prop-types
> = ({ multiple, disabled, selected, isDefaultEmail, highlightedLabel }) => (
  <Flex align="center" width="full" gap="lg">
    {multiple && (
      <Checkbox aria-hidden="true" checked={selected} disabled={!!disabled} />
    )}
    <Flex justify="space-between" width="full" gap="xl" align="center">
      <Flex gap="sm" align="center">
        {highlightedLabel}
        {typeof disabled === "string" && (
          <Tooltip
            as={Icon}
            size="sm"
            name="exclamation-circle"
            message={disabled}
          />
        )}
      </Flex>
      {isDefaultEmail && (
        <Tooltip
          as={Icon}
          size="sm"
          color="brand-2"
          variant="solid"
          name="star"
          message="Default email"
        />
      )}
    </Flex>
  </Flex>
);

const VendorEmails = <IsMultiple extends boolean = false>(
  {
    as: Component = ComboBox,
    vendorId,
    ...props
  }: VendorEmailsProps<IsMultiple>,
  ref: ForwardedRef<ComboBoxRef>
) => {
  const { showVendorById } = useVendorAction();

  const { data, isLoading } = useGetVendorQuery(String(vendorId), {
    skip: !vendorId,
  });

  const enhancedData = useDeepMemo(() => {
    return (data?.emails || []).map((email) => {
      const emailIsValid = isValidEmail(email);
      return {
        value: email,
        label: email,
        disabled: emailIsValid
          ? undefined
          : "Email is invalid. Update it in vendor profile to make it usable",
        isDefaultEmail: false,
      };
    });
  }, [data?.emails]);

  const action = useMemo<ComboBoxActionAddon>(
    () => ({
      onClick: () => showVendorById(String(vendorId), "info"),
      children: "Manage emails in vendor profile",
      icon: "arrow-up-right-from-square",
      mode: "always",
      hideOnClick: false,
    }),
    [showVendorById, vendorId]
  );

  const renderComboboxOption = useCallback<
    ComboBoxRenderOptionHandler<EnhancedOption>
  >((option, extra) => <CustomOption {...option} {...extra} />, []);

  return (
    <Component
      ref={ref}
      data={enhancedData}
      action={action}
      loading={isLoading}
      placeholder="Select an email"
      emptyMessage="No matching emails found for the selected vendor."
      renderOption={renderComboboxOption}
      {...props}
    />
  );
};

const ForwardedVendorEmails = forwardRef(VendorEmails) as <
  IsMultiple extends boolean = false,
>(
  props: VendorEmailsProps<IsMultiple> & { ref?: ForwardedRef<ComboBoxRef> }
) => ReturnType<typeof VendorEmails>;

export { ForwardedVendorEmails as VendorEmailComboBox };
