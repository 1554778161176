import React from "react";
import { Tag } from "@adaptive/design-system";
import {
  dotObject,
  formatCurrency,
  formatDate,
  isEqual,
  parseCurrency,
} from "@adaptive/design-system/utils";
import { billListSchema } from "@api/bills/response";
import type { Workflow, WorkflowApprovals } from "@api/workflows/types";
import { billPaymentListingV2Schema } from "@bill-payment/api";
import { type BillPaymentListingV2 } from "@bill-payment/types";
import {
  LIEN_WAIVER_LINKED_STATUS,
  LIEN_WAIVER_REQUESTED_STATUS,
} from "@lien-waiver/constants";
import type { LienWaiver, LienWaiverCustomer } from "@lien-waiver/types";
import { createSelector } from "@reduxjs/toolkit";
import { PURCHASE_ORDER_TYPE } from "@src/purchase-orders/constants";
import { type BillListItemResponse } from "@src/shared/api/bills";
import type { RootState } from "@store/types";
import { selectRealmId } from "@store/user/selectors-memoized";
import type { SaveWithPropagationParams } from "@transaction-propagation/types";
import { getLinesInfos } from "@transaction-propagation/utils";
import { handleResponse } from "@utils/api";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import {
  type KeysToCamelCase,
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";
import { stringCompare } from "@utils/string-compare";
import { sum } from "@utils/sum";
import {
  humanReadableBillReviewStatus,
  isObjectEmpty,
  reviewStatusColor,
} from "@utils/usefulFunctions";
import { type DocumentType } from "@vendors/types";

import {
  BILL_STATUS,
  LIEN_WAIVER_SIGNED_STATUS,
  TAB_STATUS,
  TAB_STATUS_TO_BILLS_CONST,
} from "./constants";
import type {
  Bill,
  BillListItems,
  BillListItemsV2,
  QueryFilters,
  Status,
  StatusKey,
  TransactionDuplicate,
} from "./types";

const enterBillManuallySelector = (state: RootState) =>
  state.bill.enterBillManually;

const rawBillSelector = (state: RootState) =>
  state.bill.bill as Omit<
    RootState["bill"]["bill"],
    "review_status" | "initial_review_status"
  > & {
    id?: string;
    review_status: (typeof BILL_STATUS)[keyof typeof BILL_STATUS];
    initial_review_status: (typeof BILL_STATUS)[keyof typeof BILL_STATUS];
  };

export const billListSelector = (state: RootState) => state.billList;

export const billListFilterSelector = createSelector(
  billListSelector,
  selectRealmId,
  ({ filter: { query, values }, ...billListItems }, realmId) => {
    const enhancedFilter = { query, values };

    if (realmId) {
      enhancedFilter.query = [
        { dataIndex: "realm", value: realmId },
        ...enhancedFilter.query.filter((item) => item.dataIndex !== "realm"),
      ];
    }

    return {
      filter: enhancedFilter,
      billList: billListItems as BillListItems,
    };
  }
);

const parseBillListToV2Schema = (bills: Bill[]) =>
  handleResponse(bills.map(transformKeysToCamelCase), billListSchema);

export const billListFilterV2Selector = createSelector(
  billListSelector,
  selectRealmId,
  ({ filter: { query, values }, ...billListItems }, realmId) => {
    const enhancedFilter = { query, values };

    if (realmId) {
      enhancedFilter.query = [
        { dataIndex: "realm", value: realmId },
        ...enhancedFilter.query.filter((item) => item.dataIndex !== "realm"),
      ];
    }

    return {
      filter: enhancedFilter,
      billList: {
        ...billListItems,
        allBills: {
          ...billListItems.allBills,
          bills: parseBillListToV2Schema(billListItems.allBills.bills || []),
        },
        draftBills: {
          ...billListItems.draftBills,
          bills: parseBillListToV2Schema(billListItems.draftBills.bills || []),
        },
        approvalBills: {
          ...billListItems.approvalBills,
          bills: parseBillListToV2Schema(
            billListItems.approvalBills.bills || []
          ),
        },
        forPaymentBills: {
          ...billListItems.forPaymentBills,
          bills: parseBillListToV2Schema(
            billListItems.forPaymentBills.bills || []
          ),
        },
      } as BillListItemsV2,
    };
  }
);

export const billSelector = createSelector(
  rawBillSelector,
  enterBillManuallySelector,
  (bill, enterBillManually) => {
    /**
     * @todo for some reason realm data on bill creation is an object
     * but on edit is a string of url. we didn't changed it now since it
     * could break some places, but we need to check it soon to make it
     * less fragile.
     */
    const realm = !bill.id
      ? null
      : dotObject.get(bill, "realm.url")
        ? bill.realm
        : bill.realm
          ? { url: bill.realm }
          : null;

    return { ...bill, realm, enterBillManually };
  }
);

const rootBillSelector = (state: RootState) => state.bill;

export const statusBillSelector = createSelector(
  rootBillSelector,
  (bill) => bill.billStatus
);

export const formEditedBillSelector = createSelector(
  rootBillSelector,
  (bill) => bill.billFormEdited
);

export const showCreateNewDialogBillSelector = createSelector(
  rootBillSelector,
  (bill) => bill.showCreateNewDialog
);

export const predictionBillSelector = createSelector(
  rootBillSelector,
  (bill) => bill.prediction
);

export const duplicatesBillSelector = createSelector(
  billSelector,
  (bill) =>
    (bill.duplicate || []).reduce(
      (acc, item: Record<string, unknown>) =>
        item.id == bill.id
          ? acc
          : [...acc, transformKeysToCamelCase(transformKeysToSnakeCase(item))],
      [] as Record<string, unknown>[]
    ) as TransactionDuplicate[]
);

export const isInPaymentStatusBill = (reviewStatus: string) =>
  ["PAID", "ACH_PROCESSING", "PARTIALLY_PAID"].includes(reviewStatus);

export const hasAnyLienWaiverRequired = (lienWaivers: LienWaiver[]) =>
  lienWaivers.some((lienWaiver) =>
    LIEN_WAIVER_LINKED_STATUS.some((status) => status === lienWaiver.status)
  );

/**
 * This logic should follows the same logic as the one in the backend, you can check the backend
 * logic on `get_automatically_archived` method in @see /backend/adaptive_quickbooks/enums.py
 */
type IsArchivedProps =
  | { archived?: boolean | null; review_status: string }
  | { archived?: boolean | null; reviewStatus: string };

/**
 * This function returns `true` when bill is "real archived"
 */
export const isArchivedByUserBill = (bill: IsArchivedProps) => {
  const reviewStatus =
    "review_status" in bill ? bill.review_status : bill.reviewStatus;

  return (
    bill.archived &&
    !["PAID", "ACH_PROCESSING", "VOID", "PAYMENT_FAILED"].includes(reviewStatus)
  );
};

export const minimalBillAndLinesSelector = createSelector(
  billSelector,
  (bill) => ({
    id: bill.id,
    url: bill.url,
    lines: bill.lines
      .filter((line: Line) => ("deleted" in line ? !line.deleted : true))
      .map((line: Line) =>
        transformKeysToCamelCase({
          id: line.id!,
          item: line.item,
          amount: parseCurrency(String(line.amount) || "0") || 0,
          account: line.account,
          is_spent: line.is_spent,
          customer: line.customer,
          description: line.description,
          linked_transaction: line.linked_transaction?.id,
        })
      ),
    docNumber: bill.doc_number,
    reviewStatus: bill.review_status,
  })
);

export const camelCaseBillSelector = createSelector(billSelector, (bill) => {
  const isArchivedByUser = isArchivedByUserBill(bill);

  const billLienWaiverTemplate =
    bill.default_lien_waiver_template ??
    ((bill.default_lien_waiver_status === "not_required"
      ? bill.default_lien_waiver_status
      : undefined) as string | undefined);

  return {
    id: bill.id,
    url: bill.url,
    realm: bill.realm,
    balance: parseCurrency(bill.balance || "0") ?? 0,
    docNumber: bill.doc_number as string | null,
    realmUrl: bill.realm?.url,
    linesCount: bill.lines_count,
    isArchived: bill.archived,
    isArchivedByUser,
    amountToPay: bill.amount_to_pay ? Number(bill.amount_to_pay) : undefined,
    tooManyLines: bill.lines_count > window.MAX_LINES_PAGE_SIZE,
    reviewStatus: bill.review_status,
    isVendorCredit: bill.is_vendor_credit,
    taxValue: bill.tax?.value ?? 0,
    taxIsSet: bill.tax?.isSet ?? false,
    initialReviewStatus: bill.initial_review_status,
    shouldShowPurchaseOrders:
      bill.vendor?.url && !bill.is_vendor_credit && !isArchivedByUser,
    vendorId: parseRefinementIdFromUrl(bill.vendor?.url ?? ""),
    isCreator: bill.is_creator,
    canApprove: bill.can_approve,
    canBeEdited: bill.can_be_edited,
    hasAttachables: bill.attachables?.length > 0,
    alreadyEvaluated: bill.is_approval_evaluated,
    isApproved:
      bill.is_approved && bill.review_status === BILL_STATUS.FOR_PAYMENT,
    date: bill.date as string | null,
    dueDate: bill.due_date as string | null,
    createdAt: bill.created_at as string | null,
    totalAmount: parseCurrency(bill.total_amount || "0") ?? 0,
    fileSyncStatus: bill.file_sync_status,
    enterBillManually: bill.enterBillManually,
    publishedToQuickbooks: bill.published_to_quickbooks,
    linkedInvoices: bill.linked_invoices.map(transformKeysToCamelCase),
    billLienWaiverTemplate,
    lienWaivers: bill.lien_waivers.map(
      transformKeysToCamelCase
    ) as LienWaiver[],
    paymentDetails: {
      ...transformKeysToCamelCase(bill.payment_details),
      currentDebitDate: bill.payment_details?.current_debit_date
        ? new Date(bill.payment_details.current_debit_date)
        : new Date(),
      customerLienWaivers: (
        bill.payment_details?.customer_lien_waivers || []
      ).map(transformKeysToCamelCase) as LienWaiverCustomer[],
    },
    debitDate: bill.debitDate || new Date(),
  };
});

export const billReviewStatusSelector = createSelector(
  camelCaseBillSelector,
  (bill) => bill.reviewStatus
);

export const billLinesCountSelector = createSelector(
  camelCaseBillSelector,
  (bill) => bill.linesCount
);

export const billIsVendorCreditSelector = createSelector(
  camelCaseBillSelector,
  (bill) => bill.isVendorCredit
);

export const isArchivedByUserBillSelector = createSelector(
  camelCaseBillSelector,
  (bill) => bill.isArchivedByUser
);

export const billParsedAmountToPay = createSelector(
  camelCaseBillSelector,
  (bill) => parseCurrency(String(bill.amountToPay || 0)) ?? 0
);

export const billTaxValueSelector = createSelector(
  camelCaseBillSelector,
  (bill) => (bill.taxIsSet ? bill.taxValue : 0)
);

export const billFileSyncStatusSelector = createSelector(
  camelCaseBillSelector,
  (bill) => bill.fileSyncStatus
);

export const billAmountToPaySelector = createSelector(
  camelCaseBillSelector,
  (bill) => bill.amountToPay || 0
);

export const billSubTotalSelector = createSelector(
  camelCaseBillSelector,
  (bill) => sum(bill.totalAmount, -(bill.taxIsSet ? bill.taxValue : 0))
);

export const billPublishedToQuickbooksSelector = createSelector(
  camelCaseBillSelector,
  (bill) => bill.publishedToQuickbooks
);

export const errorsBillSelector = createSelector(
  billSelector,
  (bill) => bill.errors
);

export const relatedErrorsBillSelector = createSelector(
  billSelector,
  (bill) => bill.related_errors
);

export const paymentsBillSelectorSnakeCase = createSelector(
  billSelector,
  (bill) => {
    return bill?.bill_payments as Bill["bill_payments"];
  }
);

export const paymentsBillSelector = createSelector(billSelector, (bill) => {
  return (bill?.bill_payments as Bill["bill_payments"]).map(
    transformKeysToCamelCase
  );
});

export const paymentsBillV2Selector = createSelector(billSelector, (bill) => {
  return (bill?.bill_payments_v2 || []).map((payment) => {
    const camelCasedPayment = transformKeysToCamelCase(payment);
    return handleResponse(camelCasedPayment, billPaymentListingV2Schema);
  }) as BillPaymentListingV2[];
});

type VendorBillSelectorReturn = {
  id: string;
  url: string;
  email?: string;
  display_name: string;
  expired_documents: {
    id: string | number;
    type: string;
    document?: string;
    expiration_date?: string;
  }[];
  pending_doc_requests?: {
    id: string | number;
    created_at: string;
    document_types: DocumentType[];
  }[];
};

export const vendorBillSelector = createSelector(billSelector, (bill) => {
  return {
    ...bill.vendor,
    id: parseRefinementIdFromUrl(bill.vendor?.url ?? "") ?? "",
    displayName: bill.vendor?.display_name,
    expiredDocuments: (bill.vendor?.expired_documents || []).map(
      transformKeysToCamelCase
    ),
    pendingDocRequests: (bill.vendor?.pending_doc_requests || []).map(
      transformKeysToCamelCase
    ),
  } as unknown as VendorBillSelectorReturn &
    KeysToCamelCase<VendorBillSelectorReturn>;
});

type Line = {
  id?: string;
  item?: {
    url?: string;
    display_name?: string;
  };
  amount?: number;
  deleted?: boolean;
  account?: {
    url?: string;
    display_name?: string;
  };
  customer?: {
    id?: string;
    url?: string;
    display_name?: string;
  };
  created?: boolean;
  is_spent: boolean;
  description?: string;
  billable_status?: string;
  linked_transaction?: BillLineLinkedTransaction;
  remaining_budget_after_this_bill: number | null;
  remaining_budget_after_this_bill_blocked?: boolean;
  parent?: {
    is_in_quickbooks: boolean;
  };
  order?: number;
  linked_invoice_line?: {
    customer_id: number;
    invoice_id: number;
    invoice_review_status: string;
    id: number;
  };
};

export const linesBillSelector = createSelector(
  billSelector,
  (bill) => bill.lines as Line[]
);

export const billLinesJobsSelector = createSelector(
  linesBillSelector,
  (lines) => {
    const uniqueUrls = new Set<{
      url: string;
      displayName: string;
      display_name: string;
    }>();

    for (const line of lines) {
      const url = line.customer?.url;
      const displayName = line.customer?.display_name;

      if (!line.deleted && url && displayName) {
        uniqueUrls.add({ url, displayName, display_name: displayName });
      }
    }

    return Array.from(uniqueUrls);
  }
);

export const camelCaseLinesBillSelector = createSelector(
  linesBillSelector,
  (lines) => lines.map(transformKeysToCamelCase)
);

export const camelCaseLinkedTransactionsBillSelector = createSelector(
  camelCaseLinesBillSelector,
  (lines) =>
    lines.reduce(
      (acc, line) => {
        if (!line.linkedTransaction) return acc;

        const previousLinkedTransaction = acc.find(
          (linkedTransaction) =>
            linkedTransaction.objectId === line.linkedTransaction?.objectId
        );

        if (previousLinkedTransaction) {
          return acc.map((linkedTransaction) =>
            linkedTransaction.objectId === line.linkedTransaction?.objectId
              ? {
                  ...linkedTransaction,
                  lines: [
                    ...linkedTransaction.lines,
                    ...(line.linkedTransaction.id
                      ? [line.linkedTransaction.id]
                      : []),
                  ],
                }
              : linkedTransaction
          );
        }

        return [
          ...acc,
          {
            ...line.linkedTransaction,
            lines: line.linkedTransaction.id ? [line.linkedTransaction.id] : [],
          },
        ];
      },
      [] as (KeysToCamelCase<BillLineLinkedTransaction> & { lines: number[] })[]
    )
);

export const camelCaseQuickBooksLinkedTransactionsBillSelector = createSelector(
  camelCaseLinkedTransactionsBillSelector,
  (linkedTransactions) => [
    ...linkedTransactions
      .reduce(
        (map, transaction) =>
          transaction.parent.type === PURCHASE_ORDER_TYPE.QB ||
          transaction.parent.type === PURCHASE_ORDER_TYPE.BT
            ? map.set(transaction.objectId, transaction)
            : map,
        new Map<
          number,
          KeysToCamelCase<BillLineLinkedTransaction> & { lines: number[] }
        >()
      )
      .values(),
  ]
);

export const billHasLinkedPurchaseOrderSelector = createSelector(
  camelCaseLinesBillSelector,
  (lines) => lines.some((line) => line.linkedTransaction)
);

export const billHasMultipleLinkedPurchaseOrderSelector = createSelector(
  camelCaseLinesBillSelector,
  (lines) =>
    lines.reduce((acc, cur) => {
      if (
        cur.linkedTransaction?.parent.url &&
        !acc.includes(cur.linkedTransaction?.parent.url)
      ) {
        return [...acc, cur.linkedTransaction?.parent.url];
      }

      return acc;
    }, [] as string[]).length > 1
);

export const commentsBillSelector = createSelector(
  billSelector,
  (bill) => bill.comments
);

export const defaultBankAccountBillSelector = createSelector(
  billSelector,
  (bill) =>
    bill?.customers
      ?.map((customer) => (customer as any)?.bank_account)
      .concat([bill?.default_account_balance?.url])
      .filter(Boolean)
);

export const approvalsBillSelector = createSelector(
  billSelector,
  (bill) => bill.approvals.map(transformKeysToCamelCase) as WorkflowApprovals[]
);

export const workflowsBillSelector = createSelector(
  billSelector,
  (bill) => bill.approval_workflows.map(transformKeysToCamelCase) as Workflow[]
);

export const billIdSelector = createSelector(billSelector, (bill) => bill.id);

export const isLoadingBillSelector = createSelector(
  statusBillSelector,
  (status) => status === "loading"
);

const BILL_INFO_FIELDS_TO_DIRTY_CHECK = [
  "date",
  "due_date",
  "tax.value",
  "doc_number",
  "is_vendor_credit",
];

const BILL_INFO_OBJ_FIELDS_TO_DIRTY_CHECK = ["vendor"];

const BILL_LINES_FIELDS_TO_DIRTY_CHECK = [
  "amount",
  "description",
  "billable_status",
];

const BILL_LINES_OBJ_FIELDS_TO_DIRTY_CHECK = ["item", "account", "customer"];

const BILL_INVOICED_LINES_FIELDS_TO_DIRTY_CHECK = [
  "amount",
  "description",
  "billable_status",
];

const BILL_INVOICED_LINES_OBJ_FIELDS_TO_DIRTY_CHECK = [
  "item",
  "account",
  "customer",
];

const getUrl = (obj: unknown) =>
  typeof obj === "object" ? dotObject.get(obj as object, "url") : obj;

const enhanceBillToRemoveDuplicatedLines = (
  bill: RootState["bill"]["bill"]
) => {
  const lines = ((bill.lines as Line[]) || []).reduce((acc, line) => {
    const lineIndex = acc.findIndex((accLine) => accLine.id === line.id);

    if (lineIndex === -1) {
      return [...acc, line];
    }

    const existingLine = acc[lineIndex];

    if (line.deleted && existingLine.deleted) {
      acc[lineIndex] = line;
    } else if (!line.deleted) {
      acc[lineIndex] = line;
    }

    return acc;
  }, [] as Line[]);

  return { ...bill, lines };
};

export const selectStaticBill = (state: RootState) =>
  enhanceBillToRemoveDuplicatedLines(state.bill.staticBill);

export const staticBillTaxValueSelector = createSelector(
  selectStaticBill,
  (bill) => (bill.tax.isSet ? bill.tax.value || 0 : 0)
);

export const staticBillSubTotalSelector = createSelector(
  selectStaticBill,
  (bill) =>
    sum(bill.total_amount || 0, -(bill.tax.isSet ? bill.tax.value || 0 : 0))
);

const selectDynamicBill = (state: RootState) =>
  enhanceBillToRemoveDuplicatedLines(state.bill.bill);

export const currentStaticReviewStatus = createSelector(
  selectStaticBill,
  (bill) => bill.review_status
);

export const staticLinesBillSelector = createSelector(
  selectStaticBill,
  (bill) => bill.lines as Line[]
);

export const billPropagationPayloadSelector = createSelector(
  selectStaticBill,
  selectDynamicBill,
  (staticBill, dynamicBill) => {
    const propagationParams: SaveWithPropagationParams = { values: {} };

    if (!(staticBill as any)?.id) return propagationParams;

    const billValue = dynamicBill.vendor?.url || undefined;
    const staticBillValue = staticBill.vendor?.url || undefined;

    const parsedId = parseFloat(
      parseRefinementIdFromUrl(staticBill.url || "") || ""
    );

    if (!parsedId) return propagationParams;

    propagationParams.id = parsedId;
    propagationParams.type = "bill";
    propagationParams.values.name = staticBill.doc_number;
    propagationParams.values.url = staticBill.url;

    if (!isEqual(billValue, staticBillValue)) {
      propagationParams.updatedFields = ["vendor"];
      propagationParams.values.fields = {
        vendor: billValue,
      };
      propagationParams.values.prevValues = {
        vendor: staticBillValue,
      };
    }

    const linesInfo = getLinesInfos({
      lines: dynamicBill.lines,
      staticLines: staticBill.lines,
      parentId: parsedId,
    });

    if (linesInfo?.lines?.length) {
      propagationParams.lines = linesInfo.lines;
      propagationParams.values.lines = linesInfo.values;
    }

    return propagationParams;
  }
);

export const isDirtyLinkedInvoiceLines = createSelector(
  selectStaticBill,
  selectDynamicBill,
  (staticBill, dynamicBill) => {
    if (!(staticBill as any)?.id) return false;

    const staticLines = staticBill.lines.filter(
      (line) =>
        !(line as any).deleted && !!(line as any).linked_to_draft_invoice
    );
    const dynamicLines = dynamicBill.lines.filter(
      (line) =>
        !(line as any).deleted && !!(line as any).linked_to_draft_invoice
    );
    const vendorChanged =
      getUrl(dynamicBill.vendor) !== getUrl(staticBill.vendor) &&
      staticLines.length > 0;

    return (
      vendorChanged ||
      staticLines.some((line, index) => {
        const dynamicLine = dynamicLines[index];

        const isInfoDirty = BILL_INVOICED_LINES_FIELDS_TO_DIRTY_CHECK.some(
          (field) => {
            const previousValue = dotObject.get(line, field);
            const nextValue = dotObject.get(dynamicLine, field);
            const hasChanged = nextValue != previousValue;

            if (
              hasChanged &&
              field === "billable_status" &&
              nextValue === "NotBillable"
            ) {
              return true;
            }

            return hasChanged;
          }
        );

        if (isInfoDirty) return true;

        return BILL_INVOICED_LINES_OBJ_FIELDS_TO_DIRTY_CHECK.some(
          (field) =>
            getUrl(dotObject.get(dynamicLine, field)) !=
            getUrl(dotObject.get(line, field))
        );
      })
    );
  }
);

export const isDirtyBillSelector = createSelector(
  selectStaticBill,
  selectDynamicBill,
  (staticBill, dynamicBill) => {
    if (!(staticBill as any)?.id) return false;

    const isInfoDirty = BILL_INFO_FIELDS_TO_DIRTY_CHECK.some(
      (field) =>
        dotObject.get(dynamicBill, field) != dotObject.get(staticBill, field)
    );

    if (isInfoDirty) return true;

    const isInfoObjDirty = BILL_INFO_OBJ_FIELDS_TO_DIRTY_CHECK.some(
      (field) =>
        getUrl(dotObject.get(dynamicBill, field)) !=
        getUrl(dotObject.get(staticBill, field))
    );

    if (isInfoObjDirty) return true;

    const staticLines = staticBill.lines.filter(
      (line) => !(line as any).deleted
    );
    const dynamicLines = dynamicBill.lines.filter(
      (line) => !(line as any).deleted
    );

    if (staticLines.length !== dynamicLines.length) return true;

    return staticLines.some((line, index) => {
      const dynamicLine = dynamicLines[index];

      const isInfoDirty = BILL_LINES_FIELDS_TO_DIRTY_CHECK.some(
        (field) =>
          dotObject.get(dynamicLine, field) != dotObject.get(line, field)
      );

      if (isInfoDirty) return true;

      return BILL_LINES_OBJ_FIELDS_TO_DIRTY_CHECK.some(
        (field) =>
          getUrl(dotObject.get(dynamicLine, field)) !=
          getUrl(dotObject.get(line, field))
      );
    });
  }
);

type IsLoadingBillType =
  | Pick<Bill, "file_sync_status">
  | Pick<BillListItemResponse, "fileSyncStatus">;

export const isLoadingBillOcr = (
  bill: IsLoadingBillType | KeysToCamelCase<IsLoadingBillType>
) => {
  if (isObjectEmpty(bill)) return false;

  const fileSyncStatus = dotObject.get(
    bill,
    "file_sync_status",
    dotObject.get(bill, "fileSyncStatus")
  );

  return ["deferred", "requested", "in-progress"].includes(fileSyncStatus);
};

export const isErrorBill = (bill: Bill) => !!bill.errors_not_ignored_exist;

export const isErrorBillV2 = (bill: BillListItemResponse) =>
  !!bill.errorsNotIgnoredExist;

type RenderStatusBill = Pick<
  BillListItemResponse,
  | "archived"
  | "isRejected"
  | "reviewStatus"
  | "fileSyncStatus"
  | "errorsIgnoredExist"
  | "errorsNotIgnoredExist"
>;

export const renderStatus = (bill: RenderStatusBill, testId?: string) => {
  const reviewStatus = dotObject.get(
    bill,
    "review_status",
    dotObject.get(bill, "reviewStatus")
  );

  let color = reviewStatusColor(reviewStatus);
  let content = humanReadableBillReviewStatus(reviewStatus);

  const isRejected = dotObject.get(
    bill,
    "is_rejected",
    dotObject.get(bill, "isRejected")
  );

  const isArchivedByUser = isArchivedByUserBill(bill);

  const isPartiallyPaid = reviewStatus === BILL_STATUS.PARTIALLY_PAID;

  const errorsIgnoredExist = dotObject.get(
    bill,
    "errors_ignored_exist",
    dotObject.get(bill, "errorsIgnoredExist")
  );

  const errorsNotIgnoredExist = dotObject.get(
    bill,
    "errors_not_ignored_exist",
    dotObject.get(bill, "errorsNotIgnoredExist")
  );

  if (isRejected) {
    color = "error";
    content = "Rejected";
  } else if (isArchivedByUser) {
    color = "neutral";
    content = `Archived ${content.toLowerCase().replace("ach", "ACH")}`;
  } else if (isPartiallyPaid) {
    color = "warning";
    content = "Partially paid";
  } else if (errorsNotIgnoredExist) {
    color = "error";
    content = "Sync errors";
  } else if (errorsIgnoredExist) {
    color = "neutral";
    content = "Ignored sync errors";
  }

  return (
    !isLoadingBillOcr(bill) && (
      <Tag color={color} data-testid={testId}>
        {content}
      </Tag>
    )
  );
};

export const getBillsByStatus = (
  billList: BillListItems | BillListItemsV2,
  status: Status
) => {
  if (status === "all") {
    return billList.allBills;
  } else if (status === "draft") {
    return billList.draftBills;
  } else if (status === "approval") {
    return billList.approvalBills;
  } else if (status === "for-payment") {
    return billList.forPaymentBills;
  }

  return {
    bills: [],
    isError: false,
    loading: "loaded",
    total: 0,
    filter: { values: {}, query: [] },
  };
};

export const getBillConfigByStatus = (status: Status) => {
  return TAB_STATUS_TO_BILLS_CONST[status];
};

export const isValidStatus = (status: string | null): status is Status =>
  !!(status && Object.values(TAB_STATUS).includes(status as Status));

export const getOrderingByStatus = (status: Status | StatusKey) => {
  return ["all", "ALL", "for-payment", "FOR_PAYMENT"].includes(status)
    ? "-date"
    : "-true_created_at";
};

export const sortQuery = (query: QueryFilters) =>
  query.slice().sort((a, b) => stringCompare(a.dataIndex, b.dataIndex));

export const getSourceEmail = (bill: {
  email_body_attachable?: { document?: string };
}) => {
  if (!bill.email_body_attachable?.document) {
    return null;
  }

  return (
    <Tag
      as="button"
      type="button"
      onClick={() =>
        window.open(bill.email_body_attachable?.document, "_blank")
      }
      color="info"
    >
      View source email
    </Tag>
  );
};

export const getReceivedDate = (bill: {
  id?: string;
  created_at?: string | null;
}) => {
  if (!(bill.id && bill.created_at)) {
    return null;
  }

  const formattedDate = formatDate(new Date(bill.created_at));

  return (
    <Tag>
      Received on <time>{formattedDate}</time>
    </Tag>
  );
};

export const getTransactionType = (url: string) =>
  url.includes("bill") ? "Bill" : "Receipt";

export const getTransactionRoute = (url: string) =>
  `/${
    getTransactionType(url) === "Bill" ? "bills" : "expenses"
  }/${parseRefinementIdFromUrl(url)}`;

type BillLineLinkedTransaction = {
  id: number;
  url: string;
  spent?: string | number;
  amount: string | number;
  parent: {
    id: number;
    url: string;
    type: string;
    doc_number: string;
  };
  type: string;
  balance: string | number;
  object_id: number;
  usage_percentage: number;
};

export const getLienWaiverTooltip = (
  item: BillListItemResponse["lienWaivers"][number]
) => {
  if (
    ![...LIEN_WAIVER_SIGNED_STATUS, ...LIEN_WAIVER_REQUESTED_STATUS].includes(
      item.status.toLowerCase()
    )
  ) {
    return item.statusLabel;
  }

  const dateField = LIEN_WAIVER_SIGNED_STATUS.includes(item.status)
    ? item.signedAt
    : item.updatedAt;
  const date = formatDate(new Date(dateField!));
  return `${item.statusLabel} on ${date} for ${formatCurrency(
    item.paymentAmount || 0
  )} payment`;
};

export const isInPaymentStatusBillSelector = createSelector(
  billReviewStatusSelector,
  (reviewStatus) => isInPaymentStatusBill(reviewStatus)
);
