import React, { type ReactNode, useMemo } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  Flex,
  Text,
} from "@adaptive/design-system";
import {
  formatCurrency,
  formatDate,
  parseStringCopy,
} from "@adaptive/design-system/utils";
import type { ExternalBillPaymentResponse } from "@bill-payment/types";
import { PROCESS_PREREQUISITE_TYPE } from "@src/bill-payment/constants";
import {
  PAYMENT_STATUS,
  PAYMENT_STATUS_VARIANT_V2,
  PAYMENT_STEP_INFO_STRINGS,
} from "@src/bills/constants";

import type { PaymentStatusKey } from "../../types";

type BillPaymentInfoProps = {
  billPayment: ExternalBillPaymentResponse;
  vendorAchFormUrl: string;
};
type PaymentInfoProps = {
  title: string;
  value: string | ReactNode;
  subtitle?: string | ReactNode;
};

const PaymentInfo = ({ title, value, subtitle }: PaymentInfoProps) => (
  <Flex>
    <Flex width="120px" shrink={false}>
      <Text size="sm" color="neutral-500">
        {title}
      </Text>
    </Flex>
    <Flex direction="column">
      <Text weight="bold" size="lg">
        {value}
      </Text>
      {subtitle && <Text size="sm">{subtitle}</Text>}
    </Flex>
  </Flex>
);

export const BillPaymentInfo = ({
  billPayment,
  vendorAchFormUrl,
}: BillPaymentInfoProps) => {
  const {
    totalAmount,
    status,
    vendor,
    vendorBankAccount,
    appliedAmount,
    paymentSchedule,
    processPrerequisites,
  } = billPayment;

  const paymentAmount = useMemo(() => {
    if (
      (
        [PAYMENT_STATUS.CANCELLED, PAYMENT_STATUS.FAILED] as PaymentStatusKey[]
      ).includes(status as PaymentStatusKey)
    ) {
      const formattedOriginalTotalAmount = formatCurrency(appliedAmount, {
        currencySign: true,
        allowNegative: true,
      });
      return formattedOriginalTotalAmount;
    }
    const formattedTotalAmount = formatCurrency(totalAmount, {
      currencySign: true,
      allowNegative: true,
    });

    const formattedAmountForBill = formatCurrency(appliedAmount, {
      currencySign: true,
      allowNegative: true,
    });

    // if values differ, it means the payment includes multiple bills
    // and we only want to show the amount paid for this particular bill
    return formattedAmountForBill !== formattedTotalAmount
      ? `${formattedAmountForBill} (of ${formattedTotalAmount} payment)`
      : formattedTotalAmount;
  }, [totalAmount, appliedAmount, status]);

  const payToTitle = useMemo(() => {
    if (status === PAYMENT_STATUS.SCHEDULED) {
      return PAYMENT_STEP_INFO_STRINGS.PAYMENT_METHOD_TITLE;
    } else if (status === PAYMENT_STATUS.PAID) {
      return PAYMENT_STEP_INFO_STRINGS.PAID_TO_TITLE;
    } else {
      return PAYMENT_STEP_INFO_STRINGS.PAY_TO_TITLE;
    }
  }, [status]);

  const achInfoRequired = useMemo(
    () =>
      (processPrerequisites.find(
        (prerequisite) =>
          prerequisite.type === PROCESS_PREREQUISITE_TYPE.VENDOR_BANKING_ACH &&
          !prerequisite.isSatisfied
      ) ||
        !vendorBankAccount) &&
      vendor.latestAchRequest,
    [vendorBankAccount, vendor, processPrerequisites]
  );

  const pendingLienWaiver = useMemo(
    () =>
      processPrerequisites.some(
        (prerequisite) =>
          prerequisite.type ===
            PROCESS_PREREQUISITE_TYPE.LIEN_WAIVER_SIGNATURE &&
          !prerequisite.isSatisfied
      ),
    [processPrerequisites]
  );

  const hasPendingPrerequisites = useMemo(
    () =>
      status === PAYMENT_STATUS.PENDING_PREREQUISITES &&
      processPrerequisites.some((prerequisite) => !prerequisite.isSatisfied),
    [status, processPrerequisites]
  );

  const statusEquivalent = useMemo(() => {
    if (status === PAYMENT_STATUS.PAID) {
      return PAYMENT_STATUS.PAID;
    } else if (
      hasPendingPrerequisites ||
      pendingLienWaiver ||
      achInfoRequired ||
      status === PAYMENT_STATUS.SCHEDULED
    ) {
      return PAYMENT_STATUS.SCHEDULED;
    } else {
      return PAYMENT_STATUS.PENDING;
    }
  }, [achInfoRequired, hasPendingPrerequisites, pendingLienWaiver, status]);

  const statusTitle = useMemo(() => {
    if (status === PAYMENT_STATUS.PAID) {
      return PAYMENT_STEP_INFO_STRINGS.PAYMENT_MADE;
    } else if (
      (status === PAYMENT_STATUS.PENDING ||
        (status === PAYMENT_STATUS.PENDING_PREREQUISITES &&
          !hasPendingPrerequisites)) &&
      !achInfoRequired &&
      !pendingLienWaiver
    ) {
      return PAYMENT_STEP_INFO_STRINGS.PAYMENT_IN_PROGRESS;
    } else {
      const pluralSuffix = pendingLienWaiver ? "s" : "";
      if (pendingLienWaiver && achInfoRequired) {
        return parseStringCopy(
          `${PAYMENT_STEP_INFO_STRINGS.PAYMENT_PENDING_ACH_AND_LIEN_WAIVER}${pluralSuffix}`,
          { number: pendingLienWaiver }
        );
      } else if (achInfoRequired) {
        return PAYMENT_STEP_INFO_STRINGS.PAYMENT_PENDING_ACH;
      } else if (pendingLienWaiver) {
        return parseStringCopy(
          `${PAYMENT_STEP_INFO_STRINGS.PAYMENT_PENDING_LIEN_WAIVER}${pluralSuffix}`,
          { number: pendingLienWaiver }
        );
      } else {
        return PAYMENT_STEP_INFO_STRINGS.PAYMENT_IN_PROGRESS;
      }
    }
  }, [achInfoRequired, hasPendingPrerequisites, pendingLienWaiver, status]);

  const payTo = useMemo(() => {
    if (vendorBankAccount) {
      return parseStringCopy(PAYMENT_STEP_INFO_STRINGS.VENDOR_ACCOUNT_TITLE, {
        routingNumber: vendorBankAccount.routingNumber,
        accountNumber: vendorBankAccount.accountNumber,
      });
    } else {
      return (
        <Button block size="sm" as="a" href={vendorAchFormUrl} target="_blank">
          {PAYMENT_STEP_INFO_STRINGS.ACH_REQUEST_BUTTON}
        </Button>
      );
    }
  }, [vendorAchFormUrl, vendorBankAccount]);

  const payToSubtitle = useMemo(() => {
    if (paymentSchedule?.deliveredOn) {
      return formatDate(paymentSchedule?.deliveredOn, "P");
    } else if (
      paymentSchedule?.expectedDeliveryAfter &&
      paymentSchedule?.expectedDeliveryBefore
    ) {
      return `${formatDate(
        paymentSchedule?.expectedDeliveryAfter,
        "P"
      )} - ${formatDate(paymentSchedule?.expectedDeliveryBefore, "P")}`;
    } else {
      return "";
    }
  }, [
    paymentSchedule?.deliveredOn,
    paymentSchedule?.expectedDeliveryAfter,
    paymentSchedule?.expectedDeliveryBefore,
  ]);

  return (
    <Card
      as={Flex}
      direction="column"
      width="full"
      size="sm"
      gap="2xl"
      shrink={false}
    >
      <Alert
        variant={
          PAYMENT_STATUS_VARIANT_V2[statusEquivalent as PaymentStatusKey]
        }
      >
        <AlertTitle>
          <Flex gap="xl" justify="space-between" width="full">
            {statusTitle}
            <Text as="strong" weight="bold">
              {paymentAmount}
            </Text>
          </Flex>
        </AlertTitle>
      </Alert>
      <Flex
        gap="2xl"
        direction={{ tablet: "column", desktop: "row" }}
        width="full"
        separator={{ tablet: false, desktop: true }}
      >
        <Flex gap="2xl" direction="column" width="full">
          <PaymentInfo
            title={payToTitle}
            value={payTo}
            subtitle={payToSubtitle}
          />
        </Flex>
      </Flex>
    </Card>
  );
};
