// eslint-disable-next-line simple-import-sort/imports
import "vite/modulepreload-polyfill";
import "symbol-observable";
import React, { type ReactNode, useEffect, useState } from "react";
import * as analytics from "@utils/analytics";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Route,
  Routes as ReactRouterRoutes,
  useLocation,
  useNavigationType,
} from "react-router";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Link,
  Loader,
  Provider,
  Text,
} from "@adaptive/design-system";
import * as Sentry from "@sentry/react";
import { useDialog, useEvent } from "@adaptive/design-system/hooks";
import {
  LoginForm,
  type LoginFormInfo,
} from "./auth/components/login/login-form";
import { Chat } from "@components/Chat";
import { ErrorBoundary } from "@components/error-boundary";
import { ReduxProvider } from "@components/redux-provider";
import { initializeState } from "@store/init";
import { useUserInfo } from "@store/user";
import { Navigate } from "@components/navigate";
import { Auth } from "./auth";
import { ExternalRoutes } from "@external/components";
import "./shared/styles/index.css";
import { StaffWarning } from "@components/staff-warning";
import { Onboarding } from "@src/onboarding";
import { App } from "@src/app";
import { isStaff } from "./shared/utils/is-staff";

const el = document.getElementById("root") as HTMLElement;
const isExternal = el.dataset.isExternal === "true";
const isOnboarding = el.dataset.isOnboarding === "true";
const isAuthenticated = el.dataset.isAuthenticated === "true";

const isE2E = navigator.userAgent.includes("(Playwright)");
window.IS_E2E = isE2E;

if (import.meta.env.PROD && window.SENTRY_DSN) {
  Sentry.init({
    dsn: window.SENTRY_DSN,
    release: window.COMMIT_SHA,
    environment: window.SENTRY_ENV,
    integrations: [
      Sentry.reactRouterV7BrowserTracingIntegration({
        useEffect,
        matchRoutes,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
      }),
    ],
    normalizeDepth: 6,
    tracesSampleRate: window.SENTRY_SAMPLE_RATE || 0,
    tracePropagationTargets: [
      /^bookkeeping-.+\.herokuapp.com/, //handle heroku app names
      /^.*.adaptive.build/, //handle aliased subdomains environments
      /^\//, // handle relative url
    ],
  });

  (window as any).SentrySetTag = Sentry.setTag;
}

export const ReAuth = () => {
  const { user } = useUserInfo();

  const [formInfo, setFormInfo] = useState<LoginFormInfo>({
    email: "",
    isValid: false,
    password: "",
    hasError: false,
    isSubmitting: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const dialog = useDialog({ lazy: true });

  const onReAuth = useEvent(() => {
    if (dialog.isRendered) return;

    analytics.track("reAuthenticateRequested", {
      id: user.id,
      name: user.full_name,
      email: user.email,
      phoneNumber: user.phone_number,
    });
    dialog.show();
  });

  const reload = useEvent(() => {
    dialog.hide();
    setIsLoading(true);
    window.location.reload();
  });

  const onClose = useEvent(() => {
    analytics.track("reAuthenticateCancelled", {
      id: user.id,
      name: user.full_name,
      email: user.email,
      phoneNumber: user.phone_number,
    });
    reload();
  });

  const onSuccess = useEvent(() => {
    analytics.track("reAuthenticateSucceeded", {
      id: user.id,
      name: user.full_name,
      email: user.email,
      phoneNumber: user.phone_number,
    });
    reload();
  });

  const onForgotPasswordClick = useEvent(() => {
    analytics.track("reAuthenticateForgotPassword", {
      id: user.id,
      name: user.full_name,
      email: user.email,
      phoneNumber: user.phone_number,
    });
  });

  useEffect(() => {
    window.addEventListener("re-auth", onReAuth);

    return () => {
      window.removeEventListener("re-auth", onReAuth);
    };
  }, [onReAuth]);

  return (
    <>
      {isLoading ? <Loader position="fixed" /> : null}
      {dialog.isRendered ? (
        <Dialog
          size="sm"
          show={dialog.isVisible}
          align="center"
          variant="dialog"
          onClose={onClose}
        >
          <DialogHeader>
            <Flex gap="md" direction="column" align="center">
              <Text weight="bold">Your session expired</Text>
              <Text size="md">Please sign in again to access Adaptive</Text>
            </Flex>
          </DialogHeader>
          <DialogContent>
            <Flex gap={formInfo.hasError ? "xl" : "none"} direction="column">
              <LoginForm
                id="re-auth"
                onSuccess={onSuccess}
                onChange={setFormInfo}
              />
              <Link
                href={`/accounts/forgot-password${
                  formInfo.email ? `?email=${formInfo.email}` : ""
                }`}
                onClick={onForgotPasswordClick}
              >
                I forgot my password
              </Link>
            </Flex>
          </DialogContent>
          <DialogFooter>
            <Button
              block
              type="submit"
              form="re-auth"
              disabled={formInfo.isSubmitting}
            >
              Sign in
            </Button>
          </DialogFooter>
        </Dialog>
      ) : null}
    </>
  );
};

const Routes = Sentry.withSentryReactRouterV7Routing(ReactRouterRoutes);

export const RouterProvider = ({ children }: { children: ReactNode }) => (
  <BrowserRouter>
    <Routes>
      <Route path="*" element={children} />
    </Routes>
    <Navigate />
  </BrowserRouter>
);

const root = createRoot(el);

const init = async () => {
  root.render(<Loader position="fixed" />);

  await initializeState();

  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <ReduxProvider>
          <Provider
            search={{ threshold: window.FRONTEND_FUZZY_THRESHOLD ?? 0.5 }}
            autoFocus={!window.IS_E2E}
            virtualScroll={!window.IS_E2E}
          >
            {!isAuthenticated ? (
              <RouterProvider>
                <Auth />
                <ExternalRoutes />
              </RouterProvider>
            ) : (
              <>
                <RouterProvider>
                  {isOnboarding && !isExternal ? <Onboarding /> : <App />}
                </RouterProvider>
                <Chat />
                <StaffWarning
                  show={window.WARN_IS_STAFF && isStaff() && !isE2E}
                />
              </>
            )}
            <ReAuth />
          </Provider>
        </ReduxProvider>
      </ErrorBoundary>
    </React.StrictMode>
  );
};

init();

window.history.pushState = new Proxy(window.history.pushState, {
  apply: (target, thisArg, argArray) => {
    const result = target.apply(thisArg, argArray as any);
    analytics.page(window.location.pathname);
    return result;
  },
});
