import React, {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router";
import {
  Button,
  Flex,
  Link,
  Switch,
  TableConfigurationButton,
  TableProvider,
  Tabs,
  TabsList,
  TabsTab,
  Text,
  toast,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { omit, pick } from "@adaptive/design-system/utils";
import type { Expense } from "@api/expenses";
import { CounterLabel } from "@components/counter-label";
import {
  Sticky,
  StickyMeasurer,
  StickyProvider,
  type StickyProviderOnResizeHandler,
} from "@components/sticky";
import { TableFilterControls } from "@components/table-filter";
import {
  type QuerySets,
  setFormatter,
} from "@components/table-filter/formatters";
import { useTableFilters } from "@components/table-filter/table-filter-hooks";
import type { StrictValuesFilters } from "@components/table-filter/types";
import { useAccountsSimplified } from "@hooks/use-accounts-simplified";
import { useUsersSimplified } from "@hooks/use-users-simplified";
import { createSelector } from "@reduxjs/toolkit";
import {
  selectExpenseQuery,
  selectExpenseTableInitialized,
  useExpenseAction,
  useExpenseQuery,
} from "@store/expenses";
import { useAppSelector } from "@store/hooks";
import { useUserInfo } from "@store/user";
import { noop } from "@utils/noop";

import { BatchActions } from "../batch-actions";
import { ExpenseTable, useExpenseStatus, useFetchTableData } from "../table";
import type { StatusMapKey, TabLink } from "../types";
import {
  BILLABLE_STATUS_FILTER,
  CARD_FILTER,
  DRAW_STATUS_FILTER,
  RECEIPT_FILTER,
  STATUS_FILTER,
  StatusMap,
  STRINGS,
} from "../utils/constants";

const STATUS_TO_LABEL: Record<StatusMapKey, string> = {
  ALL: "All",
  DRAFT: STRINGS.draftTitle,
  FOR_REVIEW: "Waiting for review",
};

const ExpenseTabs: TabLink<StatusMapKey>[] = Object.entries(StatusMap).map(
  ([status, str]) => ({
    to: str,
    value: STATUS_TO_LABEL[status as StatusMapKey],
    status: status as StatusMapKey,
  })
);

const selectQueryDrafts = createSelector(
  selectExpenseQuery,
  (query) => query.DRAFT
);

const selectQueryForReview = createSelector(
  selectExpenseQuery,
  (query) => query.FOR_REVIEW
);

const selectQuerySummaries = createSelector(
  [selectQueryDrafts, selectQueryForReview],
  (drafts, forReview) => ({
    DRAFT: {
      count: drafts.count,
      loading: drafts.response === "pending",
    },
    FOR_REVIEW: {
      count: forReview.count,
      loading: forReview.response === "pending",
    },
  })
);

const formatter: typeof setFormatter = (realmId, selections) => {
  const filters = Object.entries(setFormatter(realmId, selections)).reduce(
    (acc, [key, value]) => {
      if (key === "status") {
        const enhancedValue =
          value instanceof Set ? value.values().next().value : value;

        if (enhancedValue === "archived") {
          return { ...acc, is_archived: true };
        } else {
          return { ...acc, review_status: value };
        }
      } else if (key === "matched_transaction" && value instanceof Set) {
        if (value.has("card_link_true")) {
          value.delete("card_link_true");
          value.add("true");
        }

        if (value.has("card_link_false")) {
          value.delete("card_link_false");
          value.add("false");
        }

        return { ...acc, card_link: value };
      } else if (key === "receipt" && value instanceof Set) {
        if (value.has("receipt_true")) {
          value.delete("receipt_true");
          value.add("true");
        }

        if (value.has("receipt_false")) {
          value.delete("receipt_false");
          value.add("false");
        }
      }

      return { ...acc, [key]: value };
    },
    {
      receipt: new Set([]),
      assignee: new Set([]),
      card_link: new Set([]),
      card_holder: new Set([]),
      payment_account: new Set([]),
    } as QuerySets
  );

  /**
   * This is a small workaround over "user" and "my receipts" filters.
   * Since they conflict with each other, we need to handle in this way:
   * - if "user" is set and "my receipts" is off, we use users filter
   * - if "user" is set and "my receipts" is on, we create an empty state
   * - if "user" is not set and "my receipts" is on, we use my receipts filter
   */
  const hasUser = filters.user instanceof Set ? filters.user.size > 0 : false;
  const hasMyReceiptsUser = !!filters.my_receipts_user;

  if (hasUser && !hasMyReceiptsUser) {
    filters.assignee = filters.user;
  } else if (hasUser && hasMyReceiptsUser) {
    filters.assignee = new Set([-1]);
  } else if (!hasUser && hasMyReceiptsUser) {
    filters.assignee = new Set([filters.my_receipts_user as number]);
  }

  delete filters.user;
  delete filters.my_receipts_user;

  return filters;
};

export const ExpensesTabNavigation = () => {
  const { status } = useExpenseStatus();

  const summaries = useAppSelector(selectQuerySummaries);

  return (
    <TabsList>
      {ExpenseTabs.map((tab) => (
        <TabsTab key={tab.status} value={tab.status}>
          {tab.status !== "ALL" ? (
            <CounterLabel
              label={tab.value}
              active={status === tab.status}
              loading={summaries[tab.status].loading}
              counter={summaries[tab.status].count}
            />
          ) : (
            tab.value
          )}
        </TabsTab>
      ))}
    </TabsList>
  );
};

const MY_EXPENSES_SWITCH_KEY = "my-expenses-switch";

type TabHeaderProps = {
  children: ReactNode;
  clearSelection: () => void;
  tableFilters: ReturnType<typeof useTableFilters>;
  hasFilters: boolean;
};

export const TabHeader = ({
  children,
  clearSelection,
  tableFilters,
  hasFilters,
}: TabHeaderProps) => {
  const { status } = useExpenseStatus();

  const hasConflictToastRef = useRef(false);

  const previousConflictToastHideRef = useRef(noop);

  const [myExpensesOnly, setMyExpenseOnly] = useState(
    () =>
      !window.IS_E2E && localStorage.getItem(MY_EXPENSES_SWITCH_KEY) === "true"
  );

  const { setFilters, setQueryFilters } = useExpenseQuery(status);

  const { user } = useUserInfo();

  const location = useLocation();

  const navigate = useNavigate();

  const users = useUsersSimplified({ filters: { is_staff: false } });

  const hasErrorStatus = location.state === "errors" && status === "ALL";

  const paymentAccounts = useAccountsSimplified({
    filters: {
      only_payment_accounts: true,
      can_accounts_link_to_lines_desktop: true,
    },
  });

  const initialized = useAppSelector(selectExpenseTableInitialized);

  useEffect(() => clearSelection(), [clearSelection, tableFilters.filters]);

  const onClearUsersFilter = useEvent((filters = tableFilters.rawFilters) => {
    onFiltersChange(
      Object.entries(filters).reduce(
        (acc, [key]) =>
          key.includes("/users/") ? acc : { ...acc, [key]: filters[key] },
        {}
      )
    );
    hasConflictToastRef.current = false;
    previousConflictToastHideRef.current();
  });

  const onClearMyReceiptsFilter = useEvent(() => {
    onToggleMyExpensesFilter(false);
    hasConflictToastRef.current = false;
    previousConflictToastHideRef.current();
  });

  const onCloseConflictToast = useEvent(() => {
    hasConflictToastRef.current = false;
    previousConflictToastHideRef.current = noop;
  });

  const onFiltersChange = useEvent((filters) => {
    const hasUser = Object.keys(filters).some((key) => key.includes("/users/"));
    const hasMyReceiptsUser = !!filters.my_receipts_user;
    const hasChangedUser =
      hasUser &&
      !Object.keys(tableFilters.filters).some((key) => key.includes("/users/"));
    const hasChangedMyReceiptsUser =
      hasMyReceiptsUser && !tableFilters.rawFilters.my_receipts_user;

    if (hasUser && hasMyReceiptsUser && !hasConflictToastRef.current) {
      hasConflictToastRef.current = true;

      const { dismiss } = toast.warning(
        <Flex as="span" direction="column">
          <Text as="strong" weight="bold">
            You can&apos;t filter by user and my receipts at the same time
          </Text>
          <Flex gap="lg">
            {hasChangedMyReceiptsUser ? (
              <Link
                as="button"
                type="button"
                onClick={() => onClearUsersFilter(filters)}
              >
                Remove Assignee filter
              </Link>
            ) : hasChangedUser ? (
              <Link as="button" type="button" onClick={onClearMyReceiptsFilter}>
                Disable My receipts toggle
              </Link>
            ) : null}
          </Flex>
        </Flex>,
        { duration: 10000, onClose: onCloseConflictToast }
      );

      previousConflictToastHideRef.current = dismiss;
    }

    const { filters: formatted } = tableFilters.setFilters(filters);
    const globalFilters = omit(formatted, [
      "review_status",
      "is_archived",
      "billable_status",
      "draw_status",
    ]);
    const contextualFilters = pick(formatted, [
      "review_status",
      "is_archived",
      "billable_status",
      "draw_status",
    ]);

    setFilters(globalFilters, status);
    setQueryFilters(contextualFilters as QuerySets, { replace: true });
  });

  const onClearFiltersClick = useEvent(() => {
    onToggleMyExpensesFilter(false, {});
  });

  const extraData = useMemo(
    () => [
      ...(status === "ALL"
        ? [...STATUS_FILTER, ...DRAW_STATUS_FILTER, ...BILLABLE_STATUS_FILTER]
        : []),
      ...users.data.map((user) => ({
        ...user,
        groupLabel: "Assignee",
        key: "user",
      })),
      ...paymentAccounts.data.map((account) => ({
        ...account,
        groupLabel: "Payment account",
      })),
      ...users.data.map((user) => ({
        ...user,
        groupLabel: "Card holder",
      })),
      ...CARD_FILTER,
      ...RECEIPT_FILTER,
    ],
    [status, users.data, paymentAccounts.data]
  );

  const extraDataLoading =
    users.status === "loading" || paymentAccounts.status === "loading";

  useEffect(() => {
    if (!initialized) {
      hasConflictToastRef.current = true;
      onFiltersChange({
        ...tableFilters.rawFilters,
        ...(myExpensesOnly === true ? { my_receipts_user: user.id } : {}),
      });
      hasConflictToastRef.current = false;
    }
  }, [initialized, tableFilters, onFiltersChange, user.id, myExpensesOnly]);

  const allTableFilters = useTableFilters({
    formatter,
    storageKey: "expenses-all",
  });

  const onToggleMyExpensesFilter = useEvent(
    (checked: boolean, rawFilters?: StrictValuesFilters) => {
      setMyExpenseOnly(checked);
      localStorage.setItem(MY_EXPENSES_SWITCH_KEY, String(checked));
      const filters = rawFilters || tableFilters.rawFilters;
      onFiltersChange({
        ...filters,
        my_receipts_user: checked ? user.id : undefined,
      });
    }
  );

  /**
   * @todo we should remove it as soon as we merge bills/expenses
   */
  useEffect(() => {
    if (hasErrorStatus) {
      navigate(location.pathname + location.search, { replace: true });
      onToggleMyExpensesFilter(false);
      allTableFilters.setFilters({
        ...allTableFilters.rawFilters,
        errors: STATUS_FILTER.find((filter) => filter.value === "errors"),
      });
      setQueryFilters({ review_status: "errors" });
    }
  }, [hasErrorStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Flex justify="space-between" align="baseline" shrink={false}>
      {/**
       * This one is an workaround to be able to share
       * clear users action with table empty state
       */}
      <button
        id="clear-users-filter"
        style={{ display: "none" }}
        onClick={() => onClearUsersFilter()}
      />
      <button
        id="clear-all-filters"
        style={{ display: "none" }}
        onClick={() => onClearFiltersClick()}
      />
      <TableFilterControls
        onFiltersChange={onFiltersChange}
        filters={tableFilters.rawFilters}
        extraData={extraData}
        extraDataLoading={extraDataLoading}
        withFilterTags
        withDateFilter
      >
        <Flex width="full" gap="xl" align="center">
          {hasFilters && (
            <Button onClick={onClearFiltersClick}>Clear filters</Button>
          )}

          <Flex width="full" gap="2xl" justify="flex-end">
            <Flex width="135px" shrink={false}>
              <Switch
                id="my-expenses-only"
                label="My receipts"
                placement="left"
                checked={myExpensesOnly}
                onChange={onToggleMyExpensesFilter}
                data-testid="my-expenses-only"
              />
            </Flex>
            {children}
          </Flex>
        </Flex>
      </TableFilterControls>
    </Flex>
  );
};

type ActionsProps = {
  selection: Expense[];
  setSelection: (expenses: Expense[]) => void;
};

const Actions = ({ selection, setSelection }: ActionsProps) => {
  const { status } = useExpenseStatus();

  const { filters } = useFetchTableData(status);

  const { loadTableData } = useExpenseAction();

  const onBatchActionPerformed = useEvent((clearSelections = true) => {
    loadTableData();

    if (clearSelections) setSelection([]);
  });

  useEffect(() => {
    setSelection([]);
  }, [setSelection, status]);

  return (
    <BatchActions
      status={status}
      filters={filters}
      selection={selection}
      onActionPerformed={onBatchActionPerformed}
    />
  );
};

export const ExpensesTab = () => {
  const { status, setStatus } = useExpenseStatus();

  const [selection, setSelection] = useState<Expense[]>([]);

  const [stickOffset, setStickyOffset] = useState(0);

  const onResize = useEvent<StickyProviderOnResizeHandler>(({ sticky }) => {
    setStickyOffset(sticky.height);
  });

  const onStatusChange = useEvent((status) => {
    setStatus(status as StatusMapKey);
  });

  const clearSelection = useCallback(() => setSelection([]), []);

  const globalTableFilters = useTableFilters({
    formatter,
    storageKey: "expenses",
  });

  const allTableFilters = useTableFilters({
    formatter,
    storageKey: "expenses-all",
  });

  /**
   * @todo at some point we should refactoring it to be less confused and buggy
   * since "all" has "status" filter and other status doesn't have it
   * we manage two different states for our tabs. this function below basically
   * merge both data fixing some behaviors to guarantee that we don't apply
   * "all" filters in other tabs
   */
  const tableFilters = useMemo<ReturnType<typeof useTableFilters>>(() => {
    const currentFilters =
      status === "ALL" ? allTableFilters : globalTableFilters;

    return {
      setFilters: (values: StrictValuesFilters) => {
        let formatted;

        if (status === "ALL") {
          formatted = allTableFilters.setFilters(values);
          globalTableFilters.setFilters(
            omit(
              values,
              [
                ...STATUS_FILTER,
                ...DRAW_STATUS_FILTER,
                ...BILLABLE_STATUS_FILTER,
              ].map((item) => item.value)
            )
          );
        } else {
          allTableFilters.setFilters({
            ...pick(
              allTableFilters.rawFilters,
              [
                ...STATUS_FILTER,
                ...DRAW_STATUS_FILTER,
                ...BILLABLE_STATUS_FILTER,
              ].map((item) => item.value)
            ),
            ...values,
          });
          formatted = globalTableFilters.setFilters(values);
        }

        return formatted;
      },
      filters: currentFilters.filters,
      rawFilters: currentFilters.rawFilters,
    } as ReturnType<typeof useTableFilters>;
  }, [allTableFilters, globalTableFilters, status]);

  const hasFilters = useMemo(() => {
    return Object.values(tableFilters.rawFilters).some((value) => !!value);
  }, [tableFilters.rawFilters]);

  return (
    <Flex direction="column" shrink={false} minHeight="full">
      <TableProvider id={`${status}-expenses-table`}>
        <StickyProvider onResize={onResize}>
          <Tabs value={status} onChange={onStatusChange}>
            <ExpensesTabNavigation />
            <Sticky
              style={{
                paddingTop: "var(--spacing-2xl)",
                paddingBottom: "var(--spacing-lg)",
              }}
            >
              <Flex gap="md" direction="column">
                <TabHeader
                  clearSelection={clearSelection}
                  tableFilters={tableFilters}
                  hasFilters={hasFilters}
                >
                  <Flex gap="md">
                    <TableConfigurationButton />
                    <Actions
                      selection={selection}
                      setSelection={setSelection}
                    />
                  </Flex>
                </TabHeader>
              </Flex>
            </Sticky>
            <StickyMeasurer>
              <ExpenseTable
                selection={selection}
                stickyOffset={stickOffset}
                hasFilters={hasFilters}
                setSelection={setSelection}
              />
            </StickyMeasurer>
          </Tabs>
        </StickyProvider>
      </TableProvider>
    </Flex>
  );
};
