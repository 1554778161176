import React, { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import {
  Button,
  Card,
  Carousel,
  CarouselItem,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Loader,
  Text,
  Textarea,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import {
  type useDialog,
  useEvent,
  useForm,
} from "@adaptive/design-system/hooks";
import { formatCurrency } from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import {
  PAYMENT_METHOD,
  PROCESS_PREREQUISITE_TYPE,
} from "@bill-payment/constants";
import { useCreatePaymentV2Mutation } from "@bill-payment/hooks";
import type {
  CreateBillPaymentV2Response,
  PaymentOption,
} from "@bill-payment/types";
import type { CommentRef } from "@components/comments/comment";
import { LIEN_WAIVER_STATUS } from "@lien-waiver/constants";
import { CONFIRMATION_PAYMENT_STRINGS } from "@src/bills/constants";
import { useTwoFactorAuth } from "@store/ui";
import { useClientSettings } from "@store/user";
import * as analytics from "@utils/analytics";
import { isNegative } from "@utils/is-negative";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { sumBy } from "@utils/sumBy";
import { VendorEmailComboBox } from "@vendors/components";
import { z } from "zod";

import { PaymentSuccessMessage } from "./payment-success-message";
import {
  type BillPaymentConfirmation,
  type BillPaymentConfirmationPayload,
} from "./types";

type BillPaymentConfirmationDialogProps = {
  onClose: (paymentsFinished?: boolean) => void;
  paymentsInfo: BillPaymentConfirmationPayload;
  dialog: ReturnType<typeof useDialog>;
  onSinglePaymentCreated?: (
    payment: CreateBillPaymentV2Response[number]
  ) => Promise<void>;
};
const paymentFormSchema = z.object({
  payments: z
    .array(
      z.object({
        message: z.string().nullish(),
        vendorEmail: z.string().email(),
      })
    )
    .min(1),
});

const processProcessPrerequisites = (
  currentPaymentInfo: BillPaymentConfirmation
) => {
  const isMarkAsPaid =
    currentPaymentInfo.paymentMethod === PAYMENT_METHOD.MARK_AS_PAID;

  const processPrerequisites: PaymentOption["processPrerequisites"] = [];

  if (isMarkAsPaid) {
    return processPrerequisites;
  }

  if (
    currentPaymentInfo.payWhenLienWaiverIsSigned &&
    currentPaymentInfo.lienWaivers.some(
      (lienWaiver) => lienWaiver.lienWaiverTemplate
    )
  ) {
    processPrerequisites.push({
      type: PROCESS_PREREQUISITE_TYPE.LIEN_WAIVER_SIGNATURE,
    });
  }

  if (
    !currentPaymentInfo.vendor.hasBankingAch &&
    currentPaymentInfo.paymentMethod === PAYMENT_METHOD.ACH
  ) {
    processPrerequisites.push({
      type: PROCESS_PREREQUISITE_TYPE.VENDOR_BANKING_ACH,
    });
  }

  return processPrerequisites;
};
export const BillPaymentConfirmationDialog = ({
  paymentsInfo,
  onSinglePaymentCreated,
  onClose,
  dialog,
}: BillPaymentConfirmationDialogProps) => {
  const { vendorCreditsEnabled } = useClientSettings();

  const navigate = useNavigate();

  const [createPayment, { isLoading }] = useCreatePaymentV2Mutation();
  const { checkTwoFactorAuth } = useTwoFactorAuth();

  const commentRef = useRef<CommentRef>(null);

  const totalAmountToPay = useMemo(
    () => sumBy(paymentsInfo.payments, "amountToPay"),
    [paymentsInfo]
  );

  const [currentPaymentIndex, setCurrentPaymentIndex] = useState(0);

  const isMultiplePayment = paymentsInfo.payments.length > 1;

  const currentPayment = useMemo(
    () => paymentsInfo.payments[currentPaymentIndex],
    [paymentsInfo, currentPaymentIndex]
  );

  const enhancedOnClose = useCallback(
    (paymentsFinished?: boolean) => {
      dialog.hide();
      onClose(paymentsFinished);
    },
    [dialog, onClose]
  );

  const initialValues = useMemo(
    () => ({
      payments: paymentsInfo.payments.map((payment) => ({
        message: "",
        vendorEmail: payment.vendor.email,
      })),
    }),
    [paymentsInfo.payments]
  );

  const form = useForm({
    schema: paymentFormSchema,
    initialValues,
    onSubmit: async (values) => {
      try {
        const payload = values.payments.map((payment, index) => {
          const currentPaymentInfo = paymentsInfo.payments[index];

          const isMarkAsPaid =
            currentPaymentInfo.paymentMethod === PAYMENT_METHOD.MARK_AS_PAID;

          const paymentOption = isMarkAsPaid
            ? currentPaymentInfo.bankAccountOption.markAsPaidOption
            : currentPaymentInfo.bankAccountOption.paymentMethods.find(
                (option) =>
                  option.paymentMethod === currentPaymentInfo.paymentMethod
              );

          const processPrerequisites =
            processProcessPrerequisites(currentPaymentInfo);

          return {
            bills: currentPaymentInfo.bills.map((bill) => bill.url),
            options: {
              appliedProcessOn: currentPaymentInfo.currentDebitDate,
              appliedAmount: currentPaymentInfo.amountToPay,
              customerBankAccount:
                currentPaymentInfo.bankAccountOption?.customerBankAccount?.url,
              customerCard:
                currentPaymentInfo.bankAccountOption?.customerCard?.url,
              customerPaymentAccount:
                paymentOption?.customerPaymentAccount?.url,
              vendorBankAccount:
                paymentOption?.vendorBankAccount?.url ||
                currentPaymentInfo.vendor.bankingAchUrl,
              paymentMethod: paymentOption?.paymentMethod,
              appliedSignature: currentPaymentInfo.signature,
              processPrerequisites,
              appliedCommentText: payment.message ?? "",
              appliedVendorEmail: payment.vendorEmail,
              appliedVendorCredits:
                currentPaymentInfo?.appliedVendorCredits
                  ?.filter((credit) => credit.appliedAmount)
                  .map((credit) => ({
                    vendorCredit: credit.url,
                    appliedAmount: formatCurrency(credit.appliedAmount || 0, {
                      allowNegative: true,
                    }),
                  })) || [],
            },
            lienWaivers: currentPaymentInfo.lienWaivers.map((lienWaiver) => ({
              ...lienWaiver,
              lienWaiverTemplateId:
                lienWaiver.sendLienWaiverOnPayment &&
                lienWaiver?.lienWaiverTemplate
                  ? `${parseRefinementIdFromUrl(
                      lienWaiver?.lienWaiverTemplate
                    )}`
                  : null,
              status:
                !lienWaiver.sendLienWaiverOnPayment &&
                lienWaiver.status !== LIEN_WAIVER_STATUS.NOT_REQUIRED
                  ? LIEN_WAIVER_STATUS.NOT_SELECTED
                  : lienWaiver.status,
            })),
          };
        });

        await checkTwoFactorAuth(async () => {
          const createdPayments = await createPayment(payload).unwrap();
          toast.success(
            <PaymentSuccessMessage
              paymentsLength={paymentsInfo.payments.length}
              totalAmountToPay={totalAmountToPay}
              onViewPayment={() => {
                navigate("/bill-payments");
              }}
            />
          );

          analytics.track("billPaymentsCreate", {
            isMultiplePayments: payload.length > 1,
            isCombinedPayments:
              payload.length > 1 &&
              payload.some((payment) => payment?.bills?.length > 1),
            isPayWhenLienWaiverSigned: payload.some(
              (payment) =>
                payment?.options?.appliedProcessOn ===
                "PAY_WHEN_LIEN_WAIVER_SIGNED"
            ),
            numberOfPayments: payload.length,
            hasVendorCredits: payload.some(
              (payment) => payment?.options?.appliedVendorCredits?.length > 0
            ),
          });

          if (!isMultiplePayment && createdPayments.length === 1) {
            onSinglePaymentCreated?.(createdPayments[0]);
          }
          enhancedOnClose(true);
        });
      } catch (error) {
        handleErrors(error);
      }
    },
  });

  const onChangeCarousel = useEvent(
    (value: string | React.FormEvent<HTMLDivElement>) => {
      const nextIndex = +value;
      setCurrentPaymentIndex(nextIndex);
    }
  );

  const onChangeEmail = useEvent((value: string) => {
    const vendor = paymentsInfo.payments[currentPaymentIndex].vendor;

    form.setValue(
      `payments`,
      form.values.payments.map((payment, index) => {
        const currentPaymentData = paymentsInfo.payments[index];
        if (currentPaymentData.vendor.id === vendor.id) {
          return {
            ...payment,
            vendorEmail: value,
          };
        }
        return payment;
      }),
      {
        shouldValidate: true,
      }
    );
  });

  const onChangeMessage = useEvent((value: string) => {
    form.setValue(`payments.${currentPaymentIndex}.message`, value, {
      shouldValidate: true,
    });
  });

  return dialog.isRendered ? (
    <>
      {isLoading ? <Loader position="fixed" /> : null}
      <Dialog
        show={dialog.isVisible}
        variant="dialog"
        onClose={() => enhancedOnClose()}
        size="auto"
      >
        <DialogHeader>
          <Flex gap="md">
            <Text weight="bold">
              {CONFIRMATION_PAYMENT_STRINGS.CONFIRMATION_PAYMENT_TITLE}{" "}
            </Text>
            <Text color="brand-2">
              {formatCurrency(totalAmountToPay, {
                currencySign: true,
                allowNegative: true,
              })}
            </Text>
          </Flex>
        </DialogHeader>
        <DialogContent>
          <Flex gap="md" direction="column">
            <Flex
              gap="2xl"
              direction="row"
              minWidth="800px"
              as="form"
              {...form.props}
            >
              <Flex direction="column" width="full" gap="md">
                <Text as="h2" size="lg" weight="bold">
                  {
                    paymentsInfo.payments[currentPaymentIndex].vendor
                      .displayName
                  }{" "}
                  {paymentsInfo.payments.length > 1
                    ? `(${currentPaymentIndex + 1} of ${
                        paymentsInfo.payments.length
                      })`
                    : ""}
                </Text>
                <Card as={Flex} width="full">
                  <Flex gap="xl" width="full" direction="column" separator>
                    <Flex gap="xl" width="full" direction="column">
                      <Flex
                        width="full"
                        align="center"
                        gap="xl"
                        justify="space-between"
                      >
                        <Text size="sm" color="neutral-500">
                          {CONFIRMATION_PAYMENT_STRINGS.BILLS_TITLE}
                        </Text>
                        <Text weight="bold">
                          {formatCurrency(currentPayment.balance, {
                            currencySign: true,
                            allowNegative: true,
                          })}
                        </Text>
                      </Flex>
                      {vendorCreditsEnabled &&
                        !!currentPayment.appliedVendorCredits?.length && (
                          <Flex direction="column">
                            <Flex
                              width="full"
                              align="center"
                              gap="xl"
                              justify="space-between"
                            >
                              <Text size="sm" color="neutral-500">
                                {CONFIRMATION_PAYMENT_STRINGS.CREDITS_TITLE}
                              </Text>
                              <Text
                                weight="bold"
                                color={
                                  isNegative(
                                    currentPayment.appliedVendorCreditsTotalAmount ||
                                      0
                                  )
                                    ? "success-200"
                                    : "neutral-800"
                                }
                              >
                                {formatCurrency(
                                  currentPayment.appliedVendorCreditsTotalAmount ||
                                    0,
                                  { currencySign: true, allowNegative: true }
                                )}
                              </Text>
                            </Flex>
                            {!!currentPayment.vendorCreditsRemainingAmount && (
                              <Text size="sm" color="neutral-500">
                                {`${formatCurrency(
                                  currentPayment.vendorCreditsRemainingAmount ||
                                    0,
                                  { currencySign: true }
                                )} available`}
                              </Text>
                            )}
                          </Flex>
                        )}
                    </Flex>
                    <Flex
                      gap="3xl"
                      width="full"
                      justify="space-between"
                      direction="column"
                    >
                      <Flex
                        width="full"
                        align="center"
                        gap="xl"
                        justify="space-between"
                      >
                        <Text size="sm" color="neutral-500">
                          {isMultiplePayment
                            ? `${
                                CONFIRMATION_PAYMENT_STRINGS.PAYMENT_TITLE_WITH_INDEX
                              }${currentPaymentIndex + 1}`
                            : CONFIRMATION_PAYMENT_STRINGS.PAYMENT_TITLE}
                        </Text>
                        <Text weight="bold">
                          {formatCurrency(currentPayment.amountToPay, {
                            currencySign: true,
                            allowNegative: true,
                          })}
                        </Text>
                      </Flex>
                      <Flex width="full" gap="lg" direction="column">
                        <Text size="sm" color="neutral-500">
                          {CONFIRMATION_PAYMENT_STRINGS.FROM_ACCOUNT_TITLE}
                        </Text>
                        <Flex
                          width="full"
                          align="center"
                          gap="xl"
                          justify="space-between"
                        >
                          <Text size="md" weight="bold">
                            {currentPayment.bankAccountOption?.label}
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Card>
              </Flex>

              <Flex gap="xl" width="full" direction="column" separator>
                <Flex gap="xl" width="full" direction="column">
                  <Flex direction="column" gap="xs">
                    <VendorEmailComboBox
                      label={
                        CONFIRMATION_PAYMENT_STRINGS.VENDOR_EMAIL_ADDRESS_TITLE
                      }
                      disabled={form.isSubmitting}
                      vendorId={currentPayment.vendor.id}
                      messageVariant="hidden"
                      value={
                        form.values.payments[currentPaymentIndex].vendorEmail ||
                        ""
                      }
                      onChange={onChangeEmail}
                    />
                    <Flex direction="column" gap="md">
                      {currentPayment.payWhenLienWaiverIsSigned && (
                        <Text size="xs" color="neutral-500">
                          {CONFIRMATION_PAYMENT_STRINGS.SEND_LIEN_WAIVER_TITLE}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
                <Textarea
                  ref={commentRef}
                  minHeight={94}
                  maxHeight={200}
                  disabled={form.isSubmitting}
                  messageVariant="absolute"
                  label={CONFIRMATION_PAYMENT_STRINGS.ADD_COMMENT}
                  placeholder={
                    CONFIRMATION_PAYMENT_STRINGS.ADD_COMMENT_PLACEHOLDER
                  }
                  value={form.values.payments[currentPaymentIndex].message}
                  onChange={onChangeMessage}
                />
              </Flex>
            </Flex>

            {isMultiplePayment && (
              <Carousel
                value={currentPaymentIndex.toString()}
                onChange={onChangeCarousel}
              >
                {paymentsInfo.payments.map((payment, index) => (
                  <CarouselItem
                    label=""
                    key={index}
                    value={index.toString()}
                    errorMessage={
                      form.errors.payments?.[index]?.vendorEmail
                        ? CONFIRMATION_PAYMENT_STRINGS.VENDOR_EMAIL_MISSING
                        : undefined
                    }
                  />
                ))}
              </Carousel>
            )}
          </Flex>
        </DialogContent>
        <DialogFooter>
          <Flex grow width="full">
            <Button
              variant="text"
              color="neutral"
              block
              size="lg"
              onClick={() => enhancedOnClose()}
              disabled={form.isSubmitting}
            >
              {CONFIRMATION_PAYMENT_STRINGS.CANCEL_TITLE}
            </Button>
          </Flex>
          <Tooltip
            message={
              !form.isValid
                ? CONFIRMATION_PAYMENT_STRINGS.VENDOR_EMAIL_MISSING
                : ""
            }
            as={Flex}
            width="full"
            grow
          >
            <Button
              variant="solid"
              size="lg"
              block
              disabled={form.isSubmitting || !form.isValid}
              type="submit"
              form={form.id}
            >
              {form.isSubmitting ? (
                <Loader />
              ) : (
                `${CONFIRMATION_PAYMENT_STRINGS.PAY_TITLE} ${formatCurrency(
                  totalAmountToPay,
                  {
                    currencySign: true,
                    allowNegative: true,
                  }
                )}`
              )}
            </Button>
          </Tooltip>
        </DialogFooter>
      </Dialog>
    </>
  ) : null;
};
