import type { BillPaymentDetailV2 } from "@bill-payment/types";
import { type Recipe } from "@reduxjs/toolkit/dist/query/core/buildThunks";

import { BILL_PAYMENT_DETAIL_TAG, billPaymentApi } from "../api";

export const updateBillPayment =
  (billPaymentId: string) => (draftFunction: Recipe<BillPaymentDetailV2>) => {
    return billPaymentApi.util.updateQueryData(
      "getBillPaymentV2",
      { billPaymentId: billPaymentId },
      draftFunction
    );
  };

export const invalidateBillPaymentDetail = () => {
  return billPaymentApi.util.invalidateTags([BILL_PAYMENT_DETAIL_TAG]);
};
