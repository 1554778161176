import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  Loader,
  Text, // eslint-disable-line no-redeclare
  toast,
  Tooltip,
} from "@adaptive/design-system";
import {
  useDialog,
  useEvent,
  useVisibilityChange,
} from "@adaptive/design-system/hooks";
import { handleErrors } from "@api/handle-errors";
import {
  exportPurchaseOrder,
  useConvertToAdaptiveMutation,
} from "@api/purchase-orders";
import { AdaptiveIcon } from "@components/adaptive-icon";
import { BuilderTrendIcon } from "@components/buildertrend-icon";
import { ErrorAlert } from "@components/common/error-alert";
import { DownloadButton } from "@components/download-button";
import { QuickBooksIcon } from "@components/quickbooks-icon";
import {
  MarkAsSignedDialog,
  RequestedVendorPoSignatureAlert,
  RequestVendorPoSignature,
} from "@components/request-vendor-po-signature";
import { useCurrentClientFromRealm } from "@hooks/use-current-client-from-realm";
import {
  setNeedsRefresh,
  updatePurchaseOrderList,
} from "@store/purchaseOrderListSlice";
import {
  loadPurchaseOrder,
  putPurchaseOrder,
  removePurchaseOrder,
  resetPurchaseOrder,
  updateLinesBudgetRemaining,
} from "@store/purchaseOrderSlice";
import { usePurchaseOrderDrawer } from "@store/ui";
import { useClientInfo, useClientSettings } from "@store/user";
import { BasePermissions, useUserInfo } from "@store/user";
import { usePropagationDialog } from "@transaction-propagation/hooks";
import { showUnlinkedObjects } from "@transaction-propagation/utils";

import { PURCHASE_ORDER_TYPE } from "./constants";
import { PurchaseOrderFormContent } from "./purchase-order-form-content";
import { PurchaseOrderFormHeader } from "./purchase-order-form-header";
import { PurchaseOrderLearnMoreLink } from "./purchase-order-learn-more-link";
import {
  purchaseOrderPropagationPayloadSelector,
  selectPurchaseOrder,
  selectPurchaseOrderIsDirty,
  selectPurchaseOrderIsLoading,
  selectPurchaseOrderType,
} from "./purchase-orders-selectors";
import { promptConversionToast, promptConversionWarning } from "./utils";

const PurchaseOrderTypeAlert = memo(() => {
  const type = useSelector(selectPurchaseOrderType);

  const settings = useClientSettings();

  if (!settings.adaptivePosEnabled) return null;

  if (type === PURCHASE_ORDER_TYPE.ADAPTIVE) {
    return (
      <Alert
        variant={{
          icon: <AdaptiveIcon variant="dark-circle" height={24} width={24} />,
          background: "brand-1",
        }}
      >
        <AlertTitle>This is an Adaptive purchase order</AlertTitle>
        <AlertContent as={Flex} gap="md" direction="column">
          <Text>It does not sync with QuickBooks.</Text>
          <Flex as="span">
            <PurchaseOrderLearnMoreLink />
          </Flex>
        </AlertContent>
      </Alert>
    );
  }

  if (type === PURCHASE_ORDER_TYPE.BT) {
    return (
      <Alert
        variant={{
          icon: <BuilderTrendIcon size={24} />,
          background: "neutral-300",
        }}
      >
        <AlertTitle data-testid="buildertrend-purchase-order-alert-title">
          This is a BuilderTrend purchase order
        </AlertTitle>
        <AlertContent>
          You may not edit or delete this purchase order or any of its linked
          bills in Adaptive.
        </AlertContent>
      </Alert>
    );
  }

  if (type === PURCHASE_ORDER_TYPE.QB) {
    return (
      <Alert
        variant={{
          icon: <QuickBooksIcon size={24} />,
          background: "success-100",
        }}
      >
        <AlertTitle>This is a QuickBooks purchase order</AlertTitle>
        <AlertContent as={Flex} gap="md" direction="column">
          <Text>
            You may not edit or delete this purchase order or any of its linked
            bills in Adaptive.
            <br />
            Convert it to an Adaptive purchase order for full edit capabilities.
          </Text>
          <Flex as="span" margin={["none", "none", "sm"]}>
            <PurchaseOrderLearnMoreLink />
          </Flex>
          <Flex width="184px">
            <Button size="sm" block type="submit" form="purchase-order">
              Convert to Adaptive PO
            </Button>
          </Flex>
        </AlertContent>
      </Alert>
    );
  }

  return null;
});

PurchaseOrderTypeAlert.displayName = "PurchaseOrderTypeAlert";

export const PurchaseOrderForm = memo(() => {
  const {
    state: drawerState,
    hide,
    setState: drawerSetState,
    isVisible,
  } = usePurchaseOrderDrawer();

  const settings = useClientSettings();

  const isCreate = drawerState.id === "new";

  const purchaseOrderId = isCreate ? null : drawerState.id;

  const markAsSignedDialog = useDialog({ lazy: true });

  const dispatch = useDispatch();

  const { realm } = useClientInfo();

  const [status, setStatus] = useState("fulfilled");

  const { hasPermission, hasSomePermission } = useUserInfo();

  const purchaseOrder = useSelector(selectPurchaseOrder);

  const isLoading = useSelector(selectPurchaseOrderIsLoading);

  useCurrentClientFromRealm(purchaseOrder?.realm);

  const isDirty = useSelector(selectPurchaseOrderIsDirty);

  const purchaseOrderPropagationPayload = useSelector(
    purchaseOrderPropagationPayloadSelector
  );

  const isDownloadDisabled = !purchaseOrder.id || isDirty;

  const enhancedStatus =
    status !== "rejected" && isLoading ? "pending" : status;

  const permissions = useMemo(() => {
    const canEdit = purchaseOrder.id
      ? (hasPermission(BasePermissions.ADD_PO) && purchaseOrder.is_creator) ||
        hasPermission(BasePermissions.MANAGE_POS)
      : hasSomePermission([BasePermissions.ADD_PO, BasePermissions.MANAGE_POS]);

    const canComment = hasPermission(BasePermissions.COMMENT_PO);

    const isQbPo =
      purchaseOrder.type === PURCHASE_ORDER_TYPE.QB &&
      settings.adaptivePosEnabled;

    const isBuilderTrendPo =
      purchaseOrder.type === PURCHASE_ORDER_TYPE.BT &&
      settings.adaptivePosEnabled;

    return { isQbPo, canEdit, canComment, isBuilderTrendPo };
  }, [
    hasPermission,
    purchaseOrder.id,
    hasSomePermission,
    purchaseOrder.type,
    purchaseOrder.is_creator,
    settings.adaptivePosEnabled,
  ]);

  const navigate = useNavigate();

  const [convertToAdaptive] = useConvertToAdaptiveMutation();

  const showVendorSignatureRequest =
    window.VENDOR_PO_SIGNATURE_REQUEST_ENABLED &&
    purchaseOrder.vendor &&
    !purchaseOrder.is_signed;

  const onDownload = useEvent(
    async ({ params }) => await exportPurchaseOrder(purchaseOrder, params)
  );

  const saveCallback = useEvent(async (propagation) => {
    if (permissions.isBuilderTrendPo) {
      return toast.error(
        "You can't convert a BuilderTrend purchase order to an Adaptive purchase order"
      );
    }

    if (permissions.isQbPo) {
      const handle = async () => {
        try {
          setStatus("pending");
          if (drawerState.beforeConversion) {
            await drawerState.beforeConversion();
          }
          const { id } = await convertToAdaptive(purchaseOrderId).unwrap();
          promptConversionToast();
          if (drawerState.caller === "purchase-orders-page") {
            navigate(`/purchase-orders/${id}`, { replace: true });
          } else {
            dispatch(loadPurchaseOrder(id));
          }
          dispatch(setNeedsRefresh({ status: "open", refresh: true }));
          dispatch(setNeedsRefresh({ status: "closed", refresh: true }));
        } catch (e) {
          handleErrors(e);
        } finally {
          setStatus("fulfilled");
        }
      };

      promptConversionWarning({ onConfirm: handle });
    } else {
      const { propagate, toastMessage } = propagation || {};

      drawerSetState((previousState) => ({ ...previousState, propagate }));

      try {
        setStatus("pending");

        const { id } = await dispatch(putPurchaseOrder(propagate));

        if (!purchaseOrderId && drawerState.caller === "purchase-orders-page") {
          navigate(`/purchase-orders/${id}`);
        }

        if (toastMessage) toast.success(toastMessage);
      } finally {
        setStatus("fulfilled");
      }
    }
  });

  const { propagationDialog, saveWithPropagation } =
    usePropagationDialog(saveCallback);

  const onSave = useEvent(async () => {
    saveWithPropagation(purchaseOrderPropagationPayload);
  });

  const onDelete = useEvent(() => {
    const id = dialog.confirmation({
      title: (
        <>
          Are you sure you want to <br />
          delete this purchase order?
        </>
      ),
      message: "You can't undo this action.",
      action: {
        primary: {
          color: "error",
          onClick: async () => {
            toast.success("Purchase order deleted");

            drawerSetState((previousState) => ({
              ...previousState,
              isDeleted: true,
            }));

            /**
             * Workaround to guarantee that the dialog have the state before
             * the dialog.hide is called, otherwise the dialog will be hidden
             * before the state is set.
             */
            await new Promise((resolve) => requestAnimationFrame(resolve));

            const [removeData] = await Promise.all([
              dispatch(removePurchaseOrder()),
              dialog.hide(id),
              hide(true),
            ]);

            showUnlinkedObjects(removeData?.unlinked_objects);

            if (drawerState.caller === "purchase-orders-page") {
              navigate("/purchase-orders");
            }
          },
          autoHide: false,
          children: "Delete",
        },
      },
    });
  });

  const refetchPurchaseOrder = useCallback(
    () =>
      purchaseOrderId
        ? dispatch(loadPurchaseOrder(purchaseOrderId))
        : Promise.resolve(),
    [dispatch, purchaseOrderId]
  );

  const onSubmitRequestPo = useEvent(async () => {
    refetchPurchaseOrder();
    dispatch(updatePurchaseOrderList());
  });

  const onMarkAsSigned = useEvent(async () => {
    markAsSignedDialog.show();
  });

  useEffect(() => {
    if (purchaseOrderId) {
      setStatus("pending");
      refetchPurchaseOrder()
        .then(() => setStatus("fulfilled"))
        .catch(() => setStatus("rejected"));
    }
  }, [refetchPurchaseOrder, purchaseOrderId]);

  useVisibilityChange((isFocused) => {
    if (isFocused && !isCreate) dispatch(updateLinesBudgetRemaining());
  });

  useEffect(() => {
    if (!isVisible || isCreate) dispatch(resetPurchaseOrder(realm));
  }, [isVisible, realm, isCreate, dispatch]);

  return (
    <>
      <DialogHeader>
        <PurchaseOrderFormHeader
          status={enhancedStatus}
          permissions={permissions}
        />
      </DialogHeader>
      <DialogContent style={{ scrollbarGutter: "stable" }}>
        <Flex width="full" minWidth="745px">
          {enhancedStatus === "pending" ? <Loader /> : null}

          {enhancedStatus === "fulfilled" && (
            <Flex width="full" direction="column" gap="2xl">
              {(purchaseOrder.errors.length > 0 ||
                purchaseOrder.related_errors.length > 0) && (
                <ErrorAlert
                  data={purchaseOrder.errors}
                  relatedData={purchaseOrder.related_errors}
                  onChange={refetchPurchaseOrder}
                  objectType="PurchaseOrder"
                />
              )}
              {showVendorSignatureRequest &&
                purchaseOrder.latest_po_signature_request && (
                  <RequestedVendorPoSignatureAlert
                    purchaseOrder={purchaseOrder}
                    onSubmitRequestPo={onSubmitRequestPo}
                    onMarkAsSigned={onMarkAsSigned}
                    disabled={
                      isDirty ||
                      (!permissions.canEdit
                        ? "You don't have permission to do this"
                        : false)
                    }
                  />
                )}
              <PurchaseOrderTypeAlert />
              <PurchaseOrderFormContent
                onSave={onSave}
                permissions={permissions}
                linkedLines={drawerState.linkedLines ?? []}
              />
            </Flex>
          )}

          {enhancedStatus === "rejected" &&
            "This purchase order was deleted or the address of the purchase order is incorrect."}
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Flex gap="xl" width="full" justify="space-between">
          <Button
            size="lg"
            color="neutral"
            variant="text"
            onClick={hide}
            data-testid="purchase-order-cancel"
          >
            Cancel
          </Button>

          {enhancedStatus === "fulfilled" && (
            <Flex gap="md">
              <Dropdown>
                <DropdownTrigger
                  as={Button}
                  size="lg"
                  color="neutral"
                  variant="ghost"
                  aria-label="Actions"
                  data-testid="purchase-order-actions"
                >
                  <Icon name="ellipsis-vertical" />
                </DropdownTrigger>
                <DropdownList>
                  <Tooltip
                    placement="left"
                    message={
                      !permissions.canEdit
                        ? "You don't have permission to do this"
                        : permissions.isQbPo
                          ? "You can't delete a QuickBooks purchase order"
                          : permissions.isBuilderTrendPo
                            ? "You can't delete a BuilderTrend purchase order"
                            : !purchaseOrder.id
                              ? "You must save the purchase order before you can delete it"
                              : ""
                    }
                  >
                    <DropdownItem
                      color="error"
                      onClick={onDelete}
                      disabled={
                        !permissions.canEdit ||
                        permissions.isQbPo ||
                        permissions.isBuilderTrendPo ||
                        !purchaseOrder.id
                      }
                      data-testid="delete-purchase-order"
                    >
                      Delete
                    </DropdownItem>
                  </Tooltip>
                  <Tooltip
                    message={
                      isDownloadDisabled &&
                      "You must save the purchase order before you can download it"
                    }
                    placement="left"
                  >
                    <DropdownItem
                      htmlFor="download-purchase-order-button"
                      disabled={isDownloadDisabled}
                      data-testid="download-purchase-order"
                    >
                      Download
                    </DropdownItem>
                  </Tooltip>
                </DropdownList>
              </Dropdown>
              {/**
               * @todo We should revisit DownloadButton to make it more extensible,
               * so we can reuse it in other places with `as` or `render` props.
               * Right here is an workaround because we need to trigger it via dropdown item
               */}
              <div style={{ display: "none", pointerEvents: "none" }}>
                <DownloadButton
                  id="download-purchase-order-button"
                  mode={{
                    all: { enabled: true, children: "Download" },
                    selection: { enabled: false },
                  }}
                  type={[
                    { label: "Purchase order (PDF)", value: "export_pdf" },
                  ]}
                  autoRun
                  disabled={isDownloadDisabled}
                  onDownload={onDownload}
                  data-testid="download-purchase-order"
                  initialType={{ export_pdf: true }}
                />
              </div>
              {permissions.canEdit && (
                <>
                  {showVendorSignatureRequest && (
                    <Tooltip
                      message={
                        (!purchaseOrder.id || isDirty) &&
                        "You must save the purchase order before you can send the request"
                      }
                      placement="top"
                    >
                      <RequestVendorPoSignature
                        purchaseOrder={purchaseOrder}
                        onSubmitRequestPo={onSubmitRequestPo}
                        onMarkAsSigned={onMarkAsSigned}
                        disabled={!purchaseOrder.id || isDirty}
                      />
                    </Tooltip>
                  )}
                  {permissions.isQbPo ? (
                    <Button
                      size="lg"
                      type="submit"
                      form="purchase-order"
                      data-testid="save-purchase-order"
                    >
                      Convert to Adaptive PO
                    </Button>
                  ) : permissions.isBuilderTrendPo ? undefined : (
                    <Button
                      size="lg"
                      type="submit"
                      form="purchase-order"
                      data-testid="save-purchase-order"
                    >
                      Save
                    </Button>
                  )}
                </>
              )}
            </Flex>
          )}
        </Flex>
      </DialogFooter>
      {markAsSignedDialog.isRendered && (
        <MarkAsSignedDialog
          dialog={markAsSignedDialog}
          purchaseOrder={purchaseOrder}
          onSubmit={refetchPurchaseOrder}
          disabled={permissions.canEdit}
        />
      )}
      {propagationDialog}
    </>
  );
});

PurchaseOrderForm.displayName = "PurchaseOrderForm";
