import { useMemo, useState } from "react";
import { usePagination } from "@adaptive/design-system/hooks";
import {
  useGetCapitalOSCardsQuery,
  useUpdateCapitalOSCardMutation,
} from "@api/capital-os";
import { useClientInfo } from "@store/user";

export const useCapitalOSCards = () => {
  const { realmId } = useClientInfo();

  const pagination = usePagination({
    localStorageKey: "capital-os-cards-limit",
  });

  const [ordering, setOrdering] = useState("created_at");

  const sort = useMemo(
    () => ({ value: ordering, onChange: setOrdering }),
    [ordering, setOrdering]
  );

  const {
    data,
    isLoading,
    refetch: refetchCards,
  } = useGetCapitalOSCardsQuery({
    realm: realmId,
    limit: pagination.perPage,
    offset: pagination.offset,
    ordering,
  });

  const [updateCard] = useUpdateCapitalOSCardMutation();

  return {
    data,
    sort,
    isLoading,
    updateCard,
    pagination,
    refetchCards,
  };
};
