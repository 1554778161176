import React, { createContext, useMemo } from "react";
import {
  Button,
  ComboBox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  LabelValue,
  Table,
  type TableColumn,
  type TableEmptyState,
  Text,
  Tooltip,
} from "@adaptive/design-system";
import {
  type useDialog,
  useEvent,
  useForm,
} from "@adaptive/design-system/hooks";
import { dotObject, formatCurrency } from "@adaptive/design-system/utils";
import { QuickBooksSyncedIcon } from "@components/quickbooks-icon";
import { PURCHASE_ORDER_TYPE } from "@src/purchase-orders/constants";
import { useClientSettings } from "@store/user";
import { curriedNoop } from "@utils/noop";

import type { Line, PurchaseOrder } from ".";

export type PurchaseOrdersMergeDialogOnSubmitHandler = (values: {
  linkedLines: Line[];
  removedLines: Line[];
  unlinkedLines: Line[];
  unmergedLines: PurchaseOrder["lines"];
}) => void;

export type PurchaseOrdersMergeDialogProps = ReturnType<typeof useDialog> & {
  lines: Line[];
  onSubmit: PurchaseOrdersMergeDialogOnSubmitHandler;
  purchaseOrder: PurchaseOrder;
};

type CurriedOnDeleteLineHandler = (index: number) => () => void;

type CurriedOnChangeLineHandler = (index: number) => (value: string) => void;

const PurchaseOrdersMergeDialogContext = createContext<{
  purchaseOrderLines: (PurchaseOrder["lines"][number] & {
    label: string;
    value: string;
  })[];
  type: PurchaseOrder["type"];
  adaptivePosEnabled: boolean;
  curriedOnDeleteLine: CurriedOnDeleteLineHandler;
  curriedOnChangeLine: CurriedOnChangeLineHandler;
}>({
  type: PURCHASE_ORDER_TYPE.QB,
  purchaseOrderLines: [],
  adaptivePosEnabled: false,
  curriedOnChangeLine: curriedNoop,
  curriedOnDeleteLine: curriedNoop,
});

const SEARCH_KEYS = ["label", "amount", "description"];

const COLUMNS: TableColumn<Line>[] = [
  {
    id: "billLine",
    name: "Bill line",
    width: 300,
    render: (row, index) => (
      <PurchaseOrdersMergeDialogContext.Consumer>
        {({ curriedOnDeleteLine }) => (
          <Flex gap="md" align="center" justify="space-between">
            <Flex direction="column">
              <Flex gap="sm" align="center">
                <Text truncate>
                  {row.item?.displayName || row.account?.displayName}
                </Text>
                {row.parent?.isInQuickbooks && (
                  <QuickBooksSyncedIcon size={16} />
                )}
              </Flex>
              <Text as="strong" weight="bold" size="sm">
                {formatCurrency(row.amount ?? 0, {
                  allowNegative: true,
                  currencySign: true,
                })}
              </Text>
            </Flex>
            <Tooltip
              as={Button}
              variant="ghost"
              color="neutral"
              onClick={curriedOnDeleteLine(index)}
              message="Delete bill line"
            >
              <Icon name="trash" />
            </Tooltip>
          </Flex>
        )}
      </PurchaseOrdersMergeDialogContext.Consumer>
    ),
  },
  {
    id: "poLine",
    name: "PO line",
    width: 425,
    render: (row, index) => (
      <PurchaseOrdersMergeDialogContext.Consumer>
        {({
          type,
          adaptivePosEnabled,
          purchaseOrderLines,
          curriedOnChangeLine,
        }) => {
          const isInQuickBooks = !adaptivePosEnabled
            ? row.parent?.isInQuickbooks
            : row.parent?.isInQuickbooks && type === PURCHASE_ORDER_TYPE.QB;

          const isInBuilderTrend = type === PURCHASE_ORDER_TYPE.BT;

          return (
            <ComboBox
              data={purchaseOrderLines}
              value={String(
                row.linkedTransaction?.id &&
                  !isInQuickBooks &&
                  !isInBuilderTrend
                  ? row.linkedTransaction.id
                  : ""
              )}
              onChange={curriedOnChangeLine(index)}
              disabled={isInQuickBooks || isInBuilderTrend}
              searchKeys={SEARCH_KEYS}
              messageVariant={
                isInQuickBooks || isInBuilderTrend ? "relative" : "hidden"
              }
              helperMessage={
                isInQuickBooks
                  ? "Lines synced to QuickBooks can't be linked to a PO"
                  : isInBuilderTrend
                    ? "Lines synced to BuilderTrend can't be linked to a PO"
                    : null
              }
              renderOption={(option, { highlightedLabel }) => {
                const { amount, balance, description } =
                  option as unknown as PurchaseOrder["lines"][number];

                return (
                  <Flex direction="column">
                    <Text>{highlightedLabel}</Text>
                    <Flex gap="md">
                      <LabelValue
                        compact
                        label={{
                          color: "inherit",
                          children: "Amount:",
                        }}
                        value={{
                          color: "inherit",
                          children: formatCurrency(amount ?? 0, {
                            allowNegative: true,
                            currencySign: true,
                          }),
                        }}
                      />
                      <LabelValue
                        compact
                        label={{
                          color: "inherit",
                          children: "Balance:",
                        }}
                        value={{
                          color: "inherit",
                          children: formatCurrency(balance ?? 0, {
                            allowNegative: true,
                            currencySign: true,
                          }),
                        }}
                      />
                    </Flex>
                    {description && (
                      <Text size="xs" truncate>
                        Description: {description}
                      </Text>
                    )}
                  </Flex>
                );
              }}
            />
          );
        }}
      </PurchaseOrdersMergeDialogContext.Consumer>
    ),
  },
];

const EMPTY_STATE: TableEmptyState = {
  title:
    "There are no bill lines left to merge. The PO lines will be automatically linked to this bill when you click Save",
};

export const PurchaseOrdersMergeDialog = ({
  hide,
  lines,
  onSubmit,
  isVisible,
  purchaseOrder,
}: PurchaseOrdersMergeDialogProps) => {
  const settings = useClientSettings();

  const openPurchaseOrderLines = useMemo(
    () => purchaseOrder.lines.filter((line) => line.status === "Open"),
    [purchaseOrder.lines]
  );

  const initialValues = useMemo(() => {
    let enhancedLines = [...lines];

    const hasOnlyOneLineAndOnePurchaseOrderLine =
      lines.length === 1 && openPurchaseOrderLines.length === 1;

    if (hasOnlyOneLineAndOnePurchaseOrderLine) {
      const lineHasNoLinkedTransaction = !lines[0].linkedTransaction?.id;

      if (lineHasNoLinkedTransaction) {
        enhancedLines = dotObject.set(enhancedLines, "0.linkedTransaction", {
          id: openPurchaseOrderLines[0].id,
        });
      }
    }

    return { lines: enhancedLines };
  }, [lines, openPurchaseOrderLines]);

  const form = useForm({
    initialValues,
    onSubmit: (values) => {
      const linkedLines = values.lines.filter(
        (line) => line.linkedTransaction?.id
      );

      const removedLines = initialValues.lines.filter(
        (line) => !values.lines.some((l) => l.id === line.id)
      );

      const unlinkedLines = values.lines.filter(
        (line) =>
          !line.linkedTransaction?.id &&
          initialValues.lines.some(
            (initialLine) =>
              initialLine.id === line.id && initialLine.linkedTransaction?.id
          )
      );

      const unmergedLines = openPurchaseOrderLines.filter(
        (line) =>
          !linkedLines.some(
            (linkedLine) => String(linkedLine.linkedTransaction?.id) == line.id
          )
      );

      onSubmit({ linkedLines, removedLines, unlinkedLines, unmergedLines });
    },
  });

  const skip = useEvent(() => {
    onSubmit({
      linkedLines: [],
      removedLines: [],
      unlinkedLines: [],
      unmergedLines: openPurchaseOrderLines,
    });
  });

  const hasBillLines = form.values.lines.length === 0;

  const header = useMemo(() => ({ hide: hasBillLines }), [hasBillLines]);

  const purchaseOrderLines = useMemo(
    () =>
      openPurchaseOrderLines.map((line) => ({
        label: line.item?.displayName || line.account?.displayName || "Unknown",
        value: line.id,
        ...line,
      })),
    [openPurchaseOrderLines]
  );

  const curriedOnDeleteLine = useEvent<CurriedOnDeleteLineHandler>(
    (index) => () => form.remove("lines", index)
  );

  const curriedOnChangeLine = useEvent<CurriedOnChangeLineHandler>(
    (index) => (value) => {
      form.values.lines.forEach((line, index) => {
        if (String(line.linkedTransaction?.id) == value) {
          form.setValue(`lines.${index}.linkedTransaction`, undefined);
        }
      });
      form.setValue(`lines.${index}.linkedTransaction`, { id: value });
    }
  );

  return (
    <Dialog variant="dialog" size="lg" show={isVisible} onClose={hide}>
      <DialogHeader>
        <Flex gap="sm" direction="column">
          <Text weight="bold">Merge or delete the existing bill lines</Text>
          <Text size="md">
            Select the corresponding PO line from the dropdown to merge with.
            Any unselected PO lines will still be added to the bill.
          </Text>
        </Flex>
      </DialogHeader>
      <DialogContent>
        <Flex as="form" {...form.props}>
          <PurchaseOrdersMergeDialogContext.Provider
            value={{
              type: purchaseOrder.type,
              adaptivePosEnabled: settings.adaptivePosEnabled,
              purchaseOrderLines,
              curriedOnChangeLine,
              curriedOnDeleteLine,
            }}
          >
            <Table
              data={form.values.lines}
              size="sm"
              header={header}
              columns={COLUMNS}
              emptyState={EMPTY_STATE}
            />
          </PurchaseOrdersMergeDialogContext.Provider>
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Button variant="text" color="neutral" onClick={skip} block>
          Skip
        </Button>
        <Button
          type="submit"
          form={form.id}
          disabled={
            lines.length == 0 ||
            lines.some((line) => {
              if (purchaseOrder.type === PURCHASE_ORDER_TYPE.BT) {
                return false;
              }

              return !settings.adaptivePosEnabled
                ? line.parent?.isInQuickbooks
                : line.parent?.isInQuickbooks &&
                    purchaseOrder.type === PURCHASE_ORDER_TYPE.QB;
            })
          }
          block
        >
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
