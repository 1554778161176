import { configureStore } from "@reduxjs/toolkit";
import { createReduxEnhancer } from "@sentry/react";
import { reducer as vendors } from "@vendors/store";

import { reducer as attachable } from "./attachments/slice";
import { reducer as expenses } from "./expenses/slice";
import { reducer as jobsReducer } from "./jobs/slice";
import { reducer as ui } from "./ui/slice";
import { api as apiSlice } from "./api-simplified";
import { reducer as billListReducer } from "./billListSlice";
import { reducer as billReducer } from "./billSlice";
import { reducer as purchaseOrderListReducer } from "./purchaseOrderListSlice";
import { reducer as purchaseOrderReducer } from "./purchaseOrderSlice";
import { reducer as realmReducer } from "./realmSlice";
import { reducer as role } from "./role";
import { reducer as user } from "./user";
import { reducer as workflow } from "./workflow";

export { initializeState } from "./init";

import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { enableMapSet } from "immer";

enableMapSet();

export const createStore = ({ preloadedState = {} } = {}) =>
  configureStore({
    reducer: {
      bill: billReducer,
      jobs: jobsReducer,
      realm: realmReducer,
      billList: billListReducer,
      purchaseOrderList: purchaseOrderListReducer,
      purchaseOrder: purchaseOrderReducer,
      attachable,
      expenses,
      role,
      ui,
      user,
      vendors,
      workflow,
      api: apiSlice.reducer,
    },
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        apiSlice.middleware
      ),
    enhancers: (defaultEnhancers) => [
      ...defaultEnhancers,
      createReduxEnhancer(),
    ],
  });

export const store = createStore();

setupListeners(store.dispatch);
