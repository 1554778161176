import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Card,
  Checkbox,
  ComboBox,
  CurrencyField,
  DateField,
  Flex,
  Icon,
  Image,
  Label,
  Link,
  Loader,
  Text,
  TextField,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import {
  useDeepMemo,
  useDialog,
  useEvent,
  useMultiStepDialog,
} from "@adaptive/design-system/hooks";
import {
  formatCurrency,
  formatDate,
  generateSignature,
  is,
  isEqual,
  parseCurrency,
} from "@adaptive/design-system/utils";
import {
  patchAccountBalance,
  patchPlaidAccountOwner,
} from "@api/bank-accounts";
import { handleErrors } from "@api/handle-errors";
import { VendorCreditSelectionDialog } from "@bill-payment/components";
import { PAYMENT_METHOD } from "@bill-payment/constants";
import {
  useAppliedVendorCredits,
  useCreatePaymentV2Mutation,
  usePaymentOptionsInfo,
  useViewBillPayment,
} from "@bill-payment/hooks";
import {
  type BankAccountOption,
  type BillPaymentListingV2,
  type CreateBillPaymentV2Response,
  type CustomerCard,
  type DynamicBalanceVendorCredit,
  type PaymentMethod,
} from "@bill-payment/types";
import { CardTransactionField } from "@card-feed/components";
import type {
  CardTransaction,
  CardTransactionFieldOnChangeHandler,
  LinkedCost,
} from "@card-feed/types";
import { BankAccountComboBox } from "@components/bank-account-combobox";
import { Form } from "@components/form";
import { useObjectInternalVersion } from "@hooks/use-object-internal-version";
import { BillLienWaivers } from "@lien-waiver/components";
import {
  LIEN_WAIVER_LINKED_STATUS,
  LIEN_WAIVER_STATUS,
} from "@lien-waiver/constants";
import type {
  LienWaiverCustomer,
  OnLoadTemplateDataHandler,
} from "@lien-waiver/types";
import {
  useBillFormActions,
  useBillFormPermissions,
} from "@src/bills/bill-form-context";
import {
  BILL_STATUS,
  FIELDS_TO_RELOAD_AFTER_PAYMENT_ACTION,
  PAY_BUTTON_LABEL,
  PAYMENT_STEP_FORM_STRINGS,
  STRINGS,
  TAB_STATUS,
} from "@src/bills/constants";
import {
  billParsedAmountToPay,
  camelCaseBillSelector,
  camelCaseLinesBillSelector,
  isDirtyBillSelector,
  isInPaymentStatusBill,
  paymentsBillV2Selector,
  selectStaticBill,
  vendorBillSelector,
  workflowsBillSelector,
} from "@src/bills/utils";
import {
  AccountSelectDialog,
  type Step as StepSelectAccountDialog,
} from "@src/settings/components/account-select-dialog";
import { fetchAllBills } from "@store/billListSlice";
import {
  loadBill,
  recordBillUpdate,
  refetchCurrentBill,
  setBillStatus,
  unarchiveCurrentBill,
} from "@store/billSlice";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useTwoFactorAuth } from "@store/ui";
import { useClientSettings, useUserInfo } from "@store/user";
import * as analytics from "@utils/analytics";
import { isNegative } from "@utils/is-negative";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { transformKeysToSnakeCase } from "@utils/schema/converters";
import { sum } from "@utils/sum";
import { useVendorAction, useVendorInfo } from "@vendors/hooks";
import type { Stage } from "@vendors/types";
import addDays from "date-fns/addDays";
import endOfDay from "date-fns/endOfDay";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import startOfDay from "date-fns/startOfDay";
import lodashIsEmpty from "lodash.isempty";

import { BillAlerts } from "../alerts";
import { ApprovalsSection } from "../approvals-section";
import { Comments } from "../comments";
import { useCycle } from "../cycle-provider";
import { DynamicActions } from "../dynamic-actions";
import { Info } from "../info";
import { Items } from "../items";
import { PurchaseOrders } from "../purchase-orders";
import { UpdateSignatureDialog } from "../update-signature-dialog";

import { BillPaymentInfoV2 } from "./bill-payment-info-v2";
import { BillPaymentConfirmationDialog } from "./payment-bill-step-confirmation-dialog";
import {
  type BillPaymentConfirmationPayload,
  type BillPaymentFormProps,
  type BillPaymentsProps,
  type FormValidationStatus,
  type PaymentDetail,
  type Signature,
  type SignatureFieldProps,
} from "./types";
import {
  addressIsValid,
  getSignatureString,
  selectVendorPaymentData,
} from "./utils";

const DAYS_THRESHOLD = {
  daysBefore: 1,
  daysAfter: 30,
};

const SIGNATURE_NAME = "signature-name";

const SignatureField = ({
  signature,
  onChange,
  dialog,
}: SignatureFieldProps) => {
  const { user } = useUserInfo();

  return (
    <Card>
      <Flex direction="column">
        <Text size="sm">Signature</Text>
        <Flex padding={["xl", "none"]}>
          <Image
            src={signature.url}
            width={signature.width}
            height={signature.height}
          />
        </Flex>
        <Button variant="ghost" color="primary" onClick={dialog.show}>
          Update signature
        </Button>
      </Flex>
      <UpdateSignatureDialog
        dialog={dialog}
        signature={signature.name}
        defaultSignature={user.full_name}
        onChange={async (value) => {
          onChange(await generateSignature(value || user.full_name));
        }}
      />
    </Card>
  );
};

const BillPaymentInfo = ({ billPayments }: BillPaymentsProps) => {
  const { onViewBillPayment } = useViewBillPayment();

  return (
    <Flex gap="2xl" direction="column" padding={["none", "none", "xl"]}>
      {billPayments.map((payment) => (
        <BillPaymentInfoV2
          key={payment.id}
          billPayment={payment}
          onViewPayment={onViewBillPayment}
        />
      ))}
    </Flex>
  );
};

const BillPaymentForm = ({
  formValidationStatus,
  payment,
  nextAvailablePaymentSchedule,
  selectedAccountBalance,
  debitDate,
  paymentMethods,
  cardTransaction,
  paymentOptions,
  onSetPaymentAccount,
  appliedVendorCredits,
  availableVendorCredits,
  setAppliedVendorCredits,
  appliedVendorCreditsTotalAmount,
  vendorCreditsRemainingAmount,
  lastAvailablePaymentSchedule,
  highlightedDates,
}: BillPaymentFormProps) => {
  const {
    canManageLienWaivers,
    vendorCreditsEnabled,
    processOnScheduledDateEnabled,
  } = useClientSettings();
  const permissions = useBillFormPermissions();
  const [touched, setTouched] = useState(false);

  const amountToPay = useAppSelector(billParsedAmountToPay);

  const currentDate = useMemo(() => {
    return new Date();
  }, []);

  const selectedPaymentMethod = useMemo(
    () =>
      selectedAccountBalance?.paymentMethods.find(
        (item) => item.paymentMethod === payment.value.method
      ),
    [selectedAccountBalance, payment.value.method]
  );

  const accountSelectDialog = useMultiStepDialog({
    initialStep: "set-account" as StepSelectAccountDialog,
  });

  const vendorCreditSelectionDialog = useDialog({
    lazy: true,
  });

  const onChangePaymentAmount = useEvent((value) =>
    dispatch(recordBillUpdate({ amount_to_pay: value }))
  );

  const onChangePaymentMethod = useEvent((value: string) => {
    payment?.onChange((previousPayment) => ({
      ...previousPayment,
      method: value as PaymentMethod,
      currentDebitDate: currentDate,
    }));

    // Reset debit date to the next available payment schedule when changing payment method
    if (processOnScheduledDateEnabled) {
      const newPaymentMethod = selectedAccountBalance?.paymentMethods.find(
        (item) => item.paymentMethod === value
      );

      if (newPaymentMethod?.paymentSchedules?.length) {
        debitDate.onChange(
          newPaymentMethod.paymentSchedules[0].processOn || null
        );
      } else if (value === PAYMENT_METHOD.MARK_AS_PAID) {
        debitDate.onChange(currentDate);
      }
    }
  });

  const {
    balance,
    lienWaivers,
    isArchivedByUser,
    date: billDate,
  } = useAppSelector(camelCaseBillSelector);

  const billVendor = useAppSelector(vendorBillSelector);

  const { vendor, addressOptions: vendorAddresses } = useAppSelector(
    selectVendorPaymentData
  );

  const isPaid = balance === 0;

  const paymentVendorEmail = useMemo(
    () => payment.value.vendorEmail || billVendor.email || "",
    [payment, billVendor]
  );

  const costForCardTransaction = useMemo(() => {
    return billVendor
      ? ({
          vendor: {
            displayName: `${billVendor.displayName}`,
            email: paymentVendorEmail,
            url: `${billVendor.url}`,
          },
          paymentAccount: selectedPaymentMethod?.customerPaymentAccount,
          date: billDate ? new Date(billDate) : currentDate,
          cardTransaction: cardTransaction?.value,
        } as LinkedCost)
      : undefined;
  }, [
    billDate,
    billVendor,
    cardTransaction?.value,
    currentDate,
    paymentVendorEmail,
    selectedPaymentMethod?.customerPaymentAccount,
  ]);

  const enhancedCardFilters = useMemo(() => {
    if (!selectedAccountBalance?.customerCard) return [];

    const accountOwnerFilter = !selectedAccountBalance?.accountOwner;

    return (
      paymentOptions.data?.accounts
        .filter(
          (account) =>
            !!account.customerCard &&
            ((account.customerBankAccount?.id ===
              selectedAccountBalance?.customerBankAccount?.id &&
              !!account.accountOwner === accountOwnerFilter) ||
              account.customerCard?.id ===
                selectedAccountBalance?.customerCard?.id)
        )
        .map((option) => option.customerCard as CustomerCard) || []
    );
  }, [
    paymentOptions.data?.accounts,
    selectedAccountBalance?.accountOwner,
    selectedAccountBalance?.customerBankAccount?.id,
    selectedAccountBalance?.customerCard,
  ]);

  const enhancedCanPay = useMemo(
    () => permissions.canPayBill && !isPaid,
    [permissions.canPayBill, isPaid]
  );

  const lienWaiver = useMemo(
    () => lienWaivers.find((item) => !item.billPayment),
    [lienWaivers]
  );

  const haveLinkedLienWaiver =
    lienWaiver &&
    LIEN_WAIVER_LINKED_STATUS.some((status) => status === lienWaiver.status);

  const canBeReviewedAndPaid = useMemo(() => {
    const allowedPaymentMethods = [
      PAYMENT_METHOD.ACH,
      PAYMENT_METHOD.MAIL_CHECK,
      PAYMENT_METHOD.PRINT_CHECK,
    ];
    return (
      !selectedAccountBalance?.isCreditCard &&
      selectedAccountBalance?.achOption &&
      allowedPaymentMethods.some(
        (paymentMethod) => payment.value.method === paymentMethod
      )
    );
  }, [payment.value.method, selectedAccountBalance]);

  const achShouldBeRequested =
    !selectedAccountBalance?.isCreditCard &&
    payment.value.method === PAYMENT_METHOD.ACH &&
    !!vendor.url &&
    !vendor.hasBankingAch;

  const dispatch = useAppDispatch();

  const { showVendorById } = useVendorAction();

  const curriedOpenVendor = useCallback(
    (stage: Stage) => () => {
      showVendorById(billVendor.id, stage);
    },
    [billVendor.id, showVendorById]
  );

  const onChangeCardTransaction = useEvent<CardTransactionFieldOnChangeHandler>(
    (_, option) => {
      cardTransaction.onChange(option);
      const newDate = option?.date as Date;
      const newAccountBalance = paymentOptions.data?.accounts.find(
        (account) => account.customerCard?.id === option?.card?.id
      );
      payment?.onChange((prev) => ({
        ...prev,
        currentDebitDate: newDate || currentDate,
        accountBalance: newAccountBalance?.value || prev.accountBalance,
        payWhenLienWaiverIsSigned: false,
      }));
      debitDate.onChange(newDate);
      dispatch(recordBillUpdate({ amount_to_pay: option?.amount || balance }));
    }
  );

  const onPutObject = useEvent(async (values: any) => {
    try {
      await (selectedAccountBalance?.accountOwner
        ? patchPlaidAccountOwner({
            plaidAccountOwner: selectedAccountBalance?.accountOwner,
            ...values,
          })
        : patchAccountBalance({
            account: selectedAccountBalance?.customerBankAccount,
            ...values,
          }));
      onSetPaymentAccount?.();
    } catch (e) {
      handleErrors(e);
    }
  });

  const onSetPayWhenLienWaiverIsSigned = useEvent((value: boolean) =>
    payment?.onChange((prev) => ({
      ...prev,
      payWhenLienWaiverIsSigned: value,
      customerLienWaivers: prev.customerLienWaivers.map((item) =>
        value && item.lienWaiverTemplate
          ? { ...item, sendLienWaiverOnPayment: value }
          : item
      ),
    }))
  );

  const onChangeBankAccount = useEvent((value: string) => {
    payment?.onChange((previousPayment) => ({
      ...previousPayment,
      accountBalance: value,
    }));
    if (cardTransaction.value) {
      onChangePaymentAmount(balance);
      cardTransaction.onChange(undefined);
    }

    if (processOnScheduledDateEnabled) {
      const newSelectedAccount = paymentOptions.data?.accounts.find(
        (account) => account.value === value
      );
      const newPaymentMethod = newSelectedAccount?.paymentMethods.find(
        (item) => item.paymentMethod === payment.value.method
      );

      if (newPaymentMethod?.paymentSchedules?.length) {
        debitDate.onChange(
          newPaymentMethod.paymentSchedules[0].processOn || null
        );
      }
    }

    setTouched(true);
  });

  const debitDateDisabled = payment.value.payWhenLienWaiverIsSigned;

  const debitDateHelperMessage = useMemo(() => {
    const getMessage = () => {
      if (achShouldBeRequested && payment.value.payWhenLienWaiverIsSigned) {
        return PAYMENT_STEP_FORM_STRINGS.PAY_WHEN_ACH_IS_COLLECTED_AND_LIEN_WAIVER_IS_SIGNED;
      }
      if (achShouldBeRequested) {
        return PAYMENT_STEP_FORM_STRINGS.PAY_WHEN_ACH_DATA_COLLECTED;
      }
      if (payment.value.payWhenLienWaiverIsSigned) {
        return PAYMENT_STEP_FORM_STRINGS.PAY_WHEN_LIEN_WAIVER_SIGNED_TITLE;
      }
    };

    return debitDateDisabled ? getMessage() : undefined;
  }, [
    achShouldBeRequested,
    debitDateDisabled,
    payment.value.payWhenLienWaiverIsSigned,
  ]);

  const showCardTransactionField = useMemo(() => {
    return (
      (selectedAccountBalance?.isCreditCard ||
        selectedAccountBalance?.customerCard) &&
      selectedAccountBalance.hasUnmatchedCardTransactions &&
      costForCardTransaction
    );
  }, [
    selectedAccountBalance?.isCreditCard,
    selectedAccountBalance?.customerCard,
    selectedAccountBalance?.hasUnmatchedCardTransactions,
    costForCardTransaction,
  ]);

  const hasAvailableVendorCredits =
    !!paymentOptions.data?.vendorCredits?.length;

  const disabledDates = useMemo(() => {
    if (
      !processOnScheduledDateEnabled ||
      !selectedPaymentMethod?.paymentSchedules?.length
    ) {
      return undefined;
    }

    return (date: Date) => {
      // Only disable future dates that aren't in the payment schedules
      const dateTime = endOfDay(new Date(date)).getTime();
      const currentDateTime = endOfDay(new Date()).getTime();

      // If the date is in the past or today, don't disable it
      if (dateTime <= currentDateTime) {
        return false;
      }

      // For future dates, only allow those in the payment schedules
      return !selectedPaymentMethod.paymentSchedules.some(
        (schedule) =>
          schedule.processOn &&
          endOfDay(new Date(schedule.processOn)).getTime() === dateTime
      );
    };
  }, [processOnScheduledDateEnabled, selectedPaymentMethod?.paymentSchedules]);

  return (
    <>
      <Flex gap="xl" direction="column" padding={["xs", "none"]}>
        <Text size="xl" weight="bold">
          {PAYMENT_STEP_FORM_STRINGS.PAYMENT_SECTION_TITLE}
        </Text>
        <Card
          as={Flex}
          gap="2xl"
          direction={{ mobile: "column", tablet: "row" }}
          width="full"
          separator={{ mobile: false, tablet: true }}
        >
          <Flex width="full">
            <Flex gap="xl" width="full" direction="column" separator>
              <Flex gap="xl" width="full" direction="column">
                <Flex
                  width="full"
                  align="center"
                  gap="xl"
                  justify="space-between"
                >
                  <Text size="sm">
                    {PAYMENT_STEP_FORM_STRINGS.OPEN_BALANCE_TITLE}
                  </Text>
                  <Text weight="bold">
                    {" "}
                    {formatCurrency(balance, {
                      currencySign: true,
                      allowNegative: true,
                    })}
                  </Text>
                </Flex>
                {vendorCreditsEnabled && hasAvailableVendorCredits && (
                  <Flex direction="column">
                    <Flex
                      width="full"
                      align="center"
                      gap="xl"
                      justify="space-between"
                    >
                      <Text size="sm">
                        {PAYMENT_STEP_FORM_STRINGS.VENDOR_CREDITS_TITLE}
                      </Text>
                      <Text
                        weight="bold"
                        color={
                          isNegative(appliedVendorCreditsTotalAmount)
                            ? "success-200"
                            : "neutral-800"
                        }
                      >
                        {formatCurrency(appliedVendorCreditsTotalAmount, {
                          currencySign: true,
                          allowNegative: true,
                        })}
                      </Text>
                    </Flex>
                    <Flex
                      width="full"
                      align="center"
                      gap="xl"
                      justify="space-between"
                    >
                      <Text size="sm" color="neutral-500">
                        {formatCurrency(vendorCreditsRemainingAmount, {
                          currencySign: true,
                        })}{" "}
                        available
                      </Text>
                      <Flex margin={["none", "-13px", "none"]}>
                        <Button
                          size="sm"
                          variant="text"
                          color="neutral"
                          aria-label="Edit vendor credits"
                          onClick={vendorCreditSelectionDialog.show}
                        >
                          <Icon name="pen" />
                          {PAYMENT_STEP_FORM_STRINGS.EDIT_CREDITS_TITLE}
                        </Button>
                      </Flex>
                    </Flex>
                  </Flex>
                )}
                <Flex
                  width="full"
                  align="center"
                  gap="xl"
                  justify="space-between"
                >
                  <Text as="label" size="sm">
                    {PAYMENT_STEP_FORM_STRINGS.AMOUNT_TO_PAY_TITLE}
                  </Text>
                  <Flex maxWidth="170px">
                    <CurrencyField
                      aria-labelledby="amount-to-pay-label"
                      value={amountToPay}
                      disabled={
                        haveLinkedLienWaiver ||
                        !enhancedCanPay ||
                        paymentOptions.isLoading
                      }
                      placeholder="0.00"
                      helperMessage={
                        haveLinkedLienWaiver &&
                        STRINGS.PAYMENT_AMOUNT_DISABLED_LIEN_WAIVER
                      }
                      align="right"
                      onChange={onChangePaymentAmount}
                      autoFocus
                      triggerChangeOnFocusedUnmount={false}
                      allowNegative={false}
                      data-testid="payment-amount"
                      errorMessage={
                        formValidationStatus.amountToPay ||
                        formValidationStatus.amountToPayTooHigh
                      }
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                width="full"
                align="center"
                gap="xl"
                justify="space-between"
              >
                <Text size="sm">
                  {PAYMENT_STEP_FORM_STRINGS.REMAINING_BALANCE_TITLE}
                </Text>
                <Text weight="bold">
                  {formatCurrency(
                    sum(balance, -amountToPay, appliedVendorCreditsTotalAmount),
                    {
                      currencySign: true,
                      allowNegative: true,
                    }
                  )}
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex width="full" justify="flex-start" direction="column">
            <Flex width="full" direction="column">
              <BankAccountComboBox
                disabled={isArchivedByUser || !enhancedCanPay}
                errorMessage={
                  touched &&
                  (formValidationStatus.fromAccount ||
                    formValidationStatus.fromAccountLinking)
                }
                label={PAYMENT_STEP_FORM_STRINGS.FROM_ACCOUNT_TITLE}
                value={payment.value.accountBalance}
                paymentOptions={paymentOptions.data}
                data-testid="from-account"
                loading={paymentOptions.isLoading}
                onChange={onChangeBankAccount}
              />
              {formValidationStatus.fromAccountLinking &&
                !paymentOptions.isLoading && (
                  <Flex gap="sm" wrap={true} margin={["none", "none", "lg"]}>
                    <Text size="sm" color="neutral-700" weight="regular">
                      {PAYMENT_STEP_FORM_STRINGS.LINK_ACCOUNT_TITLE}
                    </Text>
                    <Link
                      as="button"
                      type="button"
                      variant="success"
                      onClick={accountSelectDialog.show}
                    >
                      {PAYMENT_STEP_FORM_STRINGS.CLICK_HERE_TITLE}
                    </Link>
                  </Flex>
                )}
            </Flex>
            {paymentMethods.length ? (
              <ComboBox
                disabled={!enhancedCanPay}
                label={PAYMENT_STEP_FORM_STRINGS.PAYMENT_METHOD_TITLE}
                value={payment.value.method}
                data={paymentMethods}
                errorMessage={
                  formValidationStatus.vendorAddress ||
                  formValidationStatus.paymentMethod
                }
                data-testid="payment-method"
                onChange={onChangePaymentMethod}
              />
            ) : null}
            {payment.value.method === PAYMENT_METHOD.MAIL_CHECK &&
              canBeReviewedAndPaid && (
                <Flex direction="column" margin={["none", "none", "lg"]}>
                  <TextField
                    disabled
                    label={PAYMENT_STEP_FORM_STRINGS.VENDOR_ADDRESS_TITLE}
                    messageVariant="hidden"
                    value={
                      vendorAddresses.length ? vendorAddresses[0].label : ""
                    }
                  />
                  <Text size="xs" color="neutral-500">
                    {PAYMENT_STEP_FORM_STRINGS.ADD_VENDOR_ADDRESS_TITLE}{" "}
                    <Link
                      variant="success"
                      size="xs"
                      onClick={curriedOpenVendor("info")}
                    >
                      {PAYMENT_STEP_FORM_STRINGS.ADD_IT_HERE_TITLE}
                    </Link>
                  </Text>
                </Flex>
              )}
            {showCardTransactionField && (
              <CardTransactionField
                cost={costForCardTransaction!}
                filtersAsFixed
                label={PAYMENT_STEP_FORM_STRINGS.CARD_TRANSACTION_TITLE}
                cardFilters={enhancedCardFilters}
                valueVariant="long"
                suffix={<Icon size="md" name="merge" />}
                placeholder={
                  PAYMENT_STEP_FORM_STRINGS.CARD_TRANSACTION_PLACEHOLDER
                }
                tooltipMessage={
                  PAYMENT_STEP_FORM_STRINGS.CARD_TRANSACTION_PLACEHOLDER
                }
                onChange={onChangeCardTransaction}
                selectCard={{
                  title:
                    PAYMENT_STEP_FORM_STRINGS.MATCHING_TRANSACTION_TO_NEW_PAYMENT_DIALOG_TITLE,
                  subtitle:
                    PAYMENT_STEP_FORM_STRINGS.MATCHING_TRANSACTION_TO_NEW_PAYMENT_DIALOG_SUBTITLE,
                }}
                daysThreshold={DAYS_THRESHOLD}
                skipRequest
              />
            )}
            {!formValidationStatus.fromAccount &&
              payment.value.accountBalance && (
                <Flex gap="sm" direction="column">
                  <Flex direction="column">
                    {(achShouldBeRequested ||
                      selectedAccountBalance?.markAsPaidOption) && (
                      <Label htmlFor="payment-when-collected">
                        {PAYMENT_STEP_FORM_STRINGS.DEBIT_DATE_TITLE}
                      </Label>
                    )}
                    <Flex direction="column" gap="xl">
                      {((achShouldBeRequested && canBeReviewedAndPaid) ||
                        selectedAccountBalance?.markAsPaidOption) && (
                        <Flex direction="column" gap="sm">
                          <DateField
                            toDate={
                              processOnScheduledDateEnabled
                                ? payment.value.method === "ACH" &&
                                  !achShouldBeRequested
                                  ? lastAvailablePaymentSchedule?.processOn ||
                                    undefined
                                  : nextAvailablePaymentSchedule?.processOn ||
                                    currentDate
                                : nextAvailablePaymentSchedule?.processOn ||
                                  currentDate
                            }
                            messageVariant={
                              debitDateHelperMessage ? "relative" : "hidden"
                            }
                            value={debitDateDisabled ? null : debitDate.value}
                            onChange={(value) =>
                              debitDate.onChange(value as Date)
                            }
                            highlightedDates={highlightedDates}
                            disabled={debitDateDisabled}
                            helperMessage={debitDateHelperMessage}
                            disabledDates={disabledDates}
                          />
                        </Flex>
                      )}
                      {achShouldBeRequested && canBeReviewedAndPaid && (
                        <Flex direction="column">
                          <Checkbox
                            id="payment-when-collected"
                            label={
                              PAYMENT_STEP_FORM_STRINGS.PAY_WHEN_ACH_DATA_COLLECTED
                            }
                            checked
                            disabled
                          />
                          <Text size="xs" color="neutral-500">
                            {PAYMENT_STEP_FORM_STRINGS.ADD_ACH_DETAILS_TITLE}{" "}
                            <Link
                              variant="success"
                              size="xs"
                              onClick={curriedOpenVendor("payments")}
                            >
                              {PAYMENT_STEP_FORM_STRINGS.ADD_IT_HERE_TITLE}
                            </Link>
                          </Text>
                        </Flex>
                      )}
                      {canManageLienWaivers &&
                      !haveLinkedLienWaiver &&
                      canBeReviewedAndPaid &&
                      !paymentOptions.isLoading &&
                      (!debitDate.value ||
                        !isAfter(debitDate.value, endOfDay(currentDate))) ? (
                        <Checkbox
                          label={
                            PAYMENT_STEP_FORM_STRINGS.PAY_WHEN_LIEN_WAIVER_SIGNED_TITLE
                          }
                          onChange={onSetPayWhenLienWaiverIsSigned}
                          checked={
                            payment.value.payWhenLienWaiverIsSigned || false
                          }
                        />
                      ) : null}
                    </Flex>
                  </Flex>
                </Flex>
              )}
          </Flex>
        </Card>
      </Flex>
      {accountSelectDialog.isRendered && (
        <AccountSelectDialog
          dialog={accountSelectDialog}
          selectHeader={PAYMENT_STEP_FORM_STRINGS.LINK_ACCOUNT_TITLE}
          selectSubHeader={`Bank account: ${selectedAccountBalance?.label}`}
          selectLabel={PAYMENT_STEP_FORM_STRINGS.LINK_ACCOUNT_TITLE}
          putObject={onPutObject}
          isBankAccount={true}
          selectedAccount={undefined}
        />
      )}
      {vendorCreditSelectionDialog.isRendered && (
        <VendorCreditSelectionDialog
          dialog={vendorCreditSelectionDialog}
          vendorCredits={availableVendorCredits}
          initialSelectedVendorCredits={appliedVendorCredits}
          openBalanceAmount={balance}
          vendorName={billVendor?.displayName}
          onSave={setAppliedVendorCredits}
        />
      )}
    </>
  );
};

export const PayBillStepFormV2 = () => {
  const { user } = useUserInfo();

  const { checkTwoFactorAuth } = useTwoFactorAuth();

  const { save, close: onClose } = useBillFormActions();

  const [currentSignature, setSignature] = useState<Signature | undefined>();

  const currentDate = useMemo(() => {
    return new Date();
  }, []);

  const [currentDebitDate, setCurrentDebitDate] = useState<Date | null>(
    currentDate
  );

  const [cancellingPayment, setCancellingPayment] = useState(false);

  const hasSignature = !lodashIsEmpty(currentSignature);

  const updateSignatureDialog = useDialog();

  const showPaymentConfirmationDialog = useDialog({
    lazy: true,
  });

  const [currentCardTransaction, setCurrentCardTransaction] = useState<
    CardTransaction | undefined
  >();

  const [cancelledPaymentId, setCancelledPaymentId] = useState(null);

  const dispatch = useAppDispatch();

  const isDirty = useAppSelector(isDirtyBillSelector);

  const permissions = useBillFormPermissions();

  const {
    canManageLienWaivers,
    purchaseOrderNewLinkingUxEnabled,
    vendorCreditsEnabled,
  } = useClientSettings();

  const cycle = useCycle();

  const billLines = useAppSelector(camelCaseLinesBillSelector);

  const workflows = useAppSelector(workflowsBillSelector);

  const billVendor = useAppSelector(vendorBillSelector);

  const billPayments = useAppSelector<BillPaymentListingV2[]>(
    paymentsBillV2Selector
  );

  const { drawerOpen } = useVendorInfo();

  const { fetchById: fetchVendor } = useVendorAction();

  const [triggerCreatePayment, { isLoading: isLoadingMarkingAsPaid }] =
    useCreatePaymentV2Mutation();

  const billAmountPerCustomer = useMemo(() => {
    return billLines.reduce<{ [key: string]: number }>((acc, line) => {
      if (!line.deleted && line.customer?.url && line.amount) {
        if (acc[line.customer.url]) {
          acc[line.customer.url] += line.amount;
        } else {
          acc[line.customer.url] = line.amount;
        }
      }
      return acc;
    }, {});
  }, [billLines]);

  const billCustomerUrls = useMemo(
    () =>
      Array.from(
        new Set(
          billLines
            .filter((line) => !line.deleted && line.customer?.url)
            .map((line) => line.customer?.url)
        )
      ),
    [billLines]
  );

  const billCustomerIds = useMemo<Set<string>>(
    () =>
      billLines.reduce((acc, line) => {
        if (!line.deleted && line.customer?.id) {
          acc.add(line.customer?.id.toString());
        }
        return acc;
      }, new Set<string>()),
    [billLines]
  );

  const {
    id,
    url: billUrl,
    balance,
    docNumber,
    isArchivedByUser,
    tooManyLines,
    reviewStatus,
    isVendorCredit,
    shouldShowPurchaseOrders,
    billLienWaiverTemplate,
    lienWaivers,
    paymentDetails,
  } = useAppSelector(camelCaseBillSelector);

  const { onViewBillPayment } = useViewBillPayment();

  const rawBill = useAppSelector(selectStaticBill, isEqual);

  const amountToPay = useAppSelector(billParsedAmountToPay);

  const isInPaymentStatus = isInPaymentStatusBill(reviewStatus);

  const enhancedShouldShowPurchaseOrders =
    shouldShowPurchaseOrders &&
    permissions.canViewPurchaseOrder &&
    (!isInPaymentStatus ||
      (isInPaymentStatus && purchaseOrderNewLinkingUxEnabled));

  const billInternalVersion = useObjectInternalVersion(rawBill);

  const isPaid = balance === 0;

  const signatureNameKey = `${SIGNATURE_NAME}-${id}`;

  const lienWaiver = useMemo(
    () => lienWaivers.find((item) => !item.billPayment),
    [lienWaivers]
  );

  const haveLinkedLienWaiver =
    lienWaiver &&
    LIEN_WAIVER_LINKED_STATUS.some((status) => status === lienWaiver.status);

  const enhancedCanPay = useMemo(
    () => permissions.canPayBill && !isPaid,
    [permissions.canPayBill, isPaid]
  );

  const { processOnScheduledDateEnabled } = useClientSettings();

  const {
    data: paymentOptions,
    getPaymentMethods,
    isLoading: paymentOptionsLoading,
    refetch: refetchPaymentOptions,
  } = usePaymentOptionsInfo({
    billIds: id ? [id] : [],
    vendorIds: billVendor?.id ? [billVendor.id] : [],
    skip: !enhancedCanPay || !id,
    includePaymentAccounts: true,
  });

  const paymentOptionsForBill = useDeepMemo(
    () => (paymentOptions.length ? paymentOptions[0] : undefined),
    [paymentOptions]
  );

  const enhancedPaymentOptions = useMemo(() => {
    return {
      data: paymentOptionsForBill,
      isLoading: paymentOptionsLoading,
    };
  }, [paymentOptionsForBill, paymentOptionsLoading]);

  const { vendor, recipientOptions: vendors } = useAppSelector(
    selectVendorPaymentData
  );

  const onChangePaymentAmount = useEvent((value) =>
    dispatch(recordBillUpdate({ amount_to_pay: value }))
  );

  const prevAppliedVendorCreditsAmount = useRef<number>(0);
  const prevBalance = useRef<number>(balance);

  const enhancedAvailableVendorCredits = useDeepMemo<
    DynamicBalanceVendorCredit[]
  >(() => {
    return vendorCreditsEnabled
      ? paymentOptionsForBill?.vendorCredits.map((vendorCredit) => ({
          ...vendorCredit,
          appliedAmount: 0,
          currentOpenBalance: parseCurrency(vendorCredit.openBalance || "0"),
        })) || []
      : [];
  }, [paymentOptionsForBill?.vendorCredits, vendorCreditsEnabled]);

  const {
    appliedVendorCredits,
    setAppliedVendorCredits,
    appliedVendorCreditsTotalAmount,
    vendorCreditsRemainingAmount,
  } = useAppliedVendorCredits({
    billAmount: balance,
    vendorCredits: enhancedAvailableVendorCredits,
    customerIds: billCustomerIds,
  });

  useEffect(() => {
    if (
      prevAppliedVendorCreditsAmount.current !==
        appliedVendorCreditsTotalAmount ||
      prevBalance.current !== balance
    ) {
      onChangePaymentAmount(balance + appliedVendorCreditsTotalAmount);
      prevAppliedVendorCreditsAmount.current = appliedVendorCreditsTotalAmount;
      prevBalance.current = balance;
    }
  }, [appliedVendorCreditsTotalAmount, balance, onChangePaymentAmount]);

  const [payment, setPayment] = useState<PaymentDetail>({
    method:
      paymentDetails.method ||
      ((window.ACH_CHECK_ENABLED
        ? PAYMENT_METHOD.ACH
        : PAYMENT_METHOD.MARK_AS_PAID) as PaymentMethod),
    vendor: paymentDetails.vendor || vendor.email || vendors[0]?.value || "",
    accountBalance: paymentDetails.accountBalance || "",
    account: paymentDetails.account || "",
    vendorBankingAch:
      paymentDetails.vendorBankingAch || vendor?.banking?.url || "",
    vendorEmail: paymentDetails.vendorEmail || vendor.email || "",
    vendorPhoneNumber:
      paymentDetails.vendorPhoneNumber || vendor.phoneNumber || "",
    signature: paymentDetails.signature || currentSignature?.url || "",
    currentDebitDate: paymentDetails.currentDebitDate || currentDate,
    customerLienWaivers: paymentDetails.customerLienWaivers || [],
    payWhenLienWaiverIsSigned:
      paymentDetails.payWhenLienWaiverIsSigned || false,
  });

  const paymentVendorEmail = useMemo(
    () => payment.vendorEmail || billVendor.email || "",
    [payment, billVendor]
  );

  const selectedAccountBalance = useMemo<BankAccountOption | undefined>(
    () =>
      payment.accountBalance
        ? paymentOptionsForBill?.accounts.find(
            (option) => option.value === payment.accountBalance
          )
        : undefined,
    [payment.accountBalance, paymentOptionsForBill]
  );

  const selectedPaymentMethod = useMemo(
    () =>
      selectedAccountBalance?.paymentMethods.find(
        (item) => item.paymentMethod === payment.method
      ) || selectedAccountBalance?.markAsPaidOption,
    [selectedAccountBalance, payment.method]
  );

  const paymentMethods = useMemo(() => {
    if (!selectedAccountBalance) return [];

    return (
      getPaymentMethods(
        selectedAccountBalance?.paymentMethods.map((item) => item.paymentMethod)
      ) || []
    );
  }, [getPaymentMethods, selectedAccountBalance]);

  const canBenReviewedAndPaid = useMemo(() => {
    const allowedPaymentMethods = [
      PAYMENT_METHOD.ACH,
      PAYMENT_METHOD.MAIL_CHECK,
      PAYMENT_METHOD.PRINT_CHECK,
    ];
    return (
      selectedAccountBalance?.achOption &&
      allowedPaymentMethods.some(
        (paymentMethod) => payment.method === paymentMethod
      ) &&
      !currentCardTransaction
    );
  }, [payment.method, selectedAccountBalance, currentCardTransaction]);

  const nextAvailablePaymentSchedule = useMemo(() => {
    return selectedPaymentMethod?.paymentSchedules?.[0];
  }, [selectedPaymentMethod?.paymentSchedules]);

  const lastAvailablePaymentSchedule = useMemo(() => {
    const schedules = selectedPaymentMethod?.paymentSchedules;
    if (!schedules || schedules.length === 0) return undefined;
    return schedules[schedules.length - 1];
  }, [selectedPaymentMethod?.paymentSchedules]);

  useEffect(() => {
    if (
      selectedPaymentMethod &&
      nextAvailablePaymentSchedule &&
      nextAvailablePaymentSchedule.processOn
    ) {
      setCurrentDebitDate(nextAvailablePaymentSchedule.processOn);
    } else if (
      selectedPaymentMethod?.paymentMethod === PAYMENT_METHOD.MARK_AS_PAID
    ) {
      setCurrentDebitDate(currentDate);
    }
  }, [
    currentDate,
    setCurrentDebitDate,
    selectedPaymentMethod?.paymentSchedules,
    nextAvailablePaymentSchedule,
    selectedPaymentMethod,
    selectedPaymentMethod?.paymentMethod,
  ]);

  const onAfterPaymentCreated = useCallback(
    async (createdPayment: CreateBillPaymentV2Response[number]) => {
      const shouldNavigateToNextBillInCycle =
        cycle.status && cycle.status !== TAB_STATUS.ALL;

      if (shouldNavigateToNextBillInCycle) {
        await Promise.all([
          fetchVendor(billVendor.id),
          dispatch(fetchAllBills()),
        ]);
        const hasNext = cycle.hasNavigation && (await cycle.next());
        if (hasNext) {
          toast.info(
            PAYMENT_STEP_FORM_STRINGS.MOVED_TO_THE_NEXT_BILL_WAITING_FOR_PAYMENT
          );
        } else {
          onClose();
        }
        return;
      }

      if (createdPayment) {
        setCurrentCardTransaction(undefined);
        dispatch(refetchCurrentBill(FIELDS_TO_RELOAD_AFTER_PAYMENT_ACTION));
        onViewBillPayment(createdPayment.id);
      }
    },
    [billVendor.id, cycle, dispatch, fetchVendor, onClose, onViewBillPayment]
  );

  const curriedMarkAsPaid = () => {
    if (selectedAccountBalance?.markAsPaidOption) {
      checkTwoFactorAuth(async () => {
        dispatch(setBillStatus("loading"));
        try {
          const createdPayments = await triggerCreatePayment([
            {
              bills: [billUrl!],
              options: {
                appliedAmount: amountToPay,
                customerBankAccount:
                  selectedAccountBalance.customerBankAccount?.url,
                customerCard: selectedAccountBalance.customerCard?.url,
                customerPaymentAccount:
                  selectedAccountBalance.markAsPaidOption
                    ?.customerPaymentAccount?.url,
                vendorBankAccount:
                  selectedAccountBalance.markAsPaidOption?.vendorBankAccount
                    ?.url,
                appliedCardTransaction: currentCardTransaction?.url,
                paymentMethod: PAYMENT_METHOD.MARK_AS_PAID,
                appliedSignature:
                  payment.method === PAYMENT_METHOD.MAIL_CHECK
                    ? getSignatureString(payment.signature)
                    : undefined,
                processPrerequisites:
                  selectedAccountBalance.markAsPaidOption?.processPrerequisites,
                appliedProcessOn: formatDate(
                  currentDebitDate || currentDate,
                  "iso-8601-now"
                ),
                appliedVendorCredits: vendorCreditsEnabled
                  ? appliedVendorCredits
                      .filter((credit) => credit.appliedAmount)
                      .map((credit) => ({
                        vendorCredit: credit.url,
                        appliedAmount: formatCurrency(
                          credit.appliedAmount || 0,
                          {
                            allowNegative: true,
                          }
                        ),
                      }))
                  : [],
              },
              lienWaivers: payment.customerLienWaivers.map((lienWaiver) => ({
                ...lienWaiver,
                lienWaiverTemplateId:
                  lienWaiver.sendLienWaiverOnPayment &&
                  lienWaiver?.lienWaiverTemplate
                    ? `${parseRefinementIdFromUrl(
                        lienWaiver?.lienWaiverTemplate
                      )}`
                    : null,
                status:
                  !lienWaiver.sendLienWaiverOnPayment &&
                  lienWaiver.status !== LIEN_WAIVER_STATUS.NOT_REQUIRED
                    ? LIEN_WAIVER_STATUS.NOT_SELECTED
                    : lienWaiver.status,
              })),
            },
          ]).unwrap();
          toast.success(
            `${PAYMENT_STEP_FORM_STRINGS.PAID_SUCCESSFULLY} #${docNumber}!`
          );
          analytics.track("billPay", { billId: id });

          if (createdPayments.length === 1) {
            await onAfterPaymentCreated(createdPayments[0]);
          }
        } catch (e) {
          handleErrors(e);
        } finally {
          dispatch(setBillStatus("loaded"));
        }
      });
    }
  };

  const commonValidationStatus = useMemo<FormValidationStatus>(() => {
    return {
      billAlreadyPaid:
        reviewStatus === BILL_STATUS.PAID
          ? PAYMENT_STEP_FORM_STRINGS.BILL_ALREADY_PAID_MESSAGE
          : undefined,
      unsavedChanges: isDirty
        ? PAYMENT_STEP_FORM_STRINGS.UNSAVED_CHANGES_ALERT
        : undefined,
      amountToPay:
        !amountToPay && amountToPay !== 0
          ? PAYMENT_STEP_FORM_STRINGS.AMOUNT_TO_PAY_REQUIRED
          : undefined,
      amountToPayTooHigh:
        amountToPay > balance + appliedVendorCreditsTotalAmount
          ? PAYMENT_STEP_FORM_STRINGS.PAYMENT_AMOUNT_T0_HIGH
          : undefined,
      fromAccount: !payment.accountBalance
        ? PAYMENT_STEP_FORM_STRINGS.SELECT_ACCOUNT_TO_PAY
        : undefined,
      fromAccountLinking:
        payment.accountBalance &&
        selectedPaymentMethod &&
        !selectedPaymentMethod?.customerPaymentAccount?.url
          ? PAYMENT_STEP_FORM_STRINGS.PAYMENT_ACCOUNT_NOT_LINKED
          : undefined,
      paymentMethod:
        paymentMethods.length &&
        !paymentMethods.some(
          (paymentMethod) => paymentMethod.value === payment.method
        )
          ? PAYMENT_STEP_FORM_STRINGS.SELECT_PAYMENT_METHOD_TITLE
          : undefined,
      currentDebitDate: !currentDebitDate
        ? PAYMENT_STEP_FORM_STRINGS.SELECT_DEBIT_DATE_TITLE
        : undefined,
      canPayBill: !enhancedCanPay ? STRINGS.ACTION_NOT_ALLOWED : undefined,
      lienWaiversData:
        canManageLienWaivers &&
        !haveLinkedLienWaiver &&
        payment.customerLienWaivers.some(
          (lienWaiver) =>
            lienWaiver.lienWaiverTemplate &&
            !lienWaiver.lienWaiverTemplateIsValid
        )
          ? PAYMENT_STEP_FORM_STRINGS.LIEN_WAIVER_TEMPLATE_MISSING_DATA
          : undefined,
    };
  }, [
    reviewStatus,
    isDirty,
    amountToPay,
    balance,
    appliedVendorCreditsTotalAmount,
    payment.accountBalance,
    payment.customerLienWaivers,
    payment.method,
    selectedPaymentMethod,
    paymentMethods,
    currentDebitDate,
    enhancedCanPay,
    canManageLienWaivers,
    haveLinkedLienWaiver,
  ]);

  const markAsPaidValidationStatus = useMemo<FormValidationStatus>(() => {
    const getCurrentDebitDateError = () => {
      if (commonValidationStatus.currentDebitDate) {
        return commonValidationStatus.currentDebitDate;
      }

      const isPayWhenLienWaiverSigned = payment.payWhenLienWaiverIsSigned;
      const today = new Date();
      const isFutureDate =
        currentDebitDate && isAfter(currentDebitDate, endOfDay(today));

      if (isPayWhenLienWaiverSigned) {
        return PAYMENT_STEP_FORM_STRINGS.CANNOT_MARK_AS_PAID_FOR_PAY_WHEN_LIEN_WAIVER_IS_SIGNED;
      }

      if (isFutureDate) {
        return PAYMENT_STEP_FORM_STRINGS.CANNOT_MARK_AS_PAID_FOR_A_FUTURE_DATE;
      }
    };

    return {
      ...commonValidationStatus,
      currentDebitDate: getCurrentDebitDateError(),
    };
  }, [
    commonValidationStatus,
    payment.payWhenLienWaiverIsSigned,
    currentDebitDate,
  ]);

  const reviewAndPayValidationStatus = useMemo<FormValidationStatus>(() => {
    const getCurrentDebitDateError = () => {
      if (commonValidationStatus.currentDebitDate) {
        return commonValidationStatus.currentDebitDate;
      }

      if (processOnScheduledDateEnabled) {
        const isPayWhenLienWaiverSigned = payment.payWhenLienWaiverIsSigned;
        const missingAchInfo =
          !selectedAccountBalance?.isCreditCard &&
          payment.method === PAYMENT_METHOD.ACH &&
          !!vendor.url &&
          !vendor.hasBankingAch;

        const isFutureDate =
          currentDebitDate &&
          isAfter(endOfDay(currentDebitDate), endOfDay(currentDate));

        if (isFutureDate && (isPayWhenLienWaiverSigned || missingAchInfo)) {
          return PAYMENT_STEP_FORM_STRINGS.CANNOT_SCHEDULE_PAYMENT_FOR_A_FUTURE_DATE_WITH_PREREQUISITES;
        }
        const isMarkAsPaidWithFutureDate =
          payment.method === PAYMENT_METHOD.MARK_AS_PAID && isFutureDate;

        const isCheckWithFutureDate =
          (payment.method === PAYMENT_METHOD.PRINT_CHECK ||
            payment.method === PAYMENT_METHOD.MAIL_CHECK) &&
          isFutureDate;

        const isSchedulablePaymentMethod =
          payment.method !== PAYMENT_METHOD.MARK_AS_PAID;

        const isSchedulableMethodWithPastDate =
          isSchedulablePaymentMethod &&
          currentDebitDate &&
          isBefore(endOfDay(currentDebitDate), endOfDay(currentDate));

        const hasValidDebitDate = !!currentDebitDate;
        const hasScheduleRange =
          !!nextAvailablePaymentSchedule?.processOn &&
          !!lastAvailablePaymentSchedule?.processOn;

        const isBeforeEarliestSchedule =
          currentDebitDate &&
          nextAvailablePaymentSchedule?.processOn &&
          isBefore(
            endOfDay(currentDebitDate),
            endOfDay(nextAvailablePaymentSchedule.processOn)
          );

        const isAfterLatestSchedule =
          currentDebitDate &&
          lastAvailablePaymentSchedule?.processOn &&
          isAfter(
            endOfDay(currentDebitDate),
            endOfDay(lastAvailablePaymentSchedule.processOn)
          );

        const isDateOutsideAvailableScheduleRange =
          isSchedulablePaymentMethod &&
          hasValidDebitDate &&
          hasScheduleRange &&
          isFutureDate &&
          (isBeforeEarliestSchedule || isAfterLatestSchedule);

        if (isMarkAsPaidWithFutureDate) {
          return PAYMENT_STEP_FORM_STRINGS.CANNOT_MARK_AS_PAID_FOR_A_FUTURE_DATE;
        }

        if (isCheckWithFutureDate) {
          return PAYMENT_STEP_FORM_STRINGS.CANNOT_PAY_WITH_CHECK_FOR_A_FUTURE_DATE;
        }

        if (isSchedulableMethodWithPastDate) {
          return PAYMENT_STEP_FORM_STRINGS.PAST_DATE_ERROR;
        }

        if (isDateOutsideAvailableScheduleRange) {
          return PAYMENT_STEP_FORM_STRINGS.SELECT_NEXT_AVAILABLE_BUSINESS_DAY_TO_BE_ABLE_TO_PAY;
        }
      } else {
        const isDateBeforeNextAvailableSchedule =
          currentDebitDate &&
          nextAvailablePaymentSchedule?.processOn &&
          isBefore(
            endOfDay(currentDebitDate),
            endOfDay(nextAvailablePaymentSchedule.processOn)
          );

        if (isDateBeforeNextAvailableSchedule) {
          return PAYMENT_STEP_FORM_STRINGS.SELECT_NEXT_AVAILABLE_BUSINESS_DAY_TO_BE_ABLE_TO_PAY;
        }
      }
    };

    const getCanReviewAndPayError = () => {
      if (!canBenReviewedAndPaid) {
        return PAYMENT_STEP_FORM_STRINGS.REVIEW_AND_PAY_UNAVAILABLE_MESSAGE;
      }
      if (is.number(amountToPay) && !amountToPay) {
        return PAYMENT_STEP_FORM_STRINGS.AMOUNT_TO_PAY_GREATER_THAN_ZERO;
      }
    };

    return {
      ...commonValidationStatus,
      currentDebitDate: getCurrentDebitDateError(),
      lienWaivers:
        payment.payWhenLienWaiverIsSigned &&
        !payment.customerLienWaivers.some(
          (lienWaiver) => lienWaiver.lienWaiverTemplate
        )
          ? PAYMENT_STEP_FORM_STRINGS.LIEN_WAIVER_TEMPLATE_MISSING
          : undefined,
      vendorAddress:
        payment.method === PAYMENT_METHOD.MAIL_CHECK &&
        (!vendor?.address || !addressIsValid(vendor.address))
          ? PAYMENT_STEP_FORM_STRINGS.ADD_VENDOR_ADDRESS_ERROR_TITLE
          : undefined,
      canReviewAndPay: getCanReviewAndPayError(),
      pastDate:
        !processOnScheduledDateEnabled &&
        isBefore(startOfDay(currentDebitDate!), startOfDay(currentDate))
          ? PAYMENT_STEP_FORM_STRINGS.PAST_DATE_ERROR
          : undefined,
    };
  }, [
    commonValidationStatus,
    payment.payWhenLienWaiverIsSigned,
    payment.customerLienWaivers,
    payment.method,
    vendor.address,
    currentDebitDate,
    currentDate,
    nextAvailablePaymentSchedule?.processOn,
    selectedAccountBalance?.isCreditCard,
    vendor.hasBankingAch,
    vendor.url,
    canBenReviewedAndPaid,
    amountToPay,
    lastAvailablePaymentSchedule?.processOn,
    processOnScheduledDateEnabled,
  ]);

  const billFormValidationStatus = useMemo<FormValidationStatus>(
    () => ({
      canEditBill: !permissions.canEditBill
        ? STRINGS.ACTION_NOT_ALLOWED
        : undefined,
      tooManyLines: tooManyLines ? STRINGS.BUTTON_TOO_MANY_LINES : undefined,
    }),
    [permissions.canEditBill, tooManyLines]
  );

  const formValidationStatus = useMemo<FormValidationStatus>(
    () => ({
      ...reviewAndPayValidationStatus,
      ...billFormValidationStatus,
    }),
    [billFormValidationStatus, reviewAndPayValidationStatus]
  );

  const reviewAndPayValidationError = useMemo(() => {
    const errors = Object.values(reviewAndPayValidationStatus).filter(
      (status) => status
    );

    return errors?.[0] || undefined;
  }, [reviewAndPayValidationStatus]);

  const markAsPaidValidationError = useMemo(() => {
    const errors = Object.values(markAsPaidValidationStatus).filter(
      (status) => status
    );

    return errors?.[0] || undefined;
  }, [markAsPaidValidationStatus]);

  const billFormValidationError = useMemo(() => {
    const errors = Object.values(billFormValidationStatus).filter(
      (status) => status
    );

    return errors?.[0] || undefined;
  }, [billFormValidationStatus]);

  const renderLabel = useCallback(
    (label: string) => (cancellingPayment ? <Loader /> : label),
    [cancellingPayment]
  );

  const onUnarchive = useEvent(() => {
    cycle.disable();
    dispatch(unarchiveCurrentBill());
  });

  const primaryActionButtons = useMemo(() => {
    let buttonProps = {
      onClick: () => {
        checkTwoFactorAuth(async () => {
          showPaymentConfirmationDialog.show();
          analytics.track("billPaymentShowConfirmationDialog", {
            isMultiplePayments: false,
            isCombinedPayments: false,
            isPayWhenLienWaiverSigned: payment.payWhenLienWaiverIsSigned,
            numberOfPayments: 1,
            hasVendorCredits: !!appliedVendorCredits?.length,
          });
        });
      },
      disabled:
        isArchivedByUser ||
        paymentOptionsLoading ||
        isLoadingMarkingAsPaid ||
        !!reviewAndPayValidationError ||
        !canBenReviewedAndPaid,

      children: renderLabel(PAY_BUTTON_LABEL.REVIEW_AND_PAY),
    };

    if (isVendorCredit) {
      return null;
    }

    const tooltipMessage = reviewAndPayValidationError ?? null;

    if (isArchivedByUser) {
      buttonProps = {
        onClick: onUnarchive,
        children: PAYMENT_STEP_FORM_STRINGS.RESTORE_TITLE,
        disabled: false,
      };
    }

    if (tooltipMessage && !buttonProps.disabled) {
      buttonProps.disabled = true;
    }

    const content = <Button {...buttonProps} size="lg" />;

    return tooltipMessage ? (
      <Tooltip message={tooltipMessage} placement="left">
        {content}
      </Tooltip>
    ) : (
      content
    );
  }, [
    appliedVendorCredits?.length,
    canBenReviewedAndPaid,
    checkTwoFactorAuth,
    isArchivedByUser,
    isLoadingMarkingAsPaid,
    isVendorCredit,
    onUnarchive,
    payment.payWhenLienWaiverIsSigned,
    paymentOptionsLoading,
    renderLabel,
    reviewAndPayValidationError,
    showPaymentConfirmationDialog,
  ]);

  const onChangeLienWaiverRequest = useEvent(() =>
    dispatch(refetchCurrentBill(["lien_waivers"]))
  );

  const mapLienWaiverCustomer = useCallback(
    (
      customer?: string,
      lienWaiverTemplateUrl?: string,
      sendLienWaiverOnPayment?: boolean,
      lienWaiverTemplateIsValid?: boolean
    ) => {
      const lienWaiverTemplate =
        lienWaiverTemplateUrl &&
        lienWaiverTemplateUrl !== LIEN_WAIVER_STATUS.NOT_REQUIRED
          ? lienWaiverTemplateUrl
          : undefined;

      const status = (
        !lienWaiverTemplateUrl
          ? LIEN_WAIVER_STATUS.NOT_SELECTED
          : lienWaiverTemplateUrl === LIEN_WAIVER_STATUS.NOT_REQUIRED
            ? lienWaiverTemplateUrl
            : undefined
      ) as LienWaiverCustomer["status"];

      return {
        customer,
        lienWaiverTemplate,
        status,
        lienWaiverTemplateIsValid,
        sendLienWaiverOnPayment:
          (lienWaiverTemplate &&
            (sendLienWaiverOnPayment || payment.payWhenLienWaiverIsSigned)) ||
          false,
      } as LienWaiverCustomer;
    },
    [payment.payWhenLienWaiverIsSigned]
  );

  const onLoadTemplateData = useEvent<OnLoadTemplateDataHandler>(
    ({ templateData, customer }) => {
      setPayment((prev) => ({
        ...prev,
        customerLienWaivers: prev.customerLienWaivers.map((item) =>
          item.customer === customer
            ? {
                ...item,
                lienWaiverTemplateIsValid:
                  !templateData.missingFields ||
                  !templateData.missingFields.length,
              }
            : item
        ),
      }));
    }
  );

  const onChangeLienWaiverTemplate = useEvent(
    (
      value: string,
      extra?: { customer?: string; lienWaiverTemplateIsValid?: boolean }
    ) => {
      setPayment((prev) => ({
        ...prev,
        customerLienWaivers: prev.customerLienWaivers.map((item) =>
          item.customer === extra?.customer
            ? mapLienWaiverCustomer(
                extra?.customer,
                value,
                item.sendLienWaiverOnPayment,
                extra.lienWaiverTemplateIsValid
              )
            : item
        ),
      }));
    }
  );

  const onChangeRequestOnPayment = useEvent(
    (value: boolean, extra?: { customer?: string }) => {
      setPayment((prev) => ({
        ...prev,
        customerLienWaivers: prev.customerLienWaivers.map((item) =>
          item.customer === extra?.customer
            ? { ...item, sendLienWaiverOnPayment: value }
            : item
        ),
      }));
    }
  );

  const requestOnPayment = useMemo(
    () => ({
      onChange: onChangeRequestOnPayment,
      disabled: payment.payWhenLienWaiverIsSigned,
    }),
    [onChangeRequestOnPayment, payment.payWhenLienWaiverIsSigned]
  );

  const paymentPayload = useDeepMemo<BillPaymentConfirmationPayload>(() => {
    if (!selectedAccountBalance) return { payments: [] };

    return {
      payments: [
        {
          billUrls: [`${billUrl}`],
          bills: [
            {
              id: `${id}`,
              url: `${billUrl}`,
            },
          ],
          amountToPay,
          paymentMethod: payment.method,
          balance,
          signature:
            payment.method === PAYMENT_METHOD.MAIL_CHECK
              ? getSignatureString(payment.signature)
              : undefined,
          bankAccountOption: selectedAccountBalance,
          payWhenLienWaiverIsSigned: payment.payWhenLienWaiverIsSigned,
          vendor: {
            id: billVendor.id,
            email: paymentVendorEmail,
            hasBankingAch: !!vendor.hasBankingAch,
            bankingAchUrl: vendor.banking?.url,
            displayName: vendor.displayName,
          },
          currentDebitDate: processOnScheduledDateEnabled
            ? currentDebitDate
              ? formatDate(currentDebitDate, "iso-8601-now")
              : undefined
            : nextAvailablePaymentSchedule?.processOn
              ? formatDate(
                  nextAvailablePaymentSchedule.processOn,
                  "iso-8601-now"
                )
              : undefined,
          lienWaivers: payment.customerLienWaivers,
          appliedVendorCredits: vendorCreditsEnabled
            ? appliedVendorCredits.map((credit) => ({
                url: credit.url,
                appliedAmount: credit.appliedAmount || 0,
              }))
            : [],
          appliedVendorCreditsTotalAmount: vendorCreditsEnabled
            ? appliedVendorCreditsTotalAmount
            : 0,
          vendorCreditsRemainingAmount: vendorCreditsEnabled
            ? vendorCreditsRemainingAmount
            : 0,
        },
      ],
    };
  }, [
    billUrl,
    id,
    amountToPay,
    payment.method,
    payment.signature,
    payment.payWhenLienWaiverIsSigned,
    payment.customerLienWaivers,
    balance,
    selectedAccountBalance,
    billVendor.id,
    paymentVendorEmail,
    vendor.hasBankingAch,
    vendor.banking?.url,
    vendor.displayName,
    currentDebitDate,
    vendorCreditsEnabled,
    appliedVendorCredits,
    appliedVendorCreditsTotalAmount,
    vendorCreditsRemainingAmount,
    processOnScheduledDateEnabled,
    nextAvailablePaymentSchedule?.processOn,
  ]);

  const refetchBill = useCallback(() => dispatch(loadBill(id)), [dispatch, id]);

  const onSave = useEvent(() => {
    const snakeCasePayment = transformKeysToSnakeCase(payment);

    dispatch(recordBillUpdate({ payment_details: snakeCasePayment }));
    return save();
  });

  const defaultPaymentOptionValue = useDeepMemo(
    () =>
      paymentOptionsForBill?.accounts.find((option) =>
        option.paymentMethods?.some((item) => item.default)
      )?.value,
    [paymentOptionsForBill]
  );

  useEffect(() => {
    if (isPaid) return;

    setPayment((prevState) => {
      const hasChangedVendor =
        prevState.vendorEmail !== vendor.email ||
        prevState.vendorPhoneNumber !== vendor.phoneNumber ||
        prevState.vendorBankingAch !== vendor?.banking?.url ||
        prevState.vendor !== vendor.url;

      return {
        ...prevState,
        accountBalance:
          prevState.accountBalance || defaultPaymentOptionValue || "",
        vendorEmail:
          (hasChangedVendor ? vendor.email : prevState.vendorEmail) || "",
        vendorPhoneNumber:
          (hasChangedVendor
            ? vendor.phoneNumber
            : prevState.vendorPhoneNumber) || "",
        vendorBankingAch:
          (hasChangedVendor
            ? vendor?.banking?.url
            : prevState.vendorBankingAch) || "",
        vendor: (hasChangedVendor ? vendor.url : prevState.vendor) || "",
        signature: paymentDetails.signature || currentSignature?.url || "",
      };
    });
  }, [
    isPaid,
    defaultPaymentOptionValue,
    vendor?.banking?.url,
    vendor.email,
    vendor.phoneNumber,
    vendor.url,
    paymentDetails.signature,
    currentSignature?.url,
  ]);

  useEffect(() => {
    if (cancelledPaymentId && cancellingPayment) {
      refetchBill();
      const cancelledPayment = billPayments.find(
        (payment) => payment.id === cancelledPaymentId
      );

      if (cancelledPayment?.isVoided) {
        setCancelledPaymentId(null);
        setCancellingPayment(false);
      }
    }
  }, [cancelledPaymentId, cancellingPayment, billPayments, refetchBill]);

  const setDefaultSignature = useCallback(async () => {
    if (!hasSignature) {
      const savedSignatureName = localStorage.getItem(signatureNameKey);

      setSignature(
        await generateSignature(savedSignatureName || user.full_name)
      );
    }
  }, [hasSignature, user.full_name, signatureNameKey]);

  const updateSignature = useEvent((signature?: Signature) => {
    setSignature(signature);
    localStorage.setItem(signatureNameKey, signature?.name || "");
  });

  useEffect(() => {
    setDefaultSignature();
  }, [setDefaultSignature]);

  useEffect(() => {
    if ((!vendor || billVendor.id != vendor.id) && !drawerOpen) {
      fetchVendor(billVendor.id);
    }
  }, [billVendor.id, drawerOpen, fetchVendor, vendor]);

  useEffect(() => {
    if (haveLinkedLienWaiver) return;
    setPayment((prev) => {
      const newLienWaivers = billCustomerUrls
        .filter(
          (customer) =>
            !prev.customerLienWaivers.some((item) => item.customer === customer)
        )
        .map((customer) =>
          mapLienWaiverCustomer(customer, billLienWaiverTemplate)
        );

      const remainingLienWaivers = prev.customerLienWaivers.filter((item) => {
        return billCustomerUrls.some((customer) => item.customer === customer);
      });

      return {
        ...prev,
        customerLienWaivers: [...remainingLienWaivers, ...newLienWaivers],
      };
    });
  }, [
    billCustomerUrls,
    billLienWaiverTemplate,
    haveLinkedLienWaiver,
    mapLienWaiverCustomer,
  ]);

  const debitDateProps = useMemo(
    () => ({
      value: currentDebitDate,
      onChange: setCurrentDebitDate,
    }),
    [currentDebitDate, setCurrentDebitDate]
  );

  const paymentProps = useMemo(
    () => ({ value: payment, onChange: setPayment }),
    [payment, setPayment]
  );

  const cardTransactionProps = useMemo(
    () => ({
      value: currentCardTransaction,
      onChange: setCurrentCardTransaction,
    }),
    [currentCardTransaction, setCurrentCardTransaction]
  );

  const legacyHighlightedDates = useMemo(() => {
    if (!nextAvailablePaymentSchedule) return;
    return [
      {
        message: PAYMENT_STEP_FORM_STRINGS.PROCESS_DATE_TITLE,
        matcher: nextAvailablePaymentSchedule.processOn!,
      },
      {
        message: PAYMENT_STEP_FORM_STRINGS.DELIVERY_DATE_TITLE,
        matcher: {
          from: nextAvailablePaymentSchedule.expectedDeliveryAfter!,
          to: nextAvailablePaymentSchedule.expectedDeliveryBefore!,
        },
      },
    ];
  }, [nextAvailablePaymentSchedule]);

  const highlightedDates = useMemo(() => {
    if (!processOnScheduledDateEnabled) {
      return legacyHighlightedDates;
    }

    const isAchWithoutSchedule =
      !selectedPaymentMethod?.paymentSchedules?.length &&
      currentDebitDate &&
      selectedPaymentMethod?.paymentMethod === PAYMENT_METHOD.ACH;

    if (isAchWithoutSchedule) {
      return [
        {
          message: PAYMENT_STEP_FORM_STRINGS.PROCESS_DATE_TITLE,
          matcher: currentDebitDate,
        },
        {
          message: PAYMENT_STEP_FORM_STRINGS.DELIVERY_DATE_TITLE,
          matcher: {
            from: addDays(currentDebitDate, 3),
            to: addDays(currentDebitDate, 5),
          },
        },
      ];
    }

    // Find the payment schedule that matches the selected debit date
    const selectedSchedule =
      selectedPaymentMethod?.paymentSchedules.find((schedule) => {
        if (!currentDebitDate || !schedule.processOn) {
          return false;
        }
        return (
          endOfDay(new Date(schedule.processOn)).getTime() ===
          endOfDay(new Date(currentDebitDate)).getTime()
        );
      }) || nextAvailablePaymentSchedule;

    if (!selectedSchedule) {
      return legacyHighlightedDates;
    }

    return [
      {
        message: PAYMENT_STEP_FORM_STRINGS.PROCESS_DATE_TITLE,
        matcher: selectedSchedule.processOn!,
      },
      {
        message: PAYMENT_STEP_FORM_STRINGS.DELIVERY_DATE_TITLE,
        matcher: {
          from: selectedSchedule.expectedDeliveryAfter!,
          to: selectedSchedule.expectedDeliveryBefore!,
        },
      },
    ];
  }, [
    currentDebitDate,
    nextAvailablePaymentSchedule,
    selectedPaymentMethod,
    processOnScheduledDateEnabled,
    legacyHighlightedDates,
  ]);

  return (
    <>
      <div className="steps-section-content">
        <Flex direction="column" padding={["3xl", "5xl"]}>
          <Flex gap="5xl" direction="column">
            <BillAlerts />
            <Flex gap="5xl" direction="column">
              {((!isVendorCredit && !isArchivedByUser) ||
                !!billPayments?.length) && (
                <Flex gap="3xl" direction="column">
                  {!isVendorCredit && !isArchivedByUser && (
                    <>
                      {!isPaid && (
                        <Flex gap="3xl" direction="column">
                          <BillPaymentForm
                            appliedVendorCredits={appliedVendorCredits}
                            setAppliedVendorCredits={setAppliedVendorCredits}
                            availableVendorCredits={
                              enhancedAvailableVendorCredits
                            }
                            appliedVendorCreditsTotalAmount={
                              appliedVendorCreditsTotalAmount
                            }
                            vendorCreditsRemainingAmount={
                              vendorCreditsRemainingAmount
                            }
                            debitDate={debitDateProps}
                            paymentOptions={enhancedPaymentOptions}
                            formValidationStatus={formValidationStatus}
                            nextAvailablePaymentSchedule={
                              nextAvailablePaymentSchedule
                            }
                            lastAvailablePaymentSchedule={
                              lastAvailablePaymentSchedule
                            }
                            payment={paymentProps}
                            selectedAccountBalance={selectedAccountBalance}
                            paymentMethods={paymentMethods}
                            cardTransaction={cardTransactionProps}
                            onSetPaymentAccount={refetchPaymentOptions}
                            highlightedDates={highlightedDates}
                          />

                          {payment.method === PAYMENT_METHOD.MAIL_CHECK &&
                            currentSignature &&
                            selectedPaymentMethod?.paymentMethod ===
                              PAYMENT_METHOD.MAIL_CHECK && (
                              <SignatureField
                                signature={currentSignature}
                                onChange={updateSignature}
                                dialog={updateSignatureDialog}
                              />
                            )}

                          {canManageLienWaivers && balance > 0 && (
                            <BillLienWaivers
                              billAmountPerCustomer={billAmountPerCustomer}
                              lienWaiverCustomers={payment.customerLienWaivers}
                              onLoadTemplateData={onLoadTemplateData}
                              onChange={onChangeLienWaiverTemplate}
                              recipientEmail={paymentVendorEmail}
                              vendor={vendor}
                              paymentAmount={
                                amountToPay - appliedVendorCreditsTotalAmount
                              }
                              netPaymentAmount={amountToPay}
                              billId={id}
                              billBalance={balance}
                              billLienWaiver={lienWaiver}
                              billDocNumber={docNumber!}
                              onRequestUpdate={onChangeLienWaiverRequest}
                              requestOnPayment={requestOnPayment}
                              billInternalVersion={billInternalVersion}
                            />
                          )}
                        </Flex>
                      )}
                    </>
                  )}
                  {billPayments?.length > 0 && (
                    <Flex gap="xl" direction="column">
                      <Text size="xl" weight="bold">
                        {
                          PAYMENT_STEP_FORM_STRINGS.PREVIOUS_PAYMENT_SECTION_TITLE
                        }
                      </Text>
                      <BillPaymentInfo billPayments={billPayments} />
                    </Flex>
                  )}
                </Flex>
              )}

              <Flex gap="xl" direction="column">
                <Form onEnterSubmit={onSave}>
                  <Info />
                </Form>
              </Flex>

              {enhancedShouldShowPurchaseOrders && <PurchaseOrders />}

              <Form onEnterSubmit={onSave}>
                <Items />
              </Form>

              {/**
               * We need it as backward compatibility since old bills could not have
               * workflows attached, new bills will always have at least one workflow
               */}
              {workflows.length > 0 && (
                <ApprovalsSection
                  editable={false}
                  objectId={Number(id)}
                  objectType="Bill"
                  workflows={workflows}
                  helperMessage={(mode) => (mode === "EMPTY" ? false : "")}
                />
              )}

              <Comments />
            </Flex>
          </Flex>
        </Flex>
      </div>

      <footer className="steps-section-footer">
        <Flex gap="xl">
          <Button variant="text" size="lg" color="neutral" onClick={onClose}>
            {PAYMENT_STEP_FORM_STRINGS.CANCEL_TITLE}
          </Button>
          <DynamicActions />
        </Flex>
        <Flex gap="xl">
          <Tooltip message={billFormValidationError} placement="left">
            <Button
              disabled={
                isArchivedByUser ||
                !!billFormValidationError ||
                isLoadingMarkingAsPaid
              }
              variant="ghost"
              size="lg"
              onClick={onSave}
            >
              {PAYMENT_STEP_FORM_STRINGS.SAVE_TITLE}
            </Button>
          </Tooltip>
          {!markAsPaidValidationStatus.billAlreadyPaid && (
            <>
              {selectedAccountBalance?.markAsPaidOption && !isVendorCredit && (
                <Tooltip message={markAsPaidValidationError} placement="left">
                  <Button
                    disabled={!!markAsPaidValidationError}
                    variant="ghost"
                    size="lg"
                    onClick={curriedMarkAsPaid}
                  >
                    {PAY_BUTTON_LABEL.MARK_AS_PAID}
                  </Button>
                </Tooltip>
              )}
              {primaryActionButtons}
            </>
          )}
        </Flex>
      </footer>
      {showPaymentConfirmationDialog &&
        id &&
        billUrl &&
        selectedAccountBalance &&
        vendor && (
          <BillPaymentConfirmationDialog
            onClose={showPaymentConfirmationDialog.hide}
            paymentsInfo={paymentPayload}
            dialog={showPaymentConfirmationDialog}
            onSinglePaymentCreated={onAfterPaymentCreated}
          />
        )}
    </>
  );
};
