import { type BillPaymentListingV2 } from "@bill-payment/types";
import { formatCard } from "@utils/format-card";

export const getPaymentAccountDisplayName = ({
  customerBankAccount,
  customerCard,
  customerPaymentAccount,
}: Pick<
  BillPaymentListingV2,
  "customerBankAccount" | "customerPaymentAccount" | "customerCard"
>) => {
  if (customerBankAccount || customerCard) {
    const name =
      customerCard?.fullName ||
      customerBankAccount?.name ||
      customerPaymentAccount?.displayName;

    const paymentAccountName =
      customerBankAccount?.paymentAccount?.displayName ||
      customerPaymentAccount?.displayName ||
      "";

    const mask = customerCard?.mask || customerBankAccount?.mask || "";
    return `${formatCard({
      name,
      mask: name?.includes(mask) ? undefined : mask,
    })}${paymentAccountName ? " - " : ""}${paymentAccountName}`;
  }

  if (customerPaymentAccount) {
    return customerPaymentAccount.displayName;
  }
};
