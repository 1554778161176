import React, { memo } from "react";
import { useDispatch } from "react-redux";
import {
  Alert,
  AlertContent,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Link,
  Loader,
} from "@adaptive/design-system";
import { type useDialog, useEvent } from "@adaptive/design-system/hooks";
import { DisputeTransaction } from "@capitalos/react";
import { toggleChatVisibility } from "@store/user/slice";

import { useCardsAdaptiveContext } from "./cards-adaptive-context";

type CardsDisputeTransactionDialogProps = {
  dialog: ReturnType<typeof useDialog>;
  capitalOsId?: string | null;
};

export const CardsDisputeTransactionDialog = memo(
  ({ dialog, capitalOsId }: CardsDisputeTransactionDialogProps) => {
    const dispatch = useDispatch();

    const { hasError, setHasError } = useCardsAdaptiveContext();

    const openChat = useEvent(() => {
      dispatch(toggleChatVisibility(true));
    });

    const onError = useEvent(() => {
      setHasError(true);
    });

    return (
      <Dialog variant="drawer" show={dialog.isVisible} onClose={dialog.hide}>
        <DialogHeader>Dispute transaction</DialogHeader>
        <DialogContent>
          {hasError || !capitalOsId ? (
            <Alert variant="error">
              <AlertContent>
                Error loading Adaptive cards.{" "}
                <Link as="button" type="button" onClick={openChat}>
                  Contact support
                </Link>{" "}
                to get help.
              </AlertContent>
            </Alert>
          ) : (
            <DisputeTransaction
              onDone={dialog.hide}
              onError={onError}
              onCancel={dialog.hide}
              transactionId={capitalOsId}
              loadingComponent={
                <Flex width="full">
                  <Loader />
                </Flex>
              }
            />
          )}
        </DialogContent>
        <DialogFooter>
          <Button
            size="lg"
            variant="text"
            color="neutral"
            onClick={dialog.hide}
          >
            Close
          </Button>
        </DialogFooter>
      </Dialog>
    );
  }
);

CardsDisputeTransactionDialog.displayName = "CardsDisputeTransactionDialog";
