import { useMemo } from "react";
import { useDeepMemo } from "@adaptive/design-system/hooks";
import { useClientSettings } from "@store/user";
import { sum } from "@utils/sum";

import type { VendorCreditPaymentOption } from "../../api/types";
import { type usePaymentOptionsInfo } from "../../hooks";
import type {
  DynamicBalanceVendorCredit,
  MultiplePaymentForm,
} from "../../types";

export const useVendorCreditsByVendor = (
  formValues: MultiplePaymentForm,
  paymentOptions: ReturnType<typeof usePaymentOptionsInfo>["data"]
) => {
  const { vendorCreditsEnabled } = useClientSettings();

  const vendorCreditsByVendor = useDeepMemo(() => {
    const calculateUsageAmountsForVendorCredit = (
      vendorCredit: VendorCreditPaymentOption
    ) => {
      const appliedAmount = formValues.payments.reduce((acc, payment) => {
        const appliedVendorCredit = payment.appliedVendorCredits?.find(
          (appliedVendorCredit) => appliedVendorCredit.id === vendorCredit.id
        );

        if (!appliedVendorCredit) return acc;

        return acc + appliedVendorCredit.appliedAmount;
      }, 0);

      return {
        currentOpenBalance: sum(vendorCredit.openBalance || 0, -appliedAmount),
        totalAppliedAmount: appliedAmount,
      };
    };

    return paymentOptions.reduce(
      (acc, option) => {
        if (!vendorCreditsEnabled) {
          acc[option.vendor.id] = [];
          return acc;
        }

        acc[option.vendor.id] = option.vendorCredits?.map((vc) => {
          const { currentOpenBalance, totalAppliedAmount } =
            calculateUsageAmountsForVendorCredit(vc);
          return {
            ...vc,
            appliedAmount: totalAppliedAmount,
            currentOpenBalance: currentOpenBalance,
          };
        });

        return acc;
      },
      {} as Record<string, DynamicBalanceVendorCredit[]>
    );
  }, [formValues.payments, paymentOptions, vendorCreditsEnabled]);

  const vendorCreditBalanceByVendor = useMemo(() => {
    return Object.entries(vendorCreditsByVendor).reduce(
      (acc, [vendorId, vendorCredits]) => {
        if (!vendorCreditsEnabled) {
          acc[vendorId] = 0;
          return acc;
        }

        acc[vendorId] = vendorCredits.reduce(
          (acc, vendorCredit) => acc + (vendorCredit.currentOpenBalance || 0),
          0
        );
        return acc;
      },
      {} as Record<string, number>
    );
  }, [vendorCreditsByVendor, vendorCreditsEnabled]);
  return { vendorCreditsByVendor, vendorCreditBalanceByVendor };
};
