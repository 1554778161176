import React, { useMemo, useState } from "react";
import {
  Button,
  Flex,
  FloatingToolbar,
  Icon,
  Text,
  Tooltip,
} from "@adaptive/design-system";
import { useDialog, useEvent } from "@adaptive/design-system/hooks";
import { formatCurrency } from "@adaptive/design-system/utils";
import type { BillListItemResponse } from "@api/bills";
import { CombinedPaymentDialog } from "@bill-payment/components";
import { PAYMENT_METHOD } from "@bill-payment/constants";
import { TAB_STATUS } from "@bills/constants";
import type { CombinedPaymentMethods, Status } from "@bills/types";
import { billListFilterV2Selector, getBillsByStatus } from "@bills/utils";
import { fetchBillsByStatus } from "@store/billListSlice";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { sumBy } from "@utils/sumBy";

import { getTransactionTypesSelectedTitle, STRINGS } from "./constants";

type PaymentFloatingBarProps = {
  currentStatus: Status;
  selectedRows: BillListItemResponse[];
  setSelectedRows: (rows: BillListItemResponse[]) => void;
  onRemoveBill: (billId: string) => void;
};

export const PaymentFloatingBar = ({
  currentStatus,
  selectedRows,
  setSelectedRows,
  onRemoveBill,
}: PaymentFloatingBarProps) => {
  const dialog = useDialog({ lazy: true });
  const { filter, billList } = useAppSelector(billListFilterV2Selector);
  const dispatch = useAppDispatch();

  const [paymentMethod, setPaymentMethod] = useState<
    CombinedPaymentMethods | undefined
  >();

  const show =
    [TAB_STATUS.FOR_PAYMENT, TAB_STATUS.ALL].some(
      (status) => status === currentStatus
    ) && selectedRows.length > 0;

  const hasVendorCreditsSelected = useMemo(
    () => selectedRows.filter((row) => row.isVendorCredit).length > 0,
    [selectedRows]
  );

  const hasBillsSelected = useMemo(
    () => selectedRows.filter((row) => !row.isVendorCredit).length > 0,
    [selectedRows]
  );

  const totalAmountToPay = useMemo(
    () =>
      formatCurrency(sumBy(selectedRows, "balance"), {
        currencySign: true,
      }),
    [selectedRows]
  );

  const onShowPaymentMethodDialog = useEvent(
    (paymentMethod: CombinedPaymentMethods) => {
      setPaymentMethod(paymentMethod);
      dialog.show();
    }
  );

  const onClose = useEvent((paymentsFinished?: boolean) => {
    if (paymentsFinished) {
      const status = TAB_STATUS.FOR_PAYMENT;
      const enhancedQuery = [
        ...filter.query,
        ...getBillsByStatus(billList, status).filter.query,
      ];
      dispatch(fetchBillsByStatus({ status, filters: enhancedQuery })).then(
        () => {
          dialog.hide();
          setSelectedRows([]);
        }
      );
    } else {
      dialog.hide();
      setSelectedRows([]);
    }
  });

  return (
    <>
      <FloatingToolbar show={show}>
        <Flex width="full" justify="space-between">
          <Flex separator gap="md" align="center">
            <Flex gap="xs">
              <Text>
                {getTransactionTypesSelectedTitle(
                  hasVendorCreditsSelected,
                  hasBillsSelected
                )}
              </Text>
              <Text weight="bold">{selectedRows.length}</Text>
            </Flex>
            <Flex gap="xs">
              <Text>{STRINGS.TOTAL_AMOUNT}</Text>
              <Text weight="bold">{totalAmountToPay}</Text>
            </Flex>
          </Flex>
          <Flex gap="md" align="center">
            <Tooltip
              as={Icon}
              name="info-circle"
              message={
                <>
                  <Text align="center">
                    {STRINGS.MARK_AS_PAID_TOOLTIP_LINE1}
                  </Text>
                  <Text align="center">
                    {STRINGS.MARK_AS_PAID_TOOLTIP_LINE2}
                  </Text>
                </>
              }
            />
            <Button
              variant="ghost"
              onClick={() =>
                onShowPaymentMethodDialog(PAYMENT_METHOD.MARK_AS_PAID)
              }
              data-testid="mark-as-paid-button"
            >
              {STRINGS.MARK_AS_PAID}
            </Button>
            <Button
              variant="solid"
              data-testid="pay-with-ach-button"
              onClick={() => onShowPaymentMethodDialog(PAYMENT_METHOD.ACH)}
            >
              {STRINGS.PAY_WITH_ACH}
            </Button>
          </Flex>
        </Flex>
      </FloatingToolbar>
      {dialog.isRendered && (
        <CombinedPaymentDialog
          dialog={dialog}
          selectedRows={selectedRows}
          onRemoveBill={onRemoveBill}
          onClose={onClose}
          paymentMethod={paymentMethod!}
        />
      )}
    </>
  );
};
