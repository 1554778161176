import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogStep,
  Flex,
  Icon,
  Loader,
  Table,
  type TableRowAddon,
  type TableSortAddon,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useEvent, usePagination } from "@adaptive/design-system/hooks";
import { isEqual, pick } from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import {
  type Change as ChangeApi,
  useDeleteChangeMutation,
  useLazyGetChangeQuery,
} from "@api/jobs/changes";
import { useGetChangesQuery } from "@api/jobs/changes";
import { useJobsCostCodeAccountSimplified } from "@hooks/use-jobs-cost-codes-accounts-simplified";
import {
  MARKUP_PERCENTAGE_FORMAT,
  PERMISSION_STRINGS,
  useJobPermission,
  useJobSettings,
} from "@src/jobs";
import { MarkupForm, type MarkupFormProps } from "@src/jobs/markup";
import { useJobInfo } from "@store/jobs";
import { useClientSettings } from "@store/user";
import * as analytics from "@utils/analytics";
import { isAccount } from "@utils/is-account";
import { isCostCode } from "@utils/is-cost-code";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { sum } from "@utils/sum";

import { getChangeColumns } from "./changes-dialog-columns";
import { ChangesDialogContext, isMultiStep } from "./changes-dialog-context";
import { type Change, ChangesForm } from "./changes-form";
import type { ChangeDialogStep, ChangeLinesDialogProps } from "./types";

const getIsEditDialog = (dialog: any): boolean =>
  isMultiStep(dialog) && dialog.step !== "create-change";

export const ChangesDialog = ({
  mode,
  dialog,
  budgetLine,
  jobCostMethods,
  onSubmitChange,
}: ChangeLinesDialogProps) => {
  const { job } = useJobInfo();

  const [searchParams, setSearchParams] = useSearchParams();

  const changeParam = searchParams.get("change");

  const [deleteChange] = useDeleteChangeMutation();

  const [change, setChange] = useState<Change>();

  const [markup, setMarkup] = useState<Change["markups"][number]>();

  const [markupIsValid, setMarkupIsValid] = useState(false);

  const [sort, setSort] = useState("-date");

  const sortAddon = useMemo<TableSortAddon>(
    () => ({ value: sort, onChange: setSort }),
    [sort]
  );

  const { externalRevisedBudgetEnabled } = useClientSettings();

  const { ownersAmountEnabled: externalBudgetEnabled, changeTrackingEnabled } =
    useJobSettings();

  const { canManageJobs, budgetReadOnly } = useJobPermission();

  const enhancedChangeTrackingEnabled =
    changeTrackingEnabled && externalRevisedBudgetEnabled;

  const editMarkup = useEvent((markup: Change["markups"][number]) => {
    setMarkup(markup);
    dialog.setStep("edit-markup");
  });

  const onOpenChangeForStep = useEvent<
    (change: Change, step: ChangeDialogStep) => () => void
  >((change, step) => () => {
    setChange(change);
    dialog.setStep(step);
  });

  const onSetChangeForStep = useEvent<(change: Change | undefined) => void>(
    (change) => {
      setChange(change);
    }
  );

  const onEnhancedBack = useEvent(() => {
    setChange(undefined);
    dialog.back();
  });

  const onDeleteChangeOrder = useEvent(async () => {
    if (!(change && change?.id)) return;
    try {
      await deleteChange({
        changeId: change.id,
        customerId: String(job.id),
      }).unwrap();
      dialog.setStep("view-changes");
      toast.success("Change successfully deleted");
      analytics.track("budgetChangeDelete", { customerId: job.id });
    } catch (e) {
      handleErrors(e);
    }
  });

  const onAddMarkupSubmitCost = useEvent<MarkupFormProps["onSubmitCost"]>(
    async ({ costCode: jobCostMethod, value }) => {
      setChange((change) => {
        if (!change) return change;

        return {
          ...change,
          markups: [
            ...(change.markups ?? []),
            {
              value,
              markupType: "fixed_amount",
              jobCostMethod,
              changeLineJobCostMethods: [],
            },
          ],
        };
      });

      dialog.back();
    }
  );

  const onAddMarkupSubmitPercentage = useEvent<
    MarkupFormProps["onSubmitPercentage"]
  >(async ({ costCode: jobCostMethod, lines, value }) => {
    setChange((change) => {
      if (!change) return change;

      return {
        ...change,
        markups: [
          ...(change.markups ?? []),
          {
            value,
            markupType: "percentage",
            jobCostMethod,
            changeLineJobCostMethods: lines.map(
              (line) => line.jobCostMethod!.url
            ),
          },
        ],
      };
    });

    dialog.back();
  });

  const onEditMarkupSubmitCost = useEvent<MarkupFormProps["onSubmitCost"]>(
    async ({ costCode: jobCostMethod, value }) => {
      setChange((change) => {
        if (!change) return change;

        return {
          ...change,
          markups: change.markups?.map((changeMarkup) => {
            if (isEqual(changeMarkup, markup)) {
              return { ...changeMarkup, value, jobCostMethod };
            }

            return changeMarkup;
          }),
        };
      });

      dialog.back();
    }
  );

  const onEditMarkupSubmitPercentage = useEvent<
    MarkupFormProps["onSubmitPercentage"]
  >(async ({ costCode: jobCostMethod, lines, value }) => {
    setChange((change) => {
      if (!change) return change;

      return {
        ...change,
        markups: change.markups?.map((changeMarkup) => {
          if (
            isEqual(
              pick(changeMarkup, [
                "id",
                "value",
                "markupType",
                "jobCostMethod",
                "changeLineJobCostMethods",
              ]),
              markup
            )
          ) {
            return {
              ...changeMarkup,
              value,
              jobCostMethod,
              changeLineJobCostMethods: lines.map(
                (line) => line.jobCostMethod!.url
              ),
            };
          }

          return changeMarkup;
        }),
      };
    });

    dialog.back();
  });

  const pagination = usePagination();

  const params = useMemo(() => {
    if (budgetLine?.groupedBy === "quickbooks") {
      if (!budgetLine.jobCostMethod.url) return {};

      return {
        items: isCostCode(budgetLine.jobCostMethod.url)
          ? budgetLine.jobCostMethod.id
          : undefined,
        accounts: isAccount(budgetLine.jobCostMethod.url)
          ? budgetLine.jobCostMethod.id
          : undefined,
      };
    }

    if (budgetLine?.groupedBy === "categories") {
      if (budgetLine?.category?.id) {
        return { categories: budgetLine.category.id };
      }

      return { uncategorized: true };
    }

    return { budgetLineId: budgetLine?.id };
  }, [budgetLine]);

  const { data, isFetching } = useGetChangesQuery(
    {
      ...params,
      customerId: job.id,
      page: pagination.page,
      limit: pagination.perPage,
      offset: pagination.offset,
      ordering: sort,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !job.id || mode === "create",
    }
  );

  const [triggerGetChange, getChangeInfo] = useLazyGetChangeQuery();

  const enhancedData = useMemo(() => {
    if (enhancedChangeTrackingEnabled && externalBudgetEnabled) {
      return (data?.results ?? []).map((change) => ({
        ...change,
        markups: change.markups.map((markup) => ({
          ...markup,
          value: markup.ownersValue,
        })),
      }));
    }

    return data?.results ?? [];
  }, [externalBudgetEnabled, data?.results, enhancedChangeTrackingEnabled]);

  const costCodeAccounts = useJobsCostCodeAccountSimplified();

  const changeLinesForMarkups = useMemo(
    () =>
      [...(change?.lines ?? [])].reduce(
        (acc, line) => {
          const existingLineIndex = acc.findIndex(
            (accLine) => accLine.jobCostMethod?.url === line.jobCostMethod
          );

          const amount =
            enhancedChangeTrackingEnabled && externalBudgetEnabled
              ? line.externalAmount
              : line.amount;

          if (existingLineIndex !== -1) {
            acc[existingLineIndex].amount = sum(
              acc[existingLineIndex].amount ?? 0,
              amount ?? 0
            );

            return acc;
          }

          const jobCostMethod = costCodeAccounts.data.find(
            (account) => account.value === line.jobCostMethod
          )!;

          if (!jobCostMethod) return acc;

          return [
            ...acc,
            {
              ...line,
              amount,
              id: "",
              jobCostMethod: {
                id: Number(parseRefinementIdFromUrl(jobCostMethod.value)),
                url: jobCostMethod.value!,
                displayName: jobCostMethod.label!,
              },
            },
          ];
        },
        [] as MarkupFormProps["lines"]
      ),
    [
      change?.lines,
      costCodeAccounts.data,
      enhancedChangeTrackingEnabled,
      externalBudgetEnabled,
    ]
  );

  const [jobCostMethodsFromParam] = useState(() => {
    const costBudget = searchParams.get("costBudget");
    const jobCostMethod = searchParams.get("jobCostMethod");
    const externalBudget =
      externalBudgetEnabled && enhancedChangeTrackingEnabled
        ? searchParams.get("externalBudget")
        : "0";

    const isCreate = costBudget && jobCostMethod && externalBudget;

    if (!isCreate) return [];

    return [
      {
        amount: Number(costBudget),
        jobCostMethod,
        externalAmount: Number(externalBudget),
      },
    ];
  });

  const createChangeLinesProps = useMemo(() => {
    let values = [
      {
        jobCostMethod: budgetLine?.jobCostMethod?.url || "",
        amount: 0,
        externalAmount: 0,
      },
    ];

    const enhancedJobCostMethods = [
      ...jobCostMethodsFromParam,
      ...(jobCostMethods ?? []),
    ];

    if (enhancedJobCostMethods.length > 0) {
      values = enhancedJobCostMethods.map((jobCostMethod) =>
        typeof jobCostMethod === "string"
          ? {
              jobCostMethod,
              amount: 0,
              externalAmount: 0,
            }
          : jobCostMethod
      );
    }

    return values;
  }, [jobCostMethods, budgetLine?.jobCostMethod?.url, jobCostMethodsFromParam]);

  const changeColumns = useMemo(
    () =>
      getChangeColumns({
        externalBudgetEnabled,
        changeTrackingEnabled: enhancedChangeTrackingEnabled,
      }),
    [externalBudgetEnabled, enhancedChangeTrackingEnabled]
  );

  const markupCostInitialValues = useMemo(
    () =>
      markup?.markupType === "fixed_amount"
        ? { value: markup.value, costCode: markup.jobCostMethod }
        : undefined,
    [markup]
  );

  const markupPercentageInitialValues = useMemo(
    () =>
      markup?.markupType === "percentage"
        ? {
            value: markup.value,
            costCode: markup.jobCostMethod,
            lines: changeLinesForMarkups.filter((line) =>
              markup.changeLineJobCostMethods?.includes(
                line?.jobCostMethod?.url ?? ""
              )
            ),
            isSeparateLine: false,
          }
        : undefined,
    [changeLinesForMarkups, markup]
  );

  const row = useMemo<TableRowAddon<ChangeApi>>(
    () => ({
      onClick: (row) => {
        const change = {
          id: row.id,
          docNumber: row.docNumber,
          description: row.description ?? "",
          date: row.date,
          lines: row.lines.map((row) => ({
            ...("id" in row && row.id ? { id: row.id } : {}),
            amount: row.builderAmount,
            externalAmount: row.externalAmount,
            jobCostMethod: row.jobCostMethod.url,
          })) ?? [{ jobCostMethod: "", amount: 0 }],
          markups: row.markups.map((row) => ({
            ...row,
            jobCostMethod: row.jobCostMethod.url,
          })),
        };

        onOpenChangeForStep(change, "edit-change")();
      },
    }),
    [onOpenChangeForStep]
  );

  const totalAmount = useMemo(() => {
    let amount = 0;

    enhancedData.forEach((change) => {
      change.lines.forEach((line) => {
        amount = sum(amount, line.builderAmount);
      });

      if (externalBudgetEnabled) return;

      change.markups.forEach((markup) => {
        amount = sum(amount, markup.changeAmount);
      });
    });

    return amount;
  }, [enhancedData, externalBudgetEnabled]);

  const totalExternalAmount = useMemo(() => {
    let amount = 0;

    enhancedData.forEach((change) => {
      change.lines.forEach((line) => {
        amount = sum(amount, line.externalAmount);
      });

      change.markups.forEach((markup) => {
        amount = sum(amount, markup.externalChangeAmount);
      });
    });

    return amount;
  }, [enhancedData]);

  const automaticallyCreateChange = useCallback(() => {
    if (!jobCostMethodsFromParam.length) return;

    setSearchParams(
      (searchParams) => {
        searchParams.delete("change");
        searchParams.delete("location");
        searchParams.delete("costBudget");
        searchParams.delete("jobCostMethod");
        searchParams.delete("externalBudget");
        return searchParams;
      },
      { replace: true }
    );
  }, [jobCostMethodsFromParam, setSearchParams]);

  /**
   * This process was started on BudgetsTable component, so we expect
   * that BudgetsTable open this dialog and then we open the change.
   */
  const automaticallyViewChange = useCallback(async () => {
    if (!changeParam) return;

    let change = enhancedData.find((change) => change.id == changeParam);

    if (!change) {
      change = await triggerGetChange({
        changeId: changeParam,
        customerId: job.id,
      }).unwrap();
    }

    if (!change) return;

    setChange(undefined);

    const enhancedChange = {
      id: change.id,
      docNumber: change.docNumber,
      description: change.description ?? "",
      date: change.date,
      lines: change.lines.map((line) => ({
        ...("id" in line && line.id ? { id: line.id } : {}),
        amount: line.builderAmount,
        externalAmount: line.externalAmount,
        jobCostMethod: line.jobCostMethod.url,
      })) ?? [{ jobCostMethod: "", amount: 0 }],
      markups: change.markups.map((markup) => ({
        ...markup,
        jobCostMethod: markup.jobCostMethod.url,
      })),
    };

    requestAnimationFrame(() => {
      onOpenChangeForStep(enhancedChange, "edit-change")();
    });

    setSearchParams(
      (searchParams) => {
        searchParams.delete("change");
        searchParams.delete("location");
        return searchParams;
      },
      { replace: true }
    );
  }, [
    job.id,
    changeParam,
    enhancedData,
    setSearchParams,
    triggerGetChange,
    onOpenChangeForStep,
  ]);

  useEffect(() => {
    automaticallyViewChange();
  }, [automaticallyViewChange]);

  useEffect(() => {
    automaticallyCreateChange();
  }, [automaticallyCreateChange]);

  useEffect(() => {
    if (dialog.step === "view-changes") {
      setChange(undefined);
      setMarkup(undefined);
    }
  }, [dialog.step]);

  return (
    <ChangesDialogContext.Provider
      value={{
        onOpenChangeForStep,
        onSetChangeForStep,
        editMarkup,
        totalAmount,
        totalExternalAmount,
        budgetLine,
        isEdit: getIsEditDialog(dialog),
      }}
    >
      {getChangeInfo.isLoading && <Loader position="fixed" />}
      <Dialog
        size="auto"
        step={dialog.step}
        variant="multi-step-dialog"
        show={dialog.isVisible}
        onClose={dialog.hide}
      >
        <DialogStep name="view-changes">
          <DialogHeader>
            {job.display_name}{" "}
            {budgetLine?.jobCostMethod?.displayName
              ? `(${budgetLine?.jobCostMethod?.displayName})`
              : ""}
          </DialogHeader>
          <DialogContent>
            <Flex direction="column" gap="2xl" minWidth="800px">
              <Flex
                direction="row"
                align="center"
                justify="space-between"
                width="full"
                padding={["none", "none", "lg", "none"]}
              >
                <Text>Change log</Text>
                <Tooltip
                  message={
                    !canManageJobs
                      ? PERMISSION_STRINGS.NO_PERMISSION
                      : budgetReadOnly
                        ? "This job is linked to a BuilderTrend job. Manage changes in BuilderTrend"
                        : undefined
                  }
                  placement="left"
                >
                  <Button
                    onClick={() => dialog.setStep("create-change")}
                    disabled={!canManageJobs || budgetReadOnly}
                    variant="ghost"
                    color="primary"
                    size="sm"
                    data-testid="view-changes-dialog-add-change-button"
                  >
                    Add a change
                    <Icon name="plus" />
                  </Button>
                </Tooltip>
              </Flex>
            </Flex>
            <Flex gap="md" direction="column" height="450px" width="full">
              <Table
                id="changes-dialog-table"
                size="sm"
                loading={isFetching}
                data={enhancedData}
                columns={changeColumns}
                row={row}
                sort={sortAddon}
                maxHeight="450px"
                emptyState={{
                  title: budgetLine
                    ? "No changes have been added to this line item"
                    : "No changes have been added to this project",
                }}
                header={{
                  hide: enhancedData.length === 0,
                }}
                footer={{
                  hide: enhancedData.length === 0,
                }}
                pagination={{
                  page: pagination.page,
                  total: data?.count ?? 0,
                  perPage: pagination.perPage,
                  onChange: pagination.setPage,
                  onPerPageChange: (perPage) => {
                    pagination.setPage(0);
                    pagination.setPerPage(perPage);
                    analytics.track("perPageLimitChange", {
                      location: "changes-dialog-table",
                      limit: perPage,
                    });
                  },
                }}
                data-testid="transaction-table"
              />
            </Flex>
          </DialogContent>
        </DialogStep>
        <DialogStep
          name="create-change"
          onBack={mode === "create" ? undefined : onEnhancedBack}
        >
          {dialog.step === "create-change" && (
            <ChangesForm
              lines={createChangeLinesProps}
              dialog={dialog}
              onChange={setChange}
              onSubmit={onSubmitChange}
              {...change}
            />
          )}
        </DialogStep>
        <DialogStep name="edit-change" onBack={onEnhancedBack}>
          {dialog.step === "edit-change" && (
            <ChangesForm
              key={change?.id ?? "unknown"}
              dialog={dialog}
              onChange={setChange}
              onSubmit={onSubmitChange}
              {...change}
            />
          )}
        </DialogStep>
        <DialogStep name="add-markup" onBack={dialog.back}>
          <DialogHeader>Add change markup</DialogHeader>
          <DialogContent>
            <Flex direction="column" width="full" minWidth="631px">
              {dialog.step === "add-markup" && (
                <MarkupForm
                  lines={changeLinesForMarkups}
                  formId="add-markup"
                  lineReferenceKey="jobCostMethod.url"
                  onSubmitCost={onAddMarkupSubmitCost}
                  onValidityChange={setMarkupIsValid}
                  percentageFormat={MARKUP_PERCENTAGE_FORMAT}
                  onSubmitPercentage={onAddMarkupSubmitPercentage}
                />
              )}
            </Flex>
          </DialogContent>
          <DialogFooter>
            <Button block onClick={dialog.hide} color="neutral" variant="text">
              Cancel
            </Button>
            <Button
              block
              type="submit"
              form="add-markup"
              disabled={!markupIsValid}
            >
              Save
            </Button>
          </DialogFooter>
        </DialogStep>
        <DialogStep name="edit-markup" onBack={dialog.back}>
          <DialogHeader>Edit change markup</DialogHeader>
          <DialogContent>
            <Flex direction="column" width="full" minWidth="631px">
              {dialog.step === "edit-markup" && (
                <MarkupForm
                  tab={
                    markup?.markupType === "fixed_amount"
                      ? "cost"
                      : "percentage"
                  }
                  lines={changeLinesForMarkups}
                  formId="edit-markup"
                  onSubmitCost={onEditMarkupSubmitCost}
                  onValidityChange={setMarkupIsValid}
                  lineReferenceKey="jobCostMethod.url"
                  percentageFormat={MARKUP_PERCENTAGE_FORMAT}
                  costInitialValues={markupCostInitialValues}
                  onSubmitPercentage={onEditMarkupSubmitPercentage}
                  percentageInitialValues={markupPercentageInitialValues}
                />
              )}
            </Flex>
          </DialogContent>
          <DialogFooter>
            <Button block onClick={dialog.hide} color="neutral" variant="text">
              Cancel
            </Button>
            <Button
              block
              type="submit"
              form="edit-markup"
              disabled={!markupIsValid}
            >
              Save
            </Button>
          </DialogFooter>
        </DialogStep>
        <DialogStep name="delete-change" onBack={onEnhancedBack}>
          <DialogHeader>Delete change order?</DialogHeader>
          <DialogContent>
            <Flex width="455px" align="center" direction="column" padding="lg">
              Are you sure you want to delete this change order? <br /> This
              action cannot be undone.
            </Flex>
          </DialogContent>
          <DialogFooter>
            <Button
              block
              onClick={onEnhancedBack}
              color="neutral"
              variant="text"
            >
              Cancel
            </Button>
            <Button
              block
              variant="solid"
              color="error"
              onClick={onDeleteChangeOrder}
            >
              Delete change order
            </Button>
          </DialogFooter>
        </DialogStep>
      </Dialog>
    </ChangesDialogContext.Provider>
  );
};
