import React from "react";
import type { TableColumn } from "@adaptive/design-system";
import type { MarkupResponse } from "@api/budgets";
import { capitalize } from "@utils/capitalize";

import {
  CostsActualColumn,
  CostsBudgetColumn,
  CostsChangeColumn,
  CostsRemainingColumn,
  CostsRevisedBudgetColumn,
  CostsUndrawnColumn,
  CostsUnpaidColumn,
  EmptyColumn,
  PurchaseOrderAmountColumn,
  PurchaseOrderOpenBalanceColumn,
  RevenuesChangeColumn,
  RevenuesInvoicedAmountColumn,
  RevenuesInvoiceRemainingAmountColumn,
  RevenuesOwnersAmountColumn,
  RevenuesRevisedBudgetColumn,
  TransactionsColumn,
  TransactionsColumnControl,
  TransactionsCommentsColumn,
} from "./markups-components";

export type Markup = Omit<MarkupResponse, "budgetLine"> & {
  data?: Markup[];
  budgetLine: MarkupResponse["budgetLine"] & {
    remaining: number;
    budgetRemaining: number;
  };
};

type MarkupColumn = TableColumn<Markup>;

/**
 * Columns definition
 */
type GetMarkupColumnsProps = {
  viewMode: string;
  hasChanges: boolean;
  hasComments: boolean;
  hasCategories: boolean;
  hasExternalBudget: boolean;
  hasPurchaseOrders: boolean;
  hasExternalRevisedBudget: boolean;
};

export const getMarkupColumns = ({
  viewMode,
  hasChanges,
  hasComments,
  hasCategories,
  hasExternalBudget,
  hasPurchaseOrders,
  hasExternalRevisedBudget,
}: GetMarkupColumnsProps) => {
  const info: MarkupColumn = {
    id: "info",
    columns: [],
  };

  const nameColumn: MarkupColumn = {
    id: `name${capitalize(viewMode)}`,
    render: (row) => <TransactionsColumn {...row} />,
  };

  const categoryColumn: MarkupColumn = {
    id: `category${capitalize(viewMode)}`,
    render: (row) => <TransactionsColumnControl {...row} />,
  };

  const commentsColumn: MarkupColumn = {
    id: `comments${capitalize(viewMode)}`,
    render: (row) => <TransactionsCommentsColumn {...row.budgetLine} />,
  };

  if (["items", "quickbooks"].includes(viewMode)) {
    info.columns.push(nameColumn);
  }

  if (hasCategories) {
    info.columns.push(categoryColumn);
  }

  if (viewMode === "categories") {
    info.columns.push(nameColumn);
  }

  if (hasComments) {
    info.columns.push(commentsColumn);
  }

  const costs: MarkupColumn = {
    id: "costs",
    columns: [
      {
        id: "budget",
        render: (row) => <CostsBudgetColumn {...row} />,
      },
    ],
  };

  if (hasChanges) {
    costs.columns.push(
      {
        id: "changes",
        render: (row) => <CostsChangeColumn {...row} />,
      },
      {
        id: "revisedBudget",
        render: (row) => <CostsRevisedBudgetColumn {...row} />,
      }
    );
  }

  costs.columns.push({
    id: "actual",
    render: (row) =>
      row.budgetLine && <CostsActualColumn {...row.budgetLine} />,
  });

  costs.columns.push({
    id: "undrawnCosts",
    render: (row) =>
      row.budgetLine && <CostsUndrawnColumn {...row.budgetLine} />,
  });

  costs.columns.push({
    id: "unpaid",
    render: (row) =>
      row.budgetLine && <CostsUnpaidColumn {...row.budgetLine} />,
  });

  costs.columns.push({
    id: "costsRemaining",
    render: (row) =>
      row.budgetLine && <CostsRemainingColumn {...row.budgetLine} />,
  });

  const purchaseOrders: MarkupColumn = {
    id: "purchaseOrders",
    columns: [],
  };

  purchaseOrders.columns.push(
    {
      id: "purchaseOrderAmount",
      render: (row) => <PurchaseOrderAmountColumn {...row.budgetLine} />,
    },
    {
      id: "purchaseOrderOpenBalance",
      render: (row) => <PurchaseOrderOpenBalanceColumn {...row.budgetLine} />,
    }
  );

  const revenues: MarkupColumn = {
    id: "revenues",
    columns: [],
  };

  if (window.BUDGET_MARKUP_COLUMN_ENABLED) {
    revenues.columns.push({
      id: "markup",
      render: () => <EmptyColumn />,
    });
  }

  if (hasExternalBudget) {
    revenues.columns.push({
      id: "ownersBudget",
      render: (row) => <RevenuesOwnersAmountColumn {...row} />,
    });

    if (hasChanges && hasExternalRevisedBudget) {
      revenues.columns.push(
        {
          id: "revenuesChanges",
          render: (row) => <RevenuesChangeColumn {...row} />,
        },
        {
          id: "revenuesRevisedBudget",
          render: (row) => <RevenuesRevisedBudgetColumn {...row} />,
        }
      );
    }
  }

  revenues.columns.push(
    {
      id: "drawn",
      render: (row) => <RevenuesInvoicedAmountColumn {...row} />,
    },
    {
      id: "remaining",
      render: (row) => <RevenuesInvoiceRemainingAmountColumn {...row} />,
    }
  );

  if (!hasPurchaseOrders) return [info, costs, revenues];

  return [info, costs, purchaseOrders, revenues];
};
