import { toast } from "@adaptive/design-system";
import {
  createAction,
  createAsyncThunk,
  createSlice,
  type Dispatch,
} from "@reduxjs/toolkit";
import { type Draft } from "@reduxjs/toolkit";

import { getApi, invalidate5xx } from "../../utils/api";
import { delay } from "../../utils/delay";
import { type RootState } from "../types";
import { withPayloadType } from "../ui/slice";
import { selectRealm } from "../user/selectors-raw";

const AttachableErrorCodes = {
  NONE: 0,
  MISSING_REALM: 1,
  MISSING_FILE: 2,
  UNKNOWN: 3,
} as const;

export const api = getApi([invalidate5xx]);
type ErrorKeys = keyof typeof AttachableErrorCodes;

type ErrorValues = (typeof AttachableErrorCodes)[ErrorKeys];

export type AttachmentStates = Record<
  string,
  "done" | "discarded" | "failed" | "in-progress" | "waiting"
>;

type AttachableState = {
  errorCode: ErrorValues;
  statuses: Record<string, any>;
  response: Record<string, any>;
};

export type SaveEndpointOptions = {
  document: "receipt" | "bill" | "document";
  save: true;
};

type NoSaveEndpointOptions = {
  parent: string;
  save: false;
};

export type AttachableOptions = SaveEndpointOptions | NoSaveEndpointOptions;

export type OnAttachableStarted = (info: { resolverId: string }) => void;
export type OnAttachableUpload = (result: {
  resolverId: string;
  data: any;
}) => void;
export type OnAttachableComplete = (result: {
  resolverId: string;
  data: any;
}) => void;

export interface AttachableLifeCycleHooks {
  onItemUpload?: OnAttachableUpload;
  onItemStarted?: OnAttachableStarted;
  onItemComplete: OnAttachableComplete;
}

export interface AttachablePayload extends AttachableLifeCycleHooks {
  attachableOptions: AttachableOptions;
  file: File;
  onRequestId: (id: string) => void;
  onStarted?: () => void;
}

type ThunkMeta<T extends "pending" | "fulfilled" | "rejected"> = {
  requestId: string;
  requestStatus: T;
  arg: unknown;
};

type AttachableThunkConfig = {
  state: RootState;
  rejectValue: ErrorValues | Error;
};

interface SubmitFileOptions {
  attachableOptions: AttachableOptions;
  dispatch: Dispatch;
  file: File;
  realm: string;
  onUpload: (data: any) => void;
}

const submitFile = async ({
  attachableOptions,
  dispatch,
  file,
  realm,
  onUpload,
}: SubmitFileOptions) => {
  const payload = new FormData();
  payload.set("document", file);
  if (realm) payload.set("realm", realm);

  const url = new URL(window.location.origin);
  url.pathname = "/api/attachables/";

  const { save } = attachableOptions;

  if (!save) {
    payload.set("parent", attachableOptions.parent);
  } else {
    // only set 'save_TYPE' if we're not creating records via OCR
    if (attachableOptions.document === "receipt") {
      url.searchParams.set(`save_expense`, save.toString());
    } else {
      url.searchParams.set(
        `save_${attachableOptions.document}`,
        save.toString()
      );
    }
  }

  const config = { headers: { "content-type": "multipart/form-data" } };
  const response = await api.post(url.toString(), payload, config);

  if (response.status === 201) {
    onUpload?.(response.data);
    toast.success(`${file.name} uploaded`);

    if (!save) {
      return response;
    }

    let syncStatus = response.data.expense.file_sync_status;
    const pollTarget = response.data.expense.url;

    const pollApi = getApi([invalidate5xx]);
    pollApi.interceptors.response.use(async (resp) => {
      //TODO if we returned 202 we wouldn't need to parse the response
      //to consider status at all
      if (resp.status === 200) {
        let pollResponse = await api.get(pollTarget);
        syncStatus = pollResponse.data.file_sync_status;
        while (["deferred", "requested", "in-progress"].includes(syncStatus)) {
          await delay(3000);
          pollResponse = await api.get(pollTarget);
          syncStatus = pollResponse.data.file_sync_status;
          dispatch(setAttachmentStatus({ [file.name]: syncStatus }));
        }

        return pollResponse;
      }

      return resp;
    });

    return pollApi.get(pollTarget);
  }
};

export const submitAttachable = createAsyncThunk<
  void,
  AttachablePayload,
  AttachableThunkConfig
>(
  "attachables/submit",
  async (
    {
      file,
      onItemComplete,
      onItemStarted,
      onRequestId,
      onItemUpload,
      attachableOptions,
    },
    { dispatch, getState, rejectWithValue, requestId }
  ) => {
    onRequestId(requestId);
    const realmObj = selectRealm(getState());

    if (!realmObj) {
      rejectWithValue(AttachableErrorCodes.MISSING_REALM);
      return;
    }

    const realm = realmObj.url;

    const submit = async (file: File) => {
      return await submitFile({
        dispatch,
        attachableOptions,
        file,
        realm,
        onUpload: (data) => onItemUpload?.({ resolverId: requestId, data }),
      });
    };

    onItemStarted?.({ resolverId: requestId });
    try {
      const response = await submit(file);
      onItemComplete({ resolverId: requestId, data: response?.data });
    } catch (e: any) {
      rejectWithValue(e);
    }
  }
);

const attachableState: AttachableState = {
  errorCode: 0,
  statuses: {},
  response: {},
};

export const setAttachmentStatus = createAction(
  "attachable/attachment/setState",
  withPayloadType<Partial<AttachmentStates>>()
);

const slice = createSlice({
  name: "attachable",
  initialState: attachableState,
  reducers: {},
  extraReducers: (builder) => {
    const updateStatus = (
      state: Draft<AttachableState>,
      meta: ThunkMeta<"fulfilled" | "pending" | "rejected">
    ) => {
      const { requestId, requestStatus, ...rest } = meta;
      state.statuses[requestId] = {
        ...rest,
        status: requestStatus,
      };
    };

    builder.addCase(submitAttachable.pending, (state, action) => {
      updateStatus(state, action.meta);
    });

    builder.addCase(submitAttachable.rejected, (state, action) => {
      updateStatus(state, action.meta);

      if (typeof action.payload === "number") {
        state.errorCode = action.payload;
      } else {
        state.errorCode = AttachableErrorCodes.UNKNOWN;
      }
      toast.error(`Error processing attachment: ${state.errorCode}`);
    });

    builder.addCase(submitAttachable.fulfilled, (state, { meta }) => {
      updateStatus(state, meta);
    });
  },
});

export const { reducer } = slice;
