import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isEqual } from "@adaptive/design-system/utils";
import { useClientSettings } from "@store/user";
import { invertSign } from "@utils/invert-sign";
import { sum } from "@utils/sum";
import { sumBy } from "@utils/sumBy";

import { type VendorCreditPaymentOption } from "../../api/types";
import { type DynamicBalanceVendorCredit } from "../../types";
import { applyVendorCreditsForBalanceAmount } from "../../utils";

export type UseAppliedVendorCreditsProps = {
  vendorCredits: VendorCreditPaymentOption[];
  billAmount: string | number;
  customerIds?: Set<string>;
  onAutoApply?: ({
    appliedVendorCreditsTotalAmount,
  }: { appliedVendorCreditsTotalAmount: number }) => void;
};

export const useAppliedVendorCredits = ({
  vendorCredits,
  billAmount,
  onAutoApply,
  customerIds,
}: UseAppliedVendorCreditsProps) => {
  const [appliedVendorCredits, setAppliedVendorCredits] =
    useState<DynamicBalanceVendorCredit[]>();
  const billAmountRef = useRef(billAmount);
  const customerIdsRef = useRef<Set<string>>(customerIds || new Set());
  const hasManualAppliedVendorCredits = useRef(false);

  const { vendorCreditsEnabled } = useClientSettings();

  useEffect(() => {
    const isBillAmountChanged = billAmountRef.current !== billAmount;
    const isCustomerIdsChanged =
      customerIds && !isEqual(customerIdsRef.current, customerIds);
    if (!vendorCreditsEnabled) {
      return;
    }
    if (hasManualAppliedVendorCredits.current) {
      // If user has manually changed the applied vendor credits, don't change it
      return;
    }
    if (
      (appliedVendorCredits !== undefined || !vendorCredits.length) &&
      !isBillAmountChanged &&
      !isCustomerIdsChanged
    ) {
      // Only apply vendor credits if there aren't any already applied
      // Or bill amount changes or customer ids changes
      return;
    }

    const availableCreditsWithAppliedAmount = vendorCredits.map(
      (vendorCredit) => {
        const appliedCredit = appliedVendorCredits?.find(
          (appliedCredit) => appliedCredit.id === vendorCredit.id
        );

        return {
          ...vendorCredit,
          appliedAmount: appliedCredit?.appliedAmount || 0,
        };
      }
    );

    const { appliedVendorCredits: newAppliedVendorCredits } =
      applyVendorCreditsForBalanceAmount({
        vendorCredits: availableCreditsWithAppliedAmount,
        balanceAmount: billAmount,
        customerIds,
      });

    billAmountRef.current = billAmount;
    customerIdsRef.current = customerIds || new Set();
    setAppliedVendorCredits(
      newAppliedVendorCredits.filter((vc) => !!vc.appliedAmount)
    );
    onAutoApply?.({
      appliedVendorCreditsTotalAmount: sumBy(
        newAppliedVendorCredits,
        "appliedAmount"
      ),
    });
  }, [
    appliedVendorCredits,
    billAmount,
    customerIds,
    onAutoApply,
    vendorCredits,
    vendorCreditsEnabled,
  ]);

  const onExternalAppliedVendorCredits = useCallback(
    (newAppliedVendorCredits: DynamicBalanceVendorCredit[]) => {
      hasManualAppliedVendorCredits.current = true;
      setAppliedVendorCredits(newAppliedVendorCredits);
    },
    [setAppliedVendorCredits]
  );

  const appliedVendorCreditsTotalAmount = useMemo(() => {
    if (!appliedVendorCredits || !appliedVendorCredits.length) {
      return 0;
    }
    return sumBy(appliedVendorCredits, "appliedAmount");
  }, [appliedVendorCredits]);

  const vendorCreditsRemainingAmount = useMemo(() => {
    return sum(
      invertSign(sumBy(vendorCredits || [], "openBalance")),
      appliedVendorCreditsTotalAmount
    );
  }, [appliedVendorCreditsTotalAmount, vendorCredits]);

  return {
    appliedVendorCredits: appliedVendorCredits || [],
    setAppliedVendorCredits: onExternalAppliedVendorCredits,
    appliedVendorCreditsTotalAmount,
    vendorCreditsRemainingAmount,
  };
};
