import { parseRefinementIdFromUrl } from "@src/shared/utils/parse-refinement-id-from-url";

import { type RenderLienWaiverTemplatePDFResponse } from "../api/types";
import {
  BILL_FIELD_LABELS,
  JOB_FIELD_LABELS,
  VENDOR_FIELD_LABELS,
} from "../constants";
import type { MissingFields, MissingLienWaiverFieldsInfo } from "../types";

export const formatMissingFields = (missingFields?: string[]) => {
  if (!missingFields) return;
  return {
    customer: {
      missingFields: missingFields.filter((field) =>
        JOB_FIELD_LABELS.includes(field)
      ),
    },
    vendor: {
      missingFields: missingFields.filter((field) =>
        VENDOR_FIELD_LABELS.includes(field)
      ),
    },
    bill: {
      missingFields: missingFields.filter((field) =>
        BILL_FIELD_LABELS.includes(field)
      ),
    },
  };
};

type FormatMissingFieldsPayload = {
  data?: RenderLienWaiverTemplatePDFResponse;
  customer: string;
  vendor: string;
  previousMissingFields: MissingFields;
};

export const formatMissingFieldsToPayload = ({
  data,
  customer,
  vendor,
  previousMissingFields,
}: FormatMissingFieldsPayload) => {
  const formattedMissingFields = formatMissingFields(
    data?.missingFields || undefined
  );

  if (formattedMissingFields?.customer.missingFields.length) {
    previousMissingFields.customers = previousMissingFields.customers || [];
    previousMissingFields.customers?.push({
      ...formattedMissingFields.customer,
      url: customer,
    });
    previousMissingFields.isValid = false;
  }
  if (
    formattedMissingFields?.bill?.missingFields.length &&
    !previousMissingFields.bill
  ) {
    previousMissingFields.bill = {
      ...formattedMissingFields.bill,
    };
    previousMissingFields.isValid = false;
  }

  if (
    formattedMissingFields?.vendor?.missingFields.length &&
    !previousMissingFields.vendor
  ) {
    previousMissingFields.vendor = {
      ...formattedMissingFields.vendor,
      url: vendor,
    };
    previousMissingFields.isValid = false;
  }

  return previousMissingFields;
};

type FormatLienWaiverMissingFieldsPayload = {
  rawMissingFields: string[];
  customer: {
    id: string;
    url: string;
    displayName: string;
  };
  vendor: {
    id: string;
    url: string;
    displayName: string;
  };
  bill: {
    id: string;
    docNumber: string;
  };
};

export const parseLienWaiverMissingFields = ({
  rawMissingFields,
  customer,
  vendor,
  bill,
}: FormatLienWaiverMissingFieldsPayload) => {
  const missingFields: MissingFields = { isValid: true };

  const missingFieldsInfo: Record<string, MissingLienWaiverFieldsInfo> = {};
  const formattedMissingFields = formatMissingFieldsToPayload({
    data: {
      missingFields: rawMissingFields,
    },
    customer: customer.url,
    vendor: vendor.url,
    previousMissingFields: missingFields,
  });

  if (formattedMissingFields.customers) {
    formattedMissingFields.customers.forEach((item) => {
      const customerId = parseRefinementIdFromUrl(customer.url!);
      missingFieldsInfo[customer.url!] = {
        ...item,
        displayName: customer.displayName,
        frontendUrl: `/jobs/${customerId}`,
        id: customerId,
        objectType: "customer",
      };
    });
  }
  if (formattedMissingFields.vendor) {
    missingFieldsInfo[formattedMissingFields.vendor.url!] = {
      ...formattedMissingFields.vendor,
      displayName: vendor.displayName,
      frontendUrl: `/vendors/${vendor.id}`,
      id: vendor.id.toString(),
      objectType: "vendor",
    };
  }
  if (formattedMissingFields.bill) {
    missingFieldsInfo[formattedMissingFields.bill.url!] = {
      ...formattedMissingFields.bill,
      displayName: `#${bill.docNumber}`,
      id: bill.id,
      objectType: "bill",
      frontendUrl: undefined,
    };
  }

  return missingFieldsInfo;
};
