import React, { type ReactNode, useCallback, useEffect, useState } from "react";
import { Navigate } from "react-router";
import { Loader } from "@adaptive/design-system";
import { type BasePermissions, useUserInfo } from "@store/user";

type Props = {
  redirectPath?: string;
  somePermissionsOf?: BasePermissions[];
  allPermissionsOf?: BasePermissions[];
  children: ReactNode;
  validate?: () => boolean | Promise<boolean>;
};

export const ProtectedRoute: React.FC<Props> = ({
  children,
  redirectPath = "/",
  somePermissionsOf,
  allPermissionsOf,
  validate,
}) => {
  const [hasPermission, setHasPermission] = useState<boolean | undefined>();

  const { hasAllPermissions, hasSomePermission } = useUserInfo();

  const checkPermission = useCallback(async () => {
    if (allPermissionsOf) {
      setHasPermission(hasAllPermissions(allPermissionsOf));
    } else if (somePermissionsOf) {
      setHasPermission(hasSomePermission(somePermissionsOf));
    } else if (validate) {
      setHasPermission(await validate());
    } else {
      setHasPermission(false);
    }
  }, [
    allPermissionsOf,
    hasAllPermissions,
    hasSomePermission,
    somePermissionsOf,
    validate,
  ]);

  useEffect(() => {
    checkPermission();
  }, [checkPermission]);

  if (hasPermission === undefined) return <Loader position="fixed" />;

  return hasPermission ? (
    <>{children}</>
  ) : (
    <Navigate to={redirectPath} replace />
  );
};
