import React, { memo } from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Icon,
} from "@adaptive/design-system";
import { useClientSettings } from "@store/user";

export const CardsLinkedAddCardButton = memo(() => {
  const { fidelEnabled } = useClientSettings();

  if (fidelEnabled) {
    return (
      <Dropdown>
        <DropdownTrigger as={Button} data-testid="cards-linked-add-card-button">
          <Icon name="plus" />
          Add a card
        </DropdownTrigger>
        <DropdownList>
          <DropdownItem
            htmlFor="cards-linked-add-plaid-card-button"
            data-testid="cards-linked-add-plaid-card-button"
          >
            American Express and Mastercard
          </DropdownItem>
          <DropdownItem
            htmlFor="cards-linked-add-fidel-card-button"
            data-testid="cards-linked-add-fidel-card-button"
          >
            Visa
          </DropdownItem>
        </DropdownList>
      </Dropdown>
    );
  }

  return (
    <Button
      as="label"
      htmlFor="cards-linked-add-plaid-card-button"
      data-testid="cards-linked-add-plaid-card-button"
    >
      <Icon name="plus" />
      Add a card
    </Button>
  );
});

CardsLinkedAddCardButton.displayName = "CardsLinkedAddCardButton";
