import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { getPurchaseOrders } from "../api/purchase-orders";

const initialState = {
  openPurchaseOrders: {
    loading: "idle",
    purchaseOrders: [],
    total: 0,
    needsRefresh: true,
    controller: undefined,
  },
  closedPurchaseOrders: {
    loading: "idle",
    purchaseOrders: [],
    total: 0,
    needsRefresh: true,
    controller: undefined,
  },
};

const updatePurchaseOrderListHelper = (purchaseOrderList, action) => {
  purchaseOrderList.loading = "loaded";
  purchaseOrderList.controller = undefined;
  purchaseOrderList.purchaseOrders = action.payload?.results ?? [];
  purchaseOrderList.total = action.payload?.count ?? 0;
  purchaseOrderList.needsRefresh = false;
};

const purchaseOrderListSlice = createSlice({
  name: "purchaseOrderList",
  initialState,
  reducers: {
    openPurchaseOrdersLoading: (state, action) => {
      state.openPurchaseOrders.isError = false;
      state.openPurchaseOrders.loading = "pending";
      state.openPurchaseOrders.controller?.abort?.();
      state.openPurchaseOrders.controller = action.payload;
    },
    openPurchaseOrdersErrored: (state) => {
      state.openPurchaseOrders.loading = "loaded";
      state.openPurchaseOrders.isError = true;
    },
    openPurchaseOrdersReceived: (state, action) => {
      updatePurchaseOrderListHelper(state.openPurchaseOrders, action);
    },
    closedPurchaseOrdersLoading: (state, action) => {
      state.closedPurchaseOrders.isError = false;
      state.closedPurchaseOrders.loading = "pending";
      state.closedPurchaseOrders.controller?.abort?.();
      state.closedPurchaseOrders.controller = action.payload;
    },
    closedPurchaseOrdersErrored: (state) => {
      state.closedPurchaseOrders.loading = "loaded";
      state.closedPurchaseOrders.isError = true;
    },
    closedPurchaseOrdersReceived: (state, action) => {
      updatePurchaseOrderListHelper(state.closedPurchaseOrders, action);
    },
    setNeedsRefresh: (state, action) => {
      const { status, refresh } = action.payload;
      if (status === "open") {
        state.openPurchaseOrders.needsRefresh = refresh;
      } else if (status === "closed") {
        state.closedPurchaseOrders.needsRefresh = refresh;
      }
    },
    updatePurchaseOrderList: (state) => {
      state.openPurchaseOrders.needsRefresh = true;
      state.closedPurchaseOrders.needsRefresh = true;
    },
  },
});

export const {
  openPurchaseOrdersLoading,
  openPurchaseOrdersReceived,
  openPurchaseOrdersErrored,
  closedPurchaseOrdersLoading,
  closedPurchaseOrdersErrored,
  closedPurchaseOrdersReceived,
  updatePurchaseOrderList,
  setNeedsRefresh,
} = purchaseOrderListSlice.actions;

/**
 * @todo replace it with abortController
 */
const handleAxiosCancelException = (e) => {
  if (!axios.isCancel(e)) {
    throw e;
  } else {
    return undefined;
  }
};

const fetchPurchaseOrders = async ({ status, filters, signal }) =>
  getPurchaseOrders({
    signal,
    filters: [{ dataIndex: "po_status", value: status }, ...filters],
  }).catch(handleAxiosCancelException);

export const fetchOpenPurchaseOrders = (filters) => async (dispatch) => {
  const controller = new AbortController();
  dispatch(openPurchaseOrdersLoading(controller));

  try {
    const data = await fetchPurchaseOrders({
      status: "Open",
      signal: controller.signal,
      filters,
    });
    dispatch(openPurchaseOrdersReceived(data));
  } catch (e) {
    dispatch(openPurchaseOrdersErrored());
    throw e;
  }
};

export const fetchClosedPurchaseOrders = (filters) => async (dispatch) => {
  const controller = new AbortController();
  dispatch(closedPurchaseOrdersLoading(controller));

  try {
    const data = await fetchPurchaseOrders({
      status: "Closed",
      signal: controller.signal,
      filters,
    });

    dispatch(closedPurchaseOrdersReceived(data));
  } catch (e) {
    dispatch(closedPurchaseOrdersErrored());
    throw e;
  }
};

export const { reducer } = purchaseOrderListSlice;
