export const ICONS = [
  "chevron-left",
  "gear",
  "chevron-right",
  "file-lines",
  "arrows-rotate",
  "receipt",
  "user-helmet-safety",
  "cart-shopping",
  "chart-line-up",
  "hammer",
  "chevrons-left",
  "download",
  "upload",
  "chevrons-right",
  "envelope-open",
  "envelope",
  "phone",
  "lock",
  "at",
  "bell",
  "unlock",
  "merge",
  "arrow-turn-right",
  "loader",
  "x",
  "chevron-up",
  "square-x",
  "chevron-down",
  "circle-question",
  "pen",
  "clock",
  "user",
  "trash",
  "ellipsis-vertical",
  "info-circle",
  "credit-card",
  "folder-open",
  "search",
  "plus",
  "arrow-up-from-bracket",
  "circle",
  "percent",
  "dollar-sign",
  "exclamation-triangle",
  "copy",
  "check",
  "arrow-left",
  "up",
  "command",
  "arrow-right",
  "grip-dots-vertical",
  "arrow-down-to-bracket",
  "image",
  "link",
  "angles-up-down",
  "exclamation-circle",
  "check-circle",
  "calendar",
  "arrow-up-wide-short",
  "arrow-down-wide-short",
  "arrow-down-a-z",
  "arrow-up-a-z",
  "caret-up",
  "caret-right",
  "circle-x",
  "xmark",
  "caret-down",
  "file-plus",
  "thumbs-up",
  "link-slash",
  "thumbs-down",
  "message",
  "archive",
  "file-minus",
  "file-invoice",
  "times",
  "print",
  "reply",
  "arrow-up-right-from-square",
  "paper-plane-top",
  "signature",
  "buildings",
  "user-circle",
  "align-left",
  "align-center",
  "align-right",
  "align-justify",
  "bold",
  "italic",
  "underline",
  "list-ul",
  "list-ol",
  "eye",
  "eye-slash",
  "file",
  "file-image",
  "paperclip",
  "refresh",
  "star",
  "bolt",
  "split",
  "arrows-from-line",
  "arrows-to-line",
  "thumbtack",
  "thumbtack-slash",
  "bank",
  "bars-filter",
  "backward",
  "ellipsis",
  "share",
  "table",
  "asterisk",
] as const;

export type IconName = (typeof ICONS)[number];
