import React, {
  type ComponentProps,
  type KeyboardEventHandler,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";
import cn from "clsx";

import { useEvent } from "../../hooks/use-event";
import { copyTextToClipboard } from "../../utils/copy-text-to-clipboard";
import { Icon, type IconProps } from "../icon";
import type { IconName } from "../icon/constants";
import { Tooltip } from "../tooltip";

import styles from "./copy.module.css";

type Props = Pick<IconProps, "size"> & {
  value: string;
  color?: ComponentProps<typeof Icon>["color"];
  onCopy?: (value: Props["value"]) => void;
  className?: string;
  "data-testid"?: string;
};

type State = "idle" | "succeeded" | "failed";

const VARIANT: Record<State, { icon: IconName; message: string }> = {
  idle: { icon: "copy", message: "Click to copy" },
  failed: { icon: "exclamation-triangle", message: "Failed to copy" },
  succeeded: { icon: "check-circle", message: "Copied to clipboard!" },
};

const CLEAR_TIMEOUT = 3000;

const Copy = ({ color, size, value, onCopy, className, ...props }: Props) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>(undefined);

  const [status, setStatus] = useState<State>("idle");

  const [isVisible, setIsVisible] = useState(false);

  const copy = useEvent(async () => {
    try {
      await copyTextToClipboard(value);
      setStatus("succeeded");
      onCopy?.(value);
      timeoutRef.current = setTimeout(() => {
        setStatus("idle");
        setIsVisible(false);
      }, CLEAR_TIMEOUT);
    } catch {
      setStatus("failed");
    }
  });

  const show = useEvent(() => {
    setIsVisible(true);
  });

  const reset = useEvent(() => {
    if (status === "succeeded") return;

    setStatus("idle");
    setIsVisible(false);
  });

  const onKeyDown = useEvent<KeyboardEventHandler>((e) => {
    if (["Enter", " "].includes(e.key)) copy();
  });

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  return (
    <Tooltip
      as={Icon}
      size={size}
      role="button"
      show={isVisible}
      name={VARIANT[status].icon}
      color={color}
      onBlur={reset}
      onFocus={show}
      message={VARIANT[status].message}
      onClick={copy}
      tabIndex={0}
      onKeyDown={onKeyDown}
      className={cn(className, styles["copy"])}
      onMouseOut={reset}
      onMouseOver={show}
      {...props}
    />
  );
};

const MemoizedCopy = memo(Copy);

export { MemoizedCopy as Copy };
