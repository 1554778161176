import React, { useEffect, useMemo } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogStep,
  Flex,
  Loader,
  Table,
  type TableColumn,
  Text,
  Textarea,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import {
  createFormContext,
  useDeepMemo,
  useEvent,
  type UseMultiStepDialogReturn,
} from "@adaptive/design-system/hooks";
import {
  handleErrors,
  parseCustomErrors,
  transformErrorToCustomError,
} from "@api/handle-errors";
import { summarizeResults } from "@utils/all-settled";
import { idSchema } from "@utils/schema";
import { documentsRequestsApi } from "@vendors/api";
import { documentType } from "@vendors/api";
import { VendorEmailComboBox } from "@vendors/components";
import type { DocumentType } from "@vendors/types";
import { type AnyZodObject, z } from "zod";

import {
  SELECTABLE_DOC_TYPE_OPTIONS,
  SELECTABLE_DOC_TYPE_OPTIONS_FORMATTED,
  STRINGS,
} from ".";

const partialVendorSchema = z.object({
  id: idSchema,
  message: z.string().nullish(),
  displayName: z.string(),
});

const partialSchema = z.object({
  documentTypes: z
    .record(documentType, z.boolean())
    .refine((value) => Object.values(value).some(Boolean), {
      message: "At least one document type should be selected",
    }),
  applyMessageIndex: z.number().nullish(),
  includeCustomMessage: z.boolean(),
});

const selectDocumentsSchema = partialSchema.extend({
  step: z.literal("select-documents"),
  vendors: z.array(partialVendorSchema.extend({ email: z.string() })),
});

const setEmailSchema = partialSchema.extend({
  step: z.enum(["set-email", "set-email-multiple"]),
  vendors: z.array(
    partialVendorSchema.extend({
      email: z.string().email("An email address is required"),
    })
  ),
});

const schema = z.discriminatedUnion("step", [
  setEmailSchema,
  selectDocumentsSchema,
]);

export type DocumentSelectDialogStep =
  | "set-email"
  | "select-documents"
  | "set-email-multiple";

type Fields = z.input<typeof schema>;

const { useForm, FormConsumer, FormProvider } = createFormContext<Fields>();

export type DocumentSelectDialogProps = {
  dialog: UseMultiStepDialogReturn<DocumentSelectDialogStep>;
  multiple: boolean;
  onSubmit?: () => void;
  initialValues?: Partial<Fields>;
};

const getColumns = (
  includeCustomMessage: boolean,
  dialog: UseMultiStepDialogReturn<DocumentSelectDialogStep>
) => {
  const columns: TableColumn<Fields["vendors"][number]>[] = [
    {
      id: "displayName",
      name: "Vendor name",
      width: "fill",
      align: includeCustomMessage ? "start" : "center",
      render: (row) => <Text size="sm">{row.displayName}</Text>,
      minWidth: "content",
    },
    {
      id: "email",
      name: "Email",
      width: 280,
      align: includeCustomMessage ? "start" : "center",
      render: (row, index) => (
        <FormConsumer>
          {(form) => (
            <VendorEmailComboBox
              key={row.id}
              size="sm"
              required
              disabled={form.isSubmitting}
              messageVariant="hidden"
              vendorId={form.values.vendors[index]?.id}
              portal={true}
              {...form.register(`vendors.${index}.email`)}
            />
          )}
        </FormConsumer>
      ),
    },
  ];

  if (includeCustomMessage) {
    columns.push({
      id: "message",
      name: "Message",
      align: includeCustomMessage ? "start" : "center",
      width: 380,
      render: (row, index) => (
        <FormConsumer>
          {(form) => (
            <Flex gap="md" direction="column">
              <Textarea
                minHeight={70}
                maxHeight={70}
                disabled={form.isSubmitting}
                placeholder="Optional message included in the request email"
                messageVariant="hidden"
                {...form.register({
                  name: `vendors.${index}.message`,
                  type: "string",
                  onChange: (value) => {
                    if (form.values.applyMessageIndex === null) return;

                    if (form.values.applyMessageIndex !== index) {
                      return form.setValue("applyMessageIndex", null);
                    }

                    form.values.vendors.map((_, i) => {
                      if (i === index) return;

                      form.setValue(`vendors.${i}.message`, value);
                    });
                  },
                })}
              />
              <Checkbox
                label="Apply to all requests"
                checked={form.values.applyMessageIndex !== null}
                disabled={
                  (form.values.applyMessageIndex !== null &&
                    form.values.applyMessageIndex !== index) ||
                  form.isSubmitting
                }
                onChange={(checked) => {
                  form.setValue("applyMessageIndex", checked ? index : null);

                  if (checked) {
                    form.values.vendors.map((_, i) =>
                      form.setValue(
                        `vendors.${i}.message`,
                        form.values.vendors[index].message
                      )
                    );
                  }
                }}
              />
            </Flex>
          )}
        </FormConsumer>
      ),
    });
  }

  columns.push({
    id: "action",
    name: "Actions",
    align: includeCustomMessage ? "start" : "center",
    width: 115,
    render: (_, index) => (
      <FormConsumer>
        {(form) => (
          <Button
            size="sm"
            color="neutral"
            variant="ghost"
            disabled={form.isSubmitting}
            onClick={() => {
              const lastElement = form.values.vendors.length === 1;
              form.remove("vendors", index);
              if (lastElement) dialog.hide();
            }}
            aria-label="Remove email"
            data-testid="document-email-remove-button"
          >
            Remove
          </Button>
        )}
      </FormConsumer>
    ),
  });

  return columns;
};

export const DocumentSelectDialog = ({
  dialog,
  multiple,
  onSubmit,
  initialValues = {},
}: DocumentSelectDialogProps) => {
  const enhancedInitialValues = useMemo(
    () => ({
      step: "select-documents" as DocumentSelectDialogStep,
      vendors: initialValues.vendors?.forEach((e) => (e.message = "")) || [],
      documentTypes: SELECTABLE_DOC_TYPE_OPTIONS.reduce(
        (acc, option) => ({
          ...acc,
          [option.value]: false,
        }),
        {} as Fields["documentTypes"]
      ),
      applyMessageIndex: null,
      includeCustomMessage: false,
      ...initialValues,
    }),
    [initialValues]
  );

  const form = useForm({
    /**
     * @todo figure out how to adjust this type issue without casting it
     */
    schema: schema as unknown as AnyZodObject,
    async onSubmit({ vendors, documentTypes }) {
      if (dialog.step === "select-documents") {
        if (multiple) {
          return dialog.setStep("set-email-multiple");
        } else {
          return dialog.setStep("set-email");
        }
      }

      const enhancedDocumentTypes = Object.entries(documentTypes).reduce(
        (acc, [key, value]) => (value ? [...acc, key as DocumentType] : acc),
        [] as DocumentType[]
      );

      const requests = vendors.map(async (vendor) => {
        try {
          await documentsRequestsApi.postVendorDocRequest({
            vendor_id: vendor.id,
            vendor_email: vendor.email,
            message: vendor.message,
            document_types: enhancedDocumentTypes,
          });
        } catch (error) {
          throw transformErrorToCustomError({
            error,
            extra: { displayName: vendor.displayName },
            render: (message) => `${message} on the following vendors:`,
          });
        }
      });

      const { success, errorResponses } = summarizeResults(
        await Promise.allSettled(requests)
      );

      const enhancedErrors = parseCustomErrors<{ displayName: string }>({
        errors: errorResponses,
        render: ({ isFirst, message, displayName }) =>
          `${message}${isFirst ? "" : ","} ${displayName}`,
      });

      if (enhancedErrors.length) {
        enhancedErrors.forEach((error) =>
          handleErrors(error, { maxWidth: 800, truncate: 2 })
        );
      }

      if (success) {
        toast.success(
          `${multiple ? `${success} document` : "Document"} request${
            success > 1 ? "s" : ""
          } sent!`
        );
      }

      onSubmit?.();

      await dialog.hide();
    },
    initialValues: enhancedInitialValues,
  });

  const { reset, setValue } = form;

  const onChangeIncludeCustomMessage = useEvent((checked: boolean) => {
    if (checked) return;

    form.values.vendors.map((_, index) =>
      form.setValue(`vendors.${index}.message`, "")
    );
    form.setValue(`applyMessageIndex`, null);
  });

  const data = useDeepMemo(() => form.values.vendors, [form.values.vendors]);

  const columns = useMemo(
    () => getColumns(form.values.includeCustomMessage, dialog),
    [form.values.includeCustomMessage, dialog]
  );

  useEffect(() => {
    setValue("step", dialog.step, { shouldValidate: true });
  }, [setValue, dialog.step]);

  useEffect(() => {
    reset();
  }, [reset, dialog.isVisible]);

  return (
    <Dialog
      size="auto"
      step={dialog.step}
      show={dialog.isVisible}
      variant="multi-step-dialog"
      onClose={dialog.hide}
    >
      <DialogStep name="select-documents">
        <DialogHeader>
          Which documents would you like to request from the vendor?
        </DialogHeader>
        <DialogContent>
          <Flex
            as="form"
            gap="xl"
            justify="space-between"
            minWidth="480px"
            direction="row"
            {...form.props}
          >
            {SELECTABLE_DOC_TYPE_OPTIONS_FORMATTED.map((types, typesIndex) => (
              <Flex
                key={typesIndex}
                grow
                gap="lg"
                width="full"
                direction="column"
              >
                {types.map((type) => (
                  <Checkbox
                    key={type.value}
                    label={type.label}
                    {...form.register({
                      name: `documentTypes.${type.value}`,
                      type: "boolean",
                    })}
                  />
                ))}
              </Flex>
            ))}
          </Flex>
        </DialogContent>
        <DialogFooter>
          <Button block color="neutral" variant="text" onClick={dialog.hide}>
            Cancel
          </Button>
          <Button
            type="submit"
            form={form.id}
            block
            disabled={form.isSubmitting || !form.isValid}
          >
            {form.isSubmitting ? (
              <Loader />
            ) : (
              STRINGS.REQUEST_DOCUMENT_TYPES_LABEL
            )}
          </Button>
        </DialogFooter>
      </DialogStep>
      <DialogStep name="set-email" onBack={dialog.back}>
        <DialogHeader>
          <Flex direction="column">
            <Text size="xl" weight="bold">
              Confirm details of request
            </Text>
            <Text size="md">{form.values.vendors?.[0]?.displayName}</Text>
          </Flex>
        </DialogHeader>
        <DialogContent>
          <Flex
            as="form"
            gap="sm"
            justify="center"
            minWidth="480px"
            direction="column"
            {...form.props}
          >
            <VendorEmailComboBox
              label="Email"
              vendorId={form.values.vendors?.[0]?.id}
              required
              {...form.register("vendors.0.email")}
            />
            <Textarea
              label="Message"
              minHeight={100}
              maxHeight={300}
              placeholder="Optional message included in the request email"
              messageVariant="hidden"
              {...form.register(`vendors.0.message`)}
            />
          </Flex>
        </DialogContent>
        <DialogFooter>
          <Flex width="full">
            <Button block color="neutral" variant="text" onClick={dialog.back}>
              Back
            </Button>
          </Flex>
          <Tooltip
            as={Flex}
            width="full"
            message={
              !form.isValid ? "Email is required to send document request" : ""
            }
            placement="left"
          >
            <Button
              type="submit"
              form={form.id}
              block
              disabled={form.isSubmitting || !form.isValid}
            >
              {form.isSubmitting ? (
                <Loader />
              ) : (
                STRINGS.REQUEST_DOCUMENT_EMAIL_LABEL
              )}
            </Button>
          </Tooltip>
        </DialogFooter>
      </DialogStep>
      <DialogStep name="set-email-multiple" onBack={dialog.back}>
        <DialogHeader>
          <Flex direction="column">
            <Text size="xl" weight="bold">
              Send document request
            </Text>
            <Text size="md">
              Confirm which email addresses you would like to send these
              requests to
            </Text>
          </Flex>
        </DialogHeader>
        <DialogContent>
          <Flex
            as="form"
            gap="2xl"
            minWidth={form.values.includeCustomMessage ? "920px" : "650px"}
            justify="center"
            direction="column"
            {...form.props}
          >
            <Checkbox
              label="Include a custom message"
              {...form.register({
                name: "includeCustomMessage",
                type: "boolean",
                onChange: onChangeIncludeCustomMessage,
              })}
            />
            <FormProvider form={form}>
              <Table
                id="document-select-dialog-table"
                size="sm"
                data={data}
                columns={columns}
                maxHeight="500px"
              />
            </FormProvider>
          </Flex>
        </DialogContent>
        <DialogFooter>
          <Flex width="full">
            <Button block color="neutral" variant="text" onClick={dialog.back}>
              Back
            </Button>
          </Flex>
          <Tooltip
            as={Flex}
            width="full"
            message={
              !form.isValid ? "Email is required to send document requests" : ""
            }
            placement="left"
          >
            <Button
              type="submit"
              form={form.id}
              block
              disabled={form.isSubmitting || !form.isValid}
            >
              {form.isSubmitting ? (
                <Loader />
              ) : (
                STRINGS.REQUEST_DOCUMENT_EMAIL_LABEL
              )}
            </Button>
          </Tooltip>
        </DialogFooter>
      </DialogStep>
    </Dialog>
  );
};
