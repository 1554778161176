import React from "react";
import { Tooltip } from "@adaptive/design-system";
import { AdaptiveIcon } from "@components/adaptive-icon";
import { QuickBooksIcon } from "@components/quickbooks-icon";
import { BuilderTrendIcon } from "@shared/components/buildertrend-icon";

import { PURCHASE_ORDER_TYPE } from "./constants";

type PurchaseOrderIconProps = {
  type: (typeof PURCHASE_ORDER_TYPE)[keyof typeof PURCHASE_ORDER_TYPE];
  size?: number;
  labelled?: boolean;
};

export const PurchaseOrderIcon = ({
  type,
  size = 24,
  labelled = true,
}: PurchaseOrderIconProps) => {
  if (type === PURCHASE_ORDER_TYPE.QB) {
    return (
      <Tooltip
        as={QuickBooksIcon}
        size={size}
        message={labelled ? "QuickBooks PO" : ""}
      />
    );
  }

  if (type === PURCHASE_ORDER_TYPE.BT) {
    return (
      <Tooltip
        as={BuilderTrendIcon}
        size={size}
        message={labelled ? "BuilderTrend PO" : ""}
      />
    );
  }

  if (type === PURCHASE_ORDER_TYPE.ADAPTIVE) {
    return (
      <Tooltip
        as={AdaptiveIcon}
        width={size}
        height={size}
        variant="circle"
        message={labelled ? "Adaptive PO" : ""}
      />
    );
  }

  return null;
};
