import { createContext, useContext } from "react";
import { noop } from "@utils/noop";

export const CardsAdaptiveContext = createContext<{
  userId: string;
  hasError: boolean;
  userRole: "manage" | "view" | "register";
  isLoading: boolean;
  setHasError: (hasError: boolean) => void;
}>({
  userId: "",
  hasError: false,
  isLoading: false,
  userRole: "view",
  setHasError: noop,
});

export const useCardsAdaptiveContext = () => useContext(CardsAdaptiveContext);
