import {
  arraySchema,
  currencySchema,
  dateSchema,
  idSchema,
} from "@utils/schema";
import { z } from "zod";

// Jobs
export const builderTrendJobSchema = z.object({
  id: idSchema,
  url: z
    .string()
    .url()
    .nullish()
    .transform((value) => value || ""),
  jobName: z
    .string()
    .nullish()
    .transform((value) => value || ""),
  customer: z
    .string()
    .url()
    .nullish()
    .transform((value) => value || ""),
  customerReadOnly: z.boolean(),
});

export const getBuilderTrendJobsListResponseSchema = z.object({
  count: z.number(),
  results: arraySchema(builderTrendJobSchema),
});

export const getBuilderTrendJobsListPayloadSchema = z
  .object({
    dataIndex: z.string(),
    value: z.boolean().or(z.string()).or(z.number()).nullish(),
  })
  .array();

export const updateBuilderTrendJobPayloadSchema = z.object({
  id: idSchema,
  customer: z.string().nullish(),
});

// Cost Codes
export const builderTrendCostCodeSchema = z.object({
  id: idSchema,
  url: z
    .string()
    .url()
    .nullish()
    .transform((value) => value || ""),
  title: z
    .string()
    .nullish()
    .transform((value) => value || ""),
  item: z
    .string()
    .url()
    .nullish()
    .transform((value) => value || ""),
});

export const getBuilderTrendCostCodesListResponseSchema = z.object({
  count: z.number(),
  results: arraySchema(builderTrendCostCodeSchema),
});

export const getBuilderTrendCostCodesListPayloadSchema = z
  .object({
    dataIndex: z.string(),
    value: z.boolean().or(z.string()).or(z.number()).nullish(),
  })
  .array();

export const updateBuilderTrendCostCodePayloadSchema = z.object({
  id: idSchema,
  item: z.string().nullish(),
});

export enum BuilderTrendSyncStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export const builderTrendBuilderSchema = z.object({
  id: idSchema,
  url: z.string().url().nullish(),
  name: z.string().nullish(),
});

export const getBuilderTrendBuildersSimpleListResponseSchema = arraySchema(
  builderTrendBuilderSchema
);

// Credentials
export const builderTrendCredentialsSchema = z.object({
  id: idSchema,
  url: z
    .string()
    .url()
    .nullish()
    .transform((value) => value || ""),
  username: z.string().nullish(),
  password: z.string().nullish(),
  lastSyncedAt: dateSchema.nullish(),
  syncStatus: z.nativeEnum(BuilderTrendSyncStatus).nullish(),
  defaultBuilder: builderTrendBuilderSchema.nullish(),
});

export const getBuilderTrendCredentialsListResponseSchema = z.object({
  count: z.number(),
  results: arraySchema(builderTrendCredentialsSchema),
});

export const updateBuilderTrendCredentialsPayloadSchema = z.object({
  id: idSchema,
  username: z.string().nullish(),
  password: z.string().nullish(),
});

export const createBuilderTrendCredentialsPayloadSchema = z.object({
  username: z.string().nullish(),
  password: z.string().nullish(),
});

export const updateBuilderTrendCredentialsDefaultBuilderPayloadSchema =
  z.object({
    id: idSchema,
    defaultBuilder: z.string().url().nullish(),
  });

// Vendors
const vendorSimpleSchema = z.object({
  id: idSchema,
  url: z.string(),
  displayName: z.string(),
});

export const builderTrendVendorSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  title: z.string().nullish(),
  vendor: vendorSimpleSchema.nullish(),
});

export const getBuilderTrendVendorsListResponseSchema = z.object({
  count: z.number(),
  results: arraySchema(builderTrendVendorSchema),
});

export const getBuilderTrendVendorsListPayloadSchema = z
  .object({
    dataIndex: z.string(),
    value: z.boolean().or(z.string()).or(z.number()).nullish(),
  })
  .array();

export const updateBuilderTrendVendorPayloadSchema = z.object({
  id: idSchema,
  vendor: z.string().nullish(),
});

// Purchase Orders
export const purchaseOrderSimpleSchema = z.object({
  id: idSchema,
  url: z.string().url().nullish(),
  docNumber: z.string().nullish(),
});

export const builderTrendPurchaseOrderSchema = z.object({
  id: idSchema,
  url: z.string().url().nullish(),
  title: z.string().nullish(),
  added: dateSchema.nullish(),
  vendor: builderTrendVendorSchema.nullish(),
  job: builderTrendJobSchema.nullish(),
  purchaseOrder: purchaseOrderSimpleSchema.nullish(),
  number: z.string().nullish(),
  totalBuilderCost: z.number(),
  totalCalculatedAmount: z.number(),
  amountRemaining: currencySchema,
  amountPaid: currencySchema,
});

export const getBuilderTrendPurchaseOrdersListResponseSchema = z.object({
  count: z.number(),
  results: arraySchema(builderTrendPurchaseOrderSchema),
});

export const getBuilderTrendPurchaseOrdersListPayloadSchema = z
  .object({
    dataIndex: z.string(),
    value: z.boolean().or(z.string()).or(z.number()).nullish(),
  })
  .array();
