import { useEffect } from "react";
import { toast } from "@adaptive/design-system";
import { usePrevious } from "@adaptive/design-system/hooks";
import { captureMessage } from "@sentry/react";
import { useClientAction, useClientInfo } from "@store/user";

type UseCurrentClientFromRealmOptions = {
  suppressToast?: boolean;
};

export const useCurrentClientFromRealm = (
  realmUrl: string | null | undefined,
  options: UseCurrentClientFromRealmOptions = {}
) => {
  const suppressToast = options?.suppressToast ?? false;

  const { changeClient, currentClient } = useClientAction();

  const { otherClients, client } = useClientInfo();

  const clientName = client?.name ?? "Unknown";

  const clientRealmUrl = client?.realm?.url;

  const prevClientRealmUrl = usePrevious(clientRealmUrl, "");

  useEffect(() => {
    if (!realmUrl) return;

    if (!clientRealmUrl) {
      captureMessage(`Client realm url is not set on ${clientName}`, {
        level: "error",
      });
    }

    if (realmUrl !== clientRealmUrl && realmUrl !== prevClientRealmUrl) {
      const otherClient = otherClients.find((otherClient) => {
        const otherClientRealmUrl = otherClient.realm?.url;

        if (!otherClientRealmUrl) {
          captureMessage(`Client realm url is not set on ${otherClient.name}`, {
            level: "error",
          });
        }

        return otherClientRealmUrl === realmUrl;
      });

      if (otherClient) {
        changeClient(otherClient.id).then(() => {
          if (suppressToast || clientRealmUrl !== currentClient?.realm.url) {
            return;
          }

          toast.info(
            "You switched to a different workspace: " + otherClient.name
          );
        });
      }
    }
  }, [
    prevClientRealmUrl,
    realmUrl,
    clientName,
    otherClients,
    changeClient,
    suppressToast,
    clientRealmUrl,
    currentClient?.realm.url,
  ]);
};
