import React, { memo } from "react";
import { Flex, Icon, Link, Prose, Text } from "@adaptive/design-system";

export type MissingLienWaiverFieldsProps = {
  missingFields: string[];
  frontendUrl?: string;
  objectId: string;
  displayName: string;
};

export const MissingLienWaiverFieldsInfo = memo(
  ({
    missingFields,
    objectId,
    frontendUrl,
    displayName,
  }: MissingLienWaiverFieldsProps) => {
    return (
      <Flex gap="sm" direction="column">
        <Flex gap="md">
          {frontendUrl ? (
            <>
              <Link href={frontendUrl} target="_blank" size="sm">
                {displayName}
              </Link>
              <Icon size="sm" name="arrow-up-right-from-square" />
            </>
          ) : (
            <Text as="span" size="sm">
              {displayName}
            </Text>
          )}
        </Flex>
        <Prose as="span">
          <ul>
            {missingFields.map((field, index) => (
              <li key={`${objectId}-${index}`}>{field}</li>
            ))}
          </ul>
        </Prose>
      </Flex>
    );
  }
);
MissingLienWaiverFieldsInfo.displayName = "MissingFieldsInfo";
