import React, { Fragment, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  Card,
  CardContent,
  CardHeader,
  Flex,
  Icon,
  Link,
  Loader,
  Markdown,
  Prose,
  Text,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import type { Error } from "@api/errors";
import { handleErrors } from "@api/handle-errors";
import {
  useDeleteQuickBooksErrorsMutation,
  useUpdateQuickBooksErrorsMutation,
} from "@api/quickbooks";
import { useIntegrationType } from "@hooks/use-integration-type";
import { toggleChatVisibility } from "@store/user/slice";
import * as analytics from "@utils/analytics";
import {
  type KeysToSnakeCase,
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";

type ErrorAlertProps = {
  data?: Error[] | KeysToSnakeCase<Error>[];
  objectType:
    | "Job"
    | "Bill"
    | "Draw"
    | "Vendor"
    | "Expense"
    | "Receipt"
    | "Invoice"
    | "Customer"
    | "PurchaseOrder"
    | "BillPayment";
  onChange?: () => void;
  relatedData?: Error[] | KeysToSnakeCase<Error>[];
};

const HUMAN_READABLE_TYPE_TO_OBJECT_TYPE: Record<
  ErrorAlertProps["objectType"],
  string
> = {
  Job: "job",
  Bill: "bill",
  Draw: "draw",
  Vendor: "vendor",
  Invoice: "draw",
  Expense: "receipt",
  Receipt: "receipt",
  Customer: "job",
  PurchaseOrder: "purchase order",
  BillPayment: "bill payment",
};

type ErrorMessageProps = Error & { variant: "error" | "neutral" };

const ErrorMessage = ({ link, variant, message }: ErrorMessageProps) => (
  <>
    {message && <Markdown>{message}</Markdown>}
    {link && variant === "error" && (
      <Link rel="noopener noreferrer" href={link} target="_blank">
        Learn how to resolve this issue
      </Link>
    )}
  </>
);

export const ErrorAlert = ({
  data = [],
  onChange,
  objectType,
  relatedData = [],
}: ErrorAlertProps) => {
  const dispatch = useDispatch();

  const [updateQuickBooksErrorsMutation, updateQuickBooksErrorsMutationInfo] =
    useUpdateQuickBooksErrorsMutation();

  const [deleteQuickBooksErrorsMutation, deleteQuickBooksErrorsMutationInfo] =
    useDeleteQuickBooksErrorsMutation();

  const isLoading =
    updateQuickBooksErrorsMutationInfo.isLoading ||
    deleteQuickBooksErrorsMutationInfo.isLoading;

  const enhancedObjectType = HUMAN_READABLE_TYPE_TO_OBJECT_TYPE[objectType];

  const integrationType = useIntegrationType();

  /**
   * We need to transform the keys to camel case because the API returns
   * snake case keys, but the Alert component expects camel case keys.
   * Some places we already converted it to camel case but some places we
   * didn't, so we need to convert it back to snake case and then back to
   * camel case to make sure it's consistent.
   */
  const enhancedData = useMemo(
    () =>
      data.map((item) =>
        transformKeysToCamelCase(transformKeysToSnakeCase(item))
      ) as Error[],
    [data]
  );

  const enhancedRelatedData = useMemo(
    () =>
      relatedData.map((item) => {
        const enhancedItem = transformKeysToCamelCase(
          transformKeysToSnakeCase(item)
        );

        return { ...item, link: null, message: enhancedItem.relatedMessage };
      }) as Error[],
    [relatedData]
  );

  const variant =
    enhancedData.length === 0 && enhancedRelatedData.length > 0
      ? "error"
      : enhancedData.some((item) => !item.isIgnored)
        ? "error"
        : "neutral";

  const openChat = useEvent(() => {
    dispatch(toggleChatVisibility(true));
    analytics.track("chatOpen", { source: "quickbooks-error-alert" });
  });

  const onIgnore = useEvent(async () => {
    const ids = enhancedData.map((item) => item.id);

    try {
      await updateQuickBooksErrorsMutation({ ids, isIgnored: true }).unwrap();
    } catch (e) {
      handleErrors(e);
    }

    onChange?.();
  });

  const onResync = useEvent(async () => {
    const ids = enhancedData.map((item) => item.id);

    try {
      await deleteQuickBooksErrorsMutation({ ids }).unwrap();
    } catch (e) {
      handleErrors(e);
    }

    onChange?.();
  });

  return (
    <Alert variant={variant}>
      <AlertTitle>
        {enhancedData.length > 0 &&
          (variant === "error"
            ? `This ${enhancedObjectType} failed to sync with QuickBooks`
            : `Elements of this ${enhancedObjectType} are out of sync with QuickBooks`)}

        {enhancedData.length === 0 &&
          enhancedRelatedData.length > 0 &&
          `There are errors that might prevent this ${enhancedObjectType} from syncing with QuickBooks`}
      </AlertTitle>
      <AlertContent as="div" hidden={variant === "neutral"}>
        <Flex direction="column" gap="md">
          {enhancedData.map((item) => (
            <ErrorMessage key={item.id} {...item} variant={variant} />
          ))}

          {enhancedRelatedData.length > 0 && (
            <>
              <Text>
                Some related objects failed to sync with QuickBooks, which will
                likely to cause this {enhancedObjectType} to fail syncing as
                well
              </Text>
              <Card size="sm" style={{ width: "var(--spacing-full)" }}>
                <CardHeader>
                  <Flex gap="md">
                    <Icon name="info-circle" />
                    <Text weight="bold">How to solve this error</Text>
                  </Flex>
                </CardHeader>
                <CardContent hidden>
                  <Flex gap="xl" align="flex-start" direction="column">
                    <Prose>
                      <ol>
                        <li>
                          Go to the following objects and ensure their
                          QuickBooks errors are resolved:
                          <ul>
                            {enhancedRelatedData.map((item) => (
                              <li key={item.id}>
                                <ErrorMessage {...item} variant={variant} />
                              </li>
                            ))}
                          </ul>
                        </li>
                        {enhancedData.length > 0 && (
                          <li>Click Resync to QuickBooks</li>
                        )}
                        {enhancedData.length > 0 &&
                          integrationType === "QBDT" && (
                            <li>
                              Initiate a sync in the QuickBooks Web Connector
                            </li>
                          )}
                      </ol>
                    </Prose>
                    <Link
                      size="sm"
                      as="button"
                      type="button"
                      onClick={openChat}
                    >
                      Still not working? Get help
                    </Link>
                  </Flex>
                </CardContent>
              </Card>
            </>
          )}

          {enhancedData.length > 0 && (
            <Flex
              gap="lg"
              margin={[
                enhancedRelatedData.length > 0 ? "md" : "none",
                "none",
                "none",
                "none",
              ]}
            >
              <Flex width="180px">
                <Button
                  size="sm"
                  block
                  color={variant === "error" ? "error" : "primary"}
                  onClick={onResync}
                  variant={variant === "error" ? "ghost" : "solid"}
                  disabled={isLoading}
                >
                  {deleteQuickBooksErrorsMutationInfo.isLoading ? (
                    <Loader />
                  ) : (
                    "Resync to QuickBooks"
                  )}
                </Button>
              </Flex>
              {variant === "error" && (
                <Flex width="142px">
                  <Button
                    size="sm"
                    block
                    onClick={onIgnore}
                    disabled={isLoading}
                  >
                    {updateQuickBooksErrorsMutationInfo.isLoading ? (
                      <Loader />
                    ) : (
                      "Ignore sync error"
                    )}
                  </Button>
                </Flex>
              )}
            </Flex>
          )}
        </Flex>
      </AlertContent>
    </Alert>
  );
};
