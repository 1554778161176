import React, { memo, useMemo } from "react";
import {
  Button,
  DateField,
  Flex,
  Icon,
  Link,
  type TableRow,
  Tag,
  Text,
  Tooltip,
} from "@adaptive/design-system";
import {
  formatCurrency,
  formatDate,
  parseCurrency,
  parseDate,
  parseStringCopy,
} from "@adaptive/design-system/utils";
import { BankAccountComboBox } from "@components/bank-account-combobox";
import { LienWaiverTemplateCombobox } from "@lien-waiver/components";
import { type AmountByCustomer } from "@lien-waiver/types";
import { useClientSettings } from "@store/user";
import { isNegative } from "@utils/is-negative";
import { sum } from "@utils/sum";
import { sumBy } from "@utils/sumBy";
import addDays from "date-fns/addDays";
import isSameDay from "date-fns/isSameDay";
import { useContextSelector } from "use-context-selector";

import {
  CombinedPaymentDialogContext,
  usePaymentMethod,
} from "../../components/combined-payments-dialog/context";
import { NO_JOB } from "../../constants";
import { COMBINED_PAYMENTS_STRINGS, PAYMENT_METHOD } from "../../constants";
import {
  type BankAccountOption,
  type MultiplePaymentFormPayment,
} from "../../types";
import {
  useCombinedPayments,
  useCurriedOpenVendor,
  useEnhancedOnRemoveBill,
  useFormValues,
  useOnChangeLienWaiverTemplate,
  useOnChangePayFrom,
  useOnSetPaymentAccount,
  usePaymentOptionsByVendor,
  usePaymentOptionsLoading,
  useRegisterFormControl,
} from "../combined-payments-dialog/context";

type Row = TableRow<MultiplePaymentFormPayment>;

const ROW_LEVEL = {
  VENDOR: 0,
  JOB: 1,
  BILL: 2,
} as const;

export const ThisPaymentCell = memo(
  ({ balance, appliedVendorCredits, depth }: Row) => {
    const combinedPayments = useCombinedPayments();
    const isParentRow = !combinedPayments || depth !== ROW_LEVEL.BILL;
    const isVendorRow = !combinedPayments || depth === ROW_LEVEL.VENDOR;

    const appliedVendorCreditAmount = isVendorRow
      ? sumBy(appliedVendorCredits || [], "appliedAmount")
      : 0;

    const remainingBalanceAfterCredits = sum(
      balance || 0,
      appliedVendorCreditAmount
    );
    return (
      <Text weight={isParentRow ? "bold" : "regular"}>
        {formatCurrency(remainingBalanceAfterCredits, {
          currencySign: true,
        })}
      </Text>
    );
  }
);
ThisPaymentCell.displayName = "ThisPaymentCell";

export const VendorCell = memo(
  ({ vendor, depth, customer, docNumber, billId }: Row) => {
    const combinedPayments = useCombinedPayments();

    if (!combinedPayments || (combinedPayments && depth === ROW_LEVEL.VENDOR)) {
      return <Text truncate>{vendor?.displayName}</Text>;
    }

    if (depth === ROW_LEVEL.JOB) {
      return <Text truncate>{customer?.displayName}</Text>;
    }

    return (
      <Link href={`/bills/${billId}`} target="_blank" size="sm">
        {`#${docNumber}`}
      </Link>
    );
  }
);
VendorCell.displayName = "VendorCell";

export const PayFromCell = memo(
  ({
    index,
    customerBankAccount,
    customerPaymentAccount,
    vendor,
    depth,
  }: Row) => {
    const paymentOptionsByVendor = usePaymentOptionsByVendor();
    const paymentOptionsLoading = usePaymentOptionsLoading();
    const onChangePayFrom = useOnChangePayFrom();
    const onSetPaymentAccount = useOnSetPaymentAccount();

    const vendorId = vendor?.id || "";
    const paymentOption = useMemo(
      () => paymentOptionsByVendor?.[vendorId],
      [paymentOptionsByVendor, vendorId]
    );

    if (depth !== ROW_LEVEL.VENDOR) {
      return null;
    }

    const selectedAccount = paymentOption?.accounts.find(
      (account) => account.value === customerBankAccount
    );

    const shouldLinkPaymentAccount =
      customerBankAccount && !customerPaymentAccount;

    return (
      <Tooltip
        message={
          shouldLinkPaymentAccount ? (
            <Text truncate>
              {COMBINED_PAYMENTS_STRINGS.LINK_ACCOUNT_TITLE}{" "}
              <Link
                as="button"
                type="button"
                onClick={() =>
                  onSetPaymentAccount?.(selectedAccount as BankAccountOption)
                }
              >
                {COMBINED_PAYMENTS_STRINGS.CLICK_HERE_TITLE}
              </Link>
            </Text>
          ) : undefined
        }
      >
        <BankAccountComboBox
          size="sm"
          paymentOptions={paymentOption}
          data-testid="from-account"
          loading={paymentOptionsLoading}
          value={customerBankAccount || ""}
          onChange={(value) => onChangePayFrom?.(value, vendorId, index!)}
          errorMessage={
            customerBankAccount === "" ? (
              COMBINED_PAYMENTS_STRINGS.PAYMENT_ACCOUNT_REQUIRED
            ) : shouldLinkPaymentAccount ? (
              <Text as="span" truncate>
                <Link
                  as="button"
                  type="button"
                  onClick={() =>
                    onSetPaymentAccount?.(selectedAccount as BankAccountOption)
                  }
                >
                  <Text as="span" size="xs">
                    {COMBINED_PAYMENTS_STRINGS.LINK_ACCOUNT_TITLE}
                  </Text>
                </Link>
              </Text>
            ) : undefined
          }
          messageVariant={
            shouldLinkPaymentAccount || customerBankAccount === ""
              ? "relative"
              : "hidden"
          }
        />
      </Tooltip>
    );
  }
);
PayFromCell.displayName = "PayFromCell";

export const DueDateCell = memo(
  ({ depth, dueDate }: Row & { index: number }) => {
    const combinedPayments = useCombinedPayments();

    if ((combinedPayments && depth !== ROW_LEVEL.BILL) || !dueDate) {
      return null;
    }

    return formatDate(parseDate(dueDate, "iso"));
  }
);
DueDateCell.displayName = "DueDateCell";

export const DebitDateCell = memo(
  ({ index, vendor, depth, appliedVendorCredits, balance }: Row) => {
    const paymentOptionsByVendor = usePaymentOptionsByVendor();
    const curriedOpenVendor = useCurriedOpenVendor();
    const registerFormControl = useRegisterFormControl();
    const paymentMethod = usePaymentMethod();
    const formValues = useFormValues();

    const vendorId = vendor?.id || "";

    const today = useMemo(() => new Date(), []);

    const isMarkAsPaid = useMemo(() => {
      return (
        sum(
          sumBy(appliedVendorCredits || [], "appliedAmount"),
          parseCurrency(balance || "0") || 0
        ) <= 0 || paymentMethod === PAYMENT_METHOD.MARK_AS_PAID
      );
    }, [appliedVendorCredits, balance, paymentMethod]);

    const paymentOption = useMemo(
      () => paymentOptionsByVendor?.[vendorId],
      [paymentOptionsByVendor, vendorId]
    );

    if (depth !== ROW_LEVEL.VENDOR) {
      return null;
    }

    const requireLienWaiverSignature = formValues?.payWhenLienWaiverIsSigned;
    const lineValue = formValues?.payments[index!];
    const isMissingAchData =
      lineValue?.customerBankAccount && !lineValue?.vendorBankAccount;

    const selectedAccount = paymentOption?.accounts.find(
      (account) => account.value === lineValue?.customerBankAccount
    );
    const nextAvailablePaymentSchedule =
      selectedAccount?.achOption?.paymentSchedules?.[0];
    const hasBothPrerequisites = requireLienWaiverSignature && isMissingAchData;

    const isDisabled =
      (!isMarkAsPaid && (isMissingAchData || requireLienWaiverSignature)) ||
      !selectedAccount;
    const placeholder = isDisabled
      ? hasBothPrerequisites
        ? COMBINED_PAYMENTS_STRINGS.ON_ACH_COLLECTION_AND_LIEN_WAIVER_SIGNATURE
        : isMissingAchData
          ? COMBINED_PAYMENTS_STRINGS.ON_ACH_COLLECTION
          : requireLienWaiverSignature
            ? COMBINED_PAYMENTS_STRINGS.ON_LIEN_WAIVER_SIGNATURE
            : !selectedAccount
              ? COMBINED_PAYMENTS_STRINGS.SELECT_PAY_FROM
              : undefined
      : undefined;

    const formControlFields = registerFormControl({
      name: `payments.${index}.debitDate`,
      type: "date",
    });

    return (
      <Flex gap="sm" justify="center" align="baseline">
        <DateField
          fromDate={
            isMarkAsPaid
              ? undefined
              : nextAvailablePaymentSchedule?.processOn || undefined
          }
          toDate={
            isMarkAsPaid
              ? today
              : nextAvailablePaymentSchedule?.processOn
                ? addDays(nextAvailablePaymentSchedule.processOn, 1)
                : undefined
          }
          highlightedDates={
            !isMarkAsPaid && nextAvailablePaymentSchedule
              ? [
                  {
                    message: COMBINED_PAYMENTS_STRINGS.PROCESS_DATE_TITLE,
                    matcher: nextAvailablePaymentSchedule.processOn!,
                  },
                  {
                    message: COMBINED_PAYMENTS_STRINGS.DELIVERY_DATE_TITLE,
                    matcher: {
                      from: nextAvailablePaymentSchedule.expectedDeliveryAfter!,
                      to: nextAvailablePaymentSchedule.expectedDeliveryBefore!,
                    },
                  },
                ]
              : undefined
          }
          disabledDates={(date) =>
            isMarkAsPaid
              ? false
              : nextAvailablePaymentSchedule?.processOn
                ? !isSameDay(date, nextAvailablePaymentSchedule?.processOn)
                : false
          }
          readOnly
          helperMessage={
            isDisabled ? (
              <Text as="span" truncate>
                {placeholder}{" "}
                {isMissingAchData && (
                  <Link onClick={curriedOpenVendor(vendorId, "payment")}>
                    {hasBothPrerequisites
                      ? COMBINED_PAYMENTS_STRINGS.ADD_ACH_INFO_HERE
                      : COMBINED_PAYMENTS_STRINGS.ADD_THEM_HERE}
                  </Link>
                )}
              </Text>
            ) : undefined
          }
          placeholder={placeholder}
          disabled={isDisabled}
          size="sm"
          {...formControlFields}
          messageVariant={
            isDisabled || formControlFields.errorMessage
              ? "relative"
              : "absolute"
          }
        />
      </Flex>
    );
  }
);
DebitDateCell.displayName = "DebitDateCell";

export const LienWaiverHeader = memo(() => {
  const { canManageLienWaivers } = useClientSettings();
  return (
    <Flex gap="md">
      <Text weight="bold">Lien waivers</Text>
      <Tooltip
        as={Icon}
        name="info-circle"
        size="sm"
        message={
          !canManageLienWaivers
            ? COMBINED_PAYMENTS_STRINGS.LIEN_WAIVER_NOT_ENABLED
            : COMBINED_PAYMENTS_STRINGS.LIEN_WAIVER_REQUESTED_INFO
        }
      />
    </Flex>
  );
});
LienWaiverHeader.displayName = "LienWaiverHeader";

export const LienWaiverCell = memo(
  ({
    index,
    bills,
    balance,
    customer,
    lienWaivers,
    customers,
    openBalanceByCustomer,
    vendor,
    depth,
    appliedVendorCredits,
  }: Row) => {
    const onChangeLienWaiverTemplate = useOnChangeLienWaiverTemplate();
    const combinedPayments = useCombinedPayments();
    const formValues = useFormValues();
    const payment = formValues?.payments[index!];

    const lienWaiver = combinedPayments
      ? payment?.lienWaivers?.find(
          (lienWaiver) => lienWaiver.customer === customer?.url
        )
      : payment?.lienWaivers?.[0];

    const appliedVendorCreditAmount = useMemo(() => {
      return sumBy(appliedVendorCredits || [], "appliedAmount");
    }, [appliedVendorCredits]);

    const netPaymentAmount = useMemo(() => {
      return sum(appliedVendorCreditAmount, parseCurrency(balance || "0") || 0);
    }, [appliedVendorCreditAmount, balance]);

    const enhancedCustomers = useMemo<AmountByCustomer[]>(() => {
      const currentCustomers =
        combinedPayments && customer ? [customer] : customers || [];
      return currentCustomers.map((customerRow) => {
        const customerOpenBalance =
          parseCurrency(
            (openBalanceByCustomer || []).find(
              (customer) => customer.customerId === customerRow?.id
            )?.balance || "0"
          ) || 0;

        const paymentOpenBalance = parseCurrency(payment?.balance || "0") || 0;

        const customerAmountPctOfTotal = paymentOpenBalance
          ? customerOpenBalance / paymentOpenBalance
          : 1;

        // this is negative
        const appliedVendorCreditsForCustomer =
          Math.round(
            appliedVendorCreditAmount * customerAmountPctOfTotal * 100
          ) / 100;

        const netPaymentAmountForCustomer = sum(
          customerOpenBalance,
          appliedVendorCreditsForCustomer
        );

        return {
          customer: customerRow?.url || "",
          customerDisplayName: customerRow?.displayName || "",
          paymentAmount: customerOpenBalance,
          netPaymentAmount: netPaymentAmountForCustomer,
        };
      });
    }, [
      appliedVendorCreditAmount,
      combinedPayments,
      customer,
      customers,
      openBalanceByCustomer,
      payment?.balance,
    ]);

    const value = lienWaiver?.lienWaiverTemplate || lienWaiver?.status || "";

    if (
      combinedPayments &&
      (depth === ROW_LEVEL.BILL || customer?.id === NO_JOB.id)
    ) {
      return null;
    }

    if (combinedPayments && depth === ROW_LEVEL.VENDOR) {
      const requestedLienWaivers =
        lienWaivers?.filter((lienWaiver) => lienWaiver?.lienWaiverTemplate)
          .length || 0;
      return (
        <Tag>
          {parseStringCopy(COMBINED_PAYMENTS_STRINGS.LIEN_WAIVERS_REQUESTED, {
            quantity: requestedLienWaivers,
            suffix: requestedLienWaivers === 1 ? "" : "s",
          })}
        </Tag>
      );
    }

    return (
      <LienWaiverTemplateCombobox
        value={value}
        onChange={(value, extra) =>
          onChangeLienWaiverTemplate?.(value, {
            ...extra,
            index: index!,
            customer: customer?.url || "",
          })
        }
        customers={enhancedCustomers}
        paymentAmount={parseCurrency(balance || "0")}
        netPaymentAmount={netPaymentAmount}
        errorMessage={
          lienWaivers?.some((lienWaiver) => !lienWaiver?.missingFields?.isValid)
            ? COMBINED_PAYMENTS_STRINGS.LIEN_WAIVER_MISSING_INFO
            : undefined
        }
        billIds={bills?.map((bill) => bill.id)}
        vendor={vendor?.url || ""}
        size="sm"
      />
    );
  }
);
LienWaiverCell.displayName = "LienWaiverCell";

export const RemoveLineCell = memo(({ index, billId, depth }: Row) => {
  const combinedPayments = useCombinedPayments();
  const enhancedOnRemoveBill = useEnhancedOnRemoveBill();

  if (combinedPayments && depth !== ROW_LEVEL.BILL) {
    return null;
  }

  return (
    <Tooltip
      as={Button}
      variant="ghost"
      color="neutral"
      size="sm"
      onClick={() => enhancedOnRemoveBill(billId, index!)}
      message={COMBINED_PAYMENTS_STRINGS.REMOVE_BUTTON_TOOLTIP}
    >
      <Icon name="x" />
    </Tooltip>
  );
});
RemoveLineCell.displayName = "RemoveLineCell";

export const TotalPaymentAmountFooter = memo(({ rows }: { rows: Row[] }) => {
  const formValues = useFormValues();
  const totalAmountToPay = sumBy(formValues?.payments || [], "balance");
  const totalAppliedVendorCreditsAmount = sumBy(
    rows.flatMap((row) => row.appliedVendorCredits || []),
    "appliedAmount"
  );

  return (
    <Text weight="bold">
      {formatCurrency(sum(totalAmountToPay, totalAppliedVendorCreditsAmount), {
        currencySign: true,
      })}
    </Text>
  );
});
TotalPaymentAmountFooter.displayName = "TotalPaymentAmountFooter";

export const AppliedVendorCreditsCell = memo(
  ({ index, vendor, appliedVendorCredits, depth }: Row) => {
    const combined = useCombinedPayments();
    const onEditVendorCredits = useContextSelector(
      CombinedPaymentDialogContext,
      (context) => context.onEditVendorCredits
    );
    const vendorCreditBalance = useContextSelector(
      CombinedPaymentDialogContext,
      (context) => context.vendorCreditBalanceByVendor?.[vendor.id.toString()]
    );

    const appliedVendorCreditsAmount = appliedVendorCredits
      ? sumBy(appliedVendorCredits, "appliedAmount")
      : 0;

    if (combined && depth !== ROW_LEVEL.VENDOR) {
      return null;
    }

    return (
      <Flex justify="space-between" align="center">
        <Flex gap="md" align="center">
          <Button
            size="sm"
            variant="ghost"
            color="neutral"
            aria-label="Edit vendor credits"
            onClick={() => onEditVendorCredits(index)}
          >
            <Icon name="pen" />
          </Button>
          {!!vendorCreditBalance && (
            <Tooltip
              as={Flex}
              message={`${formatCurrency(vendorCreditBalance, {
                currencySign: true,
              })} available`}
            >
              <Icon name="exclamation-circle" />
            </Tooltip>
          )}
        </Flex>

        <Text
          weight="bold"
          color={
            isNegative(appliedVendorCreditsAmount) ? "success-200" : undefined
          }
        >
          {formatCurrency(appliedVendorCreditsAmount, {
            currencySign: true,
            allowNegative: true,
          })}
        </Text>
      </Flex>
    );
  }
);

AppliedVendorCreditsCell.displayName = "AppliedVendorCreditsCell";
export const AppliedVendorCreditsFooter = memo(() => {
  const payments = useContextSelector(
    CombinedPaymentDialogContext,
    (context) => context.formValues?.payments
  );

  const appliedVendorCredits = useMemo(() => {
    return payments?.flatMap((payment) => payment.appliedVendorCredits || []);
  }, [payments]);

  const totalAppliedVendorCreditsAmount = appliedVendorCredits
    ? sumBy(appliedVendorCredits, "appliedAmount")
    : 0;

  return (
    <Flex justify="flex-end" align="center">
      <Text
        weight="bold"
        color={
          isNegative(totalAppliedVendorCreditsAmount)
            ? "success-200"
            : undefined
        }
      >
        {formatCurrency(totalAppliedVendorCreditsAmount, {
          currencySign: true,
          allowNegative: true,
        })}
      </Text>
    </Flex>
  );
});
AppliedVendorCreditsFooter.displayName = "AppliedVendorCreditsFooter";
