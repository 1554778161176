import React, { useMemo } from "react";
import { Flex, Link, TagGroup, Text } from "@adaptive/design-system";
import { formatCurrency, formatDate } from "@adaptive/design-system/utils";
import { formatBillableStatus } from "@utils/billable-status";
import { invertSign } from "@utils/invert-sign";
import { sum } from "@utils/sum";

import { EM_DASH } from "../../constants";
import { type DynamicBalanceVendorCredit } from "../../types";

export const RefNumberCell = ({
  docNumber,
  id,
}: DynamicBalanceVendorCredit) => {
  return docNumber ? (
    <Link
      href={`/bills/${id}`}
      target="_blank"
      size="sm"
    >{`#${docNumber}`}</Link>
  ) : (
    EM_DASH
  );
};

export const DateCreatedCell = ({ createdAt }: DynamicBalanceVendorCredit) => {
  return createdAt ? formatDate(createdAt) : EM_DASH;
};

export const AmountCell = ({ appliedAmount }: DynamicBalanceVendorCredit) => {
  return formatCurrency(appliedAmount || 0, { currencySign: true });
};

export const OpenBalanceCell = ({
  appliedAmount,
  currentOpenBalance,
}: DynamicBalanceVendorCredit) => {
  const openBalanceAfterCurrentCreditsApplied = formatCurrency(
    sum(currentOpenBalance || 0, invertSign(appliedAmount || 0)),
    {
      currencySign: true,
    }
  );

  return (
    <Flex align="baseline" justify="flex-end">
      <Flex direction="column" align="flex-end">
        {!!appliedAmount && (
          <Text size="xs" weight="light" decoration="line-through">
            {formatCurrency(currentOpenBalance || 0, { currencySign: true })}
          </Text>
        )}
        {openBalanceAfterCurrentCreditsApplied}
      </Flex>
    </Flex>
  );
};

export const JobsCell = ({ customers }: DynamicBalanceVendorCredit) => {
  const jobNames = useMemo(() => {
    return Array.from(
      new Set(customers.map((customer) => customer.displayName))
    ).sort();
  }, [customers]);

  if (!jobNames?.length) {
    return EM_DASH;
  }
  return <TagGroup limit="auto" data={jobNames} />;
};

export const SubtotalCell = ({ subtotal }: { subtotal: number }) => {
  return (
    <Flex grow justify="flex-end">
      <Text weight="bold">
        {formatCurrency(subtotal, { currencySign: true })}
      </Text>
    </Flex>
  );
};

export const BillableStatusCell = ({
  billableStatus,
}: DynamicBalanceVendorCredit) => {
  if (!billableStatus?.length) {
    return EM_DASH;
  }

  return (
    <TagGroup
      limit="auto"
      data={billableStatus.map((status) => formatBillableStatus(status))}
    />
  );
};
