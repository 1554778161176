import React from "react";
import { Card, Flex, Link, Text } from "@adaptive/design-system";

import { adaptiveUrl } from "./constants";

type Props = { companyName: string };

export const AdaptiveFooter = ({ companyName }: Props) => (
  <Card color="success" variant="solid" size={{ mobile: "sm", tablet: "md" }}>
    <Flex gap="md" direction="column">
      <Text size="sm">
        {companyName} uses Adaptive, the construction cost management platform.{" "}
        <Text
          size="sm"
          as={Link}
          href={adaptiveUrl}
          variant="success"
          style={{ textDecoration: "none" }}
        >
          Learn more
        </Text>
        .
      </Text>

      <Text size="sm">
        Adaptive’s all-in-one budgets, accounts payable, and bill pay platform
        helps construction teams save time and money. Purpose-built software +
        painless onboarding + expert customer service = more time on the job
        site.
      </Text>
      <Text size="sm">
        Copyright © 2022. Adaptive Real Estate, Inc. All rights reserved
      </Text>
    </Flex>
  </Card>
);

export const Footers = ({ companyName }: Props) => (
  <Flex gap="4xl" direction="column">
    <AdaptiveFooter companyName={companyName} />
  </Flex>
);
