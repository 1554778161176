import { useEffect, useRef } from "react";
import { applyVendorCreditsForBalanceAmount } from "@bill-payment/utils";
import { type MultiplePaymentForm } from "@src/bill-payment/types";
import { useClientSettings } from "@store/user";
import { sum } from "@utils/sum";

import { type DynamicBalanceVendorCredit } from "../../types";
export type UseAutoApplyVendorCreditsToPaymentsProps = {
  payments: MultiplePaymentForm["payments"];
  onAutoApplyVendorCreditsToPayment: ({
    appliedVendorCredits,
    paymentIndex,
  }: {
    appliedVendorCredits: DynamicBalanceVendorCredit[];
    paymentIndex: number;
  }) => void;
  vendorCreditsByVendor: Record<string, DynamicBalanceVendorCredit[]>;
  hasAvailableVendorCredits: boolean;
  combinedPayments: boolean;
};

export const useAutoApplyVendorCreditsToMultiplePayments = ({
  payments,
  onAutoApplyVendorCreditsToPayment,
  vendorCreditsByVendor,
  hasAvailableVendorCredits,
  combinedPayments,
}: UseAutoApplyVendorCreditsToPaymentsProps) => {
  const { vendorCreditsEnabled } = useClientSettings();
  const hasAutoApplied = useRef<boolean>(false);

  useEffect(() => {
    hasAutoApplied.current = false;
  }, [combinedPayments]);

  useEffect(() => {
    if (
      !vendorCreditsEnabled ||
      !hasAvailableVendorCredits ||
      payments.length === 0 ||
      hasAutoApplied.current
    ) {
      return;
    }
    if (
      Object.values(vendorCreditsByVendor).some((vendorCredits) =>
        vendorCredits.some((vendorCredit) => vendorCredit.appliedAmount)
      )
    ) {
      return;
    }

    const localVendorCreditsByVendor = { ...vendorCreditsByVendor };

    payments.forEach((payment, index) => {
      const vendorId = payment.vendor?.id?.toString();
      if (!vendorId) return;

      const vendorCredits = localVendorCreditsByVendor[vendorId] || [];

      const customerIds = payment?.customers?.reduce((acc, customer) => {
        if (customer.id) {
          acc.add(customer.id);
        }
        return acc;
      }, new Set<string>());

      const { appliedVendorCredits } = applyVendorCreditsForBalanceAmount({
        vendorCredits,
        balanceAmount: payment.balance || 0,
        customerIds:
          customerIds && customerIds.size > 0 ? customerIds : undefined,
      });

      onAutoApplyVendorCreditsToPayment({
        appliedVendorCredits,
        paymentIndex: index,
      });

      localVendorCreditsByVendor[vendorId] = vendorCredits.map(
        (vendorCredit) => {
          const appliedVendorCredit = appliedVendorCredits.find(
            (credit) => credit.id === vendorCredit.id
          );
          return {
            ...vendorCredit,
            appliedAmount: 0,
            currentOpenBalance: sum(
              vendorCredit.currentOpenBalance || 0,
              -(appliedVendorCredit?.appliedAmount || 0)
            ),
          };
        }
      );
    });
    hasAutoApplied.current = true;
  }, [
    vendorCreditsByVendor,
    payments,
    onAutoApplyVendorCreditsToPayment,
    hasAvailableVendorCredits,
    vendorCreditsEnabled,
  ]);
};
