import React, { memo } from "react";
import forwardRefAs from "forward-ref-as";

import styles from "./visually-hidden.module.css";

const DEFAULT_COMPONENT = "span";

const VisuallyHidden = forwardRefAs<typeof DEFAULT_COMPONENT>(
  ({ as: Component = DEFAULT_COMPONENT, ...props }, ref) => (
    <Component className={styles["visually-hidden"]} {...props} ref={ref} />
  )
);

const MemoizedVisuallyHidden = memo(VisuallyHidden) as typeof VisuallyHidden;

export { MemoizedVisuallyHidden as VisuallyHidden };
