import { useMemo } from "react";
import { useGetBaseCardsSimplifiedQuery } from "@store/api-simplified";
import { useClientInfo } from "@store/user";

import { useAsSimplifiedData } from "../use-simplified-data";

type UseBaseCardsSimplifiedProps = {
  enabled?: boolean;
  includeLabel?: boolean;
};

export const useBaseCardsSimplified = ({
  enabled = true,
  includeLabel = false,
}: UseBaseCardsSimplifiedProps = {}) => {
  const { realmId } = useClientInfo();

  const skip = !realmId || !enabled;

  const query = useGetBaseCardsSimplifiedQuery(
    { realm: realmId as number },
    { skip }
  );

  const { status, data, ...results } = useAsSimplifiedData(query, {
    groupLabel: includeLabel ? "Linked card" : undefined,
    skip,
  });

  return {
    ...results,
    ...query,
    data: useMemo(() => data || [], [data]),
    status: skip ? "success" : status,
  };
};
