/**
 * @todo Enable these disabled rules and fix all lint issues
 */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Alert,
  AlertContent,
  AlertTitle,
  ComboBox,
  DateField,
  Flex,
  Link,
  Tag,
  Text,
  toast,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { ErrorAlert } from "@components/common/error-alert";
import { TaxModal } from "@components/common/tax-modal";
import { TooManyLinesAlert } from "@components/common/too-many-lines-alert";
import { DuplicateAlert } from "@components/duplicate-alert";
import { SelectVendor } from "@components/form";
import { Form } from "@components/form";
import { QuickBooksSyncedIcon } from "@components/quickbooks-icon";
import { Section } from "@components/section";
import { useStageName } from "@components/sequential/context";
import { useIntegrationType } from "@hooks/use-integration-type";
import { useUsersSimplified } from "@hooks/use-users-simplified";
import {
  useExpenseAction,
  useExpensePermissions,
  useTax,
} from "@store/expenses";
import {
  expenseAssigneeOptionSelector,
  expenseDuplicatesSelector,
  expenseNextAction,
  expenseSelectors,
} from "@store/expenses/selectors";
import { useClientSettings } from "@store/user";
import * as analytics from "@utils/analytics";

import {
  DocumentNumber,
  LinkedOrCardTransactionField,
  PaymentAccountComboBox,
} from "../../forms";
import { Items } from "../../forms/items";
import { STRINGS } from "../constants";

export const Review = () => {
  const stageName = useStageName();

  const { taxSelector, setTax } = useTax();

  const { cardFeedEnabled } = useClientSettings();

  const id = useSelector(expenseSelectors.id);

  const duplicates = useSelector(expenseDuplicatesSelector);

  const publishedToQuickbooks = useSelector(
    expenseSelectors.publishedToQuickbooks
  );

  const integrationType = useIntegrationType();

  const errors = useSelector(expenseSelectors.errors);

  const relatedErrors = useSelector(expenseSelectors.relatedErrors);

  const emailBodyAttachable = useSelector(expenseSelectors.emailBodyAttachable);

  const isArchived = useSelector(expenseSelectors.isArchived);

  const paymentAccount = useSelector(expenseSelectors.paymentAccount);

  const isTransactionGeneratedDraft = useSelector(
    expenseSelectors.isTransactionGeneratedDraft
  );

  const cardTransaction = useSelector(expenseSelectors.cardTransaction);

  const linesCount = useSelector(expenseSelectors.linesCount);

  const users = useUsersSimplified({ filters: { is_staff: false } });

  const { canEditExpense } = useExpensePermissions();

  const isEditable = !isArchived && canEditExpense;

  const emailBodyAttachableTag = emailBodyAttachable && (
    <Tag
      as="button"
      key="emailBodyAttachable"
      type="button"
      onClick={() => {
        window.open(emailBodyAttachable.document, "_blank");
      }}
      color="info"
    >
      View source email
    </Tag>
  );

  const {
    publish,
    unPublish,
    unArchive,
    setDate,
    fetchById,
    saveExpense,
    setAssignee,
    setDocumentNumber,
    setPaymentAccount,
    setCardTransaction,
    setVendor,
  } = useExpenseAction();

  const refetchExpense = useCallback(() => fetchById(id), [fetchById, id]);

  const date = useSelector(expenseSelectors.date);

  const assignee = useSelector(expenseAssigneeOptionSelector);

  const nextAction = useSelector(expenseNextAction);

  const isEditableRefNumber =
    ["unPublish", "pending"].includes(nextAction) &&
    integrationType === "QBDT" &&
    paymentAccount?.isBankAccount &&
    !paymentAccount?.isCreditCard;

  const onSubmit = useMemo(() => {
    if (nextAction === "publish") {
      if (cardTransaction?.pending) {
        return () =>
          toast.error(
            "You cannot sync this receipt to QuickBooks until the transaction has cleared"
          );
      }
      return publish;
    }

    if (nextAction === "unPublish") {
      return unPublish;
    }

    if (nextAction === "unArchive") {
      return unArchive;
    }
  }, [nextAction, cardTransaction?.pending, publish, unArchive, unPublish]);

  const onEnterSubmit = useEvent(async () => {
    const data = await saveExpense();

    analytics.track("expenseUpdate", { expenseId: data.id });

    if (cardFeedEnabled && isTransactionGeneratedDraft) {
      toast.success(
        <Flex as="span" direction="column">
          <Text as="strong" weight="bold">
            Receipt #{data.docNumber} created in For Review status
          </Text>
          <Text as="span">
            Click {STRINGS.BUTTON_PUBLISH} when receipt is ready to sync
          </Text>
        </Flex>
      );
    } else {
      toast.success(`Receipt #${data.docNumber} updated`);
    }
  });

  return (
    <>
      <Form id={stageName} onSubmit={onSubmit} onEnterSubmit={onEnterSubmit}>
        <Flex gap="xl" direction="column">
          {isArchived && canEditExpense && (
            <Alert variant="info">
              <AlertTitle>This receipt is not editable</AlertTitle>
              <AlertContent>
                <Link as="button" type="button" onClick={unArchive}>
                  Restore this receipt
                </Link>{" "}
                in order to make any modifications to it
              </AlertContent>
            </Alert>
          )}

          {duplicates.length > 0 && <DuplicateAlert data={duplicates} />}

          {cardTransaction && cardTransaction.pending && (
            <Alert variant="info">
              <AlertTitle>This transaction is pending</AlertTitle>
              <AlertContent>
                The amount might update once the transaction clears your card.
                You cannot sync this receipt to QuickBooks until the transaction
                has cleared.
              </AlertContent>
            </Alert>
          )}

          <TooManyLinesAlert
            transactionType="Receipt"
            linesCount={linesCount}
          />

          {(errors.length > 0 || relatedErrors.length > 0) && (
            <ErrorAlert
              data={errors}
              onChange={refetchExpense}
              objectType="Receipt"
              relatedData={relatedErrors}
            />
          )}

          <Flex gap="5xl" direction="column">
            <Section
              heading={
                <Flex
                  gap="md"
                  padding={["xs", "none", "none"]}
                  justify="flex-start"
                  align="center"
                >
                  <Text size="xl" weight="bold">
                    {STRINGS.HEADING_DETAILS}
                  </Text>
                  {publishedToQuickbooks && <QuickBooksSyncedIcon />}
                </Flex>
              }
              tags={[emailBodyAttachableTag]}
            >
              <SelectVendor
                selector={expenseSelectors.vendor}
                disabled={!isEditable}
                onChange={setVendor}
                allowOtherNames={true}
                autoFocus
              />
              <Flex gap="xl">
                <DocumentNumber
                  selector={expenseSelectors.docNumber}
                  label={STRINGS.LABEL_DOC_NUMBER}
                  onBlur={(e) => setDocumentNumber(e.target.value)}
                  onChange={setDocumentNumber}
                  disabled={!isEditable || isEditableRefNumber}
                  helperMessage={
                    isEditableRefNumber
                      ? `This field cannot be edited because this receipt is ${
                          nextAction === "pending" ? "pending sync" : "synced"
                        }`
                      : undefined
                  }
                />
                <ComboBox
                  label="Assignee"
                  data={users.data}
                  value={assignee}
                  loading={users.status === "loading"}
                  disabled={!isEditable}
                  onChange={setAssignee}
                  hintMessage={STRINGS.ASSIGNEE_HINT}
                />
              </Flex>
              <DateField
                label="Date"
                size="md"
                value={date}
                disabled={!isEditable}
                onChange={setDate}
                messageVariant="absolute"
              />
            </Section>

            <Section heading="Payment details" hideBorder hidePadding>
              <PaymentAccountComboBox
                required
                selector={expenseSelectors.paymentAccount}
                onChange={(val, option) => {
                  if (paymentAccount?.url !== option?.value) {
                    setCardTransaction(null, null);
                  }
                  setPaymentAccount(val, option);
                }}
                disabled={
                  !isEditable ||
                  isTransactionGeneratedDraft ||
                  (cardTransaction?.id && cardFeedEnabled)
                }
                helperMessage={
                  cardTransaction?.id && cardFeedEnabled
                    ? "Unmatch the card transaction to change the payment account"
                    : ""
                }
                size="md"
              />
              <LinkedOrCardTransactionField />
            </Section>

            <Items />
          </Flex>
        </Flex>
      </Form>

      <TaxModal dataSelector={taxSelector} onSubmit={setTax} />
    </>
  );
};
