import React, {
  memo,
  type MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router";
import { Button, Flex, Icon, Shortcut, Tooltip } from "@adaptive/design-system";
import { useEvent, useKeydown } from "@adaptive/design-system/hooks";
import {
  hasDialogOpen,
  hasModifierKey,
  isField,
  omit,
} from "@adaptive/design-system/utils";
import { STRINGS as CARD_FEED_STRINGS } from "@card-feed/constants";
import {
  ShellSidebar,
  ShellSidebarFooter,
  ShellSidebarHeader,
  ShellSidebarItem,
  ShellSidebarLink,
  type ShellSidebarLinkLink,
  ShellSidebarSeparator,
} from "@components/shell";
import {
  SidebarChat,
  SidebarClient,
  SidebarLogout,
  SidebarSearch,
} from "@components/sidebar";
import { SidebarItem as SidebarNotifications } from "@notifications/components";
import { BrandingLogo } from "@shared/components/branding-logo";
import { BasePermissions, useUserInfo } from "@store/user";
import { useClientSettings } from "@store/user";
import * as analytics from "@utils/analytics";

type Link = ShellSidebarLinkLink<{ permissions?: BasePermissions[] }>;

const EXPENSES_PERMISSIONS = [
  BasePermissions.ADD_EXPENSE,
  BasePermissions.COMMENT_EXPENSE,
  BasePermissions.VIEW_ALL_EXPENSES,
  BasePermissions.EDIT_ALL_EXPENSES,
  BasePermissions.PUBLISH_EXPENSES,
  BasePermissions.FLAG_EXPENSES,
];

const JOBS_LINK: Link = {
  to: "/jobs",
  label: "Jobs",
  icon: "hammer",
};

const BILLS_PAGE_PERMISSIONS = [
  BasePermissions.ADD_BILL,
  BasePermissions.COMMENT_BILL,
  BasePermissions.VIEW_ALL_BILLS,
  BasePermissions.EDIT_ALL_BILLS,
  BasePermissions.APPROVE_BILLS,
  BasePermissions.BYPASS_APPROVAL_WORKFLOWS,
  BasePermissions.PAY_BILLS,
];

const BILLS_LINK: Link = {
  to: "/bills",
  label: "Bills",
  icon: "file-lines",
  permissions: BILLS_PAGE_PERMISSIONS,
};

const BILLS_WITH_BILL_PAYMENTS_LINKS: Link = {
  label: "Bills",
  icon: "file-lines",
  links: [
    { ...omit(BILLS_LINK, ["icon"]), default: true },
    {
      to: "/bill-payments",
      label: "Payments",
      permissions: [BasePermissions.PAY_BILLS],
    },
  ],
};

const EXPENSES_LINK: Link = {
  to: "/expenses",
  icon: "credit-card",
  label: "Receipts",
  permissions: EXPENSES_PERMISSIONS,
};

const CARD_FEED_LINK: Link = {
  icon: "credit-card",
  label: "Expenses",
  links: [
    { ...omit(EXPENSES_LINK, ["icon"]), default: true },
    {
      to: "/card-feed",
      label: CARD_FEED_STRINGS.TITLE,
      permissions: EXPENSES_PERMISSIONS,
    },
  ],
};

const VENDORS_LINK: Link = {
  to: "/vendors",
  label: "Vendors",
  icon: "user-helmet-safety",
  permissions: [
    BasePermissions.VIEW_ALL_VENDORS,
    BasePermissions.MANAGE_PAYMENT_VENDORS,
    BasePermissions.MANAGE_NON_PAYMENT_VENDORS,
  ],
};

const PURCHASE_ORDERS_LINK: Link = {
  to: "/purchase-orders",
  label: "Purchase orders",
  icon: "cart-shopping",
  permissions: [
    BasePermissions.ADD_PO,
    BasePermissions.VIEW_ALL_POS,
    BasePermissions.MANAGE_POS,
  ],
};

const REPORTS_LINK: Link = {
  to: "/reports?homeTab=Starred",
  icon: "chart-line-up",
  label: "Reports",
  permissions: [BasePermissions.VIEW_ALL_REPORTS],
};

const CARDS_LINK: Link = {
  to: "/settings/company/cards",
  label: "Card management",
};

const BUILDER_TREND_LINK: Link = {
  to: "/settings/buildertrend",
  label: "BuilderTrend",
};

const SETTINGS_LINKS: Exclude<Link["links"], undefined> = [
  {
    to: "/settings/company/general",
    label: "General",
    permissions: [BasePermissions.COMPANY_ADMIN],
    default: true,
  },
  {
    to: "/settings/profile",
    label: "My profile",
  },
  {
    to: "/settings/company/approval-workflows",
    label: "Approval workflows",
    permissions: [BasePermissions.MANAGE_APPROVAL_WORKFLOWS],
  },
  {
    to: "/settings/company/people",
    label: "People",
    permissions: [BasePermissions.COMPANY_ADMIN],
  },
  {
    to: "/settings/company/bank-accounts",
    label: "Bank accounts",
    permissions: [BasePermissions.COMPANY_ADMIN],
  },
];

type SidebarProps = {
  pin: () => void;
  open: () => void;
  close: () => void;
  unpin: () => void;
  isCollapsed: boolean;
  isFixedExpanded: true | undefined;
};

const getDataDialogParent = (el: HTMLElement | null): HTMLElement | null => {
  while (el && el.parentElement) {
    if (el.hasAttribute("data-dialog")) {
      return el.parentElement;
    }

    el = el.parentElement;
  }

  return null;
};

const SHORTCUTS: Record<"open" | "close", string> = {
  open: "]",
  close: "[",
};

export const Sidebar = memo(
  ({ isCollapsed, open, close, pin, unpin, isFixedExpanded }: SidebarProps) => {
    const settings = useClientSettings();

    const internalRef = useRef<HTMLElement>(null);

    const isMouseInRef = useRef(false);

    const [links, setLinks] = useState<Link[]>([]);

    const { hasSomePermission, hasViewAllJobsPermission, hasPermission } =
      useUserInfo();

    const updateLinks = useCallback(async () => {
      const links: Link[] = [];

      if (await hasViewAllJobsPermission()) {
        links.push(JOBS_LINK);
      }

      if (
        settings.paymentsRevampEnabled &&
        hasPermission(BasePermissions.PAY_BILLS)
      ) {
        links.push(BILLS_WITH_BILL_PAYMENTS_LINKS);
      } else {
        links.push(BILLS_LINK);
      }

      links.push(
        settings.cardFeedEnabled ? CARD_FEED_LINK : EXPENSES_LINK,
        VENDORS_LINK,
        PURCHASE_ORDERS_LINK,
        REPORTS_LINK
      );

      setLinks(links);
    }, [
      hasViewAllJobsPermission,
      settings.paymentsRevampEnabled,
      settings.cardFeedEnabled,
      hasPermission,
    ]);

    const authorizedLinks = useMemo(
      () =>
        links
          .filter(
            (link) => !link.permissions || hasSomePermission(link.permissions)
          )
          .map((link) => ({
            ...link,
            links: link.links
              ? link.links.filter(
                  (subLink) =>
                    !subLink.permissions ||
                    hasSomePermission(subLink.permissions)
                )
              : undefined,
          })) as Link[],
      [hasSomePermission, links]
    );

    const authorizedSettingsLinks = useMemo(() => {
      const baseSettings = SETTINGS_LINKS.filter(
        (link) => !link.permissions || hasSomePermission(link.permissions)
      );

      if (
        settings.capitalOsEnabled ||
        hasPermission(BasePermissions.COMPANY_ADMIN)
      ) {
        baseSettings.push(CARDS_LINK);
      }

      if (settings.buildertrendIntegrationEnabled) {
        baseSettings.push(BUILDER_TREND_LINK);
      }

      return baseSettings;
    }, [
      hasSomePermission,
      settings.buildertrendIntegrationEnabled,
      settings.capitalOsEnabled,
      hasPermission,
    ]);

    const onMouseEnter = useEvent(() => {
      isMouseInRef.current = true;
      open();
    });

    const onMouseOut = useEvent<MouseEventHandler>((e) => {
      const relatedTarget = e.relatedTarget as HTMLElement | null;

      if (internalRef.current?.contains(relatedTarget)) return;

      if (getDataDialogParent(relatedTarget)) return;

      isMouseInRef.current = false;
      close();
    });

    const onBlur = useEvent(() => {
      if (!isMouseInRef.current) close();
    });

    const onPin = useEvent(() => {
      pin();
      analytics.track("sidebarExpand", { trigger: "click" });
    });

    const onUnpin = useEvent(() => {
      unpin();
      analytics.track("sidebarCollapse", { trigger: "click" });
    });

    useKeydown((e) => {
      if (isField(e.target) || hasModifierKey(e) || hasDialogOpen()) return;

      if (e.code === "BracketRight" && !isFixedExpanded) {
        analytics.track("sidebarExpand", { trigger: "shortcut" });
        pin();
      } else if (e.code === "BracketLeft" && isFixedExpanded) {
        analytics.track("sidebarCollapse", { trigger: "shortcut" });
        unpin();
      }
    });

    useEffect(() => {
      updateLinks();
    }, [updateLinks]);

    return (
      <>
        <ShellSidebar
          ref={internalRef}
          onBlur={onBlur}
          onFocus={open}
          compact={isCollapsed}
          onMouseOut={onMouseOut}
          onMouseEnter={onMouseEnter}
        >
          <ShellSidebarHeader className="app-shell-header">
            {!isCollapsed && (
              <Link to="/">
                <BrandingLogo />
              </Link>
            )}
            <Tooltip
              as={Button}
              size="lg"
              color="neutral"
              onClick={!isFixedExpanded ? onPin : onUnpin}
              variant="text"
              placement="right"
              show={isCollapsed ? false : undefined}
              message={
                <Flex gap="md">
                  {!isFixedExpanded ? "Expand" : "Collapse"}
                  <Shortcut
                    letter={SHORTCUTS[!isFixedExpanded ? "open" : "close"]}
                  />
                </Flex>
              }
            >
              <Icon
                name={!isFixedExpanded ? "chevrons-right" : "chevrons-left"}
                color="neutral-800"
              />
            </Tooltip>
          </ShellSidebarHeader>
          <ShellSidebarItem>
            {authorizedLinks.map((link, i) => (
              <ShellSidebarLink key={i} collapsed={isCollapsed} {...link} />
            ))}
            <ShellSidebarSeparator />
            <ShellSidebarLink
              icon="gear"
              label="Settings"
              links={authorizedSettingsLinks}
              collapsed={isCollapsed}
            />
            <SidebarSearch compact={isCollapsed} />
            {settings.notificationCenterEnabled && (
              <SidebarNotifications compact={isCollapsed} />
            )}
            <SidebarChat compact={isCollapsed} />
          </ShellSidebarItem>
          <ShellSidebarFooter>
            <SidebarClient compact={isCollapsed} />
            <SidebarLogout compact={isCollapsed} />
          </ShellSidebarFooter>
        </ShellSidebar>
      </>
    );
  }
);

Sidebar.displayName = "Sidebar";
