import React, { useCallback } from "react";
import {
  Navigate,
  Route,
  Routes as ReactRouterRoutes,
  useNavigate,
} from "react-router";
import { BuilderTrend } from "@buildertrend/components";
import { NotFound } from "@components/not-found";
import { TemplatePage as LienWaiverTemplatePage } from "@lien-waiver/components";
import { withSentryReactRouterV7Routing } from "@sentry/react";
import { useClientSettings } from "@store/user";

import { ProtectedRoute } from "../app/protected-route";
import { BasePermissions, useUserInfo } from "../shared/store/user";

import { ApprovalWorkflows } from "./components/approval-workflows";
import { BankAccounts } from "./components/bank-accounts";
import { Cards, CardsAdaptiveProvider } from "./components/cards";
import { General } from "./components/general";
import { People } from "./components/people";
import { Profile } from "./components/profile";

const Routes = withSentryReactRouterV7Routing(ReactRouterRoutes);

const Redirect = ({ to }: { to: string }) => {
  const navigate = useNavigate();
  navigate(to, { replace: true });
  return null;
};

export const Settings = () => {
  const { hasPermission } = useUserInfo();

  const hasCompanyPermission = hasPermission(BasePermissions.COMPANY_ADMIN);

  const {
    canManageLienWaivers,
    buildertrendIntegrationEnabled,
    capitalOsEnabled,
  } = useClientSettings();

  const validateCardsRoute = useCallback(
    () => hasCompanyPermission || capitalOsEnabled,
    [hasCompanyPermission, capitalOsEnabled]
  );

  return (
    <Routes>
      <Route
        element={
          <Navigate
            to={hasCompanyPermission ? "company/general" : "profile"}
            replace
          />
        }
        path=""
      />
      <Route element={<Profile />} path="profile" />
      <Route path="company">
        <Route
          element={
            <ProtectedRoute somePermissionsOf={[BasePermissions.COMPANY_ADMIN]}>
              <General />
            </ProtectedRoute>
          }
          path="general"
        />
        {canManageLienWaivers && (
          <Route
            element={
              <ProtectedRoute
                allPermissionsOf={[
                  BasePermissions.COMPANY_ADMIN,
                  BasePermissions.CREATE_LIEN_WAIVER_TEMPLATE,
                ]}
              >
                <LienWaiverTemplatePage />
              </ProtectedRoute>
            }
            path="lien-waiver-template/:id"
          />
        )}
        <Route
          element={
            <ProtectedRoute somePermissionsOf={[BasePermissions.COMPANY_ADMIN]}>
              <BankAccounts />
            </ProtectedRoute>
          }
          path="bank-accounts"
        />
        <Route
          element={
            <ProtectedRoute validate={validateCardsRoute}>
              <CardsAdaptiveProvider>
                <Cards />
              </CardsAdaptiveProvider>
            </ProtectedRoute>
          }
          path="cards"
        />
        <Route
          path="adaptive-cards"
          element={<Redirect to="/settings/company/cards" />}
        />
        <Route
          path="plaid-cards"
          element={<Redirect to="/settings/company/cards" />}
        />
        <Route
          element={
            <ProtectedRoute
              somePermissionsOf={[BasePermissions.MANAGE_APPROVAL_WORKFLOWS]}
            >
              <ApprovalWorkflows />
            </ProtectedRoute>
          }
          path="approval-workflows"
        />
        <Route
          element={
            <ProtectedRoute somePermissionsOf={[BasePermissions.COMPANY_ADMIN]}>
              <People />
            </ProtectedRoute>
          }
          path="people"
        ></Route>
      </Route>
      {buildertrendIntegrationEnabled && (
        <Route element={<BuilderTrend />} path="buildertrend"></Route>
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
