import { useState } from "react";
import { useEvent } from "@adaptive/design-system/hooks";
import { putCustomer } from "@api/customers";
import { type PutCustomerProps } from "@api/jobs";
import {
  type KeysToCamelCase,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";

export const useJobUpdate = () => {
  const [isLoading, setIsLoading] = useState(false);

  const mutate = useEvent(
    async (
      jobId: string | number,
      payload: KeysToCamelCase<PutCustomerProps>
    ) => {
      setIsLoading(true);
      const transformedPayload = transformKeysToSnakeCase(payload);

      type TransformedPayload = typeof transformedPayload;

      const enhancedPayload: Omit<TransformedPayload, "address"> & {
        address?: Omit<TransformedPayload["address"], "line_1" | "line_2"> & {
          line1?: string;
          line2?: string;
        };
      } = transformedPayload;

      if (transformedPayload.address) {
        if (transformedPayload.address.line_1) {
          enhancedPayload.address!.line1 = transformedPayload.address.line_1;
        }
        if (transformedPayload.address.line_2) {
          enhancedPayload.address!.line2 = transformedPayload.address.line_2;
        }
      }

      try {
        const result = await putCustomer(
          String(jobId),
          enhancedPayload as PutCustomerProps
        );

        setIsLoading(false);

        return result;
      } catch {
        setIsLoading(false);

        return undefined;
      }
    }
  );

  return [mutate, isLoading] as const;
};
