import React from "react";
import { Avatar, Flex, Text } from "@adaptive/design-system";
import {
  formatCurrency,
  formatDate,
  parseDate,
  parseStringCopy,
} from "@adaptive/design-system/utils";

import { STRINGS } from "../constants/constants";

type BillLienWaiverFieldSignedExternalProps = {
  markedAsSigned?: boolean;
  vendor?: string;
  user?: string;
  time?: string | null;
  customer?: string;
  paymentAmount?: number;
};

export const BillLienWaiverFieldSignedExternal = ({
  markedAsSigned,
  vendor,
  user,
  time,
  customer,
  paymentAmount,
}: BillLienWaiverFieldSignedExternalProps) => {
  const revisionEventDate = time ? parseDate(time, "iso") : null;

  return (
    <Flex width="full" gap="md" align="flex-start">
      <Avatar name="signature" color="success" size="lg" />
      <Flex align="flex-start" direction="column" gap="xs">
        <Text weight="bold">
          {parseStringCopy(
            markedAsSigned
              ? STRINGS.LIEN_WAIVER_SIGNED_UPLOAD_TITLE
              : STRINGS.LIEN_WAIVER_SIGNED_TITLE,
            { vendor, user }
          )}
        </Text>
        <Text size="sm">
          {formatCurrency(`${paymentAmount}`, {
            currencySign: true,
          })}
          {customer ? " - " : ""}
          {customer}
        </Text>

        <Text color="neutral-700" size="xs">
          {parseStringCopy(STRINGS.LIEN_WAIVER_ACTIVITY_TIME, {
            date: formatDate(revisionEventDate, "MM/dd/yy"),
            time: formatDate(revisionEventDate, "hh:mma"),
          })}
        </Text>
      </Flex>
    </Flex>
  );
};
