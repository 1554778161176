import { type useForm } from "@adaptive/design-system/hooks";
import { PAYMENT_METHOD } from "@bill-payment/constants";
import type {
  BankAccountOption,
  DataBankAccount,
  MultiplePaymentForm,
} from "@bill-payment/types";
import { type CombinedPaymentMethods } from "@bills/types";
import type { MissingFields } from "@lien-waiver/types";
import { noop } from "@utils/noop";
import type { Stage } from "@vendors/types";
import { createContext, useContextSelector } from "use-context-selector";

export type OnChangeLienWaiverHandler = (
  value: string,
  extra?: { customer?: string; index?: number; missingFields?: MissingFields }
) => void;

export type CombinedPaymentDialogContextType = {
  combinedPayments: boolean;
  paymentOptionsLoading: boolean;
  enhancedOnRemoveBill: (billId: string, index: number) => void;
  onEditVendorCredits: (index: number) => void;
  curriedOpenVendor: (vendorId: string, stage: Stage) => () => void;
  registerFormControl: ReturnType<typeof useForm>["register"];
  paymentOptionsByVendor?: Record<string, DataBankAccount>;
  onChangeLienWaiverTemplate?: OnChangeLienWaiverHandler;
  onChangePayFrom?: (value: string, vendorId: string, index: number) => void;
  onSetPaymentAccount?: (account: BankAccountOption) => void;
  formValues?: MultiplePaymentForm;
  vendorCreditBalanceByVendor?: Record<string, number>;
  paymentMethod: CombinedPaymentMethods;
};

export const CombinedPaymentDialogContext =
  createContext<CombinedPaymentDialogContextType>({
    combinedPayments: false,
    registerFormControl: noop,
    paymentOptionsByVendor: undefined,
    paymentOptionsLoading: false,
    enhancedOnRemoveBill: noop,
    curriedOpenVendor: noop,
    onChangeLienWaiverTemplate: noop,
    onChangePayFrom: noop,
    onSetPaymentAccount: noop,
    onEditVendorCredits: noop,
    formValues: undefined,
    paymentMethod: PAYMENT_METHOD.MARK_AS_PAID,
  });

export const useRegisterFormControl = () =>
  useContextSelector(
    CombinedPaymentDialogContext,
    (context) => context.registerFormControl
  );

export const usePaymentMethod = () =>
  useContextSelector(
    CombinedPaymentDialogContext,
    (context) => context.paymentMethod
  );

export const usePaymentOptionsByVendor = () =>
  useContextSelector(
    CombinedPaymentDialogContext,
    (context) => context.paymentOptionsByVendor
  );

export const usePaymentOptionsLoading = () =>
  useContextSelector(
    CombinedPaymentDialogContext,
    (context) => context.paymentOptionsLoading
  );

export const useEnhancedOnRemoveBill = () =>
  useContextSelector(
    CombinedPaymentDialogContext,
    (context) => context.enhancedOnRemoveBill
  );

export const useCurriedOpenVendor = () =>
  useContextSelector(
    CombinedPaymentDialogContext,
    (context) => context.curriedOpenVendor
  );

export const useOnChangeLienWaiverTemplate = () =>
  useContextSelector(
    CombinedPaymentDialogContext,
    (context) => context.onChangeLienWaiverTemplate
  );

export const useOnChangePayFrom = () =>
  useContextSelector(
    CombinedPaymentDialogContext,
    (context) => context.onChangePayFrom
  );

export const useOnSetPaymentAccount = () =>
  useContextSelector(
    CombinedPaymentDialogContext,
    (context) => context.onSetPaymentAccount
  );

export const useFormValues = () =>
  useContextSelector(
    CombinedPaymentDialogContext,
    (context) => context.formValues
  );

export const useCombinedPayments = () =>
  useContextSelector(
    CombinedPaymentDialogContext,
    (context) => context.combinedPayments
  );
