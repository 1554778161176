import React, {
  createContext,
  type Dispatch,
  type FC,
  type PropsWithChildren,
  type ReactNode,
  type SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { ComboBox, type TableColumn, Text } from "@adaptive/design-system";
import { useDeepMemo } from "@adaptive/design-system/hooks";
import {
  dotObject,
  formatCurrency,
  isEqual,
  parseStringCopy,
} from "@adaptive/design-system/utils";
import {
  useGetAccountsSimplifiedQuery,
  useGetCostCodesSimplifiedQuery,
  useGetCustomersSimplifiedQuery,
  useGetVendorsSimplifiedQuery,
} from "@store/api-simplified";
import { useClientInfo } from "@store/user/hooks";
import { noop } from "@utils/noop";

import {
  COMBOBOX_VALUES,
  FIELD_STRINGS,
  STRINGS,
  TYPE_STRINGS,
} from "../constants/constants";
import { usePropagationContext } from "../hooks/use-propagation-context";
import type {
  LinkObject,
  ParentInfoObject,
  PropagationDetails,
  PropagationTableDataType,
} from "../types";
import { getDetailsFromUrl } from "../utils/get-details-from-url";
import { getDialogHeader } from "../utils/get-dialog-header";
import { getDisplayInfo } from "../utils/get-display-info";
import { getFieldsToShow } from "../utils/get-fields-to-show";
import { getFieldsToSkip } from "../utils/get-fields-to-skip";

import { PropagationTableContext } from "./propagation-table-context";

type PropagationTableProps = PropsWithChildren<{
  skip?: () => void;
  stepName: string;
  linkedItem: LinkObject;
  parentInfo: ParentInfoObject;
  setTitle: Dispatch<SetStateAction<ReactNode>>;
}>;

type BaseFields =
  | PropagationDetails
  | Exclude<PropagationDetails["lines"], undefined>[number]
  | undefined;

const getEmptyTitle = (field: string) =>
  `No ${(FIELD_STRINGS[field] ?? field).toLocaleLowerCase()}`;

const PropagationTableInternalContext = createContext({
  register: noop,
  getVendor: noop,
  stepName: "",
  linkedItem: {} as LinkObject,
  parentInfo: {} as ParentInfoObject,
  foundLinkedParent: {} as LinkObject | undefined,
  getCustomer: noop,
  findAccountCostCode: noop,
});

const TransactionItem = ({
  field,
  newValue,
  prevValue,
  lineAmount,
}: PropagationTableDataType) => {
  const { getVendor, getCustomer, findAccountCostCode } = useContext(
    PropagationTableInternalContext
  );

  let text = newValue || getEmptyTitle(field);
  let subtitle = prevValue || getEmptyTitle(field);

  if (field === "vendor") {
    const newVendor = getVendor(newValue)?.label;
    const oldVendor = getVendor(prevValue)?.label;
    if (newVendor) text = newVendor;
    if (oldVendor) subtitle = oldVendor;
  }

  if (field === "customer") {
    const newCustomer = getCustomer(newValue)?.label;
    const oldCustomer = getCustomer(prevValue)?.label;
    if (newCustomer) text = newCustomer;
    if (oldCustomer) subtitle = oldCustomer;
  }

  if (field === "accountCostCode") {
    const foundAccountCostCode = findAccountCostCode(newValue);
    const newAccountCostCode = (
      foundAccountCostCode.costCode || foundAccountCostCode.account
    )?.label;
    if (newAccountCostCode) text = newAccountCostCode;

    const foundOldAccountCostCode = findAccountCostCode(prevValue);
    const oldAccountCostCode = (
      foundOldAccountCostCode.costCode || foundOldAccountCostCode.account
    )?.label;
    if (oldAccountCostCode) subtitle = oldAccountCostCode;
  }

  if (field === "delete") {
    text = STRINGS.LINE_DELETED;
    const oldAccountCostCode = findAccountCostCode(prevValue);
    const itemValue =
      (oldAccountCostCode.costCode || oldAccountCostCode.account)?.label ||
      prevValue;
    subtitle = `${itemValue}${lineAmount && ` (${formatCurrency(lineAmount, { currencySign: true })})`}`;
  }

  if (["archived", "reverted", "delete"].includes(field)) {
    if (field === "reverted") text = STRINGS.BILL_REVERTED;
    if (field === "archived") text = STRINGS.BILL_ARCHIVED;
    if (field === "deleted") text = STRINGS.LINE_DELETED;
    const oldAccountCostCode = findAccountCostCode(prevValue);
    const itemValue =
      (oldAccountCostCode.costCode || oldAccountCostCode.account)?.label ||
      prevValue;
    subtitle = `${itemValue}${lineAmount && ` (${formatCurrency(lineAmount, { currencySign: true })})`}`;
  }

  return (
    <>
      <Text truncate size="sm" decoration="line-through">
        {subtitle}
      </Text>
      <Text truncate size="md">
        {text}
      </Text>
    </>
  );
};

const PropagationItem = ({
  newValue,
  oldValue,
  prevValue,
  field,
}: PropagationTableDataType) => {
  const {
    register,
    stepName,
    getVendor,
    linkedItem,
    parentInfo,
    getCustomer,
    findAccountCostCode,
  } = useContext(PropagationTableInternalContext);

  const label = FIELD_STRINGS[field] ?? TYPE_STRINGS[linkedItem.type];

  const copyParams = useDeepMemo(
    () => ({
      fieldType: label.toLocaleLowerCase(),
      parentType: getDisplayInfo(parentInfo, {
        hideName: true,
        capitalize: false,
      }),
      itemType: getDisplayInfo(linkedItem, {
        hideName: true,
        capitalize: false,
      }),
    }),
    [label, parentInfo, linkedItem]
  );

  const data = useDeepMemo(() => {
    let options = [
      {
        label: newValue || getEmptyTitle(field),
        value: COMBOBOX_VALUES.NEW,
        description: parseStringCopy(
          STRINGS.OPTION_DESCRIPTION_UPDATE,
          copyParams
        ),
      },
      {
        label: oldValue || getEmptyTitle(field),
        value: COMBOBOX_VALUES.OLD,
        description: parseStringCopy(
          STRINGS.OPTION_DESCRIPTION_KEEP,
          copyParams
        ),
      },
    ];

    if (field === "vendor") {
      const newVendor = getVendor(newValue)?.label;
      const oldVendor = getVendor(oldValue)?.label;
      options[0].label = newVendor || newValue;

      if (["bill", "purchase_order"].includes(linkedItem.type)) {
        options = [options[0]];
      } else {
        options[1] = {
          label: oldVendor || oldValue,
          description: parseStringCopy(
            STRINGS.OPTION_DESCRIPTION_KEEP_UNLINK,
            copyParams
          ),
          value: COMBOBOX_VALUES.UNLINK,
        };
      }
    }

    if (field === "customer") {
      const newCustomer = getCustomer(newValue)?.label || newValue;
      const oldCustomer =
        getCustomer(oldValue)?.label || oldValue || getEmptyTitle(field);

      if (copyParams.itemType.includes("draw")) {
        options[0] = {
          label: `Delete line on ${copyParams.itemType}`,
          value: COMBOBOX_VALUES.DELETE,
          description: STRINGS.JOB_CANNOT_BE_CHANGED,
        };
        const drawLineCustomer =
          getCustomer(prevValue)?.label || prevValue || getEmptyTitle(field);
        options[1] = {
          label: drawLineCustomer,
          description: parseStringCopy(
            STRINGS.OPTION_DESCRIPTION_KEEP_UNLINK,
            copyParams
          ),
          value: COMBOBOX_VALUES.UNLINK,
        };
      } else {
        options[0].label = newCustomer;
        options[1].label = oldCustomer;
      }
    }

    if (["delete", "reverted", "archived"].includes(field)) {
      options[0] = {
        label: `Delete ${TYPE_STRINGS[linkedItem.type].toLocaleLowerCase()}`,
        description: [null],
        value: "delete",
      };
      const oldAccountCostCode = findAccountCostCode(oldValue);
      const itemValue =
        (oldAccountCostCode.costCode || oldAccountCostCode.account)?.label ||
        oldValue;
      const lineAmount = linkedItem.fields.amount;
      options[1] = {
        label: `${itemValue}${lineAmount && ` (${formatCurrency(lineAmount, { currencySign: true })})`}`,
        description: parseStringCopy(STRINGS.OPTION_DELETED_KEEP_DESCRIPTION, {
          parentType: copyParams.parentType,
        }),
        value: "unlink",
      };
    }

    if (field === "accountCostCode") {
      const newAccountCostCode = findAccountCostCode(newValue);
      const newType = newAccountCostCode.costCode ? "item" : "account";
      options[0].label =
        (newAccountCostCode.costCode || newAccountCostCode.account)?.label ||
        newValue;
      options[0].description = parseStringCopy(
        STRINGS.OPTION_DESCRIPTION_UPDATE,
        {
          ...copyParams,
          fieldType: FIELD_STRINGS[newType].toLocaleLowerCase(),
        }
      );

      const oldAccountCostCode = findAccountCostCode(oldValue);
      const oldType = oldAccountCostCode.costCode ? "item" : "account";
      options[1].label =
        (oldAccountCostCode.costCode || oldAccountCostCode.account)?.label ||
        oldValue;
      options[1].description = parseStringCopy(
        STRINGS.OPTION_DESCRIPTION_KEEP,
        {
          ...copyParams,
          fieldType: FIELD_STRINGS[oldType].toLocaleLowerCase(),
        }
      );
    }

    return options;
  }, [
    field,
    newValue,
    oldValue,
    prevValue,
    getVendor,
    copyParams,
    getCustomer,
    linkedItem.type,
    findAccountCostCode,
    linkedItem.fields.amount,
  ]);

  const comboboxProps = register(`${stepName}.${field}`);

  const initialValue = data[0].value;
  const comboboxValue = comboboxProps?.value;
  const comboboxOnChange = comboboxProps?.onChange;

  useEffect(() => {
    if (!comboboxValue) comboboxOnChange?.(initialValue);
  }, [initialValue, comboboxValue, comboboxOnChange]);

  return (
    <ComboBox
      data={data}
      label={label}
      listSize={3}
      messageVariant="hidden"
      renderOption={(option) => (
        <Text size="md">
          {option.label}
          <br />
          <Text as="small" size="xs">
            {option.description as string}
          </Text>
        </Text>
      )}
      {...comboboxProps}
    />
  );
};

const COLUMNS = [
  {
    id: "transactionItem",
    name: (
      <PropagationTableInternalContext.Consumer>
        {({ foundLinkedParent, parentInfo }) => (
          <Text weight="bold">
            Changes made on{" "}
            {getDisplayInfo(foundLinkedParent ?? parentInfo, {
              withLink: true,
            })}
          </Text>
        )}
      </PropagationTableInternalContext.Consumer>
    ),
    width: "half",
    highlight: "neutral",
    render: (row) => <TransactionItem {...row} />,
  },
  {
    id: "propagationItem",
    name: (
      <PropagationTableInternalContext.Consumer>
        {({ linkedItem }) => (
          <Text weight="bold">
            Confirm changes to {getDisplayInfo(linkedItem, { withLink: true })}
          </Text>
        )}
      </PropagationTableInternalContext.Consumer>
    ),
    width: "half",
    render: (row) => <PropagationItem {...row} />,
  },
] as TableColumn<PropagationTableDataType>[];

export const PropagationTableProvider: FC<PropagationTableProps> = ({
  skip,
  children,
  stepName,
  parentInfo,
  linkedItem,
  setTitle,
}) => {
  const { realmId } = useClientInfo();

  const { form, propagationFields, linkedTransactions, isForcedPropagation } =
    usePropagationContext();

  const baseFields = useMemo<BaseFields>(() => {
    return ["line", "invoice_line"].includes(parentInfo.type)
      ? propagationFields?.lines?.find(
          (item) => Number(item.id) === Number(parentInfo.id)
        )
      : propagationFields;
  }, [parentInfo.id, parentInfo.type, propagationFields]);

  const foundLinkedParent = useMemo(() => {
    const isVendorChange =
      baseFields?.fields &&
      "vendor" in baseFields.fields &&
      !!baseFields.fields.vendor;
    const linkedParentUrl =
      linkedItem.fields.linkedLine?.[isVendorChange ? "parent" : "url"];

    if (!linkedParentUrl) return;

    const { type, id } = getDetailsFromUrl(linkedParentUrl);
    return linkedTransactions
      ?.flatMap((item) => item.links)
      .find((link) => link.id === id && link.type === type);
  }, [baseFields?.fields, linkedItem.fields.linkedLine, linkedTransactions]);

  const linkedParent = useMemo<ParentInfoObject>(() => {
    if (!foundLinkedParent) return parentInfo;

    return {
      id: foundLinkedParent.id,
      name:
        foundLinkedParent.fields.parent?.name || foundLinkedParent.fields.name,
      type: foundLinkedParent.type,
      url: linkedItem.fields.linkedLine?.url,
    } as ParentInfoObject;
  }, [foundLinkedParent, linkedItem.fields.linkedLine?.url, parentInfo]);

  const fieldsToSkip = useDeepMemo(
    () =>
      getFieldsToSkip({
        values: form?.values ?? {},
        stepName,
        sourceUrl: propagationFields?.url,
        linkedTransactions,
      }),
    [form?.values, stepName, propagationFields?.url, linkedTransactions]
  );

  const fieldsToShow = useMemo(() => {
    const rawFields = Object.keys(linkedItem.fields);

    if (
      baseFields?.fields?.customer &&
      linkedItem.type === "invoice_line" &&
      !fieldsToSkip.some((item) => item.includes(".customer"))
    ) {
      return ["customer"];
    }

    if (baseFields?.fields?.deleted) {
      rawFields.push("deleted");
    }
    if (propagationFields?.fields?.reverted) {
      rawFields.push("reverted");
    }
    if (propagationFields?.fields?.archived) {
      rawFields.push("archived");
    }

    return getFieldsToShow({
      values: baseFields?.fields,
      prevValues: baseFields?.prevValues,
      fields: rawFields.filter(
        (field) => !fieldsToSkip.some((item) => item.includes(`.${field}`))
      ),
      isForcedPropagation,
    });
  }, [
    fieldsToSkip,
    linkedItem.fields,
    linkedItem.type,
    baseFields?.fields,
    isForcedPropagation,
    baseFields?.prevValues,
    propagationFields,
  ]);

  const dialogTitle = useDeepMemo(
    () =>
      getDialogHeader({
        parent: foundLinkedParent ?? parentInfo,
        fieldsToShow,
        propagationFields,
        linkedTransactions,
        linkedItem,
      }),
    [
      fieldsToShow,
      foundLinkedParent,
      linkedItem,
      linkedTransactions,
      parentInfo,
      propagationFields,
    ]
  );

  useEffect(() => {
    setTitle((prevTitle) =>
      !isEqual(prevTitle, dialogTitle) ? dialogTitle : prevTitle
    );
  }, [dialogTitle, setTitle]);

  const data = useMemo(() => {
    if (
      fieldsToShow.includes("reverted") ||
      fieldsToShow.includes("archived")
    ) {
      const oldValue =
        linkedItem.fields.account || linkedItem.fields.item || "";
      const prevValue =
        baseFields && "parent" in baseFields
          ? baseFields?.prevValues?.account ||
            baseFields?.prevValues?.item ||
            oldValue
          : oldValue;

      return [
        {
          field: fieldsToShow.includes("reverted") ? "reverted" : "archived",
          prevValue,
          newValue: "true",
          oldValue,
          lineAmount:
            baseFields && "amount" in baseFields && baseFields.amount
              ? baseFields.amount
              : undefined,
        },
      ];
    }

    if (baseFields?.fields?.deleted) {
      const oldValue =
        linkedItem.fields.account || linkedItem.fields.item || "";
      const prevValue =
        "parent" in baseFields
          ? baseFields?.prevValues?.account ||
            baseFields?.prevValues?.item ||
            oldValue
          : oldValue;

      return [
        {
          field: "delete",
          prevValue,
          newValue: "true",
          oldValue,
          lineAmount:
            "amount" in baseFields && baseFields.amount
              ? baseFields.amount
              : undefined,
        },
      ];
    }

    const isLinkedParent =
      foundLinkedParent &&
      (foundLinkedParent.id !== parentInfo.id ||
        foundLinkedParent.type !== parentInfo.type);
    const prevValueSource = isLinkedParent
      ? foundLinkedParent?.fields
      : baseFields?.prevValues;

    return fieldsToShow.reduce((acc, field) => {
      const isAccountCostCode = ["item", "account"].includes(field);
      if (isAccountCostCode) {
        if (acc.find((item) => item.field === "accountCostCode")) return acc;

        const newValue = baseFields?.fields
          ? (dotObject.get(baseFields.fields, "item") ??
            dotObject.get(baseFields.fields, "account"))
          : "";

        const prevValue = prevValueSource
          ? (dotObject.get(prevValueSource, "item") ??
            dotObject.get(prevValueSource, "account"))
          : "";
        const oldValue =
          dotObject.get(linkedItem.fields, "item") ??
          dotObject.get(linkedItem.fields, "account");

        if (
          newValue !== prevValue ||
          (isForcedPropagation && newValue !== oldValue)
        ) {
          acc.push({
            field: "accountCostCode",
            prevValue,
            newValue,
            oldValue,
          });
        }

        return acc;
      }

      const newValue = baseFields?.fields
        ? dotObject.get(baseFields.fields, field)
        : "";
      const prevValue = prevValueSource
        ? dotObject.get(prevValueSource, field)
        : "";

      acc.push({
        field,
        prevValue,
        newValue,
        oldValue: dotObject.get(linkedItem.fields, field),
      });

      return acc;
    }, [] as PropagationTableDataType[]);
  }, [
    baseFields,
    foundLinkedParent,
    parentInfo.id,
    parentInfo.type,
    fieldsToShow,
    linkedItem.fields,
    isForcedPropagation,
  ]);

  const { data: vendorsSimplified } = useGetVendorsSimplifiedQuery(
    { realm: realmId as number },
    { skip: !realmId || !fieldsToShow.includes("vendor") }
  );

  const getVendor = useCallback(
    (value: string) =>
      vendorsSimplified?.find((vendor) => vendor.value === value),
    [vendorsSimplified]
  );

  const { data: customersSimplified } = useGetCustomersSimplifiedQuery(
    { realm: realmId as number },
    { skip: !realmId || !fieldsToShow.includes("customer") }
  );

  const getCustomer = useCallback(
    (value: string) =>
      customersSimplified?.find((vendor) => vendor.value === value),
    [customersSimplified]
  );

  const shouldSkipAccountCostCode =
    !!fieldsToShow.length &&
    !fieldsToShow.includes("account") &&
    !fieldsToShow.includes("item") &&
    !fieldsToShow.includes("deleted");

  const { data: accountsSimplified } = useGetAccountsSimplifiedQuery(
    { realm: realmId as number },
    { skip: !realmId || shouldSkipAccountCostCode }
  );

  const { data: costCodesSimplified } = useGetCostCodesSimplifiedQuery(
    { realm: realmId as number },
    { skip: !realmId || shouldSkipAccountCostCode }
  );

  const findAccountCostCode = useCallback(
    (value: string) => {
      return {
        account: accountsSimplified?.find((account) => account.value === value),
        costCode: costCodesSimplified?.find(
          (costCode) => costCode.value === value
        ),
      };
    },
    [accountsSimplified, costCodesSimplified]
  );

  const value = useMemo(() => ({ data, columns: COLUMNS }), [data]);

  const internalValue = useMemo(
    () => ({
      register: form?.register ?? noop,
      stepName,
      getVendor,
      linkedItem,
      parentInfo: linkedParent,
      getCustomer,
      findAccountCostCode,
      foundLinkedParent,
    }),
    [
      form?.register,
      stepName,
      getVendor,
      linkedParent,
      foundLinkedParent,
      linkedItem,
      getCustomer,
      findAccountCostCode,
    ]
  );

  useEffect(() => {
    if (fieldsToShow.length === 0) skip?.();
  }, [skip, fieldsToShow]);

  return (
    <PropagationTableContext.Provider value={value}>
      <PropagationTableInternalContext.Provider value={internalValue}>
        {children}
      </PropagationTableInternalContext.Provider>
    </PropagationTableContext.Provider>
  );
};
