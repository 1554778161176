import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  ButtonGroup,
  ComboBox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  Text,
} from "@adaptive/design-system";
import { PDF_BASE64_TYPE } from "@components/pdf-viewer";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";

import { useRenderBillLienWaiverPdfQuery } from "../api/api";
import { STRINGS } from "../constants";
import { TEMPLATE_NOT_REQUESTED_ID } from "../constants/constants";
import type { AmountByCustomer } from "../types";
import {
  lienWaiverTemplateTypesSelector,
  simplifiedLienWaiverTemplatesSelector,
} from "../utils/selectors";

import { type BillLienWaiverFieldProps } from "./bill-lien-waiver-field";
import { TemplatePreview } from "./template-preview";

type BillLienWaiverPreviewDrawerProps = {
  show: boolean;
  customers?: AmountByCustomer[];
  onClose: () => void;
  value?: string;
  billIds?: string[];
  pdf?: string;
} & Pick<
  BillLienWaiverFieldProps,
  "paymentAmount" | "billPaymentId" | "netPaymentAmount"
>;

export const BillLienWaiverPreviewDrawer = ({
  show,
  onClose,
  value,
  paymentAmount,
  netPaymentAmount,
  billIds,
  billPaymentId,
  customers,
  pdf,
}: BillLienWaiverPreviewDrawerProps) => {
  const [selectedTemplate, setSelectedTemplate] = useState(value);
  const [currentCustomer, setCurrentCustomer] = useState<number>(0);

  const lastCustomerIndex = (customers?.length || 0) - 1;

  const customer = useMemo(
    () => customers?.[currentCustomer],
    [customers, currentCustomer]
  );

  const lienWaiverTemplates = useSelector(
    simplifiedLienWaiverTemplatesSelector
  );

  const liewnWaiverTemplateTypes = useSelector(lienWaiverTemplateTypesSelector);

  const template = useMemo(() => {
    return lienWaiverTemplates.find(
      (template) => template.url === selectedTemplate
    );
  }, [lienWaiverTemplates, selectedTemplate]);

  const { data, isLoading } = useRenderBillLienWaiverPdfQuery(
    {
      billIds: billIds ? billIds : [],
      customerId: parseRefinementIdFromUrl(customer?.customer),
      lienWaiverTemplateId: template!.id,
      billPaymentId: billPaymentId!,
      paymentAmount: (customer?.paymentAmount || paymentAmount) ?? null,
      netPaymentAmount:
        (customer?.netPaymentAmount || netPaymentAmount) ?? null,
    },
    { skip: Boolean(!billIds || !template || !show || pdf) }
  );

  const templatesData = useMemo(() => {
    return lienWaiverTemplates
      .filter((template) => !!template.url)
      .map((template) => ({
        label:
          liewnWaiverTemplateTypes.find((type) => type.url === template.type)
            ?.name || template.type,
        value: template.url || TEMPLATE_NOT_REQUESTED_ID,
      }));
  }, [lienWaiverTemplates, liewnWaiverTemplateTypes]);

  const navigateToEditTemplate = useCallback(() => {
    const templateId = lienWaiverTemplates.find(
      (template) => template.url === selectedTemplate
    )?.id;

    if (!templateId) return;

    window.open(
      `/settings/company/lien-waiver-template/${templateId}`,
      "_blank"
    );
    onClose();
  }, [onClose, lienWaiverTemplates, selectedTemplate]);

  return (
    <Dialog variant="drawer" show={show} onClose={() => onClose()}>
      <DialogHeader>
        <Flex justify="space-between">
          <Flex direction="column" gap="sm">
            <Flex align="center">
              <Flex gap="lg" align="baseline">
                {STRINGS.LIEN_WAIVER_PREVIEW}{" "}
                {(customers?.length || 0) > 1 && (
                  <Text weight="semi-bold" size="lg">
                    ({currentCustomer + 1} of {customers?.length})
                  </Text>
                )}
              </Flex>
            </Flex>
            <Text size="sm">
              Job:{" "}
              {customers?.[currentCustomer]?.customerDisplayName &&
                customers?.[currentCustomer]?.customerDisplayName}
            </Text>
          </Flex>
          {(customers?.length || 0) > 1 ? (
            <Flex>
              <ButtonGroup
                size="sm"
                color="neutral"
                variant="ghost"
                direction="vertical"
              >
                <Button
                  onClick={() =>
                    setCurrentCustomer((prev) =>
                      prev === 0 ? lastCustomerIndex : prev - 1
                    )
                  }
                  aria-label="Go to previous customer"
                >
                  <Icon variant="solid" name="chevron-up" />
                </Button>
                <Button
                  onClick={() =>
                    setCurrentCustomer((prev) =>
                      prev === lastCustomerIndex ? 0 : prev + 1
                    )
                  }
                  aria-label="Go to next customer"
                >
                  <Icon variant="solid" name="chevron-down" />
                </Button>
              </ButtonGroup>
            </Flex>
          ) : null}
        </Flex>
      </DialogHeader>

      <DialogContent>
        {!pdf && (
          <Flex width="full" maxWidth="420px" padding={["none", "none", "2xl"]}>
            <ComboBox
              data={templatesData}
              messageVariant="hidden"
              value={selectedTemplate}
              onChange={setSelectedTemplate}
            />
          </Flex>
        )}
        {data?.missingFields ? (
          <Alert variant="warning">
            <AlertTitle>{STRINGS.MISSING_FIELDS_PREVIEW_PDF_TITLE}</AlertTitle>
            <AlertContent>
              {STRINGS.MISSING_FIELDS_PREVIEW_PDF_BODY}
            </AlertContent>
          </Alert>
        ) : null}
        <TemplatePreview
          docType={!pdf ? PDF_BASE64_TYPE : undefined}
          docSource={pdf ?? data?.pdf}
          loading={!pdf && isLoading}
        />
      </DialogContent>
      <DialogFooter>
        <Flex width="full" justify="space-between" align="center">
          <Button variant="text" color="neutral" onClick={() => onClose()}>
            {STRINGS.CLOSE_PREVIEW}
          </Button>
          {!pdf && (
            <Button variant="ghost" onClick={navigateToEditTemplate}>
              {STRINGS.EDIT_PREVIEW_TEMPLATE}
            </Button>
          )}
        </Flex>
      </DialogFooter>
    </Dialog>
  );
};
