import React, { useCallback, useEffect, useMemo } from "react";
import {
  Button,
  Card,
  ComboBox,
  Flex,
  Loader,
  PasswordField,
  Text,
  TextField,
  toast,
} from "@adaptive/design-system";
import { useForm } from "@adaptive/design-system/hooks";
import { formatDate } from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import { z } from "zod";

import { useBuildertrend } from "../hooks/use-buildertrend";

const schema = z.object({
  username: z.string().min(1, "Username is required"),
  password: z.string().min(1, "Password is required"),
});
type Fields = z.infer<typeof schema>;

const INITIAL_VALUES = {
  username: "",
  password: "xxxxxx",
};

export const Settings = () => {
  const {
    credential,
    updateBuilderTrendCredentials,
    syncBuilderTrendCredentials,
    createBuilderTrendCredentials,
    syncInProgress,
    syncBuilderTrendCredentialsIsLoading,
    builders,
    buildersIsLoading,
    updateBuilderTrendCredentialsDefaultBuilder,
  } = useBuildertrend();

  const wasEverSynced = useMemo(
    () => !!credential?.lastSyncedAt,
    [credential?.lastSyncedAt]
  );

  const form = useForm<Fields>({
    schema,
    async onSubmit(values) {
      try {
        if (!credential?.id) {
          await createBuilderTrendCredentials({
            username: values.username,
            password: values.password,
          }).unwrap();
        } else {
          await updateBuilderTrendCredentials({
            id: credential?.id || "",
            username: values.username,
            password: values.password,
          }).unwrap();
        }
        toast.success("Credentials updated");
      } catch (error) {
        handleErrors(error);
      }
    },
    initialValues: INITIAL_VALUES,
  });

  const onUpdateDefaultBuilder = useCallback(
    async (value: string) => {
      try {
        await updateBuilderTrendCredentialsDefaultBuilder({
          id: credential?.id || "",
          defaultBuilder: value,
        }).unwrap();
        toast.success("Account updated");
      } catch (error) {
        handleErrors(error);
      }
    },
    [credential?.id, updateBuilderTrendCredentialsDefaultBuilder]
  );

  const setValue = form.setValue;
  useEffect(() => {
    if (credential) {
      setValue("username", credential.username || INITIAL_VALUES.username);
      setValue("password", credential.password || INITIAL_VALUES.password);
    }
  }, [credential, setValue]);

  return (
    <Flex direction="column" gap="xl" padding={["2xl", "none", "none"]}>
      <Card>
        <Flex direction="column" gap="2xl" separator>
          <Flex as="form" {...form.props} direction="column" gap="md">
            <Flex direction="column">
              <TextField
                label="Username"
                required
                {...form.register("username")}
              />
              <PasswordField
                label="Password"
                required
                {...form.register("password")}
              />
            </Flex>
            <Button type="submit" disabled={form.isSubmitting}>
              {form.isSubmitting ? <Loader /> : "Save"}
            </Button>
          </Flex>
          {credential && builders?.length ? (
            <Flex direction="column" gap="md">
              <Text>Account</Text>
              <ComboBox
                data={builders?.map((builder) => ({
                  label: builder.name || "",
                  value: builder.url || "",
                }))}
                value={credential.defaultBuilder?.url || ""}
                onChange={onUpdateDefaultBuilder}
                disabled={buildersIsLoading || wasEverSynced}
                helperMessage={
                  wasEverSynced
                    ? "You cannot change accounts after the first sync. Reach out to support if you need to change your account."
                    : undefined
                }
                loading={buildersIsLoading}
                data-testid="builder-trend-default-builder"
              />
            </Flex>
          ) : null}
          {credential && credential.defaultBuilder ? (
            <Flex direction="column" gap="md">
              <Text>
                Last synced at:{" "}
                {credential?.lastSyncedAt
                  ? formatDate(credential.lastSyncedAt)
                  : "Never"}
              </Text>
              <Text>
                Sync status: {credential?.syncStatus || "Never synced"}
              </Text>
              {credential?.id && (
                <Button
                  onClick={() => syncBuilderTrendCredentials(credential.id)}
                  disabled={
                    syncBuilderTrendCredentialsIsLoading || syncInProgress
                  }
                >
                  {syncBuilderTrendCredentialsIsLoading || syncInProgress ? (
                    <Loader />
                  ) : (
                    "Sync now"
                  )}
                </Button>
              )}
            </Flex>
          ) : null}
        </Flex>
      </Card>
    </Flex>
  );
};
