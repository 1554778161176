import React, { useEffect, useMemo } from "react";
import {
  Button,
  ComboBox,
  dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Text,
  TextField,
  toast,
} from "@adaptive/design-system";
import { useEvent, useForm } from "@adaptive/design-system/hooks";
import { isEqual, omit } from "@adaptive/design-system/utils";
import type {
  Workflow,
  WorkflowCustomer,
  WorkflowItem,
  WorkflowsRequest,
  WorkflowVendor,
} from "@api/workflows";
import { useDrawerVisibility } from "@store/ui";
import { useClientAction } from "@store/user";
import { useWorkflowAction, useWorkflowInfo } from "@store/workflow";
import * as analytics from "@utils/analytics";
import { getUrlBuilder } from "@utils/api";

import {
  type AccountCondition,
  type AmountCondition,
  type BudgetCondition,
  type Condition,
  type CustomerCondition,
  type Fields,
  type ItemCondition,
  schema,
  type StepTypes,
  type VendorCondition,
} from "./form";
import { WorkflowConditions } from "./workflow-conditions";
import { WorkflowSteps, type WorkflowStepsProps } from "./workflow-steps";

const appliesTo = [
  { label: "Bills", value: "bill" },
  /*{ label: "Receipts", value: "expense" },*/
  ...(window.WORKFLOW_APPROVAL_DRAWS_ENABLED
    ? [{ label: "Draws", value: "invoice" }]
    : []),
];

const transformWorkflowToForm = (workflow: Workflow) => {
  const steps = workflow.steps.map((step) => ({
    requirementType: step.requirementType as StepTypes,
    approvers: step.approvers.map((approver) => approver.url),
  }));

  const conditions: Condition[] = [];

  const amountCondition: AmountCondition = {
    type: "AMOUNT",
    amountFrom: workflow?.amountFrom ? +workflow?.amountFrom : null,
    amountTo: workflow?.amountTo ? +workflow?.amountTo : null,
  };

  /**
   * There was some backend code (https://github.com/AdaptiveZoning/bookkeeping/blob/04c261ebcf4bf5296aec9ae53cdb21492504f295/backend/approvals/serializers/workflow_template.py#L200) that made it
   * so a bunch of approval workflow templates had a default `from` amount. This boolean
   * statement handles that. It's silly, but c'est la vie.
   */
  const hasAmountFrom =
    !isNaN(Number(workflow.amountFrom)) && Number(workflow.amountFrom) > 0;
  const hasAmountCondition =
    workflow.id && (hasAmountFrom || workflow.amountTo);
  if (!workflow.id || hasAmountCondition) {
    conditions.push(amountCondition);
  }

  if (workflow.budgetExceededBy) {
    const budgetCondition: BudgetCondition = {
      type: "BUDGET",
      budgetExceededBy: +workflow?.budgetExceededBy,
    };

    conditions.push(budgetCondition);
  }

  if (
    workflow.customerRequirementType &&
    workflow.customers.length > 0 &&
    ["ONE_OF", "NONE_OF"].includes(workflow.customerRequirementType)
  ) {
    const customerCondition = {
      type: "JOB",
      customers: workflow.customers.map(
        (customer: WorkflowCustomer) => customer.url
      ),
      requirementType: workflow.customerRequirementType,
      groupUnderParent: workflow.customersGroupUnderParent,
    } as CustomerCondition;

    conditions.push(customerCondition);
  }

  if (workflow.items.length > 0) {
    const itemCondition = {
      type: "COST_CODE",
      items: workflow.items.map((item: WorkflowItem) => item.url),
    } as ItemCondition;

    conditions.push(itemCondition);
  }

  if (workflow.accounts.length > 0) {
    const accountCondition = {
      type: "ACCOUNT",
      accounts: workflow.accounts.map((item: WorkflowItem) => item.url),
    } as AccountCondition;

    conditions.push(accountCondition);
  }

  if (workflow.vendors.length > 0) {
    const vendorCondition = {
      type: "VENDOR",
      vendors: workflow.vendors.map((vendor: WorkflowVendor) => vendor.url),
    } as VendorCondition;

    conditions.push(vendorCondition);
  }

  if (workflow.customerRequirementType === "ANY_JOB") {
    const anyJobCondition = {
      type: "JOB",
      customers: [],
      requirementType: "ANY_JOB",
      groupUnderParent: false,
    } as CustomerCondition;

    conditions.push(anyJobCondition);
  }

  if (workflow.customerRequirementType === "NO_JOB") {
    const noJobCondition = {
      type: "JOB",
      customers: [],
      requirementType: "NO_JOB",
      groupUnderParent: false,
    } as CustomerCondition;

    conditions.push(noJobCondition);
  }

  if (conditions.length === 0) {
    conditions.push(amountCondition);
  }

  return {
    id: workflow.id,
    title: workflow.title || "",
    types: workflow.types,
    isDefault: workflow.isDefault,
    steps,
    conditions,
  };
};

const INITIAL_VALUES = transformWorkflowToForm({
  id: "",
  title: "",
  isDefault: false,
  steps: [{ requirementType: "ALL_OF", approvers: [] }],
  amountFrom: null,
  amountTo: null,
  budgetExceededBy: null,
  customers: [],
  customerRequirementType: "ONE_OF",
  customersGroupUnderParent: false,
  items: [],
  accounts: [],
  types: [],
  vendors: [],
});

const formHasChanged = (
  values: Fields,
  currentWorkflow: Workflow,
  checkTitle = true
) => {
  const { title, ...formValues } = values;
  const workflowValues = transformWorkflowToForm(currentWorkflow);
  const { title: workflowTitle, ...currentWorkflowValues } = workflowValues;
  const bodyChanged = !isEqual(formValues, currentWorkflowValues);
  const titleChanged = title !== workflowTitle;
  if (checkTitle) {
    return bodyChanged || titleChanged;
  }
  return bodyChanged;
};

export const WorkflowForm = () => {
  const { currentClient } = useClientAction();

  const { visible, setVisible, setShouldShowHideConfirmation } =
    useDrawerVisibility("workflow");

  const { currentWorkflow, duplicateWorkflow } = useWorkflowInfo();

  const { isDefault } = currentWorkflow;

  const { persistWorkflow, resetCurrentWorkflow } = useWorkflowAction();

  const form = useForm<Fields>({
    schema,
    async onSubmit(values: Fields) {
      if (!formHasChanged(values, currentWorkflow, false)) {
        saveWorkflow(false);
      } else {
        dialog.confirmation({
          title: (
            <>
              Apply this workflow to <br />
              all existing drafts
            </>
          ),
          action: {
            primary: {
              children: "All existing drafts",
              onClick: () => saveWorkflow(true),
            },
            secondary: {
              children: "Only new transactions",
              onClick: () => saveWorkflow(false),
            },
          },
        });
      }
    },
    initialValues: INITIAL_VALUES,
  });

  const hasChanged = useMemo(
    () => formHasChanged(form.values, currentWorkflow),
    [currentWorkflow, form.values]
  );

  const reset = useMemo(() => form.reset, [form.reset]);

  const saveWorkflow = useEvent((applyToDrafts: boolean) => {
    if (!currentClient) return;

    if (
      duplicateWorkflow &&
      isEqual(
        omit(form.values, ["title"]),
        omit(transformWorkflowToForm(currentWorkflow), ["title"])
      )
    ) {
      toast.error("This workflow already exists. Make changes to save it");

      return;
    }

    const amountCondition = form.values.conditions.find(
      (condition) => condition.type === "AMOUNT"
    ) as AmountCondition;

    const budgetCondition = form.values.conditions.find(
      (condition) => condition.type === "BUDGET"
    ) as BudgetCondition;

    const customers = form.values.conditions.reduce((result, condition) => {
      if (condition.type === "JOB") {
        const customers =
          (condition as CustomerCondition).customers?.filter(
            (customer) => !result.some((row) => row === customer)
          ) || [];

        return [...result, ...customers];
      }
      return result;
    }, [] as string[]);

    const customersGroupUnderParent = !!form.values.conditions.find(
      (condition) => condition.type === "JOB"
    )?.groupUnderParent;

    const items = form.values.conditions.reduce((result, condition) => {
      if (condition.type === "COST_CODE") {
        const items = (condition as ItemCondition).items.filter(
          (item) => !result.some((row) => row === item)
        );

        return [...result, ...items];
      }
      return result;
    }, [] as string[]);

    const accounts = form.values.conditions.reduce((result, condition) => {
      if (condition.type === "ACCOUNT") {
        const accounts = (condition as AccountCondition).accounts.filter(
          (acct) => !result.some((row) => row === acct)
        );

        return [...result, ...accounts];
      }
      return result;
    }, [] as string[]);

    const vendors = form.values.conditions.reduce((result, condition) => {
      if (condition.type === "VENDOR") {
        const vendors = (condition as VendorCondition).vendors.filter(
          (item) => !result.some((row) => row === item)
        );

        return [...result, ...vendors];
      }
      return result;
    }, [] as string[]);

    const customerRequirementType = form.values.conditions.find(
      (condition) => condition.type === "JOB"
    );

    const steps = form.values.steps.map((step) => {
      const approvers = step.approvers.reduce(
        (result, approver) => {
          if (approver.includes("users")) {
            result.users = [...result.users, approver];
          } else {
            result.roles = [...result.roles, approver];
          }
          return result;
        },
        { roles: [], users: [] } as { users: string[]; roles: string[] }
      );

      return {
        requirementType: step.requirementType,
        ...approvers,
      };
    });

    const payload: WorkflowsRequest = {
      id: duplicateWorkflow ? "" : currentWorkflow.id,
      client: getUrlBuilder("/api/clients/").forItem(currentClient),
      isDefault: currentWorkflow.isDefault,
      amountFrom: amountCondition?.amountFrom || null,
      amountTo: amountCondition?.amountTo || null,
      budgetExceededBy: budgetCondition?.budgetExceededBy || null,
      types: form.values.types,
      items,
      customerRequirementType: customerRequirementType?.requirementType || null,
      customersGroupUnderParent,
      vendors,
      title: form.values.title || null,
      customers,
      accounts,
      steps,
      applyToDrafts,
    };

    persistWorkflow(payload);
    if (duplicateWorkflow) {
      analytics.track("approvalWorkflowDuplicate");
    } else if (currentWorkflow.id) {
      analytics.track("approvalWorkflowUpdate", {
        workflowId: currentWorkflow.id,
      });
    } else {
      analytics.track("approvalWorkflowCreate");
    }

    setVisible(false, true);
  });

  useEffect(() => {
    reset(visible ? transformWorkflowToForm(currentWorkflow) : undefined);
  }, [reset, visible, currentWorkflow]);

  useEffect(() => {
    if (!visible) resetCurrentWorkflow();
  }, [visible, resetCurrentWorkflow]);

  useEffect(() => {
    setShouldShowHideConfirmation(hasChanged);
  }, [hasChanged, setShouldShowHideConfirmation]);

  let workflowHeader = "";
  if (isDefault) {
    workflowHeader = "Fallback workflow";
  } else if (!currentWorkflow.id) {
    workflowHeader = "New workflow";
  } else if (duplicateWorkflow) {
    workflowHeader = "Duplicate workflow";
  } else {
    workflowHeader = "Edit workflow";
  }

  return (
    <>
      <DialogHeader>{workflowHeader}</DialogHeader>
      <DialogContent>
        <Flex as="form" {...form.props} direction="column" separator gap="xl">
          {!isDefault && (
            <Flex gap="xl" direction="column">
              <TextField
                label="Title"
                messageVariant="absolute"
                {...form.register("title")}
              />
              <WorkflowConditions form={form} />
            </Flex>
          )}
          {visible && (
            <WorkflowSteps
              form={form as unknown as WorkflowStepsProps["form"]}
              title="The following people should approve the transaction:"
              subtitle="Select the people who should approve a transaction that matches the
          conditions you chose."
            />
          )}
          {!isDefault && (
            <Flex direction="column" gap="xl">
              <Text size="md" as="label" htmlFor="types" weight="bolder">
                Applies to
              </Text>
              <ComboBox
                data={appliesTo}
                required
                multiple
                {...form.register({
                  name: "types",
                  type: "multiple-select",
                })}
              />
            </Flex>
          )}
        </Flex>
      </DialogContent>

      <DialogFooter>
        <Button
          size="lg"
          color="neutral"
          variant="text"
          onClick={() => setVisible(false)}
        >
          Cancel
        </Button>
        <Button
          size="lg"
          type="submit"
          form={form.id}
          disabled={form.isSubmitting || !hasChanged || !form.isValid}
        >
          Save
        </Button>
      </DialogFooter>
    </>
  );
};
