import get from "dlv";
import { dset } from "dset";
import { unflatten } from "flat";
import { klona } from "klona";

import { is } from "../is";

type Flattened<T> = T extends Array<infer U> ? Flattened<U> : T;

export const dotObject = {
  get: (...args: Parameters<typeof get>) => {
    if (is.string(args[1]) && /(http:\/\/|https:\/\/)/.test(args[1])) {
      // eslint-disable-next-line no-console
      console.warn(
        `Your key ${args[1]} seems to be a URL, which will function as multiple keys due to the dots.`
      );
    }
    return get(...args);
  },
  set: <T extends object, V>(
    obj: T,
    keys: string,
    value: V,
    params?: { mutate: boolean }
  ) => {
    const { mutate } = params || {};

    if (mutate) {
      dset(obj, keys, value);
      return obj;
    }

    const copy = klona(obj);
    dset(copy, keys, value);
    return copy;
  },
  delete: <T extends object>(obj: T, keys: string) => {
    const slices = keys.split(".");
    const lastKey = slices.pop();
    const parent = dotObject.get(obj, slices.join("."));

    if (!isNaN(Number(lastKey))) {
      let filtered: any = [];

      const list = dotObject.get(obj, slices);

      if (Array.isArray(list)) {
        filtered = (list as []).filter((_, i) => i != Number(lastKey));
      }

      if (!parent) return filtered;

      return dotObject.set(obj, slices.join("."), filtered);
    }

    const prop = dotObject.get(obj, keys);
    const flat = dotObject.flat(obj);

    if (
      typeof prop === "object" ||
      (typeof parent === "object" && Object.keys(parent).length > 1)
    ) {
      delete (flat as any)[keys];
    } else {
      return dotObject.set(
        obj as Record<string, unknown>,
        slices.join("."),
        {}
      );
    }

    return dotObject.unflat(flat as any) ?? {};
  },
  /**
   * Since "flatten" from "flat" doesn't have the ability to
   * filter their keys we need to implement it by ourself.
   * Based on https://github.com/hughsk/flat/blob/5349c0a6db4f8eccbacc9b126a0a3516767dd526/index.js
   */
  flat: <TTarget extends object>(
    target: TTarget,
    opts?: {
      safe?: boolean | undefined;
      filter?: (value: unknown) => boolean;
      maxDepth?: number | undefined;
      delimiter?: string | undefined;
      transformKey?: ((key: string) => string) | undefined;
    }
  ): Flattened<TTarget> => {
    opts = opts || {};

    const delimiter = opts.delimiter || ".";
    let maxDepth = opts.maxDepth || 0;
    const filter = opts.filter || (() => true);
    let currentDepth = 1;
    const output = {} as Flattened<TTarget>;

    function step(object: TTarget, prev?: string | undefined) {
      Object.keys(object).forEach(function (key) {
        const value = (object as any)[key] as TTarget;
        const isarray = opts?.safe && Array.isArray(value);
        const type = Object.prototype.toString.call(value);
        const isobject =
          type === "[object Object]" || type === "[object Array]";

        const newKey = prev ? prev + delimiter + key : key;

        if (!opts?.maxDepth) {
          maxDepth = currentDepth + 1;
        }

        if (
          !isarray &&
          isobject &&
          Object.keys(value).length &&
          currentDepth < maxDepth &&
          filter(value)
        ) {
          ++currentDepth;
          return step(value, newKey);
        }

        (output as any)[newKey] = value;
      });
    }

    step(target);

    return output;
  },
  unflat: <T extends object, R extends object>(obj: T) => unflatten<T, R>(obj),
};
