import React, { memo, useMemo } from "react";
import { Link as ReactRouterLink, useSearchParams } from "react-router";
import {
  Alert,
  AlertContent,
  Flex,
  Link,
  Loader,
  Tabs,
  TabsList,
  TabsPanel,
  TabsTab,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { AccountDetails } from "@capitalos/react";
import {
  Main,
  MainContent,
  MainHeader,
  MainSubtitle,
  MainTitle,
} from "@components/main";
import { BasePermissions, useClientSettings, useUserInfo } from "@store/user";

import { CardsAdaptive } from "./cards-adaptive";
import { CardsAdaptiveAddCardButton } from "./cards-adaptive-add-card-button";
import { useCardsAdaptiveContext } from "./cards-adaptive-context";
import { CardsAdaptiveSettingsButton } from "./cards-adaptive-settings-button";
import { CardsLinked } from "./cards-linked";
import { CardsLinkedAddCardButton } from "./cards-linked-add-card-button";

export const Cards = memo(() => {
  const { hasPermission } = useUserInfo();

  const [searchParams, setSearchParams] = useSearchParams();

  const canSeeLinkedCards = hasPermission(BasePermissions.COMPANY_ADMIN);

  const { cardFeedEnabled, capitalOsEnabled: canSeeAdaptiveCards } =
    useClientSettings();

  const { userRole, hasError, setHasError } = useCardsAdaptiveContext();

  const currentTab = useMemo(() => {
    if (canSeeAdaptiveCards && canSeeLinkedCards) {
      return searchParams.get("tab") || "adaptive";
    }

    if (canSeeAdaptiveCards) {
      return "adaptive";
    }

    return "linked";
  }, [searchParams, canSeeAdaptiveCards, canSeeLinkedCards]);

  const onError = useEvent(() => {
    setHasError(true);
  });

  const onTabChange = useEvent((tab: string) => {
    setSearchParams({ tab });
  });

  return (
    <Main>
      <MainHeader>
        <Flex align="center" height="full" gap="xl">
          <Flex direction="column" grow>
            <MainTitle>Card management</MainTitle>
            <MainSubtitle>
              Manage your credit and debit cards linked to Adaptive
            </MainSubtitle>
          </Flex>
          {currentTab === "adaptive" &&
            canSeeAdaptiveCards &&
            userRole === "manage" &&
            !hasError && (
              <Flex gap="xl">
                <CardsAdaptiveSettingsButton />
                <CardsAdaptiveAddCardButton />
              </Flex>
            )}
          {currentTab === "linked" && canSeeLinkedCards && (
            <CardsLinkedAddCardButton />
          )}
        </Flex>
      </MainHeader>
      <MainContent>
        <Flex
          gap="xl"
          shrink={false}
          style={{
            /* We need to this calc to compensate margin from MainContent */
            minHeight: "calc(var(--spacing-full) - var(--spacing-4xl))",
          }}
          direction="column"
        >
          {cardFeedEnabled && (
            <Alert variant="neutral">
              <AlertContent>
                View and manage your card transactions on the{" "}
                <Link as={ReactRouterLink} variant="success" to="/card-feed">
                  Card Feed
                </Link>{" "}
                page
              </AlertContent>
            </Alert>
          )}
          {canSeeAdaptiveCards && canSeeLinkedCards ? (
            <Tabs value={currentTab} onChange={onTabChange}>
              <TabsList style={{ alignItems: "flex-end" }}>
                <TabsTab value="adaptive">Adaptive</TabsTab>
                <TabsTab value="linked">Linked</TabsTab>
                <Flex gap="xl" grow justify="flex-end">
                  {userRole === "manage" && !hasError && (
                    <Flex
                      width="400px"
                      height="75px"
                      style={{
                        visibility:
                          currentTab !== "adaptive" ? "hidden" : "visible",
                      }}
                    >
                      <AccountDetails
                        loadingComponent={
                          <Flex
                            width="full"
                            height="full"
                            justify="center"
                            align="center"
                          >
                            <Loader />
                          </Flex>
                        }
                        onError={onError}
                      />
                    </Flex>
                  )}
                </Flex>
              </TabsList>
              <TabsPanel value="adaptive">
                <CardsAdaptive />
              </TabsPanel>
              <TabsPanel value="linked">
                <CardsLinked active={currentTab === "linked"} />
              </TabsPanel>
            </Tabs>
          ) : canSeeAdaptiveCards ? (
            <>
              {userRole === "manage" && !hasError && (
                <Flex justify="flex-end">
                  <Flex
                    width="400px"
                    height="75px"
                    hidden={currentTab !== "adaptive"}
                  >
                    <AccountDetails
                      loadingComponent={
                        <Flex
                          width="full"
                          height="full"
                          justify="center"
                          align="center"
                        >
                          <Loader />
                        </Flex>
                      }
                      onError={onError}
                    />
                  </Flex>
                </Flex>
              )}
              <CardsAdaptive />
            </>
          ) : canSeeLinkedCards ? (
            <CardsLinked />
          ) : null}
        </Flex>
      </MainContent>
    </Main>
  );
});

Cards.displayName = "Cards";
