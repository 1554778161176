import { useAsSimplifiedData } from "@hooks/use-simplified-data";
import { useGetPeopleSimplifiedQuery } from "@store/api-simplified";
import { useClientInfo } from "@store/user";

export const usePeopleSimplified = (includeLabel = false) => {
  const { client } = useClientInfo();

  const query = useGetPeopleSimplifiedQuery(client?.id);

  return useAsSimplifiedData(query, {
    groupLabel: includeLabel ? "User" : undefined,
  });
};
