import { formatCurrency, parseStringCopy } from "@adaptive/design-system/utils";
import {
  type BillPaymentsPageData,
  type ListBillPaymentsV2Payload,
} from "@bill-payment/types";

export const EM_DASH = "—";

export const SCHEDULED_BILL_PAYMENTS_FILTERS: ListBillPaymentsV2Payload = [
  { dataIndex: "status", value: "SCHEDULED" },
];

export const IN_PROGRESS_BILL_PAYMENTS_FILTERS: ListBillPaymentsV2Payload = [
  { dataIndex: "status", value: "PENDING" },
];

export const ALL_BILL_PAYMENTS_FILTERS: ListBillPaymentsV2Payload = [];

export const BILL_PAYMENTS_TABLE_ID = "bill-payments-page-table" as const;

export const FILTERS_STORAGE_KEY = "bill-payments-page-filters" as const;

export const BILL_PAYMENTS_TABLE_LOCAL_STORAGE_LIMIT_KEY =
  "bill-payments-page-table-limit";

export const BILL_PAYMENTS_TABS = {
  SCHEDULED: "scheduled",
  IN_PROGRESS: "pending",
  ALL: "all",
} as const;

export const DEFAULT_DATA: BillPaymentsPageData = {
  data: [],
  count: 0,
  isLoading: true,
  isError: false,
};

export const DEFAULT_TAB = BILL_PAYMENTS_TABS.SCHEDULED;

export const CUSTOMER_ACCOUNT_TYPE = {
  CREDIT_CARD: "credit-card",
  BANK_ACCOUNT: "bank-account",
} as const;

export const PROCESS_PREREQUISITE_TYPE = {
  LIEN_WAIVER_SIGNATURE: "lien_waiver_signature",
  VENDOR_BANKING_ACH: "vendor_banking_ach",
  SCHEDULED_DATE: "scheduled_date",
};

export const PAYMENT_METHOD = {
  MARK_AS_PAID: "mark-as-paid",
  ACH: "ACH",
  PRINT_CHECK: "print-check",
  MAIL_CHECK: "mail-check",
  VIRTUAL_EMAIL_CHECK: "virtual-email-check",
  VIRTUAL_SMS_CHECK: "virtual-sms-check",
} as const;

export const PAYMENT_STATUS = {
  PAID: "PAID",
  PENDING: "PENDING",
  CANCELLED: "CANCELLED",
  FAILED: "FAILED",
  ACH_REQUESTED: "ACH_REQUESTED",
  SCHEDULED: "SCHEDULED",
  PENDING_PREREQUISITES: "PENDING_PREREQUISITES",
} as const;

export const STRINGS = {
  PAYMENT_CANCELLED_TITLE: "Payment cancelled by",
  PAYMENT_VOIDED_TITLE: "Payment voided by",
  ARE_YOU_SURE_TITLE_PREFIX: "Are you sure you want to",
  ARE_YOU_SURE_SUBTITLE_PREFIX: "This cannot be undone.",
  ARE_YOU_SURE_SUBTITLE_SUFFIX: "You will need to send a new payment.",
  CANCEL_TITLE: "Cancel",
  VOID_TITLE: "Void",
  CLOSE_TITLE: "Close",
  PAYMENT_TITLE: "payment",
  PAY_NOW_TITLE: "Pay now",
  LIEN_WAIVER_TITLE: "Lien waiver",
  SELECT_TRANSACTION_TITLE:
    "Select a transaction from your card history that matches with this payment",
  MATCH_TRANSACTION_TITLE: "Match to an existing card transaction",
  ASSOCIATED_TRANSACTION_TITLE: "Associated transaction",
  CARD_TRANSACTION_TITLE: "Card transaction",
  ACH_DETAILS_TITLE: "Already have their ACH bank details?",
  ADD_IT_HERE_TITLE: "Add it here",
  REQUESTED_ON_TITLE: "Requested on",
  PAY_TO_TITLE: "Pay to",
  VENDOR_ADDRESS_TITLE: "Vendor address",
  VENDOR_EMAIL_TITLE: "Vendor email",
  VENDOR_DEBIT_DATE_TITLE: "Debit date",
  CHANGE_IT_HERE_TITLE: "Change it here",
  VENDOR_PHONE_NUMBER_TITLE: "Vendor phone number",
  CHANGE_PHONE_NUMBER_TITLE: "Do you need to change the vendor’s phone number?",
  CHANGE_ADDRESS_TITLE: "Do you need to change the vendor’s address?",
  CHANGE_EMAIL_TITLE: "Do you need to change the vendor’s email?",
  PAID_FROM_TITLE: "Paid from",
  PAY_FROM_TITLE: "Pay from",
  PAYMENT_METHOD_TITLE: "Payment method",
  REF_TITLE: "Ref #",
  PAYMENTS_DETAILS_TITLE: "Payment details",
  LOADING_TITLE: "Loading...",
  ROUTING_NUMBER_TITLE: "Routing number:",
  ACCOUNT_NUMBER_TITLE: "Account number:",
  PAY_ACH_COLLECTION_TITLE: "Pay on ACH collection",
  PAY_LIEN_WAIVER_TITLE: "Pay on lien waiver signature",
  VIEW_PRINTED_CHECK: "View check",
  MARKED_AS_PAID: "Marked as paid",
  PAID: "Paid",
  IN_PROGRESS: "In progress",
  FAILED: "Failed",
  VOIDED: "Voided",
  CANCELLED: "Cancelled",
  PAID_IN_QUICKBOOKS: "Paid in QuickBooks",
  SCHEDULED: "Scheduled",
  PAY_NOW_SCHEDULED_CONFIRMATION_AMOUNT_TITLE: "this {amount} payment?",
};

export const COMBINED_PAYMENTS_STRINGS = {
  CANCEL: "Cancel",
  REVIEW_AND_PAY: "Review & Pay",
  MARK_AS_PAID: "Mark as paid",
  HEADER_TITLE: "{quantity} payment{suffix}: {amount}",
  NO_BILLS_SELECTED: "No bills selected for payment",
  NO_BILLS_SELECTED_SUBTITLE: "Select bills to pay and click on “Review & pay”",
  ON_ACH_COLLECTION: "On ACH collection.",
  ON_ACH_COLLECTION_AND_LIEN_WAIVER_SIGNATURE:
    "On ACH collection & lien waiver signature.",
  ON_LIEN_WAIVER_SIGNATURE: "On lien waiver signature.",
  SELECT_PAY_FROM: "Select pay from account",
  HAVE_ACH_ALREADY: "Already have this vendor’s ACH bank details?",
  ADD_THEM_HERE: "Add them here",
  ADD_ACH_INFO_HERE: "Add ACH information here",
  PAY_WHEN_LIEN_WAIVER_SIGNED: "Pay when lien waiver is signed",
  COMBINED_PAYMENTS_TITLE: "Combine payments by vendor",
  LIEN_WAIVER_NOT_ENABLED:
    "The lien waivers module is not enabled for this client.",
  DELIVERY_DATE_TITLE: "Delivery date",
  PROCESS_DATE_TITLE: "Process date",
  ERROR_CANT_HAVE_DATES_IN_FUTURE_FOR_MARK_AS_PAID:
    "Can't have debit dates in the future for payments with zero balance",
  DEBIT_DATE: "Select debit date",
  DEBIT_DATE_MUST_MATCH_AVAILABLE_DATE:
    "Debit date must match one of the available dates",
  ERROR_PAYMENT_BANK_ACCOUNT_MISSING: "“Pay from“ is a required column.",
  ERROR_QUICKBOOKS_ACCOUNT_MISSING:
    "All bank accounts should have QuickBooks payment account linked.",
  PAYMENT_ACCOUNT_NOT_LINKED: "QuickBooks payment account is missing",
  PAYMENT_ACCOUNT_REQUIRED: "Payment account is required",
  LIEN_WAIVER_MISSING_INFO: "Missing information for lien waiver",
  REMOVE_BUTTON_TOOLTIP: "Remove from bill payment",
  LIEN_WAIVER_REQUESTED_INFO:
    "Lien waivers are requested for the open bill balance per job",
  LIEN_WAIVERS_TEMPLATE_NOT_SELECTED:
    "“Pay when lien waiver is signed“ is ON, but no lien waiver template is selected.",
  LINK_ACCOUNT_TITLE: "Choose QuickBooks payment account.",
  LINK_ACCOUNT_TITLE_TRUNCATED: "Choose QuickBooks payment account",
  CLICK_HERE_TITLE: "Click here",
  REQUIRED_LIEN_WAIVER_DATA_TITLE: "Required lien waiver data is missing",
  LIEN_WAIVERS_REQUESTED: "{quantity} Lien waiver{suffix}",
  FILL_OUT_LW_INFO_TITLE:
    "Please fill out the following missing information in order to request lien waivers for this payment",
};

export const PAYMENT_LANDING_PAGE_STRINGS = {
  REPLY_COMMENT_ADDED: "Comment added",
  REPLY_PLACEHOLDER: "Reply to",
  ACH_REQUEST_TITLE_BOLD: "sent your payment by direct deposit",
  ACH_REQUEST_TITLE_TAG: "Bank details required",
  ACH_REQUEST_SUBTITLE:
    "Provide your bank information to receive payment directly in your account.",
  ACH_REQUEST_BUTTON: "Submit bank details",
  PAGE_TITLE_SENT_YOU: "sent you",
  LIEN_WAIVER_TITLE: "Lien waivers",
  ASSOCIATED_TRANSACTION_TITLE: "Associated transactions",
};

export const GET_CANCEL_CONFIRMATION_AMOUNT_TITLE = (amount: string) => {
  return `this ${formatCurrency(amount, {
    currencySign: true,
  })} payment?`;
};

export const GET_PAY_NOW_SCHEDULED_CONFIRMATION_AMOUNT_TITLE = (
  amount: string
) => {
  return parseStringCopy(STRINGS.PAY_NOW_SCHEDULED_CONFIRMATION_AMOUNT_TITLE, {
    amount: formatCurrency(amount, {
      currencySign: true,
    }),
  });
};

export const HUMAN_READABLE_PAYMENT_METHOD = {
  ACH: "Bank transfer (ACH)",
  "mail-check": "Mail check",
  "print-check": "Print check",
  "virtual-email-check": "Email a virtual check",
  "virtual-sms-check": "Text a virtual check",
  "mark-as-paid": "Mark as paid",
};

export const NO_JOB = {
  id: "no_job",
  displayName: "No Job",
};
