import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  Link,
  Loader,
  Table,
  type TableColumn,
  type TableRowAddon,
  type TableSelectAddon,
  Text,
  toast,
  Tooltip,
  VisuallyHidden,
} from "@adaptive/design-system";
import { useEvent, usePagination } from "@adaptive/design-system/hooks";
import {
  formatCurrency,
  formatDate,
  parseDate,
} from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import {
  useGetCustomerAccountLinesQuery,
  useGetCustomerCategoryLinesQuery,
  useGetCustomerItemLinesQuery,
  useGetCustomerLinesQuery,
  useLazyGetLinkedInvoicesQuery,
} from "@api/jobs";
import {
  BatchApplyObject,
  type BatchApplyObjectOnChangeHandlerProps,
} from "@components/batch-apply-object";
import {
  DownloadButton,
  type OnDownloadHandler,
} from "@components/download-button";
import {
  type StrictValuesFilters,
  TableFilterControls,
} from "@components/table-filter";
import { defaultArrayFormatter } from "@components/table-filter/formatters";
import { useTableFilters } from "@components/table-filter/table-filter-hooks";
import { useApplyObject } from "@hooks/use-apply-object";
import type { CustomerItemNestedLine } from "@hooks/use-customer-item-lines";
import { HUMAN_READABLE_BILL_STATUS } from "@src/bills/constants";
import type { QueryFilters } from "@src/bills/types";
import {
  HUMAN_READABLE_EXPENSE_REVIEW_STATUS,
  HUMAN_READABLE_EXPENSE_TYPE,
} from "@src/expenses/utils/constants";
import { api as reduxApi } from "@store/api-simplified";
import { useJobInfo } from "@store/jobs";
import * as analytics from "@utils/analytics";
import { api } from "@utils/api";
import { isAccount } from "@utils/is-account";
import { isCostCode } from "@utils/is-cost-code";
import { noop } from "@utils/noop";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import {
  confirmSyncInvoiceLines,
  type InvoiceData,
} from "@utils/transaction-confirm-messages";

import type { TransactionDialogProps } from "./";

const PER_PAGE = 10;

const TYPE_TO_URL = {
  Bill: "bills",
  Receipt: "expenses",
  "Vendor Credit": "bills",
};

const TRANSACTION_TYPE_FILTER = (
  [
    { value: "bill", label: "Bill" },
    { value: "receipt", label: "Receipt" },
    { value: "labor", label: "Labor" },
    { value: "vendor_credit", label: "Vendor credit" },
  ] as const
).map((filter) => ({ ...filter, groupLabel: "Transaction type" }));

const EXTRA_DATA = [...TRANSACTION_TYPE_FILTER];

const INITIAL_FILTERS = {} as StrictValuesFilters;

export const TransactionDialog = ({
  dialog,
  budgetLine,
}: TransactionDialogProps) => {
  const { job } = useJobInfo();

  const dispatch = useDispatch();

  const [orderBy] = useState<string>("-date");

  const pagination = usePagination();

  const [offset, setOffset] = useState<number>(0);

  const [selected, setSelected] = useState<CustomerItemNestedLine[]>([]);

  const [triggerGetLinkedInvoices] = useLazyGetLinkedInvoicesQuery();

  const applyObject = useApplyObject({ items: selected, resource: "lines" });

  const previousBatchApplyToast = useRef(noop);

  const {
    setFilters,
    filters = [],
    rawFilters,
  } = useTableFilters({
    formatter: defaultArrayFormatter,
    initialFilters: INITIAL_FILTERS,
  });

  const shouldSkipAccountLines = !(
    job.id &&
    (!budgetLine.groupedBy || budgetLine.groupedBy === "quickbooks") &&
    budgetLine.jobCostMethod?.id &&
    isAccount(budgetLine.jobCostMethod?.url)
  );

  const accountLinesInfo = useGetCustomerAccountLinesQuery(
    {
      limit: PER_PAGE,
      offset,
      orderBy,
      filters: filters as QueryFilters,
      accountId: budgetLine.jobCostMethod?.id,
      customerId: job.id.toString(),
      withChildren:
        budgetLine.groupedBy === "quickbooks" &&
        (budgetLine.data || []).length > 0,
    },
    { skip: shouldSkipAccountLines, refetchOnMountOrArgChange: true }
  );

  const shouldSkipCostCodeLines = !(
    job.id &&
    (!budgetLine.groupedBy || budgetLine.groupedBy === "quickbooks") &&
    budgetLine.jobCostMethod?.id &&
    isCostCode(budgetLine.jobCostMethod?.url)
  );

  const costCodeLinesInfo = useGetCustomerItemLinesQuery(
    {
      limit: PER_PAGE,
      offset,
      itemId: budgetLine.jobCostMethod?.id,
      orderBy,
      filters: filters as QueryFilters,
      customerId: job.id.toString(),
      withChildren:
        budgetLine.groupedBy === "quickbooks" &&
        (budgetLine.data || []).length > 0,
    },
    { skip: shouldSkipCostCodeLines, refetchOnMountOrArgChange: true }
  );

  const shouldSkipCategoryLines = !(
    job.id &&
    budgetLine.groupedBy === "categories" &&
    budgetLine.category?.id
  );

  const categoryLinesInfo = useGetCustomerCategoryLinesQuery(
    {
      limit: PER_PAGE,
      offset,
      orderBy,
      filters: filters as QueryFilters,
      categoryId: budgetLine.category?.id,
      customerId: job.id.toString(),
    },
    { skip: shouldSkipCategoryLines, refetchOnMountOrArgChange: true }
  );

  const shouldSkipLines =
    !shouldSkipAccountLines ||
    !shouldSkipCostCodeLines ||
    !shouldSkipCategoryLines;

  const linesInfo = useGetCustomerLinesQuery(
    {
      limit: PER_PAGE,
      offset,
      orderBy,
      filters: filters as QueryFilters,
      customerId: job.id.toString(),
    },
    { skip: shouldSkipLines, refetchOnMountOrArgChange: true }
  );

  const data = useMemo(() => {
    if (budgetLine.groupedBy === "categories") {
      if (budgetLine.category?.id && categoryLinesInfo.data) {
        return categoryLinesInfo.data;
      } else if (linesInfo.data) {
        return linesInfo.data;
      }
    }

    if (isCostCode(budgetLine.sourceType) && costCodeLinesInfo.data) {
      return costCodeLinesInfo.data;
    }

    if (isAccount(budgetLine.sourceType) && accountLinesInfo.data) {
      return accountLinesInfo.data;
    }

    return { count: 0, results: [] };
  }, [
    linesInfo.data,
    budgetLine.groupedBy,
    budgetLine.sourceType,
    accountLinesInfo.data,
    costCodeLinesInfo.data,
    categoryLinesInfo.data,
    budgetLine.category?.id,
  ]);

  const getTransactionUrl = useCallback((row: CustomerItemNestedLine) => {
    if (row.parent.human_readable_type in TYPE_TO_URL) {
      const path =
        TYPE_TO_URL[row.parent.human_readable_type as keyof typeof TYPE_TO_URL];

      return `/${path}/${row.parent.id}`;
    }
  }, []);

  const onClearFilters = useEvent(() => setFilters({}));

  const onFiltersChange = useEvent((filters) => setFilters(filters));

  const hasFilters = Object.values(rawFilters).some((filter) => !!filter);

  const select = useMemo<TableSelectAddon<CustomerItemNestedLine>>(
    () => ({
      value: selected,
      onChange: setSelected,
    }),
    [selected]
  );

  const columns = useMemo<TableColumn<CustomerItemNestedLine>[]>(
    () => [
      {
        id: "vendor",
        width: 300,
        name: "Vendor name / Employee name",
        render: (row) => (
          <Text truncate>
            {row.vendor?.display_name ||
              row.other_name?.display_name ||
              row.employee?.display_name}
          </Text>
        ),
      },
      {
        id: "status",
        name: "Status",
        width: 140,
        render: (row) => (
          <Text truncate>
            {row.parent.human_readable_type === "Bill" ||
            row.parent.human_readable_type === "Vendor Credit"
              ? HUMAN_READABLE_BILL_STATUS[
                  row.parent
                    .review_status as keyof typeof HUMAN_READABLE_BILL_STATUS
                ]
              : HUMAN_READABLE_EXPENSE_REVIEW_STATUS[
                  row.parent
                    .review_status as keyof typeof HUMAN_READABLE_EXPENSE_REVIEW_STATUS
                ]}
          </Text>
        ),
      },
      {
        id: "type",
        name: "Type",
        width: 140,
        render: "parent.human_readable_type",
      },
      {
        id: "amount",
        name: "Cost",
        width: 140,
        textAlign: "right",
        render: (row) => (
          <Flex align="center" gap="sm" justify="flex-end">
            <Text align="right" truncate>
              {formatCurrency(row.amount, {
                currencySign: true,
                allowNegative: true,
              })}
            </Text>
            {row.parent.human_readable_type ===
              HUMAN_READABLE_EXPENSE_TYPE.LABOR &&
              !row.amount && (
                <Tooltip
                  name="info-circle"
                  as={Icon}
                  size="sm"
                  message="Hourly rate is not set in QuickBooks"
                />
              )}
          </Flex>
        ),
      },
      {
        id: "number",
        name: "Ref #",
        width: 120,
        render: (row) =>
          row.parent.doc_number &&
          row.parent.human_readable_type ===
            HUMAN_READABLE_EXPENSE_TYPE.LABOR ? (
            <Flex align="center" gap="sm">
              <Text truncate>{`#${row.parent.doc_number}`}</Text>
            </Flex>
          ) : row.parent.doc_number ? (
            <Flex height="full" align="center">
              <Link
                rel="noreferrer"
                href={getTransactionUrl(row)}
                target="_blank"
                variant="success"
              >
                <Text as="span" size="sm" truncate>
                  #{row.parent.doc_number}
                </Text>
              </Link>
            </Flex>
          ) : (
            "Missing ref #"
          ),
      },
      {
        id: "date",
        name: "Date",
        width: 120,
        render: (row) => formatDate(parseDate(row.date, "yyyy-MM-dd")),
      },
    ],
    [getTransactionUrl]
  );

  const row = useMemo<TableRowAddon<CustomerItemNestedLine>>(
    () => ({
      render: ({ row, props }) => {
        if (
          !row.parent.doc_number ||
          row.parent.human_readable_type === HUMAN_READABLE_EXPENSE_TYPE.LABOR
        ) {
          return null;
        }

        return (
          <a
            {...props}
            rel="noreferrer"
            href={getTransactionUrl(row)}
            target="_blank"
          >
            <VisuallyHidden as="div">
              Open {row.parent.human_readable_type} #{row.parent.doc_number}
            </VisuallyHidden>
          </a>
        );
      },
    }),
    [getTransactionUrl]
  );

  const onBatchApply = useEvent(
    async ({
      items = selected,
      option,
      fieldName,
    }: BatchApplyObjectOnChangeHandlerProps & {
      items?: CustomerItemNestedLine[];
    }) => {
      if (!option) return;

      const handler = async (syncInvoiceLines = false) => {
        try {
          const { success } = await applyObject.execute({
            items,
            value: option.value,
            method:
              fieldName === "billable_status"
                ? "apply_billable_status_batch"
                : "apply_object_batch",
            fieldName,
            syncInvoiceLines,
          });

          if (budgetLine.groupedBy === "categories") {
            await categoryLinesInfo.refetch();
          } else if (isCostCode(budgetLine.sourceType)) {
            await costCodeLinesInfo.refetch();
          } else if (isAccount(budgetLine.sourceType)) {
            await accountLinesInfo.refetch();
          }

          dispatch(
            reduxApi.util.invalidateTags(["CustomerMarkup", "BudgetLines"])
          );

          analytics.track("budgetTransactionBatchActions", {
            value: option.value,
            action: fieldName,
            transactionIds: items.map((transaction) => transaction.id),
          });

          if (fieldName === "customer") {
            if (job.url === option.value) {
              return toast.success(
                `Transaction${success > 1 ? "s" : ""} moved back to ${
                  job.display_name
                }`
              );
            }

            const undo = () => {
              previousBatchApplyToast.current();

              onBatchApply({
                value: job.url,
                items,
                option: { label: job.display_name, value: job.url },
                fieldName: "customer",
              });
            };

            const { dismiss } = toast.success(
              <Flex as="span" direction="column">
                <Text as="strong" weight="bold">
                  Transaction{success > 1 ? "s" : ""} moved to{" "}
                  {option?.label ?? "Unknown"}
                </Text>
                <Flex gap="lg">
                  <Link as="button" type="button" onClick={undo}>
                    Undo
                  </Link>
                  <Link
                    rel="noreferrer"
                    href={`/jobs/${parseRefinementIdFromUrl(option.value)}`}
                    target="_blank"
                  >
                    View job
                  </Link>
                </Flex>
              </Flex>,
              { duration: 10000 }
            );

            previousBatchApplyToast.current = dismiss;
          } else {
            toast.success(`${success} Line${success > 1 ? "s" : ""} updated!`);
          }
        } catch (e) {
          analytics.track("budgetBatchActionsError", {
            items,
            value: option.value,
            method: "apply_object_batch",
            fieldName,
            syncInvoiceLines,
          });
          handleErrors(e);
        }
      };

      const { data } = await triggerGetLinkedInvoices({
        billIds: selected
          .filter((item) => item.parent.human_readable_type === "Bill")
          .map(({ parent }) => parent.id),
        expenseIds: selected
          .filter((item) => item.parent.human_readable_type === "Receipt")
          .map(({ parent }) => parent.id),
      });

      const linkedInvoices: InvoiceData[] = data?.invoices ?? [];

      if (linkedInvoices.length) {
        confirmSyncInvoiceLines({
          isPlural: selected.length > 1,
          linkedInvoices,
          action: {
            primary: {
              color: "primary",
              onClick: () => handler(true),
              children: "Update line on draw",
            },
            secondary: {
              onClick: handler,
              children: "Don't update line on draw",
            },
          },
        });
      } else {
        handler();
      }
    }
  );

  const onDownload = useCallback<OnDownloadHandler>(
    async ({ params }) => {
      params.append("order_by", orderBy);

      let resource = `/api/customers/${job.id}`;

      if (budgetLine.groupedBy === "categories") {
        resource += `/categories/${budgetLine.category?.id}`;
      } else if (isCostCode(budgetLine.sourceType)) {
        resource += `/items/${budgetLine.jobCostMethod.id}`;
      } else if (isAccount(budgetLine.sourceType)) {
        resource += `/accounts/${budgetLine.jobCostMethod.id}`;
      } else {
        return;
      }

      const { data } = await api.get(`${resource}/lines/export/`, { params });

      return data?.id ? (data.id as string) : undefined;
    },
    [
      job.id,
      orderBy,
      budgetLine.groupedBy,
      budgetLine.sourceType,
      budgetLine.category?.id,
      budgetLine.jobCostMethod?.id,
    ]
  );

  useEffect(() => {
    setOffset(pagination.offset);
  }, [pagination.offset]);

  useEffect(() => {
    if (!dialog.isVisible) pagination.setPage(0);
  }, [dialog.isVisible, pagination]);

  return (
    <>
      {applyObject.isLoading && <Loader position="fixed" />}
      <Dialog
        size="auto"
        variant="dialog"
        show={dialog.isVisible}
        onClose={dialog.hide}
      >
        <DialogHeader>
          <Flex direction="column" gap="md">
            {budgetLine.jobCostMethod?.displayName ?? ""}
            <Text size="md">Transactions</Text>
          </Flex>
        </DialogHeader>
        <DialogContent>
          <Flex direction="column" gap="lg" minWidth="900px">
            <Flex gap="md" direction="column">
              <TableFilterControls
                filters={rawFilters}
                extraData={EXTRA_DATA}
                withFilterTags
                withDateFilter
                includeFilters={[]}
                dateProps={{
                  grow: true,
                  maxWidth: "auto",
                }}
                filterProps={{
                  grow: true,
                  maxWidth: "auto",
                }}
                onFiltersChange={onFiltersChange}
                renderAfter={() => (
                  <Flex width="400px" gap="md" shrink={false}>
                    {hasFilters && (
                      <Button onClick={onClearFilters}>Clear filters</Button>
                    )}
                    <Flex gap="md" width="full" justify="flex-end">
                      {data.results.length ? (
                        <DownloadButton
                          mode={{
                            all: { enabled: true, children: "Download" },
                            selection: { enabled: false },
                          }}
                          size="md"
                          withDate
                          onDownload={onDownload}
                          data-testid="budget"
                        />
                      ) : null}
                      {selected.length > 0 && (
                        <Dropdown placement="bottom-end">
                          <DropdownTrigger
                            as={Button}
                            color="primary"
                            data-testid={`${status}-actions-trigger`}
                          >
                            Actions
                            <Icon name="ellipsis-vertical" variant="solid" />
                          </DropdownTrigger>
                          <DropdownList>
                            <DropdownItem>
                              <BatchApplyObject
                                onChange={onBatchApply}
                                includeVendors={false}
                                accountFilters={{
                                  only_line_item_accounts: true,
                                }}
                                includeBillableStatus
                              />
                            </DropdownItem>
                          </DropdownList>
                        </Dropdown>
                      )}
                    </Flex>
                  </Flex>
                )}
              />
            </Flex>
            <Table
              id="transactions-dialog-table"
              size="sm"
              loading={
                accountLinesInfo.isLoading ||
                costCodeLinesInfo.isLoading ||
                categoryLinesInfo.isLoading
              }
              row={row}
              data={data.results}
              select={select}
              columns={columns}
              maxHeight="500px"
              pagination={{
                page: pagination.page,
                total: data?.count ?? 0,
                perPage: pagination.perPage,
                onChange: pagination.setPage,
              }}
              data-testid="transaction-table"
            />
          </Flex>
        </DialogContent>
      </Dialog>
    </>
  );
};
