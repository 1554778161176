import { PURCHASE_ORDER_TYPE } from "@src/purchase-orders/constants";
import {
  arraySchema,
  currencySchema,
  dateSchema,
  idSchema,
} from "@utils/schema";
import { z } from "zod";

const poStatusEnum = z.enum(["Open", "Closed"]);

const customerSchema = z.object({
  id: idSchema.nullish(),
  url: z.string().url().nullish(),
  displayName: z.string().nullish(),
});

const accountSchema = z.object({
  id: idSchema.nullish(),
  url: z.string().url().nullish(),
  displayName: z.string().nullish(),
});

const itemSchema = z.object({
  id: idSchema.nullish(),
  url: z.string().url().nullish(),
  displayName: z.string().nullish(),
  description: z.string().nullish(),
});

const lineSchema = z.object({
  id: idSchema,
  url: z.string().url().nullish(),
  item: itemSchema.nullish(),
  status: poStatusEnum,
  amount: currencySchema,
  account: accountSchema.nullish(),
  balance: currencySchema,
  customer: customerSchema.nullish(),
  forceClose: z.boolean(),
  description: z.string().nullish(),
  linkedBills: arraySchema(
    z.object({
      id: idSchema,
      url: z.string().url().nullish(),
      displayName: z.string().nullish(),
      lines: arraySchema(
        z.object({
          id: idSchema,
          url: z.string().url().nullish(),
          amount: currencySchema,
          isSpent: z.boolean(),
        })
      ),
    })
  ),
  linkedBillsTotal: currencySchema,
});

const vendorSchema = z.object({
  id: idSchema.nullish(),
  url: z.string().url().nullish(),
  displayName: z.string().nullish(),
});

const signatureSchema = z.object({
  id: idSchema,
  signedBy: z.string(),
  createdAt: dateSchema,
});

const poSignatureRequestSchema = z.object({
  id: idSchema.nullish(),
  status: z.string().nullish(),
  createdAt: dateSchema,
});

const purchaseOrderSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  date: dateSchema,
  type: z.nativeEnum(PURCHASE_ORDER_TYPE),
  lines: arraySchema(lineSchema),
  spent: currencySchema,
  realm: z.string().url(),
  items: arraySchema(itemSchema),
  vendor: vendorSchema,
  poStatus: poStatusEnum,
  accounts: arraySchema(accountSchema),
  customers: arraySchema(customerSchema),
  docNumber: z.string(),
  isCreator: z.boolean().nullish(),
  createdAt: dateSchema,
  totalAmount: currencySchema,
  openBalance: currencySchema,
  isEditAllowed: z.boolean().nullish(),
  publishedToQuickbooks: z.boolean(),
  latestPoSignatureRequest: poSignatureRequestSchema.nullish(),
  poSignatures: arraySchema(signatureSchema),
});

export const getPurchaseOrdersPayloadSchema = z.object({
  type: arraySchema(z.string()),
  realm: idSchema,
  limit: z.number().optional(),
  vendor: idSchema,
  detail: z.boolean().optional(),
  offset: z.number().optional(),
  account: arraySchema(idSchema),
  poStatus: poStatusEnum,
  customer: arraySchema(idSchema),
  signature: arraySchema(z.string().optional()),
  costCode: arraySchema(idSchema),
  ordering: z.string().optional(),
  docNumber: z.string().optional(),
  additionalIds: arraySchema(idSchema),
});

export const getPurchaseOrdersResponseSchema = z.object({
  next: z.string().url().nullish(),
  count: z.number(),
  results: arraySchema(purchaseOrderSchema),
  previous: z.string().url().nullish(),
});

export const getPurchaseOrderPayloadSchema = z.object({
  id: idSchema,
});

export const getPurchaseOrderResponseSchema = purchaseOrderSchema;

export const convertToAdaptivePayloadSchema = idSchema;

export const convertToAdaptiveResponseSchema = z.object({ id: idSchema });

export const batchConvertToAdaptivePayloadSchema = idSchema.array();

export const batchConvertToAdaptiveResponseSchema = z.void();
