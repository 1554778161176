import { api } from "@store/api-simplified";
import { api as baseApi } from "@utils/api";
import {
  getSearchParamsFromFilters,
  handleRequest,
  handleResponse,
} from "@utils/api";
import { poll } from "@utils/poll";
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";

import {
  builderTrendCredentialsSchema,
  createBuilderTrendCredentialsPayloadSchema,
  getBuilderTrendBuildersSimpleListResponseSchema,
  getBuilderTrendCostCodesListResponseSchema,
  getBuilderTrendCredentialsListResponseSchema,
  getBuilderTrendJobsListPayloadSchema,
  getBuilderTrendJobsListResponseSchema,
  getBuilderTrendPurchaseOrdersListPayloadSchema,
  getBuilderTrendPurchaseOrdersListResponseSchema,
  getBuilderTrendVendorsListPayloadSchema,
  getBuilderTrendVendorsListResponseSchema,
  updateBuilderTrendCostCodePayloadSchema,
  updateBuilderTrendCredentialsDefaultBuilderPayloadSchema,
  updateBuilderTrendCredentialsPayloadSchema,
  updateBuilderTrendJobPayloadSchema,
  updateBuilderTrendVendorPayloadSchema,
} from "./schemas";
import {
  type BuilderTrendCredentials,
  type CreateBuilderTrendCredentialsPayload,
  type GetBuilderTrendBuildersSimpleListResponse,
  type GetBuilderTrendCostCodesListPayload,
  type GetBuilderTrendCostCodesListResponse,
  type GetBuilderTrendCredentialsListResponse,
  type GetBuilderTrendJobsListPayload,
  type GetBuilderTrendJobsListResponse,
  type GetBuilderTrendPurchaseOrdersListPayload,
  type GetBuilderTrendPurchaseOrdersListResponse,
  type GetBuilderTrendVendorsListPayload,
  type GetBuilderTrendVendorsListResponse,
  type UpdateBuilderTrendCostCodePayload,
  type UpdateBuilderTrendCredentialsDefaultBuilderPayload,
  type UpdateBuilderTrendCredentialsPayload,
  type UpdateBuilderTrendJobPayload,
  type UpdateBuilderTrendVendorPayload,
} from "./types";

const TAG = "BuilderTrend";
const enhancedApi = api.enhanceEndpoints({ addTagTypes: [TAG] });

export const builderTrendJobsApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getBuilderTrendBuildersSimpleList: builder.query<
      GetBuilderTrendBuildersSimpleListResponse,
      number
    >({
      query: (realmId) => {
        return {
          url: `buildertrendbuilders/?realm=${realmId}&simplified=true`,
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              data,
              getBuilderTrendBuildersSimpleListResponseSchema
            );
          },
        };
      },
      providesTags: (data) =>
        data
          ? [
              ...data.map(({ id }) => ({ type: TAG, id }) as const),
              { type: TAG, id: "LIST" },
            ]
          : [{ type: TAG, id: "LIST" }],
    }),
    getBuilderTrendJobsList: builder.query<
      GetBuilderTrendJobsListResponse,
      GetBuilderTrendJobsListPayload
    >({
      query: (args) => {
        const params = handleRequest(
          args,
          getBuilderTrendJobsListPayloadSchema
        );

        return {
          url: `buildertrendjobs/?${getSearchParamsFromFilters(params).toString()}`,
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              getBuilderTrendJobsListResponseSchema
            );
          },
        };
      },
      providesTags: (data) =>
        data
          ? [
              ...data.results.map(({ id }) => ({ type: TAG, id }) as const),
              { type: TAG, id: "LIST" },
            ]
          : [{ type: TAG, id: "LIST" }],
    }),
    updateBuilderTrendJob: builder.mutation<void, UpdateBuilderTrendJobPayload>(
      {
        query: (args) => {
          const { id, ...data } = handleRequest(
            args,
            updateBuilderTrendJobPayloadSchema
          );
          return {
            url: `buildertrendjobs/${id}/`,
            body: transformKeysToSnakeCase(data),
            method: "PUT",
          };
        },
        invalidatesTags: (result, error, args) => {
          if (error) return [];
          return [{ type: TAG, id: args.id }];
        },
      }
    ),
    loadBuilderTrendBudget: builder.mutation<void, string>({
      query: (id) => {
        return {
          url: `buildertrendjobs/${id}/load_budget/`,
          method: "POST",
        };
      },
      invalidatesTags: (result, error) => {
        if (error) return [];
        return [
          { type: TAG, id: "LIST" },
          "BudgetLines",
          "CustomerItems",
          "CustomerMarkup",
          "BudgetLineItems",
          "CostCodesAccountsSimplified",
        ];
      },
    }),
    getBuilderTrendCostCodesList: builder.query<
      GetBuilderTrendCostCodesListResponse,
      GetBuilderTrendCostCodesListPayload
    >({
      query: (args) => {
        const params = handleRequest(
          args,
          getBuilderTrendJobsListPayloadSchema
        );

        return {
          url: `buildertrendcostcodes/?${getSearchParamsFromFilters(params).toString()}`,
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              getBuilderTrendCostCodesListResponseSchema
            );
          },
        };
      },
      providesTags: (data) =>
        data
          ? [
              ...data.results.map(({ id }) => ({ type: TAG, id }) as const),
              { type: TAG, id: "LIST" },
            ]
          : [{ type: TAG, id: "LIST" }],
    }),
    updateBuilderTrendCostCode: builder.mutation<
      void,
      UpdateBuilderTrendCostCodePayload
    >({
      query: (args) => {
        const { id, ...data } = handleRequest(
          args,
          updateBuilderTrendCostCodePayloadSchema
        );
        return {
          url: `buildertrendcostcodes/${id}/`,
          body: transformKeysToSnakeCase(data),
          method: "PUT",
        };
      },
      invalidatesTags: (result, error, args) => {
        if (error) return [];
        return [{ type: TAG, id: args.id }];
      },
    }),
    getBuilderTrendCredentialsList: builder.query<
      GetBuilderTrendCredentialsListResponse,
      number
    >({
      query: (realmId) => {
        return {
          url: `buildertrendcredentials/?realm=${realmId}`,
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              getBuilderTrendCredentialsListResponseSchema
            );
          },
        };
      },
      providesTags: (data) =>
        data
          ? [
              ...data.results.map(({ id }) => ({ type: TAG, id }) as const),
              { type: TAG, id: "LIST" },
            ]
          : [{ type: TAG, id: "LIST" }],
    }),
    updateBuilderTrendCredentials: builder.mutation<
      void,
      UpdateBuilderTrendCredentialsPayload
    >({
      query: (args) => {
        const { id, ...data } = handleRequest(
          args,
          updateBuilderTrendCredentialsPayloadSchema
        );
        return {
          url: `buildertrendcredentials/${id}/`,
          body: transformKeysToSnakeCase(data),
          method: "PUT",
        };
      },
      invalidatesTags: (result, error, args) => {
        if (error) return [];
        return [{ type: TAG, id: args.id }];
      },
    }),
    createBuilderTrendCredentials: builder.mutation<
      void,
      CreateBuilderTrendCredentialsPayload
    >({
      query: (args) => {
        const { ...data } = handleRequest(
          args,
          createBuilderTrendCredentialsPayloadSchema
        );
        return {
          url: `buildertrendcredentials/`,
          body: transformKeysToSnakeCase(data),
          method: "POST",
        };
      },
      invalidatesTags: (result, error) => {
        if (error) return [];
        return [{ type: TAG, id: "LIST" }];
      },
    }),
    syncBuilderTrendCredentials: builder.mutation<void, string>({
      query: (id) => {
        return {
          url: `buildertrendcredentials/${id}/sync/`,
          method: "POST",
        };
      },
      invalidatesTags: (result, error, id) => {
        if (error) return [];
        return [{ type: TAG, id }];
      },
    }),
    updateBuilderTrendCredentialsDefaultBuilder: builder.mutation<
      void,
      UpdateBuilderTrendCredentialsDefaultBuilderPayload
    >({
      query: (args) => {
        const { id, ...data } = handleRequest(
          args,
          updateBuilderTrendCredentialsDefaultBuilderPayloadSchema
        );
        return {
          url: `buildertrendcredentials/${id}/update_default_builder/`,
          body: transformKeysToSnakeCase(data),
          method: "PUT",
        };
      },
      invalidatesTags: (result, error, args) => {
        if (error) return [];
        return [{ type: TAG, id: args.id }];
      },
    }),
    getBuilderTrendVendorsList: builder.query<
      GetBuilderTrendVendorsListResponse,
      GetBuilderTrendVendorsListPayload
    >({
      query: (args) => {
        const params = handleRequest(
          args,
          getBuilderTrendVendorsListPayloadSchema
        );

        return {
          url: `buildertrendvendors/?${getSearchParamsFromFilters(params).toString()}`,
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              getBuilderTrendVendorsListResponseSchema
            );
          },
        };
      },
      providesTags: (data) =>
        data
          ? [
              ...data.results.map(({ id }) => ({ type: TAG, id }) as const),
              { type: TAG, id: "LIST" },
            ]
          : [{ type: TAG, id: "LIST" }],
    }),
    updateBuilderTrendVendor: builder.mutation<
      void,
      UpdateBuilderTrendVendorPayload
    >({
      query: (args) => {
        const { id, ...data } = handleRequest(
          args,
          updateBuilderTrendVendorPayloadSchema
        );
        return {
          url: `buildertrendvendors/${id}/`,
          body: transformKeysToSnakeCase(data),
          method: "PUT",
        };
      },
      invalidatesTags: (result, error, args) => {
        if (error) return [];
        return [{ type: TAG, id: args.id }];
      },
    }),
    getBuilderTrendPurchaseOrdersList: builder.query<
      GetBuilderTrendPurchaseOrdersListResponse,
      GetBuilderTrendPurchaseOrdersListPayload
    >({
      query: (args) => {
        const params = handleRequest(
          args,
          getBuilderTrendPurchaseOrdersListPayloadSchema
        );

        return {
          url: `buildertrendpurchaseorders/?${getSearchParamsFromFilters(params).toString()}`,
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              getBuilderTrendPurchaseOrdersListResponseSchema
            );
          },
        };
      },
      providesTags: (data) =>
        data
          ? [
              ...data.results.map(({ id }) => ({ type: TAG, id }) as const),
              { type: TAG, id: "LIST" },
            ]
          : [{ type: TAG, id: "LIST" }],
    }),
    loadBuilderTrendPurchaseOrder: builder.mutation<void, string>({
      query: (id) => {
        return {
          url: `buildertrendpurchaseorders/${id}/load/`,
          method: "POST",
        };
      },
      invalidatesTags: (result, error) => {
        if (error) return [];
        return [{ type: TAG, id: "LIST" }];
      },
    }),
  }),
});

export const pollBuilderTrendCredentials = (id: string) => {
  return poll<BuilderTrendCredentials>({
    fn: () =>
      baseApi
        .get<BuilderTrendCredentials>(`/api/buildertrendcredentials/${id}/`)
        .then(({ data }) => {
          const transformedData = handleResponse(
            transformKeysToCamelCase(data),
            builderTrendCredentialsSchema
          );
          return transformedData;
        }),
    validate: (data) => {
      return data?.syncStatus
        ? !["IN_PROGRESS", "PENDING"].includes(data.syncStatus)
        : false;
    },
  });
};

export const {
  useGetBuilderTrendJobsListQuery,
  useUpdateBuilderTrendJobMutation,
  useGetBuilderTrendCostCodesListQuery,
  useUpdateBuilderTrendCostCodeMutation,
  useGetBuilderTrendCredentialsListQuery,
  useUpdateBuilderTrendCredentialsMutation,
  useSyncBuilderTrendCredentialsMutation,
  useLoadBuilderTrendBudgetMutation,
  useCreateBuilderTrendCredentialsMutation,
  useGetBuilderTrendVendorsListQuery,
  useGetBuilderTrendPurchaseOrdersListQuery,
  useUpdateBuilderTrendVendorMutation,
  useLoadBuilderTrendPurchaseOrderMutation,
  useGetBuilderTrendBuildersSimpleListQuery,
  useUpdateBuilderTrendCredentialsDefaultBuilderMutation,
} = builderTrendJobsApi;
