import { useCallback, useState } from "react";
import { useDialog } from "@adaptive/design-system/hooks";
import { sum } from "@utils/sum";

import type { VendorCreditSelectionDialogProps } from "../../components/vendor-credit-selection-dialog/vendor-credit-selection-dialog";
import type {
  DynamicBalanceVendorCredit,
  MultiplePaymentForm,
} from "../../types";

export type EditVendorCreditState = Pick<
  VendorCreditSelectionDialogProps,
  | "vendorCredits"
  | "openBalanceAmount"
  | "vendorName"
  | "initialSelectedVendorCredits"
> & {
  paymentIndex: number;
};

export const useAppliedVendorCreditsForMultiplePayments = ({
  vendorCreditsByVendor,
  formValues,
  onChangeVendorCredits,
}: {
  vendorCreditsByVendor: Record<string, DynamicBalanceVendorCredit[]>;
  formValues: MultiplePaymentForm;
  onChangeVendorCredits: ({
    appliedVendorCredits,
    paymentIndex,
  }: {
    appliedVendorCredits: DynamicBalanceVendorCredit[];
    paymentIndex: number;
  }) => void;
}) => {
  const vendorCreditSelectionDialog = useDialog({
    lazy: true,
  });

  const [editVendorCreditState, setEditVendorCreditState] =
    useState<EditVendorCreditState>({
      vendorCredits: [],
      openBalanceAmount: 0,
      vendorName: "",
      initialSelectedVendorCredits: [],
      paymentIndex: 0,
    });

  const onEditVendorCredits = useCallback(
    (index: number) => {
      const payment = formValues.payments[index];
      const enhancedVendorCredits = vendorCreditsByVendor[
        payment.vendor.id
      ].map((vendorCredit) => {
        const appliedVendorCredit = payment.appliedVendorCredits?.find(
          (appliedVendorCredit) => appliedVendorCredit.id === vendorCredit.id
        );

        return {
          ...vendorCredit,
          appliedAmount: appliedVendorCredit?.appliedAmount || 0,
          // Update the current open balance to reflect the applied amount as open balance for the modal
          currentOpenBalance: sum(
            vendorCredit.currentOpenBalance || 0,
            appliedVendorCredit?.appliedAmount || 0
          ),
        };
      });

      const enhancedAppliedVendorCredits =
        payment?.appliedVendorCredits
          ?.map((appliedVendorCredit) => {
            return enhancedVendorCredits.find(
              (vc) => vc.id === appliedVendorCredit.id
            );
          })
          .filter((vc) => !!vc) || [];

      setEditVendorCreditState({
        paymentIndex: index,
        vendorCredits: enhancedVendorCredits,
        openBalanceAmount: payment.balance || 0,
        vendorName: payment.vendor.displayName,
        initialSelectedVendorCredits: enhancedAppliedVendorCredits,
      });
      vendorCreditSelectionDialog.show();
    },
    [formValues.payments, vendorCreditSelectionDialog, vendorCreditsByVendor]
  );

  const onApplyVendorCredits = useCallback(
    (newAppliedVendorCredits: DynamicBalanceVendorCredit[]) => {
      onChangeVendorCredits({
        appliedVendorCredits: newAppliedVendorCredits,
        paymentIndex: editVendorCreditState.paymentIndex,
      });
    },
    [editVendorCreditState.paymentIndex, onChangeVendorCredits]
  );
  return {
    vendorCreditSelectionDialog,
    editVendorCreditState,
    onEditVendorCredits,
    onSaveVendorCredits: onApplyVendorCredits,
  };
};
