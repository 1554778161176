import React, {
  type ComponentRef,
  type ElementType,
  type FocusEventHandler,
  type ForwardedRef,
  forwardRef,
  isValidElement,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Checkbox,
  ComboBox,
  type ComboBoxActionAddon,
  ComboBoxOption,
  type ComboBoxProps,
  type ComboBoxRef,
  type ComboBoxRenderOptionComponent,
  type ComboBoxRenderOptionHandler,
  Dropdown,
  DropdownComboBoxItem,
  DropdownList,
  type DropdownListRef,
  DropdownSelectableItem,
  DropdownTrigger,
  Flex,
  Icon,
  TagGroup,
  Text,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import {
  is,
  isFocusable,
  isOption,
  mergeRefs,
} from "@adaptive/design-system/utils";
import type { MarkupResponse } from "@api/budgets";
import type { BudgetLineItems } from "@api/jobs";
import { type AccountFilters } from "@hooks/use-accounts-simplified";
import { useCostCodesAccountsSimplified } from "@hooks/use-cost-codes-accounts-simplified";
import type { UseCostCodesSimplifiedProps } from "@hooks/use-cost-codes-simplified";
import { useCustomersSimplified } from "@hooks/use-customers-simplified";
import { getPreferredRevenueBudgetAmount } from "@src/jobs/detail-view/budget/budgets-table/utils";
import { useClientSettings, useUserInfo } from "@store/user";
import { isAccount } from "@utils/is-account";
import { isCostCode } from "@utils/is-cost-code";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";

import type { Option } from "../../types";

import {
  renderCostCodeAccountLabel,
  type RenderCostCodeAccountLabelHandler,
  renderCostCodeAccountPlaceholder,
} from "./cost-code-account-combobox-utils";

type Ref = ComponentRef<typeof ComboBox>;

type ApplyAutomaticFilterAndValuesHandler = (params?: {
  autosetValues?: boolean;
}) => void;

type Filter = {
  label: string;
  value: "budget" | "vendor" | "item" | "account";
};

const HIGHLIGHTED_TAGS = ["Vendor", "Budget"];

const BUDGET_FILTER: Filter = { label: "Budget code", value: "budget" };

const VENDOR_FILTER: Filter = { label: "Vendor code", value: "vendor" };

const COST_CODE_FILTER: Filter = { label: "Cost code", value: "item" };

const ACCOUNT_FILTER: Filter = { label: "Account", value: "account" };

const EMPTY_FILTERS: Filter[] = [];

const VENDOR_FILTERS: Filter[] = [VENDOR_FILTER];

const COST_CODE_ACCOUNT_FILTERS: Filter[] = [COST_CODE_FILTER, ACCOUNT_FILTER];

const DEFAULT_ENABLED_FILTERS = [
  "vendorCode",
  "costCodeAccount",
  "budgetCode",
] as const;

const ORDERINGS = [
  { label: "Alphabetical", value: "alphabetical" },
  { label: "Budget & vendor codes", value: "budget-vendor-codes" },
];

const SEARCH_KEYS = ["label", "description"];

export type CostCodeAccountOption = Option & {
  vendorId?: string;
  parentName?: string;
  customerId?: string;
  description?: string;
  isBudgetCode?: boolean;
  isVendorCode?: boolean;
};

const MultipleOption: ComboBoxRenderOptionComponent<
  CostCodeAccountOption
  // eslint-disable-next-line react/prop-types
> = ({ highlightedLabel, ...option }) => {
  return (
    <Flex align="center" gap="lg">
      {option.multiple && (
        <Checkbox aria-hidden="true" checked={option.selected} />
      )}
      <Flex direction="column">
        <Text>{highlightedLabel}</Text>
      </Flex>
    </Flex>
  );
};

const TaggableOption: ComboBoxRenderOptionComponent<
  CostCodeAccountOption
  // eslint-disable-next-line react/prop-types
> = ({ highlightedLabel, ...option }) => {
  const { failSafeCostCodesEnabled, itemDescriptionEnabled } =
    useClientSettings();

  const tags = useMemo(() => {
    const data = [];

    if (failSafeCostCodesEnabled && option.isVendorCode) {
      data.push("Vendor");
    }

    if (failSafeCostCodesEnabled && option.isBudgetCode) {
      data.push("Budget");
    }

    if (isCostCode(option)) {
      data.push("Cost code");
    } else if (isAccount(option)) {
      data.push("Account");
    }

    return data;
  }, [option, failSafeCostCodesEnabled]);

  const color = useCallback(
    (label: string) => (HIGHLIGHTED_TAGS.includes(label) ? "info" : "neutral"),
    []
  );

  if (!option.value) return null;

  return (
    <ComboBoxOption {...option} label={highlightedLabel}>
      {itemDescriptionEnabled && option.description && (
        <Text
          size="xs"
          color={
            option.selected && !option.multiple ? undefined : "neutral-600"
          }
          truncate
        >
          Description: {option.description}
        </Text>
      )}
      <TagGroup size="sm" data={tags} color={color} />
    </ComboBoxOption>
  );
};

export type CostCodeAccountComboBoxProps<IsMultiple extends boolean> = Omit<
  ComboBoxProps<IsMultiple>,
  "label" | "placeholder"
> & {
  as?: ElementType;
  label?: string | RenderCostCodeAccountLabelHandler;
  placeholder?: string | ((data?: Option[]) => string);
  accountFilters?: AccountFilters & { enabled?: boolean };
  costCodeFilters?: UseCostCodesSimplifiedProps;
  customer?: string;
  filters?: readonly ("budgetCode" | "vendorCode" | "costCodeAccount")[];
  disabledBudgetLines?: Pick<BudgetLineItems, "jobCostMethod">[];
  disabledChangeMarkups?: Pick<MarkupResponse, "jobCostMethod">[];
  disabledBudgetMarkups?: Pick<MarkupResponse, "jobCostMethod">[];
};

const compareCostCodeAccountOptions = (
  a: CostCodeAccountOption,
  b: CostCodeAccountOption
) => {
  /**
   * Vendor codes should come before budget codes
   */
  if (a.isVendorCode && !b.isVendorCode) {
    return -1;
  }
  if (!a.isVendorCode && b.isVendorCode) {
    return 1;
  }
  /**
   * Options with budget codes or vendor codes should come first
   */
  if (
    (a.isBudgetCode && !b.isBudgetCode) ||
    (a.isVendorCode && !b.isVendorCode)
  ) {
    return -1;
  }

  if (
    (!a.isBudgetCode && b.isBudgetCode) ||
    (!a.isVendorCode && b.isVendorCode)
  ) {
    return 1;
  }

  return 0;
};

const CostCodeAccountComboBox = <IsMultiple extends boolean = false>(
  {
    as: Component = ComboBox,
    label,
    value,
    filters = DEFAULT_ENABLED_FILTERS,
    multiple,
    onChange,
    disabled,
    placeholder,
    accountFilters,
    messageVariant,
    costCodeFilters,
    disabledBudgetLines,
    disabledBudgetMarkups,
    disabledChangeMarkups,
    ...props
  }: CostCodeAccountComboBoxProps<IsMultiple>,
  ref: ForwardedRef<Ref>
) => {
  const vendorId =
    costCodeFilters?.vendorId?.toString() ||
    accountFilters?.vendorId?.toString();
  const customerId =
    costCodeFilters?.customerId?.toString() ||
    accountFilters?.customerId?.toString();

  const { failSafeCostCodesEnabled, itemDescriptionEnabled } =
    useClientSettings();

  const vendorIdRef = useRef(vendorId);
  const customerIdRef = useRef(customerId);

  const shouldTryAutoset = useRef(false);

  const mainComboboxRef = useRef<ComboBoxRef>(null);

  const dropdownListRef = useRef<DropdownListRef>(null);
  const dropdownTriggerRef = useRef<ComponentRef<typeof DropdownTrigger>>(null);

  const filterComboboxRef = useRef<ComboBoxRef>(null);

  const [orderingDropdownShow, setOrderingDropdownShow] = useState(false);

  const allowOrdering = useMemo(
    () =>
      failSafeCostCodesEnabled &&
      (filters.includes("budgetCode") || filters.includes("vendorCode")),
    [filters, failSafeCostCodesEnabled]
  );

  const enhancedOrdering = useMemo(() => {
    return ORDERINGS.map((orderingObj) => {
      if (orderingObj.value === "budget-vendor-codes") {
        if (
          filters?.includes("budgetCode") &&
          !filters?.includes("vendorCode")
        ) {
          return {
            ...orderingObj,
            label: "Budget codes",
          };
        }
        if (
          !filters?.includes("budgetCode") &&
          filters?.includes("vendorCode")
        ) {
          return {
            ...orderingObj,
            label: "Vendor codes",
          };
        }
      }
      return orderingObj;
    });
  }, [filters]);

  const [ordering, setOrdering] = useState("budget-vendor-codes");

  const { canViewAllCodes } = useUserInfo();

  const internalDisabled = useMemo(() => {
    return (
      !canViewAllCodes &&
      filters?.length === 1 &&
      filters.includes("costCodeAccount")
    );
  }, [canViewAllCodes, filters]);

  const { data: jobs, status: customersStatus } = useCustomersSimplified({
    enabled: !!customerId,
  });

  const { data, status } = useCostCodesAccountsSimplified({
    accountFilters,
    costCodeFilters,
    showAll: canViewAllCodes,
    includeLabel: false,
  });

  const isLoading = status === "loading" || customersStatus === "loading";

  const availableFilters = useMemo(() => {
    return filters.reduce<Filter[]>((acc, filter) => {
      if (failSafeCostCodesEnabled && filter === "budgetCode") {
        return [...acc, BUDGET_FILTER];
      }
      if (failSafeCostCodesEnabled && filter === "vendorCode") {
        return [...acc, VENDOR_FILTER];
      }
      if (filter === "costCodeAccount") {
        const costCodeAccountFilters = COST_CODE_ACCOUNT_FILTERS.filter((f) => {
          if (isAccount(f.value)) {
            return accountFilters?.enabled !== false;
          }
          if (isCostCode(f.value)) {
            return costCodeFilters?.enabled !== false;
          }
          return true;
        });
        return [...acc, ...costCodeAccountFilters];
      }
      return acc;
    }, []);
  }, [
    filters,
    costCodeFilters?.enabled,
    accountFilters?.enabled,
    failSafeCostCodesEnabled,
  ]);

  const budgetCodesEnabled = useMemo(() => {
    const job = jobs?.find(
      (job) => parseRefinementIdFromUrl(job.value) === customerId
    );

    return job?.budgetCodesEnabled ?? false;
  }, [jobs, customerId]);

  const [appliedFilters, setAppliedFilters] = useState<Option[]>([]);

  const renderValue = useCallback(
    (option: CostCodeAccountOption) => {
      const selectedOption =
        data.find((item) => item.value === option.value) ?? option;

      const description =
        "description" in selectedOption
          ? selectedOption.description
          : undefined;

      if (!itemDescriptionEnabled || !description) return selectedOption.label;

      return {
        visible: `${selectedOption.label} - ${description}`,
        hidden: `${selectedOption.label}\nDescription: ${description}`,
      };
    },
    [data, itemDescriptionEnabled]
  );

  const enhancedData = useMemo(() => {
    const castData = data as CostCodeAccountOption[];

    for (const option of is.array(value) ? value : [value]) {
      if (!isOption(option)) continue;

      const shouldAppendValue = !castData.some(
        (innerOption) => innerOption.value === option.value
      );

      if (shouldAppendValue) castData.unshift(option);
    }

    let disabledData = castData;

    if (disabledChangeMarkups?.length) {
      disabledData = castData.map((item) => {
        if (
          disabledChangeMarkups?.find(
            (lineItem) => lineItem?.jobCostMethod?.url === item.value
          )
        ) {
          return {
            ...item,
            disabled: "Changes cannot be created for markups",
          };
        }
        return item;
      });
    }

    const groupLabelData = window.BUDGETS_DRAW_ACCOUNTS_COST_CODE_ENABLED
      ? data
      : data.map((item) => ({ ...item, groupLabel: undefined }));

    if (disabledBudgetLines?.length) {
      disabledData = groupLabelData.map((item) => {
        if (
          disabledBudgetLines?.find(
            (lineItem) =>
              lineItem?.jobCostMethod?.url === item.value &&
              getPreferredRevenueBudgetAmount(lineItem) !== 0
          )
        ) {
          return {
            ...item,
            disabled:
              "Lines with budget values set cannot be converted to markups",
          };
        }
        return item;
      });
    }

    if (disabledBudgetMarkups?.length) {
      disabledData = groupLabelData.map((item) => {
        if (
          disabledBudgetMarkups?.find(
            (lineItem) => lineItem?.jobCostMethod?.url === item.value
          )
        ) {
          return {
            ...item,
            disabled: "Changes cannot be created for markups",
          };
        }
        return item;
      });
    }

    const filteredData = disabledData.filter((item) => {
      if (!appliedFilters?.length) return true;

      return (
        appliedFilters.some((filter) => {
          switch (filter.value) {
            case "budget":
              return item.isBudgetCode;
            case "vendor":
              return item.isVendorCode;
            default:
              return false;
          }
        }) ||
        appliedFilters.some((filter) => {
          switch (filter.value) {
            case "item":
              return isCostCode(item);
            case "account":
              return isAccount(item);
            default:
              return false;
          }
        })
      );
    });

    if (!allowOrdering) return filteredData;

    if (ordering === "budget-vendor-codes") {
      return filteredData
        .map((item) => ({
          ...item,
          parent: undefined,
        }))
        .sort(compareCostCodeAccountOptions);
    }

    return filteredData;
  }, [
    data,
    value,
    ordering,
    allowOrdering,
    appliedFilters,
    disabledBudgetLines,
    disabledChangeMarkups,
    disabledBudgetMarkups,
  ]);

  const enhancedOnChange = useEvent<
    Exclude<ComboBoxProps<IsMultiple>["onChange"], undefined>
  >((...props) => {
    onChange?.(...props);
  });

  const warningMessage = useMemo(() => {
    if (
      !failSafeCostCodesEnabled ||
      multiple ||
      !value ||
      isLoading ||
      (!customerId && !vendorId) ||
      typeof value === "string"
    ) {
      return;
    }

    const singleValue = value as CostCodeAccountOption;
    const isInVendorList = data.some(
      (existingOption: CostCodeAccountOption) =>
        existingOption.isVendorCode &&
        existingOption.value === singleValue?.value
    );
    const isInBudgetList = data.some(
      (existingOption: CostCodeAccountOption) =>
        existingOption.isBudgetCode &&
        existingOption.value === singleValue?.value
    );

    if (vendorId && !isInVendorList) {
      if (customerId) {
        if (budgetCodesEnabled && !isInBudgetList) {
          return "Cost code not on budget or vendor code list";
        }
      }
      return "Cost code not on vendor code list";
    }

    if (customerId && budgetCodesEnabled && !isInBudgetList) {
      return "Cost code not on budget code list";
    }
  }, [
    value,
    data,
    isLoading,
    customerId,
    vendorId,
    multiple,
    budgetCodesEnabled,
    failSafeCostCodesEnabled,
  ]);

  const applyAutomaticFiltersAndValues =
    useCallback<ApplyAutomaticFilterAndValuesHandler>(
      ({ autosetValues } = { autosetValues: true }) => {
        if (isLoading) return;

        const canAutosetValue =
          failSafeCostCodesEnabled &&
          shouldTryAutoset.current &&
          autosetValues &&
          !multiple;

        const autosetValue = (option: CostCodeAccountOption) => {
          if (!canAutosetValue) return;
          if (
            (vendorId &&
              option?.vendorId?.toString() !== vendorId.toString()) ||
            (customerId &&
              option?.customerId?.toString() !== customerId.toString())
          ) {
            return;
          }
          if (option.disabled) return;

          shouldTryAutoset.current = false;

          (enhancedOnChange as ComboBoxProps<false>["onChange"])?.(
            option.value,
            option
          );
        };

        const vendorSelected = !!vendorId;
        const customerSelected = customerId && budgetCodesEnabled;

        if (vendorSelected || customerSelected) {
          const overlappingOptions = data.filter(
            (item: CostCodeAccountOption) =>
              item.isVendorCode && item.isBudgetCode
          );
          const vendorCodeOptions = data.filter(
            (item: CostCodeAccountOption) => item.isVendorCode
          );

          if (
            vendorSelected &&
            customerSelected &&
            overlappingOptions.length === 1
          ) {
            autosetValue(overlappingOptions[0]);
          } else if (vendorCodeOptions.length === 1) {
            autosetValue(vendorCodeOptions[0]);
          }
          if (
            availableFilters.includes(VENDOR_FILTER) &&
            vendorCodeOptions.length > 0
          ) {
            return setAppliedFilters(VENDOR_FILTERS);
          }
        }
        return setAppliedFilters(EMPTY_FILTERS);
      },
      [
        isLoading,
        failSafeCostCodesEnabled,
        multiple,
        vendorId,
        customerId,
        budgetCodesEnabled,
        availableFilters,
        enhancedOnChange,
        data,
      ]
    );

  const enhancedLabel = useCallback<
    Exclude<ComboBoxProps<false>["label"], ReactNode>
  >(
    (params) => {
      if (isValidElement(label) || typeof label === "string") return label;

      return label
        ? label({ ...params, data })
        : renderCostCodeAccountLabel({ ...params, data });
    },
    [label, data]
  );

  const enhancedPlaceholder = useMemo(() => {
    if (typeof placeholder === "string") return placeholder;

    return placeholder
      ? placeholder(data)
      : renderCostCodeAccountPlaceholder({ data });
  }, [placeholder, data]);

  const emptyActionAddon = useMemo<ComboBoxActionAddon>(
    () => ({
      mode: appliedFilters?.length > 0 ? "empty" : "hide",
      onClick: () => setAppliedFilters([]),
      children: "Show all codes",
      hideOnClick: false,
    }),
    [appliedFilters]
  );

  const emptyMessage = useMemo(() => {
    if (!canViewAllCodes) {
      return "No options available. Adjust job or vendor to see more.";
    }
    if (
      appliedFilters.includes(BUDGET_FILTER) &&
      appliedFilters.includes(VENDOR_FILTER)
    ) {
      if (customerId && budgetCodesEnabled && vendorId) {
        return "No vendor or budget codes found";
      }
    }
    if (
      customerId &&
      budgetCodesEnabled &&
      appliedFilters.includes(BUDGET_FILTER) &&
      !appliedFilters.includes(VENDOR_FILTER)
    ) {
      return "This job's budget doesn't have any lines. Select a code from the full list to add it as a budget code.";
    }
    if (
      customerId &&
      !budgetCodesEnabled &&
      appliedFilters.includes(BUDGET_FILTER) &&
      !appliedFilters.includes(VENDOR_FILTER)
    ) {
      return "Budget codes not enabled for this job";
    }
    if (
      vendorId &&
      !appliedFilters.includes(BUDGET_FILTER) &&
      appliedFilters.includes(VENDOR_FILTER)
    ) {
      return "No previously used codes for this vendor. Any new code selected will be added as a vendor code.";
    }
    return;
  }, [
    canViewAllCodes,
    appliedFilters,
    customerId,
    budgetCodesEnabled,
    vendorId,
  ]);

  const onRemoveFilter = useEvent((option: Option) => {
    setAppliedFilters((filters) =>
      filters.filter((filter) => filter.value !== option.value)
    );
  });

  const onDropdownChange = useEvent((value: boolean) => {
    setOrderingDropdownShow(value);

    if (!value) {
      requestAnimationFrame(() => {
        if (!isFocusable(document.activeElement)) {
          mainComboboxRef.current?.focus();
        }
      });
    }
  });

  const onComboboxFocus = useEvent(() => {
    if (
      mainComboboxRef.current?.getAttribute("aria-expanded") !== "true" &&
      filterComboboxRef.current?.getAttribute("aria-expanded") !== "true" &&
      dropdownListRef.current?.dataset.open !== "true"
    ) {
      applyAutomaticFiltersAndValues({ autosetValues: false });
      setOrderingDropdownShow(false);
    }
  });

  const onFilterBlur = useEvent<FocusEventHandler<HTMLInputElement>>((e) => {
    if (!isFocusable(e.relatedTarget)) {
      mainComboboxRef.current?.focus();
    }
  });

  const hideOnInteractOutside = useEvent(
    () =>
      dropdownListRef.current?.dataset.open !== "true" &&
      document.activeElement !== dropdownTriggerRef.current &&
      filterComboboxRef.current?.getAttribute("aria-expanded") !== "true"
  );

  const onFilterChange = useEvent((_, selectedFilter?: Option) => {
    filterComboboxRef.current?.blur();

    if (!selectedFilter) return;

    setAppliedFilters((filters) =>
      filters.some((filter) => filter.value === selectedFilter.value)
        ? filters.filter((filter) => filter.value !== selectedFilter.value)
        : [...filters, selectedFilter]
    );
  });

  const renderComboboxOption = useCallback<
    ComboBoxRenderOptionHandler<CostCodeAccountOption>
  >((option, extra) => <TaggableOption {...option} {...extra} />, []);

  const renderFilterOption = useCallback<
    ComboBoxRenderOptionHandler<CostCodeAccountOption>
  >(
    (option, extra) => (
      <MultipleOption
        {...option}
        multiple
        selected={appliedFilters.some(
          (filter) => filter.value === option.value
        )}
        {...extra}
      />
    ),
    [appliedFilters]
  );

  useEffect(() => {
    if (
      vendorIdRef.current !== vendorId ||
      customerIdRef.current !== customerId
    ) {
      shouldTryAutoset.current = true;
      vendorIdRef.current = vendorId;
      customerIdRef.current = customerId;
    }
  }, [vendorId, customerId]);

  useEffect(() => {
    applyAutomaticFiltersAndValues();
  }, [applyAutomaticFiltersAndValues]);

  return (
    <Component
      ref={mergeRefs(mainComboboxRef, ref)} // eslint-disable-line
      hideOnInteractOutside={hideOnInteractOutside}
      data={enhancedData}
      label={enhancedLabel}
      value={value}
      onFocus={onComboboxFocus}
      action={emptyActionAddon}
      loading={isLoading}
      multiple={multiple}
      onChange={enhancedOnChange}
      searchKeys={SEARCH_KEYS}
      renderValue={renderValue}
      placeholder={enhancedPlaceholder}
      renderOption={renderComboboxOption}
      emptyMessage={emptyMessage}
      warningMessage={warningMessage}
      header={
        availableFilters.length > 1 && (
          <Flex gap="md" direction="column" width="full">
            <Flex gap="md">
              <ComboBox
                ref={filterComboboxRef}
                size="sm"
                data={availableFilters}
                value=""
                portal
                readOnly
                onBlur={onFilterBlur}
                onChange={onFilterChange}
                placeholder="Filters"
                renderOption={renderFilterOption}
                messageVariant="hidden"
              />
              {allowOrdering && (
                <Dropdown
                  placement="bottom-end"
                  show={orderingDropdownShow}
                  onChange={onDropdownChange}
                >
                  <DropdownTrigger
                    ref={dropdownTriggerRef}
                    as={Button}
                    size="sm"
                    color="neutral"
                    variant="ghost"
                  >
                    <Icon name="bars-filter" />
                  </DropdownTrigger>
                  <DropdownList ref={dropdownListRef}>
                    <DropdownSelectableItem
                      name="order"
                      value={ordering}
                      data={enhancedOrdering}
                      onChange={setOrdering}
                    />
                  </DropdownList>
                </Dropdown>
              )}
            </Flex>
            {appliedFilters.length > 0 && (
              <TagGroup
                size="sm"
                data={appliedFilters}
                render="label"
                onRemove={onRemoveFilter}
              />
            )}
          </Flex>
        )
      }
      gap={Component === DropdownComboBoxItem ? false : undefined}
      helperMessage={
        internalDisabled
          ? "You don't have permission to view all codes"
          : undefined
      }
      disabled={disabled || internalDisabled}
      messageVariant={
        internalDisabled ? "relative" : messageVariant || "relative"
      }
      includeValueWhenMissing={false}
      {...props}
    />
  );
};

const ForwardedCostCodeAccountComboBox = forwardRef(
  CostCodeAccountComboBox
) as <IsMultiple extends boolean = false>(
  props: CostCodeAccountComboBoxProps<IsMultiple> & { ref?: ForwardedRef<Ref> }
) => ReturnType<typeof CostCodeAccountComboBox>;

export { ForwardedCostCodeAccountComboBox as CostCodeAccountComboBox };
