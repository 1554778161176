import { createAsyncThunk } from "@reduxjs/toolkit";

import { api } from "../../utils/api";
import { switchRole } from "../user/slice";

import {
  addRole,
  deleteRole as deleteRoleAction,
  type Role,
  updatePermissionsCategories,
  updateRole as updateRoleAction,
  updateRoles,
} from "./slice";

type DeleRolePayload = { role: Role; newRole?: Role };

export const loadRoles = createAsyncThunk(
  "roles/load",
  async (clientId: string, { dispatch, rejectWithValue }) => {
    try {
      const roles = await api
        .get(`/api/clients/${clientId}/roles`)
        .then((response) => response.data?.results || []);

      dispatch(updateRoles(roles));
    } catch {
      rejectWithValue("Failed retrieving Roles Data");
    }
    return;
  }
);

export const loadRole = createAsyncThunk(
  "role/load",
  async (roleId: string, { dispatch, rejectWithValue }) => {
    try {
      const role = await api
        .get(`/api/roles/${roleId}`)
        .then((response) => response.data);

      dispatch(updateRoleAction(role));
    } catch {
      rejectWithValue("Failed retrieving Role Data");
    }
    return;
  }
);

export const createRole = createAsyncThunk(
  "role/create",
  async (payload: Role, { dispatch, rejectWithValue }) => {
    const result = await api
      .post(`/api/roles/`, payload)
      .then((response) => {
        dispatch(addRole(response.data));
        return response;
      })
      .catch(() => {
        rejectWithValue("Failed creating Role");
      });

    return {
      response: result,
    };
  }
);

export const updateRole = createAsyncThunk(
  "role/update",
  async (payload: Role, { dispatch, rejectWithValue }) => {
    const result = await api
      .put(`/api/roles/${payload.id}/`, payload)
      .then((response) => {
        dispatch(updateRoleAction(response.data));
        return response;
      })
      .catch(() => {
        rejectWithValue("Failed updating Role Data");
      });

    return {
      response: result,
    };
  }
);

export const deleteRole = createAsyncThunk(
  "role/delete",
  async ({ role, newRole }: DeleRolePayload, { dispatch, rejectWithValue }) => {
    const params: { switch_to?: string } = {};
    if (newRole?.id) {
      params.switch_to = newRole.id.toString();
    }
    return await api
      .delete(`/api/roles/${role.id}/`, { params })
      .then((response) => {
        dispatch(deleteRoleAction(role.id));
        if (newRole) {
          dispatch(switchRole({ role, newRole }));
        }
        return response;
      })
      .catch(() => {
        return rejectWithValue("Failed deleting Role");
      });
  }
);

export const loadPermissions = createAsyncThunk(
  "permission/load",
  async (_: void, { dispatch, rejectWithValue }) => {
    try {
      const permissions = await api
        .get(`/api/permissions/`)
        .then((response) => response.data?.results || []);

      dispatch(updatePermissionsCategories(permissions));
    } catch {
      rejectWithValue("Failed retrieving Permissions Data");
    }
  }
);
