import { useCallback, useEffect, useMemo, useRef } from "react";
import { dialog, toast } from "@adaptive/design-system";
import { useEvent, usePagination } from "@adaptive/design-system/hooks";
import { handleErrors } from "@api/handle-errors";
import { useJobActions } from "@src/jobs";
import { useClientInfo } from "@store/user";
import { noop } from "@utils/noop";

import {
  pollBuilderTrendCredentials,
  useCreateBuilderTrendCredentialsMutation,
  useGetBuilderTrendBuildersSimpleListQuery,
  useGetBuilderTrendCostCodesListQuery,
  useGetBuilderTrendCredentialsListQuery,
  useGetBuilderTrendJobsListQuery,
  useGetBuilderTrendPurchaseOrdersListQuery,
  useGetBuilderTrendVendorsListQuery,
  useLoadBuilderTrendBudgetMutation,
  useLoadBuilderTrendPurchaseOrderMutation,
  useSyncBuilderTrendCredentialsMutation,
  useUpdateBuilderTrendCostCodeMutation,
  useUpdateBuilderTrendCredentialsDefaultBuilderMutation,
  useUpdateBuilderTrendCredentialsMutation,
  useUpdateBuilderTrendJobMutation,
  useUpdateBuilderTrendVendorMutation,
} from "../api/api";
import { type BuilderTrendJob } from "../types";

export const useBuildertrend = ({
  jobsQuery,
  costCodesQuery,
  jobsSortBy,
  costCodesSortBy,
  vendorsQuery,
  vendorsSortBy,
  purchaseOrdersQuery,
  purchaseOrdersSortBy,
  skip = false,
}: {
  jobsQuery?: string;
  costCodesQuery?: string;
  jobsSortBy?: string;
  costCodesSortBy?: string;
  vendorsQuery?: string;
  vendorsSortBy?: string;
  purchaseOrdersQuery?: string;
  purchaseOrdersSortBy?: string;
  skip?: boolean;
} = {}) => {
  const { realmId } = useClientInfo();
  const pagination = usePagination({ initialPerPage: 50 });

  const baseFilters = useMemo(
    () => [
      {
        dataIndex: "realm",
        value: realmId?.toString() || "",
      },
      {
        dataIndex: "limit",
        value: pagination.perPage.toString(),
      },
      {
        dataIndex: "offset",
        value: pagination.offset.toString(),
      },
    ],
    [realmId, pagination.perPage, pagination.offset]
  );

  const jobsFilters = useMemo(
    () =>
      (jobsQuery
        ? [
            {
              dataIndex: "job_name",
              value: jobsQuery,
            },
          ]
        : []
      )
        .concat(baseFilters)
        .concat(
          jobsSortBy
            ? [
                {
                  dataIndex: "ordering",
                  value: jobsSortBy,
                },
              ]
            : []
        ),
    [jobsQuery, jobsSortBy, baseFilters]
  );

  const costCodesFilters = useMemo(
    () =>
      (costCodesQuery
        ? [
            {
              dataIndex: "title",
              value: costCodesQuery,
            },
          ]
        : []
      )
        .concat(baseFilters)
        .concat(
          costCodesSortBy
            ? [
                {
                  dataIndex: "ordering",
                  value: costCodesSortBy,
                },
              ]
            : []
        ),
    [costCodesQuery, baseFilters, costCodesSortBy]
  );

  const vendorsFilters = useMemo(
    () =>
      (vendorsQuery
        ? [
            {
              dataIndex: "title",
              value: vendorsQuery,
            },
          ]
        : []
      )
        .concat(baseFilters)
        .concat(
          vendorsSortBy
            ? [
                {
                  dataIndex: "ordering",
                  value: vendorsSortBy,
                },
              ]
            : []
        ),
    [vendorsQuery, baseFilters, vendorsSortBy]
  );

  const purchaseOrdersFilters = useMemo(
    () =>
      (purchaseOrdersQuery
        ? [
            {
              dataIndex: "query",
              value: purchaseOrdersQuery,
            },
          ]
        : []
      )
        .concat(baseFilters)
        .concat(
          purchaseOrdersSortBy
            ? [
                {
                  dataIndex: "ordering",
                  value: purchaseOrdersSortBy,
                },
              ]
            : []
        ),
    [purchaseOrdersQuery, baseFilters, purchaseOrdersSortBy]
  );

  const { data: jobs, isLoading: jobsIsLoading } =
    useGetBuilderTrendJobsListQuery(jobsFilters, { skip });
  const [updateBuilderTrendJob] = useUpdateBuilderTrendJobMutation();

  const { data: vendors, isLoading: vendorsIsLoading } =
    useGetBuilderTrendVendorsListQuery(vendorsFilters, { skip });
  const [updateBuilderTrendVendor] = useUpdateBuilderTrendVendorMutation();

  const { data: purchaseOrders, isLoading: purchaseOrdersIsLoading } =
    useGetBuilderTrendPurchaseOrdersListQuery(purchaseOrdersFilters, { skip });
  const [loadBuilderTrendPurchaseOrder] =
    useLoadBuilderTrendPurchaseOrderMutation();

  const { data: costCodes, isLoading: costCodesIsLoading } =
    useGetBuilderTrendCostCodesListQuery(costCodesFilters, { skip });
  const [updateBuilderTrendCostCode] = useUpdateBuilderTrendCostCodeMutation();

  const {
    data: credentials,
    isLoading: credentialIsLoading,
    refetch: refetchCredentials,
  } = useGetBuilderTrendCredentialsListQuery(realmId || 0, { skip });
  const credential = useMemo(
    () => credentials?.results[0],
    [credentials?.results]
  );
  const syncInProgress = useMemo(
    () => ["IN_PROGRESS", "PENDING"].includes(credential?.syncStatus || ""),
    [credential?.syncStatus]
  );

  const [updateBuilderTrendCredentials] =
    useUpdateBuilderTrendCredentialsMutation();

  const [
    syncBuilderTrendCredentials,
    { isLoading: syncBuilderTrendCredentialsIsLoading },
  ] = useSyncBuilderTrendCredentialsMutation();
  const stopRef = useRef(noop);
  const pollCredentials = useCallback(async () => {
    if (!credential?.id) {
      return toast.error("No credentials found for BuilderTrend");
    }

    const { run, stop } = pollBuilderTrendCredentials(credential?.id);
    stopRef.current = stop;
    await run();
    refetchCredentials();
  }, [credential?.id, refetchCredentials]);

  const { data: builders, isLoading: buildersIsLoading } =
    useGetBuilderTrendBuildersSimpleListQuery(realmId!, {
      skip: skip || !realmId,
    });
  const [updateBuilderTrendCredentialsDefaultBuilder] =
    useUpdateBuilderTrendCredentialsDefaultBuilderMutation();

  const toastDismiss = useRef(noop);
  const enhancedSyncBuilderTrendCredentials = useCallback(
    async (id: string) => {
      const { update, dismiss } = toast.loading("Syncing BuilderTrend data");
      toastDismiss.current = dismiss;
      try {
        await syncBuilderTrendCredentials(id);
        await pollCredentials();
        update("Sync completed", { type: "success" });
      } catch {
        update("Error syncing BuilderTrend data", { type: "error" });
      }
    },
    [syncBuilderTrendCredentials, pollCredentials]
  );

  useEffect(() => {
    if (skip) return;

    if (credential?.id) {
      pollCredentials();
    }

    return () => {
      if (toastDismiss.current) {
        toastDismiss.current();
      }
      if (stopRef.current) {
        stopRef.current();
      }
    };
  }, [pollCredentials, credential?.id, skip]);

  const [loadBuilderTrendBudget] = useLoadBuilderTrendBudgetMutation();
  const [createBuilderTrendCredentials] =
    useCreateBuilderTrendCredentialsMutation();

  const { refetchJob } = useJobActions();
  const onPullBuilderTrendBudget = useEvent(async (job: BuilderTrendJob) => {
    if (!job?.id) {
      return toast.error("Adaptive job is not linked to a BuilderTrend job");
    }

    const handler = async () => {
      if (!job?.id) return;

      try {
        await loadBuilderTrendBudget(job.id).unwrap();
        await refetchJob();
        toast.success("Budget loaded from BuilderTrend");
      } catch (e) {
        handleErrors(e);
      }
    };

    dialog.confirmation({
      title: "Load the budget from BuilderTrend?",
      message:
        "This will overwrite the current budget with the budget from BuilderTrend, and mark the budget as read-only in Adaptive.",
      action: {
        primary: {
          color: "primary",
          onClick: handler,
          children: "Load budget",
        },
      },
    });
  });

  return {
    jobs,
    jobsIsLoading,
    updateBuilderTrendJob,
    costCodes,
    costCodesIsLoading,
    updateBuilderTrendCostCode,
    credential,
    credentialIsLoading,
    syncInProgress,
    updateBuilderTrendCredentials,
    createBuilderTrendCredentials,
    syncBuilderTrendCredentials: enhancedSyncBuilderTrendCredentials,
    pagination,
    loadBuilderTrendBudget,
    syncBuilderTrendCredentialsIsLoading,
    vendors,
    vendorsIsLoading,
    purchaseOrders,
    purchaseOrdersIsLoading,
    updateBuilderTrendVendor,
    loadBuilderTrendPurchaseOrder,
    onPullBuilderTrendBudget,
    builders,
    buildersIsLoading,
    updateBuilderTrendCredentialsDefaultBuilder,
  };
};
