import { invoicesApi } from "@api/invoices";
import {
  api,
  type WorkflowApprovalDelete,
  type WorkflowApprovalMutation,
  type WorkflowsRequest,
} from "@api/workflows";
import { type QueryItem } from "@components/table-filter/formatters";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateBillWorkflows } from "@store/billSlice";

import { fetchExpenseById } from "../expenses/thunks";

import {
  removeWorkflow,
  resetCurrentWorkflow,
  resetCurrentWorkflowApprovals,
  setDefaultWorkflow,
  updateDefaultWorkflow,
  updateWorkflow,
  updateWorkflows,
} from "./slice";

const DEFAULT_WORKFLOW_FILTER: QueryItem = {
  dataIndex: "is_default",
  value: true,
};

const NON_DEFAULT_WORKFLOW_FILTER: QueryItem = {
  dataIndex: "is_default",
  value: false,
};

export const loadWorkflows = createAsyncThunk(
  "workflow/load",
  async (filters: QueryItem[], { dispatch, rejectWithValue }) => {
    try {
      const workflows = await api.get([
        ...filters,
        NON_DEFAULT_WORKFLOW_FILTER,
      ]);
      const defaultWorkflow = await api.get([DEFAULT_WORKFLOW_FILTER]);
      dispatch(updateWorkflows(workflows));
      if (defaultWorkflow.length > 0) {
        dispatch(updateDefaultWorkflow(defaultWorkflow[0]));
      }
    } catch {
      return rejectWithValue("Failed retrieving workflows data");
    }
  }
);

export const persistWorkflow = createAsyncThunk(
  "workflow/persist",
  async (actionPayload: WorkflowsRequest, { dispatch, rejectWithValue }) => {
    try {
      const persistToDatabase = !actionPayload.id ? api.create : api.put;
      try {
        const response = await persistToDatabase(actionPayload);
        dispatch(resetCurrentWorkflow());
        if (actionPayload.isDefault) {
          dispatch(setDefaultWorkflow(response));
        } else {
          dispatch(updateWorkflow(response));
        }
      } catch (e) {
        return rejectWithValue(e);
      }
    } catch {
      return rejectWithValue("Failed persisting workflows data");
    }
  }
);

export const deleteWorkflow = createAsyncThunk(
  "workflow/delete",
  async (actionPayload: { id: string }, { dispatch, rejectWithValue }) => {
    try {
      try {
        await api.destroy(actionPayload);
        dispatch(removeWorkflow(actionPayload));
        dispatch(resetCurrentWorkflow());
      } catch (e) {
        return rejectWithValue(e);
      }
    } catch {
      return rejectWithValue("Failed deleting workflow");
    }
  }
);

export const deleteApprovalsWorkflow = createAsyncThunk(
  "workflow/delete",
  async (
    actionPayload: WorkflowApprovalDelete,
    { dispatch, rejectWithValue }
  ) => {
    try {
      try {
        await api.destroyWorkflow(actionPayload);
        if (actionPayload.objectType === "Bill") {
          dispatch(updateBillWorkflows(actionPayload.objectId));
        }
        dispatch(resetCurrentWorkflowApprovals());
      } catch (e) {
        return rejectWithValue(e);
      }
    } catch {
      return rejectWithValue("Failed deleting workflows data");
    }
  }
);

export const persistWorkflowApprovals = createAsyncThunk(
  "workflow/persistApprovals",
  async (
    actionPayload: WorkflowApprovalMutation,
    { dispatch, rejectWithValue }
  ) => {
    try {
      try {
        await ("id" in actionPayload.payload
          ? api.putApprovals(actionPayload.payload)
          : api.createApprovals({
              ...actionPayload.payload,
              transactionId: actionPayload.objectId,
              transactionContentType: actionPayload.objectType.toLowerCase(),
            }));
        if (actionPayload.objectType === "Bill") {
          await dispatch(updateBillWorkflows(actionPayload.objectId));
        } else if (actionPayload.objectType === "Invoice") {
          dispatch(
            invoicesApi.util.invalidateTags([
              { type: "Invoices", id: actionPayload.objectId },
            ])
          );
          await dispatch(
            invoicesApi.util.getRunningQueryThunk("getInvoice", {
              invoiceId: actionPayload.objectId,
            })
          );
        } else if (actionPayload.objectType === "Expense") {
          await dispatch(
            fetchExpenseById(String(actionPayload.objectId)) as any
          );
        }
        dispatch(resetCurrentWorkflowApprovals());
      } catch (e) {
        return rejectWithValue(e);
      }
    } catch {
      return rejectWithValue("Failed persisting workflows approvals data");
    }
  }
);
