import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  Text,
  toast,
} from "@adaptive/design-system";
import type { UseDialogReturn } from "@adaptive/design-system/hooks";
import { useEvent } from "@adaptive/design-system/hooks";
import { handleErrors } from "@api/handle-errors";
import { markAsSignedPurchaseOrder } from "@api/purchase-orders";
import { DropZone } from "@components/draggable";
import { updatePurchaseOrderList } from "@store/purchaseOrderListSlice";

import { STRINGS } from "./constants";

export type MarkAsSignedDialogProps = {
  dialog: UseDialogReturn;
  purchaseOrder: any;
  onSubmit: () => void;
  disabled: boolean;
};

export const MarkAsSignedDialog = ({
  dialog,
  purchaseOrder,
  onSubmit,
  disabled,
}: MarkAsSignedDialogProps) => {
  const [file, setFile] = useState<File>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const dispatch = useDispatch();
  const onDrop = useEvent(async (files) => {
    if (!files.length) return;
    setFile(files[0]);
  });

  const onCancel = useEvent(async () => {
    dialog.hide();
    setFile(undefined);
  });

  const onConfirmMarkAsSigned = useEvent(async () => {
    const formData = new FormData();

    if (file) {
      formData.append("file", file);
    }
    try {
      setIsSubmitting(true);
      await markAsSignedPurchaseOrder(purchaseOrder, formData);

      dialog.hide();
      setFile(undefined);
      onSubmit();
      toast.success(STRINGS.MARK_AS_SIGNED_SUCCESS_TOAST);
      dispatch(updatePurchaseOrderList());
    } catch (error) {
      handleErrors(error);
    }
    setIsSubmitting(false);
  });

  return (
    <>
      <Dialog
        size="auto"
        show={dialog.isVisible}
        variant="dialog"
        onClose={dialog.hide}
        align="center"
      >
        <DialogHeader>
          <Flex gap="xl" direction="column">
            <Text align="center" weight="bold">
              {STRINGS.MARK_AS_SIGNED_DIALOG_HEADER}
            </Text>
            <Text align="center" size="md">
              {STRINGS.MARK_AS_SIGNED_DIALOG_SUBHEADER}
            </Text>
          </Flex>
        </DialogHeader>
        <DialogContent>
          <Flex gap="2xl" minWidth="500px" direction="column">
            <Flex direction="column" gap="xl">
              <DropZone
                showBorder
                onDrop={onDrop}
                hasPermission={disabled}
                justify="space-between"
                gap="2xl"
                flip="horizontal"
                padding="2xl"
                idleMessage="Upload or drag and drop a file"
                pendingMessage="We are processing your file"
                draggingMessage="Drop a file here to upload it!"
              >
                {file ? (
                  <>
                    <Flex align="center" justify="flex-start">
                      <Icon name="image" size="2xl" />
                    </Flex>
                    <Flex grow direction="column" gap="sm">
                      <Text weight="bold">Filename</Text>
                      <Text truncate>{file.name}</Text>
                    </Flex>
                  </>
                ) : null}
              </DropZone>
            </Flex>
          </Flex>
        </DialogContent>
        <DialogFooter>
          <Button
            block
            color="neutral"
            variant="text"
            onClick={onCancel}
            size="lg"
          >
            Cancel
          </Button>
          <Button
            block
            size="lg"
            disabled={!disabled || isSubmitting}
            onClick={onConfirmMarkAsSigned}
          >
            Mark as signed
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};
