import { idSchema } from "@utils/schema";
import { z } from "zod";

export const checkBillDuplicationRequestSchema = z.object({
  vendorId: idSchema,
  docNumber: z.string(),
});

export const getLinkedInvoicesRequestSchema = z.object({
  billIds: z.array(idSchema),
});

export const billBalanceByCustomerRequestSchema = z.object({
  billIds: z.array(idSchema),
});
