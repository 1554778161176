import type { MultiplePaymentFormPayment } from "../../types";

type BillsDistribution = Record<string, { shouldRemove: boolean }>;

type CustomerBillsDistribution = Record<
  string,
  {
    bills: BillsDistribution;
    shouldRemove: boolean;
  }
>;

type VendorCustomerBillsDistribution = Record<
  string,
  {
    customers: CustomerBillsDistribution;
    shouldRemove: boolean;
  }
>;

export const removeBillFromGroupedList = (
  groupedBillListItems: MultiplePaymentFormPayment[],
  billId: string
) => {
  // Checking Vendor, Customers and Bills to remove from nested objects
  const billsDistribution = groupedBillListItems.reduce((acc, item) => {
    const vendorId = item.vendor?.id;
    const customers = (item.data || [])?.reduce((accCustomer, customer) => {
      const customerId = customer.customer?.id || "";
      const bills = (customer.data || [])?.reduce((accBill, bill) => {
        accBill[bill.billId] = {
          shouldRemove: bill.billId === billId,
        };

        return accBill;
      }, {} as BillsDistribution);

      accCustomer[customerId] = {
        bills,
        shouldRemove: !Object.values(bills || {}).some(
          (bill) => !bill.shouldRemove
        ),
      };

      return accCustomer;
    }, {} as CustomerBillsDistribution);

    acc[vendorId] = {
      customers,
      shouldRemove: !Object.values(customers || {}).some(
        (bill) => !bill.shouldRemove
      ),
    };
    return acc;
  }, {} as VendorCustomerBillsDistribution);

  // Removing Vendor, Customers and Bills from nested objects
  return groupedBillListItems.reduce((acc, item) => {
    const vendorId = item.vendor?.id;

    if (!billsDistribution[vendorId].shouldRemove) {
      const vendor = {
        ...item,
        data: item.data
          ?.filter((customer) => {
            const customerId = customer.customer?.id || "";
            return !billsDistribution[vendorId].customers[customerId]
              .shouldRemove;
          })
          .map((customer) => {
            const customerId = customer.customer?.id || "";

            return {
              ...customer,
              data: customer.data?.filter((bill) => {
                return !billsDistribution[vendorId].customers[customerId].bills[
                  bill.billId
                ].shouldRemove;
              }),
            };
          }),
      };

      acc.push(vendor);
    }

    return acc;
  }, [] as MultiplePaymentFormPayment[]);
};
