import React, { memo, type ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useNavigate, useSearchParams } from "react-router";
import { Loader } from "@adaptive/design-system";
import { login } from "@api/auth";
import { getUser } from "@api/users";
import { BillPaymentLandingPage } from "@bill-payment/components";
import { Page as ConversationPage } from "@conversations/components";
import { LienWaiverSignatureRequestPage } from "@lien-waiver/components";
import { useUserInfo } from "@store/user";
import { updateUser } from "@store/user/slice";
import { reAuth } from "@utils/custom-events";

import { VendorACHRequest, VendorDocRequest } from "./vendor-request";

type ExternalRoutesProps = {
  notFound?: ReactNode;
};

export const ExternalRoutes = memo(({ notFound }: ExternalRoutesProps) => {
  const [status, setStatus] = useState<
    "idle" | "succeeded" | "failed" | "forbidden"
  >("idle");

  const [searchParams] = useSearchParams();

  const token = searchParams.get("token") || "";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useUserInfo();

  useEffect(() => {
    if (token && !user?.id) {
      login({ token })
        .then(() => {
          getUser()
            .then((user) => {
              dispatch(updateUser(user));
              setStatus("succeeded");
            })
            .catch(() => setStatus("forbidden"));
        })
        .catch(() => setStatus("failed"));
    } else {
      setStatus("succeeded");
    }
  }, [dispatch, navigate, token, user]);

  if (status === "failed") return notFound;
  if (status === "forbidden") {
    reAuth();
    return notFound;
  }
  if (status === "idle") return <Loader position="fixed" />;

  return (
    <Routes>
      <Route
        element={<VendorACHRequest />}
        path="vendor-ach-request/:token/:realmUid/:vendorUid"
      />
      <Route
        element={<VendorDocRequest />}
        path="vendor-doc-request/:token/:realmUid/:vendorUid"
      />
      <Route
        path="bill-payments-external/:realmUid/:paymentUid"
        element={<BillPaymentLandingPage />}
      />
      <Route
        path="vendor-lien-waiver-request/:realmUid/:lienWaiverUid"
        element={<LienWaiverSignatureRequestPage />}
      />
      <Route path=":type/:id/conversation" element={<ConversationPage />} />
      <Route path="*" element={notFound} />
    </Routes>
  );
});

ExternalRoutes.displayName = "ExternalRoutes";
