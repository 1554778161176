import { LIEN_WAIVER_STATUS } from "@lien-waiver/constants";

import { type LienWaiver } from "../api/types";

export const parseLienWaiverTemplate = (lienWaiver: LienWaiver) => {
  return (
    lienWaiver?.lienWaiverTemplate ??
    (lienWaiver?.status === LIEN_WAIVER_STATUS.NOT_REQUIRED.toLowerCase()
      ? lienWaiver?.status
      : undefined)
  );
};
