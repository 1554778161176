import React, { type PropsWithChildren, useMemo } from "react";
import { Button, Flex } from "@adaptive/design-system";
import {
  TableFilterControls,
  type TableFilterControlsProps,
} from "@components/table-filter";
import { useAccountsSimplified } from "@hooks/use-accounts-simplified";
import type { UseTableFilterOptionsProps } from "@hooks/use-table-filter-options";
import { LIEN_WAIVER_STATUS } from "@lien-waiver/constants";
import { HUMAN_READABLE_LIEN_WAIVER_STATUS } from "@lien-waiver/constants";
import { useCurrentTab } from "@src/bill-payment/components/bill-payments-page/context";
import { ACH_STATUS_OPTIONS } from "@vendors/constants";

import {
  BILL_PAYMENTS_TABS,
  HUMAN_READABLE_PAYMENT_METHOD,
  PAYMENT_METHOD,
  PAYMENT_STATUS,
} from "../../constants";

const DATE_PROPS = { grow: true };

const INCLUDE_FILTERS: UseTableFilterOptionsProps["includeFilters"] = [
  "vendors",
  "customers",
];

const PAYMENT_METHOD_FILTER_OPTIONS = Object.entries(PAYMENT_METHOD).map(
  ([, value]) => ({
    label:
      HUMAN_READABLE_PAYMENT_METHOD[
        value as keyof typeof HUMAN_READABLE_PAYMENT_METHOD
      ],
    value: value,
    groupLabel: "Payment method",
  })
);

const ACH_STATUS_FILTER_OPTIONS = ACH_STATUS_OPTIONS.map((option) => ({
  ...option,
  groupLabel: "ACH status",
}));

const LIEN_WAIVER_STATUS_FILTER_OPTIONS = Object.entries(LIEN_WAIVER_STATUS)
  .filter(([, value]) => {
    const allowedFilters: (typeof LIEN_WAIVER_STATUS)[keyof typeof LIEN_WAIVER_STATUS][] =
      [
        LIEN_WAIVER_STATUS.NOT_REQUESTED,
        LIEN_WAIVER_STATUS.NOT_REQUIRED,
        LIEN_WAIVER_STATUS.REQUESTED,
        LIEN_WAIVER_STATUS.SIGNED,
      ];

    return allowedFilters.includes(value);
  })
  .map(([, value]) => ({
    label:
      HUMAN_READABLE_LIEN_WAIVER_STATUS[
        value as keyof typeof HUMAN_READABLE_LIEN_WAIVER_STATUS
      ],
    value: value,
    groupLabel: "Lien waiver status",
  }));

const BILL_PAYMENT_STATUS_FILTER_OPTIONS = [
  {
    label: "Scheduled",
    value: PAYMENT_STATUS.SCHEDULED,
    groupLabel: "Payment status",
  },
  {
    label: "In progress",
    value: PAYMENT_STATUS.PENDING,
    groupLabel: "Payment status",
  },
  {
    label: "Paid",
    value: PAYMENT_STATUS.PAID,
    groupLabel: "Payment status",
  },
  {
    label: "Failed",
    value: PAYMENT_STATUS.FAILED,
    groupLabel: "Payment status",
  },
  {
    label: "Cancelled",
    value: PAYMENT_STATUS.CANCELLED,
    groupLabel: "Payment status",
  },
  {
    label: "Voided",
    value: "VOIDED",
    groupLabel: "Payment status",
  },
];

type FilterProps = PropsWithChildren<{
  filters?: TableFilterControlsProps["filters"];
  onClear?: () => void;
  onChange: TableFilterControlsProps["onFiltersChange"];
  fixedTags?: TableFilterControlsProps["fixedTags"];
}>;

export const FilterControls = ({
  filters,
  onClear,
  onChange,
  children,
  fixedTags,
}: FilterProps) => {
  const currentTab = useCurrentTab();
  const paymentAccounts = useAccountsSimplified({
    filters: {
      only_payment_accounts: true,
      can_accounts_link_to_lines_desktop: true,
    },
    includeLabel: "Payment account",
  });

  const hasFilters = Object.values(filters ?? {}).some((filter) => !!filter);

  const extraData = useMemo(() => {
    const extraFilters = [
      ...PAYMENT_METHOD_FILTER_OPTIONS,
      ...paymentAccounts.data,
      ...ACH_STATUS_FILTER_OPTIONS,
      ...LIEN_WAIVER_STATUS_FILTER_OPTIONS,
    ];

    if (currentTab === BILL_PAYMENTS_TABS.ALL) {
      extraFilters.push(...BILL_PAYMENT_STATUS_FILTER_OPTIONS);
    }

    return extraFilters;
  }, [paymentAccounts.data, currentTab]);

  const extraDataLoading = paymentAccounts.isLoading;

  const showClearFilters = hasFilters && onClear;

  return (
    <TableFilterControls
      filters={filters}
      dateProps={DATE_PROPS}
      extraData={extraData}
      fixedTags={fixedTags}
      withFilterTags
      includeFilters={INCLUDE_FILTERS}
      onFiltersChange={onChange}
      extraDataLoading={extraDataLoading}
    >
      {showClearFilters ? (
        <Flex width="full" gap="xl" align="center" justify="space-between">
          <Button onClick={onClear}>Clear filters</Button>
          {children}
        </Flex>
      ) : (
        <Flex width="full" align="center" justify="flex-end">
          {children}
        </Flex>
      )}
    </TableFilterControls>
  );
};
