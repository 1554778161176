import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router";
import {
  Alert,
  AlertContent,
  Button,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Loader,
  Radio,
  Table,
  type TableColumn,
  Text,
} from "@adaptive/design-system";
import { createFormContext } from "@adaptive/design-system/hooks";
import { dotObject } from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import * as analytics from "@utils/analytics";
import { z } from "zod";

import type {
  CardTransaction,
  Cost,
  MatchCardTransactionErrorResponse,
} from "../api/types";
import { MATCH_STEP_TABLE_ID, STRINGS } from "../constants/constants";
import { useMatchCardTransaction } from "../hooks/use-match-card-transaction";

import type { MatchingTransactionOnChangeHandler } from "./types";

type Column = { field: string; cost: string; cardTransaction: string };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schema = z.object({
  choices: z.array(z.enum(["cost", "cardTransaction"])),
});

type Fields = z.infer<typeof schema>;

const FormContext = createFormContext<Fields>();

const COLUMNS: TableColumn<Column>[] = [
  {
    id: "field",
    name: "Field",
    render: "field",
  },
  {
    id: "cost",
    name: "Cost",
    align: "center",
    render: (row, index) => (
      <Flex align="center">
        <FormContext.FormConsumer>
          {({ values, setValue }) => (
            <Radio
              label={row.cost}
              checked={values.choices[index] === "cost"}
              placement="right"
              onChange={() =>
                setValue(`choices.${index}`, "cost", {
                  shouldValidate: true,
                })
              }
            />
          )}
        </FormContext.FormConsumer>
      </Flex>
    ),
  },
  {
    id: "cardTransaction",
    name: "Card transaction",
    render: (row, index) => (
      <Flex align="center">
        <FormContext.FormConsumer>
          {({ values, setValue }) => (
            <Radio
              name={`choices.${index}`}
              label={row.cardTransaction}
              checked={values.choices[index] === "cardTransaction"}
              placement="right"
              onChange={() =>
                setValue(`choices.${index}`, "cardTransaction", {
                  shouldValidate: true,
                })
              }
            />
          )}
        </FormContext.FormConsumer>
      </Flex>
    ),
  },
];

export type MatchingTransactionDialogMatchStepProps = {
  cost?: Cost;
  onCancel: () => void;
  onSuccess: MatchingTransactionOnChangeHandler;
  conflicts: MatchCardTransactionErrorResponse["conflicts"];
  cardTransaction?: CardTransaction;
};

export const MatchingTransactionDialogMatchStep = ({
  cost,
  onCancel,
  onSuccess,
  conflicts,
  cardTransaction,
}: MatchingTransactionDialogMatchStepProps) => {
  const [matchCardTransactionTrigger, { isLoading }] =
    useMatchCardTransaction();
  const location = useLocation();

  const initialValues = useMemo<Fields>(
    () => ({
      choices: conflicts.map(({ key }) =>
        ["createdBy", "vendor"].includes(key) ? "cost" : "cardTransaction"
      ),
    }),
    [conflicts]
  );

  const form = FormContext.useForm({
    onSubmit: async ({ choices }) => {
      if (!cardTransaction || !cost) return;

      const eventName = location.pathname.includes("expenses")
        ? "costMatchCardTransactionResolveConflicts"
        : "cardFeedMatchCostResolveConflicts";

      analytics.track(eventName, {
        costUrl: cost.url,
        cardTransactionId: cardTransaction.id,
        costType: cost.humanReadableType,
        costStatus: cost.reviewStatus,
      });

      const payload = choices.reduce(
        (acc, value, index) => {
          const { key, parse } = conflicts[index];

          const parsedValue = parse(value === "cost" ? cost : cardTransaction);

          return { ...acc, [key]: parsedValue?.url ?? parsedValue };
        },
        {} as Record<string, string>
      );

      try {
        const matchedCost = await matchCardTransactionTrigger({
          cost: cost.url,
          cardTransactionId: cardTransaction.id,
          ...payload,
        }).unwrap();

        const id = dotObject.get(matchedCost, "id", cost.id);
        const url = dotObject.get(matchedCost, "url", cost.url);
        const humanReadableType = dotObject.get(
          matchedCost,
          "humanReadableType",
          cost.humanReadableType
        );

        onSuccess({
          cost: { ...cost, id, url, humanReadableType },
          cardTransaction,
        });
      } catch (e) {
        handleErrors(e);
      }
    },
    initialValues,
  });

  const reset = form.reset;

  const data = useMemo(
    () =>
      conflicts.map(({ name, format }) => ({
        cost: format(cost),
        field: name,
        cardTransaction: format(cardTransaction),
      })),
    [cost, conflicts, cardTransaction]
  );

  useEffect(() => {
    reset(initialValues);
  }, [reset, initialValues]);

  return (
    <>
      <DialogHeader>
        <Flex direction="column">
          <Text size="xl" weight="bold">
            {STRINGS.MATCHING_TRANSACTION_DIALOG_MATCH_STEP_TITLE}
          </Text>
          <Text size="md">
            {STRINGS.MATCHING_TRANSACTION_DIALOG_MATCH_STEP_SUBTITLE}
          </Text>
        </Flex>
      </DialogHeader>
      <DialogContent>
        <FormContext.FormProvider form={form}>
          <Flex
            as="form"
            gap="2xl"
            direction="column"
            minWidth="890px"
            height="450px"
            {...form.props}
          >
            {cost?.humanReadableType === "Bill" && (
              <Alert variant="info">
                <AlertContent>
                  This bill will be automatically converted to a receipt.{" "}
                </AlertContent>
              </Alert>
            )}
            <Table
              id={MATCH_STEP_TABLE_ID}
              size="sm"
              data={data}
              columns={COLUMNS}
              maxHeight="390px"
            />
          </Flex>
        </FormContext.FormProvider>
      </DialogContent>
      <DialogFooter>
        <Button block color="neutral" variant="text" onClick={onCancel}>
          {STRINGS.MATCHING_TRANSACTION_DIALOG_MATCH_STEP_BACK_ACTION}
        </Button>
        <Button block type="submit" form={form.id} disabled={isLoading}>
          {isLoading ? (
            <Loader />
          ) : (
            STRINGS.MATCHING_TRANSACTION_DIALOG_MATCH_STEP_SAVE_ACTION
          )}
        </Button>
      </DialogFooter>
    </>
  );
};
