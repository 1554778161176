import type { Option } from "@shared/types/objects";

import type {
  ACHStatus,
  DocumentStatus,
  DocumentType,
  PaymentTerm,
} from "../api/types";

export const DOCUMENT_TYPE_OPTIONS: Option<DocumentType>[] = [
  { label: "Combined COI", value: "INSURANCE" },
  { label: "Workers compensation COI", value: "WORKERS_COMP" },
  { label: "General liability COI", value: "GENERAL_LIABILITY" },
  { label: "W-9", value: "W9" },
  { label: "Contract", value: "CONTRACT" },
  { label: "Other", value: "OTHER" },
];

export const DOCUMENT_STATUS_OPTIONS: Option<DocumentStatus>[] = [
  { label: "Active", value: "ACTIVE" },
  { label: "Expired", value: "EXPIRED" },
  { label: "Expiring soon", value: "EXPIRING" },
  { label: "Missing", value: "MISSING" },
  { label: "Request sent", value: "REQUEST_SENT" },
];

export const ACH_STATUS_OPTIONS: Option<ACHStatus>[] = [
  { label: "Active", value: "ACTIVE" },
  { label: "Missing", value: "MISSING" },
  { label: "Request sent", value: "REQUEST_SENT" },
];

export const SYNC_STATUS_OPTIONS: Option[] = [
  { label: "Synced", value: "SYNCED" },
  { label: "Not synced", value: "NOT_SYNCED" },
  { label: "Sync errors", value: "HAS_SYNC_ERRORS" },
  { label: "Ignored errors", value: "IGNORED_ERRORS" },
];

export const STRINGS = {
  REMOVE_BANKING_CONFIRMATION_DIALOG_TITLE:
    "Are you sure you want to remove ACH information?",
  REMOVE_BANKING_CONFIRMATION_DIALOG_MESSAGE:
    "This will cancel any payment with pending ACH requests from this vendor.",
  REMOVE_BANKING_CONFIRMATION_DIALOG_BUTTON: "Remove ACH",
  EMAIL_CHANGE_CONFIRMATION_DIALOG_TITLE:
    "Are you sure you want to save this email address?",
  EMAIL_CHANGE_CONFIRMATION_DIALOG_MESSAGE:
    "Any outstanding requests that have been sent to other email addresses will be automatically expired. This could include requests for vendor documents, ACH details, or PO signatures.",
  EMAIL_CHANGE_CONFIRMATION_DIALOG_BUTTON: "Add email & expire requests",
  REQUESTS_WERE_EXPIRED_TOAST:
    "All requests previously sent to this vendor were expired",
  TITLE: "Vendors",
  NEW: "New vendor",
  HEADER_TAX_INFO: "Tax Information",
  HEADER_ACH_INFO: "ACH Information",
  HEADER_ADD_DOCS: "Add documents",
  HEADER_PREFERENCES: "Preferences",
  LABEL_TAX_ID: "Tax ID",
  LABEL_TRACK_1099: "Track payments for 1099",
  LABEL_ROUTING_NUMBER: "Routing number",
  LABEL_ACCOUNT_NUMBER: "Account number",
  LABEL_PREFERENCE_TERM: "Payment Terms",
  LABEL_PREFERENCE_ATTRIBUTION: "Default cost code / account",
  BUTTON_REMOVE_ACH: "Clear",
  HINT_PREFERENCE_TERM: "Will automatically prefill due dates on bills",
  ACH_INFO_HELPER_MESSAGE: "Cancel outstanding request to manually add",
};

export const ROUTE_LABEL = {
  INFO: "Basic information",
  ACCOUNTING: "Payment details",
  DOCUMENTS: "Documents",
};

export const PAYMENT_TERMS: Option<PaymentTerm>[] = [
  { label: "Due on Receipt", value: "ON_RECEIPT" },
  { label: "Net 30", value: "NET_30" },
  { label: "Net 60", value: "NET_60" },
  { label: "Net 90", value: "NET_90" },
];

export const DEFAULT_TRANSACTION_TYPES: Option[] = [
  { label: "Bills", value: "bill" },
  { label: "Receipts", value: "expense" },
];

export const VENDOR_CODES_ARTICLE_URL =
  "https://help.adaptive.build/en/articles/10297301-budget-and-vendor-codes-beta";

export const VENDOR_REQUEST_TYPES = {
  ACH: "ACH",
  DOC: "DOC",
  PO_SIGNATURE: "PO_SIGNATURE",
} as const;

export const SubmitErrorCodes = {
  NONE: 0,
  MISSING_REALM: 1,
  INVALID_PAYLOAD: 2,
  UNKNOWN: 3,
} as const;
