import React, { createContext, useMemo, useState } from "react";
import {
  Flex,
  Icon,
  Table,
  type TableColumn,
  type TablePaginationAddon,
  TableProvider,
  Text,
  TextField,
  toast,
} from "@adaptive/design-system";
import { useDebouncedValue, useEvent } from "@adaptive/design-system/hooks";
import { handleErrors } from "@api/handle-errors";
import { CostCodeAccountComboBox } from "@components/cost-code-account-combobox";
import {
  Sticky,
  StickyMeasurer,
  StickyProvider,
  type StickyProviderOnResizeHandler,
} from "@components/sticky";
import { curriedNoop } from "@utils/noop";

import { type BuilderTrendCostCode } from "../api/types";
import { useBuildertrend } from "../hooks/use-buildertrend";

type CostCodesContextType = {
  curriedOnChangeItem: (row: BuilderTrendCostCode) => (value: string) => void;
};

const CostCodesContext = createContext<CostCodesContextType>({
  curriedOnChangeItem: curriedNoop,
});

const ACCOUNT_FILTERS = {
  enabled: false,
};
const COST_CODE_FILTERS = {
  showDisableAsEnabled: true,
};
const FILTERS: ("budgetCode" | "vendorCode" | "costCodeAccount")[] = [
  "costCodeAccount",
];

const COLUMNS: TableColumn<BuilderTrendCostCode>[] = [
  {
    id: "title",
    name: "BuilderTrend cost code",
    sortable: true,
    render: (row) => <Text truncate>{row.title}</Text>,
  },
  {
    id: "item__display_name",
    sortable: true,
    name: "Adaptive cost code",
    render: (row) => (
      <CostCodesContext.Consumer>
        {({ curriedOnChangeItem }) => (
          <CostCodeAccountComboBox
            size="sm"
            label=""
            onChange={curriedOnChangeItem(row)}
            value={row.item}
            accountFilters={ACCOUNT_FILTERS}
            messageVariant="hidden"
            filters={FILTERS}
            costCodeFilters={COST_CODE_FILTERS}
          />
        )}
      </CostCodesContext.Consumer>
    ),
  },
];

export const CostCodesTable = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("title");

  const [debouncedSearchQuery] = useDebouncedValue(
    searchQuery,
    import.meta.env.MODE === "test" ? 0 : 300
  );

  const sort = useMemo(
    () => ({ value: sortBy, onChange: setSortBy }),
    [sortBy]
  );

  const {
    costCodes,
    costCodesIsLoading: isLoading,
    updateBuilderTrendCostCode,
    pagination,
  } = useBuildertrend({
    costCodesQuery: debouncedSearchQuery,
    costCodesSortBy: sortBy,
  });

  const tablePagination = useMemo<TablePaginationAddon>(
    () => ({
      page: pagination.page,
      total: costCodes?.count ?? 0,
      perPage: pagination.perPage,
      onChange: pagination.setPage,
      onPerPageChange: (perPage) => {
        pagination.setPerPage(perPage);
        pagination.setPage(0);
      },
    }),
    [pagination, costCodes?.count]
  );

  const [offset, setOffset] = useState(0);
  const onResize = useEvent<StickyProviderOnResizeHandler>(({ sticky }) => {
    setOffset(sticky.height);
  });

  const curriedOnChangeItem =
    (row: BuilderTrendCostCode) => async (value: string) => {
      try {
        await updateBuilderTrendCostCode({ id: row.id, item: value }).unwrap();
        toast.success("BuilderTrend cost code updated");
      } catch (error) {
        handleErrors(error);
      }
    };

  return (
    <TableProvider id="buildertrend-jobs-table">
      <StickyProvider onResize={onResize}>
        <Sticky
          style={{
            paddingTop: "var(--spacing-2xl)",
            paddingBottom: "var(--spacing-lg)",
          }}
        >
          <Flex width="full" gap="xl" justify="space-between">
            <Flex width="full" maxWidth="350px">
              <TextField
                addonAfter={<Icon name="search" />}
                placeholder="Filter cost codes"
                value={searchQuery}
                onChange={setSearchQuery}
                messageVariant="hidden"
              />
            </Flex>
          </Flex>
        </Sticky>
        <StickyMeasurer>
          <CostCodesContext.Provider
            value={{
              curriedOnChangeItem,
            }}
          >
            <Table
              sort={sort}
              data={costCodes?.results}
              size="sm"
              header={{ hide: costCodes?.count === 0, sticky: { offset } }}
              loading={isLoading}
              columns={COLUMNS}
              pagination={tablePagination}
            />
          </CostCodesContext.Provider>
        </StickyMeasurer>
      </StickyProvider>
    </TableProvider>
  );
};
