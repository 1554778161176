import React, {
  memo,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router";
import {
  Dialog,
  type DialogOnCloseHandler,
  type DialogProps,
} from "@adaptive/design-system";
import {
  type ResponsiveProp,
  useEvent,
  useResponsiveProp,
} from "@adaptive/design-system/hooks";
import { useDrawerVisibility } from "@store/ui/hooks";

import { type DrawerName, useDrawerStack } from "./context";

type DrawerSize = Extract<DialogProps, { variant: "drawer" }>["size"];

type BaseProps<Params extends readonly string[]> = {
  name: DrawerName;
  size?: ResponsiveProp<DrawerSize>;
  /**
   * Parameters that should be present in the URL to open the drawer.
   */
  params?: Params;
  autoFocusOnHide?: boolean;
};

type Props<Params extends readonly string[]> =
  | (BaseProps<Params> & {
      render: (
        props: Record<Params[number], string> & {
          onClose: () => void;
        }
      ) => ReactNode;
    })
  | (BaseProps<Params> & { children: ReactNode });

const useDrawer = (name: DrawerName) => {
  const drawerStack = useDrawerStack();

  if (!drawerStack) throw new Error("Drawer must be child node of Dresser");

  const { push, pop } = drawerStack;

  const { step, visible, setVisible } = useDrawerVisibility(name);

  useEffect(() => {
    if (visible) {
      push(name);
    } else {
      pop();
    }
  }, [visible, push, pop, name]);

  /**
   * @todo We might need to handle the popstate changes confirmation
   * dialog. Since it is not implemented yet, we are ignoring it.
   */
  const onClose = useCallback<DialogOnCloseHandler>(
    (trigger) => {
      const ignoreShouldShowHideConfirmation = trigger === "popstate";
      setVisible(false, ignoreShouldShowHideConfirmation);
    },
    [setVisible]
  );

  return { step, show: visible, onClose };
};

const Drawer = <Params extends readonly string[]>({
  params,
  name,
  size: rawSize,
  autoFocusOnHide,
  ...props
}: Props<Params>) => {
  const { setVisible } = useDrawerVisibility(name);

  const [searchParams, setSearchParams] = useSearchParams();

  const enhancedParams = useMemo(() => {
    const obj: Record<string, string> = {};

    for (const [key, value] of searchParams.entries()) {
      obj[key] = value;
    }

    return obj;
  }, [searchParams]);

  const shouldOpen = params?.every((param) => enhancedParams[param]);

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>(undefined);

  const { step, show, onClose } = useDrawer(name);

  const [isRendered, setIsRendered] = useState(show);

  const size = useResponsiveProp(rawSize, "md");

  const enhancedOnClose = useEvent<typeof onClose>((trigger) => {
    onClose?.(trigger);
  });

  const clearParams = useEvent(() => {
    setSearchParams((previousParams) => {
      if (!params) return previousParams;

      for (const param of params) {
        previousParams.delete(param);
      }

      return previousParams;
    });
  });

  const contentOnClose = useCallback(() => {
    enhancedOnClose("close");
  }, [enhancedOnClose]);

  const enhancedProps: DialogProps = {
    show,
    size,
    onClose: enhancedOnClose,
    autoFocusOnHide,
    ...(step ? { step, variant: "multi-step-drawer" } : { variant: "drawer" }),
  };

  const content =
    "children" in props
      ? props.children
      : props.render({
          ...(enhancedParams as Record<Params[number], string>),
          onClose: contentOnClose,
        });

  useEffect(() => {
    if (shouldOpen) setVisible(true);
    if (!shouldOpen) {
      setVisible(false);
      enhancedOnClose("close");
    }
  }, [shouldOpen, setVisible, enhancedOnClose]);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => setIsRendered(show), show ? 0 : 300);

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [show]);

  useEffect(() => {
    if (!isRendered) clearParams();
  }, [isRendered, clearParams]);

  return isRendered && <Dialog {...enhancedProps}>{content}</Dialog>;
};

const MemoizedDrawer = memo(Drawer) as typeof Drawer;

export { MemoizedDrawer as Drawer };
