import { parseCurrency } from "@adaptive/design-system/utils";
import { invertSign } from "@utils/invert-sign";
import { sum } from "@utils/sum";
import { sumBy } from "@utils/sumBy";

import type { DynamicBalanceVendorCredit } from "../../types";

const clamp = ({
  value,
  min,
  max,
}: { value: number; min: number; max: number }) =>
  Math.min(Math.max(value, min), max);

export const applyVendorCreditsForBalanceAmount = ({
  vendorCredits,
  balanceAmount,
  customerIds,
}: {
  vendorCredits: DynamicBalanceVendorCredit[];
  balanceAmount: string | number;
  customerIds?: Set<string>;
}) => {
  const amountAlreadyApplied = sumBy(vendorCredits, "appliedAmount");

  const initialOpenBalanceAmount = sum(
    parseCurrency(balanceAmount.toString()) || 0,
    amountAlreadyApplied
  );

  // If we're removing amounts from vendor credits, we should do it in reverse order
  const shouldApplyInReverse = initialOpenBalanceAmount < 0;

  const vendorCreditsToIterate =
    initialOpenBalanceAmount < 0
      ? vendorCredits.slice().reverse()
      : vendorCredits;

  const { appliedVendorCredits, openBalanceAmount: finalOpenBalanceAmount } =
    vendorCreditsToIterate.reduce<{
      appliedVendorCredits: DynamicBalanceVendorCredit[];
      openBalanceAmount: number;
    }>(
      (acc, vendorCredit) => {
        if (vendorCredit.appliedAmount) {
          const diffAmount =
            acc.openBalanceAmount < 0
              ? clamp({
                  value: acc.openBalanceAmount,
                  min: vendorCredit.appliedAmount,
                  max: 0,
                })
              : 0;

          return {
            appliedVendorCredits: [
              ...acc.appliedVendorCredits,
              {
                ...vendorCredit,
                appliedAmount: vendorCredit.appliedAmount - diffAmount,
              },
            ],
            openBalanceAmount: acc.openBalanceAmount - diffAmount,
          };
        }
        if (
          customerIds &&
          !vendorCredit?.customers?.some((customer) =>
            customerIds?.has(customer?.id)
          )
        ) {
          return acc;
        }
        const appliedAmount = clamp({
          value: acc.openBalanceAmount,
          min: 0,
          max:
            parseCurrency(invertSign(vendorCredit.currentOpenBalance || "0")) ||
            0,
        });

        return {
          appliedVendorCredits: [
            ...acc.appliedVendorCredits,
            {
              ...vendorCredit,
              appliedAmount: vendorCredit.appliedAmount
                ? vendorCredit.appliedAmount - appliedAmount
                : -appliedAmount || 0,
            },
          ],
          openBalanceAmount: sum(acc.openBalanceAmount, -appliedAmount),
        };
      },
      {
        appliedVendorCredits: [],
        openBalanceAmount: initialOpenBalanceAmount,
      }
    );

  return {
    appliedVendorCredits: shouldApplyInReverse
      ? appliedVendorCredits.reverse()
      : appliedVendorCredits,
    openBalanceAmount: finalOpenBalanceAmount,
  };
};
