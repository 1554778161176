import React from "react";
import {
  Flex,
  TableProvider,
  Tabs,
  TabsList,
  TabsPanel,
  TabsTab,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { CounterLabel } from "@components/counter-label";
import {
  Sticky,
  StickyMeasurer,
  StickyProvider,
  type StickyProviderOnResizeHandler,
} from "@components/sticky";

import { BILL_PAYMENTS_TABLE_ID, BILL_PAYMENTS_TABS } from "../../constants";
import { type BillPaymentsPageTab } from "../../types";

import {
  useActions,
  useCurrentTab,
  useInProgressPayments,
  useScheduledPayments,
} from "./context";
import { BillPaymentsTable } from "./table";
import { TableActions } from "./table-actions";

export const BillPaymentsPageContent = () => {
  const currentTab = useCurrentTab();

  const scheduledPayments = useScheduledPayments();
  const inProgressPayments = useInProgressPayments();

  const { setPage, setTableOffset, setCurrentTab } = useActions();

  const onTabChange = useEvent((newTab: string) => {
    setPage(0);
    setCurrentTab(newTab as BillPaymentsPageTab);
  });

  const onStickyResize = useEvent<StickyProviderOnResizeHandler>(({ sticky }) =>
    setTableOffset(sticky.height)
  );

  return (
    <Flex
      gap="xl"
      shrink={false}
      style={{
        /* We need to this calc to compensate margin from MainContent */
        minHeight: "calc(var(--spacing-full) - var(--spacing-4xl))",
      }}
      direction="column"
    >
      {/*<Alert />*/}
      <Flex minHeight="full" direction="column">
        <Tabs value={currentTab} onChange={onTabChange}>
          <TabsList>
            <TabsTab value={BILL_PAYMENTS_TABS.SCHEDULED}>
              <CounterLabel
                label="Scheduled"
                active={currentTab === BILL_PAYMENTS_TABS.SCHEDULED}
                loading={scheduledPayments.isLoading}
                counter={scheduledPayments.count}
              />
            </TabsTab>
            <TabsTab value={BILL_PAYMENTS_TABS.IN_PROGRESS}>
              <CounterLabel
                label="In progress"
                active={currentTab === BILL_PAYMENTS_TABS.IN_PROGRESS}
                loading={inProgressPayments.isLoading}
                counter={inProgressPayments.count}
              />
            </TabsTab>
            <TabsTab value={BILL_PAYMENTS_TABS.ALL}>All</TabsTab>
          </TabsList>
          <TabsPanel>
            <TableProvider id={BILL_PAYMENTS_TABLE_ID}>
              <StickyProvider onResize={onStickyResize}>
                <Sticky
                  style={{
                    paddingTop: "var(--spacing-2xl)",
                    paddingBottom: "var(--spacing-lg)",
                  }}
                >
                  <TableActions />
                </Sticky>
                <StickyMeasurer>
                  <BillPaymentsTable />
                </StickyMeasurer>
              </StickyProvider>
            </TableProvider>
          </TabsPanel>
        </Tabs>
      </Flex>
    </Flex>
  );
};
