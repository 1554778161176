import { useCallback, useEffect, useMemo, useState } from "react";
import { type TypedUseSelectorHook, useSelector } from "react-redux";
import { getUser } from "@api/users";
import { useCustomersSimplified } from "@hooks/use-customers-simplified";
import { useAppDispatch } from "@store/hooks";
import {
  type KeysToCamelCase,
  transformKeysToCamelCase,
} from "@utils/schema/converters";

import type { RootState } from "../types";

import { BasePermissionCategory, BasePermissions } from "./enums";
import {
  selectClients,
  selectCurrentClient,
  selectOtherClients,
  selectRealm,
  selectRealmId,
} from "./selectors-memoized";
import {
  type Client,
  type ClientSettings,
  currentClientSelector,
  notificationCategoriesSelector,
  peopleSelector,
  switchClient,
  updateUser,
  userSelector,
} from "./slice";
import {
  deleteUser as deleteUserAction,
  invitePerson as invitePersonAction,
  loadClients,
  loadPeople as loadPeopleAction,
  resendInvitation as resendInvitationAction,
  revokeInvitation as revokeInvitationAction,
  send2FA as send2FAAction,
  updateInvite as updateInviteAction,
  updatePerson as updatePersonAction,
  verify2FA as verify2FAAction,
} from "./thunks";
import type {
  DeletePersonPayload,
  InvitePersonPayload,
  UpdatePersonPayload,
  Verify2FAPayload,
} from "./types";

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useClientInfo = () => {
  const client = useSelector(selectCurrentClient);
  const clients = useSelector(selectClients);
  const otherClients = useSelector(selectOtherClients);
  const realm = useSelector(selectRealm);
  const realmId = useSelector(selectRealmId);

  return {
    client,
    clients,
    otherClients,
    realmId,
    realm,
  } as const;
};

export const useClientSettings = () => {
  const { client } = useClientInfo();
  const settings = useMemo(() => client?.settings ?? {}, [client]);
  return transformKeysToCamelCase(settings) as KeysToCamelCase<ClientSettings>;
};

export const useClientAction = () => {
  const dispatch = useAppDispatch();

  const changeClient = useCallback(
    async (id: Client["id"]) => {
      await dispatch(switchClient(id)).unwrap();
    },
    [dispatch]
  );

  const reloadClients = useCallback(() => {
    return dispatch(loadClients()).unwrap();
  }, [dispatch]);

  const currentClient = useAppSelector(currentClientSelector);

  return {
    reloadClients,
    changeClient,
    currentClient,
  } as const;
};

export const useClientHasChanged = ({
  onClientChanged,
}: {
  onClientChanged?: (client?: Client | null) => void;
} = {}) => {
  const { client } = useClientInfo();
  const [clientId, setClientId] = useState(client?.id);

  useEffect(() => {
    if (clientId !== client?.id) {
      setClientId(client?.id);
      onClientChanged?.(client);
    }
  }, [client, clientId, onClientChanged]);
};

export const usePeopleInfo = () => {
  const people = useAppSelector(peopleSelector);
  return { people } as const;
};

export const usePeopleAction = () => {
  const dispatch = useAppDispatch();
  const loadPeople = useCallback(
    (clientId: string) => {
      dispatch(loadPeopleAction(clientId));
    },
    [dispatch]
  );
  const deleteUser = useCallback(
    (payload: DeletePersonPayload) => {
      return dispatch(deleteUserAction(payload));
    },
    [dispatch]
  );
  const revokeInvitation = useCallback(
    (payload: DeletePersonPayload) => {
      return dispatch(revokeInvitationAction(payload));
    },
    [dispatch]
  );

  const resendInvitation = useCallback(
    (payload: DeletePersonPayload) => {
      return dispatch(resendInvitationAction(payload));
    },
    [dispatch]
  );

  const updatePerson = useCallback(
    (payload: UpdatePersonPayload) => {
      if (payload.isUser) {
        dispatch(updatePersonAction(payload));
      } else {
        dispatch(updateInviteAction(payload));
      }
    },
    [dispatch]
  );

  const invitePerson = useCallback(
    (payload: InvitePersonPayload) => {
      return dispatch(invitePersonAction(payload)).unwrap();
    },
    [dispatch]
  );

  return {
    loadPeople,
    updatePerson,
    deleteUser,
    revokeInvitation,
    resendInvitation,
    invitePerson,
  } as const;
};

export const useUserAction = () => {
  const dispatch = useAppDispatch();

  const send2FA = useCallback(() => {
    return dispatch(send2FAAction()).unwrap();
  }, [dispatch]);

  const verify2FA = useCallback(
    (payload: Verify2FAPayload) => {
      return dispatch(verify2FAAction(payload)).unwrap();
    },
    [dispatch]
  );

  const update = useCallback(() => {
    return getUser().then((user) => {
      if (!user) {
        Promise.reject("Failed retrieving User Data");
      }
      dispatch(updateUser(user));
      return user;
    });
  }, [dispatch]);

  return { update, send2FA, verify2FA } as const;
};

export const useUserInfo = () => {
  const user = useAppSelector(userSelector);
  const notificationCategories = useAppSelector(notificationCategoriesSelector);

  const { fetch: fetchCustomersSimplified } = useCustomersSimplified({
    enabled: false,
  });

  const client = useSelector(selectCurrentClient);

  const userPermissions = useMemo(
    () => client?.role?.permissions || [],
    [client?.role?.permissions]
  );

  // Use this fn to check if the user has all the permissions specified in the parameter permissions
  const hasAllPermissions = useCallback(
    (permissions: BasePermissions[]) => {
      return permissions.every((permission) =>
        userPermissions.some(
          (userPermission) => userPermission.id === permission
        )
      );
    },
    [userPermissions]
  );

  // Use this fn to check if the user has the permission specified in the parameter permission
  const hasPermission = useCallback(
    (permission: BasePermissions) => {
      return userPermissions.some(
        (userPermission) => userPermission.id === permission
      );
    },
    [userPermissions]
  );

  /**
   * Use this fn to check if the user has the permission to view all jobs.
   * We have this specific fn for it because user without any permissions
   * could still see jobs list if there's at least one job on response list.
   */
  const hasViewAllJobsPermission = useCallback(
    async () =>
      hasPermission(BasePermissions.VIEW_ALL_JOBS) ||
      (await fetchCustomersSimplified()).length > 0,
    [hasPermission, fetchCustomersSimplified]
  );

  // Use this fn to check if the user has at least one of the permissions specified in the parameter permissions
  const hasSomePermission = useCallback(
    (permissions: BasePermissions[]) => {
      return permissions.some((permission) =>
        userPermissions.some(
          (userPermission) => userPermission.id === permission
        )
      );
    },
    [userPermissions]
  );

  const getPermissionsFromCategory = useCallback(
    (category: BasePermissionCategory) => {
      switch (category) {
        case BasePermissionCategory.BILLS:
          return [
            BasePermissions.ADD_BILL,
            BasePermissions.COMMENT_BILL,
            BasePermissions.VIEW_ALL_BILLS,
            BasePermissions.EDIT_ALL_BILLS,
            BasePermissions.APPROVE_BILLS,
            BasePermissions.PAY_BILLS,
            BasePermissions.BYPASS_APPROVAL_WORKFLOWS,
          ];
        case BasePermissionCategory.EXPENSES:
          return [
            BasePermissions.ADD_EXPENSE,
            BasePermissions.COMMENT_EXPENSE,
            BasePermissions.VIEW_ALL_EXPENSES,
            BasePermissions.EDIT_ALL_EXPENSES,
            BasePermissions.PUBLISH_EXPENSES,
            BasePermissions.FLAG_EXPENSES,
          ];

        case BasePermissionCategory.POS:
          return [
            BasePermissions.ADD_PO,
            BasePermissions.VIEW_ALL_POS,
            BasePermissions.MANAGE_POS,
          ];

        case BasePermissionCategory.COMPANY_SETTINGS:
          return [
            BasePermissions.COMPANY_ADMIN,
            BasePermissions.VIEW_ALL_CODES,
          ];

        case BasePermissionCategory.JOBS:
          return [
            BasePermissions.VIEW_ALL_JOBS,
            BasePermissions.MANAGE_JOBS,
            BasePermissions.EDIT_INITIAL_BUDGET,
          ];

        case BasePermissionCategory.INVOICES:
          return [
            BasePermissions.VIEW_ALL_INVOICES,
            BasePermissions.MANAGE_INVOICES,
            BasePermissions.SYNC_INVOICES_TO_ACCOUNTING_SOFTWARE,
            BasePermissions.COMMENT_INVOICE,
          ];

        case BasePermissionCategory.VENDORS:
          return [
            BasePermissions.VIEW_ALL_VENDORS,
            BasePermissions.MANAGE_PAYMENT_VENDORS,
            BasePermissions.MANAGE_NON_PAYMENT_VENDORS,
          ];
      }
    },
    []
  );

  const canManageLienWaiverTemplate = useMemo(
    () =>
      (client?.settings?.can_manage_lien_waivers &&
        hasPermission(BasePermissions.CREATE_LIEN_WAIVER_TEMPLATE)) ||
      false,
    [client?.settings?.can_manage_lien_waivers, hasPermission]
  );

  const canManageLienWaiverRequest = useMemo(
    () =>
      (client?.settings?.can_manage_lien_waivers &&
        hasPermission(BasePermissions.CREATE_LIEN_WAIVER_REQUEST)) ||
      false,
    [client?.settings?.can_manage_lien_waivers, hasPermission]
  );

  const canViewAllCodes = useMemo(
    () =>
      hasPermission(BasePermissions.VIEW_ALL_CODES) ||
      // @todo remove after the feature is enabled
      !client?.settings?.fail_safe_cost_codes_enabled,
    [client?.settings?.fail_safe_cost_codes_enabled, hasPermission]
  );

  return {
    user,
    notificationCategories,
    role: client?.role,
    permissions: userPermissions,
    hasAllPermissions,
    hasPermission,
    hasSomePermission,
    hasViewAllJobsPermission,
    getPermissionsFromCategory,
    canManageLienWaiverTemplate,
    canManageLienWaiverRequest,
    canViewAllCodes,
  } as const;
};
