import { is } from "@adaptive/design-system/utils";
import {
  budgetLinesApi,
  type ModifyBudgetLineSchemaPayload,
} from "@api/budgets";
import {
  api,
  type BudgetLineItems,
  type UpdateBuilderBudgetLockedStatusPayload,
} from "@api/jobs";
import { createAsyncThunk } from "@reduxjs/toolkit";
import type { Option } from "@shared/types";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";

import { setJobBudgetLockedStatus } from "./slice";

export const updateBudgedLockedStatus = createAsyncThunk(
  "jobs/updateBudgedLockedStatus",
  async (
    payload: UpdateBuilderBudgetLockedStatusPayload,
    { rejectWithValue, dispatch }
  ) => {
    try {
      await api.updateBudgedLockedStatus(payload);
      dispatch(setJobBudgetLockedStatus(payload.isBuilderBudgetLocked));
    } catch {
      return rejectWithValue("Failed updating builder's budget locked status");
    }
  }
);

export const updateBudgetLineFields = ({
  budgetLine,
  categories,
  originalArgs,
}: {
  budgetLine: ModifyBudgetLineSchemaPayload;
  categories: Option[];
  originalArgs: { customerId: number; params: any };
}) =>
  budgetLinesApi.util.updateQueryData(
    "getBudgetLines",
    originalArgs,
    (draft: BudgetLineItems[]) => {
      const index = draft.findIndex((line) => line.id == budgetLine.id);

      if (index === -1) return;

      draft[index].builderAmount = budgetLine.builderAmount || 0;
      draft[index].builderRevisedAmount = budgetLine.builderRevisedAmount || 0;
      draft[index].ownersAmount = budgetLine.ownersAmount || 0;

      const enhancedCategory =
        budgetLine.category === null
          ? null
          : categories.find(({ value }) => value === budgetLine.category) ||
            budgetLine.category;

      if (!is.undefined(enhancedCategory)) {
        if (is.null(enhancedCategory)) {
          draft[index].category = null;
        } else {
          const url = is.object(enhancedCategory)
            ? enhancedCategory.value
            : enhancedCategory;

          const displayName = is.object(enhancedCategory)
            ? enhancedCategory.label
            : "Loading...";

          draft[index].category = {
            id: parseRefinementIdFromUrl(url)!,
            url: url,
            displayName,
          };
        }
      }
    },
    true
  );
