import { sortBy } from "@adaptive/design-system/utils";
import type {
  BillListBalanceByCustomerResponse,
  BillListItemResponse,
} from "@api/bills";

import { NO_JOB } from "../../constants";
import type { MultiplePaymentFormPayment } from "../../types";
import { parseListRowToFormValue } from "../parse-list-row-to-form-value/parse-list-row-to-form-value";

export const groupBillListItemByVendor = (
  billListItems: BillListItemResponse[],
  billListBalanceByCustomer: BillListBalanceByCustomerResponse,
  defaultLienWaiverTemplateUrl?: string
) => {
  const groupedBillListItems = billListItems.reduce<
    MultiplePaymentFormPayment[]
  >((acc, billListItem) => {
    const vendorGroup = acc.find(
      (group) => group.vendor?.id === billListItem.vendor?.id
    );

    const billOpenBalance = billListBalanceByCustomer.find(
      (balance) => balance.id.toString() === billListItem.id.toString()
    );

    const noJobBalance = billOpenBalance?.openBalance?.find(
      (openBalance) => openBalance.customerId.toString() === NO_JOB.id
    );

    const currentBill = { id: billListItem.id, url: billListItem.url || "" };
    const lineAmount = parseFloat(billListItem.balance || "0");
    const nextIndex = acc.length;

    if (!vendorGroup) {
      const customersData =
        billListItem.customers?.map((customer) => {
          const billItemOpenBalance = billOpenBalance?.openBalance?.find(
            (openBalance) =>
              openBalance.customerId.toString() === customer.id?.toString()
          );

          return {
            ...parseListRowToFormValue(
              billListItem,
              nextIndex,
              billOpenBalance?.openBalance || [],
              defaultLienWaiverTemplateUrl
            ),
            balance: billItemOpenBalance?.balance.toString() || "0",
            customer,
            data: [
              {
                ...parseListRowToFormValue(
                  billListItem,
                  nextIndex,
                  billOpenBalance?.openBalance || [],
                  defaultLienWaiverTemplateUrl
                ),
                balance: billItemOpenBalance?.balance || "0",
              },
            ],
            bills: [currentBill],
          };
        }) || undefined;

      if (noJobBalance) {
        customersData.push({
          ...parseListRowToFormValue(
            billListItem,
            nextIndex,
            billOpenBalance?.openBalance || [],
            defaultLienWaiverTemplateUrl
          ),
          balance: noJobBalance.balance.toString(),
          customer: { ...NO_JOB },
          data: [
            {
              ...parseListRowToFormValue(
                billListItem,
                nextIndex,
                billOpenBalance?.openBalance || [],
                defaultLienWaiverTemplateUrl
              ),
              balance: noJobBalance.balance.toString(),
            },
          ],
          bills: [currentBill],
        });
      }

      return [
        ...acc,
        {
          ...parseListRowToFormValue(
            billListItem,
            nextIndex,
            billOpenBalance?.openBalance || [],
            defaultLienWaiverTemplateUrl
          ),
          bills: [currentBill],
          data: customersData,
        },
      ];
    }

    vendorGroup.bills?.push(currentBill);
    vendorGroup.balance = (
      parseFloat(vendorGroup.balance || "0") + lineAmount
    ).toString();

    billListItem.customers?.forEach((customer) => {
      const customerGroup = vendorGroup.data?.find(
        (group) => group.customer?.id === customer.id
      );

      const billItemOpenBalance = billOpenBalance?.openBalance?.find(
        (openBalance) =>
          openBalance.customerId.toString() === customer.id?.toString()
      );

      if (customerGroup) {
        customerGroup.data?.push({
          ...parseListRowToFormValue(
            billListItem,
            vendorGroup.index,
            billOpenBalance?.openBalance || [],
            defaultLienWaiverTemplateUrl
          ),
          balance: billItemOpenBalance?.balance.toString() || "0",
        });
        customerGroup.bills?.push(currentBill);
        customerGroup.balance = (
          parseFloat(customerGroup.balance || "0") +
          parseFloat(billItemOpenBalance?.balance || "0")
        ).toString();
        customerGroup.openBalanceByCustomer = (
          customerGroup.openBalanceByCustomer || []
        ).map((openBalance) => {
          if (openBalance.customerId.toString() === customer.id?.toString()) {
            return {
              ...openBalance,
              balance: (
                parseFloat(openBalance.balance || "0") +
                parseFloat(billItemOpenBalance?.balance || "0")
              ).toString(),
            };
          }
          return openBalance;
        });
      } else {
        vendorGroup.data?.push({
          ...parseListRowToFormValue(
            billListItem,
            vendorGroup.index,
            billOpenBalance?.openBalance || [],
            defaultLienWaiverTemplateUrl
          ),
          customer,
          bills: [currentBill],
          data: [
            {
              ...parseListRowToFormValue(
                billListItem,
                vendorGroup.index,
                billOpenBalance?.openBalance || [],
                defaultLienWaiverTemplateUrl
              ),
              balance: billItemOpenBalance?.balance.toString() || "0",
            },
          ],
          balance: billItemOpenBalance?.balance.toString(),
        });
        vendorGroup.lienWaivers.push({
          customer: customer.url || "",
          lienWaiverTemplate: defaultLienWaiverTemplateUrl,
          missingFields: {
            isValid: true,
          },
        });
      }
    });

    if (noJobBalance) {
      const noCustomerGroup = vendorGroup.data?.find(
        (group) => group.customer?.id === NO_JOB.id
      );

      if (noCustomerGroup) {
        noCustomerGroup.data?.push({
          ...parseListRowToFormValue(
            billListItem,
            vendorGroup.index,
            billOpenBalance?.openBalance || [],
            defaultLienWaiverTemplateUrl
          ),
          balance: noJobBalance.balance.toString(),
        });
        noCustomerGroup.bills?.push(currentBill);
        noCustomerGroup.balance = (
          parseFloat(noCustomerGroup.balance || "0") +
          parseFloat(noJobBalance?.balance || "0")
        ).toString();
      } else {
        vendorGroup.data?.push({
          ...parseListRowToFormValue(
            billListItem,
            vendorGroup.index,
            billOpenBalance?.openBalance || [],
            defaultLienWaiverTemplateUrl
          ),
          balance: noJobBalance.balance.toString(),
          customer: NO_JOB,
          bills: [currentBill],
          data: [
            {
              ...parseListRowToFormValue(
                billListItem,
                vendorGroup.index,
                billOpenBalance?.openBalance || [],
                defaultLienWaiverTemplateUrl
              ),
              balance: noJobBalance.balance.toString(),
            },
          ],
        });
      }
    }

    return acc;
  }, [] as MultiplePaymentFormPayment[]);

  return groupedBillListItems.map((item) => ({
    ...item,
    data: sortBy(item.data || [], "customer.id", "asc"),
  }));
};
