import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  isValidElement,
  memo,
} from "react";
import cn from "clsx";

import { Icon } from "../icon";
import { Text } from "../text";

import styles from "./field-message.module.css";

type DefaultComponent = "p";

type Ref = ComponentRef<DefaultComponent>;

export type Props = ComponentPropsWithoutRef<DefaultComponent> & {
  show?: boolean;
  color?: "neutral" | "error" | "warning";
  variant?: "hidden" | "relative" | "absolute";
};

const FieldMessage = forwardRef<Ref, Props>(
  ({ className, variant, color, children, show = true, ...props }, ref) => (
    <p
      ref={ref}
      className={cn(styles["field-message"], className, {
        [styles[`-${color}`]]: color,
        [styles[`-${variant}`]]: variant,
      })}
      aria-hidden={!show}
      {...props}
    >
      <Icon
        size="sm"
        name={
          color && ["error", "warning"].includes(color)
            ? "exclamation-triangle"
            : "info-circle"
        }
      />
      <Text as="span" truncate={isValidElement(children) ? false : true}>
        {children}
      </Text>
    </p>
  )
);

FieldMessage.displayName = "FieldMessage";

const MemoizedFieldMessage = memo(FieldMessage);

export { MemoizedFieldMessage as FieldMessage };
