import React, { useEffect } from "react";
import {
  Avatar,
  Button,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  PhoneField,
  TextField,
  toast,
} from "@adaptive/design-system";
import {
  useDeepMemo,
  useEvent,
  useForm,
  type UseMultiStepDialogReturn,
} from "@adaptive/design-system/hooks";
import { useUserMutation } from "@hooks/use-user-mutation";
import { useTwoFactorAuth } from "@store/ui";
import { z } from "zod";

import { type Step, type User } from "./types";

type Props = {
  user: User;
  dialog: UseMultiStepDialogReturn<Step>;
};

type Fields = z.infer<typeof schema>;

const schema = z.object({
  full_name: z.string().min(1, "Your name is required"),
  email: z.string().email("An email address is required"),
  phone_number: z.string().min(10, "A phone number is required"),
});

export const ProfileEditDialogUserForm = ({ user, dialog }: Props) => {
  const userMutation = useUserMutation();
  const { checkTwoFactorAuth } = useTwoFactorAuth();

  const saveProfile = useEvent(async (values) => {
    await userMutation.mutate({ id: user.id, ...values });
    dialog.hide();
    toast.success("Settings saved");
  });

  const initialValues = useDeepMemo(
    () => ({
      email: user.email,
      full_name: user.full_name,
      phone_number: user.phone_number || "",
    }),
    [user]
  );

  const form = useForm<Fields>({
    schema,
    async onSubmit(values) {
      if (user.phone_number && values.phone_number !== user.phone_number) {
        checkTwoFactorAuth(() => {
          saveProfile(values);
        });
      } else {
        saveProfile(values);
      }
    },
    initialValues,
  });

  useEffect(() => {
    if (!dialog.isVisible && form.isDirty) form.reset();
  }, [dialog.isVisible, form]);

  return (
    <>
      <DialogHeader>My profile</DialogHeader>
      <DialogContent>
        <Flex as="form" direction="column" gap="xl" {...form.props}>
          <Flex justify="center">
            <Avatar size={140} name={user.full_name} />
          </Flex>
          <Flex direction="column">
            <TextField
              label="Full name"
              required
              {...form.register("full_name")}
            />
            <TextField
              type="email"
              label="Email"
              required
              {...form.register("email")}
            />
            <PhoneField
              label="Phone number"
              required
              {...form.register("phone_number")}
            />
          </Flex>
          <Flex>
            <Button variant="ghost" onClick={() => dialog.setStep("password")}>
              Change password
            </Button>
          </Flex>
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Button block color="neutral" variant="text" onClick={dialog.hide}>
          Cancel
        </Button>
        <Button block type="submit" form={form.id} disabled={form.isSubmitting}>
          Save
        </Button>
      </DialogFooter>
    </>
  );
};
