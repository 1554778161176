import { useCallback } from "react";
import {
  CUSTOMER_ACCOUNT_TYPE,
  HUMAN_READABLE_PAYMENT_METHOD,
  PAYMENT_METHOD,
} from "@bill-payment/constants";
import { useGetBillPaymentOptionsQuery } from "@bill-payment/hooks";
import { type PaymentMethod } from "@bill-payment/types";
import { ACCOUNT_TYPE } from "@src/bills/constants";
import { formatCard } from "@utils/format-card";

const PAYMENT_METHOD_FLAGS_MAP: Record<string, string> = {
  ACH: "ACH_CHECK_ENABLED",
  "mail-check": "MAIL_CHECK_ENABLED",
  "print-check": "PRINT_CHECK_ENABLED",
  "virtual-email-check": "VIRTUAL_EMAIL_CHECK_ENABLED",
  "virtual-sms-check": "VIRTUAL_SMS_CHECK_ENABLED",
};

export type UsePaymentOptionsInfoParams = {
  skip?: boolean;
  billIds?: string[];
  vendorIds?: string[];
  includePaymentAccounts?: boolean;
};

export const usePaymentOptionsInfo = ({
  billIds = [],
  vendorIds = [],
  skip,
  includePaymentAccounts = false,
}: UsePaymentOptionsInfoParams) => {
  const {
    data = [],
    isLoading,
    refetch,
  } = useGetBillPaymentOptionsQuery(
    { billIds, vendorIds },
    {
      skip,
      selectFromResult: ({ data, isLoading }) => ({
        data: data
          ?.filter((paymentOption) => paymentOption.options)
          .map((paymentOption) => {
            const filteredBankAccounts = paymentOption.options.filter(
              (paymentOption) =>
                paymentOption.customerBankAccount ||
                paymentOption.customerCard ||
                (includePaymentAccounts && paymentOption.customerPaymentAccount)
            );

            const accounts = Array.from(
              new Map(
                filteredBankAccounts.map((option) => {
                  const isSamePaymentOption = (
                    item: (typeof data)[number]["options"][number]
                  ) => {
                    return (
                      item.customerCard?.id === option.customerCard?.id &&
                      item.customerBankAccount?.id ===
                        option.customerBankAccount?.id &&
                      item.customerPaymentAccount?.id ===
                        option.customerPaymentAccount?.id
                    );
                  };

                  const isCustomerCard = !!option.customerCard;

                  const isCreditCard =
                    option.customerAccountType ===
                    CUSTOMER_ACCOUNT_TYPE.CREDIT_CARD;

                  const isPaymentAccount =
                    !!option.customerPaymentAccount &&
                    !option.customerCard &&
                    !option.customerBankAccount;

                  const name = isCustomerCard
                    ? option.customerCard?.fullName
                    : option.customerBankAccount?.name ||
                      option.customerPaymentAccount?.displayName;

                  const mask = `${
                    option.customerCard?.mask ||
                    option.customerBankAccount?.mask
                  }`;

                  const type = isCreditCard
                    ? ACCOUNT_TYPE.credit
                    : option.customerBankAccount?.type
                      ? ACCOUNT_TYPE[option.customerBankAccount?.type]
                      : option.customerBankAccount?.type;

                  const markAsPaidOption = paymentOption.options.find(
                    (item) => {
                      return (
                        item.paymentMethod === PAYMENT_METHOD.MARK_AS_PAID &&
                        isSamePaymentOption(item)
                      );
                    }
                  );

                  const achOption = paymentOption.options.find((item) => {
                    return (
                      item.paymentMethod === PAYMENT_METHOD.ACH &&
                      isSamePaymentOption(item)
                    );
                  });

                  const accountOwner =
                    option.customerBankAccount?.plaidAccountOwners.find(
                      (accountOwner) =>
                        accountOwner.cardId === option.customerCard?.id
                    );

                  const paymentAccountName =
                    option.customerPaymentAccount?.displayName || "";

                  const label =
                    option?.customerCard || option?.customerBankAccount
                      ? `${formatCard({
                          name,
                          mask: name?.includes(mask) ? undefined : mask,
                        })}${paymentAccountName ? " - " : ""} ${paymentAccountName}`
                      : paymentAccountName;

                  const value =
                    option.customerCard?.url ||
                    option.customerBankAccount?.url ||
                    option.customerPaymentAccount?.url ||
                    "";

                  const id = isCustomerCard
                    ? option.customerCard?.id
                    : option.customerBankAccount?.id ||
                      option.customerPaymentAccount?.id;

                  return [
                    id,
                    {
                      ...option,
                      label,
                      value,
                      mask,
                      type,
                      markAsPaidOption,
                      achOption,
                      isCreditCard,
                      isPaymentAccount,
                      accountOwner: accountOwner,
                      customerBankAccount: option.customerBankAccount,
                      customerCard: option.customerCard,
                      hasUnmatchedCardTransactions:
                        option.hasUnmatchedCardTransactions,
                      paymentMethods: paymentOption.options.filter((opt) => {
                        const flag =
                          PAYMENT_METHOD_FLAGS_MAP[
                            opt.paymentMethod as keyof typeof PAYMENT_METHOD_FLAGS_MAP
                          ];

                        return (
                          isSamePaymentOption(opt) &&
                          (flag
                            ? (window[flag as keyof typeof window] ?? false)
                            : false)
                        );
                      }),
                    },
                  ];
                })
              ).values()
            );

            return {
              vendor: paymentOption.vendor,
              lienWaivers: paymentOption.lienWaivers,
              vendorCredits: paymentOption.vendorCredits,
              bills: paymentOption.bills,
              accounts,
            };
          })
          .flat(),
        isLoading,
      }),
    }
  );

  const getPaymentMethods = useCallback(
    (paymentMethods?: PaymentMethod[]) =>
      paymentMethods?.map((paymentMethod) => ({
        value: paymentMethod,
        label: HUMAN_READABLE_PAYMENT_METHOD[paymentMethod],
      })) || [],
    []
  );
  return {
    data,
    isLoading,
    refetch,
    getPaymentMethods,
  };
};
