import React from "react";
import {
  Avatar,
  Button,
  Flex,
  Tag,
  Text,
  Tooltip,
} from "@adaptive/design-system";
import {
  formatCurrency,
  formatDate,
  parseDate,
  parseStringCopy,
} from "@adaptive/design-system/utils";

import type { LienWaiver } from "../api/types";
import { STRINGS } from "../constants/constants";

import type { BillLienWaiverFieldProps } from "./bill-lien-waiver-field";

type BillLienWaiverFieldRequestedExternalProps = {
  billLienWaiver: LienWaiver;
} & Pick<BillLienWaiverFieldProps, "onRequestUpdate" | "isProcessPrerequisite">;

export const BillLienWaiverFieldRequestedExternal = ({
  billLienWaiver,
  onRequestUpdate,
  isProcessPrerequisite,
}: BillLienWaiverFieldRequestedExternalProps) => {
  const { revisionEvent } = billLienWaiver;
  const revisionEventDate = parseDate(revisionEvent?.createdAt || "", "iso");

  return (
    <Flex width="full" gap="lg" align="flex-start">
      <Avatar name="signature" color="neutral" size="lg" />
      <Flex direction="column" gap="xs" width="full">
        <Flex direction="row" gap="md" align="baseline" width="full">
          <Flex gap="xs" align="flex-start" direction="column">
            <Text size="sm" weight="bold">
              {formatCurrency(`${billLienWaiver.paymentAmount}`, {
                currencySign: true,
              })}
              {billLienWaiver?.customer?.displayName ? " - " : ""}
              {billLienWaiver?.customer?.displayName}
            </Text>
            <Text color="neutral-700" size="sm">
              {parseStringCopy(STRINGS.LIEN_WAIVER_ACTIVITY_TIME, {
                date: formatDate(revisionEventDate, "MM/dd/yy"),
                time: formatDate(revisionEventDate, "hh:mma"),
              })}
            </Text>
          </Flex>
          {isProcessPrerequisite && (
            <Tooltip message={STRINGS.LIEN_WAIVER_SIGNATURE_REQUIRED}>
              <Tag size="sm" truncate={{ tooltip: false }} color="error">
                {STRINGS.SIGNATURE_REQUIRED}
              </Tag>
            </Tooltip>
          )}
        </Flex>

        <Flex align="flex-start" direction="column">
          <Flex width="full" gap="md" padding={["md", "none", "sm"]}>
            <Button size="sm" onClick={onRequestUpdate}>
              {STRINGS.SIGN_LIEN_WAIVER_REQUEST}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
