import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  Card,
  DateField,
  Flex,
  Icon,
  LabelValue,
  Link,
  ProgressBar,
  Tag,
  Text, // eslint-disable-line no-redeclare
  Textarea,
  TextField,
  Tooltip,
} from "@adaptive/design-system";
import { useDeepMemo, useEvent } from "@adaptive/design-system/hooks";
import {
  formatCurrency,
  formatDate,
  formatPercentage,
  is,
  isEqual,
  parseDate,
} from "@adaptive/design-system/utils";
import { Comments } from "@components/comments";
import { Form, SelectVendor } from "@components/form";
import { Items } from "@components/items";
import { useIntegrationType } from "@hooks/use-integration-type";
import { useVendorsSimplified } from "@hooks/use-vendors-simplified";
import {
  addLine,
  preFillLines,
  preFillVendor,
  recordLineUpdate,
  recordPurchaseOrderUpdate,
  removeLine,
  updateTotalFromLines,
} from "@store/purchaseOrderSlice";
import { usePurchaseOrderDrawer } from "@store/ui";
import { useClientSettings, useUserInfo } from "@store/user";
import * as analytics from "@utils/analytics";
import { isAccount } from "@utils/is-account";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { transformKeysToCamelCase } from "@utils/schema/converters";
import { sum } from "@utils/sum";
import { humanReadableReviewStatus } from "@utils/usefulFunctions";

import { PURCHASE_ORDER_TYPE } from "./constants";
import {
  selectPurchaseOrder,
  selectPurchaseOrderTotals,
} from "./purchase-orders-selectors";

const vendorSelector = (state) =>
  transformKeysToCamelCase(state.purchaseOrder?.purchaseOrder?.vendor);

const commentsSelector = (state) => ({
  comments: state.purchaseOrder?.purchaseOrder?.comments.map((comment) => ({
    ...comment,
    hasExternalMention: comment.has_external_mention,
    createdAt: new Date(comment.created_at),
    timelineEventType: comment.timeline_event_type,
    relatedVersions: comment.related_versions?.map((version) =>
      transformKeysToCamelCase(version)
    ),
    author: comment.author
      ? {
          ...comment.author,
          fullName: comment.author.full_name,
        }
      : null,
    attachable: comment.attachable,
    replies:
      comment.replies?.map((reply) => ({
        ...reply,
        hasExternalMention: reply.has_external_mention,
        createdAt:
          typeof reply.created_at === "string"
            ? new Date(reply.created_at)
            : reply.created_at,
        timelineEventType: reply.timeline_event_type,
        author: {
          ...reply.author,
          fullName: reply.author.full_name,
        },
      })) || [],
    ...(comment.diffs
      ? {
          diffs: comment.diffs.map((diff) => ({
            ...diff,
            oldValue: diff.old_value,
            newValue: diff.new_value,
          })),
        }
      : {}),
  })),
  url: state.purchaseOrder?.purchaseOrder?.url,
});

const PurchaseOrderTotals = memo(() => {
  const { total, percentage, linkedBills, openBalance } = useSelector(
    selectPurchaseOrderTotals
  );

  return (
    <Flex gap="2xl" direction="column">
      <Flex gap="2xl" justify="space-between">
        <Flex width="full">
          <LabelValue
            label="Total"
            value={{
              color: total < 0 ? "warning-200" : "neutral",
              children: formatCurrency(total, {
                currencySign: true,
                allowNegative: true,
              }),
            }}
            data-testid="purchase-order-totals-total"
          />
        </Flex>
        <Flex width="full">
          <LabelValue
            label="Linked bills"
            value={{
              color: linkedBills < 0 ? "warning-200" : "neutral",
              children: formatCurrency(linkedBills, {
                currencySign: true,
                allowNegative: true,
              }),
            }}
            hintMessage="Total amount of approved bills linked to this PO"
            data-testid="purchase-order-totals-linked-bills"
          />
        </Flex>
        <Flex width="full">
          <LabelValue
            label="Open balance"
            value={{
              color: openBalance < 0 ? "warning-200" : "neutral",
              children: formatCurrency(openBalance, {
                currencySign: true,
                allowNegative: true,
              }),
            }}
            data-testid="purchase-order-totals-open-balance"
          />
        </Flex>
      </Flex>
      <ProgressBar
        color={percentage > 100 ? "warning" : "success"}
        value={percentage}
        message={`${formatPercentage(percentage, { percentSign: true })} of PO linked to bills`}
        data-testid="purchase-order-totals-progress-bar"
      />
    </Flex>
  );
});

PurchaseOrderTotals.displayName = "PurchaseOrderTotals";

const PurchaseOrderFormContent = ({ onSave, permissions, linkedLines }) => {
  const { state } = usePurchaseOrderDrawer();

  const dispatch = useDispatch();

  const settings = useClientSettings();

  const purchaseOrder = useSelector(selectPurchaseOrder);

  const { data: vendors } = useVendorsSimplified({ enabled: !!state.vendorId });

  const integrationType = useIntegrationType();

  const vendor = useSelector(vendorSelector, isEqual);

  const preSelectedVendor = useDeepMemo(() => {
    const selectedVendor = vendors.find(
      (item) => parseRefinementIdFromUrl(item.value) == state.vendorId
    );

    return selectedVendor
      ? {
          id: parseRefinementIdFromUrl(selectedVendor.value),
          url: selectedVendor.value,
          email: selectedVendor.email,
          display_name: selectedVendor.label,
        }
      : null;
  }, [vendors, state.vendorId]);

  const preFilledLines = useDeepMemo(() => {
    if (!state.lines) return [];

    return state.lines.map((line) => {
      const enhancedLine = { ...line };

      if (line.item) enhancedLine.item = line.item;

      if (line.account) enhancedLine.account = line.account;

      if (line.customer) enhancedLine.customer = line.customer;

      return enhancedLine;
    });
  }, [state.lines]);

  const hasLinkedBills =
    linkedLines.length > 0 ||
    purchaseOrder?.lines.some((line) => line.linked_bills.length > 0);

  const isLinkedToMultipleBills =
    hasLinkedBills &&
    purchaseOrder?.lines.every((line) => line.linked_bills.length > 1);

  const data = useMemo(
    () =>
      (purchaseOrder?.lines ?? [])
        .filter((line) => !("deleted" in line))
        .map((line) => {
          const amountValue = line.amount || 0;

          let costCodeValue = "";

          if (line.account?.url) {
            costCodeValue = !line.account?.display_name
              ? line.account.url
              : {
                  label: line.account.display_name,
                  value: line.account.url,
                };
          } else if (line.item?.url) {
            costCodeValue = !line.item?.display_name
              ? line.item.url
              : {
                  label: line.item.display_name,
                  value: line.item.url,
                };
          }

          const costCodeUrl = is.object(costCodeValue)
            ? costCodeValue.value
            : costCodeValue;

          const jobCustomerValue =
            line.customer?.url && line.customer?.display_name
              ? { label: line.customer.display_name, value: line.customer.url }
              : line.customer?.url
                ? line.customer?.url
                : "";

          const jobCustomerId = parseRefinementIdFromUrl(
            is.object(jobCustomerValue)
              ? jobCustomerValue.value
              : jobCustomerValue
          );

          const linkedBills = line?.linked_bills || [];

          const uniqueLinkedBills = linkedBills.filter(
            (bill, index, self) =>
              index === self.findIndex((innerBill) => innerBill.id == bill.id)
          );

          let purchaseOrderAmount = line.purchase_order_amount;

          if (!is.nullish(purchaseOrderAmount)) {
            purchaseOrderAmount = parseFloat(purchaseOrderAmount);
          }

          let budgetAvailable = line.customer?.change_tracking_enabled
            ? line.builder_revised_amount
            : line.builder_amount;

          if (!is.nullish(budgetAvailable)) {
            budgetAvailable = sum(
              parseFloat(budgetAvailable),
              -purchaseOrderAmount
            );
          }

          let budgetAvailableContent = (
            <Link as="button" type="button" variant="warning" onClick={onSave}>
              Save changes
            </Link>
          );

          if (!jobCustomerValue && !costCodeValue) {
            budgetAvailableContent = (
              <Text as="span" size="sm" color="neutral-600">
                Set job & cost code / account
              </Text>
            );
          } else if (!jobCustomerValue) {
            budgetAvailableContent = (
              <Text as="span" size="sm" color="neutral-600">
                Set job
              </Text>
            );
          } else if (!costCodeValue) {
            budgetAvailableContent = (
              <Text as="span" size="sm" color="neutral-600">
                Set cost code / account
              </Text>
            );
          } else if (!is.nullish(budgetAvailable)) {
            budgetAvailableContent = (
              <Link
                href={`/jobs/${jobCustomerId}?jobCostMethod=${costCodeUrl}`}
                target="_blank"
              >
                {formatCurrency(budgetAvailable, {
                  currencySign: true,
                  allowNegative: true,
                })}
              </Link>
            );
          }

          const currentPoUsage =
            line.amount === 0
              ? 0
              : (line?.linked_bills_total / line.amount) * 100;

          return {
            id: line.id,
            amount: {
              value: amountValue,
              required: true,
              errorMessage:
                amountValue === 0 ? "Amount should not be empty" : "",
            },
            extra: [
              {
                label: "Open balance",
                value:
                  line?.balance === null ? (
                    <Link
                      as="button"
                      type="button"
                      variant="warning"
                      onClick={onSave}
                    >
                      Save changes
                    </Link>
                  ) : (
                    (line?.balance ?? 0)
                  ),
                variant:
                  line?.balance < 0 || line?.balance === null
                    ? "warning"
                    : "neutral",
              },
              {
                label: (
                  <Flex as="span" align="center" gap="sm">
                    <Text as="span">Budget available</Text>
                    <Tooltip
                      as={Icon}
                      size="sm"
                      name="info-circle"
                      color="neutral-800"
                      message={`${line.customer?.change_tracking_enabled ? "Revised cost" : "Cost"} budget - Total PO amount`}
                    />
                  </Flex>
                ),
                value: budgetAvailableContent,
                variant: budgetAvailable < 0 ? "warning" : "neutral",
              },
            ],
            description: {
              value: line.description,
              render: (children) => {
                const jobUrl = new URL(
                  `${window.origin}/jobs/${jobCustomerId}`
                );

                jobUrl.searchParams.set("location", "changes");
                jobUrl.searchParams.set(
                  "costBudget",
                  Math.abs(budgetAvailable)
                );
                jobUrl.searchParams.set("jobCostMethod", costCodeUrl);
                jobUrl.searchParams.set(
                  "externalBudget",
                  Math.abs(budgetAvailable)
                );

                return (
                  <Flex direction="column" gap="xl">
                    {budgetAvailable < 0 && (
                      <Alert variant="warning">
                        <AlertTitle>
                          This budget line is over-committed
                        </AlertTitle>
                        <AlertContent as={Flex} gap="md" direction="column">
                          Keep your project financials accurate by adjusting
                          your cost budget to match committed costs
                          <Flex>
                            <Button
                              as="a"
                              size="sm"
                              href={jobUrl}
                              target="_blank"
                              onClick={() => {
                                analytics.track(
                                  "purchaseOrderOpenOverCommittedJob",
                                  {
                                    jobId: jobCustomerId,
                                    purchaseOrderId: purchaseOrder.id,
                                  }
                                );
                              }}
                            >
                              Adjust budget
                            </Button>
                          </Flex>
                        </AlertContent>
                      </Alert>
                    )}
                    <div>{children}</div>
                  </Flex>
                );
              },
            },
            ...(!permissions.isQbPo && !permissions.isBuilderTrendPo
              ? { closable: { checked: line.status === "Open" } }
              : {}),
            jobCustomer: {
              value: jobCustomerValue,
            },
            costCodeAccount: {
              value: costCodeValue,
              required: true,
              errorMessage: !costCodeValue ? "Items must be attributed" : "",
              disabled:
                (permissions.isQbPo || permissions.isBuilderTrendPo) &&
                line.linked_bills.length > 0,
            },
            footer: uniqueLinkedBills.length > 0 && (
              <Flex gap="2xl" justify="space-between">
                <Flex width="full">
                  <LabelValue
                    label={{ size: "xs", children: "Linked bills" }}
                    value={{
                      weight: "regular",
                      children: (
                        <Flex direction="column">
                          {uniqueLinkedBills.map((bill) => (
                            <Text
                              as={Link}
                              truncate
                              key={bill.id}
                              href={`/bills/${bill.id}`}
                              target="_blank"
                              variant="success"
                              onClick={() => {
                                analytics.track("purchaseOrderOpenLinkedBill", {
                                  billId: bill.id,
                                  purchaseOrderId: purchaseOrder.id,
                                });
                              }}
                            >
                              Bill #{bill.doc_number} (
                              {humanReadableReviewStatus(
                                bill.human_readable_type,
                                bill.review_status
                              )}
                              )
                            </Text>
                          ))}
                        </Flex>
                      ),
                    }}
                    data-testid="purchase-order-items-linked-bills"
                  />
                </Flex>
                <Flex width="full">
                  <LabelValue
                    label={{ size: "xs", children: "Bill line total" }}
                    value={{
                      weight: "regular",
                      children: formatCurrency(line?.linked_bills_total, {
                        currencySign: true,
                        allowNegative: true,
                      }),
                    }}
                    hintMessage="Total amount of approved bill lines linked to this PO line"
                    data-testid="purchase-order-items-linked-bills-total"
                  />
                </Flex>
                <Flex width="full">
                  <LabelValue
                    label={{
                      size: "xs",
                      children: (
                        <Flex align="center" gap="sm">
                          Current PO line usage{" "}
                          <Tooltip
                            as={Icon}
                            size="sm"
                            color="neutral-700"
                            name="info-circle"
                            message="Approved bill line total / PO line amount"
                          />
                        </Flex>
                      ),
                    }}
                    value={
                      <Tag
                        size="sm"
                        color={currentPoUsage > 100 ? "warning" : "neutral"}
                      >
                        {formatPercentage(currentPoUsage, {
                          percentSign: true,
                        })}
                      </Tag>
                    }
                    data-testid="purchase-order-items-linked-usage"
                  />
                </Flex>
              </Flex>
            ),
          };
        }),
    [
      onSave,
      purchaseOrder.id,
      permissions.isQbPo,
      purchaseOrder?.lines,
      permissions.isBuilderTrendPo,
    ]
  );

  const { canViewAllCodes } = useUserInfo();

  const showAll = canViewAllCodes;

  const vendorFilters = useMemo(
    () =>
      vendor?.url ? { vendorId: parseRefinementIdFromUrl(vendor.url) } : {},
    [vendor?.url]
  );

  const componentsProps = useMemo(
    () => ({
      costCodeAccount: {
        accountFilters: {
          enabled: integrationType !== "QBDT",
          only_purchase_order_line_accounts: true,
          ...vendorFilters,
        },
        costCodeFilters: {
          canItemsLinkToLinesDesktop: true,
          ...vendorFilters,
        },
        showAll,
      },
      table: { headerSticky: { offset: -24 } },
    }),
    [integrationType, showAll, vendorFilters]
  );

  const onAmountChange = useEvent((id, value) => {
    const item = purchaseOrder?.lines?.find((line) => line.id === id);

    if (!item || value === item.amount) return;

    dispatch(
      recordLineUpdate({
        id,
        payload: {
          amount: value,
          balance: null,
          builder_amount: null,
          builder_revised_amount: null,
        },
      })
    );
    dispatch(updateTotalFromLines());
  });

  const onJobCustomerChange = useEvent((id, option) => {
    const customer = {
      url: option?.value ?? null,
      display_name: option?.label ?? null,
      change_tracking_enabled:
        option?.change_tracking_enabled ?? option?.changeTrackingEnabled,
    };
    dispatch(
      recordLineUpdate({
        id,
        payload: {
          customer,
          builder_amount: null,
          builder_revised_amount: null,
        },
      })
    );
  });

  const onClosableChange = useEvent((id, checked) => {
    const newStatus = checked ? "Open" : "Closed";
    const payload = { status: newStatus, force_close: !checked };
    dispatch(recordLineUpdate({ id, payload }));
  });

  const onAddComment = useEvent(
    ({ user, text, id, url, parentCommentUrl, hasExternalMention, files }) => {
      const newComment = {
        id,
        url,
        text,
        author: { ...user, full_name: user.fullName },
        created_at: new Date(),
        files,
        has_external_mention: hasExternalMention,
        timeline_event_type: "COMMENT",
      };

      dispatch(
        recordPurchaseOrderUpdate({
          comments: parentCommentUrl
            ? purchaseOrder.comments.map((comment) => {
                if (comment.url === parentCommentUrl) {
                  return {
                    ...comment,
                    replies: [...(comment.replies || []), newComment],
                  };
                }
                return comment;
              })
            : [...purchaseOrder.comments, newComment],
        })
      );
    }
  );

  const onCostCodeAccountChange = useEvent((id, option) => {
    const payload = {
      item: { display_name: null, url: null },
      account: { display_name: null, url: null },
      category: null,
      builder_amount: null,
      builder_revised_amount: null,
    };

    if (option) {
      payload.category = isAccount(option.value) ? "Account" : "Cost code";

      if (isAccount(payload.category)) {
        payload.type = "AccountBasedExpenseLineDetail";
        payload.account = { display_name: option.label, url: option.value };
      } else {
        payload.type = "ItemBasedExpenseLineDetail";
        payload.item = { display_name: option.label, url: option.value };
      }
    }

    dispatch(recordLineUpdate({ id, payload }));
  });

  const onDescriptionChange = useEvent((id, value) => {
    dispatch(recordLineUpdate({ id, payload: { description: value } }));
  });

  const canRemoveLine = useCallback(
    (id) => {
      const line = purchaseOrder?.lines?.find((line) => line.id == id);

      if (!line) return false;

      const isLinkedToBill =
        linkedLines.some((linkedLine) => linkedLine == line.id) ||
        line.linked_bills.length > 0;

      return !isLinkedToBill;
    },
    [linkedLines, purchaseOrder?.lines]
  );

  const onItemsRemove = useEvent((id) => {
    if (!canRemoveLine(id)) return false;

    dispatch(removeLine(id));
    dispatch(updateTotalFromLines());
  });

  const onItemsAdd = useEvent(() => {
    dispatch(addLine());
  });

  const mutateStrategy = {
    amount: onAmountChange,
    closable: onClosableChange,
    jobCustomer: onJobCustomerChange,
    description: onDescriptionChange,
    costCodeAccount: onCostCodeAccountChange,
  };

  const onItemsChange = useEvent(({ id, name, value }) => {
    const line = purchaseOrder?.lines?.find((line) => line.id == id);

    if (!line) return false;

    mutateStrategy[name](id, value);
  });

  const isNewItem = useCallback((item) => typeof item.id !== "number", []);

  useEffect(() => {
    if (preSelectedVendor) {
      requestAnimationFrame(() => dispatch(preFillVendor(preSelectedVendor)));
    }
  }, [dispatch, preSelectedVendor]);

  useEffect(() => {
    if (preFilledLines.length > 0) {
      requestAnimationFrame(() => dispatch(preFillLines(preFilledLines)));
    }
  }, [preFilledLines, dispatch]);

  return (
    <Flex gap="5xl" direction="column">
      <Form id="purchase-order" onSubmit={onSave} onEnterSubmit={onSave}>
        <Flex gap="5xl" direction="column">
          <Flex gap="xl" direction="column">
            <Text size="xl" weight="bold">
              Details
            </Text>
            <Card>
              <Flex direction="column" gap="sm">
                <SelectVendor
                  label="Vendor name"
                  required
                  placeholder="Select vendor name"
                  data-testid="purchase-order-vendor"
                  disabled={
                    !permissions.canEdit ||
                    isLinkedToMultipleBills ||
                    permissions.isQbPo ||
                    permissions.isBuilderTrendPo
                  }
                  selector={vendorSelector}
                  // TODO: update this logic
                  helperMessage={
                    (settings.adaptivePosEnabled &&
                      permissions.canEdit &&
                      isLinkedToMultipleBills &&
                      purchaseOrder.type === PURCHASE_ORDER_TYPE.ADAPTIVE) ||
                    (!settings.adaptivePosEnabled && isLinkedToMultipleBills)
                      ? "Unlink bills to edit"
                      : ""
                  }
                  onChange={(_, option) =>
                    dispatch(
                      recordPurchaseOrderUpdate({
                        vendor: {
                          url: option?.value,
                          email: option?.email,
                          display_name: option?.label,
                        },
                      })
                    )
                  }
                />

                <TextField
                  disabled={
                    !permissions.canEdit ||
                    permissions.isQbPo ||
                    permissions.isBuilderTrendPo
                  }
                  label="Shipping address"
                  value={purchaseOrder.shipping_address}
                  messageVariant="absolute"
                  onChange={(value) =>
                    dispatch(
                      recordPurchaseOrderUpdate({ shipping_address: value })
                    )
                  }
                />

                <br />

                <Flex gap="xl">
                  <TextField
                    disabled={
                      !permissions.canEdit ||
                      permissions.isQbPo ||
                      permissions.isBuilderTrendPo
                    }
                    label="Purchase order number"
                    value={purchaseOrder.doc_number}
                    maxLength={20}
                    data-testid="po-number"
                    onChange={(value) =>
                      dispatch(recordPurchaseOrderUpdate({ doc_number: value }))
                    }
                  />

                  <DateField
                    label="Purchase order date"
                    disabled={
                      !permissions.canEdit ||
                      permissions.isQbPo ||
                      permissions.isBuilderTrendPo
                    }
                    value={
                      purchaseOrder.date
                        ? parseDate(purchaseOrder.date, "yyyy-MM-dd")
                        : null
                    }
                    onChange={(date) => {
                      dispatch(
                        recordPurchaseOrderUpdate({
                          date: date ? formatDate(date, "yyyy-MM-dd") : null,
                        })
                      );
                    }}
                  />
                </Flex>

                {!permissions.isQbPo && (
                  <Textarea
                    disabled={
                      !permissions.canEdit ||
                      permissions.isQbPo ||
                      permissions.isBuilderTrendPo
                    }
                    label="Scope of work"
                    value={purchaseOrder.scope_of_work || ""}
                    onChange={(value) => {
                      dispatch(
                        recordPurchaseOrderUpdate({ scope_of_work: value })
                      );
                    }}
                    wysiwyg
                  />
                )}
              </Flex>

              <PurchaseOrderTotals />
            </Card>
          </Flex>

          <Items
            id="purchase-order-items"
            data={data}
            total={purchaseOrder?.total_amount ?? 0}
            onAdd={onItemsAdd}
            onChange={onItemsChange}
            onRemove={onItemsRemove}
            disabled={
              !permissions.canEdit ||
              permissions.isQbPo ||
              permissions.isBuilderTrendPo
                ? true
                : { removeLine: (line) => !canRemoveLine(line.id) }
            }
            isNewItem={isNewItem}
            data-testid="purchase-order-items"
            componentsProps={componentsProps}
          />
        </Flex>
      </Form>

      {purchaseOrder.id ? (
        <Comments
          vendor={vendor}
          selector={commentsSelector}
          onAddComment={permissions.canComment ? onAddComment : undefined}
        />
      ) : null}
    </Flex>
  );
};

const MemoizedPurchaseOrderFormContent = memo(PurchaseOrderFormContent);

export { MemoizedPurchaseOrderFormContent as PurchaseOrderFormContent };
