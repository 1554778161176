import React from "react";
import type { TableColumn } from "@adaptive/design-system";
import type { BudgetLineItems } from "@api/jobs";
import { capitalize } from "@utils/capitalize";

import {
  CostBudgetVisibilityName,
  CostsActualColumn,
  CostsActualFooter,
  CostsActualHeader,
  CostsBudgetColumn,
  CostsBudgetFooter,
  CostsBudgetHeader,
  CostsBudgetRemainingTooltip,
  CostsChangesColumn,
  CostsChangesFooter,
  CostsChangesHeader,
  CostsRemainingColumn,
  CostsRemainingFooter,
  CostsRevisedBudgetColumn,
  CostsRevisedBudgetFooter,
  CostsUndrawnColumn,
  CostsUndrawnFooter,
  CostsUnpaidColumn,
  CostsUnpaidFooter,
  EmptyColumn,
  PurchaseOrderAmountColumn,
  PurchaseOrderAmountFooter,
  PurchaseOrderAmountHeader,
  PurchaseOrderOpenBalanceColumn,
  PurchaseOrderOpenBalanceFooter,
  RevenuesChangesColumn,
  RevenuesChangesFooter,
  RevenuesChangesHeader,
  RevenuesDrawnColumn,
  RevenuesDrawnFooter,
  RevenuesDrawnHeader,
  RevenuesDrawnRemainingColumn,
  RevenuesDrawnRemainingFooter,
  RevenuesDrawRemainingTooltip,
  RevenuesMarkupColumn,
  RevenuesOwnersBudgetColumn,
  RevenuesOwnersBudgetFooter,
  RevenuesOwnersBudgetHeader,
  RevenuesOwnersBudgetVisibilityName,
  RevenuesRevisedBudgetColumn,
  RevenuesRevisedBudgetFooter,
  TransactionsCategoryColumn,
  TransactionsCommentsColumn,
  TransactionsLineColumn,
  TransactionsLineFooter,
} from "./lines-components";
export type Line = BudgetLineItems & {
  data?: Line[];
  /**
   * When we group by QuickBooks (parent), we duplicate the parent line
   * to be shown in both the parent and the child group. To figure out
   * which line is duplicated, we add a `clone` property, so we can
   * apply some specific logic to it.
   */
  clone?: boolean;
  remaining: number;
  budgetRemaining: number;
};

type LineColumn = TableColumn<Line>;

export const getIsDeletable = (row: BudgetLineItems) => {
  return (
    row.jobCostMethod.url !== row.jobCostMethod.parent &&
    !row.hasCosts &&
    !row.hasInvoices &&
    !row.hasChanges
  );
};

/**
 * Columns definition
 */
type GetLineColumnsProps = {
  viewMode: string;
  hasChanges: boolean;
  hasComments: boolean;
  hasCategories: boolean;
  hasOwnersAmount: boolean;
  hasPurchaseOrders: boolean;
  hasExternalRevisedBudget: boolean;
};

export const getLineColumns = ({
  viewMode,
  hasChanges,
  hasComments,
  hasCategories,
  hasOwnersAmount,
  hasPurchaseOrders,
  hasExternalRevisedBudget,
}: GetLineColumnsProps) => {
  const info: LineColumn = {
    id: "info",
    columns: [],
  };

  const isItemsOrQuickbooks = ["items", "quickbooks"].includes(viewMode);

  const nameColumn: LineColumn = {
    id: `name${capitalize(viewMode)}`,
    name: "Line items",
    visibility: {
      name: "Line items",
      mode: "always-visible-not-orderable",
    },
    width: isItemsOrQuickbooks ? "fill" : 350,
    minWidth: isItemsOrQuickbooks ? 350 : undefined,
    orderable: false,
    render: (row) => <TransactionsLineColumn {...row} />,
    sortable: "asc",
  };

  const categoryColumn: LineColumn = {
    id: `category${capitalize(viewMode)}`,
    name: "Categories",
    orderable: false,
    visibility: {
      name: "Categories",
      mode: "always-visible-not-orderable",
    },
    width: 275,
    render: (row) => (
      <TransactionsCategoryColumn
        mode={(row.data?.length ?? 0) > 0 ? "group" : "line"}
        {...row}
      />
    ),
    sortable: "asc",
  };

  const commentsColumn: LineColumn = {
    id: `comments${capitalize(viewMode)}`,
    name: (
      <span data-intercom-target="budgets-comments-column-name">Notes</span>
    ),
    orderable: false,
    visibility: {
      name: "Notes",
      mode: "always-visible-not-orderable",
    },
    width: 105,
    render: (row) => <TransactionsCommentsColumn {...row} />,
  };

  if (isItemsOrQuickbooks) {
    info.columns.push({
      ...nameColumn,
      footer: {
        colSpan:
          (hasCategories && !hasComments) || (!hasCategories && hasComments)
            ? 2
            : hasCategories && hasComments
              ? 3
              : 1,
        render: <TransactionsLineFooter />,
      },
    });
  }

  if (hasCategories) {
    info.columns.push({
      ...categoryColumn,
      footer: {
        colSpan: isItemsOrQuickbooks
          ? 0
          : (hasCategories && !hasComments) || (!hasCategories && hasComments)
            ? 2
            : 3,
        render: isItemsOrQuickbooks ? null : <TransactionsLineFooter />,
      },
    });
  }

  if (viewMode === "categories") {
    info.columns.push({
      ...nameColumn,
      footer: { colSpan: 0, render: null },
    });
  }

  if (hasComments) {
    info.columns.push({
      ...commentsColumn,
      footer: { colSpan: 0, render: null },
    });
  }

  const costs: LineColumn = {
    id: "costs",
    name: "Costs",
    columns: [
      {
        id: "budget",
        name: <CostsBudgetHeader />,
        width: 205,
        render: (row) => <CostsBudgetColumn {...row} />,
        footer: <CostsBudgetFooter />,
        sortable: true,
        visibility: {
          name: <CostBudgetVisibilityName />,
          mode: "visible",
        },
      },
    ],
    highlight: "info",
  };

  if (hasChanges) {
    costs.columns.push(
      {
        id: "changes",
        name: <CostsChangesHeader />,
        width: 165,
        render: (row) => <CostsChangesColumn {...row} />,
        footer: <CostsChangesFooter />,
        sortable: true,
        visibility: {
          name: "Costs changes",
          mode: "visible",
        },
      },
      {
        id: "revisedBudget",
        name: "Revised budget",
        width: 165,
        render: (row) => <CostsRevisedBudgetColumn {...row} />,
        footer: <CostsRevisedBudgetFooter />,
        message:
          hasOwnersAmount && hasExternalRevisedBudget
            ? "[Cost budget] + [Changes]"
            : "[Budget] + [Changes]",
        sortable: true,
      }
    );
  }

  costs.columns.push({
    id: "actual",
    message: "Total cost of approved bills & receipts",
    name: <CostsActualHeader />,
    width: 185,
    render: (row) => <CostsActualColumn {...row} />,
    footer: <CostsActualFooter />,
    sortable: true,
    visibility: {
      name: "Actual cost to date",
      mode: "visible",
    },
  });

  costs.columns.push({
    id: "undrawnCosts",
    name: "Undrawn costs",
    width: 165,
    render: (row) => <CostsUndrawnColumn {...row} />,
    footer: <CostsUndrawnFooter />,
    sortable: true,
    visibility: {
      name: "Undrawn costs",
      mode: "hidden",
    },
  });

  costs.columns.push({
    id: "unpaid",
    name: "Unpaid bills",
    width: 165,
    message: "Total cost of unpaid bills",
    render: (row) => <CostsUnpaidColumn {...row} />,
    footer: <CostsUnpaidFooter />,
    sortable: true,
    visibility: {
      name: "Unpaid bills",
      mode: "hidden",
    },
  });

  costs.columns.push({
    id: "costsRemaining",
    name: "Budget remaining",
    width: 175,
    render: (row) => <CostsRemainingColumn {...row} />,
    footer: <CostsRemainingFooter />,
    message: <CostsBudgetRemainingTooltip />,
    sortable: true,
  });

  const purchaseOrders: LineColumn = {
    id: "purchaseOrders",
    name: "Purchase orders",
    columns: [],
    highlight: "warning",
  };

  purchaseOrders.columns.push(
    {
      id: "purchaseOrderAmount",
      name: <PurchaseOrderAmountHeader />,
      width: 165,
      render: (row) => <PurchaseOrderAmountColumn {...row} />,
      footer: <PurchaseOrderAmountFooter />,
      sortable: true,
      visibility: {
        name: "Purchase orders",
        mode: "visible",
      },
      message: `Total amount of all PO lines for this\n job (including open and closed POs)`,
    },
    {
      id: "purchaseOrderOpenBalance",
      name: "Open balance",
      width: 165,
      render: (row) => <PurchaseOrderOpenBalanceColumn {...row} />,
      footer: <PurchaseOrderOpenBalanceFooter />,
      sortable: true,
      message: `Total amount of all open PO\n lines for this job`,
    }
  );

  const revenues: LineColumn = {
    id: "revenues",
    name: "Revenue",
    columns: [],
    highlight: "success",
  };

  if (window.BUDGET_MARKUP_COLUMN_ENABLED) {
    revenues.columns.push({
      id: "markup",
      name: "Markup",
      width: 165,
      render: (row) => <RevenuesMarkupColumn {...row} />,
      footer: <EmptyColumn />,
    });
  }

  if (hasOwnersAmount) {
    revenues.columns.push({
      id: "ownersBudget",
      name: <RevenuesOwnersBudgetHeader />,
      width: 225,
      visibility: {
        name: <RevenuesOwnersBudgetVisibilityName />,
        mode: "visible",
      },
      render: (row) => <RevenuesOwnersBudgetColumn {...row} />,
      footer: <RevenuesOwnersBudgetFooter />,
      sortable: true,
    });

    if (hasChanges && hasExternalRevisedBudget) {
      revenues.columns.push(
        {
          id: "revenuesChanges",
          name: <RevenuesChangesHeader />,
          width: 165,
          visibility: {
            name: "Revenue changes",
            mode: "visible",
          },
          render: (row) => <RevenuesChangesColumn {...row} />,
          footer: <RevenuesChangesFooter />,
          sortable: true,
        },
        {
          id: "revenuesRevisedBudget",
          name: "Revised budget",
          width: 165,
          render: (row) => <RevenuesRevisedBudgetColumn {...row} />,
          footer: <RevenuesRevisedBudgetFooter />,
          message: "[Revenue budget] + [Changes]",
          sortable: true,
        }
      );
    }
  }

  revenues.columns.push(
    {
      id: "drawn",
      name: <RevenuesDrawnHeader />,
      width: 165,
      visibility: {
        name: "Draw to date",
        mode: "visible",
      },
      render: (row) => <RevenuesDrawnColumn {...row} />,
      footer: <RevenuesDrawnFooter />,
      message: "Total amount on draws",
      sortable: true,
    },
    {
      id: "remaining",
      name: "Draw remaining",
      width: 165,
      message: <RevenuesDrawRemainingTooltip />,
      render: (row) => <RevenuesDrawnRemainingColumn {...row} />,
      footer: <RevenuesDrawnRemainingFooter />,
      sortable: true,
    }
  );

  if (!hasPurchaseOrders) return [info, costs, revenues];

  return [info, costs, purchaseOrders, revenues];
};
