import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import {
  Button,
  ComboBox,
  type ComboBoxRenderOptionHandler,
  CurrencyField,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  Link,
  Switch,
  Table,
  type TableColumn,
  type TableRef,
  Text,
  Tooltip,
} from "@adaptive/design-system";
import {
  useDeepMemo,
  type useDialog,
  useEvent,
  useForm,
} from "@adaptive/design-system/hooks";
import { formatCurrency, isEqual } from "@adaptive/design-system/utils";
import { nanoid } from "@reduxjs/toolkit";
import * as analytics from "@utils/analytics";
import { noop } from "@utils/noop";
import { idSchema } from "@utils/schema";
import { createContext, useContextSelector } from "use-context-selector";
import { z } from "zod";

import {
  isInPaymentStatusBillSelector,
  minimalBillAndLinesSelector,
} from "../../utils";

type GenericReference = {
  id?: string | number | null;
  url?: string | null;
  displayName?: string | null;
};

type Line = {
  id: string | number;
  item?: GenericReference | null;
  amount: number;
  account?: GenericReference | null;
  customer?: GenericReference | null;
  description?: string | null;
  linkedTransaction?: string | number | null;
};

type Parent = {
  id?: string | number | null;
  url?: string | null;
  type?: string | null;
  lines: Line[];
  total?: number;
  docNumber?: string | null;
  reviewStatus?: string | null;
};

type RemoveBillLineHandler = (index: number) => void;

type UnlinkPurchaseOrderHandler = (index: number) => void;

type DistributeAmountProportionallyHandler = (params: {
  lines: Line[];
  total: number;
}) => Line[];

type Context = {
  bill?: Parent;
  register: ReturnType<typeof useForm>["register"];
  purchaseOrder?: Omit<Parent, "lines"> & {
    lines: (Line & { label: string; value: string })[];
    total: number;
  };
  removeBillLine: RemoveBillLineHandler;
  hasMultipleLines: boolean;
  isInPaymentStatus: boolean;
  unlinkPurchaseOrder: UnlinkPurchaseOrderHandler;
  isProportionallyDistributed: boolean;
};

const PurchaseOrdersLinkContext = createContext<Context>({
  bill: undefined,
  register: noop,
  purchaseOrder: undefined,
  removeBillLine: noop,
  hasMultipleLines: false,
  isInPaymentStatus: false,
  unlinkPurchaseOrder: noop,
  isProportionallyDistributed: false,
});

const Info = memo(({ item, account, customer, description }: Line) => (
  <Flex direction="column">
    <Text size="sm" truncate>
      <Text as="strong" weight="bold">
        Job:
      </Text>{" "}
      {customer?.displayName || "—"}
    </Text>
    <Text size="sm" truncate>
      <Text as="strong" weight="bold">
        {item?.displayName
          ? "Cost code"
          : account?.displayName
            ? "Account"
            : "Cost code / Account"}
        :
      </Text>{" "}
      {item?.displayName || account?.displayName || "—"}
    </Text>
    <Text size="sm" truncate={2}>
      <Text as="strong" weight="bold">
        Description:
      </Text>{" "}
      {description || "—"}
    </Text>
  </Flex>
));

Info.displayName = "Info";

const BillLineHeader = memo(() => {
  const bill = useContextSelector(
    PurchaseOrdersLinkContext,
    (context) => context.bill
  );

  const content = `Bill${bill?.docNumber ? ` #${bill.docNumber}` : ""}`;

  return (
    <Flex padding={["sm", "none"]} align="center">
      {bill?.id ? (
        <Link size="sm" href={`/bills/${bill.id}`} target="_blank">
          {content}
        </Link>
      ) : (
        content
      )}
    </Flex>
  );
});

BillLineHeader.displayName = "BillLineHeader";

const BillLineCell = memo(
  ({ index, linkedTransaction, ...props }: Line & { index: number }) => {
    const {
      register,
      isInPaymentStatus,
      linkedTransactionLine,
      isProportionallyDistributed,
    } = useContextSelector(
      PurchaseOrdersLinkContext,
      ({
        register,
        purchaseOrder,
        isInPaymentStatus,
        isProportionallyDistributed,
      }) => ({
        register,
        isInPaymentStatus,
        linkedTransactionLine: purchaseOrder?.lines.find(
          (line) => line.id == linkedTransaction
        ),
        isProportionallyDistributed,
      })
    );

    return (
      <Flex direction="column" padding={["md", "none", "none"]}>
        <CurrencyField
          align="right"
          disabled={isInPaymentStatus || isProportionallyDistributed}
          placeholder="0.00"
          helperMessage={
            isInPaymentStatus
              ? "This field is disabled as the bill is already paid"
              : ""
          }
          {...register({ name: `lines.${index}.amount`, type: "currency" })}
        />
        <Info {...(linkedTransactionLine ? linkedTransactionLine : props)} />
        {linkedTransactionLine && (
          <Text size="sm" fontStyle="italic" color="neutral-600">
            Line data inherited from PO
          </Text>
        )}
      </Flex>
    );
  }
);

BillLineCell.displayName = "BillLineCell";

const BillLineFooter = memo(() => {
  const total = useContextSelector(
    PurchaseOrdersLinkContext,
    ({ bill }) => bill?.total ?? 0
  );

  return (
    <Flex align="center" padding={["xs", "none"]} justify="space-between">
      <Text as="strong" weight="bold">
        Totals
      </Text>
      <Text
        as="strong"
        weight="bold"
        data-testid="purchase-orders-link-dialog-bill-total"
      >
        {formatCurrency(total, { currencySign: true })}
      </Text>
    </Flex>
  );
});

BillLineFooter.displayName = "BillLineFooter";

const PurchaseOrderLineHeader = memo(() => {
  const purchaseOrder = useContextSelector(
    PurchaseOrdersLinkContext,
    (context) => context.purchaseOrder
  );

  return (
    <Flex padding={["sm", "none"]} align="center">
      <Link
        href={`/purchase-orders/${purchaseOrder!.id}`}
        size="sm"
        target="_blank"
      >
        PO{purchaseOrder?.docNumber ? ` #${purchaseOrder.docNumber}` : ""}
      </Link>
    </Flex>
  );
});

PurchaseOrderLineHeader.displayName = "PurchaseOrderLineHeader";

const PurchaseOrderLineCell = memo(
  ({ index, linkedTransaction }: Line & { index: number }) => {
    const {
      billId,
      register,
      purchaseOrderId,
      billReviewStatus,
      purchaseOrderLines,
    } = useContextSelector(
      PurchaseOrdersLinkContext,
      ({ register, purchaseOrder, bill }) => ({
        billId: bill?.id,
        register,
        purchaseOrderId: purchaseOrder?.id,
        purchaseOrderLines: purchaseOrder?.lines ?? [],
        billReviewStatus: bill?.reviewStatus,
      })
    );

    const onChange = useEvent(() => {
      analytics.track("billLinkedPurchaseOrderLinkLine", {
        billId,
        purchaseOrderId,
        billReviewStatus,
      });
    });

    const renderOption = useCallback<ComboBoxRenderOptionHandler<Line>>(
      (option, extra) => (
        <Flex direction="column">
          <Text>{extra.highlightedLabel}</Text>
          <Info {...option} />
        </Flex>
      ),
      []
    );

    const purchaseOrderLine = useDeepMemo(
      () => purchaseOrderLines.find((line) => line.id == linkedTransaction),
      [linkedTransaction, purchaseOrderLines]
    );

    return (
      <Flex direction="column" padding={["md", "none", "none"]}>
        <ComboBox
          {...register({
            name: `lines.${index}.linkedTransaction`,
            type: "string",
            onChange,
          })}
          data={purchaseOrderLines}
          value={purchaseOrderLine?.id?.toString() ?? ""}
          portal
          placeholder="Select PO line"
          renderOption={renderOption}
        />
        {purchaseOrderLine && <Info {...purchaseOrderLine} />}
      </Flex>
    );
  }
);

PurchaseOrderLineCell.displayName = "PurchaseOrderLineCell";

const PurchaseOrderLineFooter = memo(() => {
  const { total } = useContextSelector(
    PurchaseOrdersLinkContext,
    ({ purchaseOrder }) => ({ total: purchaseOrder?.total ?? 0 })
  );

  return (
    <Flex padding={["xs", "none"]} gap="md" align="center" justify="flex-end">
      <Text as="strong" weight="bold">
        {formatCurrency(total, { currencySign: true })}
      </Text>
    </Flex>
  );
});

PurchaseOrderLineFooter.displayName = "PurchaseOrderLineFooter";

const ActionsCell = memo(
  ({ linkedTransaction, index }: Line & { index: number }) => {
    const {
      removeBillLine,
      hasMultipleLines,
      isInPaymentStatus,
      unlinkPurchaseOrder,
    } = useContextSelector(
      PurchaseOrdersLinkContext,
      ({
        removeBillLine,
        hasMultipleLines,
        isInPaymentStatus,
        unlinkPurchaseOrder,
      }) => ({
        removeBillLine,
        hasMultipleLines,
        isInPaymentStatus,
        unlinkPurchaseOrder,
      })
    );

    const onRemoveBillLine = useEvent(() => removeBillLine(index));

    const onUnlinkPurchaseOrder = useEvent(() => unlinkPurchaseOrder(index));

    return (
      <Flex direction="column" gap="md" padding={["md", "none", "none"]}>
        <Tooltip
          message={hasMultipleLines && !isInPaymentStatus ? "Delete line" : ""}
          placement="top-end"
        >
          <Button
            size="sm"
            color="neutral"
            variant="ghost"
            onClick={onRemoveBillLine}
            disabled={!hasMultipleLines || isInPaymentStatus}
            data-testid="purchase-orders-link-dialog-remove-bill-line-button"
          >
            <Icon name="trash" />
          </Button>
        </Tooltip>
        <Tooltip
          message={
            linkedTransaction && !isInPaymentStatus ? "Unlink line from PO" : ""
          }
          placement="top-end"
        >
          <Button
            size="sm"
            color="neutral"
            variant="ghost"
            onClick={onUnlinkPurchaseOrder}
            disabled={!linkedTransaction}
            data-testid="purchase-orders-link-dialog-unlink-purchase-order-button"
          >
            <Icon name="link-slash" />
          </Button>
        </Tooltip>
      </Flex>
    );
  }
);

ActionsCell.displayName = "ActionsCell";

const COLUMNS: TableColumn<Line>[] = [
  {
    id: "billLine",
    name: <BillLineHeader />,
    width: "half",
    align: "start",
    render: (row, index) => <BillLineCell {...row} index={index} />,
    footer: () => <BillLineFooter />,
    highlight: "neutral",
  },
  {
    id: "purchaseOrderLine",
    name: <PurchaseOrderLineHeader />,
    width: "half",
    align: "start",
    render: (row, index) => <PurchaseOrderLineCell {...row} index={index} />,
    footer: () => <PurchaseOrderLineFooter />,
  },
  {
    id: "actions",
    width: 65,
    align: "start",
    render: (row, index) => <ActionsCell {...row} index={index} />,
  },
];

const schema = z.object({
  lines: z
    .array(
      z.object({
        id: idSchema,
        amount: z.number(),
        linkedTransaction: idSchema.nullish(),
      })
    )
    .superRefine((lines, ctx) => {
      const hasLinkedTransaction = lines.some(
        (line) => !!line.linkedTransaction
      );

      if (hasLinkedTransaction) return;

      lines.forEach((_, index) => {
        ctx.addIssue({
          path: [index, "linkedTransaction"],
          code: z.ZodIssueCode.custom,
          message:
            "At least one bill line must be linked to a purchase order line",
        });
      });
    }),
  isProportionallyDistributed: z.boolean(),
});

type Fields = z.input<typeof schema>;

const INITIAL_VALUES: Fields = {
  lines: [],
  isProportionallyDistributed: false,
};

const createBillLine = (
  line: Partial<Fields["lines"][number]> = {}
): Fields["lines"][number] => ({
  id: nanoid(),
  amount: 0,
  linkedTransaction: null,
  ...line,
});

export type PurchaseOrdersLinkDialogProps = Pick<
  ReturnType<typeof useDialog>,
  "hide" | "isVisible"
> & {
  onSubmit: (values: {
    linkedLines: Line[];
    removedLines: Line[];
    unlinkedLines: Line[];
  }) => void;
  purchaseOrder?: Parent;
};

export const PurchaseOrdersLinkDialog = memo(
  ({
    hide,
    onSubmit,
    isVisible,
    purchaseOrder,
  }: PurchaseOrdersLinkDialogProps) => {
    const tableRef = useRef<TableRef>(null);

    const bill = useSelector(minimalBillAndLinesSelector, isEqual);

    const isInPaymentStatus = useSelector(isInPaymentStatusBillSelector);

    const billLines = useDeepMemo(
      () =>
        bill.lines.filter(
          (line) =>
            !line.linkedTransaction ||
            purchaseOrder?.lines?.some(
              (purchaseOrderLine) =>
                purchaseOrderLine.id == line.linkedTransaction
            )
        ),
      [bill.lines, purchaseOrder?.lines]
    );

    const onClose = useEvent(() => {
      analytics.track("billLinkedPurchaseOrderClose", {
        billReviewStatus: bill?.reviewStatus,
        billId: bill?.id,
        purchaseOrderId: purchaseOrder?.id,
      });

      hide();
    });

    const { reset, setValue, register, ...form } = useForm<Fields>({
      schema,
      onSubmit: ({ lines }) => {
        const linkedLines = lines.filter((line) => line.linkedTransaction);

        const removedLines = billLines.filter(
          (billLine) => !lines.some((line) => billLine.id == line.id)
        );

        const unlinkedLines = lines.filter(
          (line) =>
            !line.linkedTransaction &&
            billLines.some(
              (billLine) => billLine.id == line.id && billLine.linkedTransaction
            )
        );

        analytics.track("billLinkedPurchaseOrderSubmit", {
          billReviewStatus: bill?.reviewStatus,
          billId: bill?.id,
          purchaseOrderId: purchaseOrder?.id,
          linkedLinesQuantity: linkedLines.length,
          removedLinesQuantity: removedLines.length,
          unlinkedLinesQuantity: unlinkedLines.length,
        });
        onSubmit({ linkedLines, removedLines, unlinkedLines });
      },
      initialValues: INITIAL_VALUES,
    });

    const enhancedPurchaseOrder = useDeepMemo(() => {
      const total = (purchaseOrder?.lines ?? []).reduce(
        (acc, line) => acc + line.amount,
        0
      );

      const lines = (purchaseOrder?.lines ?? []).map((line) => ({
        ...line,
        label: formatCurrency(line.amount ?? 0, { currencySign: true }),
        value: line.id?.toString() ?? "",
      }));

      return {
        id: purchaseOrder?.id,
        url: purchaseOrder?.url,
        lines,
        total,
        docNumber: purchaseOrder?.docNumber,
      };
    }, [purchaseOrder]);

    const billSubTotal = useMemo(() => {
      const lines = form.values.isProportionallyDistributed
        ? billLines
        : form.values.lines;

      return lines.reduce((acc, line) => acc + line.amount, 0);
    }, [billLines, form.values.lines, form.values.isProportionallyDistributed]);

    const canToggleProportionallyDistributed = useMemo(
      () => billLines.reduce((acc, line) => acc + line.amount, 0) > 0,
      [billLines]
    );

    const shouldAddBillLine =
      !canToggleProportionallyDistributed &&
      !isInPaymentStatus &&
      billLines.length === 0;

    const addBillLine = useEvent(() => {
      form.append("lines", createBillLine());
      tableRef.current?.scrollToIndex(form.values.lines.length);
    });

    const onAddBillLine = useEvent(() => {
      analytics.track("billLinkedPurchaseOrderAddBillLine", {
        billReviewStatus: bill?.reviewStatus,
        billId: bill?.id,
        purchaseOrderId: enhancedPurchaseOrder?.id,
      });

      addBillLine();
    });

    const distributeAmountProportionally =
      useCallback<DistributeAmountProportionallyHandler>(
        ({ lines, total }) => {
          const hasSomeLinkedTransaction = lines.some(
            (line) => line.linkedTransaction
          );

          const rawLines = lines.map((line) => {
            const linkedTransactionLine = enhancedPurchaseOrder.lines.find(
              (purchaseOrderLine) =>
                purchaseOrderLine.id == line.linkedTransaction
            );

            let amount = 0;

            if (linkedTransactionLine) {
              amount =
                enhancedPurchaseOrder.total > 0
                  ? total *
                    (linkedTransactionLine.amount / enhancedPurchaseOrder.total)
                  : 0;
            } else if (!hasSomeLinkedTransaction && lines.length > 0) {
              amount = total / lines.length;
            }

            return { ...line, amount };
          });

          const remainingAmount =
            total - rawLines.reduce((acc, line) => acc + line.amount, 0);

          let distributedLines = rawLines;

          if (Math.abs(remainingAmount) > 0.001) {
            const linkedTransactionCount = rawLines.filter(
              (line) => line.linkedTransaction
            ).length;

            if (linkedTransactionCount > 0) {
              const amount = remainingAmount / linkedTransactionCount;

              distributedLines = rawLines.map((line) =>
                line.linkedTransaction
                  ? { ...line, amount: line.amount + amount }
                  : line
              );
            }
          }

          const roundedLines = distributedLines.map((line) => ({
            ...line,
            amount: Math.round(line.amount * 100) / 100,
          }));

          const roundedTotal = roundedLines.reduce(
            (acc, line) => acc + line.amount,
            0
          );
          const roundingDifference = total - roundedTotal;

          if (Math.abs(roundingDifference) > 0.001) {
            const linesWithLinkedTransaction = roundedLines.filter(
              (line) => line.linkedTransaction
            );

            const targetLines =
              linesWithLinkedTransaction.length > 0
                ? linesWithLinkedTransaction
                : roundedLines;

            if (targetLines.length > 0) {
              const lastIndex = targetLines.length - 1;
              const lastLineIndex = roundedLines.findIndex(
                (line) => line.id == targetLines[lastIndex].id
              );

              if (lastLineIndex !== -1) {
                roundedLines[lastLineIndex].amount =
                  Math.round(
                    (roundedLines[lastLineIndex].amount + roundingDifference) *
                      100
                  ) / 100;
              }
            }
          }

          return roundedLines;
        },
        [enhancedPurchaseOrder.lines, enhancedPurchaseOrder.total]
      );

    const [isDefaultProportionallyDistributed] = useState(() => {
      const total = billLines.reduce((acc, line) => acc + line.amount, 0);

      return distributeAmountProportionally({ lines: billLines, total }).every(
        (line) =>
          billLines.find((billLine) => billLine.id == line.id)?.amount ===
          line.amount
      );
    });

    const removeBillLine = useEvent<RemoveBillLineHandler>((index) => {
      analytics.track("billLinkedPurchaseOrderRemoveBillLine", {
        billReviewStatus: bill?.reviewStatus,
        billId: bill?.id,
        purchaseOrderId: enhancedPurchaseOrder?.id,
      });

      form.remove("lines", index);
    });

    const addAllPoLines = useEvent(() => {
      analytics.track("billLinkedPurchaseOrderAddAllPoLines", {
        billReviewStatus: bill?.reviewStatus,
        billId: bill?.id,
        purchaseOrderId: enhancedPurchaseOrder?.id,
      });

      setValue(
        "isProportionallyDistributed",
        canToggleProportionallyDistributed
      );
      setValue(
        "lines",
        purchaseOrder?.lines.map((line) =>
          createBillLine({
            amount: form.values.isProportionallyDistributed ? line.amount : 0,
            linkedTransaction: line.id,
          })
        ) ?? []
      );
    });

    const unlinkPurchaseOrder = useEvent<UnlinkPurchaseOrderHandler>(
      (index) => {
        analytics.track("billLinkedPurchaseOrderUnlinkLine", {
          billReviewStatus: bill?.reviewStatus,
          billId: bill?.id,
          purchaseOrderId: enhancedPurchaseOrder?.id,
        });
        setValue(`lines.${index}.linkedTransaction`, null);
      }
    );

    useEffect(() => {
      const isProportionallyDistributed =
        canToggleProportionallyDistributed &&
        isDefaultProportionallyDistributed;

      reset({ lines: billLines, isProportionallyDistributed });
    }, [
      reset,
      billLines,
      isDefaultProportionallyDistributed,
      canToggleProportionallyDistributed,
    ]);

    useEffect(() => {
      if (shouldAddBillLine) addBillLine();
    }, [addBillLine, shouldAddBillLine]);

    useEffect(() => {
      if (!form.values.isProportionallyDistributed || isInPaymentStatus) return;

      setValue(
        "lines",
        distributeAmountProportionally({
          lines: form.values.lines,
          total: billSubTotal,
        })
      );
    }, [
      setValue,
      billSubTotal,
      form.values.lines,
      isInPaymentStatus,
      distributeAmountProportionally,
      form.values.isProportionallyDistributed,
    ]);

    return (
      <Dialog show={isVisible} variant="dialog" size="lg" onClose={onClose}>
        <DialogHeader>Link bill to PO</DialogHeader>
        <DialogContent>
          <Flex as="form" gap="xl" direction="column" {...form.props}>
            <Flex justify="space-between" gap="xl" align="center">
              {canToggleProportionallyDistributed ? (
                <Switch
                  label="Distribute bill lines amount proportionally"
                  disabled={isInPaymentStatus}
                  {...register({
                    name: "isProportionallyDistributed",
                    type: "boolean",
                    onChange: (value) => {
                      analytics.track(
                        "billLinkedPurchaseOrderProportionallyDistributedSwitch",
                        {
                          billReviewStatus: bill?.reviewStatus,
                          billId: bill?.id,
                          action: value ? "enable" : "disable",
                          purchaseOrderId: enhancedPurchaseOrder?.id,
                        }
                      );
                    },
                  })}
                />
              ) : (
                <Flex />
              )}
              <Flex gap="md">
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={onAddBillLine}
                  disabled={isInPaymentStatus}
                  data-testid="purchase-orders-link-dialog-add-bill-line-button"
                >
                  <Icon name="plus" />
                  Add a bill line
                </Button>
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={addAllPoLines}
                  disabled={isInPaymentStatus}
                  data-testid="purchase-orders-link-dialog-add-all-po-lines-button"
                >
                  <Icon name="plus" />
                  Add all PO lines
                </Button>
              </Flex>
            </Flex>
            <PurchaseOrdersLinkContext.Provider
              value={{
                bill: {
                  id: bill?.id,
                  url: bill?.url,
                  docNumber: bill?.docNumber,
                  total: billSubTotal,
                  lines: form.values.lines,
                  reviewStatus: bill?.reviewStatus,
                },
                register,
                purchaseOrder: enhancedPurchaseOrder,
                removeBillLine,
                hasMultipleLines: form.values.lines.length > 1,
                isInPaymentStatus,
                unlinkPurchaseOrder,
                isProportionallyDistributed:
                  form.values.isProportionallyDistributed,
              }}
            >
              <Table
                ref={tableRef}
                size="sm"
                data={form.values.lines}
                columns={COLUMNS}
                maxHeight="453px"
                data-testid="purchase-orders-link-dialog-table"
              />
            </PurchaseOrdersLinkContext.Provider>
          </Flex>
        </DialogContent>
        <DialogFooter>
          <Button
            block
            variant="text"
            color="neutral"
            onClick={onClose}
            data-testid="purchase-orders-link-dialog-cancel-button"
          >
            Cancel
          </Button>
          <Button
            block
            type="submit"
            form={form.id}
            data-testid="purchase-orders-link-dialog-save-button"
          >
            Save
          </Button>
        </DialogFooter>
      </Dialog>
    );
  }
);

PurchaseOrdersLinkDialog.displayName = "PurchaseOrdersLinkDialog";
