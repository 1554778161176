import type {
  BillListBalanceByCustomerResponse,
  BillListItemResponse,
} from "@api/bills";

import { groupBillListItemByVendor } from "../group-bill-list-item-by-vendor/group-bill-list-item-by-vendor";
import { parseListRowToFormValue } from "../parse-list-row-to-form-value/parse-list-row-to-form-value";

export const parseBillListToFormPaymentList = (
  billListItems: BillListItemResponse[],
  combinedPayments: boolean,
  billBalanceByCustomer: BillListBalanceByCustomerResponse,
  defaultLienWaiverTemplateUrl?: string
) => {
  if (combinedPayments) {
    return groupBillListItemByVendor(
      billListItems,
      billBalanceByCustomer,
      defaultLienWaiverTemplateUrl
    );
  }

  return billListItems.map((billListItem, index) => {
    const billOpenBalance = billBalanceByCustomer.find(
      (balance) => balance.id.toString() === billListItem.id.toString()
    );

    return {
      ...parseListRowToFormValue(
        billListItem,
        index,
        billOpenBalance?.openBalance || [],
        defaultLienWaiverTemplateUrl
      ),
      bills: [{ id: billListItem.id, url: billListItem.url }],
      openBalanceByCustomer: billOpenBalance?.openBalance || [],
    };
  });
};
