import { getCustomer, getCustomers } from "@api/customers";
import { handleErrors } from "@api/handle-errors";
import {
  type ActionFilter,
  type ActionType,
  type Filter,
  type FilterNameType,
  type JobDetail,
  type JobsState,
  type OptionType,
  type Received,
  type Response,
  type SearchedJobType,
  type SetJobsFilter,
  type State,
} from "@api/jobs";
import { rtkApi } from "@api/jobs";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type store } from "@store/index";

const PER_PAGE = 10;

const initialState: State = {
  activeJobs: {
    status: "idle",
    jobs: [],
    total: 0,
  },
  inactiveJobs: {
    status: "idle",
    jobs: [],
    total: 0,
  },
  job: {
    status: "idle",
    job: {
      display_name: "",
      display_name_without_company: "",
      url: "",
      id: 0,
      spent: 0,
      builder_revised_amount: 0,
      owners_revised_amount: 0,
      invoiced_amount: 0,
      builder_revised_remaining_amount: 0,
      invoiced_remaining_amount: 0,
      unpaid_bills: 0,
      undrawn_costs: 0,
      active: false,
      restricted_to_users: [],
      addresses: [],
      is_builder_budget_locked: false,
      categories_enabled: false,
      change_tracking_enabled: false,
      owners_amount_enabled: false,
      is_owner_budget_locked: false,
      budgetLines: [],
      invoiced: 0,
    } as JobDetail,
  },
  activeSearchedJobs: [],
  inactiveSearchedJobs: [],
  activeFilters: [],
  inactiveFilters: [],
  sortOption: "-most_recent_transaction_date",
  limit: PER_PAGE,
};

const updateJobs = (jobState: JobsState, response: Response) => {
  if (jobState.status === "pending") {
    jobState.status = "loaded";
    jobState.jobs = response.results;
    jobState.total = response.count;
  }
};

const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    jobsLoading: (state: State, action: PayloadAction<ActionType>) => {
      state[`${action.payload}Jobs`].status = "pending";
    },
    jobsReceived: (state: State, action: PayloadAction<Received>) => {
      updateJobs(
        state[`${action.payload.action}Jobs`],
        action.payload.response
      );
    },
    jobLoading: (state: State) => {
      state.job.status = "pending";
    },
    jobError: (state: State) => {
      state.job.status = "error";
    },
    jobReceived: (state: State, action: PayloadAction<JobDetail>) => {
      const jobState = state.job;
      if (jobState.status === "pending") {
        jobState.status = "loaded";
        jobState.job = { ...jobState.job, ...action.payload };
      }
    },
    setJobId: (state: State, action: PayloadAction<string>) => {
      state.job.job.id = Number(action.payload);
    },
    jobReset: (state: State) => {
      state.job = initialState.job;
    },
    setJobsFilter: (state: State, action: PayloadAction<SetJobsFilter>) => {
      const searchedJob: SearchedJobType = `${action.payload.action}SearchedJobs`;
      state[searchedJob] = action.payload.jobs;
      const filterName: FilterNameType = `${action.payload.action}Filters`;
      const filtersWithoutJobAndOffset = state[filterName].filter(
        (filter) => filter.index !== "id" && filter.index !== "offset"
      );
      const jobsFilter = action.payload.jobs.map((job) => ({
        index: "id",
        value: job.id.toString(),
      }));
      state[filterName] = [
        ...filtersWithoutJobAndOffset,
        ...jobsFilter,
        { index: "offset", value: 0 },
      ];
    },
    updateLimit: (state: State, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    updateFilter: (state: State, action: PayloadAction<ActionFilter>) => {
      const filterName: FilterNameType = `${action.payload.action}Filters`;
      const rowToUpdate: Filter | undefined = state[filterName].find(
        (filter) => filter.index === action.payload.filter.index
      );
      if (rowToUpdate) {
        rowToUpdate.value = action.payload.filter.value;
      }
    },
    removeFilter: (state: State, action: PayloadAction<ActionFilter>) => {
      const filterName: FilterNameType = `${action.payload.action}Filters`;
      state[filterName] = state[filterName].filter(
        (filter) =>
          filter.value !== action.payload.filter.value ||
          filter.index !== action.payload.filter.index
      );
    },
    resetFilters: (state: State, action: PayloadAction<number>) => {
      state.inactiveFilters = [];
      state.activeFilters = [];
      (["active", "inactive"] as ActionType[]).forEach(
        (actionType: ActionType) => {
          const filterName: FilterNameType = `${actionType}Filters`;
          state[filterName] = [
            ...state[filterName],
            { index: "realm", value: action.payload.toString() },
          ];
          state[filterName] = [
            ...state[filterName],
            { index: "offset", value: 0 },
          ];
          state[filterName] = [
            ...state[filterName],
            ...state[`${actionType}SearchedJobs`].map((job) => ({
              index: "id",
              value: job.id.toString(),
            })),
          ];
          state[filterName] = [
            ...state[filterName],
            {
              index: "order_by",
              value: state.sortOption,
            },
          ];
        }
      );
    },
    changeSortOption: (state: State, action: PayloadAction<OptionType>) => {
      state.sortOption = action.payload;
    },
    setJobBudgetLockedStatus: (
      state: State,
      action: PayloadAction<boolean>
    ) => {
      state.job.job.is_builder_budget_locked = action.payload;
    },
    setJobOwnersBudgetLockedStatus: (
      state: State,
      action: PayloadAction<boolean>
    ) => {
      state.job.job.is_owner_budget_locked = action.payload;
    },
    setCategoriesEnabled: (state: State, action: PayloadAction<boolean>) => {
      state.job.job.categories_enabled = action.payload;
    },
  },
});

export const {
  jobsLoading,
  jobsReceived,
  jobLoading,
  jobError,
  jobReceived,
  jobReset,
  setJobsFilter,
  updateFilter,
  removeFilter,
  updateLimit,
  resetFilters,
  changeSortOption,
  setJobBudgetLockedStatus,
  setJobOwnersBudgetLockedStatus,
  setCategoriesEnabled,
} = jobsSlice.actions;

const jobsAbortController: Record<ActionType, AbortController | undefined> = {
  active: undefined,
  inactive: undefined,
};

export const fetchJobs =
  (action: ActionType, filters: Filter[]) =>
  async (dispatch: typeof store.dispatch) => {
    const abortController = jobsAbortController[action];

    if (abortController) abortController.abort();

    jobsAbortController[action] = new AbortController();

    dispatch(jobsLoading(action));
    try {
      const enhancedFilters = [
        ...filters,
        { index: "active_no_children", value: action === "active" },
      ];

      const data = await getCustomers({
        filters: enhancedFilters,
        signal: jobsAbortController[action]?.signal,
      });

      const limit = Number(
        enhancedFilters.find((item) => item.index === "limit")?.value ??
          PER_PAGE
      );

      const offset = Number(
        enhancedFilters.find((item) => item.index === "offset")?.value ?? 0
      );

      /**
       * Sometimes after doing some batch actions, the results is empty because we
       * moved all the jobs to another status, so we need to adjust the offset
       * to fetch the previous page.
       */
      if (data.results.length === 0 && offset > 0) {
        return dispatch(
          updateFilter({
            action,
            filter: { index: "offset", value: offset - limit },
          })
        );
      }

      dispatch(jobsReceived({ action, response: data }));
    } catch (e) {
      handleErrors(e);
    }
  };

export const fetchJob =
  (id: string) => async (dispatch: typeof store.dispatch) => {
    try {
      dispatch(jobsSlice.actions.setJobId(id));
      dispatch(jobLoading());
      const data = await getCustomer(id);
      dispatch(jobReceived(data));
      dispatch(
        rtkApi.util.updateQueryData(
          "getCustomer",
          { customerId: id },
          (draft) => {
            Object.assign(draft, data);
          }
        )
      );
    } catch {
      dispatch(jobError());
    }
  };

export const { reducer } = jobsSlice;
