import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import {
  Alert,
  AlertContent,
  Button,
  ComboBox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  Link,
  Loader,
  Table,
  type TableColumn,
  type TableEmptyState,
  type TableHeaderAddon,
  type TablePaginationAddon,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useDialog, useEvent } from "@adaptive/design-system/hooks";
import { suffixify } from "@adaptive/design-system/utils";
import {
  capitalOSApi,
  type CapitalOSCard,
  type UpdateCapitalOSCardPayload,
} from "@api/capital-os";
import { CardDetails, Onboarding } from "@capitalos/react";
import {
  CostCodeAccountComboBox,
  type CostCodeAccountComboBoxProps,
} from "@components/cost-code-account-combobox";
import { useAccountsSimplified } from "@hooks/use-accounts-simplified";
import { useCapitalOSCards } from "@hooks/use-capital-os-cards";
import { useCustomersSimplified } from "@hooks/use-customers-simplified";
import { useClientAction } from "@store/user";
import { toggleChatVisibility } from "@store/user/slice";
import * as analytics from "@utils/analytics";
import { capitalize } from "@utils/capitalize";
import { formatCard } from "@utils/format-card";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";

import { AccountCreateForm } from "./../account-select-dialog/account-create-form";
import { CardsAdaptiveAddCardButton } from "./cards-adaptive-add-card-button";
import { useCardsAdaptiveContext } from "./cards-adaptive-context";

type CurriedOnChangeCapitalOSCard = (params: {
  row: CapitalOSCard;
  field: "user" | "payment-account" | "customer" | "cost_code";
}) => (value: string) => Promise<void>;

const PaymentAccountName = memo(() => (
  <Flex gap="sm">
    <Text weight="bold">Payment account</Text>
    <Tooltip
      size="sm"
      as={Icon}
      name="info-circle"
      message={`Only transactions with an assigned payment\n account will be pulled into Adaptive`}
    />
  </Flex>
));

PaymentAccountName.displayName = "PaymentAccountName";

const CustomerName = memo(() => (
  <Flex gap="sm">
    <Text weight="bold">Job</Text>
    <Tooltip
      size="sm"
      as={Icon}
      name="info-circle"
      message={`Transactions will get automatically\n assigned to this job in Adaptive`}
    />
  </Flex>
));

CustomerName.displayName = "CustomerName";

const CostCodeAccountName = memo(() => (
  <Flex gap="sm">
    <Text weight="bold">Default code</Text>
    <Tooltip
      size="sm"
      as={Icon}
      name="info-circle"
      message={`Transactions will get automatically\n assigned to this cost code / account in Adaptive`}
    />
  </Flex>
));

CostCodeAccountName.displayName = "CostCodeAccountName";

const COSTCODE_ACCOUNT_COMBOBOX_FILTERS: CostCodeAccountComboBoxProps<false>["filters"] =
  ["budgetCode", "costCodeAccount"];

const CostCodeAccountColumn = memo(
  ({
    row,
    ...props
  }: { row: CapitalOSCard } & CostCodeAccountComboBoxProps<false>) => {
    const customerId = row.customer?.url
      ? parseRefinementIdFromUrl(row.customer.url)
      : undefined;

    const selectedOption: CostCodeAccountComboBoxProps<false>["value"] =
      useMemo(() => {
        const selectedItemAccount = row.item || row.account || "";
        return selectedItemAccount
          ? {
              value: selectedItemAccount.url || "",
              label: selectedItemAccount.displayName || "",
              parent: selectedItemAccount.parent ?? undefined,
            }
          : "";
      }, [row.item, row.account]);

    const costCodeAccountQueryFilters = useMemo(() => {
      return {
        accountFilters: {
          customerId,
        },
        costCodeFilters: {
          customerId,
        },
      };
    }, [customerId]);

    return (
      <CostCodeAccountComboBox
        size="sm"
        label=""
        messageVariant="hidden"
        value={selectedOption}
        filters={COSTCODE_ACCOUNT_COMBOBOX_FILTERS}
        data-testid={suffixify("bank-table", "role")}
        {...costCodeAccountQueryFilters}
        {...props}
      />
    );
  }
);

CostCodeAccountColumn.displayName = "CostCodeAccountColumn";

const ActionsColumn = memo(({ capitalOsId }: CapitalOSCard) => {
  const dialog = useDialog({ lazy: true });

  const { setHasError } = useCardsAdaptiveContext();

  const onError = useEvent(() => {
    setHasError(true);
    dialog.hide();
  });

  return (
    <>
      <Button size="sm" variant="ghost" color="neutral" onClick={dialog.show}>
        View card
      </Button>
      {dialog.isRendered && (
        <Dialog
          size="auto"
          variant="drawer"
          show={dialog.isVisible}
          onClose={dialog.hide}
        >
          <DialogHeader>Manage card</DialogHeader>
          <DialogContent>
            <Flex width="388px">
              <CardDetails
                cardId={capitalOsId}
                onError={onError}
                loadingComponent={<Loader />}
              />
            </Flex>
          </DialogContent>
          <DialogFooter>
            <Button
              variant="text"
              color="neutral"
              size="lg"
              onClick={dialog.hide}
            >
              Close
            </Button>
          </DialogFooter>
        </Dialog>
      )}
    </>
  );
});

ActionsColumn.displayName = "ActionsColumn";

export const CardsAdaptive = memo(() => {
  const dispatch = useDispatch();

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { currentClient } = useClientAction();

  const [selectedRow, setSelectedRow] = useState<CapitalOSCard>();

  const { hasError, isLoading, userRole, userId, setHasError } =
    useCardsAdaptiveContext();

  const { refetch: refetchAccounts, ...accounts } = useAccountsSimplified({
    filters: {
      only_payment_accounts: true,
      can_accounts_link_to_lines_desktop: true,
    },
  });

  const customers = useCustomersSimplified();

  const accountCreateDialog = useDialog({ lazy: true });

  const { show: showAccountCreateDialog } = accountCreateDialog;

  const {
    data: capitalOSCards,
    isLoading: capitalOSCardsIsLoading,
    updateCard,
    pagination: capitalOSCardsPagination,
    sort: capitalOSCardsSort,
  } = useCapitalOSCards();

  const invalidateCapitalOs = useEvent(() => {
    dispatch(capitalOSApi.util.invalidateTags(["CapitalOSToken"]));

    /**
     * It could take a while for sync to complete, so we wait for 1 second before invalidating the tag
     */
    timeoutRef.current = setTimeout(() => {
      dispatch(capitalOSApi.util.invalidateTags(["CapitalOSCards"]));
    }, 1000);
  });

  const retry = useEvent(() => {
    window.location.reload();
  });

  const curriedOnChangeCapitalOSCard =
    useCallback<CurriedOnChangeCapitalOSCard>(
      ({ row, field }) =>
        async (value) => {
          const payload: UpdateCapitalOSCardPayload = { id: row.id };

          if (field === "user") {
            payload.user = value;
          } else if (field === "payment-account") {
            payload.paymentAccount = value;
          } else if (field === "customer") {
            payload.customer = value;
          } else if (field === "cost_code") {
            if (value.includes("item")) {
              payload.item = value;
            } else {
              payload.account = value;
            }
          }

          await updateCard(payload);

          if (field === "user") {
            toast.success("Successfully set user!");
          } else if (field === "payment-account") {
            toast.success("Successfully set payment account!");
          } else if (field === "customer") {
            toast.success("Successfully set job!");
          } else if (field === "cost_code") {
            if (value.includes("item")) {
              toast.success("Successfully set cost code!");
            } else {
              toast.success("Successfully set account!");
            }
          }
        },
      [updateCard]
    );

  const openChat = useEvent(() => {
    dispatch(toggleChatVisibility(true));
    analytics.track("chatOpen", { source: "adaptive-card-loading-fail" });
  });

  const capitalOSCardsTableColumns = useMemo<TableColumn<CapitalOSCard>[]>(
    () => [
      {
        id: "user",
        name: "Card holder",
        sortable: "asc",
        width: 150,
        render: (row) => (
          <Text truncate>
            {row.user?.fullName ||
              `${row.cardHolderFirstName} ${row.cardHolderLastName}`}
          </Text>
        ),
      },
      {
        id: "card_nickname",
        sortable: true,
        name: "Nickname",
        width: 150,
        render: (row) => <Text truncate>{row.cardNickname}</Text>,
      },
      {
        id: "mask",
        name: "Card number",
        sortable: true,
        width: 160,
        render: (row) => formatCard({ mask: row.mask }),
      },
      {
        id: "status",
        name: "Status",
        sortable: true,
        width: 150,
        render: (row) => capitalize(row.status),
      },
      {
        id: "payment_account",
        name: <PaymentAccountName />,
        sortable: "asc",
        render: (row) => (
          <ComboBox
            size="sm"
            flip
            action={{
              icon: "plus",
              onClick: () => {
                setSelectedRow(row);
                showAccountCreateDialog();
              },
              children: "Add new",
            }}
            onChange={curriedOnChangeCapitalOSCard({
              row,
              field: "payment-account",
            })}
            data={accounts.data}
            loading={accounts.status === "loading"}
            messageVariant="hidden"
            value={row.paymentAccount?.url || ""}
            data-testid={suffixify("bank-table", "role")}
          />
        ),
      },
      {
        id: "customer",
        name: <CustomerName />,
        sortable: "asc",
        render: (row) => (
          <ComboBox
            size="sm"
            flip
            onChange={curriedOnChangeCapitalOSCard({
              row,
              field: "customer",
            })}
            data={customers.data}
            loading={customers.status === "loading"}
            messageVariant="hidden"
            value={row.customer?.url || ""}
            data-testid={suffixify("bank-table", "role")}
          />
        ),
      },
      {
        id: "cost_code_account",
        name: <CostCodeAccountName />,
        sortable: "asc",
        render: (row) => (
          <CostCodeAccountColumn
            row={row}
            onChange={curriedOnChangeCapitalOSCard({
              row,
              field: "cost_code",
            })}
          />
        ),
      },
      {
        id: "actions",
        name: "Action",
        render: (row) => <ActionsColumn {...row} />,
      },
    ],
    [
      accounts.data,
      customers.data,
      accounts.status,
      customers.status,
      showAccountCreateDialog,
      curriedOnChangeCapitalOSCard,
    ]
  );

  const paginationAddon = useMemo<TablePaginationAddon>(
    () => ({
      page: capitalOSCardsPagination.page,
      total: capitalOSCards?.count ?? 0,
      perPage: capitalOSCardsPagination.perPage,
      onChange: capitalOSCardsPagination.setPage,
      onPerPageChange: (perPage) => {
        capitalOSCardsPagination.setPage(0);
        capitalOSCardsPagination.setPerPage(perPage);
        analytics.track("perPageLimitChange", {
          location: "cards-capital-os-table",
          limit: perPage,
        });
      },
    }),
    [capitalOSCardsPagination, capitalOSCards?.count]
  );

  const emptyStateAddon = useMemo<TableEmptyState>(
    () => ({
      title: "You don't have any cards yet",
      subtitle:
        userRole === "manage"
          ? "Create a card to pull transactions into Adaptive"
          : "Ask your company admin to create a card for you",
      action: userRole === "manage" ? <CardsAdaptiveAddCardButton /> : null,
    }),
    [userRole]
  );

  const hasCards = capitalOSCards?.results?.length ?? 0 > 0;

  const onError = useEvent(() => {
    setHasError(true);
  });

  const headerAddon = useMemo<TableHeaderAddon>(
    () => ({ hide: !hasCards }),
    [hasCards]
  );

  useEffect(() => {
    refetchAccounts();
  }, [refetchAccounts, currentClient]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <>
      <Flex direction="column">
        {hasError ? (
          <Alert variant="error">
            <AlertContent>
              Error loading Adaptive cards. You can{" "}
              <Link as="button" type="button" onClick={retry}>
                Try again
              </Link>{" "}
              or{" "}
              <Link as="button" type="button" onClick={openChat}>
                Contact support
              </Link>{" "}
              to get help.
            </AlertContent>
          </Alert>
        ) : !userId || isLoading ? (
          <Loader position="fixed" />
        ) : (
          <Flex gap="xl" direction="column" align="flex-end">
            {userRole === "register" ? (
              <Onboarding
                onDone={invalidateCapitalOs}
                onError={onError}
                loadingComponent={
                  <Flex width="full">
                    <Loader />
                  </Flex>
                }
              />
            ) : (
              <Table
                id="cards-capital-os-table"
                size="sm"
                sort={capitalOSCardsSort}
                data={capitalOSCards?.results}
                header={headerAddon}
                columns={capitalOSCardsTableColumns}
                loading={capitalOSCardsIsLoading}
                emptyState={emptyStateAddon}
                pagination={paginationAddon}
              />
            )}
          </Flex>
        )}
      </Flex>
      {accountCreateDialog.isRendered && (
        <Dialog
          show={accountCreateDialog.isVisible}
          onClose={accountCreateDialog.hide}
          variant="dialog"
        >
          <AccountCreateForm
            dialog={accountCreateDialog}
            variant="single"
            initialAccountType="Liability.CreditCard.CreditCard"
            isCreditCard={true}
            isBankAccount={false}
            onCreatedAccountChange={(account) => {
              refetchAccounts();

              if (selectedRow) {
                const handler = curriedOnChangeCapitalOSCard({
                  row: selectedRow as CapitalOSCard,
                  field: "payment-account",
                });
                handler(account.url);
              }

              setSelectedRow(undefined);
            }}
          />
        </Dialog>
      )}
    </>
  );
});

CardsAdaptive.displayName = "CardsAdaptive";
