import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
  Button,
  ComboBox,
  Flex,
  Icon,
  Loader,
  Tooltip,
} from "@adaptive/design-system";
import { useDialog, useVisibilityChange } from "@adaptive/design-system/hooks";
import { useUserInfo } from "@store/user";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";

import {
  useGetLienWaiverTemplatesQuery,
  useGetLienWaiverTypesListQuery,
  useLazyRenderBillLienWaiverPdfQuery,
} from "../api/api";
import {
  LIEN_WAIVER_NOT_REQUIRED_STATUS,
  STRINGS,
  TEMPLATE_NOT_REQUESTED_ID,
} from "../constants/constants";
import type { AmountByCustomer, MissingFields } from "../types";
import { formatMissingFieldsToPayload } from "../utils/format-missing-fields";

import { type BillLienWaiverFieldProps } from "./bill-lien-waiver-field";
import { BillLienWaiverPreviewDrawer } from "./bill-lien-waiver-preview-drawer";

type LienWaiverTemplateComboboxProps = {
  customers?: AmountByCustomer[];
  billIds?: string[];
  vendor: string;
  size?: "sm" | "md";
  errorMessage?: string;
} & Pick<
  BillLienWaiverFieldProps,
  "value" | "onChange" | "paymentAmount" | "netPaymentAmount" | "isReadOnly"
>;

export const LienWaiverTemplateCombobox = ({
  customers,
  billIds,
  value,
  onChange,
  paymentAmount,
  netPaymentAmount,
  isReadOnly,
  errorMessage,
  vendor,
  size = "md",
}: LienWaiverTemplateComboboxProps) => {
  const previewDrawer = useDialog({ lazy: true });

  const { data: lienWaiverTypesData, isLoading: lienWaiverTypesLoading } =
    useGetLienWaiverTypesListQuery();

  const valueRef = useRef<string | null>(null);

  const {
    data: lienWaiverTemplatesData,
    isLoading: lienWaiverTemplatesLoading,
  } = useGetLienWaiverTemplatesQuery({ withStatus: true });

  const { canManageLienWaiverRequest } = useUserInfo();

  const isDataLoading = lienWaiverTypesLoading || lienWaiverTemplatesLoading;

  const templatesData = useMemo(
    () =>
      lienWaiverTemplatesData?.map((template) => ({
        label:
          lienWaiverTypesData?.results?.find(
            (type) => type.url === template.type
          )?.name || template.type,
        value: template.url || TEMPLATE_NOT_REQUESTED_ID,
      })) || [],
    [lienWaiverTemplatesData, lienWaiverTypesData?.results]
  );

  const isValidValue =
    !!value &&
    !LIEN_WAIVER_NOT_REQUIRED_STATUS.some((status) => status === value);

  const isEditAction = templatesData.length > 1;

  const [renderLienWaiverPDF] = useLazyRenderBillLienWaiverPdfQuery();

  const enhancedOnChange = useCallback(
    async (value: string) => {
      if (valueRef.current !== value) {
        valueRef.current = value;
      }

      let missingFields: MissingFields = { isValid: true };

      if (
        value &&
        !LIEN_WAIVER_NOT_REQUIRED_STATUS.some((status) => status === value)
      ) {
        await Promise.all(
          (customers || []).map(async (customer) => {
            try {
              const data = await renderLienWaiverPDF({
                billIds,
                lienWaiverTemplateId: parseRefinementIdFromUrl(value)!,
                paymentAmount: paymentAmount,
                customerId: parseRefinementIdFromUrl(customer.customer)!,
              }).unwrap();
              missingFields = formatMissingFieldsToPayload({
                data,
                customer: customer.customer,
                vendor: vendor || "",
                previousMissingFields: missingFields,
              });
            } catch {
              missingFields = formatMissingFieldsToPayload({
                customer: customer.customer,
                vendor: vendor || "",
                previousMissingFields: missingFields,
              });
            }
          })
        );
      }
      onChange?.(value, { missingFields });
    },
    [billIds, customers, onChange, vendor, paymentAmount, renderLienWaiverPDF]
  );

  useVisibilityChange((isVisible) => {
    if (isVisible && !isReadOnly && isValidValue) {
      enhancedOnChange(value);
    }
  });

  useEffect(() => {
    if (valueRef.current !== value && isValidValue) {
      enhancedOnChange(value);
    }
  }, [value, isValidValue, enhancedOnChange]);

  if (isDataLoading) {
    return (
      <Flex width="full" maxWidth="420px" align="center" justify="center">
        <Loader size="xl" />
      </Flex>
    );
  }

  return (
    <>
      <Flex width="full" gap="lg">
        <ComboBox
          value={value}
          onChange={enhancedOnChange}
          data={templatesData}
          messageVariant={!errorMessage ? "hidden" : "relative"}
          action={{
            icon: isEditAction ? "pen" : "plus",
            children: isEditAction
              ? STRINGS.SUPPORT_EDIT_TEMPLATES
              : STRINGS.SUPPORT_ADD_TEMPLATES,
            onClick: () => window.open("/settings/company/general", "_blank"),
          }}
          disabled={isDataLoading || !canManageLienWaiverRequest || isReadOnly}
          size={size}
          errorMessage={errorMessage}
          data-testid="lien-waiver-combobox"
        />
        {isValidValue && (
          <Tooltip message={STRINGS.VIEW_LIEN_WAIVER}>
            <Button
              color="neutral"
              variant="ghost"
              onClick={previewDrawer.show}
              data-testid="view-lien-waiver-button"
              size={size}
            >
              <Icon name="eye" />
            </Button>
          </Tooltip>
        )}
      </Flex>
      {previewDrawer.isRendered && value && canManageLienWaiverRequest && (
        <BillLienWaiverPreviewDrawer
          show={previewDrawer.isVisible}
          onClose={previewDrawer.hide}
          value={value}
          netPaymentAmount={netPaymentAmount}
          paymentAmount={paymentAmount}
          billIds={billIds}
          customers={customers}
        />
      )}
    </>
  );
};
