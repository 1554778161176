import {
  arraySchema,
  commentSchema,
  currencySchema,
  dateSchema,
  decimalAmountSchema,
  idSchema,
  stringOrEmptySchema,
} from "@utils/schema";
import { vendorSimplifiedResponseSchema } from "@vendors/api";
import { z } from "zod";

import { errorSchema } from "../../errors";

export const itemSchema = z.object({
  id: z.number(),
  displayName: z.string(),
  parent: z.string().url().nullable(),
  url: z.string().url(),
});

const categorySchema = z.object({
  id: idSchema,
  url: z.string().url(),
  displayName: z.string(),
});

export const accountSchema = z.object({
  displayName: z.string().nullish(),
  url: z.string().nullish(),
  id: z.number().nullish(),
  isPaymentAccount: z.boolean().nullish(),
  fullyQualifiedCategory: z.string().nullish(),
  parent: z.string().nullish(),
});

export const jobCostMethodSchema = z.object({
  id: z.number(),
  displayName: z.string(),
  parent: z.string().url().nullish(),
  parentLabel: z.string().nullish(),
  disabled: z.boolean().nullish(),
  description: z.string().nullish(),
  url: z.string().url(),
});

export const budgetMarkupSchema = z.object({
  id: idSchema,
  name: z.string().optional().nullish(),
  variant: z
    .enum(["error", "success", "neutral", "warning", "info"])
    .optional(),
  value: decimalAmountSchema.nullish(),
  ownersValue: decimalAmountSchema.nullish(),
  isSeparateLine: z.boolean().nullish(),
  markupType: z.enum(["percentage", "fixed_amount"]),
  jobCostMethod: jobCostMethodSchema,
});

export const budgetLineItemsSchema = z.object({
  id: idSchema,
  builderAmount: currencySchema,
  changeAmount: currencySchema,
  externalChangeAmount: currencySchema,
  builderRevisedAmount: currencySchema,
  builderRemainingAmount: currencySchema,
  markups: z.array(budgetMarkupSchema),
  jobCostMethod: jobCostMethodSchema,
  ownersAmount: currencySchema,
  ownersRevisedAmount: currencySchema,
  invoicedRemainingAmount: currencySchema,
  spent: currencySchema,
  invoicedAmount: currencySchema,
  unpaidBills: currencySchema,
  undrawnCosts: currencySchema,
  sourceType: z.string(),
  commentsCount: z.number(),
  category: categorySchema.nullish(),
  hasCosts: z.boolean(),
  hasChanges: z.boolean(),
  hasInvoices: z.boolean(),
  hasPurchaseOrders: z.boolean(),
  purchaseOrderAmount: currencySchema,
  purchaseOrderBudgetAvailable: currencySchema,
  purchaseOrderOpenBalance: currencySchema,
});

const parentSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  docNumber: z.string().nullish(),
  reviewStatus: z.string(),
  humanReadableType: z.string(),
  date: z.string(),
});

export const transactionLineSchema = z.object({
  id: idSchema,
  url: z.string().url().nullish(),
  type: z.string(),
  item: itemSchema.nullish(),
  jobCostMethod: jobCostMethodSchema.nullish(),
  order: z.number().nullish(),
  realm: z.string().url().nullish(),
  amount: currencySchema,
  parent: parentSchema.nullish(),
  status: z.string(),
  account: accountSchema.nullish(),
  taxAmount: currencySchema,
  totalAmount: currencySchema,
  forceClose: z.boolean(),
  description: stringOrEmptySchema,
  isAVariance: z.boolean(),
  billableStatus: z.enum(["Billable", "NotBillable", "HasBeenBilled"]),
  humanReadableType: z.string().nullish(),
});

export const invoiceSchema = z.object({
  id: idSchema,
  docNumber: z.string().nullish(),
  reviewStatus: z.string(),
  url: z.string().url().nullish(),
  humanReadableType: z.string().nullish(),
  errors: errorSchema.array(),
  publishedToQuickbooks: z.boolean(),
});

export const drawnToDateSchema = z.object({
  id: idSchema,
  vendor: vendorSimplifiedResponseSchema.nullish(),
  url: z.string().url(),
  item: itemSchema.nullish(),
  itemAccount: accountSchema.nullish(),
  jobCostMethod: jobCostMethodSchema.nullish(),
  amount: currencySchema,
  isExtra: z.boolean().nullish(),
  description: stringOrEmptySchema.nullish(),
  transactionLine: transactionLineSchema.nullish(),
  invoice: invoiceSchema,
});

export const budgetLineItemsResponseSchema = z.array(budgetLineItemsSchema);

export const budgetLineResponseSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  comments: arraySchema(commentSchema),
  customer: z.object({
    id: z.number(),
    url: z.string().url(),
  }),
  jobCostMethod: jobCostMethodSchema,
});

export const saveBudgetLineItemsResponseSchema = z.array(budgetLineItemsSchema);

/*
  TODO: migrate budgets to getBudgetLinesV2 types and endpoints.
*/
export const leafBudgetLineSchema = budgetLineItemsSchema.extend({
  item: z.nullable(itemSchema),
  account: z.nullable(accountSchema),
});

export const budgetLineParentBaseSchema = z.object({
  item: z.nullable(itemSchema),
  account: z.nullable(accountSchema),
  totalSpent: currencySchema,
  totalUnpaidBills: currencySchema,
  id: idSchema,
});

export const budgetLineParentSchema = budgetLineParentBaseSchema.extend({
  data: z.lazy(() =>
    z.array(
      z.union([budgetLineParentBaseSchema, leafBudgetLineSchema]).optional()
    )
  ),
});

export const budgetLineSchema = z.union([
  budgetLineParentSchema,
  leafBudgetLineSchema,
]);
export const budgetLinesResponseSchema = z.array(budgetLineSchema);

export const paginatedBaseSchema = z.object({
  next: z.string().url().nullish(),
  count: z.number(),
  previous: z.string().url().nullish(),
});

export const customerItemLineSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  vendor: vendorSimplifiedResponseSchema,
  parent: z.object({
    id: idSchema,
    url: z.string().url(),
    doc_number: z.string(),
    human_readable_type: z.string(),
    review_status: z.string().nullish(),
    human_readable_review_status: z.string().nullish(),
  }),
});

export const customerItemLinesResponseSchema = paginatedBaseSchema.extend({
  results: z.array(customerItemLineSchema),
});

export const drawnToDateResponseSchema = paginatedBaseSchema.extend({
  results: z.array(drawnToDateSchema),
});

export const storeCustomerCategoryResponseSchema = categorySchema;

export const getCustomerCategoriesResponseSchema = arraySchema(
  categorySchema
).transform((categories) =>
  categories.map(({ id, displayName }) => ({ label: displayName, value: id }))
);

export const linkedInvoicesResponseSchema = z.object({
  invoices: arraySchema(
    z.object({
      id: idSchema,
      docNumber: z.string(),
      customerId: idSchema,
      humanReadableType: z.enum(["Draw"]),
      url: z.string().url(),
    })
  ),
});

export const purchaseOrderLinesResponseSchema = z.object({
  count: z.number(),
  results: arraySchema(
    z
      .object({
        item: z.object({ displayName: z.string() }).nullish(),
        vendor: z.object({ displayName: z.string() }).nullish(),
        parent: z.object({
          id: idSchema,
          date: dateSchema,
          docNumber: z.string(),
          latestPoSignatureRequest: z
            .object({
              status: z.string(),
              createdAt: dateSchema,
            })
            .nullish(),
        }),
        account: z.object({ displayName: z.string() }).nullish(),
        balance: currencySchema,
        totalAmount: currencySchema,
        description: z.string(),
      })
      .transform(({ item, vendor, account, parent, ...rest }) => ({
        date: parent.date,
        vendor: vendor?.displayName ?? "",
        docNumber: parent.docNumber,
        jobCostMethod: item?.displayName || account?.displayName || "",
        purchaseOrderId: parent.id,
        latestPoSignatureRequest: parent.latestPoSignatureRequest,
        ...rest,
      }))
  ),
  totalAmount: currencySchema,
  totalBalance: currencySchema,
});

export const purchaseOrderLinesPayloadSchema = z.object({
  limit: z.number(),
  offset: z.number(),
  itemId: idSchema.nullish(),
  ordering: z.string(),
  accountId: idSchema.nullish(),
  customerId: idSchema,
  categoryId: idSchema.nullish(),
  withChildren: z.boolean().nullish(),
});
