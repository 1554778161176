import React from "react";
import { type TableColumn } from "@adaptive/design-system";
import { sum } from "@utils/sum";
import { sumBy } from "@utils/sumBy";

import {
  type DynamicBalanceVendorCredit,
  type GetVendorCreditsColumnsHandler,
} from "../../types";

import {
  AmountCell,
  BillableStatusCell,
  DateCreatedCell,
  JobsCell,
  OpenBalanceCell,
  RefNumberCell,
  SubtotalCell,
} from "./table-cells";

export const getColumns: GetVendorCreditsColumnsHandler = () => {
  const columns: TableColumn<DynamicBalanceVendorCredit>[] = [
    {
      id: "docNumber",
      name: "Ref #",
      visibility: "always-visible",
      width: 140,
      sortable: true,
      render: (row) => <RefNumberCell {...row} />,
    },
    {
      id: "createdAt",
      name: "Date created",
      sortable: "asc",
      width: 160,
      render: (row) => <DateCreatedCell {...row} />,
    },
    {
      id: "openBalance",
      name: "Open balance",
      textAlign: "right",
      sortable: true,
      width: 150,
      render: (row) => <OpenBalanceCell {...row} />,
      footer: (data) => {
        const totalApplied = sumBy(data, "appliedAmount");

        const openBalance = sumBy(data, "currentOpenBalance");

        const remainingBalance = sum(openBalance, -totalApplied);

        return <SubtotalCell subtotal={remainingBalance} />;
      },
    },
    {
      id: "amount",
      name: "Amount applied to payment",
      textAlign: "right",
      width: 150,
      render: (row) => <AmountCell {...row} />,
      footer: (data) => {
        const totalApplied = sumBy(data, "appliedAmount");

        return <SubtotalCell subtotal={totalApplied} />;
      },
    },
    {
      id: "jobs",
      name: "Jobs",
      width: 240,
      render: (row) => <JobsCell {...row} />,
    },
    {
      id: "billableStatus",
      name: "Billable status",
      width: 180,
      render: (row) => <BillableStatusCell {...row} />,
    },
  ];

  return columns;
};
