import React, { memo } from "react";
import { Button, Flex, Switch } from "@adaptive/design-system";
import { useDeepMemo, useEvent } from "@adaptive/design-system/hooks";
import { TableFilterControls } from "@components/table-filter";
import { useUsersSimplified } from "@hooks/use-users-simplified";
import { useClientSettings } from "@store/user";

import {
  ARCHIVED_VALID_STATUS,
  BILLABLE_STATUS_FILTER,
  DRAFT_STATUS_FILTER,
  DRAW_STATUS_FILTER,
  LIEN_WAIVER_STATUS_FILTER,
  LIEN_WAIVER_TEMPLATE_TYPE_FILTER,
  PAYMENT_STATUS_FILTER,
  STATUS_FILTER,
} from "../../constants";

const RawBillsTabHeader = ({
  status,
  filters,
  children,
  switchValue,
  onSwitchChange,
  onFiltersChange,
}) => {
  const { canManageLienWaivers } = useClientSettings();

  const enhancedOnFiltersChange = useEvent((nextFilters) => {
    /**
     * We added this workaround remove review status that are different from
     * DRAFT, APPROVAL or FOR_PAYMENT when archived is true
     */
    const isArchived = nextFilters?.archived?.value ?? false;

    if (!isArchived) return onFiltersChange(nextFilters);

    Object.keys(nextFilters).forEach((key) => {
      const isStatus = nextFilters?.[key]?.groupLabel === "Status";
      const isSafeStatus = [
        "archived",
        "rejected",
        ...ARCHIVED_VALID_STATUS,
      ].includes(key);

      if (!isStatus || isSafeStatus) return;

      delete nextFilters[key];
    });

    onFiltersChange(nextFilters);
  });

  const hasFilters =
    Object.values(filters).some((filter) => !!filter) || switchValue;

  const onClearFiltersClick = useEvent(() => {
    onSwitchChange?.(false);
    enhancedOnFiltersChange({});
  });

  const users = useUsersSimplified({
    filters: { is_staff: false, enabled: status === "APPROVAL" },
  });

  const extraDataLoading = status === "APPROVAL" && users.status === "loading";

  const extraData = useDeepMemo(() => {
    let data = [];
    if (status === "ALL") {
      const isArchived = filters?.archived?.value;
      if (!isArchived) {
        data = STATUS_FILTER;
      } else {
        /**
         * We added this workaround remove review status that are different from
         * DRAFT, APPROVAL or FOR_PAYMENT when archived is true
         */
        data = STATUS_FILTER.filter((status) =>
          ARCHIVED_VALID_STATUS.includes(status.value)
        );
      }
      data = [
        ...data,
        ...DRAW_STATUS_FILTER,
        ...BILLABLE_STATUS_FILTER,
        ...(canManageLienWaivers ? LIEN_WAIVER_STATUS_FILTER : []),
        ...(canManageLienWaivers ? LIEN_WAIVER_TEMPLATE_TYPE_FILTER : []),
      ];
    }

    if (status === "APPROVAL") {
      data = [
        ...data,
        ...users.data.map((user) => ({
          ...user,
          groupLabel: "Approval required from",
        })),
      ];
    }
    if (status === "DRAFT") {
      data = [...data, ...DRAFT_STATUS_FILTER];
    }

    if (status === "FOR_PAYMENT") {
      data = [
        ...data,
        ...PAYMENT_STATUS_FILTER.map((status) => ({
          ...status,
          groupLabel: "Status",
        })),
        ...DRAW_STATUS_FILTER,
        ...BILLABLE_STATUS_FILTER,
        ...(canManageLienWaivers ? LIEN_WAIVER_STATUS_FILTER : []),
        ...(canManageLienWaivers ? LIEN_WAIVER_TEMPLATE_TYPE_FILTER : []),
      ];
    }

    return data;
  }, [status, filters?.archived?.value, canManageLienWaivers, users.data]);

  return (
    <Flex justify="space-between" align="baseline" shrink={false}>
      <TableFilterControls
        filters={filters}
        extraData={extraData}
        extraDataLoading={extraDataLoading}
        withFilterTags
        withDateFilter
        dateProps={{ grow: true }}
        onFiltersChange={enhancedOnFiltersChange}
      >
        <Flex width="full" gap="xl" align="center">
          {hasFilters && (
            <Button onClick={onClearFiltersClick}>Clear filters</Button>
          )}
          <Flex width="full" gap="2xl" justify="flex-end">
            {switchValue !== undefined && onSwitchChange && (
              <Flex width="210px" shrink={false}>
                <Switch
                  label="Requires your approval"
                  checked={switchValue}
                  onChange={onSwitchChange}
                  data-testid="requires-your-approval"
                />
              </Flex>
            )}
            {children}
          </Flex>
        </Flex>
      </TableFilterControls>
    </Flex>
  );
};

export const BillsTabHeader = memo(RawBillsTabHeader);

BillsTabHeader.displayName = "BillsTabHeader";
