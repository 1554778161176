import React, { useEffect, useMemo } from "react";
import {
  Button,
  dialog,
  Flex,
  Icon,
  Table,
  type TableColumn,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import type { Workflow as WorkflowItem } from "@api/workflows";
import {
  Main,
  MainContent,
  MainHeader,
  MainSubtitle,
  MainTitle,
} from "@components/main";
import { TableFilterControls } from "@components/table-filter";
import { defaultArrayFormatter } from "@components/table-filter/formatters";
import { useTableFilters } from "@components/table-filter/table-filter-hooks";
import { usePeopleSimplified } from "@hooks/use-people-simplified";
import { useRolesSimplified } from "@hooks/use-roles-simplified";
import { useDrawerVisibility } from "@store/ui";
import { useWorkflowAction, useWorkflowInfo } from "@store/workflow";

import { TYPES_DATA } from "./utils";
import { WorkflowCard } from "./workflow-title";

const NEW_WORKFLOW_BUTTON_TEXT = "Add Workflow";

const NEW_FALLBACCK_WORKFLOW_BUTTON_TEXT = "Add fallback workflow";

export const ApprovalConditionalWorkflows = () => {
  const roles = useRolesSimplified(true);

  const people = usePeopleSimplified(true);

  const { setVisible } = useDrawerVisibility("workflow");

  const {
    loadWorkflows,
    deleteWorkflow,
    setCurrentWorkflow,
    duplicateWorkflow,
    resetCurrentWorkflow,
  } = useWorkflowAction();

  const { workflows } = useWorkflowInfo();

  const { filters, setFilters, rawFilters } = useTableFilters({
    storageKey: "workflow",
    formatter: defaultArrayFormatter,
  });

  const onNewWorkflowClick = useEvent(() => {
    resetCurrentWorkflow();
    setVisible(true);
  });

  const onEditWorkflow = useEvent((workflow) => {
    setCurrentWorkflow(workflow);
    setVisible(true);
  });

  const onDeleteWorkflow = useEvent((workflow) => {
    dialog.confirmation({
      title: "Delete Workflow?",
      action: {
        primary: {
          color: "error",
          onClick: async () => deleteWorkflow(workflow),
          children: "Remove",
        },
      },
    });
  });

  const onDuplicateWorkflow = useEvent((workflow) => {
    duplicateWorkflow(workflow);
    setVisible(true);
  });

  const onChangeFilters = useEvent((filters) => {
    setFilters(filters);
  });

  const columns: TableColumn<WorkflowItem>[] = useMemo(
    () => [
      {
        id: "workflow",
        width: "fill",
        minWidth: "content",
        render: (row) => <WorkflowCard {...row} />,
      },
      {
        id: "action",
        render: (row) => (
          <Flex justify="flex-end">
            <Button
              color="neutral"
              variant="text"
              aria-label="Edit workflow"
              onClick={() => onEditWorkflow(row)}
            >
              <Icon name="pen" />
            </Button>
            <Button
              color="neutral"
              variant="text"
              aria-label="Copy workflow"
              onClick={() => onDuplicateWorkflow(row)}
            >
              <Icon name="copy" />
            </Button>
            <Button
              color="neutral"
              variant="text"
              aria-label="Delete workflow"
              data-testid="delete-workflow-icon"
              onClick={() => onDeleteWorkflow(row)}
            >
              <Icon name="trash" />
            </Button>
          </Flex>
        ),
      },
    ],
    [onDeleteWorkflow, onDuplicateWorkflow, onEditWorkflow]
  );

  const extraData = useMemo(
    () => [...people.data, ...roles.data, ...TYPES_DATA],
    [people.data, roles.data]
  );

  useEffect(() => {
    if (filters) loadWorkflows(filters);
  }, [loadWorkflows, filters]);

  return (
    <>
      <Flex direction="column" gap="lg" shrink={false}>
        <Flex grow>
          <TableFilterControls
            filters={rawFilters}
            extraData={extraData}
            withFilterTags
            includeFilters={["costCodes", "customers"]}
            onFiltersChange={onChangeFilters}
          />
          {workflows.length > 0 ? (
            <Button onClick={onNewWorkflowClick}>
              {NEW_WORKFLOW_BUTTON_TEXT}
            </Button>
          ) : null}
        </Flex>

        <Table
          id="approval-workflows-table"
          data={workflows}
          header={{ hide: true }}
          columns={columns}
          bordered={false}
          emptyState={{
            title: "You currently have no approval workflows",
            subtitle:
              "Select who should approve transactions based on a variety of conditions",
            action: {
              onClick: onNewWorkflowClick,
              children: NEW_WORKFLOW_BUTTON_TEXT,
            },
          }}
        />
      </Flex>
    </>
  );
};

/* NOTE: fallback == default
 * It's just that in the code the "fallback workflow" is called "default". We use both names
 * interchangeably. It's mostly a consequency of an "isDefault" field being created, but not used
 * 2 years before this code was written. If you're reading this and scratching your head, just
 * reach out to me!
 *
 * - @yotam
 */
export const ApprovalFallbackWorkflow = () => {
  const { setVisible } = useDrawerVisibility("workflow");

  const {
    deleteWorkflow,
    resetDefaultWorkflow,
    newDefaultWorkflow,
    editDefaultWorkflow,
  } = useWorkflowAction();

  const onNewFallbackWorkflow = useEvent(() => {
    newDefaultWorkflow();
    setVisible(true);
  });

  const onEditFallbackWorkflow = useEvent(() => {
    editDefaultWorkflow();
    setVisible(true);
  });

  const onDeleteFallbackWorkflow = useEvent(() => {
    if (!defaultWorkflow) return;

    const handler = async () => {
      deleteWorkflow(defaultWorkflow);
      resetDefaultWorkflow();
    };

    dialog.confirmation({
      title: "Delete fallback workflow?",
      action: {
        primary: {
          color: "error",
          onClick: async () => handler(),
          children: "Remove",
        },
      },
    });
  });

  const { defaultWorkflow } = useWorkflowInfo();

  const columns: TableColumn<WorkflowItem>[] = useMemo(
    () => [
      {
        id: "fallback-workflow",
        width: "fill",
        minWidth: "content",
        render: (row) => <WorkflowCard {...row} />,
      },
      {
        id: "action",
        render: () => (
          <Flex justify="flex-end">
            <Button
              color="neutral"
              variant="text"
              aria-label="Edit fallback workflow"
              onClick={onEditFallbackWorkflow}
            >
              <Icon name="pen" />
            </Button>
            <Button
              color="neutral"
              variant="text"
              aria-label="Delete fallback workflow"
              data-testid="delete-fallback-workflow-icon"
              onClick={onDeleteFallbackWorkflow}
            >
              <Icon name="trash" />
            </Button>
          </Flex>
        ),
      },
    ],
    [onDeleteFallbackWorkflow, onEditFallbackWorkflow]
  );

  return (
    <>
      <Flex direction="column" gap="lg" shrink={false}>
        <Table
          id="fallback-workflows-table"
          data={defaultWorkflow ? [defaultWorkflow] : []}
          header={{ hide: true }}
          columns={columns}
          bordered={false}
          emptyState={{
            title: "You have not defined a fallback workflow",
            action: {
              onClick: onNewFallbackWorkflow,
              children: NEW_FALLBACCK_WORKFLOW_BUTTON_TEXT,
            },
          }}
        />
      </Flex>
    </>
  );
};

export const ApprovalWorkflows = () => {
  return (
    <Main>
      <MainHeader>
        <Flex direction="column" grow>
          <MainTitle>Approval workflows</MainTitle>
          <MainSubtitle>
            Create rules for how all types of spend should get approved
            <br />
            Rules allow you to automatically send bills to the right people for
            approval based on vendor, cost code, and/or transaction amount
          </MainSubtitle>
        </Flex>
      </MainHeader>
      <MainContent>
        <Flex direction="column" gap="2xl" shrink={false}>
          <ApprovalConditionalWorkflows />
          <ApprovalFallbackWorkflow />
        </Flex>
      </MainContent>
    </Main>
  );
};
