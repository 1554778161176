import React, { memo, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  Flex,
  Icon,
  Loader,
} from "@adaptive/design-system";
import { useDialog, useEvent } from "@adaptive/design-system/hooks";
import { capitalOSApi } from "@api/capital-os";
import { IssueCard } from "@capitalos/react";

import { useCardsAdaptiveContext } from "./cards-adaptive-context";

export const CardsAdaptiveAddCardButton = memo(() => {
  const dispatch = useDispatch();

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { userId, setHasError } = useCardsAdaptiveContext();

  const dialog = useDialog({ lazy: true });

  const onError = useEvent(() => {
    setHasError(true);
    dialog.hide();
  });

  const onDone = useEvent(() => {
    dialog.hide();

    /**
     * It could take a while for sync to complete, so we wait for 1 second before invalidating the tag
     */
    timeoutRef.current = setTimeout(() => {
      dispatch(capitalOSApi.util.invalidateTags(["CapitalOSCards"]));
    }, 1000);
  });

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <>
      <Button onClick={dialog.show}>
        <Icon name="plus" />
        Add a card
      </Button>
      {dialog.isRendered && (
        <Dialog variant="drawer" show={dialog.isVisible} onClose={dialog.hide}>
          <DialogContent>
            <IssueCard
              onDone={onDone}
              onCancel={dialog.hide}
              onError={onError}
              cardholder={{ userId }}
              loadingComponent={
                <Flex width="full">
                  <Loader />
                </Flex>
              }
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
});

CardsAdaptiveAddCardButton.displayName = "CardsAdaptiveAddCardButton";
