import React, { useEffect } from "react";
import { Button } from "@adaptive/design-system";
import { isEqual } from "@adaptive/design-system/utils";
import { Layout } from "@components/layout";
import { captureMessage } from "@sentry/react";
import { useBillFormActions } from "@src/bills/bill-form-context";
import { billSelector } from "@src/bills/utils";
import { useAppSelector } from "@store/hooks";
import { useClientSettings } from "@store/user";

import { PayBillStepForm } from "./pay-bill-step-form";
import { PayBillStepFormV2 } from "./pay-bill-step-form-v2";

export const PayBillStep = () => {
  const { close: onClose } = useBillFormActions();
  const { paymentsRevampEnabled } = useClientSettings();

  const { id, hasVendor } = useAppSelector((state) => {
    const bill = billSelector(state);

    return { id: bill.id, hasVendor: !!bill.initial_vendor?.id };
  }, isEqual);

  useEffect(() => {
    if (!hasVendor) {
      captureMessage(`Bill ${id} has no vendor`, {
        level: "error",
      });
    }
  }, [id, hasVendor]);

  if (!hasVendor) {
    return (
      <Layout
        title="Vendor not found"
        subtitle="We're sorry, the bill you requested doesn't have vendor. Please go back to the previous page or contact us."
      >
        <Button onClick={onClose}>Go to previous page</Button>
      </Layout>
    );
  }

  return paymentsRevampEnabled ? <PayBillStepFormV2 /> : <PayBillStepForm />;
};
