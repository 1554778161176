import React, { memo, type ReactNode, useRef } from "react";
import {
  Button,
  Flex,
  Icon,
  ResponsiveProvider,
  Text,
} from "@adaptive/design-system";
import { useResponsiveProp } from "@adaptive/design-system/hooks";
import { is } from "@adaptive/design-system/utils";
import { Attachable, type AttachableProps } from "@components/attachable";
import { BrandingLogo } from "@components/branding-logo";
import { Main, MainContent, MainHeader } from "@components/main";
import {
  MIN_LEFT_SIZE,
  MIN_RIGHT_SIZE,
  TransactionSplitView,
} from "@components/transaction-split-view";

import { adaptiveUrl, STRINGS } from "./constants";

export type ExternalLayoutProps = {
  children: ReactNode;
  attachable?: AttachableProps["attachable"] | ReactNode;
  snapToggleId?: string;
};

const isAttachable = (
  attachable: unknown
): attachable is AttachableProps["attachable"] => {
  return is.object(attachable) && "id" in attachable;
};

const BREAKPOINTS = {
  mobile: 0,
  tablet: MIN_LEFT_SIZE + MIN_RIGHT_SIZE,
};

export const ExternalLayout = memo(
  ({ attachable, children, snapToggleId }: ExternalLayoutProps) => {
    const attachableRef = useRef<HTMLDivElement>(null);

    const isMobile = useResponsiveProp({ mobile: true, tablet: false }, false);

    return (
      <Main style={attachable ? { height: "100vh" } : {}}>
        <MainHeader
          size={{ mobile: "xs", tablet: "sm" }}
          style={{ position: "sticky", top: 0 }}
        >
          <Flex wrap gap="md" justify="space-between">
            <BrandingLogo />
            <Flex gap="2xl" wrap align="center">
              {!isMobile && <Text size="sm">{STRINGS.HEADLINE}</Text>}
              <Button
                size="sm"
                as="a"
                variant="text"
                href={adaptiveUrl}
                target="_blank"
                data-skip-focusable=""
              >
                {STRINGS.HEADLINE_ACTION}
              </Button>
            </Flex>
          </Flex>
        </MainHeader>
        <MainContent scrollable={false} variant="unspaced">
          {attachable ? (
            <TransactionSplitView
              snapToggleId={snapToggleId}
              initialVisibleSide="left"
              left={
                <Flex
                  ref={attachableRef}
                  width="full"
                  align={isAttachable(attachable) ? "center" : undefined}
                  height="full"
                  padding="3xl"
                  justify="center"
                  overflow={!isAttachable(attachable) ? "auto" : undefined}
                >
                  {!isAttachable(attachable) ? (
                    <ResponsiveProvider
                      ref={attachableRef}
                      breakpoints={BREAKPOINTS}
                      initialBreakpoint="desktop"
                    >
                      {attachable}
                    </ResponsiveProvider>
                  ) : (
                    <Attachable
                      attachable={attachable}
                      action={
                        snapToggleId && isMobile ? (
                          <Button
                            as="label"
                            size="sm"
                            color="neutral"
                            variant="ghost"
                            htmlFor={snapToggleId}
                          >
                            <Icon name="x" />
                          </Button>
                        ) : undefined
                      }
                    />
                  )}
                </Flex>
              }
              right={children}
            />
          ) : (
            children
          )}
        </MainContent>
      </Main>
    );
  }
);

ExternalLayout.displayName = "ExternalLayout";
