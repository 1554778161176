import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Text,
  TextField,
  toast,
} from "@adaptive/design-system";
import { useTwoFactorAuth } from "@shared/store/ui";
import { useUserAction } from "@shared/store/user";
import { userSelector } from "@store/user/slice";

import type { User } from "./types";

const CODE_LENGTH = 6;

const RESEND_TIMEOUT = 60;

export const TwoFactorAuthDialog = () => {
  const user = useSelector(userSelector) as unknown as User;

  const [verificationAvailable, setVerificationAvailable] = useState(false);

  const [code, setCode] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const [verificationId, setVerificationId] = useState("");

  const [resendTimer, setResendTimer] = useState(0);

  const { send2FA, verify2FA } = useUserAction();

  const { visible, setVisible, callback } = useTwoFactorAuth();

  const verify = useCallback(async () => {
    try {
      await verify2FA({ code: code, verification_sid: verificationId });
      toast.success(
        `${user.preferred_mfa_method === "SMS" ? "Phone number" : "E-mail"} verified`
      );
      callback?.();
    } catch (error: unknown) {
      const { message } = error as Error;
      if (message) {
        setErrorMessage(message);
        toast.error(message);
      }
    }
  }, [callback, user.preferred_mfa_method, code, verificationId, verify2FA]);

  const onSendCode = useCallback(async () => {
    try {
      const { data } = await send2FA();
      setVerificationId(data.sid);
      setVerificationAvailable(true);
      setResendTimer(RESEND_TIMEOUT);
    } catch {
      setVisible(false);
      toast.error(
        `Two factor authentication failed. Please check your ${user.preferred_mfa_method === "SMS" ? "phone number" : "email"}`
      );
    }
  }, [send2FA, setVisible, user.preferred_mfa_method]);

  useEffect(() => {
    const timeoutId =
      resendTimer > 0
        ? setTimeout(() => setResendTimer(resendTimer - 1), 1000)
        : undefined;

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [resendTimer]);

  useEffect(() => {
    if (visible) {
      onSendCode();
    }
  }, [onSendCode, visible]);

  useEffect(() => {
    if (code.length === CODE_LENGTH && verificationId) {
      setCode("");
      verify();
    } else {
      setErrorMessage("");
    }
  }, [code, verificationId, verify]);

  return verificationAvailable ? (
    <Dialog
      show={visible}
      variant="dialog"
      onClose={() => setVisible(false)}
      size="sm"
    >
      <DialogHeader>
        {user.preferred_mfa_method === "SMS" ? "SMS" : "E-mail"} verification
        required
      </DialogHeader>
      <DialogContent>
        <Flex direction="column" gap="xl">
          <Text size="md" weight="semi-bold">
            We sent a 6 digit verification code to{" "}
            {user.preferred_mfa_method === "SMS"
              ? user.phone_number
              : user.email}
          </Text>
          <TextField
            errorMessage={errorMessage}
            maxLength={6}
            value={code}
            onChange={setCode}
          />
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Button
          block
          variant="text"
          color="neutral"
          onClick={() => setVisible(false)}
        >
          Cancel
        </Button>
        <Button block onClick={onSendCode} disabled={resendTimer > 0}>
          {resendTimer > 0
            ? `Resend in ${resendTimer} seconds`
            : "Resend verification code"}
        </Button>
      </DialogFooter>
    </Dialog>
  ) : null;
};
