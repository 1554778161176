import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  memo,
  useEffect,
  useState,
} from "react";
import { Portal } from "@ariakit/react";
import cn from "clsx";

import { useDeepMemo } from "../../hooks/use-deep-memo";
import { useEvent } from "../../hooks/use-event";
import { useResizeObserver } from "../../hooks/use-resize-observer";

import styles from "./floating-toolbar.module.css";

type DefaultComponent = "div";

export type FloatingToolbarRef = ComponentRef<DefaultComponent>;

export type FloatingToolbarProps =
  ComponentPropsWithoutRef<DefaultComponent> & {
    show?: boolean;
  };

const FloatingToolbar = forwardRef<FloatingToolbarRef, FloatingToolbarProps>(
  ({ show, className, ...props }, ref) => {
    const [toolbar, setToolbar] = useState<HTMLDivElement | null>(null);
    const [placeholder, setPlaceholder] = useState<HTMLDivElement | null>(null);

    const updateHeight = useEvent(() => {
      if (!toolbar || !placeholder) return;

      const height = toolbar.getBoundingClientRect().height;
      placeholder.style.setProperty("--floating-toolbar-height", `${height}px`);
    });

    const memoizedToolbarRef = useDeepMemo(
      () => ({ current: toolbar }),
      [toolbar]
    );

    useEffect(() => updateHeight(), [updateHeight]);

    useResizeObserver(memoizedToolbarRef, updateHeight, { observe: "height" });

    return (
      <>
        <Portal
          ref={ref}
          className={cn(className, styles["floating-toolbar"])}
          aria-hidden={!show}
          {...props}
        >
          <div ref={setToolbar} className={styles["toolbar"]}>
            <div className={styles["content"]}>{props.children}</div>
          </div>
        </Portal>
        <div
          ref={setPlaceholder}
          className={styles["placeholder"]}
          aria-hidden={!show}
        />
      </>
    );
  }
);

FloatingToolbar.displayName = "FloatingToolbar";

const MemoizedFloatingToolbar = memo(FloatingToolbar);

export { MemoizedFloatingToolbar as FloatingToolbar };
