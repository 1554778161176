import React, { useMemo } from "react";
import { useParams } from "react-router";
import {
  Button,
  Card,
  Flex,
  Loader,
  Tag,
  Text,
  toast,
} from "@adaptive/design-system";
import { useEvent, useResponsiveProp } from "@adaptive/design-system/hooks";
import { formatCurrency } from "@adaptive/design-system/utils";
import { useGetExternalBillPaymentQuery } from "@bill-payment/api";
import { PROCESS_PREREQUISITE_TYPE } from "@bill-payment/constants";
import { Comments } from "@components/comments";
import { ExternalLayout } from "@components/external-layout";
import { MainContent } from "@components/main";
import { BillLienWaiverField } from "@lien-waiver/components";
import { LIEN_WAIVER_LINKED_STATUS } from "@lien-waiver/constants";
import type { LienWaiver } from "@lien-waiver/types";

import { PAYMENT_LANDING_PAGE_STRINGS } from "../../constants";
import { LinkedTransactionsTable } from "../bill-payment-linked-transactions";

import { BillPaymentInfo } from "./bill-payment-info";

export const BillPaymentLandingPage = () => {
  const isDesktop = useResponsiveProp(
    { mobile: false, tablet: false, desktop: true },
    false
  );

  const { paymentUid, realmUid } = useParams<{
    paymentUid: string;
    realmUid: string;
  }>();
  const { data: externalBillPayment, isLoading: isExternalBillPaymentLoading } =
    useGetExternalBillPaymentQuery(
      { billPaymentId: paymentUid! },
      { skip: !paymentUid, refetchOnFocus: true }
    );
  const commentsSelector = useMemo(
    () => ({
      url: externalBillPayment?.url || "",
      comments: externalBillPayment?.comments || [],
    }),
    [externalBillPayment]
  );

  const achInfoRequired = useMemo(
    () =>
      (externalBillPayment?.processPrerequisites.find(
        (prerequisite) =>
          prerequisite.type === PROCESS_PREREQUISITE_TYPE.VENDOR_BANKING_ACH &&
          !prerequisite.isSatisfied
      ) ||
        !externalBillPayment?.vendorBankAccount) &&
      externalBillPayment?.vendor.latestAchRequest,
    [externalBillPayment]
  );

  const lienWaivers = useMemo(
    () =>
      externalBillPayment?.lienWaivers?.filter((lienWaiver) =>
        LIEN_WAIVER_LINKED_STATUS.includes(lienWaiver.status)
      ) || [],
    [externalBillPayment?.lienWaivers]
  );

  const onAddThreadComment = () =>
    toast.success(PAYMENT_LANDING_PAGE_STRINGS.REPLY_COMMENT_ADDED);

  const onSignLienWaiver = useEvent((billLienWaiverId) =>
    window.open(
      `/vendor-lien-waiver-request/${realmUid}/${billLienWaiverId}`,
      "_blank"
    )
  );

  const vendorAchFormUrl = useMemo(
    () =>
      `/vendor-ach-request/${externalBillPayment?.vendor.latestAchRequest?.token}/${externalBillPayment?.realm.uniqueId}/${externalBillPayment?.vendor?.uniqueId}?billPaymentId=${externalBillPayment?.id}`,
    [externalBillPayment]
  );

  if (isExternalBillPaymentLoading) {
    return <Loader position="fixed" />;
  }

  return (
    <ExternalLayout
      attachable={
        <Flex direction="column" gap="3xl" width="full">
          <Text align="center" size="2xl" weight="bold" family="brand">
            {externalBillPayment?.realm.companyName}{" "}
            {PAYMENT_LANDING_PAGE_STRINGS.PAGE_TITLE_SENT_YOU}{" "}
            {formatCurrency(`${externalBillPayment?.appliedAmount}`, {
              currencySign: true,
            })}
          </Text>
          {achInfoRequired && (
            <Card as={Flex} direction="column" width="full" gap="xl">
              <Flex gap="md" align="center">
                <Text size="sm" weight="bold">
                  {externalBillPayment?.realm.companyName}{" "}
                  {PAYMENT_LANDING_PAGE_STRINGS.ACH_REQUEST_TITLE_BOLD}
                </Text>
                <Tag size="sm" color="error">
                  {PAYMENT_LANDING_PAGE_STRINGS.ACH_REQUEST_TITLE_TAG}
                </Tag>
              </Flex>
              <Text size="sm">
                {PAYMENT_LANDING_PAGE_STRINGS.ACH_REQUEST_SUBTITLE}
              </Text>

              <Button
                as="a"
                href={vendorAchFormUrl}
                target="_blank"
                block
                size="lg"
              >
                {PAYMENT_LANDING_PAGE_STRINGS.ACH_REQUEST_BUTTON}
              </Button>
            </Card>
          )}
          {externalBillPayment && (
            <BillPaymentInfo
              billPayment={externalBillPayment}
              vendorAchFormUrl={vendorAchFormUrl}
            />
          )}
          {externalBillPayment &&
            lienWaivers.length > 0 &&
            externalBillPayment.vendor && (
              <Flex gap="lg" direction="column" shrink={false}>
                <Text as="h2" size="xl" weight="bold">
                  {PAYMENT_LANDING_PAGE_STRINGS.LIEN_WAIVER_TITLE}
                </Text>
                {lienWaivers.map((lienWaiver) => (
                  <BillLienWaiverField
                    customer={lienWaiver.customer?.url || undefined}
                    key={lienWaiver.id}
                    value={lienWaiver.lienWaiverTemplate || undefined}
                    recipientEmail={
                      externalBillPayment.vendor.email || undefined
                    }
                    vendor={externalBillPayment.vendor}
                    paymentAmount={Number(lienWaiver?.paymentAmount || "0")}
                    billPaymentId={externalBillPayment?.id}
                    billLienWaiver={lienWaiver as LienWaiver}
                    externalFlow
                    onRequestUpdate={() => onSignLienWaiver(lienWaiver.id)}
                    billId={lienWaiver?.billId || undefined}
                    isProcessPrerequisite={!!lienWaiver?.isProcessPrerequisite}
                  />
                ))}
              </Flex>
            )}

          {externalBillPayment && (
            <Flex width="full" justify="flex-start" direction="column">
              <Flex gap="lg" direction="column">
                <Text as="h2" size="xl" weight="bold">
                  {PAYMENT_LANDING_PAGE_STRINGS.ASSOCIATED_TRANSACTION_TITLE}
                </Text>
                <LinkedTransactionsTable
                  bills={externalBillPayment?.bills}
                  appliedVendorCredits={
                    externalBillPayment?.appliedVendorCredits
                  }
                  externalFlow
                  loading={isExternalBillPaymentLoading}
                />
              </Flex>
            </Flex>
          )}
        </Flex>
      }
    >
      <MainContent
        variant={{
          mobile: "spaced-sm",
          tablet: "spaced-md",
          desktop: "unspaced",
        }}
      >
        <Flex
          gap="2xl"
          width="full"
          grow
          shrink={false}
          direction={{ tablet: "column", desktop: "row" }}
        >
          <Flex
            direction="column"
            background={isDesktop ? "neutral-200" : "neutral-100"}
            padding="3xl"
            width="full"
          >
            <Comments
              title=""
              selector={commentsSelector}
              onAddThreadComment={onAddThreadComment}
              showExternalWarning={false}
              showExternalFlag={false}
              placeholder={`${PAYMENT_LANDING_PAGE_STRINGS.REPLY_PLACEHOLDER} ${externalBillPayment?.realm.companyName}`}
            />
          </Flex>
        </Flex>
      </MainContent>
    </ExternalLayout>
  );
};
