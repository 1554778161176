import React, {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSearchParams } from "react-router";
import { useEvent, usePagination } from "@adaptive/design-system/hooks";
import { Main, MainContent, MainHeader, MainTitle } from "@components/main";
import { type StrictValuesFilters } from "@components/table-filter";
import { useTableFilters } from "@components/table-filter/table-filter-hooks";
import { scrollMainTop } from "@utils/scroll-main-top";

import {
  ALL_BILL_PAYMENTS_FILTERS,
  BILL_PAYMENTS_TABLE_LOCAL_STORAGE_LIMIT_KEY,
  BILL_PAYMENTS_TABS,
  DEFAULT_TAB,
  FILTERS_STORAGE_KEY,
  IN_PROGRESS_BILL_PAYMENTS_FILTERS,
  SCHEDULED_BILL_PAYMENTS_FILTERS,
} from "../../constants";
import { useListBillPaymentsV2 } from "../../hooks";
import {
  type BillPaymentsPageData,
  type BillPaymentsPageTab,
} from "../../types";

import { billPaymentsFilterFormatter } from "./bill-payments-filter-formatter";
import { BillPaymentsPageContext } from "./context";
import { BillPaymentsPageContent } from "./page-content";

export const BillPaymentsPage = () => {
  const pagination = usePagination({
    localStorageKey: BILL_PAYMENTS_TABLE_LOCAL_STORAGE_LIMIT_KEY,
    perPageVariant: "sm",
  });

  const setPage = useCallback<Dispatch<SetStateAction<number>>>(
    (props) => {
      pagination.setPage(props);
      scrollMainTop(0);
    },
    [pagination]
  );

  const enhancedPagination = useMemo(() => {
    return {
      ...pagination,
      setPage,
    };
  }, [pagination, setPage]);

  const [order, setOrder] = useState("-debit_date");

  const [tableOffset, setTableOffset] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams({
    tab: DEFAULT_TAB,
  });

  const currentTab =
    (searchParams.get("tab") as BillPaymentsPageTab) || DEFAULT_TAB;

  const { filters, setFilters, rawFilters } = useTableFilters({
    formatter: billPaymentsFilterFormatter,
    storageKey: FILTERS_STORAGE_KEY,
  });

  useEffect(() => {
    if (
      currentTab !== BILL_PAYMENTS_TABS.ALL &&
      filters?.some((f) => f.dataIndex === "status")
    ) {
      //Removing `Payment status` filter when switching to other tabs other than `all`
      // @ts-expect-error incorrect typing for method
      setFilters((prevFilters: StrictValuesFilters) => {
        const newFilters = {};

        Object.keys(prevFilters).forEach((key) => {
          if (
            prevFilters[key].groupLabel &&
            prevFilters[key].groupLabel !== "Payment status"
          ) {
            // @ts-expect-error incorrect typing for method
            newFilters[key] = prevFilters[key];
          }
        });
        return newFilters as StrictValuesFilters;
      });
    }
  }, [currentTab, filters, setFilters]);

  const scheduled: BillPaymentsPageData = useListBillPaymentsV2({
    query: SCHEDULED_BILL_PAYMENTS_FILTERS,
    order: order,
    filters,
    pagination,
  });
  const inProgress: BillPaymentsPageData = useListBillPaymentsV2({
    query: IN_PROGRESS_BILL_PAYMENTS_FILTERS,
    order: order,
    filters,
    pagination,
  });
  const all: BillPaymentsPageData = useListBillPaymentsV2({
    query: ALL_BILL_PAYMENTS_FILTERS,
    order: order,
    filters,
    pagination,
  });

  const setTab = useEvent((tab: BillPaymentsPageTab) => {
    setSearchParams({ tab });
  });

  const clearFilters = useEvent(() => setFilters({}));

  return (
    <Main>
      <MainHeader>
        <MainTitle>Payments</MainTitle>
      </MainHeader>
      <MainContent>
        <BillPaymentsPageContext.Provider
          value={{
            scheduled,
            inProgress,
            all,
            pagination: enhancedPagination,
            filters: filters || [],
            order,
            setOrder,
            tab: currentTab,
            setTab,
            rawFilters: rawFilters,
            setFilters: setFilters,
            tableOffset,
            clearFilters,
            setTableOffset,
          }}
        >
          <BillPaymentsPageContent />
        </BillPaymentsPageContext.Provider>
      </MainContent>
    </Main>
  );
};
